import { useCallback, useEffect, useMemo, useState } from "react";

import { AnalyticsResourceType, EarlyAccessFeature, Roles } from "@doitintl/cmp-models";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import Tooltip from "@mui/material/Tooltip";

import { attributionGroupsText, globalText } from "../../../assets/texts";
import { entitlementsTxt } from "../../../assets/texts/CloudAnalytics/entitlements";
import DeleteDialog from "../../../Components/DeleteDialog";
import { DoitConsoleTitle } from "../../../Components/DoitConsoleTitle";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import FilterTableToolbarMeteredTitle from "../../../Components/FilterTable/Toolbar/FilterTableToolbarMeteredTitle";
import Hide from "../../../Components/HideChildren/Hide";
import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useTierLimitReachedAnalyticsAttributionGroups } from "../../../Context/AnalyticsTierProvider";
import { useAuthContext } from "../../../Context/AuthContext";
import { useIsFeatureEntitled, useTier } from "../../../Context/TierProvider";
import mixpanel from "../../../utils/mixpanel";
import { attributionGroupsToAnalyticsResources } from "../analyticsResources/utils";
import AllocationsMigrationAlert from "../components/AllocationsMigrationAlert";
import AssignLabelsButton from "../labels/components/AssignLabelsButton";
import EditPermissionsButton from "../labels/components/EditPermissionsButton";
import { useLabels } from "../labels/hooks";
import { useCanEditSelectedObjects, useCanEditSelectedObjectsPermissions } from "../utilities";
import { filterColumns, headers } from "./AttributionGroupsColumns";
import { AttributionGroupRow } from "./AttributionGroupsRow";
import { AttributionGroupShareDialog } from "./AttributionGroupsShareDialog";
import { useCreateAttributionGroupHandler, useDeleteAttributionGroupHandler } from "./hooks";
import { type AttributionGroupWithRef } from "./types";

export type AnalyticsAttributionGroupsBrowserProps = {
  attributionGroups: AttributionGroupWithRef[];
};

export const AttributionGroupsBrowser = ({ attributionGroups }: AnalyticsAttributionGroupsBrowserProps) => {
  const { getFeatureLimit, getFeatureName } = useTier();
  const isAllocationsFeatureEnabled = useFeatureFlag(EarlyAccessFeature.ALLOCATIONS_PAGES);
  const isEntitledAttributionGroups = useIsFeatureEntitled("analytics:attributionGroups");
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const routeMatchURL = useRouteMatchURL();
  const [clickedRow, setClickedRow] = useState<AttributionGroupWithRef>({} as AttributionGroupWithRef);
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selected, setSelected] = useState<AttributionGroupWithRef[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const isBulk = selected.length >= 1;
  const [labels, labelsLoading] = useLabels();

  const agTier = useTierLimitReachedAnalyticsAttributionGroups();

  useEffect(() => {
    const updatedSelectedAttributionGroups = attributionGroups.filter((attributionGroup) =>
      selected.some((x) => x.ref.id === attributionGroup.ref.id)
    );

    setSelected(updatedSelectedAttributionGroups);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attributionGroups]);

  const handleNewAttributionGroups = useCreateAttributionGroupHandler({
    mixpanelEventName: "analytics.attribution-groups.new",
    baseUrl: routeMatchURL,
  });

  const handleDeleteAttributionGroups = useDeleteAttributionGroupHandler({
    row: clickedRow,
    selected,
    closeDialog: () => {
      setClickedRow({} as AttributionGroupWithRef);
      setSelected([]);
      setOpenDeleteDialog(false);
    },
  });

  useMountEffect(() => {
    mixpanel.track("analytics.attribution-groups.list");
  });

  const AttributionGroupRowWrapper = useCallback(
    ({ row }: { row: AttributionGroupWithRef }) => (
      <AttributionGroupRow
        setOpenDeleteDialog={setOpenDeleteDialog}
        setShareDialogOpen={setShareDialogOpen}
        setClickedRow={setClickedRow}
        row={row}
        labels={labels}
        tierLimitReached={Boolean(agTier.limitReached)}
      />
    ),
    [labels, agTier.limitReached]
  );

  const disableDeleteAttribution = useMemo(
    () =>
      !selected.length ||
      selected?.some((s) =>
        s.data.collaborators.find((c) => c.email !== currentUser.email && c.role === Roles.OWNER)
      ) ||
      selected?.some((s) => s.data.type === AnalyticsResourceType.PRESET),
    [currentUser.email, selected]
  );

  const attributionGroupsWithoutPresets = useMemo(() => selected.filter((x) => x.data.type !== "preset"), [selected]);

  const canEditSelectedGroups = useCanEditSelectedObjects(
    currentUser.email,
    attributionGroupsWithoutPresets.map((x) => x.data)
  );

  const canEditPermissions = useCanEditSelectedObjectsPermissions(
    currentUser.email,
    attributionGroupsWithoutPresets.map((x) => x.data)
  );

  const handleOpenDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(true);
  }, []);

  const handleCloseDeleteDialog = useCallback(() => {
    setOpenDeleteDialog(false);
  }, []);

  const toolbarTitle = useMemo(() => {
    const featureName = getFeatureName("analytics:attributionGroups") as string;
    const limit = getFeatureLimit("analytics:attributionGroups") as number | null;
    if (limit) {
      return (
        <FilterTableToolbarMeteredTitle
          value={agTier.size ?? 0}
          limit={limit}
          featureName={featureName}
          loading={agTier.loading}
        />
      );
    }
    return featureName;
  }, [agTier.loading, agTier.size, getFeatureLimit, getFeatureName]);

  const createDisabled = useMemo(
    () => isAllocationsFeatureEnabled || !isEntitledAttributionGroups || agTier.limitReached,
    [isEntitledAttributionGroups, agTier.limitReached, isAllocationsFeatureEnabled]
  );

  if (labelsLoading || agTier.loading) {
    return <FilterTableSkeleton />;
  }

  return (
    <>
      <DoitConsoleTitle pageName="Attributions Groups" />
      <FilterTable<AttributionGroupWithRef>
        showRowsSelection={true}
        onRowsSelected={setSelected}
        tableItems={attributionGroups}
        rowComponent={AttributionGroupRowWrapper}
        headerColumns={headers}
        filterColumns={filterColumns}
        filterBarPlaceholder={attributionGroupsText.FILTER_ATTRIBUTION_GROUPS}
        persistenceKey="analytics_attribution_groups_tab"
        itemUniqIdentifierField="ref.id"
        defaultSortingColumnValue="data.timeModified"
        toolbarProps={{
          title: toolbarTitle,
        }}
      >
        <Grid>
          <Button
            variant="contained"
            color="error"
            onClick={handleOpenDeleteDialog}
            disabled={disableDeleteAttribution}
          >
            {globalText.DELETE}
          </Button>
        </Grid>
        <Grid>
          <AssignLabelsButton
            labels={labels ?? []}
            selectedResources={attributionGroupsToAnalyticsResources(selected)}
            disabled={!canEditSelectedGroups}
          />
        </Grid>
        <Grid>
          <EditPermissionsButton
            selectedResources={attributionGroupsToAnalyticsResources(selected)}
            onClick={() => {
              setShareDialogOpen(true);
            }}
            disabled={!canEditPermissions}
          />
        </Grid>
        <Grid>
          <Hide mdDown>
            <Tooltip title={createDisabled ? entitlementsTxt.CREAT_ATTRIBUTION_GROUPS : ""}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewAttributionGroups}
                disabled={createDisabled}
              >
                {attributionGroupsText.CREATE_NEW_GROUP}
              </Button>
            </Tooltip>
          </Hide>
        </Grid>
        {isAllocationsFeatureEnabled && (
          <Grid size={12} sx={{ mt: 1 }}>
            <AllocationsMigrationAlert />
          </Grid>
        )}
      </FilterTable>
      {openDeleteDialog && (
        <DeleteDialog
          open={openDeleteDialog}
          title={attributionGroupsText.DELETE_SELECTED}
          message={attributionGroupsText.DELETE_MESSAGE}
          onDelete={handleDeleteAttributionGroups}
          onClose={handleCloseDeleteDialog}
        />
      )}
      {shareDialogOpen && (
        <AttributionGroupShareDialog
          title={
            isBulk ? attributionGroupsText.SHARE_ATTRIBUTION_GROUPS : attributionGroupsText.SHARE_ATTRIBUTION_GROUP
          }
          attributionGroups={
            isBulk
              ? selected.map((x) => ({ ...x.data, id: x.ref.id }))
              : [{ ...clickedRow.data, id: clickedRow.ref.id }]
          }
          shareDialogOpen={shareDialogOpen}
          onClose={() => {
            setShareDialogOpen(false);
            setClickedRow({} as AttributionGroupWithRef);
          }}
        />
      )}
    </>
  );
};

export default AttributionGroupsBrowser;
