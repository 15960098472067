import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";
import { isGoogleCloudPartnerLedPremiumSupport } from "../../utils";

export const validateGcpPLPSStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  if (!isGoogleCloudPartnerLedPremiumSupport(state)) {
    return false;
  }

  const errors: Record<string, boolean> = {};

  errors.assets = !state.assets.length;
  errors.gcpContract = !state.gcpContract.length;
  // errors.startDate validation is done in PlpsDatePicker component
  errors.startDate = !state.startDate || !!state.errors.startDate;
  errors.endDate = !!state.endDate && state.startDate >= state.endDate;

  setState({ ...state, errors });

  return !Object.values(errors).some((error) => error);
};
