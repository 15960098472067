import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark as dark, twilight as light } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Alert, Button, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import remarkGfm from "remark-gfm";

type Props = {
  line: string;
  isDarkMode: boolean;
  handleAskQuestion?: (question: string) => void;
  handleOpenTicket?: () => void;
};

// Custom Link component to handle different link actions
const CustomLink = ({
  href,
  children,
  handleAskQuestion,
  handleOpenTicket,
  ...props
}: {
  href?: string;
  children: React.ReactNode;
  handleAskQuestion?: (question: string) => void;
  handleOpenTicket?: () => void;
  sx?: any;
}) => {
  const handleClick = () => {
    if (href && href.includes("runAva") && handleAskQuestion) {
      const decodedQuery = decodeURIComponent(href.replace("runAva=", ""));
      handleAskQuestion(decodedQuery);
    }
  };

  if (href?.includes("runAva")) {
    return (
      <Alert
        action={
          <Button variant="outlined" sx={{ cursor: "pointer" }} onClick={handleClick}>
            Run
          </Button>
        }
        severity="info"
        sx={{ mb: 2 }}
      >
        <Stack
          direction="row"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            {...props}
            target="_self"
            component="a"
            sx={[
              {
                fontSize: "small",
              },
              ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
            ]}
          >
            {children}
          </Typography>
        </Stack>
      </Alert>
    );
  }

  if (href?.includes("open-ticket")) {
    return (
      <>
        <br />

        <Button variant="outlined" onClick={() => handleOpenTicket?.()} sx={{ my: 1 }}>
          Create a request
        </Button>

        <br />
      </>
    );
  }

  return (
    <Link {...props} href={(props as any)?.node?.properties?.href} target="_blank" component="a">
      {children}
    </Link>
  );
};

export const CustomMarkdown = ({ line, isDarkMode, handleAskQuestion, handleOpenTicket }: Props) => (
  <ReactMarkdown
    linkTarget="_blank"
    remarkPlugins={[remarkGfm]}
    components={{
      a: ({ href, children, ...props }) => (
        <CustomLink href={href} handleAskQuestion={handleAskQuestion} handleOpenTicket={handleOpenTicket} {...props}>
          {children}
        </CustomLink>
      ),
      code({ inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        return !inline && match ? (
          <SyntaxHighlighter {...props} style={isDarkMode ? dark : light} language={match[1]} PreTag="div">
            {String(children).replace(/\n$/, "")}
          </SyntaxHighlighter>
        ) : (
          <code {...props} className={className}>
            {children}
          </code>
        );
      },
      p: ({ children }) => <>{children}</>,
    }}
  >
    {line}
  </ReactMarkdown>
);
