import { type JSX, useState } from "react";

import { AnalyticsDataSource, EarlyAccessFeature } from "@doitintl/cmp-models";
import { InfoOutlined, OpenInNew } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import FormControlLabel, { type FormControlLabelProps } from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import { type SelectChangeEvent } from "@mui/material/Select";

import { globalText } from "../../../../assets/texts";
import { BIG_QUERY_LINK, dataSourceDialogTxt, reportTxt } from "../../../../assets/texts/CloudAnalytics";
import useReportSaver from "../../../../Components/hooks/cloudAnalytics/reports/useReportSaver";
import { useIsCustomerUsingBQ } from "../../../../Components/hooks/useBqLens";
import { useFeatureFlag } from "../../../../Components/hooks/useFeatureFlag";
import { useIsCustomerUsingKubernetesUtilization } from "../../../../Components/hooks/useKubernetesUtilization";
import LoadingButton from "../../../../Components/LoadingButton";
import { useAuthContext } from "../../../../Context/AuthContext";
import { DataSourceTexts } from "./DataSourceSection";

type Props = {
  onClose: () => void;
  onSave: (newDataSource: AnalyticsDataSource) => void;
  onCreateNewReport: (source: AnalyticsDataSource) => Promise<string>;
  currentDataSource: AnalyticsDataSource;
};

function DataSourceRadioContainer({
  linkTag,
  description,
  infoText,
  value,
  ...options
}: FormControlLabelProps & {
  linkTag?: JSX.Element;
  description: string;
  infoText?: string;
  value: AnalyticsDataSource;
}) {
  const radioGroup = useRadioGroup();

  let checked = false;
  if (radioGroup) {
    checked = radioGroup.value === value;
  }
  return (
    <Box
      sx={{
        p: checked ? "15px" : 2,
        mb: 1,
        border: `${checked ? "2" : "1"}px solid`,
        borderColor: checked ? "primary.main" : "general.divider",
        borderRadius: 1,
        boxSizing: "border-box",
      }}
    >
      <FormControlLabel
        {...options}
        value={value}
        sx={{ alignItems: "start" }}
        label={
          <Box>
            <Typography
              variant="subtitle1"
              sx={{
                color: "text.primary",
                fontWeight: 500,
                display: "flex",
                gap: 1,
                alignItems: "center",
              }}
            >
              {DataSourceTexts.get(value)}
              {!!infoText && (
                <Tooltip title={infoText}>
                  <InfoOutlined fontSize="inherit" />
                </Tooltip>
              )}
            </Typography>
            <Typography
              variant="subtitle1"
              sx={{
                color: "text.secondary",
              }}
            >
              {description}
              {linkTag}
            </Typography>
          </Box>
        }
      />
    </Box>
  );
}

export const DataSourceDialog = ({ onClose, onSave, onCreateNewReport, currentDataSource }: Props) => {
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { getNewReportURL } = useReportSaver();
  const [dataSource, setDataSource] = useState<AnalyticsDataSource>(
    currentDataSource === AnalyticsDataSource.BILLING_DATAHUB ? AnalyticsDataSource.BILLING : currentDataSource
  );
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const { isCustomerUsingBQ } = useIsCustomerUsingBQ();
  const { isCustomerUsingKubernetesUtilization } = useIsCustomerUsingKubernetesUtilization();
  const isFsk8sEnabled = useFeatureFlag(EarlyAccessFeature.FSK8S);

  const onConfirm = () => {
    setLoading(true);
    onSave(dataSource);
    setLoading(false);
    onClose();
  };

  const handleChangeDataSource = (event: SelectChangeEvent) => {
    setDataSource(event.target.value as AnalyticsDataSource);
  };

  const handleCreateNewReport = async () => {
    setCreating(true);
    const newReportId = await onCreateNewReport(dataSource);
    window.open(getNewReportURL(newReportId), "_blank");
    setCreating(false);
    onClose();
  };

  const disabledButtons = !dataSource || loading || dataSource === currentDataSource;

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="dialog-title" maxWidth="sm" fullWidth>
      <DialogTitle id="dialog-title">{reportTxt.CHANGE_DATA_SOURCE}</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            mb: 2,
          }}
        >
          <Typography
            variant="body1"
            sx={{
              color: "text.primary",
            }}
          >
            {reportTxt.DATA_SOURCE_DIALOG_ALERT_TEXT}
          </Typography>
        </Box>
        <Stack
          sx={{
            gap: 2,
          }}
        >
          <RadioGroup sx={{ gap: 2 }} onChange={handleChangeDataSource} value={dataSource}>
            <DataSourceRadioContainer
              label={AnalyticsDataSource.BILLING}
              value={AnalyticsDataSource.BILLING}
              control={<Radio sx={{ pt: 0 }} />}
              description={dataSourceDialogTxt.BILLING_DATA_DESC}
            />
            {isCustomerUsingBQ && (
              <DataSourceRadioContainer
                label={AnalyticsDataSource.BQLENS}
                value={AnalyticsDataSource.BQLENS}
                control={<Radio sx={{ pt: 0 }} />}
                description={`${dataSourceDialogTxt.BIG_QUERY_DESC} `}
                linkTag={
                  <Link
                    href={BIG_QUERY_LINK}
                    target="_blank"
                    sx={{ display: "inline-flex", alignItems: "center", gap: 1 }}
                  >
                    {dataSourceDialogTxt.LEARN_MORE} <OpenInNew fontSize="inherit" />
                  </Link>
                }
              />
            )}

            {isDoitEmployee && (
              <DataSourceRadioContainer
                label={AnalyticsDataSource.CUSTOMER_FEATURES}
                value={AnalyticsDataSource.CUSTOMER_FEATURES}
                control={<Radio sx={{ pt: 0 }} />}
                description={dataSourceDialogTxt.CUSTOMER_FEATURES_DESC}
              />
            )}

            {isFsk8sEnabled && isCustomerUsingKubernetesUtilization && (
              <DataSourceRadioContainer
                label={AnalyticsDataSource.KUBERNETES_UTILIZATION}
                value={AnalyticsDataSource.KUBERNETES_UTILIZATION}
                control={<Radio sx={{ pt: 0 }} />}
                description={dataSourceDialogTxt.KUBERNETES_UTILIZATION_DESC}
              />
            )}
          </RadioGroup>
        </Stack>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          onClick={handleCreateNewReport}
          variant="text"
          disabled={disabledButtons}
          sx={{ mr: "auto" }}
          loading={creating}
          mixpanelEventId="analytics.report.data-source.create"
        >
          {reportTxt.CREATE_AS_NEW_REPORT}
          <OpenInNewIcon sx={{ ml: 0.5 }} fontSize="small" />
        </LoadingButton>
        <Button onClick={onClose} variant="text">
          {globalText.CANCEL}
        </Button>
        <LoadingButton
          loading={loading}
          onClick={onConfirm}
          disabled={disabledButtons}
          variant="contained"
          mixpanelEventId="analytics.report.data-source.update"
        >
          {globalText.UPDATE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
