import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  CLOUD_FLOW_CREATION_STATUS,
  type CloudflowCreationStatus,
  CloudflowExecutionStatus,
  CloudFlowNodeType,
  type CloudflowType,
} from "@doitintl/cmp-models";
import { AppBar, Button, Divider, Skeleton, Stack, Tooltip, useTheme } from "@mui/material";
import { Box } from "@mui/system";

import doitLogo from "../../../../assets/doit-logo-hero-square.svg";
import { cloudflowTexts } from "../../../../assets/texts";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { EditTitleDialog } from "../../Dialog/EditTitleDialog";
import { useCloudflow, useHasActiveCloudflowExecutions } from "../../hooks";
import { type UpdateCloudflowNodes } from "../../types";
import { useModalManager } from "../Common/CloudflowModalsProvider";
import { useCloudflowOperations } from "../Common/CloudflowOperationsProvider";
import { useNodeEdgeManager } from "../Common/NodeEdgeManagerProvider";
import Title from "../Common/Title";
import { mapToUpdateNodePayload } from "../utils/nodeTransformUtils";
import ConfirmEditDialog from "./ConfirmEditDialog";
import ConfirmUnpublishDialog from "./ConfirmUnpublishDialog";
import { StatusChip } from "./StatusChip";

type Props = {
  flowTouched: boolean;
  cloudflowName: string;
  isPublished: boolean;
  cloudflowType?: CloudflowType;
};

const WorkflowActions = ({ isPublished, flowTouched, cloudflowName, cloudflowType }: Props) => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [confirmUnpublishOpen, setConfirmUnpublishOpen] = useState(false);
  const history = useHistory();
  const { hasActiveExecutions, executions } = useHasActiveCloudflowExecutions(flowId);
  const isPendingApproval = executions?.some((e) => e.status === CloudflowExecutionStatus.PENDING_APPROVAL);

  const { nodes, saveCloudFlow, publishCloudFlow } = useNodeEdgeManager();
  const { unpublishCloudflow, updateCloudflow, triggerCloudflow, httpOperationLoading } = useCloudflowOperations();

  const { customer } = useCustomerContext();

  const disabledStates = {
    edit: hasActiveExecutions || cloudflowType === "demo" || cloudflowType === "blueprint" || httpOperationLoading,
    run: hasActiveExecutions || cloudflowType === "demo" || cloudflowType === "blueprint" || httpOperationLoading,
    unpublish: httpOperationLoading || cloudflowType === "blueprint",
    publish: httpOperationLoading || cloudflowType === "blueprint",
    save: httpOperationLoading || !flowTouched || cloudflowType === "blueprint" || isPublished,
  };

  const onSaveCloudflow = useCallback(async () => {
    const updateNodesPayload: UpdateCloudflowNodes = {
      name: cloudflowName || "",
      flowId,
      updatedNodes: nodes
        .filter((n) => n.type !== CloudFlowNodeType.GHOST && n.data.touched)

        .map((n) => ({
          node: mapToUpdateNodePayload(n),
        }))
        .filter((n) => n.node),
    };
    await saveCloudFlow(customer.id, updateNodesPayload);
  }, [cloudflowName, customer.id, flowId, nodes, saveCloudFlow]);

  const onPublishCloudflow = useCallback(async () => {
    const unsavedNodes = nodes
      .filter((n) => n.type !== CloudFlowNodeType.GHOST && n.data.touched)
      .map((n) => ({
        node: mapToUpdateNodePayload(n),
      }))
      .filter((n) => n.node);

    const updateNodesPayload = unsavedNodes.length
      ? {
          name: cloudflowName || "",
          flowId,
          updatedNodes: unsavedNodes,
        }
      : undefined;

    await publishCloudFlow(customer.id, flowId, updateNodesPayload);
  }, [cloudflowName, customer.id, flowId, nodes, publishCloudFlow]);

  const onUnpublishCloudflow = useCallback(async () => {
    setConfirmUnpublishOpen(false);
    await unpublishCloudflow(customer.id, flowId);
  }, [customer.id, flowId, unpublishCloudflow]);

  const onEditCloudflow = useCallback(async () => {
    await updateCloudflow(customer.id, flowId, {
      status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
    });
    setConfirmEditOpen(false);
  }, [customer.id, flowId, updateCloudflow]);

  const onRunCloudflow = useCallback(async () => {
    await triggerCloudflow(customer.id, flowId);
  }, [customer.id, flowId, triggerCloudflow]);

  const onClose = useCallback(() => {
    history.push(`/customers/${customer.id}/cloudflow`);
  }, [customer.id, history]);

  return (
    <Stack direction="row" sx={{ gap: 1 }}>
      <ConfirmEditDialog
        isDialogOpened={confirmEditOpen}
        handleCloseDialog={() => {
          setConfirmEditOpen(false);
        }}
        handleEdit={onEditCloudflow}
      />
      <ConfirmUnpublishDialog
        isDialogOpened={confirmUnpublishOpen}
        handleCloseDialog={() => {
          setConfirmUnpublishOpen(false);
        }}
        isPendingApproval={isPendingApproval}
        handleUnpublish={onUnpublishCloudflow}
        hasActiveExecutions={hasActiveExecutions}
      />
      {isPublished ? (
        <>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setConfirmEditOpen(true);
            }}
            disabled={disabledStates.edit}
          >
            {cloudflowTexts.EDIT}
          </Button>
          <Tooltip
            title={
              hasActiveExecutions
                ? "This CloudFlow is currently executing and cannot be triggered again until the current execution is complete."
                : ""
            }
          >
            <Box>
              <Button
                variant="outlined"
                color="primary"
                onClick={onRunCloudflow}
                disabled={disabledStates.run}
                sx={{
                  height: "100%",
                }}
              >
                {cloudflowTexts.RUN}
              </Button>
            </Box>
          </Tooltip>
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              setConfirmUnpublishOpen(true);
            }}
            disabled={disabledStates.unpublish}
          >
            {cloudflowTexts.UNPUBLISH}
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" color="primary" onClick={onPublishCloudflow} disabled={disabledStates.publish}>
            {cloudflowTexts.PUBLISH}
          </Button>
          <Button variant="outlined" color="primary" onClick={onSaveCloudflow} disabled={disabledStates.save}>
            {cloudflowTexts.SAVE}
          </Button>
        </>
      )}
      <Button variant="text" onClick={onClose}>
        {cloudflowTexts.CLOSE}
      </Button>
    </Stack>
  );
};

const Topbar = () => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { customer } = useCustomerContext();
  const [status, setStatus] = useState<CloudflowCreationStatus | undefined>();
  const { cloudflow, cloudflowLoading } = useCloudflow(flowId);
  const { nodes } = useNodeEdgeManager();
  const { updateCloudflow } = useCloudflowOperations();
  const { isModalVisible } = useModalManager();
  const theme = useTheme();

  const isPublished = useMemo(() => status === CLOUD_FLOW_CREATION_STATUS.PUBLISHED, [status]);

  const flowTouched = useMemo(() => nodes.some((n) => n.data.touched), [nodes]);

  const handleUpdateTitle = useCallback(
    (title: string) => {
      if (!cloudflowLoading) {
        updateCloudflow(customer.id, flowId, { name: title });
      }
    },
    [cloudflowLoading, customer, flowId, updateCloudflow]
  );

  useEffect(() => {
    if (!cloudflowLoading && cloudflow?.data.status) {
      setStatus(cloudflow.data.status);
    }
  }, [cloudflow?.data.status, cloudflowLoading]);

  return (
    <AppBar elevation={0} sx={{ background: theme.palette.general.backgroundDefault }}>
      <Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            py: 1,
            px: 2,
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            sx={{
              alignItems: "center",
              gap: 0.5,
            }}
          >
            {cloudflowLoading ? (
              <>
                <Skeleton variant="circular" width={36} height={36} />
                <Skeleton variant="text" width={200} height={36} />
              </>
            ) : (
              <>
                <Box component="img" src={doitLogo} sx={{ height: "36px", width: "36px" }} />
                <Title
                  title={cloudflow?.data.name || ""}
                  placeholder="Add CloudFlow name"
                  disabled={cloudflowLoading || cloudflow?.data.type === "blueprint"}
                  titleType="cloudflow"
                  maxWidth={600}
                />
                <StatusChip status={status} />
                {isModalVisible("editCloudflowTitle") && (
                  <EditTitleDialog
                    title={cloudflow?.data.name || ""}
                    onConfirm={handleUpdateTitle}
                    header="Edit CloudFlow name"
                    dialogType="editCloudflowTitle"
                  />
                )}
              </>
            )}
          </Stack>
          {cloudflowLoading ? (
            <Skeleton variant="rectangular" width={120} height={36} />
          ) : (
            <WorkflowActions
              flowTouched={flowTouched}
              isPublished={isPublished}
              cloudflowName={cloudflow?.data.name || ""}
              cloudflowType={cloudflow?.data.type}
            />
          )}
        </Stack>
        <Divider sx={{ width: "100%" }} />
      </Stack>
    </AppBar>
  );
};

export default Topbar;
