import React, { useCallback, useMemo } from "react";

import { Box, Chip, FormControl, MenuItem, Select, type SelectChangeEvent, Stack } from "@mui/material";

import { FilterModeOption } from "../../Pages/CloudAnalytics/allocations/types";
import { type AttributionFilter, type MetadataOption } from "../../types";
import { DimensionSelector } from "../Selects/CloudAnalytics/DimensionSelector";
import AllocationRuleChips from "./AllocationRuleChips";

const PROPERTY_FIELD_WIDTH = 250;

type Props = {
  selectedDimension: MetadataOption | null;
  dimensions: MetadataOption[];
  disabled: boolean;
  filter?: AttributionFilter;
  excludeSelectMetadataIds?: Set<string>;
  label?: string;
  placeholder?: string;
  onEdit: () => void;
  onDimensionChanged: (option: MetadataOption | null) => void;
  onModeChanged: (rule: FilterModeOption, filter: AttributionFilter, option: MetadataOption | null) => void;
};

export default function DimensionSelectionRow({
  onDimensionChanged,
  selectedDimension,
  dimensions,
  disabled = false,
  onEdit,
  placeholder,
  filter,
  excludeSelectMetadataIds,
  label,
  onModeChanged,
}: Props) {
  const mode = useMemo(() => {
    if (filter?.regexp) {
      return filter.inverse ? FilterModeOption.DOES_NOT_MATCHES_REGEX : FilterModeOption.MATCHES_REGEX;
    }

    switch (filter?.mode) {
      case "is":
        return filter?.inverse ? FilterModeOption.IS_NOT : FilterModeOption.IS;
      case "contains":
        return filter?.inverse ? FilterModeOption.DOES_NOT_CONTAIN : FilterModeOption.CONTAINS;
      case "starts_with":
        return FilterModeOption.STARTS_WITH;
      case "ends_with":
        return FilterModeOption.ENDS_WITH;
      default:
        return FilterModeOption.IS;
    }
  }, [filter]);

  const isRegex = Boolean(filter?.regexp);

  const handleChange = useCallback(
    (event: SelectChangeEvent<FilterModeOption>) => {
      const newMode = event.target.value as FilterModeOption;
      onModeChanged(newMode, filter!, selectedDimension);
    },
    [filter, onModeChanged, selectedDimension]
  );

  const modeMenuItems = useMemo(
    () =>
      Object.values(FilterModeOption).map((mode) => (
        <MenuItem key={mode} value={mode}>
          {mode}
        </MenuItem>
      )),
    []
  );
  return (
    <Stack spacing={1.25} direction={{ xs: "column", md: "row" }} alignContent="center" sx={{ mb: 1 }}>
      <Box sx={{ width: { md: PROPERTY_FIELD_WIDTH } }}>
        <DimensionSelector
          onDimensionSelected={onDimensionChanged}
          selectedDimension={selectedDimension}
          dimensions={dimensions}
          placeholder="select"
          disabled={disabled}
          onEdit={onEdit}
          excludeSelectMetadataIds={excludeSelectMetadataIds}
          textFieldProps={{
            variant: "outlined",
            margin: "dense",
            size: "small",
            sx: ({ palette }) => ({
              margin: 0,
              backgroundColor: palette.general.backgroundDefault,
            }),
            label,
            placeholder,
          }}
        />
      </Box>
      {filter && (
        <>
          <FormControl size="small">
            <Select
              size="small"
              margin="dense"
              required
              disabled={disabled}
              value={mode}
              onChange={handleChange}
              sx={({ palette }) => ({
                backgroundColor: palette.general.backgroundDefault,
                maxWidth: { md: PROPERTY_FIELD_WIDTH },
              })}
            >
              {modeMenuItems}
            </Select>
          </FormControl>

          <Stack direction="row" gap={1} alignItems="center" justifyContent="flex-start" flexWrap="wrap">
            {isRegex ? (
              <Chip
                label={filter.regexp}
                size="small"
                color="primary"
                sx={{ maxWidth: filter.inverse ? 290 : 340 }}
                variant="outlined"
              />
            ) : (
              <AllocationRuleChips
                filter={filter}
                ruleFilterWidth={mode.length}
                nullFallback={null}
                title={selectedDimension?.data.label ?? ""}
              />
            )}
          </Stack>
        </>
      )}
    </Stack>
  );
}
