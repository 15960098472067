import React, { useEffect, useRef, useState } from "react";

import { type CurrencyCode, TimeInterval } from "@doitintl/cmp-models";
import { InputAdornment, Stack, TextField } from "@mui/material";
import { DateTime } from "luxon";

import { getSeasonalAmountsLength } from "../../../../Pages/CloudAnalytics/utilities";
import { getCurrencySymbol, onKeyPressPreventNonNumeric } from "../../../../utils/common";

interface PeriodPullOverProps {
  currency: CurrencyCode;
  disabled: boolean;
  seasonalAmounts?: number[];
  onAmountsChange: (amounts: number[]) => void;
  periodType: TimeInterval;
  startDate?: DateTime;
  initialSeasonalAmounts?: number[];
}

const getInitialPeriods = (periodType: TimeInterval, startDate?: DateTime) => {
  switch (periodType) {
    case TimeInterval.DAY: {
      const weekStart = DateTime.now().set({ weekday: 1 });
      return Array.from({ length: 7 }, (_, i) => {
        const dayName = weekStart.plus({ days: i }).toFormat("EEEE");
        return dayName;
      });
    }
    case TimeInterval.MONTH:
      return Array.from({ length: 12 }, (_, i) =>
        DateTime.now()
          .set({ month: i + 1 })
          .toFormat("MMMM")
      );
    case TimeInterval.QUARTER:
      return Array.from({ length: 4 }, (_, i) => `Q${i + 1}`);
    case TimeInterval.YEAR: {
      const startYear = startDate?.year ?? DateTime.now().year;
      return Array.from({ length: 5 }, (_, i) => String(startYear + i));
    }
    default:
      return [];
  }
};

const PeriodPullOver: React.FC<PeriodPullOverProps> = ({
  currency,
  disabled,
  seasonalAmounts,
  onAmountsChange,
  periodType,
  startDate,
  initialSeasonalAmounts,
}) => {
  const prevPeriodTypeRef = useRef(periodType);
  const periodNames = getInitialPeriods(periodType, startDate);
  const initialPeriods = periodNames.reduce(
    (acc, name, index) => ({
      ...acc,
      [name]: seasonalAmounts?.[index] ?? 0,
    }),
    {}
  );

  const [periods, setPeriods] = useState<{ [key: string]: number }>(initialPeriods);
  const prevSeasonalAmountsRef = useRef(seasonalAmounts);
  const isDirtyRef = useRef(false);

  useEffect(() => {
    if (prevPeriodTypeRef.current !== periodType) {
      const expectedLength = getSeasonalAmountsLength(periodType);
      if (!seasonalAmounts || seasonalAmounts.length !== expectedLength) {
        if (initialSeasonalAmounts && initialSeasonalAmounts.length === expectedLength) {
          onAmountsChange([...initialSeasonalAmounts]);
        } else {
          onAmountsChange(Array(expectedLength).fill(0));
        }
      }
      prevPeriodTypeRef.current = periodType;
    }
  }, [periodType, seasonalAmounts, onAmountsChange, initialSeasonalAmounts]);

  useEffect(() => {
    if (seasonalAmounts) {
      const prevAmounts = prevSeasonalAmountsRef.current;
      if (
        !prevAmounts ||
        prevAmounts.length !== seasonalAmounts.length ||
        prevAmounts.some((val, idx) => val !== seasonalAmounts[idx])
      ) {
        const updatedPeriods = Object.keys(periods).reduce(
          (acc, key, index) => ({
            ...acc,
            [key]: seasonalAmounts[index] ?? 0,
          }),
          {}
        );
        setPeriods(updatedPeriods);
        prevSeasonalAmountsRef.current = seasonalAmounts;
        isDirtyRef.current = false;
      }
    }
  }, [seasonalAmounts, periods]);

  const handlePeriodChange = (periodKey: string, value: string) => {
    const numericValue = value === "" ? 0 : parseFloat(value) || 0;
    if (periods[periodKey] !== numericValue) {
      isDirtyRef.current = true;
      const updatedPeriods = { ...periods, [periodKey]: numericValue };
      setPeriods(updatedPeriods);
    }
  };

  const handlePeriodBlur = () => {
    if (isDirtyRef.current) {
      const amountsArray = Object.values(periods);
      onAmountsChange(amountsArray);
      isDirtyRef.current = false;
    }
  };

  return (
    <Stack sx={{ gap: 3 }}>
      {periodNames.map((periodKey, index) => (
        <TextField
          key={`${periodType}NextPeriodPullOver${index}`}
          variant="outlined"
          type="number"
          fullWidth
          required
          disabled={disabled}
          label={periodKey}
          value={periods[periodKey] === 0 ? "" : periods[periodKey]}
          onChange={(e) => {
            handlePeriodChange(periodKey, e.target.value);
          }}
          onBlur={handlePeriodBlur}
          onKeyUp={onKeyPressPreventNonNumeric}
          slotProps={{
            input: {
              startAdornment: <InputAdornment position="start">{getCurrencySymbol(currency)}</InputAdornment>,
              inputProps: { min: "0" },
            },
          }}
        />
      ))}
    </Stack>
  );
};

export default PeriodPullOver;
