import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import type { AzureSubscription } from "../types";

export const itemsDescriptions: FilterTableItemDescriptor<AzureSubscription>[] = [
  {
    headerText: "Tenant ID",
    value: "tenantId",
  },
  {
    headerText: "Subscription Name",
    value: "displayName",
  },
  {
    headerText: "Subscription Id",
    value: "subscriptionId",
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
