import { useState } from "react";

import { type AssetType, type IssueCategoryType } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import capitalize from "lodash/capitalize";
import { type DateTime } from "luxon";

import { issueCategoryTypeToName } from "../../../../utils/common";

type Props = {
  open: boolean;
  handleClose: () => void;
  handleAction: (action: "recalculate" | "issue" | "copy-billing-tables") => void;
  isSubmitting: boolean;
  action?: "recalculate" | "issue" | "copy-billing-tables";
  selectedCustomer: {
    id: string;
    name: string;
  } | null;
  formData: {
    invoiceMonth: DateTime<true>;
    cloud?: AssetType | undefined;
    reason?: string | undefined;
    category?: IssueCategoryType;
    isReissue?: boolean;
  };
  hasIssuedInvoices?: boolean;
};

const InfoField = ({ label, value }: { label: string; value: string | undefined }) => (
  <Grid
    size={{
      xs: 12,
      sm: 6,
    }}
  >
    <Typography
      variant="subtitle1"
      sx={{
        fontWeight: "medium",
      }}
    >
      {label}:
    </Typography>
    <Typography variant="body1">{value || "N/A"}</Typography>
  </Grid>
);

export const ConfirmationDialog = ({
  open,
  handleClose,
  handleAction,
  isSubmitting,
  action,
  selectedCustomer,
  formData,
  hasIssuedInvoices,
}: Props) => {
  const [cancelCheckbox, setCancelCheckbox] = useState<boolean>(false);

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>{capitalize(action?.replaceAll("-", " "))} confirmation</DialogTitle>
      <DialogContent>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {action === "issue" && (
              <Alert severity="warning">
                Do not issue invoices on days when engineering are batch issuing invoices to customers
              </Alert>
            )}
            <InfoField label="Customer" value={selectedCustomer?.name} />
            {action !== "copy-billing-tables" && (
              <InfoField label="Month" value={formData.invoiceMonth?.toFormat("yyyy-MM")} />
            )}
            <InfoField label="Cloud" value={formData.cloud} />
            <InfoField label="Reason" value={formData.reason} />
            <InfoField label="Action" value={action} />
            {action === "issue" && (
              <>
                <InfoField
                  label="Category"
                  value={formData.category ? issueCategoryTypeToName(formData.category) : "-"}
                />
                <InfoField label="Is a reissue" value={formData.isReissue?.toString()} />
                {hasIssuedInvoices && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={cancelCheckbox}
                        onChange={(e) => {
                          setCancelCheckbox(e.target.checked);
                        }}
                        data-cy="cancellation-checkbox"
                      />
                    }
                    label="An issued invoice already exists. Check to cancel and proceed with re-issue"
                  />
                )}
              </>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ borderTop: 1, borderColor: "divider" }}>
        <Button onClick={handleClose}>Cancel</Button>
        {action && (
          <LoadingButton
            color="primary"
            variant="contained"
            loading={isSubmitting}
            onClick={() => {
              handleAction(action);
            }}
            autoFocus
            data-cy="confirm-btn"
            disabled={action === "issue" && hasIssuedInvoices && !cancelCheckbox}
          >
            Confirm
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  );
};
