import { type KubernetesFeature } from "@doitintl/cmp-models";
import { CircularProgress, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { useKubernetesFeatures } from "../../../../hooks";
import Fsk8sFeatureRow from "./Fsk8sFeatureRow";

interface Fsk8sFeatureSelectorProps {
  selectedFeatures: KubernetesFeature[];
  onSelect: (features: KubernetesFeature[]) => void;
}

const Fsk8sFeatureSelector = ({ onSelect, selectedFeatures }: Fsk8sFeatureSelectorProps) => {
  const [features, featuresAreLoading] = useKubernetesFeatures();

  const selectedKeys = selectedFeatures.map((f) => f.id);

  const handleToggleFeature = (featureId: string) => {
    if (!features) return;

    const newSelectedFeatures: KubernetesFeature[] = selectedKeys.includes(featureId)
      ? selectedFeatures.filter((f) => f.id !== featureId)
      : [...selectedFeatures, features.find((f) => f.id === featureId)!];

    onSelect(newSelectedFeatures);
  };

  if (featuresAreLoading) {
    return <CircularProgress />;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={15} />
          <TableCell>Available features</TableCell>
          <TableCell align="right" padding="checkbox">
            Include
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {features?.map((feature) => (
          <Fsk8sFeatureRow
            key={feature.id}
            selectedFeatures={selectedFeatures}
            feature={feature}
            onToggleFeature={handleToggleFeature}
          />
        ))}
      </TableBody>
    </Table>
  );
};

export default Fsk8sFeatureSelector;
