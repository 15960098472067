import { useCallback } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import { httpMethods } from "../../../assets/texts";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";

export const useMetadata = () => {
  const { customer } = useCustomerContext({ allowNull: true });
  const api = useApiContext();

  const customerId = customer?.id || "no-customer-id";
  const [customerMetadata, loadingMetadataInfo, MetadataError] = useDocumentDataOnce(
    getCollection(AppModel).doc("ava").collection("customersMetadata").doc(customerId)
  );

  const updateCustomerMetadata = useCallback(async () => {
    if (!customer || loadingMetadataInfo) {
      return;
    }

    if (
      !MetadataError &&
      customerMetadata?.lastUpdated &&
      Math.abs(DateTime.fromJSDate(customerMetadata.lastUpdated.toDate()).diffNow("days").days) < 30
    ) {
      return;
    }

    try {
      await api.request({
        method: httpMethods.POST,
        url: `/v1/customers/${customer.id}/ava/customer-metadata`,
        data: { customerId: customer.id },
      });
    } catch (error) {
      consoleErrorWithSentry(error);
    }
  }, [customer, loadingMetadataInfo, customerMetadata, MetadataError, api]);

  return { updateCustomerMetadata };
};

export const useFetcher = () => {
  const { token } = useAuthContext({ mustHaveUser: true });

  const fetcher = useCallback(
    (data: unknown) =>
      fetch("/api/ava/ask", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token?.token}`,
        },
        body: JSON.stringify(data),
      }),
    [token?.token]
  );

  return fetcher;
};
