import { Link } from "react-router-dom";
import { TableCell, Typography } from "@mui/material";

import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { capitalizeStartCase } from "../../../../utils/common";
import { type AzureSubscription } from "../types";

type Props = {
  row: AzureSubscription;
};

export const RowComponent = ({ row }: Props) => {
  const { customer } = useCustomerContext();
  const editPath = `/customers/${customer.id}/settings/azure/connect/${row.subscriptionId}`;
  return (
    <CellsWrapper>
      <TableCell>{capitalizeStartCase(row.tenantId)}</TableCell>
      <TableCell data-cy="org-cell">
        <Typography component={Link} to={editPath} color="inherit" variant="body2">
          {row.displayName}
        </Typography>
      </TableCell>
      <TableCell>{capitalizeStartCase(row.subscriptionId)}</TableCell>
    </CellsWrapper>
  );
};
