import { useEffect, useMemo, useState } from "react";

import {
  AssetModelBillingAnomalyModel,
  CloudAnalyticsModel,
  type CurrencyCode,
  CurrencyCodes,
  CustomerModel,
  UserPermissions,
} from "@doitintl/cmp-models";
import {
  Filter,
  getCollection,
  getCollectionGroup,
  useCollectionData,
  useDocumentData,
} from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { httpMethods } from "../../assets/texts";
import { type CardConfig, CardID, CardTitle, TrendType } from "../../Components/Dashboard/CloudSpendSummaryCards";
import { asyncConvertCurrencyTo } from "../../Context/AsyncCurrencyConverterContext";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";
import { getCurrencySymbol, partnerOrgsMap } from "../../utils/common";
import { arrayRemove, arrayUnion } from "../../utils/firebase";
import { useDateTime } from "../../utils/timeHooks";
import { getWidgetDocId } from "../../utils/widgets";
import { getCostOfAnomaly } from "../AnomalyDetection/AlertTools";
import { getAnomalyStartTime } from "../AnomalyDetection/utils";
import { asyncCalculateTrend, asyncSumCustomersMonthSpend, getCustomerCurrentMonthSpend } from "../Customers/db";

const thisMonthVsLast = "ViSpueEq1iXNMcL1n6Un"; // This month Vs Last month report ID

function emptyCard(title: CardTitle, code: CurrencyCode = CurrencyCodes.USD): CardConfig {
  return {
    id: CardID.Forecast,
    trend: 0,
    number: 0,
    text: title,
    monthOffset: 0,
    prefix: getCurrencySymbol(code),
  };
}

const useCustomerForecastCloudReport = (): CardConfig & { currentSpend?: number } => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const { partnerCompany } = useAuthContext({ mustHaveUser: true });

  const cloudAnalytics = getCollection(CloudAnalyticsModel).doc("widgets").collection("cloudAnalyticsWidgets");
  const docId = getWidgetDocId(customer.id, thisMonthVsLast, partnerCompany ? partnerOrgsMap[partnerCompany] : null);
  const [forecastModelData, forecastDataLoading] = useDocumentData(cloudAnalytics.doc(docId));
  const [data, setData] = useState<CardConfig & { currentSpend?: number }>(emptyCard(CardTitle.Forecast));

  const { startOfDay } = useDateTime();
  const beforeTwoDays = useMemo(() => startOfDay.minus({ days: 1 }), [startOfDay]);

  useEffect(() => {
    if (!forecastDataLoading && !forecastModelData?.data) {
      setData(emptyCard(CardTitle.Forecast));
      return;
    }

    if (!forecastModelData?.data) {
      return;
    }

    const rows = Object.entries(forecastModelData.data.forecastRows).map((r) => r[1]) as [
      feature: string,
      year: string,
      month: string,
      val: number,
    ][];

    const forecastArr = rows.filter((row: any) => {
      if (beforeTwoDays.month === parseInt(row[2]) && beforeTwoDays.year === parseInt(row[1])) {
        // current month
        return true;
      }
      if (
        beforeTwoDays.plus({ month: 1 }).month === parseInt(row[2]) &&
        beforeTwoDays.plus({ month: 1 }).year === parseInt(row[1])
      ) {
        // next month
        return true;
      }
    });

    // get current month spend from Historical Costs & Forecast report
    const currentSpend = Object.values(forecastModelData.data.rows).reduce((acc, row) => {
      if (row[1] === beforeTwoDays.year.toString() && parseInt(row[2]) === beforeTwoDays.month) {
        return acc + row[3];
      }
      return acc;
    }, 0);

    if (forecastArr.length <= 1) {
      setData({ ...emptyCard(CardTitle.Forecast, forecastModelData.config.currency), currentSpend });
      return;
    }

    const currentForecast: number = forecastArr[0][3];
    const nextMonthForecast: number = forecastArr[1][3];
    const trend = ((nextMonthForecast - currentForecast) / currentForecast) * 100;

    setData({
      trend,
      trendDirection: trend !== null && trend >= 0 ? TrendType.Up : TrendType.Down,
      number: currentForecast,
      text: CardTitle.Forecast,
      monthOffset: nextMonthForecast - currentForecast,
      prefix: getCurrencySymbol(forecastModelData.config.currency),
      id: CardID.Forecast,
      currentSpend,
    });
  }, [beforeTwoDays, forecastDataLoading, forecastModelData?.config.currency, forecastModelData?.data]);

  return data;
};

const useCustomersMonthVsLastReport = () => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const { partnerCompany } = useAuthContext({ mustHaveUser: true });
  const docRef = getCustomerCurrentMonthSpend(customer.id, partnerCompany);
  return useDocumentData(docRef);
};

export const useCurrentMonthCloudSpend = (): CardConfig => {
  const [report] = useCustomersMonthVsLastReport();
  const [data, setData] = useState<CardConfig>(emptyCard(CardTitle.MonthSpend));

  useEffect(() => {
    if (!report?.data) {
      setData(emptyCard(CardTitle.MonthSpend));
      return;
    }

    Promise.all([
      asyncCalculateTrend(report),
      asyncSumCustomersMonthSpend(report, { lastMonth: false, countAllDays: true }),
      asyncSumCustomersMonthSpend(report, { lastMonth: true }),
    ]).then(([trend, currentMonth, lastMonth]) => {
      setData({
        trend,
        trendDirection: trend !== null && trend >= 0 ? TrendType.Up : TrendType.Down,
        number: currentMonth ?? 0,
        text: CardTitle.MonthSpend,
        monthOffset: (currentMonth ?? 0) - (lastMonth ?? 0),
        prefix: getCurrencySymbol(report.config.currency),
        id: CardID.MonthSpend,
      });
    });
  }, [report]);

  return data;
};

const asyncReduce = async <T, U>(
  array: T[],
  asyncCallback: (accumulator: U, item: T, index: number, array: T[]) => Promise<U>,
  initialValue: U
): Promise<U> => {
  let accumulator: U = initialValue;

  for (let index = 0; index < array.length; index++) {
    accumulator = await asyncCallback(accumulator, array[index], index, array);
  }

  return accumulator;
};

const useCurrentAnomalySpend = () => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const { startOfDay } = useDateTime();

  const { isDoitPartner } = useAuthContext({ mustHaveUser: true });
  const [anomalyDocs] = useCollectionData(
    getCollectionGroup(AssetModelBillingAnomalyModel)
      .where("customer", "==", customer.ref)
      .where("metadata.timestamp", ">=", startOfDay.minus({ days: 60 }).toJSDate())
      .where(
        Filter.or(
          Filter.where<AssetModelBillingAnomalyModel>()("metadata.severity", "==", 2),
          Filter.where<AssetModelBillingAnomalyModel>()("metadata.severity", "==", 3)
        )
      )
      .orderBy("metadata.timestamp", "desc")
  );

  const [data, setData] = useState<CardConfig>(emptyCard(CardTitle.Anomaly));

  useEffect(() => {
    if (isDoitPartner) {
      setData(emptyCard(CardTitle.Anomaly));
      return;
    }

    if (!anomalyDocs) {
      return;
    }

    asyncReduce(
      anomalyDocs,
      async (acc, anomaly) => {
        const costAnomaly = await asyncConvertCurrencyTo(
          getCostOfAnomaly(anomaly),
          anomaly.timestamp,
          CurrencyCodes.USD,
          (customer.settings?.currency as CurrencyCodes) ?? CurrencyCodes.USD
        );
        const startTime = getAnomalyStartTime(anomaly.metadata);

        if (startTime >= startOfDay.minus({ days: 30 })) {
          // last 30 days
          acc.currentSumAnomaly += costAnomaly;
        } else {
          // last 60 days
          acc.before30Days += costAnomaly;
        }

        return acc;
      },
      { currentSumAnomaly: 0, before30Days: 0 }
    ).then(({ before30Days, currentSumAnomaly }) => {
      const trend = before30Days !== 0 ? ((currentSumAnomaly - before30Days) / before30Days) * 100 : 0;
      setData({
        trend: isNaN(trend) ? 0 : trend,
        trendDirection: trend !== null && trend >= 0 ? TrendType.Up : TrendType.Down,
        number: currentSumAnomaly,
        text: CardTitle.Anomaly,
        monthOffset: currentSumAnomaly - before30Days,
        prefix: getCurrencySymbol(customer.settings?.currency ?? CurrencyCodes.USD),
        id: CardID.Anomaly,
      });
    });
  }, [anomalyDocs, customer.settings?.currency, isDoitPartner, startOfDay, customer.ref]);

  return data;
};

export const useCloudSpendSummaryData = () => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  const forecastReport = useCustomerForecastCloudReport();
  const anomalyReport = useCurrentAnomalySpend();
  const currentMonthReport = useCurrentMonthCloudSpend();
  return useMemo(() => {
    if (!userRoles.permissions.has(UserPermissions.CloudAnalyticsUser) && !isDoitEmployee) {
      return;
    }

    return [currentMonthReport, forecastReport, anomalyReport];
  }, [anomalyReport, currentMonthReport, forecastReport, isDoitEmployee, userRoles.permissions]);
};

export const handleUnsubscribe = (customerId: string, email: string) =>
  getCollection(CustomerModel)
    .doc(customerId)
    .update({
      subscribers: arrayRemove(email),
    });

export const handleSubscribe = (customerId: string, email: string) =>
  getCollection(CustomerModel)
    .doc(customerId)
    .update({
      subscribers: arrayUnion(email),
    });

export const updateCustomerWidgets = async (api: AxiosInstance, customerId: string, isPrioritized: boolean) => {
  let url = `/v1/customers/${customerId}/analytics/widgets`;
  if (isPrioritized) {
    url += "?isPrioritized=immediate";
  }
  await api.request({
    method: httpMethods.POST,
    url,
  });
};

export const updateVisibleDashboards = async (api: AxiosInstance, customerId: string, dashboardPaths: string[]) => {
  const body = {
    customerId,
    dashboardPaths,
  };

  await api.request({
    method: httpMethods.POST,
    url: `/v1/customers/${customerId}/analytics/widgets/dashboards`,
    data: body,
  });
};
