import { useState } from "react";

import { useTheme } from "@mui/material";

export const useRowHighlighting = () => {
  const theme = useTheme();
  const [isHovered, setIsHovered] = useState(false);

  const highlightColor = theme.palette.general.backgroundDark;

  return {
    onMouseEnter: () => {
      setIsHovered(true);
    },
    onMouseLeave: () => {
      setIsHovered(false);
    },
    sx: {
      backgroundColor: isHovered ? highlightColor : undefined,
    },
  };
};
