import { type JSX, Suspense, useEffect, useMemo } from "react";

import { TierPackageTypes } from "@doitintl/cmp-models";
import { Box, StyledEngineProvider } from "@mui/material";
import * as Sentry from "@sentry/react";

import { AVADialog } from "../../Components/Ava/Messenger/AVADialog";
import { AvaOpeningSource, useAvaDialogContext } from "../../Components/Ava2/AvaDialogContextProvider";
import { AvaRoundButton } from "../../Components/Ava2/AvaRoundButton";
import ErrorBoundaryFallback from "../../Components/ErrorBoundaryFallback";
import { useHideLoadingAnimation } from "../../Components/hooks/useHideLoadingAnimation";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useFirestoreContext } from "../../Context/FirestoreContext";
import { useTier } from "../../Context/TierProvider";
import { useI18NUserLanguage } from "../../locales/i18n";
import { CustomerNotes } from "../../Pages/Customer/CustomerNotes/CustomerNotes";
import { LanguageAlert } from "../../Support/Components/LanguageAlert";
import { useFullScreen } from "../../utils/dialog";
import { getCurrentCategories } from "../core/categories";
import { Header } from "./Header/Header";
import { RealTimeNotifications } from "./Header/InAppNotifications/RealTimeNotifications";
import KeyboardShortcuts from "./Header/KeyboardShortcuts/KeyboardShortcuts";
import { ClientSuspendedOrTerminatedAlert } from "./HeaderAlerts/ClientSuspendedAlert";
import { EKSAlert } from "./HeaderAlerts/EKSAlert";
import GettingStartedAlert from "./HeaderAlerts/GettingStartedAlert";
import { LegacySsoAlert } from "./HeaderAlerts/LegacySsoAlert";
import { PayOverdueAlert } from "./HeaderAlerts/PayOverdueAlert";
import { ScheduledPriorityMaintenanceAlert } from "./HeaderAlerts/ScheduledMaintenanceAlert";
import TierTrialEndAlert from "./HeaderAlerts/TrialEnd/TierTrialEndAlert";
import { TrialEndAlert } from "./HeaderAlerts/TrialEnd/TrialEndAlert";
import {
  useCurrentPageIdInfo,
  useLoginContext,
  useNavConfig,
  useOpenDialogIfHasAvaParams,
  useRoutesConfig,
} from "./hooks";
import NavTabs from "./NavTabs/NavTabs";
import { PageContent } from "./PageContent";
import { SideNavigationLayout } from "./ThirdLevelNav/SideNavigationLayout";
import { thirdLevelItemsFromCategory } from "./ThirdLevelNav/utils";
import { useNavigationVariant } from "./variantHooks";

type Props = {
  onSignOut: any;
  children: JSX.Element;
};

export const MainLayout = ({ onSignOut, children }: Props) => {
  useHideLoadingAnimation();
  const { firestoreInitialized } = useFirestoreContext();
  const loginContext = useLoginContext();
  const routesConfig = useRoutesConfig();
  useI18NUserLanguage();
  const currentPageInfo = useCurrentPageIdInfo(routesConfig);
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext();
  const showEKSAlert = false;
  const { isActiveTrialCustomer } = useTier();
  const { isMobile } = useFullScreen();
  useOpenDialogIfHasAvaParams();
  useEffect(() => {}, [
    firestoreInitialized,
    loginContext,
    currentPageInfo,
    customer,
    customerOrPresentationModeCustomer,
    isDoitEmployee,
    showEKSAlert,
    isActiveTrialCustomer,
    isMobile,
  ]);

  // decide about the variant using context and screen resolution
  const navigationVariant = useNavigationVariant();

  const { navCategories } = useNavConfig();
  const categories = useMemo(() => {
    if (routesConfig) {
      return getCurrentCategories(routesConfig, navCategories, loginContext, currentPageInfo?.pageId);
    }
  }, [routesConfig, navCategories, loginContext, currentPageInfo?.pageId]);

  const firstLevelCategory = useMemo(() => {
    if (categories) {
      return categories.firstLevel.find((category) => category.isSelected);
    }
  }, [categories]);

  const thirdLevelData = useMemo(() => {
    if (categories?.secondLevel && currentPageInfo?.pageId) {
      return thirdLevelItemsFromCategory(currentPageInfo?.pageId, categories.secondLevel);
    }
  }, [categories, currentPageInfo?.pageId]);

  // for MVP we will have different background color for dashboards
  const backgroundColor = useMemo(() => {
    const specialPages = new Map([["home-page", "general.backgroundDark"]]);
    const color = specialPages.get(`${currentPageInfo?.pageId}`);
    if (color) {
      return color;
    }

    if (firstLevelCategory?.displayName === "Dashboards") {
      return "general.backgroundDark";
    }
    return "background.paper";
  }, [currentPageInfo?.pageId, firstLevelCategory?.displayName]);

  const presentationModeActive = !!customer?.presentationMode?.enabled && !!currentPageInfo?.presentationModeSupported;
  const { isAvaDialogOpen, isAvaDialogMinimized, handleOpenAvaDialog } = useAvaDialogContext();

  if (!firestoreInitialized) {
    return null;
  }

  const customerOnNavigatorTrial = isActiveTrialCustomer(TierPackageTypes.NAVIGATOR);
  const customerOnSolveTrial = isActiveTrialCustomer(TierPackageTypes.SOLVE);

  return (
    <Box
      id="main-layout"
      sx={{
        minHeight: "100vh",
        bgcolor: backgroundColor,
        pb: 10,
      }}
    >
      {loginContext && categories && (
        <>
          <ClientSuspendedOrTerminatedAlert />
          <ScheduledPriorityMaintenanceAlert hide={navigationVariant === "small"} />
          <PayOverdueAlert hide={navigationVariant === "small"} pageId={currentPageInfo?.pageId} />
          <GettingStartedAlert />
          <LanguageAlert />
          <EKSAlert hide={!showEKSAlert || navigationVariant === "small"} />
          <LegacySsoAlert hide={navigationVariant === "small"} pageId={currentPageInfo?.pageId} />
          {loginContext.customerState?.trialEndDate && (
            <TrialEndAlert
              trialEndDate={loginContext.customerState.trialEndDate}
              showShortMessage={navigationVariant === "small"}
            />
          )}
          {customer && customerOrPresentationModeCustomer && <RealTimeNotifications />}

          {(customerOnNavigatorTrial || customerOnSolveTrial) && (
            <TierTrialEndAlert
              navigatorTrialStartDate={
                customerOnNavigatorTrial ? loginContext.customerState?.navigatorTierTrialStartDate : undefined
              }
              navigatorTrialEndDate={
                customerOnNavigatorTrial ? loginContext.customerState?.navigatorTierTrialEndDate : undefined
              }
              solveTrialStartDate={
                customerOnSolveTrial ? loginContext.customerState?.solveTierTrialStartDate : undefined
              }
              solveTrialEndDate={customerOnSolveTrial ? loginContext.customerState?.solveTierTrialEndDate : undefined}
            />
          )}
          <StyledEngineProvider injectFirst>
            {isAvaDialogOpen && <AVADialog />}
            {(!isAvaDialogOpen || isAvaDialogMinimized) && (
              <AvaRoundButton
                handleOpen={() => {
                  handleOpenAvaDialog(AvaOpeningSource.FLOATING_BUTTON);
                }}
              />
            )}
            <Header
              presentationModeActive={presentationModeActive}
              variant={navigationVariant}
              categoriesContext={categories.firstLevel}
              onSignOut={onSignOut}
            >
              {categories && firstLevelCategory && (
                <StyledEngineProvider injectFirst>
                  <NavTabs
                    presentationModeActive={presentationModeActive}
                    isMobile={navigationVariant === "small" || navigationVariant === "smallWithOptions"}
                    isTrialMode={Boolean(loginContext.customerState?.trialEndDate)}
                    categoryContext={firstLevelCategory}
                  />
                </StyledEngineProvider>
              )}
            </Header>
            <KeyboardShortcuts />
          </StyledEngineProvider>
        </>
      )}
      <Box
        sx={{
          pt: 1,
          px: 2,
        }}
      >
        <Sentry.ErrorBoundary
          fallback={({ error }: { error: any }) => {
            const shouldShowError = loginContext?.isDoitEmployee || !process.env.REACT_APP_SENTRY_RELEASE;
            return <ErrorBoundaryFallback error={error} shouldShowError={shouldShowError} />;
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                width: "100%",
              }}
            >
              <SideNavigationLayout data={thirdLevelData} title={categories?.secondLevel?.displayName}>
                <Suspense>
                  <PageContent loginContext={loginContext} routesConfig={routesConfig}>
                    {children}
                  </PageContent>
                </Suspense>
              </SideNavigationLayout>
            </Box>
            {isDoitEmployee && customer && customerOrPresentationModeCustomer && <CustomerNotes customer={customer} />}
          </Box>
        </Sentry.ErrorBoundary>
      </Box>
    </Box>
  );
};
