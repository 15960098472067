import StatusChipWithTooltip, { type StatusChipWithTooltipProps } from "./Components/StatusChipWithTooltip";
import { ClusterAgentStatus, type ClusterTableItem } from "./types";

interface AgentStatusProps {
  cluster: ClusterTableItem;
}

const AgentStatus = ({ cluster }: AgentStatusProps) => {
  const statusConfigs: Record<ClusterAgentStatus, StatusChipWithTooltipProps> = {
    [ClusterAgentStatus.INSTALLED]: {
      color: "success",
      label: "Installed",
    },
    [ClusterAgentStatus.UNKNOWN]: {
      color: "error",
      label: "Unknown",
      tooltipContent: "We stopped receiving data from this cluster",
    },
    [ClusterAgentStatus.NOT_INSTALLED]: {
      color: "default",
      label: "Not installed",
      disabled: true,
      tooltipContent: "Agent is not installed on this cluster",
    },
    [ClusterAgentStatus.UPGRADE_AVAILABLE]: {
      color: "warning",
      label: "Upgrade available",
      tooltipContent: "An new version of the agent is available",
    },
  };

  const statusConfig = statusConfigs[cluster.agentStatus];

  if (!statusConfig) {
    return null;
  }

  return <StatusChipWithTooltip {...statusConfig} />;
};

export default AgentStatus;
