import { useCallback, useEffect, useRef, useState } from "react";

import ReactQuill from "react-quill";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { CircularProgress } from "@mui/material";
import { ThemeModes } from "../../../muiThemeTypes";
import "./Editor.css";

import { useFullScreen } from "../../../utils/dialog";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../utils";
import { getModules } from "./EditorModules";
import DarkModeString from "./EditorThemes/dark-mode";
import DarkModeBubbleString from "./EditorThemes/dark-mode-bubble";

const MOBILE = "mobile";
const DESKTOP = "desktop";

const useStyles = makeStyles(() => ({
  fullscreen: {
    height: "100% !important",
    transition: "height 300ms",
    transitionTimingFunction: "ease-out",
  },
  bubbleMode: {
    border: "1px solid",
    borderColor: "#cccccc",
    borderRadius: 4,
  },
}));

export const addTheme = (id) => {
  const sheet = document.createElement("style");
  sheet.setAttribute("type", "text/css");
  sheet.setAttribute("id", id);
  sheet.innerHTML = id === "dark-mode" ? DarkModeString : DarkModeBubbleString;
  document.body.appendChild(sheet);
};
export const removeTheme = (id) => {
  const sheetToBeRemoved = document.getElementById(id);
  if (sheetToBeRemoved) {
    const sheetParent = sheetToBeRemoved.parentNode;
    sheetParent?.removeChild(sheetToBeRemoved);
  }
};

/**
 * @param {*} props - props for the editor component. whenever supplying a bucket, make sure the bucket is already created in firebase cloud storage.
 * if bucket is not supplied, the editor will save images as embedded based 64 img elements.
 */
function Editor(props) {
  const { bucketRef, isLoadingBucket } = props;
  const theme = useTheme();
  const classes = useStyles();
  const handle = useFullScreenHandle();
  const editorRef = useRef(null);
  const editorRefMobile = useRef(null);
  const { isMobile: smDown } = useFullScreen();
  const showErr = useErrorSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  const [modules, setModules] = useState(null);

  const updateEditor = useCallback(
    (imageDataUrl) => {
      const quill = editorRef.current?.getEditor();
      let index = (quill.getSelection() ?? {}).index;
      if (index === undefined || index < 0) {
        index = quill.getLength();
      }
      quill.insertEmbed(index, "image", imageDataUrl, "user");
    },
    [editorRef]
  );

  const saveImgToBucket = useCallback(
    async (file) => {
      try {
        if (!bucketRef) {
          throw new Error("No bucket reference");
        }
        const imageExt = file.name.split(".").pop();
        const fileName = `${props.resourceName || ""}-${Date.now()}.${imageExt}`;
        const fileRef = bucketRef.child(fileName);
        const snapshot = await fileRef.put(file);
        const downloadURL = await snapshot.ref.getDownloadURL();
        return downloadURL;
      } catch (err) {
        showErr(err.message);
        consoleErrorWithSentry(err);
      }
    },
    [bucketRef, showErr, props.resourceName]
  );

  const fullscreenEvent = useCallback(async () => {
    const isOpen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement;
    if (!handle.active && !isOpen) {
      handle.enter();
    } else {
      handle.exit();
    }
  }, [handle]);

  useEffect(() => {
    if (modules) {
      return;
    }
    if (isLoadingBucket) {
      return;
    }
    const modulesArgs = { fullscreenEvent };

    if (bucketRef) {
      modulesArgs.uploadImgHandler = saveImgToBucket;
    }
    const modulesToSet = getModules(modulesArgs);
    if (!bucketRef) {
      modulesToSet.imageDropAndPaste.handler = updateEditor;
    }
    setModules(modulesToSet);
  }, [bucketRef, isLoadingBucket, saveImgToBucket, fullscreenEvent, modules, updateEditor]);

  useEffect(() => {
    if (modules) {
      setIsLoading(false);
    }
  }, [modules]);

  useEffect(() => {
    if (theme.palette.mode === ThemeModes.DARK) {
      if (smDown) {
        addTheme("dark-mode-bubble");
      } else {
        addTheme("dark-mode");
      }
    } else {
      removeTheme("dark-mode");
      removeTheme("dark-mode-bubble");
    }
  }, [smDown, theme.palette.mode]);

  const getPropsForEditor = (type) => {
    if (smDown && type === MOBILE) {
      return props;
    }
    if (!smDown && type === DESKTOP) {
      return props;
    }
    return {};
  };
  const handleChangeFullscreen = () => {};

  const themeClass = handle.active ? classes.fullscreen : "";

  return isLoading ? (
    <CircularProgress color="inherit" size={20} />
  ) : (
    <div>
      <FullScreen onChange={handleChangeFullscreen} handle={handle}>
        <div className={themeClass} style={{ display: smDown ? "none" : "block" }}>
          <ReactQuill
            ref={(el) => {
              editorRef.current = el;
            }}
            modules={modules}
            theme="snow"
            className={handle.active ? classes.fullscreen : ""}
            {...getPropsForEditor(DESKTOP)}
          />
        </div>

        <div style={{ display: !smDown ? "none" : "block" }}>
          <ReactQuill
            ref={(el) => {
              editorRefMobile.current = el;
            }}
            theme="bubble"
            modules={modules}
            className={classes.bubbleMode}
            bounds=".app"
            {...getPropsForEditor(MOBILE)}
          />
        </div>
      </FullScreen>
    </div>
  );
}

export default Editor;
