import { useCallback, useEffect, useMemo, useState } from "react";

import { useParams } from "react-router";
import { type AttributionWRef, type MetadataOption } from "@client/src/types";
import { Metadata } from "@doitintl/cmp-models";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Alert, Button } from "@mui/material";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { allocationTxt } from "../../../../../assets/texts/CloudAnalytics/allocation";
import { ExpandMore } from "../../../../../Components/ExpendMore";
import { useAttributionGroups } from "../../../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { useAuthContext } from "../../../../../Context/AuthContext";
import useSegmentTrackEvent from "../../../../../utils/useSegmentTrackEvent";
import { isOwner } from "../../../utilities";
import useAllocationDndStore from "../../hooks/useAllocationDnd";
import { formStoreManager } from "../../hooks/useAllocationFormState";
import useDimensionsSelection from "../../hooks/useDimensionsSelection";
import { trackAllocationEvent } from "../../utils";
import { AllocationDraggableHolder } from "../AllocationDraggableHolder";
import ActionButtons from "./ActionButtons";
import AllocationFormulaField from "./AllocationFormulaField";
import AllocationNameField from "./AllocationNameField";

const CONTENT_PADDING_LEFT = "46px";

type Props = {
  attributionNumber?: number;
  grabbing?: boolean;
  initialAttributionID: string;
  dimensions: MetadataOption[];
  disabled?: boolean;
};

export function AllocationDraggableItem({
  attributionNumber,
  grabbing,
  initialAttributionID,
  dimensions,
  disabled = false,
}: Props) {
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { allocationId } = useParams<{ allocationId: string }>();
  const { removeItem, addItem, items, hasUnallocatedCosts, isAllocationOwner, isGroup } = useAllocationDndStore();
  const [expanded, setExpanded] = useState(true);
  const [attributionGroups] = useAttributionGroups();
  const [first] = items.main;
  const isFirst = first === initialAttributionID;

  const { trackEvent } = useSegmentTrackEvent();
  const formStore = formStoreManager.getStore(initialAttributionID);

  const {
    values,
    setName,
    setFilterFields,
    setViewType,
    setFormula,
    setEditWarnings,
    viewType,
    setSelectedExistingRule,
    selectedExistingRule,
    isDirty,
    isEditMode,
    validateForm,
  } = formStore();

  const usedInAttributionGroup = useMemo(
    () =>
      attributionGroups.filter(
        (group) =>
          group.ref.id !== allocationId &&
          group.data?.attributions?.some((attribution) => attribution.id === selectedExistingRule?.ref.id)
      ),
    [allocationId, attributionGroups, selectedExistingRule]
  );

  const displayNumber = useMemo(
    () => (attributionNumber !== undefined ? attributionNumber + 1 : undefined),
    [attributionNumber]
  );

  const isRuleOwner = useMemo(
    () =>
      selectedExistingRule &&
      isOwner(currentUser.email, {
        collaborators: selectedExistingRule?.data?.collaborators || [],
      }),
    [currentUser.email, selectedExistingRule]
  );

  const canRemove = useMemo(() => items.main?.length > 1, [items.main]);
  const isSingleRule = useMemo(() => items.main?.length === 1 && isEditMode, [items.main, isEditMode]);
  const canDuplicate = useMemo(
    () => (!!values.name && !!values.formula && !!values.filterFields?.length) || !!selectedExistingRule,
    [selectedExistingRule, values.filterFields?.length, values.formula, values.name]
  );

  const filteredDimensions = useMemo(() => {
    if (!dimensions?.length) return [];

    const filtered = dimensions.slice();

    return filtered.filter((d) => {
      if (d.data.type === Metadata.DATETIME) {
        d._visible = false;
        return true;
      }
      return d.data.type !== Metadata.ATTRIBUTION;
    });
  }, [dimensions]);

  const { DimensionsSelection, filters, setFilters, setDisabled } = useDimensionsSelection({
    isEditMode,
    initialFilters: values.filterFields ?? [],
    dimensions: filteredDimensions,
    excludeSelectMetadataIds: undefined,
  });

  useEffect(() => {
    setDisabled(disabled);
  }, [disabled, setDisabled]);

  const handleExpandClick = useCallback((e) => {
    e.preventDefault();
    setExpanded((prev) => !prev);
  }, []);

  const onRemoveRule = useCallback(() => {
    trackAllocationEvent({
      trackEvent,
      eventName: "Allocation Rule Deleted",
      ruleCount: items.main?.length || 0,
      hasUnallocatedCosts,
      allocationId,
      currentUserEmail: currentUser.email,
      collaborators: selectedExistingRule?.data.collaborators,
    });
    removeItem(initialAttributionID);
    formStoreManager.removeStore(initialAttributionID);
  }, [
    selectedExistingRule,
    initialAttributionID,
    trackEvent,
    allocationId,
    items.main?.length,
    hasUnallocatedCosts,
    currentUser.email,
    removeItem,
  ]);

  const handleAllocationNameChanged = useCallback(
    (event) => {
      setName(event.target.value as string);
    },
    [setName]
  );

  const handleSelectExistingAllocation = useCallback(
    (value: AttributionWRef | null) => {
      if (!value) {
        setSelectedExistingRule(null);
        setFilters([]);
        return;
      }

      const disabled = !isOwner(currentUser.email, { collaborators: value?.data?.collaborators || [] });
      setName(value?.data.name);
      setSelectedExistingRule(value);
      setFilters(value?.data.filters ?? []);

      setDisabled(disabled);
    },
    [currentUser.email, setDisabled, setFilters, setName, setSelectedExistingRule]
  );

  const handleToggleSource = useCallback(
    (event, value) => {
      if (!value) return null;

      setViewType(value);

      if (value === "new") {
        setName("");
        setFilters([]);

        setDisabled(false);
      } else {
        setName(selectedExistingRule?.data.name ?? "");
        setFilters(selectedExistingRule?.data.filters ?? []);

        setDisabled(false);
      }
    },
    [selectedExistingRule?.data.filters, selectedExistingRule?.data.name, setDisabled, setFilters, setName, setViewType]
  );

  const handleSaveACopy = useCallback(() => {
    setViewType("new");
    setName(`${selectedExistingRule?.data.name} (Copy)`);
    setFilters(selectedExistingRule?.data.filters ?? []);
    setFilterFields(selectedExistingRule?.data.filters ?? []);
    setFormula(selectedExistingRule?.data.formula ?? "");
    setDisabled(false);
    setSelectedExistingRule(null);
  }, [
    selectedExistingRule?.data.filters,
    selectedExistingRule?.data.formula,
    selectedExistingRule?.data.name,
    setDisabled,
    setFilterFields,
    setFilters,
    setFormula,
    setName,
    setSelectedExistingRule,
    setViewType,
  ]);

  const onDuplicateRule = useCallback(() => {
    if ((!values.name && viewType === "new") || (!selectedExistingRule && viewType !== "new")) return null;

    const newStoreId = crypto.randomUUID();
    const allocation = {
      name: `${values.name || selectedExistingRule?.data.name} (Copy)`,
      filterFields: values.filterFields,
      formula: values.formula,
    };

    trackAllocationEvent({
      trackEvent,
      eventName: "Allocation Rule Copied",
      ruleCount: (items.main?.length || 0) + 1,
      hasUnallocatedCosts,
      allocationId,
      currentUserEmail: currentUser.email,
      collaborators: selectedExistingRule?.data.collaborators,
    });

    const newStore = formStoreManager.getStore(newStoreId);
    newStore.setState((state) => ({
      ...state,
      values: allocation,
      viewType: "new",
    }));
    addItem(newStoreId);
  }, [
    values.name,
    values.filterFields,
    values.formula,
    viewType,
    selectedExistingRule,
    trackEvent,
    items.main?.length,
    hasUnallocatedCosts,
    allocationId,
    currentUser.email,
    addItem,
  ]);

  useEffect(() => {
    setFilterFields(filters);
  }, [filters, setFilterFields]);

  useEffect(() => {
    if (selectedExistingRule && viewType === "existing") {
      handleSelectExistingAllocation(selectedExistingRule);
    }
  }, [handleSelectExistingAllocation, selectedExistingRule, validateForm, viewType]);

  const viewSelector = useMemo(
    () => (
      <Stack
        direction="row"
        spacing={2}
        sx={{
          display: { xs: "none", sm: "none", md: "flex" },
          justifyContent: "space-between",
          pl: CONTENT_PADDING_LEFT,
        }}
      >
        <FormControl>
          <RadioGroup row value={viewType} onChange={handleToggleSource}>
            <FormControlLabel
              value="new"
              control={<Radio />}
              label={allocationTxt.CREATE_NEW}
              disabled={isSingleRule || disabled}
            />
            <FormControlLabel
              value="existing"
              control={<Radio />}
              label={allocationTxt.USE_EXISTING_ALLOCATION}
              disabled={isSingleRule || disabled}
            />
          </RadioGroup>
        </FormControl>

        <ActionButtons
          canDuplicate={canDuplicate}
          canRemove={canRemove}
          onDuplicateRule={onDuplicateRule}
          onRemoveRule={onRemoveRule}
        />
      </Stack>
    ),
    [viewType, handleToggleSource, isSingleRule, disabled, canDuplicate, canRemove, onDuplicateRule, onRemoveRule]
  );

  const expandableHeader = useMemo(
    () => (
      <Stack direction="row" gap={1} sx={{ mt: 2, width: "100%" }}>
        <Grid>
          <ExpandMore expand={expanded} onClick={handleExpandClick} aria-expanded={expanded} aria-label="show more">
            <ExpandMoreIcon />
          </ExpandMore>
        </Grid>

        <Grid width="100%">
          <AllocationNameField
            viewType={viewType}
            isEditMode={isEditMode}
            isSingleRule={isSingleRule}
            values={values}
            selectedExistingRule={selectedExistingRule}
            onAllocationNameChanged={handleAllocationNameChanged}
            onSelectExistingAllocation={handleSelectExistingAllocation}
            disabled={(Boolean(selectedExistingRule?.data?.collaborators) && !isRuleOwner) || disabled}
          />
        </Grid>
      </Stack>
    ),
    [
      expanded,
      handleExpandClick,
      viewType,
      isEditMode,
      isSingleRule,
      values,
      selectedExistingRule,
      handleAllocationNameChanged,
      handleSelectExistingAllocation,
      isRuleOwner,
      disabled,
    ]
  );

  useEffect(() => {
    if (isDirty && usedInAttributionGroup.length) {
      setEditWarnings(usedInAttributionGroup.map((group) => group.data.name).join(", "));
    } else {
      setEditWarnings("");
    }
  }, [isDirty, usedInAttributionGroup, setEditWarnings]);

  return (
    <>
      <Stack gap={2} sx={{ mb: 2 }}>
        {!grabbing && (
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >{`${allocationTxt.ALLOCATION_RULE_LABEL} ${displayNumber}`}</Typography>
        )}

        <AllocationDraggableHolder
          grabbing={grabbing}
          height="auto"
          disabled={!canRemove || disabled}
          id={initialAttributionID}
          key={initialAttributionID}
          alignItems="flex-start"
        >
          <Box sx={{ p: 1, pb: 2, ml: { xs: 0, sm: 0, md: -5 } }}>
            {viewSelector}
            {expandableHeader}
            {!isRuleOwner && isAllocationOwner && viewType === "existing" && selectedExistingRule && (
              <Alert
                severity="info"
                sx={{
                  mt: 1,
                  ml: CONTENT_PADDING_LEFT,
                  maxWidth: "540px",
                }}
                action={
                  <Button color="inherit" size="small" onClick={handleSaveACopy} disabled={disabled}>
                    {allocationTxt.ALLOCATION_COPY}
                  </Button>
                }
              >
                {allocationTxt.ALLOCATION_RULE_INFO}
              </Alert>
            )}
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              {(viewType === "new" || (viewType === "existing" && selectedExistingRule)) && (
                <Box sx={{ pl: CONTENT_PADDING_LEFT, mt: 2 }}>
                  {DimensionsSelection}
                  {!!filters.length && (
                    <AllocationFormulaField
                      formStore={formStore}
                      disabled={(!isRuleOwner && !!selectedExistingRule) || disabled}
                    />
                  )}
                </Box>
              )}
            </Collapse>
            {!!usedInAttributionGroup.length && isDirty && (
              <Alert severity="warning" sx={{ marginX: 5, mt: 2, mb: 1, maxWidth: { lg: "546px", md: "100%" } }}>
                {allocationTxt.ALLOCATION_EDIT_WARNING}
              </Alert>
            )}
          </Box>
        </AllocationDraggableHolder>
        {!!usedInAttributionGroup.length && !isGroup && isDirty && isEditMode && isFirst && items.main.length > 1 && (
          <Alert severity="warning" sx={{ mt: 2 }}>
            {allocationTxt.SINGLE_ALLOCATION_EDIT_WARNING}
          </Alert>
        )}
      </Stack>
    </>
  );
}
