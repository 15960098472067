import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";

import { useFullScreen } from "../../../utils/dialog";
import { type SingleAllocationRowItem } from "./AllocationBrowser/AllocationBrowser";
import { AllocationSettingsForm } from "./AllocationSettingsForm";

type Props = {
  allocation: SingleAllocationRowItem;
  onClose: () => void;
};

export const AllocationAnomalyDetectionDialog = ({ allocation, onClose }: Props) => {
  const { isMobile: isSmallScreen } = useFullScreen("sm");
  return (
    <Dialog open={true} onClose={onClose} fullScreen={isSmallScreen} fullWidth maxWidth="xs">
      <DialogContent sx={{ p: 0, width: "100%" }}>
        <Box>
          <Stack sx={{ p: 2 }}>
            <Typography variant="h6" sx={{ fontSize: "20px" }}>
              Anomaly detection
            </Typography>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              sx={{ mt: 2, mb: 1, fontSize: "16px", fontWeight: 400 }}
            >
              {allocation.data.name}
            </Typography>
          </Stack>

          <AllocationSettingsForm
            initialValues={{ anomalyDetection: allocation.data.anomalyDetection ?? false }}
            onCancel={onClose}
            onSubmit={async (data) => {
              await allocation.ref.update(data);
              onClose();
            }}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};
