import { useMemo } from "react";

import { Autocomplete, TextField } from "@mui/material";

import { usePlatformList } from "./hooks";
import { type TicketAsset } from "./types";

type Props = {
  onChange: (data: TicketAsset) => void;
  selectedItem: string;
  platform: string;
  assets: TicketAsset[];
};

export const AssetSelector = ({ onChange, selectedItem, platform, assets }: Props) => {
  const platformList = usePlatformList();
  const label = platformList.find((p) => p.value === platform)?.label || "";

  const value = useMemo(
    () => assets.find((asset) => asset.identifier === selectedItem) || null,
    [assets, selectedItem]
  );

  if (!assets.length) {
    return null;
  }

  return (
    <Autocomplete
      options={assets}
      getOptionLabel={(option) => option.identifier || ""}
      fullWidth
      disablePortal
      size="medium"
      sx={{ mt: 2, mb: 2 }}
      onChange={(_, newValue: TicketAsset | null) => {
        if (newValue) {
          onChange(newValue);
        }
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          {...params}
          size="medium"
          label={label}
          required
          variant="outlined"
          slotProps={{
            inputLabel: {
              shrink: true,
            },
          }}
        />
      )}
    />
  );
};
