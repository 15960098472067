import { useEffect, useMemo, useState } from "react";

import { type Assignee, type SupportZendeskTicketModel, UserModel } from "@doitintl/cmp-models";
import { getCollection, type QueryModel, useCollectionData } from "@doitintl/models-firestore";

import { consoleErrorWithSentry } from "../../utils";

export type AssigneeUsers = Map<string, UserModel>;

type AssigneeUsersHookData = { assigneeUsers: AssigneeUsers; assigneeUsersLoading: boolean };

/**
 * Get a map of assignee users from the support zendesk tickets.
 * @param supportZendeskTickets
 * @returns map of assignee emails and their corresponding DoiT UserModels
 */
function useAssigneeUsers(supportZendeskTickets?: SupportZendeskTicketModel[]): AssigneeUsersHookData {
  const [assigneeUsers, setAssigneeUsers] = useState<AssigneeUsers>(new Map());

  const query: QueryModel<UserModel> | undefined = useMemo(() => {
    const tickets = supportZendeskTickets ?? [];
    const assigneeEmails = tickets.length
      ? tickets.map((zdt) => zdt.ticket.assignee.email)
      : ["fallback.email@example.com"]; // fallback email to prevent empty or null query

    return getCollection(UserModel).where("email", "in", assigneeEmails);
  }, [supportZendeskTickets]);

  const [users, usersDataLoading, usersError] = useCollectionData(query);

  useEffect(() => {
    if (usersError) {
      consoleErrorWithSentry(usersError);
    }
  }, [usersError]);

  useEffect(() => {
    if (!supportZendeskTickets?.length || !users?.length) {
      setAssigneeUsers(new Map()); // Clear existing users if tickets are empty
      return;
    }

    const assigneeUsersMap = new Map<string, UserModel>();
    const assignees: Assignee[] = supportZendeskTickets.map((zdt) => zdt.ticket.assignee);

    assignees.forEach((assignee) => {
      const user: UserModel | undefined = users.find((user: UserModel) => user.email === assignee.email);
      if (user) {
        assigneeUsersMap.set(assignee.email, user);
      }
    });

    setAssigneeUsers(assigneeUsersMap);
  }, [supportZendeskTickets, users]);

  return { assigneeUsers, assigneeUsersLoading: usersDataLoading };
}

export default useAssigneeUsers;
