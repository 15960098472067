import { useCallback, useMemo, useState } from "react";

import { Box, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { RadioCard } from "../../../../Components/RadioCard/RadioCard";
import { useIsFeatureEntitled } from "../../../../Context/TierProvider";
import { usePriorities } from "../../../consts";
import { Platform, type Severity, SeverityLevels } from "../../../types";
import { ContentHeader } from "../../Stepper/ContentHeader";
import { TermsAgreementDialog } from "../../TermsAgreementDialog";
import UrgentSeverityRadioCard from "./UrgentSeverityRadioCard";

type props = {
  severity: Severity | null;
  platform?: string;
  product?: string;
  onChange: (severity: Severity) => void;
  billingProfile: string;
  setBillingProfile: (profile: string | null) => void;
};

export const SeverityRadioSelect = ({
  severity,
  platform,
  product,
  onChange,
  billingProfile,
  setBillingProfile,
}: props) => {
  const theme = useTheme();
  const [selectedSeverity, setSelectedSeverity] = useState<SeverityLevels>();
  const hideSeverityDescription = Platform.finance === platform || Platform.cmp === platform;
  const priorities = usePriorities();

  const [hasAgreedToPayForEscalatedTicket, setHasAgreedToPayForEscalatedTicket] = useState(false);

  // for customers whose tickets are supposed to be answered by Ava
  const isEntitledSupportChatWithAva = useIsFeatureEntitled("support:ticket:chat-with-ava");

  const [escalateDialogOpen, setEscalateDialogOpen] = useState(false);

  const bulletList = useCallback(
    (list: string[]) => (
      <ul style={{ color: theme.palette.text.secondary, paddingLeft: 24 }}>
        {list.map((item: string, index: number) => (
          <li key={index}>
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              {item}
            </Typography>
          </li>
        ))}
      </ul>
    ),
    [theme.palette.text.secondary]
  );

  const formatDescription = useCallback(
    (val: React.ReactNode): React.ReactNode => (Array.isArray(val) ? bulletList(val) : val),
    [bulletList]
  );

  const onChangeSeverity = useCallback(
    (severity: Severity) => {
      setSelectedSeverity(severity.value);
      onChange(severity);
    },
    [onChange]
  );

  const isSeveritySelected = useCallback(
    (severityLevel) =>
      severityLevel.value === selectedSeverity || (!selectedSeverity && severityLevel.value === severity?.value),
    [selectedSeverity, severity?.value]
  );

  // Cannot open urgent tickets by default --> DCI Essentials, Solve Standard
  // DCI Essentials has an option to pay for an escalated ticket.
  const cannotOpenUrgentTickets = useCallback(
    (severityLevel: Severity): boolean =>
      [SeverityLevels.urgent].includes(severityLevel.value) &&
      !!isEntitledSupportChatWithAva &&
      !hasAgreedToPayForEscalatedTicket,
    [hasAgreedToPayForEscalatedTicket, isEntitledSupportChatWithAva]
  );

  // platform = CMP && product = "Report a bug"
  // platform = invoice/finance, request promotional credits, GSuite or O-365
  const overrideDisable = useMemo(
    () =>
      ([Platform.finance, Platform.credits, Platform.gSuite, Platform.office365] as string[]).includes(
        platform ?? ""
      ) ||
      (platform === Platform.cmp && product === "cmp/plan-report-a-bug") ||
      !!billingProfile,
    [billingProfile, platform, product]
  );

  return (
    <Box>
      <ContentHeader title="Issue severity" />
      {priorities.map((severityLevel) => {
        const formattedDescription = formatDescription(severityLevel.description);
        const isThisSeveritySelected = isSeveritySelected(severityLevel);
        const notAllowedUrgentTickets = cannotOpenUrgentTickets(severityLevel);

        const disabled = notAllowedUrgentTickets && !overrideDisable;

        if (severityLevel.value === "urgent") {
          return (
            <UrgentSeverityRadioCard
              key={severityLevel.value}
              severityLevel={severityLevel}
              formattedDescription={formattedDescription}
              hideSeverityDescription={hideSeverityDescription}
              isThisSeveritySelected={isThisSeveritySelected}
              disabled={disabled}
              showUpsell={disabled && !billingProfile}
              onChangeSeverity={onChangeSeverity}
              setEscalateDialogOpen={setEscalateDialogOpen}
            />
          );
        }

        return (
          <RadioCard
            key={severityLevel.value}
            title={severityLevel.title ?? ""}
            description={!hideSeverityDescription && formattedDescription}
            color={severityLevel.color}
            selected={isThisSeveritySelected}
            onClick={() => {
              if (!notAllowedUrgentTickets) {
                onChangeSeverity(severityLevel);

                if (severityLevel.value !== SeverityLevels.urgent) {
                  setHasAgreedToPayForEscalatedTicket(false);
                }
              }
            }}
            disabled={notAllowedUrgentTickets}
          />
        );
      })}
      <TermsAgreementDialog
        open={escalateDialogOpen}
        onClose={() => {
          setEscalateDialogOpen(false);
          setBillingProfile(null);
        }}
        onConfirm={() => {
          setEscalateDialogOpen(false);
          setHasAgreedToPayForEscalatedTicket(true);

          const urgentSeverity = priorities.find((p) => p.value === SeverityLevels.urgent);
          if (urgentSeverity) {
            onChangeSeverity(urgentSeverity);
          }
        }}
        billingProfile={billingProfile}
        setBillingProfile={setBillingProfile}
      />
    </Box>
  );
};
