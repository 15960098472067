import { useEffect, useState } from "react";

import {
  type ApprovalConfig,
  type CloudFlowNodeType,
  NotificationProviders,
  type SlackChannel,
  TimeUnits,
} from "@doitintl/cmp-models";
import { Checkbox, FormControlLabel, Stack, TextField, Typography } from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";

import { useCustomerId } from "../../../../../../Components/hooks/useCustomerId";
import { useSlackChannelsApi } from "../../../../../../Components/Slack/ChannelSelection/useSlackChannelApi";
import { consoleErrorWithSentry } from "../../../../../../utils";
import { FormChangesListener } from "../../../Common/FormChangesListener";
import { useNodeConfigurationContext } from "../../NodeConfigurationContext";
import { useErrorUpdates, useInitialErrors } from "../hooks";
import { AutoRejectApproval } from "./AutoRejectApproval";
import { NotificationProvider } from "./NotificationProvider";

const validationSchema = yup.object({
  required: yup.boolean().default(false),
  recipient: yup.object({
    notificationProvider: yup
      .string()
      .oneOf([NotificationProviders.EMAIL, NotificationProviders.SLACK])
      .required()
      .default(NotificationProviders.EMAIL),
    emails: yup
      .array()
      .nullable()
      .default([])
      .when(["notificationProvider", "$required"], ([provider, required], schema) =>
        provider === NotificationProviders.EMAIL && required
          ? schema.min(1, "Valid email required").of(yup.string().email("Valid email required"))
          : schema
      ),
    slackChannels: yup
      .array()
      .nullable()
      .default([])
      .when(["notificationProvider", "$required"], ([provider, required], schema) =>
        provider === NotificationProviders.SLACK && required ? schema.min(1, "Slack channel required") : schema
      )
      .label("Error from the slack channels"),
  }),
  message: yup.string().default(""),
  rejectApprovalAfterTime: yup.boolean().default(false),
  rejectTimeValue: yup.number().when("rejectApprovalAfterTime", {
    is: true,
    then: (schema) =>
      schema
        .required("Number required")
        .nullable()
        .transform((val) => (isNaN(val) ? null : val))
        .integer()
        .positive("Must be a positive value"),
    otherwise: (schema) => schema,
  }),
  rejectTimeUnit: yup.string().when("rejectApprovalAfterTime", {
    is: true,
    then: (schema) =>
      schema
        .required("Time unit required")
        .nullable()
        .oneOf([TimeUnits.Days, TimeUnits.Hours, TimeUnits.Weeks, TimeUnits.Months]),
    otherwise: (schema) => schema,
  }),
});

const Approval = () => {
  const customerId = useCustomerId();
  const [slackChannels, setSlackChannels] = useState<SlackChannel[]>([]);
  const { getCustomerSlackChannels, channelsLoading } = useSlackChannelsApi(customerId);
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  const initialValues: ApprovalConfig = {
    ...validationSchema.getDefault(),
    ...nodeConfig.approval,
  };

  const [isApprovalValid, setIsApprovalValid] = useState(() => validationSchema.isValidSync(initialValues));

  useEffect(() => {
    const fetchChannels = async () => {
      try {
        const channels = await getCustomerSlackChannels();
        setSlackChannels(channels);
      } catch (e) {
        consoleErrorWithSentry(e);
        setSlackChannels([]);
      }
    };
    if (
      nodeConfig.approval?.recipient?.notificationProvider === NotificationProviders.SLACK &&
      slackChannels.length === 0
    ) {
      fetchChannels();
    }
  }, [customerId, getCustomerSlackChannels, nodeConfig.approval?.recipient, slackChannels.length]);

  useErrorUpdates<CloudFlowNodeType.ACTION>({
    isValid: isApprovalValid,
    errorKey: "approval_error",
    errorMessage: "Incorrect approval parameters",
  });

  const initialErrors = useInitialErrors(validationSchema, initialValues as yup.InferType<typeof validationSchema>);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      validateOnMount
      enableReinitialize
      initialErrors={initialErrors}
    >
      {(props) => (
        <Stack spacing={1}>
          <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
            Approval
          </Typography>

          <Typography variant="body2" color={"text.secondary"}>
            If you want this action to be approved by someone in your organization before it runs you can set it to
            require approval
          </Typography>

          <FormControlLabel
            control={
              <Checkbox
                name="required"
                checked={Boolean(props.values.required)}
                onChange={(e) => {
                  updateNode((prevNode) => ({ approval: { ...prevNode.approval!, required: e.target.checked } }));
                }}
              />
            }
            label="Require approval for this action"
          />

          {props.values.required && (
            <>
              <NotificationProvider loadingSlackChannels={channelsLoading} slackChannels={slackChannels} />
              <TextField
                fullWidth
                name="message"
                label="Message"
                value={props.values.message}
                onChange={(e) => {
                  updateNode((prevNode) => ({ approval: { ...prevNode.approval!, message: e.target.value } }));
                }}
                multiline
                rows={4}
              />
              <AutoRejectApproval />
            </>
          )}
          <FormChangesListener onValidityChange={setIsApprovalValid} />
        </Stack>
      )}
    </Formik>
  );
};

export default Approval;
