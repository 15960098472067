import { type AmazonWebServicesAssetModelProperties, type AwsFeaturePermission } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

export enum AWSFeatureName {
  core = "core",
  spotScaling = "spot-scaling",
  quotas = "quotas",
  realTimeData = "real-time-data",
  fsk8s_core = "fsk8s_core",
  fsk8s_auto_connect = "fsk8s_auto_connect",
  trustedAdvisorInsights = "trusted-advisor-insights",
  costOptimizationHubInsights = "cost-optimization-hub-insights",
  cloud_diagrams = "cloud_diagrams",
}

export enum AccountStatus {
  healthy = "Healthy",
  notConfigured = "Not configured",
  unhealthy = "Incomplete",
}

export const FeatureDetails: Record<string, FeatureDescription> = {
  [AWSFeatureName.core]: {
    displayName: "Core",
    mandatory: true,
    description:
      "The minimum set of read-only permissions we need as a foundation for many features in the DoiT Console.",
  },
  [AWSFeatureName.quotas]: {
    displayName: "Quotas",
    mandatory: false,
    description: "Monitor your AWS service limits.",
  },
  [AWSFeatureName.spotScaling]: {
    displayName: "Spot Scaling",
    mandatory: false,
    description:
      "Analyzes your Auto Scaling Groups based on cost and usage and get recommendations to replace on-demand EC2 instances with Spot instances.",
  },
  [AWSFeatureName.fsk8s_core]: {
    displayName: "FSK8s core",
    mandatory: false,
    description: "Reduces costs and improves efficiency for EKS clusters",
  },
  [AWSFeatureName.fsk8s_auto_connect]: {
    displayName: "FSK8s auto connect clusters",
    mandatory: false,
    description: "Allows FSK8s to automatically connect to EKS clusters ",
  },
  [AWSFeatureName.realTimeData]: {
    displayName: "Real-time anomaly",
    mandatory: false,
    description:
      "Monitor real-time cost anomalies in your AWS workloads. (Only enable for the account that hosts CloudTrail logs)",
  },
  [AWSFeatureName.trustedAdvisorInsights]: {
    displayName: "Trusted Advisor insights",
    mandatory: false,
    description: "Include Trusted Advisor recommendations in DoiT Insights.",
  },
  [AWSFeatureName.costOptimizationHubInsights]: {
    displayName: "Cost Optimization Hub insights",
    mandatory: false,
    description:
      "Include Cost Optimization Hub recommendations in DoiT Insights (available for accounts opted in to Cost Optimization Hub and AWS Compute Optimizer).",
  },
  [AWSFeatureName.cloud_diagrams]: {
    displayName: "Cloud Diagrams",
    mandatory: false,
    description: "Use DoiT Cloud Diagrams.",
  },
};

// presentation type that will be used in the table
export type AWSAccount = {
  id: string;
  accountId: string;
  roleName: string;
  featureNames: string[];
  status: AccountStatus;
  timeLinked: DateTime | null;
  error?: string;
  properties?: AmazonWebServicesAssetModelProperties;
};

// presentation type to hold extended information about feature
export type FeatureDescription = {
  displayName: string;
  description: string;
  mandatory: boolean;
};

export type Feature = FeatureDescription & AwsFeaturePermission;

export type CodeSnippet = {
  code: string;
  name: string;
};
