import { Box, Link, Tooltip, Typography } from "@mui/material";

import { RadioCard } from "../../../../Components/RadioCard/RadioCard";
import { essentialsEscalatePrice } from "../../../../constants";
import { useIsDCIEssentialsTier } from "../../../hooks/useIsDCIEssentialsTier";
import { type Severity } from "../../../types";

type RadioCardProps = {
  severityLevel: Severity;
  formattedDescription: React.ReactNode;
  hideSeverityDescription: boolean;
  isThisSeveritySelected: boolean;

  disabled: boolean;
  onChangeSeverity: (severity: Severity) => void;
};

type UpsellProps = {
  setEscalateDialogOpen: (open: boolean) => void;
};

type UrgentSeverityRadioCardProps = RadioCardProps &
  UpsellProps & {
    showUpsell: boolean;
  };

const UpsellPriorityTicket = ({ setEscalateDialogOpen }: UpsellProps) => (
  <Typography sx={{ my: 2 }} data-cy="p1-upsell-message">
    {`Escalate this ticket with a one-time payment of $${essentialsEscalatePrice}. `}
    <Link
      sx={{ cursor: "pointer" }}
      onClick={() => {
        setEscalateDialogOpen(true);
      }}
      data-cy="p1-upsell-click"
    >
      Click here
    </Link>{" "}
    to proceed
  </Typography>
);

const UrgentSeverityRadioCard = ({
  severityLevel,
  disabled,
  showUpsell,
  formattedDescription,
  hideSeverityDescription,
  isThisSeveritySelected,
  onChangeSeverity,
  setEscalateDialogOpen,
}: UrgentSeverityRadioCardProps) => {
  const isDciEssentials = useIsDCIEssentialsTier();

  const BaseRadioCard = (
    <RadioCard
      key={severityLevel.value}
      title={severityLevel.title ?? ""}
      description={!hideSeverityDescription && formattedDescription}
      color={severityLevel.color}
      selected={isThisSeveritySelected}
      onClick={() => {
        if (!disabled) {
          onChangeSeverity(severityLevel);
        }
      }}
      disabled={disabled}
    />
  );

  // if disabled w/ tooltip
  if (disabled && !isDciEssentials) {
    return (
      <Tooltip
        placement="top"
        title="Please upgrade your subscription in order to submit an urgent ticket for our support team."
      >
        <Box>{BaseRadioCard}</Box>
      </Tooltip>
    );
  }

  // if disabled w/ upsell
  if (showUpsell) {
    return (
      <>
        <UpsellPriorityTicket setEscalateDialogOpen={setEscalateDialogOpen} />
        {BaseRadioCard}
      </>
    );
  }

  return BaseRadioCard;
};

export default UrgentSeverityRadioCard;
