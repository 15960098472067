import { useMemo } from "react";

import { DateTime } from "luxon";

import { useAvaConversationRefs } from "../Ava/useAvaConversationRefs";
import { type ConversationHistory, ConversationHistoryPeriod } from "./types";

export const useConversationHistory = (bringAll: boolean) => {
  const [allConversations] = useAvaConversationRefs(bringAll);

  return useMemo<ConversationHistory>(
    () =>
      allConversations.reduce((acc, conversation) => {
        if (!conversation.timeUpdated.isValid) {
          return acc;
        }

        const updated = conversation.timeUpdated.toUTC();
        const now = DateTime.now().toUTC();

        const addConversation = (period: ConversationHistoryPeriod) => {
          acc[period] = acc[period] ? [...acc[period], conversation] : [conversation];
        };

        switch (true) {
          case updated.hasSame(now.startOf("day"), "day"):
            addConversation(ConversationHistoryPeriod.TODAY);
            break;
          case now.minus({ days: 7 }).startOf("day") <= updated:
            addConversation(ConversationHistoryPeriod.PREV_7);
            break;
          case now.minus({ days: 30 }).startOf("day") <= updated:
            addConversation(ConversationHistoryPeriod.PREV_30);
            break;
          default:
            addConversation(ConversationHistoryPeriod.OLDER);
        }

        return acc;
      }, {} as ConversationHistory),
    [allConversations]
  );
};
