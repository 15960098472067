import { type Theme } from "@mui/material";
import { type EChartsOption } from "echarts";
import merge from "lodash/merge";

export const getCommonChartOptions = (theme: Theme, overrideOptions?: EChartsOption): EChartsOption => {
  const baseOptions: EChartsOption = {
    grid: {
      top: 10,
      left: 40,
      right: 10,
      bottom: 50,
    },
    yAxis: {
      type: "value",
      axisLine: {
        lineStyle: {
          color: theme.palette.text.primary,
        },
      },
      axisLabel: {
        color: theme.palette.text.primary,
      },
      splitLine: {
        lineStyle: {
          color: theme.palette.divider,
        },
      },
    },
    legend: {
      textStyle: {
        color: theme.palette.text.primary,
      },
      bottom: 0,
      type: "scroll",
      icon: "circle",
    },
    tooltip: {
      trigger: "axis",
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
      textStyle: {
        color: theme.palette.text.primary,
      },
    },
  };

  if (overrideOptions) {
    return merge({}, baseOptions, overrideOptions);
  }

  return baseOptions;
};

export const formatTimestamp = (timestamp: string): string => {
  const date = new Date(timestamp);
  return date.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

export const formatTimestampAxis = (formattedDate: string): string => formattedDate.split(",")[0];

export const formatMemory = (value: string, decimals: number = 2): string =>
  (value ? (+value / 1024).toFixed(decimals) : 0).toString();
