import { type MouseEvent, useCallback, useMemo, useState } from "react";

import {
  AnalyticsDataSource,
  type CurrencyCode,
  LimitAggregation,
  Metric,
  Positions,
  type ReportFilter,
  Sort,
  TimeInterval,
} from "@doitintl/cmp-models";
import InfoIcon from "@mui/icons-material/Info";
import { ClickAwayListener } from "@mui/material";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { useApiContext } from "../../../../api/context";
import { attributionTxt } from "../../../../assets/texts/CloudAnalytics";
import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import { useCloudAnalyticsTransforms } from "../../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import useUpdateEffect from "../../../../Components/hooks/useUpdateEffect";
import HtmlTooltip from "../../../../Components/Tooltip/HtmlTooltip";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import ReportData from "../../../../Pages/CloudAnalytics/ReportData";
import { type AttributionFilter, type MetadataOption } from "../../../../types";
import { CSPCustomerID } from "../../../../utils/common";
import { useFullScreen } from "../../../../utils/dialog";
import { getLetterForIndex } from "../../../../utils/string";
import { executeQueryRequest, type QueryRequest, QueryType } from "../../report/ReportQuery";
import { accountsParam, attrModelToQueryRequest, positionsParams } from "../../report/ReportQueryUtils";
import { MetricOptions } from "../../utilities";
import { FormulaField, type FormulaState } from "../FormulaField";
import { isValidFiltersAndFormula } from "../utils";

const LOGIC_PART_WIDTH = 540;

const DEFAULT_LIMIT = 25;
const DEFAULT_TIME_SETTINGS = {
  interval: TimeInterval.DAY,
  from: DateTime.utc()
    .minus({ [TimeInterval.MONTH]: 2 })
    .startOf(TimeInterval.MONTH),
  to: DateTime.utc(),
};

type Props = {
  formula?: string;
  filters: AttributionFilter[];
  guidedExperienceMode?: boolean;
  allocationMode?: boolean;
};

const useAttributionCreateFormula = ({ formula, filters, guidedExperienceMode = false, allocationMode }: Props) => {
  const [logicAlertOpen, setLogicAlertOpen] = useState(true);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleTooltipOpen = (e: MouseEvent) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  };

  const handleTooltipClose = () => {
    setIsTooltipOpen(false);
  };

  const [formulaState, setFormulaState] = useState<FormulaState>({
    value: formula ?? "",
    valid: !!formula,
    autocompleteOn: !formula,
    shouldRun: false,
    disabled: false,
  });
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const transforms = useCloudAnalyticsTransforms();
  const { isMobile } = useFullScreen();

  const variablesArray = useMemo(() => filters.map((_, idx) => getLetterForIndex(idx)) || [], [filters]);
  const hasValidFiltersAndFormula = useMemo(
    () => isValidFiltersAndFormula(filters, formulaState.valid),
    [filters, formulaState.valid]
  );
  const isCSP = useMemo(() => customer.id === CSPCustomerID, [customer]);

  useUpdateEffect(() => {
    if (formulaState.shouldRun) {
      setFormulaState((prevState) => ({ ...prevState, shouldRun: false }));
    }
  }, [formulaState.shouldRun, hasValidFiltersAndFormula]);

  const attributionQueryRun = useCallback(
    async (
      name: string,
      currency: CurrencyCode,
      filteredDimensions: MetadataOption[],
      attributionId: string,
      rowOrder: Sort,
      timeSettings: { interval: TimeInterval; from: DateTime; to: DateTime } = DEFAULT_TIME_SETTINGS
    ) => {
      const accounts = accountsParam(filteredDimensions);
      const { rows, cols } = positionsParams(filteredDimensions, true, filters, guidedExperienceMode);
      const attr = attrModelToQueryRequest({ name, filters, formula: formulaState.value, id: attributionId }, true);

      const limitFilters: ReportFilter[] = filters.map((filter) => ({
        ...filter,
        allowNull: false,
        values: [],
        regexp: null,
        inverse: false,
        includeInFilter: true,
        position: Positions.ROW,
        limit: DEFAULT_LIMIT,
        limitMetric: Metric.COST,
        limitOrder: Sort.DESC,
        composite: [],
      }));

      const request: QueryRequest = {
        type: QueryType.ATTRIBUTION,
        id: attributionId,
        accounts,
        timeSettings,
        rows,
        cols,
        attributions: [attr],
        filters: limitFilters,
        currency,
        limitAggregation: LimitAggregation.TOP,
        calculatedMetric: null,
        cloudProviders: null,
        dataSource: AnalyticsDataSource.BILLING_DATAHUB,
      };

      const response = await executeQueryRequest(api, customer.id, request);
      const metricOffset = rows.length + cols.length;
      const vals = [metricOffset, metricOffset + 1];
      const numMetrics = isCSP ? MetricOptions.length + 1 : MetricOptions.length;

      return new ReportData({
        data: response.data.rows,
        rows,
        cols,
        rowOrder,
        vals,
        transforms,
        numMetrics,
      });
    },
    [api, customer.id, filters, formulaState.value, guidedExperienceMode, isCSP, transforms]
  );

  const AttributionCreateFormula = (
    <Grid container gap={1} sx={{ flexDirection: "column" }}>
      {!guidedExperienceMode && !allocationMode && (
        <Typography variant="h4" sx={{ pl: 1 }}>
          {allocationTxt.DEFINE_LOGIC_TITLE}
        </Typography>
      )}
      <Grid sx={{ width: { md: LOGIC_PART_WIDTH } }}>
        <FormulaField
          formulaState={formulaState}
          setFormulaState={setFormulaState}
          variables={variablesArray}
          disabled={formulaState.disabled || isMobile || filters.length < 2}
          label={allocationTxt.DEFINE_LOGIC}
        />
        {allocationMode && (
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <span>
              <HtmlTooltip
                placement="bottom"
                onClose={handleTooltipClose}
                open={isTooltipOpen}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={
                  <Box>
                    <Typography variant="subtitle1" fontWeight={500} mb={1}>
                      {attributionTxt.LOGIC_ALERT_TITLE}
                    </Typography>
                    <Stack gap={2}>
                      {attributionTxt.LOGIC_ALERT_RULES.map((val) => (
                        <Typography key={val}>{val}</Typography>
                      ))}
                    </Stack>
                  </Box>
                }
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ borderBottom: "1px dotted", cursor: "pointer" }}
                  onClick={handleTooltipOpen}
                >
                  {allocationTxt.LEARN_HOW_TO_DEFINE_LOGIC_TITLE}
                </Typography>
              </HtmlTooltip>
            </span>
          </ClickAwayListener>
        )}
      </Grid>
      {guidedExperienceMode && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            pl: 1,
          }}
        >
          {attributionTxt.OPERATORS_BETWEEN_RULES}
          <Link
            color="inherit"
            href="https://help.doit.com/docs/cloud-analytics/attributing-cloud-spend/create-attribution#create-an-attribution-from-scratch"
            target="_blank"
            variant="inherit"
            sx={{
              pl: 0.5,
            }}
          >
            {attributionTxt.LEARN_MORE}
          </Link>
        </Typography>
      )}
      {!guidedExperienceMode && !allocationMode && logicAlertOpen && (
        <Grid
          size={{
            xs: 12,
            md: "auto",
          }}
        >
          <Alert
            variant="standard"
            severity="info"
            onClose={() => {
              setLogicAlertOpen(false);
            }}
            icon={<InfoIcon fontSize="inherit" />}
            sx={{ width: { md: LOGIC_PART_WIDTH } }}
          >
            <AlertTitle>{attributionTxt.LOGIC_ALERT_TITLE}</AlertTitle>
            <List dense disablePadding sx={{ listStyleType: "disc", pl: 2 }}>
              {attributionTxt.LOGIC_ALERT_RULES.map((val) => (
                <ListItem key={val} disablePadding sx={{ display: "list-item" }}>
                  {val}
                </ListItem>
              ))}
            </List>
          </Alert>
        </Grid>
      )}
    </Grid>
  );

  return { AttributionCreateFormula, formulaState, attributionQueryRun, setFormulaState };
};

export default useAttributionCreateFormula;
