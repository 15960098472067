import { Box, Stack, Typography } from "@mui/material";

import snowflakeLogo from "../../../../../assets/snowflake-logo.svg";

export const cyIds = {
  header: "snowflake-header",
  logo: "snowflake-logo",
};

const SnowflakeAssetsTabHeader = ({ connectButton }: { connectButton: React.JSX.Element }) => (
  <Stack direction="row" justifyContent="space-between" sx={{ pt: 2, pb: 2.5 }}>
    <Typography variant="h1" data-cy="snowflake-header">
      <Box
        component="img"
        src={snowflakeLogo}
        sx={{ height: "0.8em", width: "auto", mr: 1 }}
        data-cy="snowflake-logo"
        aria-hidden
        alt-text="Snowflake logo"
      />
      Snowflake
    </Typography>
    {connectButton}
  </Stack>
);

export default SnowflakeAssetsTabHeader;
