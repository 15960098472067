import { useMemo } from "react";

import { MarketplaceModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { default as saasConsoleMarketPlaceSuccessDark } from "../../../assets/standalone/aws-mp-success-dark.svg";
import { default as saasConsoleMarketPlaceSuccessLight } from "../../../assets/standalone/aws-mp-success-light.svg";
import { useCustomerRouteNavigation } from "../../../Components/hooks/useCustomerRouteNavigation";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { Loader } from "../../../Components/Loader/Loader";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { MarketplaceErrorMessage } from "./MarketplaceError";
import { SubscriptionPendingAWS } from "./SubscriptionPendingAWS";
import { SubscriptionSuccessMsg } from "./SubscriptionSuccessMsg";
import { CustomerPageUrl } from "./type";

export const MarketplaceSuccessAWS = () => {
  const navigateToCustomerRoute = useCustomerRouteNavigation();
  const isDark = useDarkThemeCheck();
  const { customer } = useCustomerContext();

  const awsMarketplaceAccountsRef = useMemo(
    () =>
      getCollection(MarketplaceModel)
        .doc("aws-marketplace")
        .collection("awsMarketplaceAccounts")
        .where("customer", "==", customer.ref),
    [customer.ref]
  );

  const [awsMarketplaceAccountsDocs, loading] = useCollectionData(awsMarketplaceAccountsRef);

  const Message = () => {
    switch (awsMarketplaceAccountsDocs?.[0].status) {
      case "PENDING":
        return <SubscriptionPendingAWS />;
      case "ACTIVE":
        return (
          <SubscriptionSuccessMsg
            marketplaceSuccessImg={isDark ? saasConsoleMarketPlaceSuccessDark : saasConsoleMarketPlaceSuccessLight}
            subscriptionSuccessMsgSubtitle="You have subscribed to DoiT Console via the AWS Marketplace"
            linkText="Go to Home page"
            onLinkClick={() => {
              navigateToCustomerRoute(CustomerPageUrl.HOME);
            }}
          />
        );
      default:
        return (
          <MarketplaceErrorMessage
            message={"Failed to subscribe to DoiT Console via the AWS Marketplace. Please contact support."}
          />
        );
    }
  };

  return (
    <Loader loading={loading}>
      <Message />
    </Loader>
  );
};
