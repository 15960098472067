import {
  type Cluster,
  ClusterDiscoveryStatus,
  ClusterType,
  type ClusterWithCloudConnect,
  type CustomerModelCloudConnectModel,
  type KubernetesFeature,
  KubernetesFeatureStatus,
} from "@doitintl/cmp-models";

import { ClusterAgentStatus, ClusterComputedStatus } from "./types";

export const splitActiveAndInactiveClusters = (clusters?: Cluster[]) => {
  const activeClusters: Cluster[] = [];
  const inactiveClusters: Cluster[] = [];

  clusters?.forEach((cluster) => {
    (cluster.isActivated ? activeClusters : inactiveClusters).push(cluster);
  });

  return { activeClusters, inactiveClusters };
};

export const getClusterEnabledFeatures = (
  cluster: ClusterWithCloudConnect,
  featureMap: Record<KubernetesFeature["id"], KubernetesFeature> | undefined
) =>
  cluster.features
    ? Object.entries(cluster.features)
        .filter(([_, f]) => f.isActivated)
        .map(([k, _]) => featureMap?.[k]?.name ?? "Unknown Feature")
        .sort((a, b) => a.localeCompare(b))
        .join(", ")
    : "";

export const isCloudConnectHealthy = (cloudConnect?: CustomerModelCloudConnectModel) => {
  if (!cloudConnect) {
    return false;
  }

  if (cloudConnect.cloudPlatform === "amazon-web-services") {
    return cloudConnect.status === 0;
  }

  return cloudConnect.status === 1;
};

export const getClusterComputedStatus = (cluster: ClusterWithCloudConnect): ClusterComputedStatus => {
  const { discoveryStatus, isPrivate, type, cloudConnect, features } = cluster;
  const featureList = Object.values(features ?? {});
  const enabledFeatures = featureList.filter((f) => f.isActivated);

  const anyFeatureEnabled = featureList.some((f) => f.isActivated);
  const hasCloudConnectIssue = type !== ClusterType.SELF_MANAGED && !isCloudConnectHealthy(cloudConnect);
  const anyEnabledFeatureIsHealthy = enabledFeatures.some((f) => f.status === KubernetesFeatureStatus.HEALTHY);
  const anyEnabledFeatureIsUnhealthy = enabledFeatures.some((f) => f.status !== KubernetesFeatureStatus.HEALTHY);
  const allFeaturesEnabledAndHealthy = featureList.every(
    (f) => f.isActivated && f.status === KubernetesFeatureStatus.HEALTHY
  );

  if (hasCloudConnectIssue) return ClusterComputedStatus.CONNECTION_ISSUE;
  if (discoveryStatus === ClusterDiscoveryStatus.DELETED) return ClusterComputedStatus.DELETED;
  if (isPrivate) return ClusterComputedStatus.PRIVATE;
  if (!anyFeatureEnabled) return ClusterComputedStatus.NOT_CONFIGURED;
  if (anyEnabledFeatureIsUnhealthy) return ClusterComputedStatus.UNHEALTHY;
  if (allFeaturesEnabledAndHealthy) return ClusterComputedStatus.HEALTHY;
  if (anyEnabledFeatureIsHealthy) return ClusterComputedStatus.PARTIAL;

  return ClusterComputedStatus.UNKNOWN;
};

export const getClusterAgentStatus = (_: ClusterWithCloudConnect) => ClusterAgentStatus.NOT_INSTALLED;

export const sortedValueToOption = (value: string) => ({
  label: value.split("-")[1],
  value,
});
