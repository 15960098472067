import { type Cluster } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";

interface ConnectedClustersDialogProps {
  open: boolean;
  onClose: () => void;
  clusters: Cluster[];
}

const ConnectedClustersDialog = ({ open, onClose, clusters }: ConnectedClustersDialogProps) => (
  <Dialog open={open} onClose={onClose}>
    <DialogTitle>Connected clusters</DialogTitle>
    <DialogContent sx={{ minWidth: "400px", maxWidth: "600px" }}>
      <ul>
        {clusters.map((cluster) => (
          <li key={cluster.id}>
            {cluster.name} ({cluster.region})
          </li>
        ))}
      </ul>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={onClose}>Close</Button>
    </DialogActions>
  </Dialog>
);

export default ConnectedClustersDialog;
