import { useEffect, useState } from "react";

import { getModelRequiredTupleByPath } from "@doitintl/cloudflow-commons";
import {
  type CloudFlowNodeType,
  ComparisonOperator,
  type ReferencedNodeValue,
  type UnwrappedApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { MenuItem, Stack, TextField, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { cloudflowTexts } from "../../../../../assets/texts";
import { GenericForm } from "../../ApiActionParametersForm/ApiActionParametersForm";
import { ReferencedFieldContextProvider } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { ReferencedFieldStandalone } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldStandalone";
import { type NodeWitOutputModel } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useFieldCommonProps } from "../../ApiActionParametersForm/useFieldCommonProps";
import { useNodeConfigurationContext } from "../../ConfigurationPanel/NodeConfigurationContext";
import { useFilterDialogFormTexts } from "./hooks/useFilterDialogFormTexts";
import {
  type FilterDialogFormValues,
  getCriteriaValueModel,
  getOperatorsByModel,
  type useFilterFormSchema,
} from "./useFilterFormSchema";

export const FilterDialogForm: React.FC<{
  schema: ReturnType<typeof useFilterFormSchema>[0];
  selectedNode: NodeWitOutputModel;
  referenceableNodes: NodeWitOutputModel[];
}> = ({ referenceableNodes, selectedNode, schema }) => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.FILTER | CloudFlowNodeType.CONDITION>();
  const { getFieldProps, setValues, setFieldValue } = useFormikContext<FilterDialogFormValues>();
  const { fieldToFilterTitle, filterCriteriaTitle } = useFilterDialogFormTexts(nodeConfig.type);

  const fieldReferenceProps = useFieldCommonProps<ReferencedNodeValue>(
    getFieldProps("fieldReference"),
    cloudflowTexts.FIELD,
    true
  );
  const operatorProps = useFieldCommonProps(getFieldProps("comparisonOperator"), cloudflowTexts.OPERATOR, true);

  const [referencedModel, isRequired] = getModelRequiredTupleByPath(
    selectedNode.outputModel,
    fieldReferenceProps.value.referencedField
  );

  const [criteriaValueModel, setCriteriaValueModel] = useState<UnwrappedApiServiceModelDescriptor>(
    getCriteriaValueModel(referencedModel, operatorProps.value)
  );

  useEffect(() => {
    const newModel = getCriteriaValueModel(referencedModel, operatorProps.value);
    setCriteriaValueModel(newModel);
  }, [operatorProps.value, referencedModel]);

  useEffect(() => {
    setValues((values) => schema.cast(values));
  }, [criteriaValueModel, schema, setValues]);

  const isCriteriaValueVisible = ![ComparisonOperator.IS_NULL, ComparisonOperator.IS_NOT_NULL].includes(
    operatorProps.value
  );

  useEffect(() => {
    if (!isCriteriaValueVisible) {
      setFieldValue("value", null);
    }
  }, [isCriteriaValueVisible, setFieldValue]);

  const operators = getOperatorsByModel(referencedModel);
  const isGroupOperator = [ComparisonOperator.IN, ComparisonOperator.NOT_IN].includes(operatorProps.value);

  return (
    <Stack spacing={3} sx={{ maxHeight: 640, overflowY: "auto" }}>
      <Typography variant="subtitle1" fontWeight={500}>
        {fieldToFilterTitle}
      </Typography>
      <ReferencedFieldStandalone
        {...fieldReferenceProps}
        rootReferencedNodeValue={{
          referencedField: [],
          referencedNodeId: selectedNode.id,
        }}
        referenceableNodes={[selectedNode]}
      />

      <Typography variant="subtitle1" fontWeight={500}>
        {filterCriteriaTitle}
      </Typography>
      <Stack
        direction="row"
        spacing={1}
        alignItems="baseline"
        sx={{
          mt: isGroupOperator ? 24 : "8px !important",
        }}
      >
        <TextField select variant="outlined" sx={{ minWidth: 160 }} disabled={!criteriaValueModel} {...operatorProps}>
          {operators.map((operator) => (
            <MenuItem key={operator} value={operator}>
              {operator}
            </MenuItem>
          ))}
        </TextField>

        {isCriteriaValueVisible && (
          <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
            <GenericForm
              inputModel={criteriaValueModel}
              fieldPath="value"
              label="Value"
              renderAsNotRequired={!isRequired}
            />
          </ReferencedFieldContextProvider>
        )}
      </Stack>
    </Stack>
  );
};
