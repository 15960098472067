import { useMemo } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Metric, Roles } from "@doitintl/cmp-models";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import LabelList from "../../../Components/LabelList";
import { cloudAnalytics } from "../../../constants/cypressIds";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type Budget } from "../../../types";
import { formatNumber, formatToLocaleTimestamp, getCurrencySymbol } from "../../../utils/common";
import { type LabelWithRef } from "../labels/types";
import { type BudgetRowActions } from "../utilities";
import BudgetUtilizationProgressBar from "./BudgetUtilizationProgressBar";
import { buildBudgetViewPath, useBudgetThreeDotsMenu } from "./hooks";
import { getUtilizationProgressBarColor } from "./shared";

const { budgets: budgetIds } = cloudAnalytics;

type BudgetRowProps = {
  actions: BudgetRowActions;
  budget: Budget;
  tierLimitReached: boolean;
  labels: LabelWithRef[] | undefined;
};

export const BudgetRow = ({ actions, budget, tierLimitReached, labels }: BudgetRowProps) => {
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const { setOpenDeleteDialog, setSelected, setShareDialogOpen } = actions;
  const rowLabels = useMemo(
    () => labels?.filter((label) => budget.data.labels?.some((rowLabel) => label.ref.id === rowLabel.id)) ?? [],
    [budget.data, labels]
  );

  const budgetCurrencySymbol = getCurrencySymbol(budget.data?.config?.currency);
  const budgetAmountToDateLabel = `${budgetCurrencySymbol}${formatNumber(budget.data.budgetAmountToDate)}`;
  const varianceToDateLabel = `${budgetCurrencySymbol}${formatNumber(budget.data.varianceToDate)}`;
  const currentSpendLabel = `${budgetCurrencySymbol}${formatNumber(budget.data?.utilization?.current)}`;
  const amountLabel = `${budget.data?.config?.metric === Metric.COST ? budgetCurrencySymbol : "(usage)"}${formatNumber(budget.data.config.amount)}`;

  const threeDotsMenu = useBudgetThreeDotsMenu({
    row: { ...budget, ref: budget.ref, id: budget.ref.id },
    handleShare: () => {
      setSelected([budget]);
      setShareDialogOpen(true);
    },
    handleDelete: () => {
      setSelected([budget]);
      setOpenDeleteDialog(true);
    },
    tierLimitReached,
  });

  const viewBudgetUrl = buildBudgetViewPath(customer.ref.id, budget.ref.id);

  return (
    <CellsWrapper>
      <TableCell data-cy={budgetIds.browser.budgetName}>
        <Stack spacing={1}>
          <Typography
            component={RouterLink}
            to={viewBudgetUrl}
            color="inherit"
            variant="body2"
            sx={{
              mb: 1,
            }}
          >
            {budget.data.name}
          </Typography>
          <LabelList labels={rowLabels} />
        </Stack>
      </TableCell>
      <Hide smDown>
        <TableCell data-cy={budgetIds.browser.budgetOwner}>
          {budget.data.collaborators.find((c) => c.role === Roles.OWNER)?.email}
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy={budgetIds.browser.budgetPeriod}>{budget.data.period}</TableCell>
      </Hide>
      <Hide xsDown>
        <TableCell data-cy={budgetIds.browser.budgetAmount}>{amountLabel}</TableCell>
      </Hide>
      <Hide xsDown>
        <TableCell>{budgetAmountToDateLabel}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell>{currentSpendLabel}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell>{varianceToDateLabel}</TableCell>
      </Hide>
      <Hide xsDown>
        <TableCell data-cy={budgetIds.browser.budgetPercent}>
          <BudgetUtilizationProgressBar
            value={budget.data.currentPercentage}
            color={getUtilizationProgressBarColor(budget.data)}
          />
        </TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>{formatToLocaleTimestamp(budget.data.timeCreated)}</TableCell>
      </Hide>
      <Hide smDown>
        <TableCell>{formatToLocaleTimestamp(budget.data.timeModified)}</TableCell>
      </Hide>
      <TableCell data-cy={budgetIds.browser.budgetOptions}>
        <Box
          sx={{
            m: -1.5,
          }}
        >
          {threeDotsMenu}
        </Box>
      </TableCell>
    </CellsWrapper>
  );
};
