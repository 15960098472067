import { type JSX, useMemo } from "react";

import { NavLink } from "react-router-dom";
import { AppBar, Box, StyledEngineProvider, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { cmpBaseColors } from "../../../cmpBaseColors";
import StartFreeTrialButton from "../../../Components/StartTrialButton/StartTrialButton";
import { navigationId } from "../../../constants";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { domainConfig } from "../../../domainConfig";
import MenuBarSearch from "../../../Layout/MenuBarSearch";
import { AnnounceKitWrapper } from "../../../utils/announcekit/announcekit";
import { AppBarStyles, ToolbarStyles } from "../../themes";
import { type CategoryContext } from "../../types";
import MobileHeader from "../MobileHeader/MobileHeader";
import { type NavigationVariant } from "../variantHooks";
import { AdditionalOptions } from "./AdditionalOptions";
import AvatarMenu from "./AvatarMenu/AvatarMenu";
import { HeaderCategory } from "./HeaderCategory";
import { useShowFreeTrialCard } from "./hooks";

type HeaderProps = {
  variant: NavigationVariant;
  onSignOut: () => unknown;
  categoriesContext: CategoryContext[];
  children?: JSX.Element;
  presentationModeActive: boolean;
};

export const Header = ({ variant, onSignOut, categoriesContext, presentationModeActive, children }: HeaderProps) => {
  const theme = useTheme();
  const { customer, isProductOnlyCustomer } = useCustomerContext({ allowNull: true });
  const { currentUser, isDoitPartner } = useAuthContext({ mustHaveUser: true });
  const hideDomainName = useMediaQuery(theme.breakpoints.down("lg"));
  const showFreeTrialButton = useShowFreeTrialCard();

  const { categories, settings } = useMemo<{ categories: CategoryContext[]; settings: CategoryContext[] }>(
    () =>
      categoriesContext.reduce(
        (acc, cur) => {
          if (["Settings"].includes(cur.displayName)) {
            acc.settings.push(cur);
          } else {
            acc.categories.push(cur);
          }
          return acc;
        },
        {
          categories: [] as CategoryContext[],
          settings: [] as CategoryContext[],
        }
      ),
    [categoriesContext]
  );

  if (!currentUser && !customer) {
    return null;
  }

  if (variant === "small") {
    return (
      <MobileHeader
        onSignOut={onSignOut}
        categories={categories}
        settings={settings}
        hideWhatsNewOption={isProductOnlyCustomer}
        presentationModeActive={presentationModeActive}
      >
        {children}
      </MobileHeader>
    );
  }

  if (variant === "smallWithOptions") {
    return (
      <MobileHeader
        onSignOut={onSignOut}
        categories={categories}
        settings={settings}
        showOptions={true}
        hideWhatsNewOption={isProductOnlyCustomer}
        presentationModeActive={presentationModeActive}
      >
        {children}
      </MobileHeader>
    );
  }

  return (
    <AnnounceKitWrapper>
      <AppBar elevation={0} position="sticky" sx={AppBarStyles} id={navigationId}>
        <Toolbar
          disableGutters
          sx={[
            (theme) => ({
              [theme.breakpoints.up("sm")]: {
                minHeight: 48,
              },
              borderImage: presentationModeActive
                ? `linear-gradient(90deg, ${cmpBaseColors.presentationModeOrange} 0.8%, ${cmpBaseColors.presentationModeOrange} 99.1%)`
                : theme.palette.navigation.gradient,
            }),
            ToolbarStyles,
          ]}
        >
          <Box
            style={{ zIndex: 2 }}
            sx={{
              pl: 2,
              pr: 1,
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {variant === "full" && (
                <Box
                  sx={{
                    pr: 2,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <NavLink to="/">
                    <img
                      src={domainConfig.assets.logoURL}
                      alt={domainConfig.title}
                      width="81px"
                      height="100%"
                      style={{ verticalAlign: "middle" }}
                    />
                  </NavLink>
                </Box>
              )}
              <Box />
              {categories.map((category) => (
                <HeaderCategory key={category.displayName} category={category} dense={variant === "compact"} />
              ))}
              {showFreeTrialButton && <StartFreeTrialButton />}
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <StyledEngineProvider injectFirst>
                <MenuBarSearch searchWidth={120} />
              </StyledEngineProvider>
              <AdditionalOptions hideWhatsNewOption={!!isProductOnlyCustomer} settings={settings} />
              <Box
                sx={{
                  pl: 1,
                }}
              >
                <AvatarMenu hideDomainName={hideDomainName} onSignOut={onSignOut} disableProfileLink={isDoitPartner} />
              </Box>
            </Box>
          </Box>
        </Toolbar>
        {children}
      </AppBar>
    </AnnounceKitWrapper>
  );
};
