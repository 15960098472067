import { type JSX } from "react";

import {
  AssetTypeMicrosoftAzure,
  type MicrosoftAzureAssetModel,
  type MicrosoftAzureStandaloneAssetModel,
  SaaSConsoleType,
} from "@doitintl/cmp-models";
import ErrorIcon from "@mui/icons-material/ErrorOutlineRounded";
import { Box, Button, CardActions } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import { amber } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { makeStyles } from "@mui/styles";

import { useCustomerRouteNavigation } from "../../../Components/hooks/useCustomerRouteNavigation";
import { useAuthContext } from "../../../Context/AuthContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { getSaaSConsoleOnboardingUrl } from "../../../utils/common";
import { mixpanelTrack } from "../../../utils/mixpanel";
import MicrosoftAzureAssetCard from "../Cards/MicrosoftAzureAssetCard";
import { type AssetsTabPropsOuterProps } from "../types";
import NoAssets from "./components/NoAssets";

const useStyles = makeStyles({
  root: {},
  textField: {},
  cardsArea: {
    padding: 0,
    marginTop: 60,
  },
  cardContent: {},
  cardActions: {
    justifyContent: "flex-end",
  },
  warningIcon: {
    color: amber[500],
  },
});

const Wrapper = ({
  billingProfileFilter,
  children,
}: {
  billingProfileFilter: React.ReactNode;
  children: JSX.Element;
}) => {
  const navigateToCustomerRoute = useCustomerRouteNavigation();
  const allowedToAddStandaloneAssets = useIsFeatureEntitled("pdi:azure");
  const classes = useStyles();

  return (
    <div>
      <Box>
        <CardActions className={classes.cardActions}>
          {billingProfileFilter}
          {allowedToAddStandaloneAssets && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                mixpanelTrack(`home.cloud-connect.azure.click`, { $referrer: location.href });
                navigateToCustomerRoute(getSaaSConsoleOnboardingUrl(SaaSConsoleType.AZURE));
              }}
            >
              Connect new account
            </Button>
          )}
        </CardActions>

        {children}
      </Box>
    </div>
  );
};

type Props = AssetsTabPropsOuterProps<MicrosoftAzureAssetModel | MicrosoftAzureStandaloneAssetModel>;

const MicrosoftAzureAssetsTab = ({
  assets,
  highlight,
  onAssignToEntity,
  onRemoveAsset,
  onAddTag,
  billingProfileFilter,
}: Props) => {
  const classes = useStyles();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  if (assets === undefined) {
    return null;
  }

  if (assets.length <= 0) {
    return (
      <Wrapper billingProfileFilter={billingProfileFilter}>
        <NoAssets assetType={AssetTypeMicrosoftAzure} />
      </Wrapper>
    );
  }

  return (
    <Wrapper billingProfileFilter={billingProfileFilter}>
      <CardContent className={classes.cardsArea}>
        <Grid container spacing={2} direction="row">
          {assets.map((asset) => {
            const isHighlighted = asset.id === highlight;
            const unassignedAction = isDoitEmployee &&
              asset.data.type === AssetTypeMicrosoftAzure &&
              !asset.data.entity && (
                <Tooltip title="Asset is not assigned to an entity">
                  <IconButton onClick={onAssignToEntity(asset)} size="large">
                    <ErrorIcon className={classes.warningIcon} />
                  </IconButton>
                </Tooltip>
              );
            return (
              <Grid
                key={asset.ref.path}
                data-cy={`asset-${asset.id}`}
                size={{
                  xs: 12,
                  sm: 6,
                  md: 6,
                  lg: 4,
                  xl: 4,
                }}
              >
                <MicrosoftAzureAssetCard
                  asset={asset}
                  unassignedAction={unassignedAction}
                  highlight={isHighlighted}
                  onAssignToEntity={onAssignToEntity(asset)}
                  onRemoveAsset={onRemoveAsset(asset)}
                  onAddTag={() => {
                    onAddTag(asset);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
    </Wrapper>
  );
};

export default MicrosoftAzureAssetsTab;
