import { useCallback } from "react";

import {
  type QueryKey,
  useQuery,
  useQueryClient,
  type UseQueryOptions,
  type UseQueryResult,
} from "@tanstack/react-query";
import { type DocumentData, type Unsubscribe } from "firebase/firestore";
import noop from "lodash/noop";

import { type FirebaseDocumentSnapshotModel, type FirebaseQuerySnapshotModel } from "../core";

export type UseSubscriptionQueryOptions<TModel extends DocumentData, TError, R> = UseQueryOptions<
  FirebaseQuerySnapshotModel<TModel>,
  TError,
  R
> &
  (
    | {
        isSubscription: true;
        fetchFn?: undefined;
        subscribeFn: (
          cb: (data: FirebaseQuerySnapshotModel<TModel>) => void,
          error: (err: any) => void
        ) => Promise<Unsubscribe>;
      }
    | {
        isSubscription: false;
        fetchFn: () => Promise<FirebaseQuerySnapshotModel<TModel>>;
        subscribeFn: undefined;
      }
  );

export type UseSubscriptionDocumentOptions<TModel extends DocumentData, TError, R> = UseQueryOptions<
  FirebaseDocumentSnapshotModel<TModel>,
  TError,
  R
> &
  (
    | {
        isSubscription: true;
        fetchFn?: undefined;
        subscribeFn: (
          cb: (data: FirebaseDocumentSnapshotModel<TModel>) => void,
          error: (err: any) => void
        ) => Promise<Unsubscribe>;
      }
    | {
        isSubscription: false;
        fetchFn: () => Promise<FirebaseDocumentSnapshotModel<TModel>>;
        subscribeFn: undefined;
      }
  );

let cleanupCacheSubscriptionRegistered = false;

const cleanupCacheSubscription = ({ type, query }: { type: string; query: any }) => {
  if (type === "removed" && query.__unsubscribe) {
    query.__unsubscribe();
    delete query.__unsubscribe;
  }
};

export function useSubscriptionQuery<TModel extends DocumentData, TError, R = TModel>(
  queryKey: QueryKey | undefined,
  options: UseSubscriptionQueryOptions<TModel, TError, R>
): UseQueryResult<R, TError> {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  if (!cleanupCacheSubscriptionRegistered) {
    queryCache.subscribe(cleanupCacheSubscription);
    cleanupCacheSubscriptionRegistered = true;
  }

  const queryFn = useCallback(async () => {
    let resolvePromise: (data: FirebaseQuerySnapshotModel<TModel>) => void = noop as any;
    let rejectPromise: (err: any) => void = noop as any;
    const result = new Promise<FirebaseQuerySnapshotModel<TModel>>((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });

    if (options.isSubscription) {
      let firstRun = true;
      const unsubscribe = await options.subscribeFn((data) => {
        if (firstRun) {
          firstRun = false;
          resolvePromise(data);
        } else {
          queryClient.setQueryData(queryKey!, data);
        }
      }, rejectPromise);

      const cachedQuery = queryCache.find(queryKey!);
      if (cachedQuery) {
        ((cachedQuery as any).__unsubscribe as Unsubscribe | undefined)?.();
        (cachedQuery as any).__unsubscribe = unsubscribe;
      }
    } else {
      options
        .fetchFn()
        .then(resolvePromise)
        .catch((err: unknown) => {
          rejectPromise(err);
        });
    }

    return result;
  }, [options, queryCache, queryClient, queryKey]);

  return useQuery({
    ...options,
    queryFn,
    queryKey,
    retry: false,
    staleTime: options.isSubscription ? Infinity : 0,
    refetchInterval: undefined,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}

export function useSubscriptionDocument<TModel extends DocumentData, TError, R = TModel>(
  queryKey: QueryKey | undefined,
  options: UseSubscriptionDocumentOptions<TModel, TError, R>
): UseQueryResult<R, TError> {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();

  if (!cleanupCacheSubscriptionRegistered) {
    queryCache.subscribe(cleanupCacheSubscription);
    cleanupCacheSubscriptionRegistered = true;
  }

  const queryFn = useCallback(async () => {
    let resolvePromise: (data: FirebaseDocumentSnapshotModel<TModel>) => void = noop as any;
    let rejectPromise: (err: any) => void = noop as any;
    const result = new Promise<FirebaseDocumentSnapshotModel<TModel>>((resolve, reject) => {
      resolvePromise = resolve;
      rejectPromise = reject;
    });

    if (options.isSubscription) {
      let firstRun = true;
      const unsubscribe = await options.subscribeFn((data) => {
        if (firstRun) {
          firstRun = false;
          resolvePromise(data);
        } else {
          queryClient.setQueryData(queryKey!, data);
        }
      }, rejectPromise);

      const cachedQuery = queryCache.find(queryKey!);
      if (cachedQuery) {
        ((cachedQuery as any).__unsubscribe as Unsubscribe | undefined)?.();
        (cachedQuery as any).__unsubscribe = unsubscribe;
      }
    } else {
      options
        .fetchFn()
        .then(resolvePromise)
        .catch((err: unknown) => {
          rejectPromise(err);
        });
    }

    return result;
  }, [options, queryCache, queryClient, queryKey]);

  return useQuery({
    ...options,
    queryFn,
    queryKey,
    retry: false,
    staleTime: options.isSubscription ? Infinity : 0,
    refetchInterval: undefined,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
}
