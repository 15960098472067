import { Fragment, useEffect } from "react";

import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { CloudflowExecutionStatus, NodeExecutionStatus } from "@doitintl/cmp-models";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {
  Alert,
  Box,
  Divider,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { cloudflowTexts } from "../../../assets/texts";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { CircularProgressLoader } from "../../../Components/Loader";
import { useErrorSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { customersRoute } from "../../../Navigation/core/utils";
import { consoleErrorWithSentry } from "../../../utils";
import StatusChip, { executionStatus } from "../Components/StatusChip";
import CloudflowRunDetailsRow from "./CloudflowRunDetailsRow";
import { useExecutionModel } from "./hooks/useExecution";
import { useExecutionNodes } from "./hooks/useExecutionNode";
import { formatCloudFlowRunDetailsTime } from "./utils";
import ViewCloudFlowButton from "./ViewCloudFlowButton";

const CloudflowRunDetails = () => {
  const { executionId } = useParams<{ executionId: string }>();
  const history = useHistory();

  const showError = useErrorSnackbar(7);

  const { customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(customer);

  const customerId = customer.id;

  const { execution, loading: executionLoading, error: executionError } = useExecutionModel(executionId);
  const { nodes, loading: nodesLoading, error: nodesError } = useExecutionNodes(executionId);

  useEffect(() => {
    if (!executionLoading) {
      if (executionError || nodesError) {
        consoleErrorWithSentry(executionError || nodesError);
        showError("Failed to load execution or execution nodes");
        history.push(customersRoute(customerId, `cloudflow/history`));
        return;
      }

      if (!execution) {
        showError("Execution not found");
        history.push(customersRoute(customerId, `cloudflow/history`));
      }
    }
  }, [executionLoading, execution, executionError, nodesError, history, customerId, showError]);

  const isGlobalError =
    execution?.status === CloudflowExecutionStatus.FAILED &&
    !nodes?.some((node) => node.status === NodeExecutionStatus.FAILED);

  if (executionLoading || !execution) {
    return <CircularProgressLoader />;
  }

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 3, mb: 1 }}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={0} sx={{ ml: -1 }}>
          <IconButton
            to={customersRoute(customerId, `cloudflow/history`)}
            component={Link}
            aria-label="Back to CloudFlow list"
          >
            <ArrowBackIcon sx={{ color: "text.primary" }} />
          </IconButton>
          <Typography variant="h1" data-cy="title" sx={{ ml: 0, fontWeight: "fontWeightMedium" }}>
            {execution.cloudflowName || "CloudFlow History"}
          </Typography>
        </Stack>
        {!isPresentationMode && <ViewCloudFlowButton flowId={execution.cloudflowId} />}
      </Stack>

      <Stack spacing={2} divider={<Divider orientation="vertical" flexItem />} direction="row">
        <Box component="span">
          <Typography component="span" variant="body2" sx={{ color: "text.primary" }}>
            {cloudflowTexts.EXECUTION_HISTORY.RUN_STATUS}
          </Typography>{" "}
          <StatusChip status={execution.status} statusMap={executionStatus} />
        </Box>

        <Box component="span">
          <Typography component="span" variant="body2" sx={{ color: "text.primary" }}>
            {cloudflowTexts.EXECUTION_HISTORY.TRIGGER}
          </Typography>{" "}
          <Typography component="span" variant="body2" sx={{ color: "text.secondary" }}>
            {execution.triggeredBy}
          </Typography>
        </Box>

        <Box component="span">
          <Typography component="span" variant="body2" sx={{ color: "text.primary" }}>
            {cloudflowTexts.EXECUTION_HISTORY.START_TIME}:
          </Typography>{" "}
          <Typography component="span" variant="body2" sx={{ color: "text.secondary" }}>
            {formatCloudFlowRunDetailsTime(execution.startTime)}
          </Typography>
        </Box>

        <Box component="span">
          <Typography component="span" variant="body2" sx={{ color: "text.primary" }}>
            {cloudflowTexts.EXECUTION_HISTORY.END_TIME}:
          </Typography>{" "}
          <Typography component="span" variant="body2" sx={{ color: "text.secondary" }}>
            {formatCloudFlowRunDetailsTime(execution.endTime)}
          </Typography>
        </Box>
      </Stack>
      {isGlobalError && (
        <Alert severity="error" icon={<WarningAmberIcon />}>
          {execution.error}
        </Alert>
      )}
      {nodesLoading ? (
        <Box p={1} mt={6}>
          <FilterTableSkeleton />
        </Box>
      ) : (
        <TableContainer sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{cloudflowTexts.EXECUTION_HISTORY.STEP}</TableCell>
                <TableCell>{cloudflowTexts.EXECUTION_HISTORY.STATUS}</TableCell>
                <TableCell>{cloudflowTexts.EXECUTION_HISTORY.START_TIME}</TableCell>
                <TableCell>{cloudflowTexts.EXECUTION_HISTORY.END_TIME}</TableCell>
                <TableCell>{cloudflowTexts.EXECUTION_HISTORY.DURATION}</TableCell>
                <TableCell>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {nodes?.map((row) => (
                <Fragment key={row.nodeId}>
                  <CloudflowRunDetailsRow customerId={customerId} flowId={execution.cloudflowId} row={row} />
                </Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Stack>
  );
};
export default CloudflowRunDetails;
