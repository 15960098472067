import { type Cluster, ClusterConnectionStatus } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { CopyCodeBlock } from "../../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { OpenAWSConsole } from "../../../../../EKS/Components/OpenAWSConsole";
import { ASSOCIATE_ACCESS_POLICY_BASE_COMMAND, CREATE_ACCESS_ENTRY_BASE_COMMAND } from "../../../rbac";
import { getCodeVariablesForAwsAccessEntryCreation } from "./utils";

interface AwsAccessEntryActivationProps {
  accountId: string | null;
  clusters: Cluster[] | null;
  commandExecutionHelperText?: string;
}

const AwsAccessEntryActivation = ({
  accountId,
  clusters,
  commandExecutionHelperText,
}: AwsAccessEntryActivationProps) => {
  if (!clusters || clusters.length === 0) {
    return null;
  }

  const getBaseCommand = () =>
    clusters
      .map((cluster, index) => {
        const needsToCreateAccessEntry = cluster.connectionStatus !== ClusterConnectionStatus.HEALTHY;

        const command = needsToCreateAccessEntry
          ? `${CREATE_ACCESS_ENTRY_BASE_COMMAND} &&\n${ASSOCIATE_ACCESS_POLICY_BASE_COMMAND}`
          : ASSOCIATE_ACCESS_POLICY_BASE_COMMAND;

        return command
          .replace(/\$CLUSTER_NAME/g, `$CLUSTER_${index}_NAME`)
          .replace(/\$ROLE_NAME/g, `$CLUSTER_${index}_ROLE_NAME`)
          .replace(/\$REGION/g, `$CLUSTER_${index}_REGION`);
      })
      .join(";\n\n");

  const getHelpText = () => {
    const clusterText = clusters.length > 1 ? "clusters" : "cluster";

    return `Associate the specified IAM principal with the EKS ${clusterText}`;
  };

  return (
    <Stack spacing={4} sx={{ pt: 3 }}>
      <Stack spacing={2}>
        <Typography variant="body2">
          1. {commandExecutionHelperText ?? "Run the following commands on the AWS Console or a method of your choice"}
        </Typography>
        <OpenAWSConsole />
      </Stack>

      <Stack spacing={1}>
        <Typography variant="body2" color="text.secondary">
          {getHelpText()}
        </Typography>
        <CopyCodeBlock
          maxHeight={300}
          base={getBaseCommand()}
          variables={getCodeVariablesForAwsAccessEntryCreation(clusters, accountId ?? "")}
        />
      </Stack>
    </Stack>
  );
};

export default AwsAccessEntryActivation;
