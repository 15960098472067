import { createContext, useContext } from "react";

import { type AvaFeedback } from "@doitintl/cmp-models";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import type { AppRouter } from "@doitintl/trpc/ava-core-service/router";

type ContextProps = {
  baseURL: string;
};

const defaultValue: ContextProps = {
  baseURL: "",
};

const trpcContext = createContext(defaultValue);
export const TrpcContextProvider = trpcContext.Provider;
export const useTrpcContext = () => {
  const { baseURL } = useContext(trpcContext);
  const trpc = createTRPCProxyClient<AppRouter>({
    links: [
      httpBatchLink({
        url: `${baseURL}/api/ava/trpc`,
      }),
    ],
  });

  const deleteConversation = async (conversationId: string) => {
    await trpc.deleteConversation.mutate({ conversationId });
  };

  const fetchFeedback = async (conversationId: string, answerId: string, feedback: AvaFeedback) => {
    await trpc.feedback.mutate({ conversationId, answerId, feedback });
  };

  const stopGeneration = async ({
    conversationId,
    answerId,
    partialMessage,
    stopped,
    question,
  }: {
    conversationId: string;
    answerId: string;
    partialMessage: string;
    stopped?: boolean;
    question?: string;
  }) => {
    await trpc.stopGeneration.mutate({ conversationId, answerId, partialMessage, stopped, question });
  };
  const addReportLinkAndChartTypeToAvaConfig = async ({
    conversationId,
    messageId,
    reportLink,
    chartType,
  }: {
    conversationId: string;
    messageId: string;
    reportLink?: string;
    chartType?: string;
  }) => {
    await trpc.addReportLinkAndChartTypeToAvaConfig.mutate({ conversationId, messageId, reportLink, chartType });
  };

  const enrichConversionWithData = async (conversationId: string, data: Record<string, string>) => {
    await trpc.enrichConversionWithData.mutate({ conversationId, data });
  };

  const summarizeConversation = async (conversationId: string) => trpc.summarizeConversation.mutate({ conversationId });

  const summarizeDescription = async (description: string) => trpc.summarizeDescription.mutate({ description });

  return {
    deleteConversation,
    fetchFeedback,
    stopGeneration,
    addReportLinkAndChartTypeToAvaConfig,
    enrichConversionWithData,
    summarizeDescription,
    summarizeConversation,
  };
};
