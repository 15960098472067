import { useMemo } from "react";

import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import {
  type Assignee,
  type SupportZendeskTicketModel,
  type UserModel,
  ZendeskTicketStatus,
} from "@doitintl/cmp-models";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import { DateTime } from "luxon";

import ZendeskIcon from "../../../assets/zendeskIcon.png";
import { AvaIcon } from "../../../Components/Ava/Messenger/AvaIcon";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import { avaAgentIdDev, avaAgentIdProd } from "../../../constants";
import { transformPriority, transformStatus, truncate } from "../../../utils/common";
import { type AssigneeUsers } from "../../hooks/useAssigneeUsers";
import SupportItemIcon from "../SupportItemIcon";
import { getStatusColor } from "./TicketsTableColumns";

type SupportTicketsRowProps = {
  isDoitEmployee: boolean;
  supportZendeskTicket: SupportZendeskTicketModel;
  assigneeUsers: AssigneeUsers;
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: "0px solid #e0e0e0",
    width: 32,
    height: 32,
    marginTop: 2,
    marginRight: 5,
  },
  zendeskIcon: {
    width: 25,
    marginRight: 4,
  },
  subject: {
    maxWidth: 320,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    [theme.breakpoints.down("xl")]: {
      maxWidth: 250,
    },
    [theme.breakpoints.down("lg")]: {
      maxWidth: 250,
    },
  },
}));

function buildDateTime(timestamp?: SupportZendeskTicketModel["firestoreAddedDate"]): DateTime<false> | DateTime<true> {
  if (!timestamp) {
    return DateTime.invalid("no timestamp provided");
  }

  return DateTime.fromSeconds(timestamp.seconds);
}

/**
 * Row for {@link SupportTicketsTable} that renders a firestore-stored support ticket.
 * @param isDoitEmployee - Whether the current user is a Doit employee.
 * @param supportZendeskTicket - The support ticket to render.
 * @param assigneeUsers - map of assignee emails and their corresponding DoiT UserModels.
 */
const SupportTicketsRow = ({ isDoitEmployee, supportZendeskTicket, assigneeUsers }: SupportTicketsRowProps) => {
  const isAvaAgent = (author?: Assignee): boolean =>
    !!author && [avaAgentIdDev, avaAgentIdProd].includes(Number(author.id));
  const { t } = useTranslation("services");
  const classes = useStyles();

  const zendeskURL = (id: string): string => `https://doitintl.zendesk.com/agent/tickets/${id}`;
  const getTicketLink = (id: string): void => {
    window.open(zendeskURL(id), "_blank");
  };

  const assigneeUser: UserModel | undefined = assigneeUsers.get(supportZendeskTicket.ticket.assignee.email);

  const rateLink = (
    <Typography
      to={`support/tickets/${supportZendeskTicket.zendeskId}/rate`}
      component={RouterLink}
      color="inherit"
      variant="body2"
      data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-subject`}
    >
      Rate
    </Typography>
  );

  const assigneeName: string = useMemo(() => {
    if (!supportZendeskTicket.ticket.assignee) {
      return "Unassigned";
    }

    if (isAvaAgent(supportZendeskTicket.ticket.assignee)) {
      return "Ava";
    }

    return assigneeUser?.displayName ?? supportZendeskTicket.ticket.assignee.email;
  }, [assigneeUser?.displayName, supportZendeskTicket.ticket.assignee]);

  const getSatisfactionText = () =>
    ![ZendeskTicketStatus.CLOSED, ZendeskTicketStatus.SOLVED].includes(supportZendeskTicket.ticket.status)
      ? "N/A"
      : (supportZendeskTicket.ticket.satisfaction_rating?.rating.toUpperCase() ?? "N/A");

  return (
    <CellsWrapper>
      <TableCell data-cy={`ticketRow-${supportZendeskTicket.zendeskId}`}>
        <div style={{ display: "flex", alignItems: "center" }}>
          {isDoitEmployee && (
            <Link
              href="#"
              onClick={() => {
                getTicketLink(supportZendeskTicket.zendeskId);
              }}
              style={{ marginTop: 4 }}
              data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-zendDesk`}
            >
              <img alt="zendesk icon" className={classes.zendeskIcon} src={ZendeskIcon} />
            </Link>
          )}
          <Typography
            to={`support/tickets/${supportZendeskTicket.zendeskId}`}
            component={RouterLink}
            color="inherit"
            variant="body2"
            data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-id`}
          >
            #{supportZendeskTicket.zendeskId}
          </Typography>
        </div>
      </TableCell>
      <TableCell>
        <Typography
          to={`support/tickets/${supportZendeskTicket.zendeskId}`}
          component={RouterLink}
          color="inherit"
          variant="body2"
          data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-subject`}
        >
          {truncate(supportZendeskTicket.ticket.subject, 50, false)}
        </Typography>
      </TableCell>
      <TableCell>
        {!!supportZendeskTicket.ticket.fields.Platform && (
          <SupportItemIcon platformValue={supportZendeskTicket.ticket.fields.Platform} />
        )}
      </TableCell>
      <TableCell>
        <Tooltip title={assigneeName}>
          {isAvaAgent(supportZendeskTicket.ticket.assignee) ? (
            <Box data-cy={`ava-assignee-icon-${supportZendeskTicket.zendeskId}`}>
              <AvaIcon />
            </Box>
          ) : (
            <Avatar
              alt="Agent"
              src={
                assigneeUser?.enrichment?.avatar ??
                undefined /* This looks strange but avatar may be null, and src doesn't like null. */
              }
              className={classes.avatar}
            />
          )}
        </Tooltip>
      </TableCell>
      <TableCell>
        <Typography variant="body2" component="span" data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-priority`}>
          {transformPriority(supportZendeskTicket.ticket.priority, t)}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          noWrap
          data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-ticketRequester`}
        >
          {supportZendeskTicket.ticket.requester_email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          noWrap
          data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-satisfaction`}
        >
          {supportZendeskTicket.ticket.status === ZendeskTicketStatus.SOLVED &&
          supportZendeskTicket.ticket.satisfaction_rating?.rating === "offered"
            ? rateLink
            : getSatisfactionText()}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-ticketLastUpdated`}
        >
          {buildDateTime(supportZendeskTicket.ticket.updated_at).isValid
            ? buildDateTime(supportZendeskTicket.ticket.updated_at).toLocaleString(DateTime.DATETIME_MED)
            : "-"}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography
          variant="body2"
          component="span"
          noWrap
          data-cy={`ticketRow-${supportZendeskTicket.zendeskId}-ticketStatus`}
        >
          <Chip
            variant="status"
            color={getStatusColor(supportZendeskTicket.ticket.status)}
            label={transformStatus(supportZendeskTicket.ticket.status, t)}
          />
        </Typography>
      </TableCell>
    </CellsWrapper>
  );
};

export default SupportTicketsRow;
