import { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import {
  type Adjustment,
  type CommitmentManagersModel,
  ContractModel,
  CurrenciesMap,
  type CurrencyCode,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  InputAdornment,
  Link,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { Form, Formik } from "formik";
import { DateTime } from "luxon";
import * as Yup from "yup";

import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../Components/DefinitionList";
import { formatValueWithCurrency } from "../../Components/hooks/useFormatter";
import { MetadataCard } from "../../Components/MetadataCard";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useFullScreen } from "../../utils/dialog";
import { TimestampFromDate } from "../../utils/firebase";
import { StatusChip } from "../RampPlans/components/StatusChips";
import { InfoTooltip, MarketplaceText } from "./Tooltips";
import { type SeriesData } from "./types";
import { getContractName, getPeriodStatus } from "./utils";

const getContractLink = async (contractId: string, pathname: string) => {
  const contract = await getCollection(ContractModel).doc(contractId).get();
  const contractData = contract.asModelData();
  if (!contractData) {
    return null;
  }
  const contractName = getContractName(
    contractData.type,
    contractData.startDate.toDate(),
    contractData.endDate?.toDate()
  );
  return (
    <Link href={`${pathname}/contracts/contracts-list/${contractId}/view`} target="_blank" rel="noopener noreferrer">
      {contractName}
    </Link>
  );
};
interface CommitmentMetadataCardsProps {
  commitmentManager: CommitmentManagersModel;
  commitmentManagerRef: ModelReference<CommitmentManagersModel>;
  periodIndex: number;
  seriesData: SeriesData;
  currencyCode: CurrencyCode;
}

interface AddAdjustmentFormValues {
  date: string;
  amount: string;
  invoiceLink: string;
  comments: string;
}

export const CommitmentMetadataCards = ({
  commitmentManager,
  commitmentManagerRef,
  periodIndex,
  seriesData,
  currencyCode,
}: CommitmentMetadataCardsProps) => {
  const { isMobile } = useFullScreen("lg");
  const history = useHistory();
  const [contractLinks, setContractLinks] = useState<(React.JSX.Element | null)[]>([]);
  const [isContractsDialogOpen, setIsContractsDialogOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [isConfigDialogOpen, setIsConfigDialogOpen] = useState(false);
  const [isAdjustmentDialogOpen, setIsAdjustmentDialogOpen] = useState(false);
  const [isAdjustmentListDialogOpen, setIsAdjustmentListDialogOpen] = useState(false);
  const [currentAdjustmentIndex, setCurrentAdjustmentIndex] = useState<number | null>(null);
  const [filterDialogContent, setFilterDialogContent] = useState<{ title: string; items: string[] }>({
    title: "",
    items: [],
  });

  const showErrorSnackbar = useErrorSnackbar();
  const showSuccessSnackbar = useSuccessSnackbar();

  useEffect(() => {
    if (!commitmentManager?.contracts) return;

    const pathname = history.location.pathname.split("/").slice(0, -2).join("/");
    Promise.all(commitmentManager.contracts.map((contractId) => getContractLink(contractId, pathname))).then(
      (links) => {
        setContractLinks(links);
      }
    );
  }, [commitmentManager?.contracts, history.location.pathname]);

  const openContractsDialog = () => {
    setIsContractsDialogOpen(true);
  };
  const closeContractsDialog = () => {
    setIsContractsDialogOpen(false);
  };

  const openFilterDialog = (title: string, items: string[]) => {
    setFilterDialogContent({ title, items });
    setIsFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setIsFilterDialogOpen(false);
  };

  const handleOpenConfigDialog = () => {
    setIsConfigDialogOpen(true);
  };

  const handleCloseConfigDialog = () => {
    setIsConfigDialogOpen(false);
  };

  const handleOpenAdjustmentDialog = (adjustmentIndex?: number) => {
    if (adjustmentIndex !== undefined) {
      setCurrentAdjustmentIndex(adjustmentIndex);
    } else {
      setCurrentAdjustmentIndex(null);
    }
    setIsAdjustmentDialogOpen(true);
  };

  const handleCloseAdjustmentDialog = () => {
    setCurrentAdjustmentIndex(null);
    setIsAdjustmentDialogOpen(false);
  };

  const currentPeriod = commitmentManager.periods[periodIndex];
  const currentPeriodSpend = commitmentManager.periodsSpend?.[periodIndex];
  const startDate = currentPeriod.startDate.toDate();
  const endDate = currentPeriod.endDate.toDate();
  const periodStatus = getPeriodStatus(startDate, endDate);

  const startDateFormatted = startDate.toLocaleDateString(undefined, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const endDateFormatted = endDate.toLocaleDateString(undefined, {
    day: "2-digit",
    month: "short",
    year: "numeric",
  });

  const contractSummaryText =
    contractLinks.length > 0
      ? `${contractLinks[0]?.props.children}, ${contractLinks.length > 1 ? `+${contractLinks.length - 1}` : ""}`
      : null;

  const spendToDate = seriesData.totalEligible[seriesData.totalEligible.length - 1]?.y ?? 0;
  const commitmentValue = currentPeriod.commitmentValue ?? 0;
  const shortfallRollover = currentPeriodSpend?.shortfallRollover ?? 0;
  const rolloverFromPrevPeriod = currentPeriodSpend?.rolloverFromPrevPeriod ?? 0;
  const adjustments = Array.isArray(commitmentManager.adjustments) ? commitmentManager.adjustments : [];

  const handleDeleteAdjustment = async (indexToDelete: number) => {
    try {
      const updatedAdjustments = [...adjustments];
      updatedAdjustments.splice(indexToDelete, 1);

      await commitmentManagerRef.update({
        adjustments: updatedAdjustments,
      });

      showSuccessSnackbar("Adjustment deleted successfully");
    } catch (error) {
      showErrorSnackbar("Failed to delete adjustment");
    }
  };

  const totalAdjustments = adjustments.reduce((sum: number, adj: Adjustment) => sum + Number(adj.amount), 0);
  const remaining = commitmentValue - spendToDate - rolloverFromPrevPeriod - shortfallRollover - totalAdjustments;
  const marketplaceLimitPercentage = `${currentPeriod.marketplaceLimitPercentage}%`;
  const excessRollOverPercentage = `${commitmentManager.excessRollOverPercentage}%`;
  const shortfallRollOverPercentage = `${commitmentManager.shortfallRollOverPercentage}%`;
  const serviceExclusion = commitmentManager.serviceFilter?.inverse;
  const serviceFilter = commitmentManager.serviceFilter?.values;
  const billingAccounts = commitmentManager.billingAccounts;
  const skuExclusion = commitmentManager.skuFilter?.inverse;
  const skuFilter = commitmentManager.skuFilter?.values;
  const accountLabel = commitmentManager.contractType === "amazon-web-services" ? "Payer accounts" : "Billing accounts";

  const spendToDateFormatted = formatValueWithCurrency(spendToDate, 2, currencyCode, true);
  const valueRemainingFormatted = formatValueWithCurrency(Math.abs(remaining), 2, currencyCode, true);
  const shortfallRolloverFormatted = formatValueWithCurrency(shortfallRollover, 2, currencyCode, true);
  const rolloverFromPrevPeriodFormatted = formatValueWithCurrency(rolloverFromPrevPeriod, 2, currencyCode, true);
  const commitmentValueFormatted = formatValueWithCurrency(commitmentValue, 2, currencyCode, true);
  const totalFormatted = formatValueWithCurrency(
    rolloverFromPrevPeriod + spendToDate + shortfallRollover,
    2,
    currencyCode,
    true
  );

  const formatDateForInput = (date: Date) => date.toISOString().split("T")[0];

  const today = formatDateForInput(new Date());

  const initialAddSpendValues: AddAdjustmentFormValues = {
    date: today,
    amount: "",
    invoiceLink: "",
    comments: "",
  };

  const getInitialValues = () => {
    if (currentAdjustmentIndex !== null && adjustments[currentAdjustmentIndex]) {
      const adjustment = adjustments[currentAdjustmentIndex];
      return {
        date: formatDateForInput(adjustment.date.toDate()),
        amount: adjustment.amount.toString(),
        invoiceLink: adjustment.invoiceLink || "",
        comments: adjustment.comments || "",
      };
    }
    return initialAddSpendValues;
  };

  const addSpendValidationSchema = Yup.object({
    date: Yup.date().required("Date is required"),
    amount: Yup.number().required("Amount is required").positive("Amount must be positive"),
    invoiceLink: Yup.string().url("Invalid URL"),
    comments: Yup.string(),
  });

  const handleAddAdjustment = async (
    values: AddAdjustmentFormValues,
    { setSubmitting, resetForm }: { setSubmitting: (isSubmitting: boolean) => void; resetForm: () => void }
  ) => {
    try {
      if (currentAdjustmentIndex !== null) {
        const updatedAdjustments = [...adjustments];
        updatedAdjustments[currentAdjustmentIndex] = {
          date: TimestampFromDate(new Date(values.date)),
          amount: Number(values.amount),
          comments: values.comments,
          invoiceLink: values.invoiceLink,
        };

        await commitmentManagerRef.update({
          adjustments: updatedAdjustments,
        });

        showSuccessSnackbar("Adjustment updated successfully");
      } else {
        await commitmentManagerRef.update({
          adjustments: [
            ...(commitmentManager.adjustments ?? []),
            {
              date: TimestampFromDate(new Date(values.date)),
              amount: Number(values.amount),
              comments: values.comments,
              invoiceLink: values.invoiceLink,
            },
          ],
        });

        showSuccessSnackbar("Adjustment added successfully");
      }

      resetForm();
      setIsAdjustmentDialogOpen(false);
      setCurrentAdjustmentIndex(null);
      setSubmitting(false);
    } catch (error) {
      showErrorSnackbar(currentAdjustmentIndex !== null ? "Failed to update adjustment" : "Failed to add adjustment");
      setSubmitting(false);
    }
  };

  return (
    <>
      <Stack
        direction={isMobile ? "column" : "row"}
        spacing={2}
        sx={{
          justifyContent: "stretch",
        }}
      >
        <MetadataCard
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Typography>Period details</Typography>
              <Button variant="text" onClick={handleOpenConfigDialog} size="small" sx={{ p: 0 }}>
                View configuration
              </Button>
            </Box>
          }
        >
          <DefinitionList>
            <DefinitionListTerm>Status:</DefinitionListTerm>
            <DefinitionListDesc>
              <StatusChip status={periodStatus} />
            </DefinitionListDesc>
            <DefinitionListTerm>Start date:</DefinitionListTerm>
            <DefinitionListDesc>{startDateFormatted}</DefinitionListDesc>
            <DefinitionListTerm>End date:</DefinitionListTerm>
            <DefinitionListDesc>{endDateFormatted}</DefinitionListDesc>
            {commitmentManager.contracts && commitmentManager.contracts.length > 0 && (
              <>
                <DefinitionListTerm>Contract(s):</DefinitionListTerm>
                <DefinitionListDesc>
                  {contractLinks.length === 1 ? (
                    contractLinks[0]
                  ) : (
                    <Box
                      onClick={openContractsDialog}
                      sx={{ cursor: "pointer", "&:hover": { textDecoration: "underline" } }}
                    >
                      {contractSummaryText}
                    </Box>
                  )}
                </DefinitionListDesc>
              </>
            )}
          </DefinitionList>
        </MetadataCard>

        <MetadataCard
          title={
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
              <Typography>Period commitment</Typography>
              <Button
                variant="text"
                onClick={() => {
                  handleOpenAdjustmentDialog();
                }}
                size="small"
                sx={{ p: 0 }}
              >
                Add adjustment
              </Button>
            </Box>
          }
        >
          <DefinitionList>
            <DefinitionListTerm>Spend to date:</DefinitionListTerm>
            <DefinitionListDesc>{spendToDateFormatted}</DefinitionListDesc>
            {totalAdjustments !== 0 && (
              <>
                <DefinitionListTerm>Adjustments:</DefinitionListTerm>
                <DefinitionListDesc>
                  <Box
                    onClick={() => {
                      setIsAdjustmentListDialogOpen(true);
                    }}
                    sx={{ cursor: "pointer", color: "primary.main", textDecoration: "underline" }}
                  >
                    {formatValueWithCurrency(totalAdjustments, 2, currencyCode, true)}
                  </Box>
                </DefinitionListDesc>
              </>
            )}
            {rolloverFromPrevPeriod !== 0 && (
              <>
                <DefinitionListTerm>Rollover from previous period:</DefinitionListTerm>
                <DefinitionListDesc>{rolloverFromPrevPeriodFormatted}</DefinitionListDesc>
              </>
            )}
            {shortfallRollover > 0 && (
              <>
                <DefinitionListTerm>Shortfall rollover:</DefinitionListTerm>
                <DefinitionListDesc>{shortfallRolloverFormatted}</DefinitionListDesc>
              </>
            )}
            {totalFormatted !== spendToDateFormatted && (
              <>
                <DefinitionListTerm>Total:</DefinitionListTerm>
                <DefinitionListDesc>{totalFormatted}</DefinitionListDesc>
              </>
            )}
            <DefinitionListTerm>{remaining < 0 ? "Excess:" : "Remaining:"}</DefinitionListTerm>
            <DefinitionListDesc>{valueRemainingFormatted}</DefinitionListDesc>
            <DefinitionListTerm>Commitment value:</DefinitionListTerm>
            <DefinitionListDesc>{commitmentValueFormatted}</DefinitionListDesc>
          </DefinitionList>
        </MetadataCard>
      </Stack>

      <Dialog
        open={isContractsDialogOpen}
        onClose={closeContractsDialog}
        aria-labelledby="contracts-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="contracts-dialog-title">Contracts</DialogTitle>
        <DialogContent>
          <Stack component="ul" spacing={1} sx={{ pl: 2 }}>
            {contractLinks.map((link, index) => (
              <li key={index}>{link}</li>
            ))}
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeContractsDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isConfigDialogOpen}
        onClose={handleCloseConfigDialog}
        aria-labelledby="config-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="config-dialog-title">Configuration</DialogTitle>
        <DialogContent>
          <DefinitionList>
            <DefinitionListTerm>Commitment value:</DefinitionListTerm>
            <DefinitionListDesc>{commitmentValueFormatted}</DefinitionListDesc>
            <DefinitionListTerm>Marketplace limit:</DefinitionListTerm>
            <Stack direction="row" alignItems="center" spacing={0.5}>
              <DefinitionListDesc>{marketplaceLimitPercentage}</DefinitionListDesc>
              <InfoTooltip text={MarketplaceText} />
            </Stack>
            {(commitmentManager.excessRollOverPercentage ?? 0) > 0 && (
              <>
                <DefinitionListTerm>Excess rollover:</DefinitionListTerm>
                <DefinitionListDesc>{excessRollOverPercentage}</DefinitionListDesc>
              </>
            )}
            {(commitmentManager.shortfallRollOverPercentage ?? 0) > 0 && (
              <>
                <DefinitionListTerm>Shortfall rollover:</DefinitionListTerm>
                <DefinitionListDesc>{shortfallRollOverPercentage}</DefinitionListDesc>
              </>
            )}
            {billingAccounts && billingAccounts.length > 0 && (
              <>
                <DefinitionListTerm>{accountLabel}:</DefinitionListTerm>
                <DefinitionListDesc>
                  <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
                    {billingAccounts.slice(0, 2).map((account, index) => (
                      <Chip key={index} label={account} size="small" variant="outlined" />
                    ))}
                    {billingAccounts.length > 2 && (
                      <Chip
                        label={`+${billingAccounts.length - 2}`}
                        size="small"
                        variant="outlined"
                        onClick={() => {
                          openFilterDialog(accountLabel, billingAccounts);
                        }}
                        sx={{ cursor: "pointer" }}
                      />
                    )}
                  </Stack>
                </DefinitionListDesc>
              </>
            )}
            {serviceFilter && serviceFilter.length > 0 && (
              <>
                <DefinitionListTerm>Services:</DefinitionListTerm>
                <DefinitionListDesc>
                  <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      {serviceExclusion ? "is not" : "is"}
                    </Typography>
                    {serviceFilter.slice(0, 2).map((service, index) => (
                      <Chip key={index} label={service} size="small" variant="outlined" />
                    ))}
                    {serviceFilter.length > 2 && (
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openFilterDialog("Services", serviceFilter);
                        }}
                        style={{
                          marginLeft: "4px",
                        }}
                      >
                        +{serviceFilter.length - 2}
                      </Link>
                    )}
                  </Stack>
                </DefinitionListDesc>
              </>
            )}
            {skuFilter && skuFilter.length > 0 && (
              <>
                <DefinitionListTerm>SKUs:</DefinitionListTerm>
                <DefinitionListDesc>
                  <Stack direction="row" spacing={0.5} flexWrap="wrap" gap={0.5}>
                    <Typography variant="body2" sx={{ mb: 0.5 }}>
                      {skuExclusion ? "is not" : "is"}
                    </Typography>
                    {skuFilter.slice(0, 2).map((sku, index) => (
                      <Chip key={index} label={sku} size="small" variant="outlined" />
                    ))}
                    {skuFilter.length > 2 && (
                      <Link
                        href="#"
                        onClick={(e) => {
                          e.preventDefault();
                          openFilterDialog("SKUs", skuFilter);
                        }}
                        style={{
                          marginLeft: "4px",
                        }}
                      >
                        +{skuFilter.length - 2}
                      </Link>
                    )}
                  </Stack>
                </DefinitionListDesc>
              </>
            )}
          </DefinitionList>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseConfigDialog} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isFilterDialogOpen}
        onClose={handleCloseFilterDialog}
        aria-labelledby="filter-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <Box sx={{ p: 4 }}>
          <Typography id="filter-dialog-title" variant="h6" component="h2" gutterBottom>
            {filterDialogContent.title}
          </Typography>
          <Stack component="ul" spacing={1} sx={{ pl: 2 }}>
            {filterDialogContent.items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </Stack>
        </Box>
      </Dialog>

      <Dialog
        open={isAdjustmentListDialogOpen}
        onClose={() => {
          setIsAdjustmentListDialogOpen(false);
        }}
        aria-labelledby="adjustments-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="adjustments-dialog-title">Adjustments</DialogTitle>
        <DialogContent>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Date</TableCell>
                  <TableCell>Amount</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {adjustments.map((adjustment, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {adjustment.date.toDate().toLocaleDateString(undefined, {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                      })}
                    </TableCell>
                    <TableCell>{formatValueWithCurrency(adjustment.amount, 2, currencyCode, true)}</TableCell>
                    <TableCell align="right">
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {adjustment.invoiceLink && (
                          <Button
                            size="small"
                            sx={{ minWidth: "auto", p: 0.5 }}
                            component="a"
                            href={adjustment.invoiceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <OpenInNewIcon fontSize="small" />
                          </Button>
                        )}
                        <Button
                          size="small"
                          sx={{ minWidth: "auto", p: 0.5 }}
                          onClick={() => {
                            handleOpenAdjustmentDialog(index);
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </Button>
                        <Button
                          size="small"
                          sx={{ minWidth: "auto", p: 0.5 }}
                          onClick={() => {
                            handleDeleteAdjustment(index);
                          }}
                          color="error"
                        >
                          <DeleteIcon fontSize="small" />
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    {formatValueWithCurrency(totalAdjustments, 2, currencyCode, true)}
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            onClick={() => {
              setIsAdjustmentListDialogOpen(false);
            }}
            color="primary"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isAdjustmentDialogOpen}
        onClose={handleCloseAdjustmentDialog}
        aria-labelledby="add-adjustment-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle id="add-adjustment-dialog-title">
          {currentAdjustmentIndex !== null ? "Edit adjustment" : "Add adjustment"}
        </DialogTitle>
        <Formik
          initialValues={getInitialValues()}
          validationSchema={addSpendValidationSchema}
          onSubmit={handleAddAdjustment}
          enableReinitialize
        >
          {({ values, errors, touched, handleChange, handleBlur, isSubmitting }) => (
            <Form>
              <DialogContent>
                <Stack spacing={3}>
                  <DatePicker
                    disableMaskedInput
                    label="Date"
                    value={DateTime.fromISO(values.date)}
                    inputFormat="dd MMMM yyyy"
                    onChange={(e) => {
                      handleChange({ target: { name: "date", value: e ? e.toFormat("yyyy-MM-dd") : "" } });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={touched.date && Boolean(errors.date)}
                        helperText={touched.date && errors.date}
                        fullWidth
                        required
                        variant="outlined"
                      />
                    )}
                  />

                  <TextField
                    fullWidth
                    id="amount"
                    name="amount"
                    label="Amount"
                    type="number"
                    value={values.amount}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.amount && Boolean(errors.amount)}
                    helperText={touched.amount && errors.amount}
                    slotProps={{
                      input: {
                        startAdornment: <InputAdornment position="start">{CurrenciesMap[currencyCode]}</InputAdornment>,
                      },
                    }}
                    required
                  />

                  <TextField
                    fullWidth
                    id="invoiceLink"
                    name="invoiceLink"
                    label="Invoice link"
                    value={values.invoiceLink}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.invoiceLink && Boolean(errors.invoiceLink)}
                    helperText={touched.invoiceLink && errors.invoiceLink}
                  />

                  <TextField
                    fullWidth
                    id="comments"
                    name="comments"
                    label="Comments"
                    multiline
                    rows={3}
                    value={values.comments}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.comments && Boolean(errors.comments)}
                    helperText={touched.comments && errors.comments}
                  />
                </Stack>
              </DialogContent>
              <Divider />
              <DialogActions>
                <Button onClick={handleCloseAdjustmentDialog} color="primary">
                  Cancel
                </Button>
                <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
                  {currentAdjustmentIndex !== null ? "Update" : "Add"}
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  );
};
