import { memo } from "react";

import EditIcon from "@mui/icons-material/Edit";
import { IconButton, Stack, Typography } from "@mui/material";

import { useModalManager } from "./CloudflowModalsProvider";

type Props = {
  title?: string;
  placeholder: string;
  disabled?: boolean;
  titleType: "cloudflow" | "node";
  maxWidth?: number;
};

const Title = ({ title, placeholder, disabled = false, titleType, maxWidth = 350 }: Props) => {
  const { openModal } = useModalManager();

  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Typography
        variant="h4"
        color="text.primary"
        sx={{
          maxWidth,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {title || placeholder}
      </Typography>
      <IconButton
        size="small"
        onClick={() => {
          openModal(titleType === "cloudflow" ? "editCloudflowTitle" : "editNodeTitle");
        }}
        disabled={disabled}
      >
        <EditIcon fontSize="small" color={disabled ? "disabled" : "primary"} />
      </IconButton>
    </Stack>
  );
};

const MemoizedTitle = memo(Title);
export default MemoizedTitle;
