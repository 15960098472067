export const text = {
  SAVING_MESSAGE: "Saving...",
  PROFILE_TAB_LABEL: "Profile",
  PERMISSIONS_TAB_LABEL: "Permissions",
  PERMISSIONS_PANEL_HEADER: "Permissions",
  MUST_PICK_ROLE_MESSAGE: "Please select a role",
  ROLE_PERMISSIONS_HEADER: "Role Entitlements",
  LEGACY_PERMISSIONS_HEADER: "Legacy Permissions",
  BILLING_PROFILES_PANEL_HEADER: "Billing Profiles",
  NOTIFICATIONS_TAB_LABEL: "Notifications",
  NOTIFICATIONS_TAB_HEADER: "Notifications",
  API_TAB_LABEL: "API",
  API_TAB_HEADER: "API",
  API_TAB_GENERATE_KEY_HEADER: "Generate API Key",
  API_TAB_LEARN_TO_USE_DESCRIPTION: "Learn how to use the APIs at ",
  API_TAB_GENERATE_KEY_DESCRIPTION: "Generate your API key by clicking on <i>Generate API key</i>.",
  API_TAB_CUSTOMER_ID_INFO:
    "When using our public APIs, DoIT employees need to provide the relevant Customer ID when using their own DoIT API keys. The Customer ID is part of the URL. For example the ID for this customer (doit.com) is:",
  API_TAB_EXISTING_KEY_DESCRIPTION:
    "For security reasons we cannot display your API Key. To generate a new one, please delete the previous one.",
  API_TAB_GENERATE_BUTTON_TEXT: "Generate API Key",
  API_TAB_GENERATED_KEY_WARNING:
    "Make sure to copy and store this key. We won't show it again after you leave this page.",
  API_TAB_DELETE_BUTTON_TEXT: "Delete Key",
  API_TAB_DELETE_DIALOGUE_TITLE: "Delete API Key?",
  API_TAB_DELETE_DIALOGUE_TEXT: "After deleting you will be able to generate a new key.",
  API_TAB_ACTIONS_DISABLED_TIP: "* You can manage only your own Access Token",
  ROLE_CONFIRMATION_DIALOG_Header: "Role confirmation",
  ROLE_CONFIRMATION_DIALOG_MESSAGE: "Are you sure that you want to change the role?",
  ROLE_CONFIRMATION_DIALOG_SUBMIT: "Update",
  ROLE_CONFIRMATION_DIALOG_CANCEL: "Cancel",
  ROLE_UPDATE_DISABLED_DUE_TO_SSO_ENABLED_ALERT: "Changing user role is only available through your identity provider.",
  PERMISSIONS_ERROR: "You don't have permission to perform this action",
  INVITE_USER_DISABLED_DUE_TO_SSO_ENABLED_TOOLTIP: "Inviting users is only available through your identity provider",
  INVITE_USER_BTN_TEXT: "Invite user",
  ANOMALY_DELTA_NOTIFICATION_DESC:
    "Cost of anomaly is the gap between the actual cost and the maximum cost in the normal range",
  ACCOUNT_IMPERSONATION_UNSUCCESSFUL: "Unable to impersonate user",
};

export const API_LINK_ADDRESS = "developer.doit.com";
