import React from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Chip, Tooltip } from "@mui/material";

interface EasyWinChipProps {
  tooltipText: string;
}

export const EasyWinChip: React.FC<EasyWinChipProps> = ({ tooltipText }) => (
  <Chip
    label={
      <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
        Easy win
        <Tooltip title={tooltipText} arrow>
          <InfoOutlinedIcon fontSize="small" />
        </Tooltip>
      </Box>
    }
    variant="filled"
    color="primary"
    size="small"
  />
);
