import { useMemo } from "react";

import { DoitRole } from "@doitintl/cmp-models";

import { useUserContext } from "../../Context/UserContext";
import { useDoitRoleCheck } from "./useDoitRoles";

export const useCloudAnalyticsAdminCheck = (): boolean => {
  const { userRoles, userModel } = useUserContext();
  const isDoerCAOwnershipAssigner = useDoitRoleCheck(DoitRole.CAOwnershipAssigner);

  return useMemo(() => {
    let caOwnerAssigner = false;

    if (userModel?.customer?.ref.id) {
      caOwnerAssigner = Boolean(userRoles?.cloudAnalyticsAdmin);
    } else {
      caOwnerAssigner = isDoerCAOwnershipAssigner;
    }

    return caOwnerAssigner;
  }, [isDoerCAOwnershipAssigner, userModel?.customer?.ref.id, userRoles?.cloudAnalyticsAdmin]);
};
