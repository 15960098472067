import { InAppNotificationType, InvoiceModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { formatCurrency } from "../../../utils/common";
import { type Mapper } from "../types";

export class NewInvoice implements Mapper<InvoiceModel> {
  readonly type = InAppNotificationType.NewInvoice;

  getCollection: Mapper<InvoiceModel>["getCollection"] = (customerRef, startTime) =>
    getCollection(InvoiceModel)
      .where("customer", "==", customerRef)
      .where("IVDATE", ">", startTime)
      .orderBy("IVDATE", "desc");

  userHasPermission: Mapper<InvoiceModel>["userHasPermission"] = (userRoles) => userRoles?.invoicesViewer ?? false;

  toNotification: Mapper<InvoiceModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    const dueDate = DateTime.fromJSDate(docData.PAYDATE.toDate()).toLocal().toLocaleString(DateTime.DATE_MED);
    const balance = formatCurrency(docData.DEBIT, docData.SYMBOL);
    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.NewInvoice,
      created: docData.IVDATE,
      updated: undefined,
      title: `Invoice ${docData.DETAILS?.toLowerCase()} has been issued`,
      description: `A balance of A${balance} is due on ${dueDate}`,
      urlPath: `/customers/${docData.customer.id}/invoices/${docData.entity.id}/${docData.IVNUM}`,
      color: "#E8F5FC",
    };
  };
}
