import { KubernetesFeatureStatus } from "@doitintl/cmp-models";

import { CLUSTER_PAGE_MESSAGES } from "../../../messages";
import StatusChipWithTooltip, { type StatusChipWithTooltipProps } from "../Components/StatusChipWithTooltip";
import { ClusterComputedStatus, type ClusterTableItem } from "../types";
import FeatureList from "./FeaturesList";
import StatusTooltipContent from "./StatusTooltipContent";

interface ClusterStatusProps {
  cluster: ClusterTableItem;
}

const ClusterStatus = ({ cluster }: ClusterStatusProps) => {
  const statusConfigs: Record<ClusterComputedStatus, StatusChipWithTooltipProps> = {
    [ClusterComputedStatus.UNKNOWN]: {
      color: "info",
      label: "Unknown",
    },
    [ClusterComputedStatus.NOT_CONFIGURED]: {
      color: "default",
      label: "Not configured",
      disabled: true,
      tooltipContent: "No features are enabled for this cluster",
    },
    [ClusterComputedStatus.HEALTHY]: {
      color: "success",
      label: "Healthy",
      tooltipContent: <StatusTooltipContent updatedTimestamp={cluster.connectionStatusUpdated} />,
    },
    [ClusterComputedStatus.PARTIAL]: {
      color: "success",
      label: "Partial",
      tooltipContent: (
        <StatusTooltipContent
          updatedTimestamp={cluster.connectionStatusUpdated}
          body={<FeatureList title="Disabled features" cluster={cluster} filterFeatures={(f) => !f.isActivated} />}
        />
      ),
    },
    [ClusterComputedStatus.DELETED]: {
      color: "error",
      label: "Deleted",
      tooltipContent: CLUSTER_PAGE_MESSAGES.DELETED_CLUSTER,
    },
    [ClusterComputedStatus.UNHEALTHY]: {
      color: "error",
      label: "Unhealthy",
      tooltipContent: (
        <StatusTooltipContent
          updatedTimestamp={cluster.connectionStatusUpdated}
          body={
            <FeatureList
              title="Unhealthy features"
              cluster={cluster}
              filterFeatures={(f) => f.isActivated && f.status !== KubernetesFeatureStatus.HEALTHY}
            />
          }
        />
      ),
    },
    [ClusterComputedStatus.CONNECTION_ISSUE]: {
      color: "warning",
      label: "Connection issues",
      tooltipContent: "The cloud account for this cluster is not healthy",
    },
    [ClusterComputedStatus.PRIVATE]: {
      color: "default",
      label: "Private",
      disabled: true,
      tooltipContent: CLUSTER_PAGE_MESSAGES.PRIVATE_CLUSTER,
    },
  };

  const statusConfig = statusConfigs[cluster.computedStatus];

  if (!statusConfig) {
    return null;
  }

  return <StatusChipWithTooltip {...statusConfig} />;
};

export default ClusterStatus;
