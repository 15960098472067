import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Link } from "@mui/material";

type Props = {
  text: string;
  url: string;
};

export const OpenNewWindowLink = ({ text, url }: Props) => (
  <Link href={url} target="_blank" underline="hover" variant="body2">
    {text}
    <OpenNewIcon sx={{ fontSize: "14px", verticalAlign: "middle", ml: 0.5 }} />
  </Link>
);
