import { useCallback, useMemo, useState } from "react";

import { AccessLevel, type CloudConnectCategory, TierPackageTypes } from "@doitintl/cmp-models";

import { useTier } from "../../../Context/TierProvider";
import { capitalizeStartCase } from "../../../utils/common";
import { isHeritageTier } from "../General/utils";
import { type PermissionsCategoryOverview } from "./types";
import { usePermissionCategories } from "./usePermissionCategories";
import { getScopedCategoryPermissions } from "./utils";

export function useSelectItemList<T>(initialState: T[]): [T[], (value: T, isSelected: boolean) => void] {
  const [selectedItems, setSelectedItems] = useState(initialState);

  const onSelectItem = useCallback(
    (value: T, isSelected: boolean) => {
      setSelectedItems((current) => {
        if (isSelected) {
          return [...current, value];
        }

        return current.filter((currentId) => currentId !== value);
      });
    },
    [setSelectedItems]
  );

  return [selectedItems, onSelectItem];
}

export const usePermissionCategoriesByEntity = (entity: AccessLevel): PermissionsCategoryOverview[] => {
  const categories = usePermissionCategories();
  const { getCustomerTier } = useTier();
  const isHeritageCustomer = isHeritageTier(getCustomerTier(TierPackageTypes.NAVIGATOR));

  return useMemo(() => {
    const transformCategory = (
      category: CloudConnectCategory,
      scope: AccessLevel
    ): PermissionsCategoryOverview | null => {
      const entityPermissions = getScopedCategoryPermissions(category, scope);
      if (entityPermissions.length === 0) return null;
      return {
        id: category.id,
        name: category.name,
        description: category.description,
        isDefault: category?.isDefault,
        requiredEntitlements: category?.requiredEntitlements,
        entityPermissions,
        additionalPermissions: category?.outOfScopePermissions,
        additionalPermissionsDescription: category?.outOfScopePermissionsDescription,
      };
    };

    const accessProperty = `allow${capitalizeStartCase(entity)}AccessLevel`;

    let transformed = categories
      .filter((cat) => (entity !== AccessLevel.ORGANIZATION ? cat[accessProperty] : true))
      .map((cat) => transformCategory(cat, entity))
      .filter((category): category is PermissionsCategoryOverview => category !== null);

    // If the customer is not a heritage customer, filter out heritage-only categories.
    if (!isHeritageCustomer) {
      transformed = transformed.filter((tc) => {
        const original = categories.find((cat) => cat.id === tc.id);
        return original ? !original.heritageOnly : true;
      });
    }

    return transformed;
  }, [categories, entity, isHeritageCustomer]);
};

export const useIsEntitledToCategory = () => {
  const { isFeatureEntitled } = useTier();

  return useCallback(
    (category: PermissionsCategoryOverview) => {
      // If there's no restrictions set, the user is entitled to the category
      if (!category.requiredEntitlements?.length) {
        return true;
      }

      // If there's any restriction set, the user must have all of them to be entitled to the category
      return category.requiredEntitlements.every((entitlement) => isFeatureEntitled(entitlement));
    },
    [isFeatureEntitled]
  );
};
