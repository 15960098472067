import { useCallback } from "react";

import { type Services, type SupportModel } from "@doitintl/cmp-models";
import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { getCreTicketURL } from "../../../api/utils";

const baseUrl = "/support";

export type ticketFields = {
  subject: string;
  priority: "low" | "normal" | "high" | "urgent";
  platformField: "doit" | "google_cloud_platform" | "amazon_web_services" | "microsoft_azure" | "looker" | "g_suite";
  categoryField?: string;
  productField?: string;
  platformInfoField?: string;
  description?: string;
  tags?: string[];
  public?: boolean;
  existingCustomer?: boolean;
  existingCustomerTier?: string;
  services?: Services[];
};

export type TicketCreatedResponse = {
  ticketId: number;
  id: number;
};

export type CreFields = {
  customerCountry: string;
  customerDomain: string;
  mpaName: string;
  nraMaterial?: string;
  requesterEmail: string;
  mpaId: string;
  description: string;
  requestedBy?: string;
  requesterLocation?: string;
  nra?: boolean;
  nraExpectedMRR?: number;
  orgImport?: boolean;
  supportModel?: SupportModel;
  supportTier?: string;
  noOrgImportReason?: string;
  additionalDetails?: string;
  edpppa?: boolean;
  billingProfileLocations?: string;
  rootEmail: string;
  tags?: string[];
  existingCustomer?: boolean;
  existingCustomerTier?: string;
  services?: Services[];
};

const useCreateTicket = (): {
  createTicket: (customerId: string, ticketFields: ticketFields) => Promise<AxiosResponse<TicketCreatedResponse>>;
  createCreTicket: (creFields: CreFields) => Promise<AxiosResponse<TicketCreatedResponse>>;
  postTicketComment: (
    ticketId: number,
    customerId: string,
    comment: string,
    privateComment: boolean,
    collaborators: string[]
  ) => Promise<AxiosResponse>;
} => {
  const api = useApiContext();

  const createTicket = useCallback(
    async (customerId: string, ticketFields: ticketFields) => {
      const formData = new FormData();

      for (const [fieldName, fieldValue] of Object.entries(ticketFields)) {
        switch (typeof fieldValue) {
          case "object": //  array of values such as tags
            for (const arrayValue of Object.values(fieldValue)) {
              formData.append(fieldName, arrayValue);
            }
            break;
          case "boolean":
          case "string":
          default:
            formData.append(fieldName, fieldValue.toString());
        }
      }

      return api.request<TicketCreatedResponse>({
        method: "post",
        url: `${baseUrl}/customers/${customerId}/tickets`,
        data: formData,
      });
    },
    [api]
  );

  const createCreTicket = useCallback(
    async (ticketCREFields: CreFields) => {
      if (!ticketCREFields.description.length) {
        ticketCREFields.description = "No description provided";
      }

      return api.request<TicketCreatedResponse>({
        method: "post",
        url: `${getCreTicketURL}/cmp/mpa`,
        data: ticketCREFields,
      });
    },
    [api]
  );

  const postTicketComment = useCallback(
    async (ticketId: number, customerId: string, comment: string, privateComment: boolean, collaborators: string[]) => {
      const formData = new FormData();
      formData.append("body", comment);
      formData.append("private", privateComment.toString());

      for (const collaborator of collaborators) {
        formData.append("collaborators[]", collaborator);
      }

      return api.request({
        method: "put",
        url: `${baseUrl}/customers/${customerId}/tickets/${ticketId}/comments`,
        data: formData,
      });
    },
    [api]
  );

  return {
    createTicket,
    createCreTicket,
    postTicketComment,
  };
};

export default useCreateTicket;
