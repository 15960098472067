import React, { useEffect, useMemo, useState } from "react";

import { FeatureForm } from "@client/src/Pages/Settings/Azure/Stepper/FeatureForm";
import { getNewResources } from "@client/src/Pages/Settings/Azure/utils";
import { type AzureFeaturePermission } from "@doitintl/cmp-models";
import { Alert, Box, Button, Container, Snackbar, Typography } from "@mui/material";
import type { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert/Alert";
import type { OverridableStringUnion } from "@mui/types";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { consoleErrorWithSentry } from "../../../../utils";
import { useAzureConnectApi } from "../hooks";
import { type AzureRole, type RoleIds, type RoleRequest, type Scope } from "../types";

type Props = {
  submitClicked: number;
  setSubmitDisabled: (disabled: boolean) => void;
  azureFeatures: AzureFeaturePermission[];
  selectedFeaturesNames: string[];
  tenantID: string;
  roleRequestData: RoleRequest | undefined;
  setRoleRequestData: React.Dispatch<React.SetStateAction<RoleRequest | undefined>>;
  roles: AzureRole[];
  setRoles: React.Dispatch<React.SetStateAction<AzureRole[]>>;
};

const ConnectResourcesStep = ({
  setSubmitDisabled,
  submitClicked,
  azureFeatures,
  selectedFeaturesNames,
  roleRequestData,
  tenantID,
  setRoleRequestData,
}: Props) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>("success");

  const [rolesIds, setRolesIds] = useState<RoleIds[]>([]);
  const [connectionValid, setConnectionValid] = useState(false);

  const showSnackbar = (severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>, title: string) => {
    setSnackbarSeverity(severity);
    setSnackbarTitle(title);
    setOpenSnackbar(true);
  };

  const customerId = useCustomerId();

  const selectedFeatures = useMemo(
    () =>
      azureFeatures.filter((feature: AzureFeaturePermission) => selectedFeaturesNames.includes(feature.name as string)),
    [azureFeatures, selectedFeaturesNames]
  );

  const azureConnectApi = useAzureConnectApi();

  useEffect(() => {
    setRoleRequestData({
      tenantId: tenantID,
      features: selectedFeatures.map((feature) => ({
        name: feature.name,
        roles: rolesIds
          .filter((role) => role.featureName === feature.name)
          .map((role) => ({
            resourceId: role.resourceId,
            roleId: role.roleId,
            scope: role.scope,
          })),
      })),
    });
  }, [setRoleRequestData, tenantID, selectedFeatures, rolesIds]);

  useEffect(() => {
    const handleSubmit = async () => {
      try {
        await azureConnectApi.addAzureRole(customerId, roleRequestData);
        showSnackbar("success", "Role added successfully");
      } catch (err) {
        showSnackbar("error", "Error adding role");
        consoleErrorWithSentry(err);
      }
    };

    async function handleSubmitClicked() {
      await handleSubmit();
    }
    if (submitClicked > 0) {
      handleSubmitClicked().then().catch();
    }
  }, [customerId, azureConnectApi, roleRequestData, submitClicked]);

  const handleTestConnection = async () => {
    try {
      const subscription = await azureConnectApi.listSubscription(tenantID);
      if (subscription.length) {
        showSnackbar("success", "Test Connection passed");
        setConnectionValid(true);
      } else {
        setConnectionValid(false);
      }
    } catch (err) {
      setConnectionValid(false);
      showSnackbar("error", "Test Connection failed");
    }
  };

  const handleCloseSnackbar = (_event: React.SyntheticEvent<Element, Event> | Event, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarTitle("");
    setOpenSnackbar(false);
  };

  const rolesValid: boolean = useMemo<boolean>(
    () =>
      rolesIds.every((role) => role.roleId !== "" && role.resourceId !== "") &&
      rolesIds.length ===
        selectedFeatures.reduce(
          (acc: number, feature: AzureFeaturePermission) => acc + (feature.roles.length as number),
          0
        ),
    [selectedFeatures, rolesIds]
  );

  const isFormValid = useMemo<boolean>(() => rolesValid && tenantID !== "", [rolesValid, tenantID]);

  useEffect(() => {
    setSubmitDisabled(!(isFormValid && rolesValid && connectionValid));
  }, [setSubmitDisabled, isFormValid, rolesValid, connectionValid]);

  const updateRoles = (featureName: string, roleName: string, scope: Scope, resourceId: string, roleId: string) => {
    setRolesIds(getNewResources(rolesIds, featureName, roleName, scope, resourceId, roleId));
  };

  return (
    <Container maxWidth={false} sx={{ maxWidth: "688px" }} disableGutters key={"connect-azure-resource-container"}>
      <>
        <Typography
          variant="h3"
          sx={{
            mb: 3,
            fontSize: 20,
          }}
        >
          Grant access to feature resources
        </Typography>
        <Box
          component="form"
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <>
            {selectedFeatures.map((feature, featureIdx) => (
              <React.Fragment key={`feature-fragment-${featureIdx}`}>
                <FeatureForm feature={feature} roleChanged={updateRoles} />
              </React.Fragment>
            ))}
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
              <Button variant="outlined" color="primary" onClick={handleTestConnection}>
                Test Connection
              </Button>
            </Box>
          </>
        </Box>

        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
            {snackbarTitle}
          </Alert>
        </Snackbar>
      </>
    </Container>
  );
};

export default ConnectResourcesStep;
