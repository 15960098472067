import { useHistory } from "react-router-dom";

import useMountEffect from "../../Components/hooks/useMountEffect";

const ProductWorkshop = () => {
  const history = useHistory();
  useMountEffect(() => {
    window.open("https://events.doit.com/eventsplus/d42e4fd5-69ee-4d61-a3aa-44b0f2904b97", "_blank", "noopener");
  });
  history.goBack();
  return null;
};
export default ProductWorkshop;
