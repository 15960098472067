import { NavLink } from "react-router-dom";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Badge, Box, Divider, MenuItem, MenuList, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { domainConfig } from "../../../domainConfig";
import { useAnnounceKit } from "../../../utils/announcekit/announcekitHooks";
import { type CategoryContext } from "../../types";
import { InAppNotifications } from "../Header/InAppNotifications/InAppNotifications";

type MobileMenuProps = {
  closeMenu: () => void;
  openSubMenu: (category: CategoryContext) => void;
  categories: CategoryContext[];
  settings: CategoryContext[];
  showOptions?: boolean;
  hideWhatsNewOption: boolean;
};

const MobileMenu = ({
  closeMenu,
  openSubMenu,
  categories,
  settings,
  showOptions = false,
  hideWhatsNewOption,
}: MobileMenuProps) => {
  const { openAnnounceKit, unread } = useAnnounceKit();
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });

  return (
    <Box sx={{ color: "navigation.text" }}>
      <NavLink onClick={closeMenu} to="/">
        <img src={domainConfig.assets.logoURL} alt="DoIT" width="56px" height="100%" />
      </NavLink>
      <Box
        sx={{
          pt: 2,
        }}
      >
        <Box
          sx={{
            pb: 1,
          }}
        >
          <MenuList>
            {categories.map((category) =>
              category.fullRoute ? (
                <MenuItem
                  disableGutters
                  key={category.displayName}
                  component={NavLink}
                  onClick={closeMenu}
                  to={category.fullRoute}
                >
                  <Typography variant="body1">{category.displayName}</Typography>
                </MenuItem>
              ) : (
                <MenuItem
                  disableGutters
                  key={category.displayName}
                  onClick={() => {
                    openSubMenu(category);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">{category.displayName}</Typography>
                    <Box
                      sx={{
                        pl: 1,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <ArrowForwardIcon fontSize="small" />
                    </Box>
                  </Box>
                </MenuItem>
              )
            )}
          </MenuList>
        </Box>

        <Divider sx={{ borderColor: "general.divider" }} />
        {/* temporarily disable ava for mobile */}
        {/* <MenuItem>*/}
        {/*  <AVAButton />*/}
        {/* </MenuItem>*/}
        {customer && customerOrPresentationModeCustomer && (
          <MenuItem>
            <InAppNotifications openInDialog={true} onClickNotification={closeMenu} />
          </MenuItem>
        )}
        {!showOptions && (
          <Box
            sx={{
              pt: 1,
            }}
          >
            <MenuList>
              {!hideWhatsNewOption && (
                <MenuItem
                  disableGutters
                  onClick={() => {
                    closeMenu();
                    openAnnounceKit();
                  }}
                >
                  <Badge variant="dot" color="secondary" invisible={!unread}>
                    What's new
                  </Badge>
                </MenuItem>
              )}
              {settings.map((settingsCategory) => (
                <MenuItem
                  disableGutters
                  key={settingsCategory.displayName}
                  onClick={() => {
                    openSubMenu(settingsCategory);
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="body1">{settingsCategory.displayName}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </MenuList>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default MobileMenu;
