import { createContext, type ReactNode, useContext, useState } from "react";

import noop from "lodash/noop";

import { useAvaDialogContext } from "./AvaDialogContextProvider";
import { AvaMessageHandlersProvider } from "./AvaMessageHandlersProvider";
import { useConversationHistory } from "./conversationsHook";
import { type ConversationHistory, type FetcherData } from "./types";

type ContextProps = {
  fetcher: (data: FetcherData) => Promise<Response>;
  conversationId?: string;
  setConversationId: (conversationId?: string) => void;
  history: ConversationHistory;
};

const defaultValue: ContextProps = {
  fetcher: noop as (data: FetcherData) => Promise<Response>,
  conversationId: undefined,
  setConversationId: noop,
  history: {},
};

const avaContext = createContext(defaultValue);

export const AvaContextProvider = ({
  children,
  fetcher,
}: {
  children: ReactNode;
  fetcher: (data: FetcherData) => Promise<Response>;
  dashboardId?: string;
}) => {
  const { setDashboardId } = useAvaDialogContext();
  const [conversationId, setConversationId] = useState<string | undefined>();

  const history = useConversationHistory(false);

  const handleChangeConversationId = (newConversationId?: string) => {
    setConversationId(newConversationId);
    if (conversationId !== newConversationId) {
      setDashboardId("");
    }
  };

  return (
    <AvaMessageHandlersProvider>
      <avaContext.Provider
        value={{
          fetcher,
          conversationId,
          setConversationId: handleChangeConversationId,
          history,
        }}
      >
        {children}
      </avaContext.Provider>
    </AvaMessageHandlersProvider>
  );
};

export const useAvaContext = () => useContext(avaContext);
