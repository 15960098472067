import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type EnhancedCloudConnectGoogleCloud } from "./types";

export const itemsDescriptions: FilterTableItemDescriptor<EnhancedCloudConnectGoogleCloud>[] = [
  {
    headerText: "Name",
    value: "entityId",
  },
  {
    headerText: "Service account",
    value: "data.clientEmail",
    hidden: { mdDown: true },
  },
  {
    headerText: "Type",
    value: "data.scope",
    hidden: { mdDown: true },
  },
  {
    headerText: "Enabled features",
    value: "enabledFeatures",
    hidden: { mdDown: true },
  },
  {
    headerText: "Status",
    value: "data.status",
  },
  {
    // Three dots menu
    headerText: "",
    value: "@",
    headerStyle: {
      width: "15px",
      align: "right",
    },
  },
];

export const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);
