import { useCallback, useEffect, useMemo, useState } from "react";

import { DashboardModel, type QuickLinkDescriptorModel, type UserPermission } from "@doitintl/cmp-models";
import { getCollection, useCollectionData, type WithFirebaseModel } from "@doitintl/models-firestore";
import { useQuery } from "@tanstack/react-query";
import overEvery from "lodash/overEvery";

import { useTierLimitReachedAnalyticsAttributionGroups } from "../../../Context/AnalyticsTierProvider";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { getCachingKeys } from "../../../utils/cachingKeys";
import { useHasCloudAnalyticsMetadata } from "../../hooks/cloudAnalytics/useHasCloudAnalyticsMetadata";
import { useCheckQuickLinkConditionFn } from "./useCheckQuickLinkConditionFn";
import { useUserMaturity } from "./useUserMaturity";

function quickLinkSortOrderComparator(a: QuickLinkDescriptorModel, b: QuickLinkDescriptorModel) {
  return a.sortOrder - b.sortOrder;
}

const keysToCheck: (keyof QuickLinkDescriptorModel)[] = [
  "description",
  "icon",
  "name",
  "permissions",
  "sortOrder",
  "target",
  "title",
];

function isQuickLink(maybeQuickLink: QuickLinkDescriptorModel): maybeQuickLink is QuickLinkDescriptorModel {
  return (
    maybeQuickLink !== null &&
    maybeQuickLink !== undefined &&
    keysToCheck.every((key) => Object.prototype.hasOwnProperty.call(maybeQuickLink, key)) &&
    Array.isArray(maybeQuickLink.permissions)
  );
}

function isNotNull(maybeQuickLink: QuickLinkDescriptorModel | null): maybeQuickLink is QuickLinkDescriptorModel {
  return maybeQuickLink !== null;
}

type FilterFunction<T> = (data: T) => boolean;

const applyFilters = <T>(obj: T, filters: FilterFunction<T>[]): T | undefined => {
  const passesAllFilters = overEvery(filters);
  return passesAllFilters(obj) ? obj : undefined;
};

export function useUserQuickLinks(): [QuickLinkDescriptorModel[], boolean, unknown] {
  const { isProductOnlyCustomer } = useCustomerContext();
  const { isDoitEmployee } = useAuthContext();
  const { userRoles } = useUserContext();
  const userMaturityInDays = useUserMaturity();
  const checkQuickLinkConditions = useCheckQuickLinkConditionFn();
  const [attributionGroupsEnabled, setAttributionGroupsEnabled] = useState(false);
  const attributionGroupsEnabledLimitReached = useTierLimitReachedAnalyticsAttributionGroups();
  const { customer } = useCustomerContext();
  const hasMetadata = useHasCloudAnalyticsMetadata();

  const quickLinksQuery = useMemo(() => getCollection(DashboardModel).doc("home").collection("quickLinks"), []);

  const [allQuickLinks] = useCollectionData(quickLinksQuery, { caching: true, cachingKeys: ["quickLinks"] });

  const isEntitledAttributionGroups = useIsFeatureEntitled("analytics:attributionGroups");

  useEffect(() => {
    if (hasMetadata && attributionGroupsEnabledLimitReached && isEntitledAttributionGroups) {
      setAttributionGroupsEnabled(!attributionGroupsEnabledLimitReached.limitReached);
    }
  }, [attributionGroupsEnabledLimitReached, hasMetadata, isEntitledAttributionGroups]);

  const filter = useCallback(
    (docData: WithFirebaseModel<QuickLinkDescriptorModel>) => {
      const filterByEntitlement = (quickLinkModel: QuickLinkDescriptorModel) => {
        if (
          ["create-attribution-group", "guided-experience"].includes(quickLinkModel.name) &&
          isEntitledAttributionGroups
        ) {
          return Boolean(attributionGroupsEnabled);
        }

        return true;
      };

      const filterByPermission = (quickLinkModel: QuickLinkDescriptorModel) =>
        quickLinkModel.permissions.every(
          (permissionRef) => userRoles?.permissions.has(permissionRef.id as UserPermission) || isDoitEmployee
        );

      const filterByMaturity = (quickLinkModel: QuickLinkDescriptorModel) =>
        (quickLinkModel.userMaturityFrom === undefined || userMaturityInDays >= quickLinkModel.userMaturityFrom) &&
        (quickLinkModel.userMaturityTo === undefined || userMaturityInDays < quickLinkModel.userMaturityTo);

      const filterByCustomerType = (quickLinkModel: QuickLinkDescriptorModel) =>
        !isProductOnlyCustomer ||
        [
          "invite-colleagues",
          "create-report",
          "manage-notifications",
          "create-alert",
          "create-attribution",
          "create-attribution-group",
          "eks-onboarding",
          "guided-experience",
        ].includes(quickLinkModel.name);

      return applyFilters(docData, [
        isQuickLink,
        filterByPermission,
        filterByMaturity,
        filterByCustomerType,
        filterByEntitlement,
      ]);
    },
    [
      attributionGroupsEnabled,
      isDoitEmployee,
      isEntitledAttributionGroups,
      isProductOnlyCustomer,
      userMaturityInDays,
      userRoles?.permissions,
    ]
  );

  const retrieveLinksCondition = Boolean(allQuickLinks);

  const retrieveLinks = useCallback(async () => {
    if (!allQuickLinks) {
      return;
    }

    const filters = allQuickLinks.filter(filter);

    const checkConditions = filters.map(checkQuickLinkConditions);

    const filtersAfterConditionCheck = await Promise.all(checkConditions);

    return filtersAfterConditionCheck.filter(isNotNull).toSorted(quickLinkSortOrderComparator);
  }, [allQuickLinks, checkQuickLinkConditions, filter]);

  const { data, error, isLoading } = useQuery({
    queryKey: retrieveLinksCondition
      ? [...getCachingKeys(customer.id), "quickLinks", retrieveLinksCondition, attributionGroupsEnabled]
      : undefined,
    queryFn: retrieveLinks,
    keepPreviousData: true,
    staleTime: Infinity,
    enabled: retrieveLinksCondition, // Disable query if condition is falsy
  });

  return useMemo(() => [data ?? [], !isLoading, error], [error, data, isLoading]);
}
