import { Slide } from "react-awesome-reveal";
import { Avatar, Stack, Typography } from "@mui/material";

import { useAuthContext } from "../../Context/AuthContext";
import { type MessageUser } from "./types";

type PropsUser = {
  message: MessageUser;
  disableAnimation: boolean;
};

export const MessageUserComponent = ({ message, disableAnimation }: PropsUser) => {
  const { currentUser } = useAuthContext({ mustHaveUser: true });

  return (
    <Slide direction="up" triggerOnce duration={disableAnimation ? 0 : undefined}>
      <Stack
        alignSelf="center"
        borderTop={1}
        borderBottom={1}
        borderColor="divider"
        sx={{
          p: 2,
          backgroundColor: "background.paper",
        }}
      >
        <Stack
          sx={{
            alignSelf: "center",
            maxWidth: 800,
            width: "100%",
          }}
        >
          <Stack sx={{ alignSelf: "flex-start" }} direction="row" spacing={1.5}>
            <Avatar sx={{ height: 24, width: 24 }} src={currentUser.photoURL ?? undefined} />
            <Typography variant="subtitle2">{message.content}</Typography>
          </Stack>
        </Stack>
      </Stack>
    </Slide>
  );
};
