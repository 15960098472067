import { type ContractSupport, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { type ModelReference, type WithFirebaseModel } from "@doitintl/models-firestore";

import { firestoreTimestamp } from "../../../../../utils/firebase";
import { nonDigitsOutOfRange } from "./AccountBox/AccountBox";
import { costMode, type payerAccountObj, type supportMapObj } from "./types";

export function getMPANumberFromName(name: string): number {
  return parseInt(name.replaceAll(/\D/g, ""), 10);
}

export function getPayerAccountById(payerList: payerAccountObj[], mpaId: string): payerAccountObj | undefined {
  return payerList.find(({ ref: { id } }) => id === mpaId);
}

export const NEW_ACCOUNT_ID = "";

export const newAccountBox: supportMapObj = {
  [NEW_ACCOUNT_ID]: {
    discount: null,
    flatRate: null,
    lastUpdateTime: null,
    mpaRef: null,
  },
};

export const costInputLabel = (chargeMode: string): string => {
  switch (chargeMode) {
    case costMode.percentage:
      return "Discount";
    case costMode.flatRate:
      return "Flat-rate";
    default:
      return "Discount";
  }
};

export function payerAccountComparator(
  { data: { name: nameA } }: payerAccountObj,
  { data: { name: nameB } }: payerAccountObj
): number {
  return getMPANumberFromName(nameA) - getMPANumberFromName(nameB);
}

export const isFreshAccBox = (supVal?: string, key?: string): boolean =>
  typeof supVal === "undefined" && typeof key !== "undefined" && key?.length === 0;

export const isNumError = (
  isFreshForm,
  selectedAccountNumber,
  contractSupportErrorBool,
  costNum,
  mpaId,
  contractSupportVal
): boolean =>
  (isFreshForm && selectedAccountNumber?.length && !contractSupportErrorBool && costNum === "") || // new account box missing num
  (mpaId === null && costNum === "" && contractSupportErrorBool) ||
  (!isFreshForm && mpaId !== null && costNum === "") || // deleting instead of replacing an existing account box value
  (typeof contractSupportVal !== "undefined" && costNum !== "" && nonDigitsOutOfRange(costNum)); // value errors checks, unless it's an initial, empty box form load

export const accObj = (
  numStr: string,
  chargeMode: costMode,
  payerAccRef: ModelReference<MasterPayerAccountsModel> | null
): WithFirebaseModel<ContractSupport> => {
  const floatParsedCostNum: number | null = !isNaN(parseFloat(numStr)) ? parseFloat(numStr) : null;
  return {
    discount: chargeMode === costMode.percentage ? floatParsedCostNum : null,
    flatRate: chargeMode === costMode.flatRate ? floatParsedCostNum : null,
    lastUpdateTime: firestoreTimestamp(),
    mpaRef: payerAccRef,
  };
};
