import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { cloudflowTexts } from "../../../../../../assets/texts";

export function useFilterDialogFormTexts(nodeType: CloudFlowNodeType) {
  switch (nodeType) {
    case CloudFlowNodeType.CONDITION:
      return {
        fieldToFilterTitle: cloudflowTexts.SELECT_FORM_FIELD_TO_FILTER,
        filterCriteriaTitle: cloudflowTexts.SELECT_CONDITION_CRITERIA_TITLE,
      };
    default:
      return {
        fieldToFilterTitle: cloudflowTexts.SELECT_FORM_FIELD_TO_FILTER,
        filterCriteriaTitle: cloudflowTexts.SELECT_FILTER_CRITERIA_TITLE,
      };
  }
}
