import { type ReactNode, useMemo } from "react";

import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import type { UserModel } from "@doitintl/cmp-models";
import type { ModelReference } from "@doitintl/models-firestore";

import type { InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";

type NotificationsListControlsProps = {
  notifications: Map<string, InAppNotificationWithRead>;
  onlyShowUnread: boolean;
  setOnlyShowUnread: (value: boolean) => void;
  unreadNotificationsCount: number;
  markAllAsRead: (userRef: ModelReference<UserModel>) => void;
  userRef: ModelReference<UserModel>;
};

export const cyIds = {
  showOnlyUnread: {
    caption: "caption",
    switch: "switch",
  },
  markAllAsRead: {
    button: "button",
  },
};

function NotificationsListControls({
  notifications,
  onlyShowUnread,
  setOnlyShowUnread,
  unreadNotificationsCount,
  markAllAsRead,
  userRef,
}: Readonly<NotificationsListControlsProps>) {
  const unreadLabel = useMemo<ReactNode>(() => {
    let caption = "Show only unread";

    if (unreadNotificationsCount > 0) {
      caption += ` (${unreadNotificationsCount})`;
    }

    return (
      <Typography
        variant="body2"
        color={notifications.size !== 0 ? "text.primary" : "text.secondary"}
        data-cy={cyIds.showOnlyUnread.caption}
      >
        {caption}
      </Typography>
    );
  }, [notifications.size, unreadNotificationsCount]);

  return (
    <Stack direction="row" sx={{ px: 2, pt: 1.25, mb: -2, zIndex: 2 }}>
      <FormControlLabel
        control={
          <Switch
            disabled={notifications.size === 0}
            checked={onlyShowUnread}
            onChange={(e) => {
              setOnlyShowUnread(e.target.checked);
            }}
            data-cy={cyIds.showOnlyUnread.switch}
          />
        }
        label={unreadLabel}
      />
      <Button
        disabled={unreadNotificationsCount === 0}
        sx={{ py: 0, fontSize: 14, ml: "auto" }}
        onClick={() => {
          markAllAsRead(userRef);
        }}
        data-cy={cyIds.markAllAsRead.button}
      >
        Mark all as read
      </Button>
    </Stack>
  );
}

export default NotificationsListControls;
