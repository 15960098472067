import { type ComponentType, createContext, useCallback, useContext, useMemo, useState } from "react";

import { HelmetProvider } from "react-helmet-async";
// Router
import { BrowserRouter, Router, Switch } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import GlobalStyles from "@mui/material/GlobalStyles";
// Material-UI
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import noop from "lodash/noop";

import { DeveloperDocs } from "./Components/hooks/useDeveloperDocs";
import { NoTraceRoute } from "./Components/Routes/TraceRoutes";
import { SharedSnackbarProvider } from "./Components/SharedSnackbar/SharedSnackbar.context";
import { routes } from "./constants/navigation";
import { configureTheme } from "./muiTheme";
import { type ThemeMode, ThemeModes } from "./muiThemeTypes";
import { browserHistory } from "./utils/sentryInit";
import { getCurrentTheme, setCurrentTheme } from "./utils/theme";

export const withDeveloperDocs = <P extends object>(Component: ComponentType<P>) => {
  const withDeveloperDocsRoot = (props: P) => (
    <>
      <Switch>
        <NoTraceRoute path={routes.developerDocs} exact component={DeveloperDocs} />
      </Switch>

      <Component {...props} />
    </>
  );

  withDeveloperDocsRoot.displayName = `withDeveloperDocsRoot(${Component.displayName || Component.name})`;

  return withDeveloperDocsRoot;
};

type ThemeContextProps = {
  onToggleTheme: () => void;
};

const themeContext = createContext<ThemeContextProps>({
  onToggleTheme: noop,
});
export const useThemeContext = () => useContext(themeContext);

const withRoot = <P extends object>(Component: ComponentType<P>) => {
  const darkTheme = configureTheme(ThemeModes.DARK);
  const lightTheme = configureTheme(ThemeModes.LIGHT);

  const WithRootInner = (props: P) => {
    const [themeMode, setThemeMode] = useState<ThemeMode | null>(getCurrentTheme());

    const toggleThemeType = useCallback(() => {
      setThemeMode((theme) => setCurrentTheme(theme));
    }, []);

    const themeContextValue = useMemo(() => ({ onToggleTheme: toggleThemeType }), [toggleThemeType]);

    // noinspection JSPotentiallyInvalidUsageOfThis
    return (
      <HelmetProvider>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={themeMode === ThemeModes.DARK ? darkTheme : lightTheme}>
            <CssBaseline />
            <GlobalStyles
              styles={(th) => ({
                ".rv-discrete-color-legend-item": {
                  color: th.palette.text.primary,
                },
                ".rv-discrete-color-legend-item.clickable": {
                  "&:hover": {
                    backgroundColor: th.palette.background.default,
                  },
                },
                ".highcharts-tooltip-container": {
                  // this is for use case when highcharts is in a modal
                  "z-index": "1301 !important",
                },
              })}
            />
            <BrowserRouter
              getUserConfirmation={() => {
                /* Empty callback to block the default browser prompt */
              }}
            >
              <LocalizationProvider dateAdapter={AdapterLuxon}>
                <themeContext.Provider value={themeContextValue}>
                  <Router history={browserHistory}>
                    <SharedSnackbarProvider>
                      <Component {...props} history={browserHistory} location={browserHistory.location} />
                    </SharedSnackbarProvider>
                  </Router>
                </themeContext.Provider>
              </LocalizationProvider>
            </BrowserRouter>
          </ThemeProvider>
        </StyledEngineProvider>
      </HelmetProvider>
    );
  };

  WithRootInner.displayName = `WithRootInner(${Component.displayName || Component.name})`;

  return WithRootInner;
};

export default withRoot;
