import { CloudflowExecutionStatus, NodeExecutionStatus } from "@doitintl/cmp-models";
import { Chip, type ChipProps } from "@mui/material";

import { executionStatusNames } from "../consts";

type StatusColorMap = Record<string, { name: string; color: ChipProps["color"] }>;

export const executionStatus: StatusColorMap = {
  [CloudflowExecutionStatus.COMPLETE]: {
    name: executionStatusNames[CloudflowExecutionStatus.COMPLETE],
    color: "success",
  },
  [CloudflowExecutionStatus.FAILED]: {
    name: executionStatusNames[CloudflowExecutionStatus.FAILED],
    color: "error",
  },
  [CloudflowExecutionStatus.RUNNING]: {
    name: executionStatusNames[CloudflowExecutionStatus.RUNNING],
    color: "primary",
  },
  [CloudflowExecutionStatus.PENDING]: {
    name: executionStatusNames[CloudflowExecutionStatus.PENDING],
    color: "info",
  },
  [CloudflowExecutionStatus.PENDING_APPROVAL]: {
    name: executionStatusNames[CloudflowExecutionStatus.PENDING_APPROVAL],
    color: "warning",
  },
};

export const nodeExecutionStatus: StatusColorMap = {
  [NodeExecutionStatus.COMPLETE]: { name: "Complete", color: "success" },
  [NodeExecutionStatus.FAILED]: { name: "Has errors", color: "error" },
  [NodeExecutionStatus.IN_PROGRESS]: { name: "In progress", color: "primary" },
  [NodeExecutionStatus.PENDING]: { name: "Pending", color: "info" },
  [NodeExecutionStatus.REJECTED]: { name: "Rejected", color: "error" },
  [NodeExecutionStatus.APPROVAL_TIMED_OUT]: { name: "Timed out", color: "error" },
  [NodeExecutionStatus.PENDING_APPROVAL]: { name: "Awaiting approval", color: "warning" },
};

type Props = {
  status: string | undefined;
  statusMap: StatusColorMap;
};

const StatusChip = ({ status, statusMap }: Props) => {
  const statusColors = statusMap;
  const statusProps = status ? statusColors[status] : undefined;

  if (!statusProps) {
    return <Chip label="Unknown Status" variant="status" color="default" />;
  }

  return <Chip label={statusProps.name} variant="status" color={statusProps.color} />;
};

export default StatusChip;
