import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AnalyticsDataSource, IDs, Keys, Metadata, ReportOrgMetadataModel } from "@doitintl/cmp-models";
import { getCollectionGroup, type QueryDocumentSnapshotModel } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { useOrgsContext } from "../../../Context/customer/OrgsProvider";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCachedHook } from "../../../Context/DynamicContext/useCachedHook";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { getAttributionGroupMetadata } from "../../../Pages/CloudAnalytics/attributionGroups/api";
import { getOrganizationRef } from "../../../Pages/CloudAnalytics/utilities";
import { consoleErrorWithSentry } from "../../../utils";
import { groupByDataSource } from "./useAnalyticsDimensions/analyticsDimensionsParser";
import { useHasCloudAnalyticsMetadata } from "./useHasCloudAnalyticsMetadata";

export type AnalyticsMetadata = QueryDocumentSnapshotModel<ReportOrgMetadataModel>[];

export type AnalyticsMetadataReturn = {
  metadata: AnalyticsMetadata;
  hasDataHub: boolean;
  fetchMetadata: (opts?: { withLabels?: boolean; withAttributionGroups?: boolean }) => void;
  fetchAttributionGroupsMetadata: () => Promise<void>;
  hasEKSData?: boolean;
  loading: boolean;
  hasMetadata?: boolean;
};

const useNonCachedCloudAnalyticsMetadata = (): AnalyticsMetadataReturn => {
  const { customer, customerOrPresentationModeCustomer } = useCustomerContext({ allowNull: true });
  const { attributions } = useAttributionsContext();
  const { isDoitEmployee } = useAuthContext();
  const { userOrganization, organizationsLoading } = useOrgsContext();
  const api = useApiContext();

  const [metadata, setMetadata] = useState<AnalyticsMetadata>([]);
  const hasMetadata = useHasCloudAnalyticsMetadata();
  const [attributionGroupsMetadata, setAttributionGroupsMetadata] = useState<AnalyticsMetadata>([]);
  const [firestoreLoading, setFirestoreLoading] = useState(true);
  const [apiLoading, setApiLoading] = useState(true);
  const isFetchingMetadata = useRef(false);
  const isFetchingAGMetadata = useRef(false);
  const isDataHubEntitled = useIsFeatureEntitled("pdi:datahub");

  const fetchAttributionGroupsMetadata = useCallback(async () => {
    if (isFetchingAGMetadata.current) {
      return;
    }
    isFetchingAGMetadata.current = true;
    setApiLoading(true);

    const attributionGroupMetadata = await getAttributionGroupMetadata(api, customer?.id ?? "");

    setAttributionGroupsMetadata(attributionGroupMetadata);
    setApiLoading(false);
    isFetchingAGMetadata.current = false;
  }, [api, customer?.id]);

  const fetchMetadata = useCallback(
    async (opts?: { withLabels?: boolean; withAttributionGroups?: boolean }) => {
      if (isDoitEmployee === undefined || organizationsLoading) {
        return;
      }

      const withLabels = opts?.withLabels ?? false;
      const withAttributionGroups = opts?.withAttributionGroups ?? false;

      if (attributionGroupsMetadata.length === 0 && withAttributionGroups) {
        fetchAttributionGroupsMetadata();
      }

      if (metadata.length > 0 || isFetchingMetadata.current) {
        return;
      }

      setFirestoreLoading(true);
      isFetchingMetadata.current = true;

      const typesToLoad: Metadata[] = [Metadata.FIXED, Metadata.DATETIME, Metadata.ATTRIBUTION].concat(
        withLabels ? [Metadata.LABEL, Metadata.PROJECT_LABEL, Metadata.SYSTEM_LABEL] : [Metadata.OPTIONAL]
      );

      const orgRef = getOrganizationRef(isDoitEmployee, userOrganization, customerOrPresentationModeCustomer?.id ?? "");
      const query = getCollectionGroup(ReportOrgMetadataModel)
        .where("customer", "==", customerOrPresentationModeCustomer?.ref)
        .where("organization", "==", orgRef)
        .where("type", "in", typesToLoad);

      try {
        const querySnap = await query.get();
        setMetadata(querySnap.docs);
        isFetchingMetadata.current = false;
      } catch (error) {
        consoleErrorWithSentry(error);
      }

      setFirestoreLoading(false);
    },
    [
      attributionGroupsMetadata.length,
      customerOrPresentationModeCustomer?.id,
      customerOrPresentationModeCustomer?.ref,
      fetchAttributionGroupsMetadata,
      isDoitEmployee,
      metadata.length,
      organizationsLoading,
      userOrganization,
    ]
  );

  useEffect(() => {
    attributionGroupsMetadata.forEach((attributionGroup) => {
      if (attributions) {
        attributionGroup.data().values?.sort((a, b) => {
          const aName = attributions.find((attr) => attr.ref.id === a)?.data.name ?? "";
          const bName = attributions.find((attr) => attr.ref.id === b)?.data.name ?? "";
          return aName.localeCompare(bName);
        });
      }
    });
  }, [attributionGroupsMetadata, attributions]);

  const filteredMetadata = useMemo(
    () => [...metadata, ...attributionGroupsMetadata],
    [metadata, attributionGroupsMetadata]
  );

  const hasDataHub = useMemo(() => {
    if (!isDataHubEntitled) {
      return false;
    }
    const dataSourceQueries = groupByDataSource(metadata);
    const dataSourcesFromMd = Object.keys(dataSourceQueries);
    return dataSourcesFromMd.includes(AnalyticsDataSource.BILLING_DATAHUB);
  }, [isDataHubEntitled, metadata]);

  const hasEKSData = useMemo(() => {
    // search for eks:cluster-name label in metadata
    const labelKeys = metadata.find((md) => md.id === IDs.OptionalLabels)?.data();
    return !!labelKeys?.values?.includes(Keys.eksClusterName);
  }, [metadata]);

  const loading = apiLoading || (firestoreLoading && metadata.length === 0);
  return useMemo<AnalyticsMetadataReturn>(
    () => ({
      metadata: filteredMetadata,
      loading,
      hasMetadata,
      hasDataHub,
      fetchMetadata,
      fetchAttributionGroupsMetadata,
      hasEKSData,
    }),
    [filteredMetadata, loading, hasMetadata, hasDataHub, fetchMetadata, fetchAttributionGroupsMetadata, hasEKSData]
  );
};

export const useCloudAnalyticsMetadata = () => useCachedHook(useNonCachedCloudAnalyticsMetadata);
