import { type InsightStatusChangesModel } from "@doitintl/cmp-models";

import { type RequestConfig } from "../../Pages/CloudAnalytics/generateReport/types";
import { type CommonEventProperties } from "../../utils/segment";
import { type SegmentEventProperties } from "../../utils/useSegmentTrackEvent";

export type DisplayStatus = "optimized" | "actionable" | "dismissed" | "permissions needed";

export type CloudProviderTag = "aws" | "gcp" | "azure" | "snowflake";

export type CloudProviderName = "Amazon Web Services" | "Google Cloud" | "Azure" | "Snowflake";

export type AccessType = "full" | "partial" | "no_access";

export enum InsightProviderId {
  TRUSTED_ADVISOR = "aws-trusted-advisor",
  COST_OPTIMIZATION_HUB = "aws-cost-optimization-hub",
  GCP_RECOMMENDER = "gcp-recommender",
  FSK8S = "fsk8s",
  CORE = "core",
  CUSTOM = "custom",
}

export enum Category {
  FinOps = "FinOps",
  OperationalExcellence = "Operational excellence",
  PerformanceEfficiency = "Performance efficiency",
  Reliability = "Reliability",
  Security = "Security",
  Sustainability = "Sustainability",
}

export type SecurityRisksBreakdownValue = {
  critical: number;
  high: number;
  medium: number;
  low: number;
};

export type SecurityRisksBreakdownData = {
  value: SecurityRisksBreakdownValue;
  baseValue?: SecurityRisksBreakdownValue;
  dimensionValues: string[];
};

type SecurityRisksBreakdown = {
  dimensions: string[];
  data: SecurityRisksBreakdownData[];
};

export type SecurityRisks = {
  isOptimized: boolean;
  breakdown?: SecurityRisksBreakdown;
};

export type BreakdownData = {
  value: number;
  baseValue: number;
  dimensionValues: string[];
};

export type Breakdown = {
  dimensions: string[];
  data: BreakdownData[];
};

export type PotentialDailySavings = {
  isOptimized: boolean;
  breakdown: Breakdown | null;
};

export type DisplaySecurityRisks = {
  isOptimized: boolean;
  breakdown: Breakdown | null;
};

export type CustomProblems = {
  isOptimized: boolean;
  unitShortSingular: string;
  unitShortPlural: string;
  unitLongSingular: string;
  unitLongPlural: string;
  breakdown: Breakdown | null;
};

export type Results = {
  isRelevant: boolean;
  potentialDailySavings: PotentialDailySavings | null;
  customProblems: CustomProblems | null;
  securityRisks: SecurityRisks | null;
  reportConfig: RequestConfig | null;
};

export type CustomInsightAttributes = {
  published: string;
  publishingUserEmail: string;
  showPublishingUser: boolean;
};

export type InsightPublishingUser = {
  displayName: string;
  avatarUrl: string;
  title: string;
  onVacation: boolean;
};

export type LastStatusChange = {
  userId: string;
  lastChangedAt: string;
};

export type ServerInsightResult = Omit<Results, "securityRisks"> & { securityRisks: SecurityRisks | null };

export type ServerInsight = Omit<Insight, "results"> & { results: ServerInsightResult | null };

export type InsightPermissions = {
  accessType: AccessType;
};

export type Insight = {
  providerId: string;
  key: string;
  canBeIndividuallyExecuted: boolean;
  customerId: string;
  displayStatus: DisplayStatus;
  lastStatusChange: LastStatusChange | null;
  lastUpdated: string;
  title: string;
  shortDescription: string;
  detailedDescriptionMdx: string;
  cloudTags: CloudProviderTag[];
  otherTags: string[];
  categories: Category[];
  isInternal: boolean;
  supportCategory: string;
  results: Results | null;
  reportUrl: string;
  customInsightAttributes: CustomInsightAttributes | null;
  userStatusChanges: InsightStatusChangesModel | null;
  easyWinDescription: string;
  publishingUser: InsightPublishingUser | null;
  permissions: InsightPermissions | null;
};

export type SavingsPeriod = "daily" | "monthly" | "yearly";

type NonEntitledInsightsResult = {
  // Number of insights that are relevant to a customer (but may not have any value and be already optimized)
  numberOfRelevantInsights: number;

  // Number of insights that are relevant but not yet optimized
  numberOfActionableInsights: number;

  // Total value (in USD in the case of potentialDailySavings or simple integer count for customProblems)
  value: number;
};

export type NonEntitledInsightsResults = {
  potentialDailySavings: NonEntitledInsightsResult;
  customProblems: NonEntitledInsightsResult;
};

type NoAccessPotentialDailySavings = {
  isOptimized: boolean;
  value: number;
};

export type NoAccessInsight = {
  providerId: string;
  key: string;
  potentialDailySavings?: NoAccessPotentialDailySavings;
  customerId: string;
  title: string;
  shortDescription: string;
  supportCategory: string;
  cloudTags: string[];
  categories: string[];
  isRelevant: boolean;
  easyWinDescription: string;
};

export type InsightResultsResponse<T = Insight> = {
  noAccessResults: NoAccessInsight[];
  results: T[];
  nonEntitledResultsSummary: NonEntitledInsightsResults;
};

export type InsightMissingPermissionsResponse = {
  cloud: CloudProviderTag;
  providerId: InsightProviderId;
  scopes: string[];
}[];

export interface InsightsListEventProperties extends CommonEventProperties, SegmentEventProperties {
  permissionsNeededInsights: number;
  actionableInsights: number;
  optimizedInsights: number;
  dismissedInsights: number;
  selectedInsightsCount: number;
}

export interface InsightsListViewedProperties extends InsightsListEventProperties {
  insightsSortOrder: string;
  insightsSavingsPeriod: string;
  insightsFiltersSelected: string[];
  insightsSearchTerms: string;
  customInsightsCount: number;
  insightsTotalSavings: string;
  insightsHighestSavings: number;
  insightsOrderOnSelectedTab: string[];
  selectedInsightsTab: string;
}

export interface InsightsAddPermissionsProperties extends InsightsListEventProperties {
  accountsMissingPermissions: string[];
  insightsSavingsPeriod: string;
  customInsightsCount: number;
  insightsTotalSavings: string;
  insightsHighestSavings: number;
}

export interface InsightProperties extends CommonEventProperties, SegmentEventProperties {
  insightKey: string;
  insightTitle: string;
  insightProvider: string;
  isOptimized: boolean;
  isRelevant: boolean;
  insightCloudTags: string[];
  insightCategories: string[];
  isCustomInsight: boolean;
  insightLastUpdated: string;
  insightDailySavings?: number;
  insightUserStatus?: string;
  insightSupportCategory?: string;
  insightSavingsPeriod?: string;
  insightTotalSavings?: string;
  customProblemsTotal?: number;
  customProblemsUnit?: string;
}

export interface CustomInsightProperties extends InsightProperties {
  insightPublished: string;
  insightPublishingUser: string;
}

export interface InsightsUpsellPageViewedProperties extends CommonEventProperties, SegmentEventProperties {
  totalYearlySavings: string;
  dciTotalCost: number;
  monthlyCloudSpend: number;
  insightsTotalSavings: number;
  insightsHighestSavings: number;
  actionableInsights: number;
  optimizedInsights: number;
  irrelevantInsights: number;
  hasCustomInsights: boolean;
}

export type StatusDetails = {
  date: string;
  userDisplayName: string;
};

export type Filters = {
  providers: CloudProviderTag[];
  categories: Category[];
  sources: string[];
};

export type SecurityRisksInput = {
  critical: number;
  high: number;
  medium: number;
  low: number;
};
