import "@services/clouddiagrams/frontend/sass/client/_cmp_dashboard_list_item.scss";

import { useEffect, useState } from "react";

import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Popover from "@mui/material/Popover";

import awsLogoDark from "@client/src/assets/amazon-web-services-logo-white.png";
import awsLogo from "@client/src/assets/amazon-web-services-new-square.png";
import { useDarkThemeCheck } from "@client/src/Components/hooks/useDarkThemeCheck";
import { consoleErrorWithSentry } from "@client/src/utils";
import { useCustomerContext } from "@client/src/Context/CustomerContext";
import { useFullScreen } from "@client/src/utils/dialog";
import { useInfoSnackbar } from "@client/src/Components/SharedSnackbar/SharedSnackbar.context";
import cdAppSettings from "@cdAppSettings";

export const getDiagramList = async (api, customer, setDiagramList) => {
  let date = new Date().getTime(),
    daysBackObj = {
      "24h": 1,
      "7d": 7,
      "28d": 28,
      "365d": 365,
    },
    start = new Date(date - daysBackObj["7d"] * 24 * 60 * 60 * 1000), // NOTE: days*hours*mins*secs*milisecs
    end = new Date(date);

  try {
    let { data } = await api.request({
      method: "get",
      baseURL: cdAppSettings.baseUrl,
      url: `/cloud-diagrams/api/scheme/stats`,
      params: {
        start,
        end,
        customer: customer.id,
      },
    });
    setDiagramList(data);
  } catch (err) {
    setDiagramList([]);
    consoleErrorWithSentry(err);
  }
};

const BorderBox = ({ children, underline, onClick, ...props }) => {
  return (
    <Box
      className={`__item ${underline ? "_clickable" : ""}`}
      sx={{ borderColor: "divider" }}
      {...props}
      onClick={onClick || ((e) => e.stopPropagation())}
    >
      <Box className="__text" sx={{ borderColor: underline ? "text.secondary" : "transparent" }}>
        {children}
      </Box>
    </Box>
  );
};

const DiagramCard = ({ item }) => {
  const [popover, setPopover] = useState(null);

  const darkMode = useDarkThemeCheck();
  const { customer } = useCustomerContext();
  const { isMobile } = useFullScreen();
  const showInfoSnackbar = useInfoSnackbar(3);

  const openDiagram = () => {
    if (isMobile) {
      showInfoSnackbar("Cloud Diagrams are unavailable on mobile screens. Please use a desktop.");
      return;
    }
    window.location.href = `/cloud-diagrams/scheme/${item._id}/${item.ss_id}/${customer.id}`;
  };

  const changesLen = item.changes?.length || 0;

  const nodeCreateList = item.changes?.filter((c) => c.type === "NODE_CREATE");
  const nodeUpdateList = item.changes?.filter((c) => c.type === "NODE_UPDATE");
  const nodeDeleteList = item.changes?.filter((c) => c.type === "NODE_DELETE");

  return (
    <Card sx={{ cursor: "pointer" }} onClick={openDiagram}>
      <CardContent sx={{ padding: "12px 16px 16px" }}>
        <Typography variant="h6" noWrap sx={{ mb: "-2px" }}>
          {item.name || "Unnamed"}
        </Typography>
        <Typography variant="body2" noWrap sx={{ color: "text.secondary", mb: "5px" }}>
          {item.account_name ? `${item.account_name}${item.account_type ? ` (${item.account_type})` : ""}` : "-"}
        </Typography>
        <Box variant="body2" className="cd-diagram-card-bottomline">
          <BorderBox>
            <img src={darkMode ? awsLogoDark : awsLogo} width={24} alt="aws logo" style={{ marginBottom: "-3px" }} />
          </BorderBox>
          <BorderBox>
            <Typography
              variant="body2"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              All regions
            </Typography>
          </BorderBox>
          <BorderBox
            underline={changesLen > 0}
            onClick={
              changesLen > 0
                ? (e) => {
                    e.stopPropagation();
                    setPopover((s) => (s ? null : e.target));
                  }
                : null
            }
            onMouseEnter={changesLen > 0 ? (e) => setPopover(e.target) : null}
            onMouseLeave={changesLen > 0 ? () => setPopover(null) : null}
          >
            <Typography variant="body2">
              {changesLen} change{changesLen == 1 ? "" : "s"}
            </Typography>
          </BorderBox>
          {changesLen > 0 && (
            <Popover
              sx={{
                pointerEvents: "none",
              }}
              open={!!popover}
              anchorEl={popover}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              disableRestoreFocus
              slotProps={{
                paper: {
                  style: {
                    width: "323px",
                    padding: "16px",
                  },
                },
              }}
            >
              <Typography variant="subtitle1" sx={{ fontWeight: "500", mb: "-14px" }}>
                Changes (last 7 days)
              </Typography>
              {nodeCreateList.length > 0 && (
                <>
                  <Typography variant="body2" color="success" sx={{ mt: "22px" }}>
                    Created
                  </Typography>
                  {nodeCreateList.map((c, i) => (
                    <Typography key={i} variant="body2">
                      {c.count} {c.service} instance{c.count > 1 ? "s" : ""}
                    </Typography>
                  ))}
                </>
              )}
              {nodeUpdateList.length > 0 && (
                <>
                  <Typography variant="body2" color="text.secondary" sx={{ mt: "22px" }}>
                    Updated
                  </Typography>
                  {nodeUpdateList.map((c, i) => (
                    <Typography key={i} variant="body2">
                      {c.count} {c.service} instance{c.count > 1 ? "s" : ""}
                    </Typography>
                  ))}
                </>
              )}
              {nodeDeleteList.length > 0 && (
                <>
                  <Typography variant="body2" color="error" sx={{ mt: "22px" }}>
                    Deleted
                  </Typography>
                  {nodeDeleteList.map((c, i) => (
                    <Typography key={i} variant="body2">
                      {c.count} {c.service} instance{c.count > 1 ? "s" : ""}
                    </Typography>
                  ))}
                </>
              )}
            </Popover>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

const DashboardList = ({ searchQuery = "", diagramList }) => {
  const filteredDiagrams = diagramList.filter(
    (diagram) =>
      diagram.name?.toLowerCase().includes(searchQuery) ||
      diagram.account_name?.toLowerCase().includes(searchQuery) ||
      diagram.account_type?.toLowerCase().includes(searchQuery)
  );

  return (
    <Grid container spacing={2} sx={{ padding: "0.5rem 0" }}>
      {filteredDiagrams.map((c, i) => (
        <Grid size={{ xs: 12, sm: 6, md: 4, lg: 3, xl: 2 }} key={i}>
          <DiagramCard {...{ item: c }} />
        </Grid>
      ))}
    </Grid>
  );
};

export default DashboardList;
