import { memo, useCallback, useEffect, useMemo, useRef } from "react";

import { useParams } from "react-router";

import { useAttributionGroups } from "../../../Components/hooks/cloudAnalytics/attributionGroups/useAttributionGroups";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { useAuthContext } from "../../../Context/AuthContext";
import { type AttributionWRef } from "../../../types";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { isOwner } from "../utilities";
import { Allocation } from "./Allocation";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { formStoreManager } from "./hooks/useAllocationFormState";
import { type AttributionGroupWithRef } from "./types";
import { trackAllocationEvent } from "./utils";

type Allocations = Array<AttributionGroupWithRef | AttributionWRef>;
const isAttributionGroup = (item: AttributionWRef | AttributionGroupWithRef): item is AttributionGroupWithRef =>
  "attributions" in item.data;

export const AllocationsEdit = memo(() => {
  const { allocationId } = useParams<{ allocationId: string }>();
  const { attributions: filteredAttributions } = useAttributionsContext();
  const [attributionGroups] = useAttributionGroups();
  const { trackEvent } = useSegmentTrackEvent();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const {
    addItem,
    clear,
    setHasUnallocatedCosts,
    setUnallocatedCosts,
    setAllocationName,
    hasUnallocatedCosts,
    items,
    setIsGroup,
    setIsAllocationOwner,
  } = useAllocationDndStore();

  const findAttributionById = useCallback(
    (attributions: Allocations, id: string): AttributionGroupWithRef | AttributionWRef | undefined =>
      attributions?.find((item) => item?.ref.id === id),
    []
  );

  const mapAttributionsToGroup = useCallback(
    (attributionIds: Array<{ id: string }>, filteredAttributions: AttributionWRef[]): Allocations =>
      attributionIds
        .map((item) => findAttributionById(filteredAttributions, item.id))
        .filter((item): item is AttributionGroupWithRef | AttributionWRef => item !== undefined),
    [findAttributionById]
  );

  const currentAttribution = useMemo(() => {
    const allAttributions = [...filteredAttributions, ...attributionGroups];
    const attributionGroup = findAttributionById(allAttributions, allocationId);
    return attributionGroup;
  }, [allocationId, attributionGroups, filteredAttributions, findAttributionById]);

  const allocations = useMemo((): Allocations => {
    if (!currentAttribution) {
      return [];
    }
    setIsAllocationOwner(
      isOwner(currentUser.email, {
        collaborators: currentAttribution.data?.collaborators || [],
      })
    );

    if (isAttributionGroup(currentAttribution)) {
      const { attributions, nullFallback } = currentAttribution.data;
      setIsGroup(true);

      setAllocationName(currentAttribution.data.name);
      if (nullFallback) {
        setUnallocatedCosts(nullFallback);
        setHasUnallocatedCosts(true);
      }
      const mappedAttributions = mapAttributionsToGroup(attributions, filteredAttributions);
      return mappedAttributions;
    }

    return [currentAttribution];
  }, [
    currentAttribution,
    currentUser.email,
    filteredAttributions,
    mapAttributionsToGroup,
    setAllocationName,
    setHasUnallocatedCosts,
    setIsGroup,
    setIsAllocationOwner,
    setUnallocatedCosts,
  ]);

  const handleAllocationsStore = useCallback(
    (allocations) => {
      allocations?.forEach((item) => {
        if (item.ref && item.data) {
          const storeId = crypto.randomUUID();
          addItem(storeId);
          const { filters, formula, name } = item.data;

          const newStore = formStoreManager.getStore(storeId);
          const { setEditMode, setSelectedExistingRule, setViewType } = newStore.getState();
          const allocationData = {
            id: item.ref.id,
            name,
            filterFields: filters,
            formula,
          };

          setEditMode(allocationData);
          setSelectedExistingRule(item);
          setViewType("existing");
        }
      });
    },
    [addItem]
  );

  useEffect(() => {
    handleAllocationsStore(allocations);
    return () => {
      clear();
    };
  }, [allocations, handleAllocationsStore, clear]);

  const hasTrackedRef = useRef(false);

  useEffect(() => {
    if (items.main.length > 0 && !hasTrackedRef.current) {
      hasTrackedRef.current = true;
      trackAllocationEvent({
        trackEvent,
        eventName: "Allocation Opened",
        ruleCount: items.main.length,
        hasUnallocatedCosts,
        allocationId,
        collaborators: currentAttribution?.data.collaborators,
        currentUserEmail: currentUser.email,
      });
    }
  }, [allocationId, currentAttribution, currentUser.email, hasUnallocatedCosts, items.main.length, trackEvent]);

  return <Allocation edit />;
});

AllocationsEdit.displayName = "Edit Allocation";
