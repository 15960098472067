import { useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Collapse, Typography } from "@mui/material";

import { Alert } from "../../../Components/Alerts";

type Props = {
  title?: string;
  buttonText?: string;
  link?: string;
  onClick?: () => void;
  children: React.ReactNode;
};

export const PermissionsAlert: React.FC<Props> = ({ onClick, title, buttonText, link, children }) => {
  const [hidden, setHidden] = useState(false);

  return (
    <Collapse in={!hidden}>
      <Alert
        sx={{ mt: -2, mb: 4 }}
        severity="info"
        onClose={() => {
          setHidden(true);
        }}
        title={title ?? "Permissions needed"}
        icon={<InfoOutlinedIcon fontSize="inherit" />}
        buttonProps={{
          text: buttonText ?? "Add permissions",
          href: link,
          onClick,
          key: "info",
          color: "inherit",
          sx: { whiteSpace: "nowrap" },
        }}
      >
        <Typography color="inherit" variant="body2">
          {children}
        </Typography>
      </Alert>
    </Collapse>
  );
};
