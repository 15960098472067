import { type AttributionFilter } from "@doitintl/cmp-models";
import { type DateTime } from "luxon";

export type CommitmentPeriod = {
  startDate: Date;
  endDate: Date;
  commitmentValue?: string;
  periodLength: number;
  marketplaceLimitPercentage: string;
};

export type ContractType = "google-cloud" | "amazon-web-services";

export type CommitmentFormValues = {
  name: string;
  selectedContractIds: string[];
  numberOfPeriods: number;
  contractType?: ContractType;
  currency: string;
  startDate: DateTime;
  periods: CommitmentPeriod[];
  excess: boolean;
  excessValue: number;
  shortfall: boolean;
  shortfallValue: number;
  billingAccounts: string[];
  serviceFilter?: AttributionFilter;
  skuFilter?: AttributionFilter;
};

export type TableRow = {
  name: string;
  type: string;
  data: Record<string, number | null>;
  total?: number;
};

export type TotalRow = {
  name: string;
  data: Record<string, number>;
  total: number;
};

export interface TableData {
  months: string[];
  rows: TableRow[];
  totalRow: TotalRow;
  marketplaceEligableData: Record<string, number>; // <month, spend>
}

export type DataPoint = { x: number; y: number };

export interface SeriesData {
  totalEligible: DataPoint[];
  marketplace: DataPoint[];
  commitment: DataPoint[];
}

export interface ProcessedPeriod {
  tableData: TableData;
  seriesData: SeriesData;
}

export type MonthlyBreakdown = {
  total?: number;
  services?: Record<string, number>;
  marketplace?: Record<string, number>;
  credits?: Record<string, number>;
  marketplaceTotal?: number;
};

export type MonthlySpend = Record<string, MonthlyBreakdown>; // <month, breakdown>

export class CommitmentManagerError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "CommitmentManagerError";
  }
}
