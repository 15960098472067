import { Fragment, useCallback } from "react";

import DeleteIcon from "@mui/icons-material/Delete";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Autocomplete, Button, Container, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Stack } from "@mui/system";
import { DatePicker } from "@mui/x-date-pickers";

import { roundWithCommas } from "../../../../../utils/common";
import { lookerSkuDefaultValues, useContractFormContext } from "../../ContractsFormContext";
import { getLookerSkuStartDates, isLooker } from "../../utils";
import { skus } from "./skuData";

const dateFormat = "dd MMM yyyy";

export const LookerContractSKUsStep = () => {
  const { state, handleChange, handleChangeDate, setState } = useContractFormContext();

  const lookerSkusError = state.errors.lookerSkus;
  const lookerSkuStartDates = isLooker(state) && getLookerSkuStartDates(state);
  const lookerContractDurationError = state.errors.lookerContractDuration;

  const handleAddSku = useCallback(() => {
    if (!isLooker(state)) {
      return;
    }

    const sku = { ...lookerSkuDefaultValues };
    setState({ ...state, lookerSkus: [...state.lookerSkus, sku] });
  }, [setState, state]);

  const handleDeleteSku = useCallback(
    (i) => {
      if (!isLooker(state)) {
        return;
      }

      const lookerSkus = [...state.lookerSkus];
      lookerSkus.splice(i, 1);

      const errors = { ...state.errors };
      if (errors.lookerSkus) {
        errors.lookerSkus.splice(i, 1);
      }

      setState({ ...state, lookerSkus, errors });
    },
    [setState, state]
  );

  const handleSkusChange = useCallback(
    (index: number, nameOrEvent: string | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value?: any) => {
      if (!isLooker(state)) {
        return;
      }

      setState((prevState) => {
        if (!isLooker(prevState)) {
          return prevState;
        }
        const skus = [...prevState.lookerSkus];
        const skusErrors = [...(prevState.errors.lookerSkus ?? [])];
        const name = typeof nameOrEvent === "string" ? nameOrEvent : nameOrEvent.target.name;
        const newValue = typeof nameOrEvent === "string" ? value : nameOrEvent.target.value;

        skus[index] = {
          ...skus[index],
          [name]: newValue,
        };

        skusErrors[index] = {
          ...skusErrors[index],
          [name]: false,
        };

        return {
          ...prevState,
          lookerSkus: skus,
          errors: { ...prevState.errors, lookerSkus: skusErrors },
        };
      });
    },
    [setState, state]
  );

  if (!isLooker(state)) {
    return null;
  }

  let contractEndDate = state.startDate;
  if (state.startDate?.isValid && state.lookerContractDuration > 0) {
    contractEndDate = state.startDate.plus({ months: state.lookerContractDuration }).minus({ days: 1 });
  }
  const totalSalesPrices = state.lookerSkus.map((sku) => {
    if (sku.monthlySalesPrice > 0 && sku.months > 0 && sku.quantity > 0) {
      return sku.monthlySalesPrice * sku.months * sku.quantity;
    }
    return 0;
  });
  const annualizedValue = state.lookerSkus.reduce(
    (total, sku) =>
      sku.monthlySalesPrice > 0 && sku.months > 0 && sku.quantity > 0
        ? total + sku.monthlySalesPrice * sku.months * sku.quantity
        : total,
    0
  );

  const minContractDuration = Math.max(...state.lookerSkus.map((sku) => sku.months));
  const invalidContractDuration = state.lookerContractDuration < minContractDuration;

  return (
    <Container maxWidth="sm">
      <Grid
        container
        spacing={2}
        sx={{
          mb: 3,
        }}
      >
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <DatePicker
            renderInput={(params) => <TextField data-cy="contractStartDate" margin="dense" fullWidth {...params} />}
            label="Overall subscription start date"
            onChange={handleChangeDate("startDate")}
            value={state.startDate}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat={dateFormat}
            disabled={state.isEditForbidden}
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 6,
          }}
        >
          <TextField
            data-cy="contractDuration"
            required={true}
            name="contractDuration"
            label="Months"
            onChange={handleChange("lookerContractDuration")}
            margin="dense"
            value={state.lookerContractDuration}
            error={lookerContractDurationError || invalidContractDuration}
            helperText={invalidContractDuration && "Months must be a positive number and greater than the longest SKU"}
            type="number"
            disabled={state.isEditForbidden}
            fullWidth
            slotProps={{
              input: { inputProps: { min: minContractDuration } },
            }}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="subtitle2">Overall subscription end date:</Typography>
          <Typography
            variant="body2"
            data-cy="contractEndDate"
            sx={{
              display: "block",
            }}
          >
            {contractEndDate?.isValid && contractEndDate.toFormat(dateFormat)}
          </Typography>
        </Grid>
        {state.lookerSkus.map((sku, i) => (
          <Fragment key={sku.skuName.googleSku}>
            <Grid size={12}>
              <Stack
                direction="row"
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="subtitle1"
                  component="span"
                  sx={{
                    fontWeight: "medium",
                  }}
                >
                  SKU {i + 1}
                </Typography>
                {state.lookerSkus.length > 1 && (
                  <Button
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleDeleteSku(i);
                    }}
                    data-cy="deleteSkuButton"
                  >
                    Delete SKU
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <Autocomplete
                id="skuName"
                data-cy="skuName"
                disableClearable
                value={sku.skuName}
                onChange={(_, value) => {
                  handleSkusChange(i, "skuName", value);
                }}
                options={skus}
                disabled={state.isEditForbidden}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="SKU name"
                    error={lookerSkusError?.[i]?.skuName}
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                name="googleSku"
                label="Google SKU"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                value={sku.skuName.googleSku}
                margin="dense"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <TextField
                required={true}
                data-cy="months"
                name="months"
                label="Months"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                value={sku.months}
                error={lookerSkusError?.[i]?.months}
                helperText={
                  "SKU months run backward from the end of the contract. " +
                  "Selecting fewer months than the overall subscription will delay the start date"
                }
                type="number"
                disabled={state.isEditForbidden}
                fullWidth
                slotProps={{
                  input: { inputProps: { min: 1, max: state.lookerContractDuration } },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 6,
              }}
            >
              <DatePicker
                renderInput={(params) => <TextField margin="dense" fullWidth {...params} />}
                label="Service start date"
                onChange={() => 0}
                value={lookerSkuStartDates[i]}
                inputFormat={dateFormat}
                disabled={true}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                required={true}
                name="quantity"
                label="Quantity"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                value={sku.quantity}
                error={lookerSkusError?.[i]?.quantity}
                type="number"
                disabled={state.isEditForbidden}
                fullWidth
                slotProps={{
                  input: { inputProps: { min: 1 } },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                type="number"
                name="monthlyListPrice"
                label="Monthly list price"
                value={sku.skuName.monthlyListPrice}
                disabled={true}
                margin="dense"
                fullWidth
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  },
                }}
              />
            </Grid>
            <Grid
              size={{
                xs: 12,
                sm: 4,
              }}
            >
              <TextField
                type="number"
                required={true}
                name="monthlySalesPrice"
                label="Monthly sales price"
                value={sku.monthlySalesPrice}
                error={lookerSkusError?.[i]?.monthlySalesPrice}
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                disabled={state.isEditForbidden}
                fullWidth
                slotProps={{
                  input: {
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                    inputProps: { min: 0 },
                  },
                }}
              />
            </Grid>
            <Grid size={12}>
              <Typography variant="subtitle2">Total sales price:</Typography>
              <Typography
                variant="body2"
                data-cy="totalPrice"
                sx={{
                  display: "block",
                }}
              >
                ${roundWithCommas(totalSalesPrices[i])}
              </Typography>
            </Grid>
            <Grid size={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddSku}
        data-cy="addSkuButton"
        disabled={state.isEditForbidden}
      >
        Add an additional SKU
      </Button>
      {state.discount > 0 && (
        <Grid
          container
          spacing={2}
          sx={{
            mb: 3,
          }}
        >
          <Grid size={12}>
            <Divider sx={{ mt: 3 }} />
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Typography variant="subtitle2">Annualized value</Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Typography
              variant="body2"
              data-cy="lookerAnnualizedValue"
              sx={{
                display: "block",
              }}
            >
              ${roundWithCommas(annualizedValue)}
            </Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Typography variant="subtitle2">{`One-time discount (${state.discount}%)`}</Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Typography
              variant="body2"
              data-cy="lookerDiscount"
              sx={{
                display: "block",
              }}
            >
              ${roundWithCommas(annualizedValue * (state.discount / 100))}
            </Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Typography variant="subtitle2">Total annualized value</Typography>
          </Grid>
          <Grid
            size={{
              xs: 12,
              sm: 6,
            }}
          >
            <Typography
              variant="body2"
              data-cy="lookerTotalAnnualizedValue"
              sx={{
                display: "block",
              }}
            >
              ${roundWithCommas(annualizedValue - annualizedValue * (state.discount / 100))}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};
