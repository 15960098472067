import { type ChangeEvent, useEffect, useMemo, useState } from "react";

import { type SupportServiceModel } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";
import debounce from "lodash/debounce";

import CaseIQ from "../../Support/Components/CaseIQ/CaseIQ";
import { type CaseIQPayload, setCaseIqSessionId, useCaseIQByDetails } from "../../Support/Components/CaseIQ/utils";
import CategorySuggest from "../../Support/Components/CategorySuggest";
import Editor from "../../Support/Components/Editor/Editor";
import SupportPlatformDropdown from "../../Support/Components/SupportPlatformDropdown";
import { AssetSelector } from "./AssetSelector";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { filterAssetsByPlatform, useCategoryListFromPlatform } from "./hooks";
import { SeverityDropdown } from "./SeverityDropdown";

type Props = {
  bigWidth: boolean;
};

const debouncedContentChange = debounce((newContent: string, setLocalContentToSend: (newContent: string) => void) => {
  setLocalContentToSend(newContent);
}, 1000);

export const AvaCreateTicket = ({ bigWidth }: Props) => {
  const [localPlatform, setLocalPlatform] = useState<string>("");
  const [localCloudProduct, setLocalCloudProduct] = useState<SupportServiceModel | null>(null);
  const [localAsset, setLocalAsset] = useState<string>("");
  const [localSeverity, setLocalSeverity] = useState<string>("");
  const [localContent, setLocalContent] = useState<string>("");
  const [localContentToSend, setLocalContentToSend] = useState<string>("");

  const { caseIQConversationId } = useAvaCaseIQContext();
  const { caseIQState, handleCaseIQStateChange, platformList, categoryList, assetsList } = useAvaCaseIQContext();

  const { asset, cloudProduct, content, platform, urgency, technicalDetails } = caseIQState;

  const categories = useCategoryListFromPlatform(categoryList, platform);

  const assets = useMemo(() => filterAssetsByPlatform(assetsList, platform), [assetsList, platform]);

  const caseIqSessionId = useMemo(() => crypto.randomUUID(), []);

  const payload: CaseIQPayload = useMemo(
    () => ({
      description: localContentToSend,
      userSelectedFields: {
        platform: localPlatform,
        product: localCloudProduct ? localCloudProduct?.id : "",
        subject: "",
      },
      conversationId: caseIQConversationId,
    }),
    [caseIQConversationId, localCloudProduct, localContentToSend, localPlatform]
  );

  const { caseIQDetails } = useCaseIQByDetails(payload);

  useEffect(() => {
    setCaseIqSessionId(caseIqSessionId);
  }, [caseIqSessionId]);

  useEffect(() => {
    if (!caseIQDetails?.requiredFields) return;

    const {
      platform: requiredPlatform,
      severity: requiredSeverity,
      product: requiredProduct,
      assetId: requiredAsset,
    } = caseIQDetails.requiredFields;

    if (requiredPlatform && requiredPlatform !== platform && !localPlatform) {
      handleCaseIQStateChange("platform", requiredPlatform);
    }

    if (requiredSeverity && requiredSeverity.toLocaleLowerCase() !== urgency && !localSeverity) {
      handleCaseIQStateChange("urgency", requiredSeverity.toLocaleLowerCase());
    }

    if (requiredProduct && requiredProduct !== cloudProduct?.id && !localCloudProduct) {
      const newCloudProduct = categoryList.find((category) => category.id === requiredProduct);
      if (newCloudProduct) {
        handleCaseIQStateChange("cloudProduct", newCloudProduct);
      }
    }

    if (requiredAsset) {
      const newAssets = filterAssetsByPlatform(assetsList, requiredPlatform);

      const found = newAssets.find((asset) => asset.identifier === requiredAsset);

      if (requiredAsset !== asset && !localAsset && found) {
        handleCaseIQStateChange("asset", requiredAsset ?? "");
      }
    }
  }, [
    caseIQDetails,
    platform,
    urgency,
    cloudProduct,
    asset,
    handleCaseIQStateChange,
    categoryList,
    localAsset,
    localCloudProduct,
    localPlatform,
    localSeverity,
    assetsList,
  ]);

  useEffect(() => {
    if (localPlatform) {
      handleCaseIQStateChange("platform", localPlatform);
    }
    if (localCloudProduct) {
      handleCaseIQStateChange("cloudProduct", localCloudProduct);
    }
    if (localAsset) {
      handleCaseIQStateChange("asset", localAsset);
    }
    if (localSeverity) {
      handleCaseIQStateChange("urgency", localSeverity);
    }
    if (localContent) {
      handleCaseIQStateChange("content", localContent);
    }
  }, [localPlatform, localCloudProduct, localAsset, localSeverity, localContent, handleCaseIQStateChange]);

  const caseIQTechnicalDetails = caseIQDetails?.technicalDetails.length
    ? caseIQDetails.technicalDetails
    : technicalDetails;

  return (
    <Stack
      px={2}
      py={3}
      alignItems="center"
      sx={{
        overflow: "auto",
        flex: 1,
      }}
      justifyContent={bigWidth ? "center" : "flex-start"}
    >
      <Stack width={bigWidth ? 980 : "100%"} alignItems={bigWidth ? "center" : "flex-start"}>
        <Typography variant={bigWidth ? "h1" : "subtitle1"} fontWeight={500}>
          How can we help you today?
        </Typography>
        <Typography variant={bigWidth ? "body1" : "caption"} color="text.secondary" pb={bigWidth ? 3 : 2}>
          Our Customer Reliability Engineers need additional details to assist you effectively
        </Typography>
      </Stack>
      <Stack spacing={2} direction={bigWidth ? "row" : "column"} width={bigWidth ? 980 : "100%"}>
        <Editor
          placeholder="Please provide a detailed description of your issue"
          name="body"
          minHeight={250}
          value={localContent || content}
          onChange={(newContent: string) => {
            const htmlContent = `<div>${content}</div>`;
            if (newContent.includes("<div>") && htmlContent !== newContent) {
              setLocalContent(newContent);
              debouncedContentChange(newContent, setLocalContentToSend);
            }
          }}
        />

        <Stack spacing={2} minWidth={bigWidth ? 380 : "auto"}>
          <SeverityDropdown
            onChange={(urgency: string) => {
              setLocalSeverity(urgency);
            }}
            value={localSeverity || urgency}
          />
          <SupportPlatformDropdown
            platformsList={platformList}
            handleChangePlatformField={(e: ChangeEvent) => {
              setLocalPlatform((e.target as HTMLSelectElement).value);
              setLocalCloudProduct(null);
              handleCaseIQStateChange("cloudProduct", null);
              setLocalAsset("");
            }}
            error={false}
            platform={localPlatform || platform}
            hideHelper
          />
          <CategorySuggest
            categories={categories}
            categoryTitle={localCloudProduct?.name || cloudProduct?.name || ""}
            onChange={(cloudProduct: SupportServiceModel) => {
              setLocalCloudProduct(cloudProduct);
              setLocalPlatform(platform);
            }}
            error={false}
          />
          <AssetSelector
            onChange={(asset) => {
              setLocalAsset(asset.identifier);
              setLocalCloudProduct(cloudProduct);
              setLocalPlatform(platform);
            }}
            selectedItem={localAsset || asset}
            platform={platform}
            assets={assets}
          />
          <CaseIQ detectedDetails={caseIQTechnicalDetails} setMinHeight={(_: number) => true} avaChat />
        </Stack>
      </Stack>
    </Stack>
  );
};
