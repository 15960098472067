import { useCallback, useState } from "react";

import { isAxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AttributionWRef } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { deleteAllocations } from "./db";
import { type AffectedResources, DeleteValidationDialog } from "./DeleteValidationDialog";
import { type AttributionGroupWithRef } from "./types";

type BlockingResource = {
  id: string;
  name: string;
  owner?: string;
};

type DeletionBlocker = {
  id: string;
  type: string;
  error: string;
  resources: Record<string, BlockingResource[]>;
};

export type DeletionValidationResponse = {
  validations: DeletionBlocker[];
};

type DeleteAllocationCallbacks = {
  ids: string[];
  onValidationRequired: (response: DeletionValidationResponse) => void;
  onDeletionSuccess: () => void;
  onDeletionError: (error: any) => void;
};

export const useAllocationDeletionApi = (mixpanelFrom?: string) => {
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const customerId = customer.id;

  return useCallback(
    async ({ ids, onValidationRequired, onDeletionSuccess, onDeletionError }: DeleteAllocationCallbacks) => {
      try {
        const response = await deleteAllocations(api, customerId, ids, mixpanelFrom);

        if (response?.data?.validations?.length > 0) {
          onValidationRequired(response?.data as DeletionValidationResponse);
        } else {
          onDeletionSuccess();
        }
      } catch (error) {
        consoleErrorWithSentry(error);

        if (isAxiosError(error) && error.response?.status === 409 && error.response?.data?.validations?.length > 0) {
          onValidationRequired(error.response?.data);
        } else {
          onDeletionError(error);
        }
      }
    },
    [api, customerId, mixpanelFrom]
  );
};

export const useDeleteAllocation = ({
  allocations,
}: {
  allocations: (AttributionWRef | AttributionGroupWithRef)[];
}) => {
  const deleteAllocationApi = useAllocationDeletionApi();
  const snackbar = useSnackbar();
  const [deleteValidationResponse, setDeleteValidationResponse] = useState<AffectedResources[] | null>(null);

  const handleValidationResponse = useCallback((validationResponse: DeletionValidationResponse): void => {
    const validations = validationResponse.validations || [];
    if (validations.length === 0) {
      setDeleteValidationResponse(null);
      return;
    }
    const transformedValidations: AffectedResources[] = validations.map((blocker) => {
      const resourcesMap = {};
      if (blocker.resources) {
        Object.entries(blocker.resources).forEach(([resourceType, items]) => {
          if (Array.isArray(items)) {
            const resourceArray = items.map((item) => ({
              id: item.id,
              name: item.name,
              owner: item.owner,
            }));
            resourcesMap[resourceType] = resourceArray;
          }
        });
      }
      return {
        id: blocker.id,
        resources: resourcesMap,
      };
    });
    setDeleteValidationResponse(transformedValidations);
  }, []);

  const closeValidationDialog = useCallback(() => {
    setDeleteValidationResponse(null);
  }, []);

  const deleteAllocationHandler = useCallback(
    async (params: { allocationIds: string[] }) =>
      new Promise((resolve, reject) => {
        deleteAllocationApi({
          ids: params.allocationIds,
          onValidationRequired: (validationResponse) => {
            handleValidationResponse(validationResponse);
            resolve(undefined);
          },
          onDeletionSuccess: () => {
            setDeleteValidationResponse(null);
            snackbar.onOpen({
              message: allocationTxt.DELETE_ALC_SUCCESS,
              variant: "success",
              autoHideDuration: 5000,
            });
            resolve(undefined);
          },
          onDeletionError: (error) => {
            setDeleteValidationResponse(null);
            snackbar.onOpen({
              message: allocationTxt.DELETE_ALC_ERROR,
              variant: "error",
              autoHideDuration: 5000,
            });
            reject(error);
          },
        });
      }),
    [snackbar, deleteAllocationApi, handleValidationResponse, setDeleteValidationResponse]
  );

  const ValidationDialog = useCallback(
    () =>
      deleteValidationResponse && (
        <DeleteValidationDialog
          deleteValidationResponse={deleteValidationResponse}
          onClose={closeValidationDialog}
          resources={allocations}
        />
      ),
    [deleteValidationResponse, closeValidationDialog, allocations]
  );

  return {
    deleteAllocation: deleteAllocationHandler,
    DeleteValidationDialog: ValidationDialog,
  };
};
