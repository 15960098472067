import { useCallback, useState } from "react";

import { type AttributionFilter, type MetadataCloud } from "@doitintl/cmp-models";
import { Box, TextField } from "@mui/material";

import { type MetadataOption } from "../../../types";
import FilterDialog from "./FilterDialog";

type Props = {
  onSave: (filter: AttributionFilter) => void;
  dimension: MetadataOption;
  cloudProviders?: MetadataCloud[];
  showExcludeSelection: boolean;
  valuesOnStart?: string[];
};

export const FilterDialogContainer = ({ dimension, onSave, cloudProviders, showExcludeSelection }: Props) => {
  const [open, setOpen] = useState(false);

  const handleSave = useCallback(
    (valuesOrRegexp: "values" | "regexp", filtersOrRegex: string[] | string, inverse: boolean) => {
      if (!dimension?.id) {
        return;
      }

      let regexp: string | null = null;
      let values: string[] | null = null;
      if (typeof filtersOrRegex === "string" && valuesOrRegexp === "regexp") {
        regexp = filtersOrRegex;
      } else if (Array.isArray(filtersOrRegex) && valuesOrRegexp === "values") {
        values = [...filtersOrRegex];
      }

      const filter: AttributionFilter = {
        id: dimension.id,
        values,
        regexp,
        inverse,
        allowNull: false,
        field: dimension.data.field,
        key: dimension.data.key,
        type: dimension.data.type,
      };

      if (dimension.data.nullFallback) {
        const nullIndex = filter.values?.findIndex((v) => v === dimension.data.nullFallback);
        if (nullIndex !== undefined && nullIndex !== -1) {
          filter.allowNull = true;
          filter.values?.splice(nullIndex, 1);
        }
      }
      onSave(filter);
    },
    [dimension, onSave]
  );

  return (
    <Box>
      <TextField
        value={dimension?.data.label}
        variant="outlined"
        fullWidth
        sx={{
          width: 220,
          "& .MuiInputBase-input": {
            cursor: "pointer",
          },
        }}
        InputProps={{
          readOnly: true,
        }}
        onClick={() => {
          setOpen(true);
        }}
      />

      <FilterDialog
        selected={dimension}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        onSave={handleSave}
        cloudProviders={cloudProviders}
        showExcludeSelection={showExcludeSelection}
      />
    </Box>
  );
};
