import { type ReactNode } from "react";

import { type CustomerModel, type InAppNotificationType, type UserModel } from "@doitintl/cmp-models";
import {
  getBatch,
  type ModelReference,
  type QueryDocumentSnapshotModel,
  type QueryModel,
} from "@doitintl/models-firestore";
import { type DocumentData, serverTimestamp } from "firebase/firestore";

import { type FirestoreTimestamp } from "../../utils/firebase";
import type Permissions from "../../utils/permissions";

export type InAppNotification<TResultModel extends DocumentData = any> = {
  id: string;
  ref: ModelReference<TResultModel>;
  type: InAppNotificationType;
  created: FirestoreTimestamp;
  updated: FirestoreTimestamp | undefined;
  title: string;
  description: string | ReactNode;
  urlPath: string;
  color: string;
};

export type InAppNotificationWithRead<TResultModel extends DocumentData = any> = InAppNotification<TResultModel> & {
  read: boolean;
};

export type RealTimeNotification = InAppNotification & { newOrUpdated: "New" | "Updated" };

export const maxDays = 30;

const getTTL = (n: InAppNotification) => {
  const notificationTime = n.updated ?? n.created;
  const ttl = new Date(notificationTime.toDate());
  ttl.setDate(ttl.getDate() + maxDays + 1);
  return ttl;
};

export const setLastReadBy = async (
  n: InAppNotification,
  userRef: ModelReference<UserModel>,
  customer: ModelReference<CustomerModel>
) => {
  await userRef
    .collection("inAppNotificationRead")
    .doc(n.id)
    .set({
      lastRead: serverTimestamp(),
      customer,
      ttl: getTTL(n),
    });
};

export const setAllLastReadBy = async (
  notifications: InAppNotification[],
  userRef: ModelReference<UserModel>,
  customer: ModelReference<CustomerModel>
) => {
  const batch = getBatch();
  notifications.forEach((n) => {
    const docRef = userRef.collection("inAppNotificationRead").doc(n.id);
    batch.set(docRef, {
      lastRead: serverTimestamp(),
      customer,
      ttl: getTTL(n),
    });
  });
  await batch.commit();
};

export interface Mapper<TResultModel extends DocumentData = any> {
  type: InAppNotificationType;
  userHasPermission: (userRoles: Permissions | null) => boolean;
  // use startTime to filter the collection by LAST UPDATE time
  getCollection: (
    customerRef: ModelReference<CustomerModel>,
    startTime: Date,
    userEmail?: string,
    isDoitEmployee?: boolean
  ) => QueryModel<TResultModel>;
  toNotification: (docSnap: QueryDocumentSnapshotModel<TResultModel>) => InAppNotification<TResultModel> | null;
}
