import { type ReactNode, type SyntheticEvent, useState } from "react";

import { type CloudFlowNodeType, type NodeConfigApiParameters } from "@doitintl/cmp-models/src/Cloudflow";
import { Alert, Box, Button, Tab, Tabs, Typography } from "@mui/material";
import { useFormikContext } from "formik";

import { CopyCodeBlock } from "../../../../../Components/CopyCodeBlock/CopyCodeBlock";
import { Loader } from "../../../../../Components/Loader";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ActionInputForm } from "../../ApiActionParametersForm/ActionInputForm";
import { useApiActionParametersValidationSchemaContext } from "../../ApiActionParametersForm/ApiActionParametersForm";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import { type TestInput, type TestNodeState, useTestNode } from "../TestNodeContext";

type TabKeys = "in" | "out";

type TabProps = Readonly<{
  children?: ReactNode;
  index: TabKeys;
  value: TabKeys;
}>;

function TabPanel({ index, value, children }: TabProps) {
  return (
    <div role="tabpanel" hidden={value !== index}>
      {value === index && (
        <Box
          sx={{
            pt: 2,
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

const TestsTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { state, isLoading, mutate: testNode } = useTestNode();
  const [testInputValid, setTestInputValid] = useState(false);
  const [testInput, setTestInput] = useState<TestInput>(nodeConfig.parameters);
  const [currentTab, setCurrentTab] = useState<TabKeys>("in");
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();

  const handleTestNode = () => {
    testNode(testInput, {
      onSuccess: () => {
        successSnackbar("Test node successful");
      },
      onError: () => {
        errorSnackbar("Test node failed. Please try again or contact support.");
      },
    });
    setCurrentTab("out");
  };

  const handleChange = (_: SyntheticEvent, newVal: TabKeys) => {
    setCurrentTab(newVal);
  };

  return (
    <Box
      sx={{
        p: 2,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          fontWeight: 500,
        }}
      >
        Test this step
      </Typography>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          mt: 1,
        }}
      >
        Testing allows you to confirm if the correct data is moving in and out of this step in your CloudFlow.
      </Typography>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Button variant="outlined" onClick={handleTestNode} disabled={!testInputValid}>
          Test
        </Button>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={currentTab} onChange={handleChange} sx={{ pt: 2 }}>
          <Tab label="Data in" value="in" sx={{ textTransform: "none" }} />
          <Tab label="Data out" value="out" sx={{ textTransform: "none" }} />
        </Tabs>
      </Box>
      <TabPanel value={currentTab} index="in">
        <DataInTab
          testInput={testInput}
          onTestInputChange={setTestInput}
          onTestInputValidityChange={setTestInputValid}
        />
      </TabPanel>
      <TabPanel value={currentTab} index="out">
        <DataOutTab state={state} loading={isLoading} />
      </TabPanel>
    </Box>
  );
};

function DataFromParametersButton({
  parameters,
}: Readonly<{
  parameters: NodeConfigApiParameters;
}>) {
  const { setValues } = useFormikContext<TestInput>();
  const validationSchema = useApiActionParametersValidationSchemaContext();

  const handleCopyNodeConfigValues = () => {
    setValues(validationSchema.cast(parameters));
  };

  return (
    <Button variant="outlined" onClick={handleCopyNodeConfigValues} sx={{ mb: 2, alignSelf: "flex-start" }}>
      Use Data from Parameters
    </Button>
  );
}

type DataInProps = Readonly<{
  testInput: TestInput;
  onTestInputChange: (input: TestInput) => void;
  onTestInputValidityChange: (isValid: boolean) => void;
}>;

function DataInTab({ testInput, onTestInputChange, onTestInputValidityChange }: DataInProps) {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();

  return (
    <ActionInputForm
      input={testInput}
      onInputChange={onTestInputChange}
      onInputValidityChange={onTestInputValidityChange}
      operationPointer={nodeConfig.parameters.operation}
    >
      <DataFromParametersButton parameters={nodeConfig.parameters} />
    </ActionInputForm>
  );
}

type DataOutProps = Readonly<{
  state: TestNodeState;
  loading: boolean;
}>;

function DataOutTab({ state, loading }: DataOutProps) {
  return (
    <Loader loading={loading}>
      {state === null && (
        <Typography variant="body2" color="textSecondary">
          Data output will show here after you run a test
        </Typography>
      )}
      {state?.isError && (
        <Alert severity="error" sx={{ mb: 1 }}>
          Test failed
        </Alert>
      )}
      {state?.result && <CopyCodeBlock base={JSON.stringify(state.result, undefined, 2)} />}
    </Loader>
  );
}

export default TestsTab;
