import { type ReactNode, useCallback, useEffect, useState } from "react";

import { DialogContent, TextField, Typography } from "@mui/material";
import { type TextFieldProps } from "@mui/material/TextField/TextField";

import { globalText } from "../../assets/texts";
import { useKeyPress } from "../hooks/useKeyPress";
import SimpleDialog from "../SimpleDialog";

type Props = {
  title: string;
  open: boolean;
  onClose: () => void;
  onSave: (name: any) => Promise<void> | void;
  textFieldProps?: TextFieldProps;
  description?: string;
  helperContent?: ReactNode;
  value?: string;
  confirmButtonText?: string;
};

const SaveDialog = ({
  title,
  open,
  onClose,
  onSave,
  textFieldProps,
  description,
  value,
  helperContent,
  confirmButtonText,
}: Props) => {
  const { helperText, label } = textFieldProps || {};
  const [name, setName] = useState(value || "");
  const [loading, setLoading] = useState(false);
  const enterKey = useKeyPress("enter");

  const handleSave = useCallback(async () => {
    setLoading(true);
    await onSave(name);
    setLoading(false);
    setName("");
  }, [name, onSave]);

  const handleChangeName = useCallback((event) => {
    setName(event.target.value);
  }, []);

  const disableConfirm = !name.trim() || loading;

  useEffect(() => {
    if (enterKey && !disableConfirm) {
      (async () => {
        await handleSave();
      })();
    }
  }, [enterKey, disableConfirm, handleSave]);

  return (
    <SimpleDialog
      open={open}
      title={title}
      onConfirm={handleSave}
      onCancel={onClose}
      confirmButtonText={confirmButtonText ? confirmButtonText : globalText.SAVE}
      disableConfirm={disableConfirm}
      dialogProps={{ fullWidth: true }}
      titleCy="save-dialog-title"
      confirmButtonCy="save-dialog-confirm-button"
    >
      <DialogContent sx={{ mt: 2 }} data-cy="save-dialog-content">
        {description && (
          <Typography variant="body1" sx={{ mb: 1 }}>
            {description}
          </Typography>
        )}
        <TextField
          sx={{ mt: 1 }}
          autoFocus
          variant="outlined"
          fullWidth
          value={name}
          onChange={handleChangeName}
          helperText={helperText}
          label={label ? label : globalText.NAME}
        />
        {helperContent}
      </DialogContent>
    </SimpleDialog>
  );
};

export default SaveDialog;
