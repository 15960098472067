import { type KubernetesFeature } from "@doitintl/cmp-models";
import { Box, Chip, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";

import { getAggregatedRbacPermissions } from "../../../rbac";

interface ChipWrapperProps {
  children: React.ReactNode;
}

const ChipWrapper = ({ children }: ChipWrapperProps) => (
  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>{children}</Box>
);

interface FeaturePermissionsProps {
  feature: KubernetesFeature;
}

const FeaturePermissions = ({ feature }: FeaturePermissionsProps) => {
  const aggregatedPermissions = getAggregatedRbacPermissions(feature.requirements);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Kubernetes resources</TableCell>
          <TableCell>Kubernetes verbs (permissions)</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(aggregatedPermissions).map(([apiGroup, values]) => (
          <TableRow key={apiGroup}>
            <TableCell>
              <ChipWrapper>
                {values?.resources.map((resource) => (
                  <Chip
                    key={resource}
                    variant="outlined"
                    size="small"
                    color="secondary"
                    label={apiGroup ? `${apiGroup}.${resource}` : resource}
                  />
                ))}
              </ChipWrapper>
            </TableCell>
            <TableCell>
              <ChipWrapper>
                {values?.verbs.map((verb) => (
                  <Chip key={verb} variant="outlined" size="small" color="warning" label={verb} />
                ))}
              </ChipWrapper>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default FeaturePermissions;
