import { Stack } from "@mui/system";

import { UtilizationCharts } from "./Charts";
import ClusterDetailHeader from "./Header";
import { WorkloadsTable } from "./WorkloadsTable";

const ClusterDetailPage = () => (
  <Stack spacing={3} mt={2}>
    <ClusterDetailHeader />
    <UtilizationCharts />
    <WorkloadsTable />
  </Stack>
);

export default ClusterDetailPage;
