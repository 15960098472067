import { type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";

import { type Customer } from "../../../types";

interface AwsCloudConnectLinkParams {
  customer: Customer;
  cloudConnect?: CustomerModelCloudConnectModel;
  callbackUrl?: string;
}

export const getAwsCloudConnectSettingsLink = ({ customer, cloudConnect, callbackUrl }: AwsCloudConnectLinkParams) => {
  let base = `/customers/${customer.id}/settings/aws`;

  if (cloudConnect) {
    base += `/${cloudConnect.accountId}`;
  }

  if (callbackUrl) {
    base += `?callbackUrl=${encodeURIComponent(callbackUrl)}`;
  }

  return base;
};
