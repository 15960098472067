import { type ClusterWithCloudConnect } from "@doitintl/cmp-models";

import { DEFAULT_DOIT_ROLE_NAME } from "../../../rbac";

export const getCodeVariablesForAwsAccessEntryCreation = (clusters: ClusterWithCloudConnect[], accountId: string) => {
  const variables = {
    $ACCOUNT_ID: {
      value: accountId ?? "",
      name: "Account ID",
    },
  };

  clusters.forEach((cluster, index) => {
    variables[`$CLUSTER_${index}_NAME`] = {
      value: cluster.name,
      name: `Cluster name`,
    };

    variables[`$CLUSTER_${index}_ROLE_NAME`] = {
      value: cluster.cloudConnect?.roleName ?? DEFAULT_DOIT_ROLE_NAME,
      name: "Role name",
    };

    variables[`$CLUSTER_${index}_REGION`] = {
      value: cluster.region,
      name: "Region",
    };
  });

  return variables;
};
