import { useMemo } from "react";

import { useClustersWithCloudConnect, useKubernetesFeatures } from "../../hooks";
import { type ClusterTableItem } from "./types";
import { getClusterAgentStatus, getClusterComputedStatus, getClusterEnabledFeatures } from "./utils";

export const useClusterTableItems = () => {
  const [clusters, loading] = useClustersWithCloudConnect({
    includeDeletedClusters: true,
  });
  const [, , featureMap] = useKubernetesFeatures();

  const tableItems: ClusterTableItem[] = useMemo(
    () =>
      clusters?.map((cluster) => {
        const tableItem: ClusterTableItem = {
          ...cluster,
          computedStatus: getClusterComputedStatus(cluster),
          enabledFeatures: getClusterEnabledFeatures(cluster, featureMap),
          agentStatus: getClusterAgentStatus(cluster),
        };

        return tableItem;
      }) || [],
    [clusters, featureMap]
  );

  return [tableItems, loading] as const;
};
