export const PODFIT_DATA = {
  data: {
    summary: {
      cpuRequests: 4.895987237111279,
      cpuRequestsRecommend: 1.0405337923453228,
      cpuAllocatable: 11.70974358974359,
      cpuUsed: 0.35163726602126144,
      memRequestsMB: 4686.108284155489,
      memRequestsRecommendMB: 3103.4432928284614,
      memAllocatableMB: 46955.73824328205,
      memUsedMB: 2343.3349136476236,
      clusterCostTimeFrame: 7.496611018178219,
      workloadsCostTimeFrame: 1.6794550217796422,
      workloadsProjectedCostTimeFrame: 21.703728746852306,
      workloadsHistoricalWasteTimeFrame: 1.396568803633056,
      podFitRecommendWorkloadsCostTimeFrame: 1.6333005240271952,
      workloadsPodFitSavingTimeFrame: 0.46073306176472345,
      cpuTotalPodFitWaste: 2.779537394751131,
      cpuTotalPodFitWastePct: 85.99815027611263,
      memTotalPodFitWasteMB: 1305.4431926587176,
      memTotalPodFitWastePct: 34.25020380571732,
      numberOfWorkloads: 36,
      workloadsWithResilienceIssues: {
        "1": 24,
        "2": 2,
      },
      workloadsWithSilencedResilienceIssues: 0,
      workloadsWithWasteIssues: 12,
      openTickets: 0,
      memRequestsMiB: 4469.021114497651,
      memRequestsRecommendMiB: 2959.6741607937442,
      memAllocatableMiB: 44780.48157051282,
      memUsedMiB: 2234.7783218837963,
      memTotalPodFitWasteMiB: 1244.9676443659964,
      idleCost: 4.673531533286387,
      podsRecommendedCostIncrease: 0.074818954661153,
    },
    workloads: [
      {
        id: "kube-system-daemonset-cilium-node-init",
        name: "cilium-node-init",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "727da1c0",
        revisionHash: "666598b775",
        calculatedRevisionId: "727da1c0",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0009716809801691583,
        wastePerHourAllReplicas: 0.00008693580376831293,
        hourlyCost: 0.0036053816512,
        timeframeCost: 0.14000898745493334,
        projectedTimeFrameCost: 1.8093469148022154,
        historicalTimeFrameWaste: 0.1394982371434697,
        estimatedHourlyCost: 0.009806342650726154,
        estimatedTimeframeCost: 0.12748245445944,
        savingPct: 8.946949208903767,
        containers: {
          "node-init": {
            name: "node-init",
            runningMinutes: 2330,
            cpuCoresRequest: 0.1,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00018945525032263244,
            cpuCoresMaxUse: 0.0004924852973210051,
            cpuCores80p: 0.0002654986356682214,
            cpuCores90p: 0.0003150522708303022,
            cpuCores95p: 0.0003458820535952062,
            cpuCores99p: 0.00039572529331954724,
            cpuCores999p: 0.00046589522462697167,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 104.8576,
            memMBLimits: 0,
            memMBAvgUse: 0.7446624616105778,
            memMBMaxUse: 3.31776,
            memMB80p: 0.7496825868527418,
            memMB90p: 0.7536585325295668,
            memMB95p: 0.755543446918793,
            memMB99p: 0.7577600121437452,
            memMB999p: 0.7720017861336976,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0004924852973210051,
            aggregatedMaxMemMib: 3317760,
            aggregatedP95Cpu: 0.0003458820535952062,
            aggregatedP95MemMib: 0.7205423802555018,
            aggregatedP99Cpu: 0.00039572529331954724,
            aggregatedP99MemMib: 0.7226562615811779,
            aggregatedP100Cpu: 0.0004924852796648338,
            aggregatedP100MemMib: 0.9570312508294488,
            aggregatedCpuCount: 4657,
            aggregatedMemCount: 4657,
            latestCpuCount: 4657,
            latestMemCount: 4657,
            memMiBRequest: 100,
            memMiBLimits: 0,
            memMiBAvgUse: 0.7101654640298631,
            memMiBMaxUse: 3.1640625,
            memMiB80p: 0.7149530285384577,
            memMiB90p: 0.7187447858138721,
            memMiB95p: 0.7205423802555018,
            memMiB99p: 0.7226562615811779,
            memMiB999p: 0.7362382756554581,
            recommendCpuCoresRequest: 0.091,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 95.87184,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 91.43051147460938,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0.1,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 100,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "b01952e7",
          },
        },
        maxIndicator: {
          name: "OverProvisionedCpuRequest",
          type: "waste",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.00032248959928808614,
        wasteTimeFrameUsd: 0.012523346105687345,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:08Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app: "cilium-node-init",
          label_app_kubernetes_io_name: "cilium-node-init",
          label_app_kubernetes_io_part_of: "cilium",
          label_controller_revision_hash: "666598b775",
          label_pod_template_generation: "1",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.14000898745493334,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-gke-metadata-server",
        name: "gke-metadata-server",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "396afde1",
        revisionHash: "978f58459",
        calculatedRevisionId: "396afde1",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0009716809801691583,
        wastePerHourAllReplicas: 0.00008566891283396702,
        hourlyCost: 0.0036053816512,
        timeframeCost: 0.14000898745493334,
        projectedTimeFrameCost: 1.8093469148022154,
        historicalTimeFrameWaste: 0.1344418404684373,
        estimatedHourlyCost: 0.009820384622284375,
        estimatedTimeframeCost: 0.1276650000896969,
        savingPct: 8.816567842982069,
        containers: {
          "gke-metadata-server": {
            name: "gke-metadata-server",
            runningMinutes: 2330,
            cpuCoresRequest: 0.1,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0005557353940330965,
            cpuCoresMaxUse: 0.004787307418375035,
            cpuCores80p: 0.0008105614106325599,
            cpuCores90p: 0.0009732554066699529,
            cpuCores95p: 0.0010816722867575388,
            cpuCores99p: 0.0015021643546786912,
            cpuCores999p: 0.002635863220031533,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 104.8576,
            memMBLimits: 104.8576,
            memMBAvgUse: 24.266456877313278,
            memMBMaxUse: 32.60416,
            memMB80p: 25.03100395081351,
            memMB90p: 26.574092890183167,
            memMB95p: 27.419470229287057,
            memMB99p: 27.79784807228105,
            memMB999p: 28.124224177767577,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.004787307418375035,
            aggregatedMaxMemMib: 32604160,
            aggregatedP95Cpu: 0.0010816722867575388,
            aggregatedP95MemMib: 26.149244527136858,
            aggregatedP99Cpu: 0.0015021643546786912,
            aggregatedP99MemMib: 26.510093757897423,
            aggregatedP100Cpu: 0.00478730745271605,
            aggregatedP100MemMib: 27.417968828231096,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 100,
            memMiBLimits: 100,
            memMiBAvgUse: 23.142296674073485,
            memMiBMaxUse: 31.09375,
            memMiB80p: 23.871425581754217,
            memMiB90p: 25.34302987116162,
            memMiB95p: 26.149244527136858,
            memMiB99p: 26.510093757897423,
            memMiB999p: 26.82135026718862,
            recommendCpuCoresRequest: 0.091,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 96.98129146177126,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 93.212666382035,
            recommendMemMiBLimits: 96.42533276407,
            specCpuCoresRequest: 0.1,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 100,
            specMemMiBLimits: 100,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "7603b758",
          },
        },
        maxIndicator: {
          name: "OverProvisionedCpuRequest",
          type: "waste",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.0003145738264118828,
        wasteTimeFrameUsd: 0.01221595025899478,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:15Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_addonmanager_kubernetes_io_mode: "Reconcile",
          label_controller_revision_hash: "978f58459",
          label_k8s_app: "gke-metadata-server",
          label_pod_template_generation: "3",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.14000898745493334,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "gke-managed-cim-statefulset-kube-state-metrics",
        name: "kube-state-metrics",
        workloadType: "StatefulSet",
        namespace: "gke-managed-cim",
        revisionId: "73f6a570",
        revisionHash: "kube-state-metrics-6c699cbf77",
        calculatedRevisionId: "73f6a570",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0003515689142213319,
        wastePerHourAllReplicas: 0.00002910297293531501,
        hourlyCost: 0.00389672114656,
        timeframeCost: 0.05065737490528001,
        projectedTimeFrameCost: 0.65464915262208,
        historicalTimeFrameWaste: 0.04871694123263373,
        estimatedHourlyCost: 0.0035741494814400003,
        estimatedTimeframeCost: 0.046463943258720004,
        savingPct: 8.278027936506682,
        containers: {
          "ksm-metrics-collector": {
            name: "ksm-metrics-collector",
            runningMinutes: 780,
            cpuCoresRequest: 0.005,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.00014624536608494123,
            cpuCoresMaxUse: 0.0034705788090507016,
            cpuCores80p: 0.00023671580039011245,
            cpuCores90p: 0.00032425361667572723,
            cpuCores95p: 0.00039226665991760965,
            cpuCores99p: 0.0005704352130247674,
            cpuCores999p: 0.0008845549337799539,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 31.45728,
            memMBLimits: 31.45728,
            memMBAvgUse: 14.034904803832784,
            memMBMaxUse: 15.27808,
            memMB80p: 14.063941320160207,
            memMB90p: 14.07523859596724,
            memMB95p: 14.081659372862264,
            memMB99p: 14.08816896,
            memMB999p: 14.114816,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0034705788090507016,
            aggregatedMaxMemMib: 15278080,
            aggregatedP95Cpu: 0.00039226665991760965,
            aggregatedP95MemMib: 13.429316876280083,
            aggregatedP99Cpu: 0.0005704352130247674,
            aggregatedP99MemMib: 13.43552490234375,
            aggregatedP100Cpu: 0.0034705787074926775,
            aggregatedP100MemMib: 13.515625,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 30,
            memMiBLimits: 30,
            memMiBAvgUse: 13.384728244622025,
            memMiBMaxUse: 14.5703125,
            memMiB80p: 13.4124196244814,
            memMiB90p: 13.423193546263924,
            memMiB95p: 13.429316876280083,
            memMiB99p: 13.43552490234375,
            memMiB999p: 13.4609375,
            recommendCpuCoresRequest: 0.005,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 31.45728,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.005,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 30,
            specMemMiBLimits: 30,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "a8a036be",
          },
          "kube-state-metrics": {
            name: "kube-state-metrics",
            runningMinutes: 780,
            cpuCoresRequest: 0.1,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00031044589655882524,
            cpuCoresMaxUse: 0.001216173808086874,
            cpuCores80p: 0.000401037817897939,
            cpuCores90p: 0.0004769119109949649,
            cpuCores95p: 0.0005318934383869197,
            cpuCores99p: 0.0006877125041622829,
            cpuCores999p: 0.0010241334718647348,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 104.8576,
            memMBLimits: 524.288,
            memMBAvgUse: 14.161217557375755,
            memMBMaxUse: 17.129472,
            memMB80p: 14.840818765533108,
            memMB90p: 15.176233004197819,
            memMB95p: 15.30662636918696,
            memMB99p: 16.041873415017907,
            memMB999p: 16.408575962890627,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.001216173808086874,
            aggregatedMaxMemMib: 17129472,
            aggregatedP95Cpu: 0.0005318934383869197,
            aggregatedP95MemMib: 14.597536439120255,
            aggregatedP99Cpu: 0.0006877125041622829,
            aggregatedP99MemMib: 15.298722662942797,
            aggregatedP100Cpu: 0.001216173839111434,
            aggregatedP100MemMib: 15.656249964609742,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 100,
            memMiBLimits: 500,
            memMiBAvgUse: 13.505189473510509,
            memMiBMaxUse: 16.3359375,
            memMiB80p: 14.153307691128834,
            memMiB90p: 14.473183635900325,
            memMiB95p: 14.597536439120255,
            memMiB99p: 15.298722662942797,
            memMiB999p: 15.648437464609742,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 95.87184,
            recommendMemMBLimits: 474.8592,
            recommendMemMiBRequest: 15.42288515625,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.1,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 100,
            specMemMiBLimits: 500,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "cbe34639",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.003290480097725133,
        wasteTimeFrameUsd: 0.04277624127042673,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:48Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_name: "gke-managed-kube-state-metrics",
          label_apps_kubernetes_io_pod_index: "0",
          label_controller_revision_hash: "kube-state-metrics-6c699cbf77",
          label_statefulset_kubernetes_io_pod_name: "kube-state-metrics-0",
        },
        namespaceLabels: {
          label_addonmanager_kubernetes_io_mode: "Reconcile",
          label_kubernetes_io_metadata_name: "gke-managed-cim",
          namespace: "gke-managed-cim",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: {
            memory: {
              average: 419430400,
            },
          },
          HpaSpecMinReplicas: 1,
          HpaSpecMaxReplicas: 10,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 740.9872666666668,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: {
          memory: {
            average: 419430400,
          },
        },
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.05065737490528001,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-pdcsi-node",
        name: "pdcsi-node",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "704fd7e2",
        revisionHash: "78f6ffb7ff",
        calculatedRevisionId: "704fd7e2",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0001130003880624905,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.0004192832153903615,
        timeframeCost: 0.016282164864325703,
        projectedTimeFrameCost: 0.21041566901590142,
        historicalTimeFrameWaste: 0.007295091697673094,
        estimatedHourlyCost: 0.0012097071146912823,
        estimatedTimeframeCost: 0.015726192490986668,
        savingPct: 3.4146096540096647,
        containers: {
          "csi-driver-registrar": {
            name: "csi-driver-registrar",
            runningMinutes: 2330,
            cpuCoresRequest: 0.005,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.004539128964798462,
            cpuCoresMaxUse: 0.007870912951165435,
            cpuCores80p: 0.005144384743102957,
            cpuCores90p: 0.005452908327808719,
            cpuCores95p: 0.005751781251470245,
            cpuCores99p: 0.006297195395728763,
            cpuCores999p: 0.007128042252751816,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 10.48576,
            memMBLimits: 52.4288,
            memMBAvgUse: 5.26057327350583,
            memMBMaxUse: 9.048064,
            memMB80p: 5.430931809388222,
            memMB90p: 5.431296030425312,
            memMB95p: 5.431671580275515,
            memMB99p: 5.476148203047983,
            memMB999p: 7.299694598718139,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.007870912951165435,
            aggregatedMaxMemMib: 9048064,
            aggregatedP95Cpu: 0.005751781251470245,
            aggregatedP95MemMib: 5.1800456812625075,
            aggregatedP99Cpu: 0.006297195395728763,
            aggregatedP99MemMib: 5.2224618940811,
            aggregatedP100Cpu: 0.007870912781072548,
            aggregatedP100MemMib: 7.26171877704231,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 10,
            memMiBLimits: 50,
            memMiBAvgUse: 5.016873620515661,
            memMiBMaxUse: 8.62890625,
            memMiB80p: 5.179340180767271,
            memMiB90p: 5.179687529015839,
            memMiB95p: 5.1800456812625075,
            memMiB99p: 5.2224618940811,
            memMiB999p: 6.961531256406916,
            recommendCpuCoresRequest: 0.005,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 10.48576,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 10,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.005,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 10,
            specMemMiBLimits: 50,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "860eea00",
          },
          "gce-pd-driver": {
            name: "gce-pd-driver",
            runningMinutes: 2330,
            cpuCoresRequest: 0.005,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0002152987766927911,
            cpuCoresMaxUse: 0.0004682139253281132,
            cpuCores80p: 0.00026728732630670074,
            cpuCores90p: 0.0002991050686299762,
            cpuCores95p: 0.00033346104512249023,
            cpuCores99p: 0.00036842275467529337,
            cpuCores999p: 0.0004354448398890513,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 10.48576,
            memMBLimits: 471.8592,
            memMBAvgUse: 6.2505061517833544,
            memMBMaxUse: 7.221248,
            memMB80p: 6.275012035688375,
            memMB90p: 6.275072000929124,
            memMB95p: 6.275072002028991,
            memMB99p: 6.275072007446289,
            memMB999p: 6.401865047212717,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.0004682139253281132,
            aggregatedMaxMemMib: 7221248,
            aggregatedP95Cpu: 0.00033346104512249023,
            aggregatedP95MemMib: 5.984375001935676,
            aggregatedP99Cpu: 0.00036842275467529337,
            aggregatedP99MemMib: 5.984375007101335,
            aggregatedP100Cpu: 0.00046821391453022443,
            aggregatedP100MemMib: 6.230468751862645,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 10,
            memMiBLimits: 450,
            memMiBAvgUse: 5.960947181495051,
            memMiBMaxUse: 6.88671875,
            memMiB80p: 5.984317813576102,
            memMiB90p: 5.984375000886081,
            memMiB95p: 5.984375001934996,
            memMiB99p: 5.984375007101335,
            memMiB999p: 6.105294272625653,
            recommendCpuCoresRequest: 0.005,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 10.48576,
            recommendMemMBLimits: 471.8592,
            recommendMemMiBRequest: 10,
            recommendMemMiBLimits: 450,
            specCpuCoresRequest: 0.005,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 10,
            specMemMiBLimits: 450,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0,
        wasteTimeFrameUsd: 0,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:11Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_controller_revision_hash: "78f6ffb7ff",
          label_k8s_app: "gcp-compute-persistent-disk-csi-driver",
          label_pod_template_generation: "4",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.016282164864325703,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-fluentbit-gke",
        name: "fluentbit-gke",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "491bf6df",
        revisionHash: "667474b76d",
        calculatedRevisionId: "491bf6df",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0011699371277491722,
        wastePerHourAllReplicas: 0.000017307778891666407,
        hourlyCost: 0.0043410027977600005,
        timeframeCost: 0.1685756086463467,
        projectedTimeFrameCost: 2.1785155578912496,
        historicalTimeFrameWaste: 0.15021033170913217,
        estimatedHourlyCost: 0.012775518472065752,
        estimatedTimeframeCost: 0.16608174013685478,
        savingPct: 1.4793768383917085,
        containers: {
          fluentbit: {
            name: "fluentbit",
            runningMinutes: 2330,
            cpuCoresRequest: 0.05,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.004847469654220386,
            cpuCoresMaxUse: 0.010489948067671548,
            cpuCores80p: 0.0068324188647851305,
            cpuCores90p: 0.0070449570861707035,
            cpuCores95p: 0.007227363162183564,
            cpuCores99p: 0.007664033865140725,
            cpuCores999p: 0.008759409102428794,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 104.8576,
            memMBLimits: 262.144,
            memMBAvgUse: 14.332925256880085,
            memMBMaxUse: 30.375936,
            memMB80p: 14.652639989625685,
            memMB90p: 14.757913860380826,
            memMB95p: 14.86817314710749,
            memMB99p: 15.073160910832275,
            memMB999p: 15.354719902335175,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.010489948067671548,
            aggregatedMaxMemMib: 30375936,
            aggregatedP95Cpu: 0.007227363162183564,
            aggregatedP95MemMib: 14.179394862277498,
            aggregatedP99Cpu: 0.007664033865140725,
            aggregatedP99MemMib: 14.371684397294047,
            aggregatedP100Cpu: 0.010489948283066042,
            aggregatedP100MemMib: 15.324218729743734,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 100,
            memMiBLimits: 250,
            memMiBAvgUse: 13.66894269645699,
            memMiBMaxUse: 28.96875,
            memMiB80p: 13.973846425653157,
            memMiB90p: 14.074243412381007,
            memMiB95p: 14.179394862277498,
            memMiB99p: 14.374886427719378,
            memMiB999p: 14.64340200646894,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 96.80807572247937,
            recommendMemMBLimits: 240.80207144495876,
            recommendMemMiBRequest: 23.252013071100002,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.05,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 100,
            specMemMiBLimits: 250,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
          "fluentbit-gke": {
            name: "fluentbit-gke",
            runningMinutes: 2330,
            cpuCoresRequest: 0.05,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0005153312733439738,
            cpuCoresMaxUse: 0.004211139058569628,
            cpuCores80p: 0.0006972057453240841,
            cpuCores90p: 0.000822401667525612,
            cpuCores95p: 0.000976922263158949,
            cpuCores99p: 0.0013714116134832897,
            cpuCores999p: 0.0028029333319032497,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 104.8576,
            memMBLimits: 262.144,
            memMBAvgUse: 17.994510486044483,
            memMBMaxUse: 42.614784,
            memMB80p: 19.249950899757177,
            memMB90p: 19.806276329781852,
            memMB95p: 20.22107966541766,
            memMB99p: 20.71169872597595,
            memMB999p: 21.815574529761836,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.004211139058569628,
            aggregatedMaxMemMib: 42614784,
            aggregatedP95Cpu: 0.000976922263158949,
            aggregatedP95MemMib: 19.28432432691351,
            aggregatedP99Cpu: 0.0013714116134832897,
            aggregatedP99MemMib: 19.752215124107313,
            aggregatedP100Cpu: 0.004211139000290132,
            aggregatedP100MemMib: 21.33203126816079,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 100,
            memMiBLimits: 250,
            memMiBAvgUse: 17.16090248684357,
            memMiBMaxUse: 40.640625,
            memMiB80p: 18.358183765179803,
            memMiB90p: 18.888737039358,
            memMiB95p: 19.28432432691351,
            memMiB99p: 19.752215124107313,
            memMiB999p: 20.804953126680218,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 97.7502231814629,
            recommendMemMBLimits: 242.6863663629258,
            recommendMemMiBRequest: 32.21877271140001,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.05,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 100,
            specMemMiBLimits: 250,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "d23fe6b7",
          },
          "fluentbit-metrics-collector": {
            name: "fluentbit-metrics-collector",
            runningMinutes: 2330,
            cpuCoresRequest: 0.005,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.00020193115072738427,
            cpuCoresMaxUse: 0.0016194635445702524,
            cpuCores80p: 0.000346109038705283,
            cpuCores90p: 0.0004307638128669609,
            cpuCores95p: 0.0005032894545810558,
            cpuCores99p: 0.0006723289914228957,
            cpuCores999p: 0.0010030947284136872,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 31.45728,
            memMBLimits: 68.15744,
            memMBAvgUse: 15.038976000331607,
            memMBMaxUse: 16.150528,
            memMB80p: 15.115083807516397,
            memMB90p: 15.144007571085222,
            memMB95p: 15.179603416831128,
            memMB99p: 15.200627714384488,
            memMB999p: 15.41464450789343,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0016194635445702524,
            aggregatedMaxMemMib: 16150528,
            aggregatedP95Cpu: 0.0005032894545810558,
            aggregatedP95MemMib: 14.476397911864403,
            aggregatedP99Cpu: 0.0006781119697201749,
            aggregatedP99MemMib: 14.496448244461526,
            aggregatedP100Cpu: 0.0016194635336432839,
            aggregatedP100MemMib: 14.917968769959257,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 30,
            memMiBLimits: 65,
            memMiBAvgUse: 14.342285156566245,
            memMiBMaxUse: 15.40234375,
            memMiB80p: 14.41486721755638,
            memMiB90p: 14.442451068005774,
            memMiB95p: 14.476397911864403,
            memMiB99p: 14.496448244461526,
            memMiB999p: 14.700550563710623,
            recommendCpuCoresRequest: 0.005,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 31.45728,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 30,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.005,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 30,
            specMemMiBLimits: 65,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "aafd4e17",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.003170190042418885,
        wasteTimeFrameUsd: 0.1231090466472667,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:07Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_component: "fluentbit-gke",
          label_controller_revision_hash: "667474b76d",
          label_k8s_app: "fluentbit-gke",
          label_kubernetes_io_cluster_service: "true",
          label_pod_template_generation: "5",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.1685756086463467,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-cilium-operator",
        name: "cilium-operator",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "a1873f29",
        revisionHash: "55578bdc85",
        calculatedRevisionId: "a1873f29",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.000022385496172318984,
        wastePerHourAllReplicas: -0.00001863877898654158,
        hourlyCost: 0.0002481164653141012,
        timeframeCost: 0.0032255140490833156,
        projectedTimeFrameCost: 0.041683566172769006,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.00045470504947200003,
        estimatedTimeframeCost: 0.0059111656431360005,
        savingPct: -83.26274674934191,
        containers: {
          "cilium-operator": {
            name: "cilium-operator",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.002947429402414873,
            cpuCoresMaxUse: 0.004338514568158365,
            cpuCores80p: 0.003300316938814948,
            cpuCores90p: 0.003460661555427314,
            cpuCores95p: 0.003577816480826881,
            cpuCores99p: 0.0038458969602174894,
            cpuCores999p: 0.004090191295112846,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 32.68803806607319,
            memMBMaxUse: 47.947776,
            memMB80p: 32.710656,
            memMB90p: 32.74049867488515,
            memMB95p: 32.91645982180564,
            memMB99p: 33.19675050355332,
            memMB999p: 41.242624,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.004338514568158365,
            aggregatedMaxMemMib: 47947776,
            aggregatedP95Cpu: 0.003577816480826881,
            aggregatedP95MemMib: 31.39158231907429,
            aggregatedP99Cpu: 0.0038458969602174894,
            aggregatedP99MemMib: 31.658888343385044,
            aggregatedP100Cpu: 0.004338514504524937,
            aggregatedP100MemMib: 39.33203125,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 31.173742357323825,
            memMiBMaxUse: 45.7265625,
            memMiB80p: 31.1953125,
            memMiB90p: 31.223772692570833,
            memMiB95p: 31.39158231907429,
            memMiB99p: 31.658888343385044,
            memMiB999p: 39.33203125,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 32.710656,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 31.157368048941883,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "882d578b",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.00021156539853183512,
        wasteTimeFrameUsd: -0.0027503501809138567,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:46Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_name: "cilium-operator",
          label_app_kubernetes_io_part_of: "cilium",
          label_io_cilium_app: "operator",
          label_name: "cilium-operator",
          label_pod_template_hash: "55578bdc85",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.002685651594052685,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.0032255140490833156,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "cilium-test-1-deployment-client",
        name: "client",
        workloadType: "Deployment",
        namespace: "cilium-test-1",
        revisionId: "cb6ce212",
        revisionHash: "b65598b6f",
        calculatedRevisionId: "cb6ce212",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 8.76834397211046e-8,
        wastePerHourAllReplicas: -0.00003416634880882094,
        hourlyCost: 9.71866112e-7,
        timeframeCost: 0.000012634259456,
        projectedTimeFrameCost: 0.00016327350681600002,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.00037966500000000006,
        estimatedTimeframeCost: 0.004935645000000001,
        savingPct: -38965.56626598377,
        containers: {
          client: {
            name: "client",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 5e-324,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0.229376,
            memMBMaxUse: 3.002368,
            memMB80p: 0.229376,
            memMB90p: 0.229376,
            memMB95p: 0.229376,
            memMB99p: 0.229376,
            memMB999p: 0.229376,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 5e-324,
            aggregatedMaxMemMib: 3002368,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 0.21875,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 0.21874999999999997,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 0.21875,
            aggregatedCpuCount: 1558,
            aggregatedMemCount: 1558,
            latestCpuCount: 1558,
            latestMemCount: 1558,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0.21875,
            memMiBMaxUse: 2.86328125,
            memMiB80p: 0.21875,
            memMiB90p: 0.21875,
            memMiB95p: 0.21875,
            memMiB99p: 0.21875,
            memMiB999p: 0.21875,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.000378693133887895,
        wasteTimeFrameUsd: -0.004923010740542635,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:36Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_kind: "client",
          label_name: "client",
          label_pod_template_hash: "b65598b6f",
        },
        namespaceLabels: {
          label_app_kubernetes_io_name: "cilium-cli",
          label_kubernetes_io_metadata_name: "cilium-test-1",
          namespace: "cilium-test-1",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.004923010740544001,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.000012634259456,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-kube-dns-autoscaler",
        name: "kube-dns-autoscaler",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "660f46ce",
        revisionHash: "6c87cf5796",
        calculatedRevisionId: "660f46ce",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00006104836650451657,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.0006766481651200001,
        timeframeCost: 0.008796426146560002,
        projectedTimeFrameCost: 0.11367689174016002,
        historicalTimeFrameWaste: 0.008172945772451742,
        estimatedHourlyCost: 0.0006766481651200001,
        estimatedTimeframeCost: 0.008796426146560002,
        savingPct: 0,
        containers: {
          autoscaler: {
            name: "autoscaler",
            runningMinutes: 780,
            cpuCoresRequest: 0.02,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00026309149837643037,
            cpuCoresMaxUse: 0.0006689826900676748,
            cpuCores80p: 0.00034111130705086277,
            cpuCores90p: 0.0003836222212029618,
            cpuCores95p: 0.0004209961106258867,
            cpuCores99p: 0.0005062408921295567,
            cpuCores999p: 0.0006061539130220129,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 10.48576,
            memMBLimits: 0,
            memMBAvgUse: 8.351661872735038,
            memMBMaxUse: 9.515008,
            memMB80p: 8.415849785939923,
            memMB90p: 8.45724492402911,
            memMB95p: 8.503825920169755,
            memMB99p: 8.561851733429044,
            memMB999p: 8.62978867028025,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.0006689826900676748,
            aggregatedMaxMemMib: 9515008,
            aggregatedP95Cpu: 0.0004209961106258867,
            aggregatedP95MemMib: 8.11000380485863,
            aggregatedP99Cpu: 0.0005062408921295567,
            aggregatedP99MemMib: 8.16521809904961,
            aggregatedP100Cpu: 0.000668982686875097,
            aggregatedP100MemMib: 8.242187503958121,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 10,
            memMiBLimits: 0,
            memMiBAvgUse: 7.964765427336729,
            memMiBMaxUse: 9.07421875,
            memMiB80p: 8.025979791583941,
            memMiB90p: 8.06545727160369,
            memMiB95p: 8.10988037125564,
            memMiB99p: 8.16521809904961,
            memMiB999p: 8.230007810859918,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 10.48576,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 10,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.02,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 10,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "",
          type: "none",
          severity: 0,
        },
        indicatorState: "",
        wastePerHour: 0.00031611000000000014,
        wasteTimeFrameUsd: 0.004109430000000002,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:46Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "kube-dns-autoscaler",
          label_pod_template_hash: "6c87cf5796",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.008796426146560002,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-netd",
        name: "netd",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "3c73c880",
        revisionHash: "5b46c4d578",
        calculatedRevisionId: "3c73c880",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.00013147863840618076,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.00048784599072,
        timeframeCost: 0.01894468597296,
        projectedTimeFrameCost: 0.24482363411209845,
        historicalTimeFrameWaste: 0.013016737744939635,
        estimatedHourlyCost: 0.000012508871556923077,
        estimatedTimeframeCost: 0.01894468597296,
        savingPct: 0,
        containers: {
          netd: {
            name: "netd",
            runningMinutes: 20,
            cpuCoresRequest: 0.002,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0003432914448732542,
            cpuCoresMaxUse: 0.0010903882248345345,
            cpuCores80p: 0.000756642283420206,
            cpuCores90p: 0.0008727189575324701,
            cpuCores95p: 0.0008858128722636138,
            cpuCores99p: 0.0010171860573677802,
            cpuCores999p: 0.0010830680127469348,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 31.45728,
            memMBLimits: 0,
            memMBAvgUse: 10.284396072916666,
            memMBMaxUse: 11.69408,
            memMB80p: 10.39146096188965,
            memMB90p: 10.447667183764649,
            memMB95p: 10.486374383764646,
            memMB99p: 10.53552638376465,
            memMB999p: 10.55709182376465,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.0016904449689658326,
            aggregatedMaxMemMib: 50716672,
            aggregatedP95Cpu: 0.000882196104693392,
            aggregatedP95MemMib: 12.306033063438463,
            aggregatedP99Cpu: 0.0010901606433107015,
            aggregatedP99MemMib: 12.555998533420645,
            aggregatedP100Cpu: 0.001690444974883576,
            aggregatedP100MemMib: 12.789062455296516,
            aggregatedCpuCount: 4659,
            aggregatedMemCount: 4659,
            latestCpuCount: 40,
            latestMemCount: 40,
            memMiBRequest: 30,
            memMiBLimits: 0,
            memMiBAvgUse: 9.807964394489924,
            memMiBMaxUse: 11.15234375,
            memMiB80p: 9.910069429292344,
            memMiB90p: 9.963671859516763,
            memMiB95p: 10.00058592201676,
            memMiB99p: 10.047460922016763,
            memMiB999p: 10.068027328266762,
            recommendCpuCoresRequest: 0.002,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 31.45728,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 30,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0.002,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 30,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "65295976",
          },
          "netd-metrics-collector": {
            name: "netd-metrics-collector",
            runningMinutes: 20,
            cpuCoresRequest: 0.005,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.0001584198825905029,
            cpuCoresMaxUse: 0.0006850215738648045,
            cpuCores80p: 0.00043732997567758503,
            cpuCores90p: 0.000476575397999568,
            cpuCores95p: 0.0005147476466817124,
            cpuCores99p: 0.0006188854777610686,
            cpuCores999p: 0.0006784079665121678,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 31.45728,
            memMBLimits: 68.15744,
            memMBAvgUse: 12.452035048014322,
            memMBMaxUse: 44.572672,
            memMB80p: 12.760780808300781,
            memMB90p: 12.95564800830078,
            memMB95p: 13.224960008300782,
            memMB99p: 13.361152008300781,
            memMB999p: 13.361152008300781,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0011478813041884734,
            aggregatedMaxMemMib: 45834240,
            aggregatedP95Cpu: 0.00045899800896386856,
            aggregatedP95MemMib: 13.994877992904357,
            aggregatedP99Cpu: 0.0006166919249729302,
            aggregatedP99MemMib: 14.01139338700797,
            aggregatedP100Cpu: 0.00114788128996679,
            aggregatedP100MemMib: 14.43750002492552,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 40,
            latestMemCount: 40,
            memMiBRequest: 30,
            memMiBLimits: 65,
            memMiBAvgUse: 11.875186012281725,
            memMiBMaxUse: 42.5078125,
            memMiB80p: 12.169628914166243,
            memMiB90p: 12.355468757916242,
            memMiB95p: 12.612304695416242,
            memMiB99p: 12.742187507916242,
            memMiB999p: 12.742187507916242,
            recommendCpuCoresRequest: 0.005,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 31.45728,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 30,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.005,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 30,
            specMemMiBLimits: 65,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "e2055b16",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0,
        wasteTimeFrameUsd: 0,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-27T09:03:27Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_controller_revision_hash: "5b46c4d578",
          label_k8s_app: "netd",
          label_pod_template_generation: "3",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.01894468597296,
        minParallelization: 2,
        maxParallelization: 2,
        avgParallelization: 2,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 20,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-event-exporter-gke",
        name: "event-exporter-gke",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "c63cd444",
        revisionHash: "746c49d5b8",
        calculatedRevisionId: "c63cd444",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00005461620005157565,
        wastePerHourAllReplicas: -0.000024938861282955784,
        hourlyCost: 0.0006053552890394108,
        timeframeCost: 0.007869618757512341,
        projectedTimeFrameCost: 0.10169968855862102,
        historicalTimeFrameWaste: 0.005790754060620741,
        estimatedHourlyCost: 0.0008817727542969911,
        estimatedTimeframeCost: 0.011463045805860885,
        savingPct: -45.66202199970941,
        containers: {
          "event-exporter": {
            name: "event-exporter",
            runningMinutes: 780,
            cpuCoresRequest: 0.003,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00030294414974537493,
            cpuCoresMaxUse: 0.005508813218602144,
            cpuCores80p: 0.00047055349592255104,
            cpuCores90p: 0.0005708084464592671,
            cpuCores95p: 0.0007242972590162727,
            cpuCores99p: 0.0009947318248593527,
            cpuCores999p: 0.0027199340106372626,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 104.8576,
            memMBLimits: 0,
            memMBAvgUse: 16.66736384103763,
            memMBMaxUse: 23.785472,
            memMB80p: 17.515384038213387,
            memMB90p: 17.849470226864955,
            memMB95p: 18.330770150224662,
            memMB99p: 19.57346202355339,
            memMB999p: 21.05344005859375,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.005508813218602144,
            aggregatedMaxMemMib: 23785472,
            aggregatedP95Cpu: 0.0007242972590162727,
            aggregatedP95MemMib: 17.4815846922156,
            aggregatedP99Cpu: 0.0009947318248593527,
            aggregatedP99MemMib: 18.666708015015974,
            aggregatedP100Cpu: 0.005508813253072731,
            aggregatedP100MemMib: 20.085937555879354,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 100,
            memMiBLimits: 0,
            memMiBAvgUse: 15.89523681739581,
            memMiBMaxUse: 22.68359375,
            memMiB80p: 16.703971899236095,
            memMiB90p: 17.02258131681915,
            memMiB95p: 17.4815846922156,
            memMiB99p: 18.666708015015974,
            memMiB999p: 20.078125055879354,
            recommendCpuCoresRequest: 0.003,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 96.12337840382133,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 15.473637950238082,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.003,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 100,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
          "prometheus-to-sd-exporter": {
            name: "prometheus-to-sd-exporter",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00011408324363961823,
            cpuCoresMaxUse: 0.0018687567651152006,
            cpuCores80p: 0.00037771555974228566,
            cpuCores90p: 0.000444110374366906,
            cpuCores95p: 0.0005126190183466527,
            cpuCores99p: 0.0007960957248675602,
            cpuCores999p: 0.0013406804676660317,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 12.024468209680858,
            memMBMaxUse: 13.729792,
            memMB80p: 12.21597665835265,
            memMB90p: 12.320477884186584,
            memMB95p: 12.42647495302669,
            memMB99p: 12.636725245257814,
            memMB999p: 12.738560002075195,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0018687567651152006,
            aggregatedMaxMemMib: 13729792,
            aggregatedP95Cpu: 0.0005126190183466527,
            aggregatedP95MemMib: 11.850810006167116,
            aggregatedP99Cpu: 0.0007960957248675602,
            aggregatedP99MemMib: 12.051320309884847,
            aggregatedP100Cpu: 0.0018687567920778747,
            aggregatedP100MemMib: 12.335937492083758,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 11.467426500016078,
            memMiBMaxUse: 13.09375,
            memMiB80p: 11.65006318888917,
            memMiB90p: 11.749723323999962,
            memMiB95p: 11.850810006167116,
            memMiB99p: 12.051320309884847,
            memMiB999p: 12.14843750197906,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "0ab00e62",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.00006159441051502897,
        wasteTimeFrameUsd: 0.0008007273366953766,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:44Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "event-exporter",
          label_pod_template_hash: "746c49d5b8",
          label_version: "v0.4.0",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.0035934270483485445,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.007869618757512341,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "default-deployment-polls",
        name: "polls",
        workloadType: "Deployment",
        namespace: "default",
        revisionId: "ab6ae197",
        revisionHash: "777458745c",
        calculatedRevisionId: "ab6ae197",
        runningMinutes: 2,
        minReplicas: 65535,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0,
        timeframeCost: 0,
        projectedTimeFrameCost: 0,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0,
        estimatedTimeframeCost: 0,
        savingPct: 0,
        containers: {
          "cloudsql-proxy": {
            name: "cloudsql-proxy",
            runningMinutes: 0,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 0,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0,
            memMBMaxUse: 0,
            memMB80p: 0,
            memMB90p: 0,
            memMB95p: 0,
            memMB99p: 0,
            memMB999p: 0,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0,
            aggregatedMaxMemMib: 0,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 0,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 0,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 0,
            aggregatedCpuCount: 0,
            aggregatedMemCount: 0,
            latestCpuCount: 0,
            latestMemCount: 0,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0,
            memMiBMaxUse: 0,
            memMiB80p: 0,
            memMiB90p: 0,
            memMiB95p: 0,
            memMiB99p: 0,
            memMiB999p: 0,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0,
            memGBAvgCostPerHour: 0,
            envHash: "3bc7461c",
          },
          "polls-app": {
            name: "polls-app",
            runningMinutes: 1,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 0,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0,
            memMBMaxUse: 0,
            memMB80p: 0,
            memMB90p: 0,
            memMB95p: 0,
            memMB99p: 0,
            memMB999p: 0,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0,
            aggregatedMaxMemMib: 0,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 0,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 0,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 0,
            aggregatedCpuCount: 0,
            aggregatedMemCount: 0,
            latestCpuCount: 0,
            latestMemCount: 0,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0,
            memMiBMaxUse: 0,
            memMiB80p: 0,
            memMiB90p: 0,
            memMiB95p: 0,
            memMiB99p: 0,
            memMiB999p: 0,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0,
            memGBAvgCostPerHour: 0,
            envHash: "a37e4ac8",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0,
        wasteTimeFrameUsd: 0,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-21T14:34:52Z",
        lastSeen: "2025-03-21T15:01:26.615Z",
        labels: {
          label_app: "polls",
          label_date: "1742567691",
          label_pod_template_hash: "777458745c",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "default",
          namespace: "default",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 1,
        restartsObserved: 18,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-21T15:01:26.615Z",
        totalCost: 0,
        minParallelization: 65535,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: false,
          minutesLeftToMature: 4288,
        },
      },
      {
        id: "cilium-test-1-deployment-client3",
        name: "client3",
        workloadType: "Deployment",
        namespace: "cilium-test-1",
        revisionId: "2c06edee",
        revisionHash: "75555c5f5",
        calculatedRevisionId: "2c06edee",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 8.611766401179916e-8,
        wastePerHourAllReplicas: -0.000034167914584530254,
        hourlyCost: 9.5451136e-7,
        timeframeCost: 0.00001240864768,
        projectedTimeFrameCost: 0.00016035790848000002,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.00037966500000000006,
        estimatedTimeframeCost: 0.004935645000000001,
        savingPct: -39675.84928900166,
        containers: {
          client3: {
            name: "client3",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 5e-324,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0.22528,
            memMBMaxUse: 3.162112,
            memMB80p: 0.22528,
            memMB90p: 0.22528,
            memMB95p: 0.22528000000000004,
            memMB99p: 0.22528,
            memMB999p: 0.22528,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 5e-324,
            aggregatedMaxMemMib: 3162112,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 0.21484374999999997,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 0.21484375,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 0.21484375,
            aggregatedCpuCount: 1559,
            aggregatedMemCount: 1559,
            latestCpuCount: 1559,
            latestMemCount: 1559,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0.21484375,
            memMiBMaxUse: 3.015625,
            memMiB80p: 0.21484375,
            memMiB90p: 0.21484375,
            memMiB95p: 0.21484375000000003,
            memMiB99p: 0.21484375,
            memMiB999p: 0.21484375,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0003787104886400001,
        wasteTimeFrameUsd: -0.0049232363523200005,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:36Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_kind: "client",
          label_name: "client3",
          label_other: "client-other-node",
          label_pod_template_hash: "75555c5f5",
        },
        namespaceLabels: {
          label_app_kubernetes_io_name: "cilium-cli",
          label_kubernetes_io_metadata_name: "cilium-test-1",
          namespace: "cilium-test-1",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.004923236352320001,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.00001240864768,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "gmp-system-daemonset-collector",
        name: "collector",
        workloadType: "DaemonSet",
        namespace: "gmp-system",
        revisionId: "124d51a6",
        revisionHash: "b487bcd59",
        calculatedRevisionId: "124d51a6",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0002518886234599234,
        wastePerHourAllReplicas: 0.0000812835033253146,
        hourlyCost: 0.0009346222059531664,
        timeframeCost: 0.03629449566451463,
        projectedTimeFrameCost: 0.46903655935680444,
        historicalTimeFrameWaste: 0.0009296387188908694,
        estimatedHourlyCost: 0.001890953814310815,
        estimatedTimeframeCost: 0.024582399586040592,
        savingPct: 32.269620679493364,
        containers: {
          "config-reloader": {
            name: "config-reloader",
            runningMinutes: 2330,
            cpuCoresRequest: 0.001,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00011995241490729154,
            cpuCoresMaxUse: 0.0005792252359267788,
            cpuCores80p: 0.00020397637152170853,
            cpuCores90p: 0.0002426941736143601,
            cpuCores95p: 0.0002785378088057584,
            cpuCores99p: 0.0003620748860519875,
            cpuCores999p: 0.000453232151186413,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 4,
            memMBLimits: 32,
            memMBAvgUse: 8.533474900944467,
            memMBMaxUse: 11.9808,
            memMB80p: 8.82683473421389,
            memMB90p: 9.031433122408135,
            memMB95p: 9.25728516420911,
            memMB99p: 9.890516323651791,
            memMB999p: 11.153408017089843,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0005792252359267788,
            aggregatedMaxMemMib: 11980800,
            aggregatedP95Cpu: 0.0002787669851188816,
            aggregatedP95MemMib: 8.828435100754843,
            aggregatedP99Cpu: 0.0003620748860519875,
            aggregatedP99MemMib: 9.432331393863478,
            aggregatedP100Cpu: 0.0005792252504761564,
            aggregatedP100MemMib: 10.664062544703484,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 3.814697265625,
            memMiBLimits: 30.517578125,
            memMiBAvgUse: 8.138155842728107,
            memMiBMaxUse: 11.42578125,
            memMiB80p: 8.417925581182374,
            memMiB90p: 8.613045809181342,
            memMiB95p: 8.828435100754843,
            memMiB99p: 9.432331393863478,
            memMiB999p: 10.636718766298145,
            recommendCpuCoresRequest: 0.001,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 4,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 3.814697265625,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.001,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 3.814697265625,
            specMemMiBLimits: 30.517578125,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "d23fe6b7",
          },
          prometheus: {
            name: "prometheus",
            runningMinutes: 2330,
            cpuCoresRequest: 0.004,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.008386305877869598,
            cpuCoresMaxUse: 0.021938586836894303,
            cpuCores80p: 0.010957927468081044,
            cpuCores90p: 0.01234908863240966,
            cpuCores95p: 0.013855433526473265,
            cpuCores99p: 0.016504215447420728,
            cpuCores999p: 0.020820505557510846,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 32,
            memMBLimits: 3000,
            memMBAvgUse: 102.01735958398497,
            memMBMaxUse: 142.114816,
            memMB80p: 108.1001098196286,
            memMB90p: 111.9608173364223,
            memMB95p: 115.1680695680151,
            memMB99p: 127.11093210195307,
            memMB999p: 133.9779309902904,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemRequest",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.021938586836894303,
            aggregatedMaxMemMib: 142114816,
            aggregatedP95Cpu: 0.013855433526473265,
            aggregatedP95MemMib: 109.83283001710424,
            aggregatedP99Cpu: 0.016504215447420728,
            aggregatedP99MemMib: 121.22243128009136,
            aggregatedP100Cpu: 0.02193858692771755,
            aggregatedP100MemMib: 130.83593827486038,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 30.517578125,
            memMiBLimits: 2861.02294921875,
            memMiBAvgUse: 97.29133566282746,
            memMiBMaxUse: 135.53125,
            memMiB80p: 103.09229833567485,
            memMiB90p: 106.77415593759756,
            memMiB95p: 109.83283001710424,
            memMiB99p: 121.22243128009136,
            memMiB999p: 127.77131175068892,
            recommendCpuCoresRequest: 0.004,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 108.1001098196286,
            recommendMemMBLimits: 3000,
            recommendMemMiBRequest: 101.14852336985001,
            recommendMemMiBLimits: 2861.02294921875,
            specCpuCoresRequest: 0.004,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 30.517578125,
            specMemMiBLimits: 2861.02294921875,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "5d7c63fc",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.0002704774037939674,
        wasteTimeFrameUsd: 0.010503539180665735,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:07Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app: "managed-prometheus-collector",
          label_app_kubernetes_io_name: "collector",
          label_app_kubernetes_io_version: "0.15.1",
          label_controller_revision_hash: "b487bcd59",
          label_pod_template_generation: "4",
        },
        namespaceLabels: {
          label_addonmanager_kubernetes_io_mode: "Reconcile",
          label_kubernetes_io_metadata_name: "gmp-system",
          namespace: "gmp-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.03629449566451463,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kro-deployment-kro",
        name: "kro",
        workloadType: "Deployment",
        namespace: "kro",
        revisionId: "babf280e",
        revisionHash: "6d5b8f8b8f",
        calculatedRevisionId: "babf280e",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0007814190912578121,
        wastePerHourAllReplicas: 0.00007454526940472132,
        hourlyCost: 0.008661096513536,
        timeframeCost: 0.11259425467596801,
        projectedTimeFrameCost: 1.455064214274048,
        historicalTimeFrameWaste: 0.11160950098395948,
        estimatedHourlyCost: 0.007834851314045708,
        estimatedTimeframeCost: 0.1018530670825942,
        savingPct: 9.539729735132209,
        containers: {
          kro: {
            name: "kro",
            runningMinutes: 780,
            cpuCoresRequest: 0.256,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.000792890729959489,
            cpuCoresMaxUse: 0.0012813717005625265,
            cpuCores80p: 0.000872043691692144,
            cpuCores90p: 0.0009244588293297422,
            cpuCores95p: 0.0009665747279345288,
            cpuCores99p: 0.0010656540831973003,
            cpuCores999p: 0.0012279679794012285,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 134.217728,
            memMBLimits: 1073.741824,
            memMBAvgUse: 10.930295220964771,
            memMBMaxUse: 24.14592,
            memMB80p: 10.97728,
            memMB90p: 10.981169682962964,
            memMB95p: 10.981376,
            memMB99p: 10.985074577297297,
            memMB999p: 11.239424,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 3,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0012813717005625265,
            aggregatedMaxMemMib: 24145920,
            aggregatedP95Cpu: 0.0009658039141122932,
            aggregatedP95MemMib: 10.47265625,
            aggregatedP99Cpu: 0.0010656540831973003,
            aggregatedP99MemMib: 10.476183488175675,
            aggregatedP100Cpu: 0.001281371696336464,
            aggregatedP100MemMib: 10.71875,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 128,
            memMiBLimits: 1024,
            memMiBAvgUse: 10.423941822972079,
            memMiBMaxUse: 23.02734375,
            memMiB80p: 10.46875,
            memMiB90p: 10.472459490740741,
            memMiB95p: 10.47265625,
            memMiB99p: 10.476183488175675,
            memMiB999p: 10.71875,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 122.74390230014362,
            recommendMemMBLimits: 970.2635358002873,
            recommendMemMiBRequest: 18.577071191250003,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.256,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 128,
            specMemMiBLimits: 1024,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "8445e0aa",
          },
        },
        maxIndicator: {
          name: "OverProvisionedCpuRequest",
          type: "waste",
          severity: 3,
        },
        indicatorState: "on",
        wastePerHour: 0.006276499382176292,
        wasteTimeFrameUsd: 0.0815944919682918,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:18Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_instance: "kro",
          label_app_kubernetes_io_name: "kro",
          label_pod_template_hash: "6d5b8f8b8f",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kro",
          label_name: "kro",
          namespace: "kro",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.11259425467596801,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "cilium-test-1-daemonset-host-netns",
        name: "host-netns",
        workloadType: "DaemonSet",
        namespace: "cilium-test-1",
        revisionId: "3b3ceb64",
        revisionHash: "54954548c",
        calculatedRevisionId: "3b3ceb64",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 2.666034256505755e-7,
        wastePerHourAllReplicas: -0.0001020563390603789,
        hourlyCost: 9.892208642597012e-7,
        timeframeCost: 0.00003841474356208507,
        projectedTimeFrameCost: 0.0004964366860330993,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.0011341275000000001,
        estimatedTimeframeCost: 0.014743657500000002,
        savingPct: -38280.205444223844,
        containers: {
          "host-netns": {
            name: "host-netns",
            runningMinutes: 2330,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 5e-324,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0.22937599996789182,
            memMBMaxUse: 3.055616,
            memMB80p: 0.23347199996405898,
            memMB90p: 0.23347200006129362,
            memMB95p: 0.23347200011700772,
            memMB99p: 0.23347200019950448,
            memMB999p: 0.23347200034882154,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 5e-324,
            aggregatedMaxMemMib: 3055616,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 0.22265625011158727,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 0.2226562502115263,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 0.22265625033266215,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0.21874999996937924,
            memMiBMaxUse: 2.9140625,
            memMiB80p: 0.22265624996572397,
            memMiB90p: 0.22265625005845416,
            memMiB95p: 0.22265625011158727,
            memMiB99p: 0.2226562501902623,
            memMiB999p: 0.22265625033266215,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.00037857943970728895,
        wasteTimeFrameUsd: -0.014701501575299722,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:51Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_controller_revision_hash: "54954548c",
          label_kind: "host-netns",
          label_name: "host-netns",
          label_other: "host-netns",
          label_pod_template_generation: "1",
        },
        namespaceLabels: {
          label_app_kubernetes_io_name: "cilium-cli",
          label_kubernetes_io_metadata_name: "cilium-test-1",
          namespace: "cilium-test-1",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.014705242756437918,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.00003841474356208507,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "perfectscale-deployment-perfectscale-kube-state-metrics",
        name: "perfectscale-kube-state-metrics",
        workloadType: "Deployment",
        namespace: "perfectscale",
        revisionId: "72595cc4",
        revisionHash: "6555c5db57",
        calculatedRevisionId: "72595cc4",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0006468536989428761,
        wastePerHourAllReplicas: 0.0000611368972899034,
        hourlyCost: 0.0071696,
        timeframeCost: 0.0932048,
        projectedTimeFrameCost: 1.2044928,
        historicalTimeFrameWaste: 0.09181175094509587,
        estimatedHourlyCost: 0.006491970576954218,
        estimatedTimeframeCost: 0.08439561750040482,
        savingPct: 9.451425784503783,
        containers: {
          "kube-state-metrics": {
            name: "kube-state-metrics",
            runningMinutes: 780,
            cpuCoresRequest: 0.2,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0007315672586345366,
            cpuCoresMaxUse: 0.0026178158458244117,
            cpuCores80p: 0.0008622952039323148,
            cpuCores90p: 0.0009360823910695899,
            cpuCores95p: 0.0010051487008711768,
            cpuCores99p: 0.0012262879130965612,
            cpuCores999p: 0.00219992461212155,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 200,
            memMBLimits: 0,
            memMBAvgUse: 18.17750314313119,
            memMBMaxUse: 19.628032,
            memMB80p: 18.219440533909417,
            memMB90p: 18.307084999742553,
            memMB95p: 18.411067053198238,
            memMB99p: 18.723993690103978,
            memMB999p: 18.742532906934652,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0026178158458244117,
            aggregatedMaxMemMib: 19628032,
            aggregatedP95Cpu: 0.0010051487008711768,
            aggregatedP95MemMib: 17.55635761927755,
            aggregatedP99Cpu: 0.0012262879130965612,
            aggregatedP99MemMib: 17.856950575723314,
            aggregatedP100Cpu: 0.002617815823214187,
            aggregatedP100MemMib: 18.06640625419095,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 190.73486328125,
            memMiBLimits: 0,
            memMiBAvgUse: 17.335417883998097,
            memMiBMaxUse: 18.71875,
            memMiB80p: 17.375412496480386,
            memMiB90p: 17.458996772520592,
            memMiB95p: 17.55816178626846,
            memMiB99p: 17.85659188280485,
            memMiB999p: 17.87427225774255,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 181.82194405339095,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 18.09690142632607,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.2,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 190.73486328125,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "OverProvisionedCpuRequest",
          type: "waste",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.005145150256441767,
        wasteTimeFrameUsd: 0.06688695333374298,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:18Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_component: "metrics",
          label_app_kubernetes_io_instance: "perfectscale",
          label_app_kubernetes_io_managed_by: "Helm",
          label_app_kubernetes_io_name: "kube-state-metrics",
          label_app_kubernetes_io_part_of: "kube-state-metrics",
          label_app_kubernetes_io_version: "2.14.0",
          label_helm_sh_chart: "kube-state-metrics-5.27.0",
          label_pod_template_hash: "6555c5db57",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "perfectscale",
          label_name: "perfectscale",
          namespace: "perfectscale",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.0932048,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-konnectivity-agent",
        name: "konnectivity-agent",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "32d43d98",
        revisionHash: "797998b794",
        calculatedRevisionId: "32d43d98",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.00019963410251113388,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.0007407339907200001,
        timeframeCost: 0.028765169972960004,
        projectedTimeFrameCost: 0.3717345042659447,
        historicalTimeFrameWaste: 0.02124579769764489,
        estimatedHourlyCost: 0.0022127053825353846,
        estimatedTimeframeCost: 0.028765169972960004,
        savingPct: 0,
        containers: {
          "konnectivity-agent": {
            name: "konnectivity-agent",
            runningMinutes: 2330,
            cpuCoresRequest: 0.01,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0009885162455384438,
            cpuCoresMaxUse: 0.003999742533470611,
            cpuCores80p: 0.0017203416278940946,
            cpuCores90p: 0.0019461885981604929,
            cpuCores95p: 0.0021404332317368547,
            cpuCores99p: 0.0027163614398091097,
            cpuCores999p: 0.003151575028572641,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 31.45728,
            memMBLimits: 131.072,
            memMBAvgUse: 13.857580894816014,
            memMBMaxUse: 16.846848,
            memMB80p: 14.63357865508385,
            memMB90p: 14.937074969020633,
            memMB95p: 15.07654944393373,
            memMB99p: 15.389728181632291,
            memMB999p: 15.77298993497276,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.003999742533470611,
            aggregatedMaxMemMib: 16846848,
            aggregatedP95Cpu: 0.0021404332317368547,
            aggregatedP95MemMib: 14.37811798470853,
            aggregatedP99Cpu: 0.0027163614398091097,
            aggregatedP99MemMib: 14.676788503296176,
            aggregatedP100Cpu: 0.003999742627456726,
            aggregatedP100MemMib: 15.074218879686669,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 30,
            memMiBLimits: 125,
            memMiBAvgUse: 13.215618986907971,
            memMiBMaxUse: 16.06640625,
            memMiB80p: 13.955668120464182,
            memMiB90p: 14.24510476018966,
            memMiB95p: 14.37811798470853,
            memMiB99p: 14.676788503296176,
            memMiB999p: 15.042295393917808,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 31.45728,
            recommendMemMBLimits: 131.072,
            recommendMemMiBRequest: 20.49427802565,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.01,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 30,
            specMemMiBLimits: 125,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "cbe34639",
          },
          "konnectivity-agent-metrics-collector": {
            name: "konnectivity-agent-metrics-collector",
            runningMinutes: 2330,
            cpuCoresRequest: 0.005,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.000173407166072295,
            cpuCoresMaxUse: 0.0010263193968470673,
            cpuCores80p: 0.0002835253369631259,
            cpuCores90p: 0.00036855192066003405,
            cpuCores95p: 0.0004330805740340501,
            cpuCores99p: 0.0005849582690370917,
            cpuCores999p: 0.0009081207807758889,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 31.45728,
            memMBLimits: 31.45728,
            memMBAvgUse: 13.345186488062966,
            memMBMaxUse: 14.589952,
            memMB80p: 13.457949267221872,
            memMB90p: 13.493569719422124,
            memMB95p: 13.500415996629693,
            memMB99p: 13.504511894216266,
            memMB999p: 13.58159324236776,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0010263193968470673,
            aggregatedMaxMemMib: 14589952,
            aggregatedP95Cpu: 0.0004330805740340501,
            aggregatedP95MemMib: 12.874999996785824,
            aggregatedP99Cpu: 0.0005849582690370917,
            aggregatedP99MemMib: 12.87890614911677,
            aggregatedP100Cpu: 0.0010263193890551747,
            aggregatedP100MemMib: 13.132812494101643,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 30,
            memMiBLimits: 30,
            memMiBAvgUse: 12.726961601317374,
            memMiBMaxUse: 13.9140625,
            memMiB80p: 12.834500567647812,
            memMiB90p: 12.868470878049969,
            memMiB95p: 12.874999996785824,
            memMiB99p: 12.87890614911677,
            memMiB999p: 12.952416651122817,
            recommendCpuCoresRequest: 0.005,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 31.45728,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.005,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 30,
            specMemMiBLimits: 30,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "4e08cede",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.00009210406800665335,
        wasteTimeFrameUsd: 0.0035767079742583716,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:35Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "konnectivity-agent",
          label_pod_template_hash: "797998b794",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.028765169972960004,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "default-\u003cnone\u003e-gcloud-cmd",
        name: "gcloud-cmd",
        workloadType: "\u003cnone\u003e",
        namespace: "default",
        revisionId: "f97caa29",
        revisionHash: "",
        calculatedRevisionId: "f97caa29",
        runningMinutes: 640,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.000001512083272500102,
        wastePerHourAllReplicas: -0.000378711112102919,
        hourlyCost: 0.000001509863424,
        timeframeCost: 0.000016105209855999998,
        projectedTimeFrameCost: 0.0002081288658313846,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.00031152,
        estimatedTimeframeCost: 0.00404976,
        savingPct: -25045.651849368864,
        containers: {
          "gcloud-cmd": {
            name: "gcloud-cmd",
            runningMinutes: 640,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 0.00036606039201836463,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0.00006670931755912078,
            cpuCores99p: 0.00015356757185038398,
            cpuCores999p: 0.0001731106790659181,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0.35635199999999995,
            memMBMaxUse: 2.777088,
            memMB80p: 0.356352,
            memMB90p: 0.356352,
            memMB95p: 0.35786072763819093,
            memMB99p: 0.35996347819095476,
            memMB999p: 0.36043659706532666,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.00036606039201836463,
            aggregatedMaxMemMib: 2777088,
            aggregatedP95Cpu: 0.00004457625995814457,
            aggregatedP95MemMib: 0.341282584798995,
            aggregatedP99Cpu: 0.00014942291010854513,
            aggregatedP99MemMib: 0.3432879239949749,
            aggregatedP100Cpu: 0.00036606039793696254,
            aggregatedP100MemMib: 2.1484375,
            aggregatedCpuCount: 1277,
            aggregatedMemCount: 1278,
            latestCpuCount: 1277,
            latestMemCount: 1278,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0.33984374999999994,
            memMiBMaxUse: 2.6484375,
            memMiB80p: 0.33984375,
            memMiB90p: 0.33984375,
            memMiB95p: 0.341282584798995,
            memMiB99p: 0.3432879239949749,
            memMiB999p: 0.34373912531407036,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.00037815513657600005,
        wasteTimeFrameUsd: -0.004033654790144001,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [640] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-21T11:20:53Z",
        lastSeen: "2025-03-21T21:59:56.626Z",
        labels: {
          label_run: "gcloud-cmd",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "default",
          namespace: "default",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 1,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.004033654790144001,
        LastSeen: "2025-03-21T21:59:56.626Z",
        totalCost: 0.000016105209855999998,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 640,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: false,
          minutesLeftToMature: 3680,
        },
      },
      {
        id: "kube-system-deployment-konnectivity-agent-autoscaler",
        name: "konnectivity-agent-autoscaler",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "b45e9988",
        revisionHash: "cc5bd5684",
        calculatedRevisionId: "b45e9988",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.000032342684947143806,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.00035848000000000005,
        timeframeCost: 0.00466024,
        projectedTimeFrameCost: 0.06022464000000001,
        historicalTimeFrameWaste: 0.004062678593735948,
        estimatedHourlyCost: 0.00035848000000000005,
        estimatedTimeframeCost: 0.00466024,
        savingPct: 0,
        containers: {
          autoscaler: {
            name: "autoscaler",
            runningMinutes: 780,
            cpuCoresRequest: 0.01,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00019974334549726432,
            cpuCoresMaxUse: 0.0004915614806408086,
            cpuCores80p: 0.0002726267359449585,
            cpuCores90p: 0.0003078275854887498,
            cpuCores95p: 0.00033705097884122637,
            cpuCores99p: 0.0003870739365063162,
            cpuCores999p: 0.00046310819711334317,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 10,
            memMBLimits: 0,
            memMBAvgUse: 8.407150270790881,
            memMBMaxUse: 9.674752,
            memMB80p: 8.492703243125817,
            memMB90p: 8.552165262078569,
            memMB95p: 8.598790171779452,
            memMB99p: 8.706977598482556,
            memMB999p: 8.886030335149291,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.0004915614806408086,
            aggregatedMaxMemMib: 9674752,
            aggregatedP95Cpu: 0.00033702279341861876,
            aggregatedP95MemMib: 8.20044533899255,
            aggregatedP99Cpu: 0.0003870739365063162,
            aggregatedP99MemMib: 8.303620909197384,
            aggregatedP100Cpu: 0.0004915614872516016,
            aggregatedP100MemMib: 8.582031249650754,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 9.5367431640625,
            memMiBLimits: 0,
            memMiBAvgUse: 8.017683287421113,
            memMiBMaxUse: 9.2265625,
            memMiB80p: 8.099272959829156,
            memMiB90p: 8.155980360106057,
            memMiB95p: 8.20044533899255,
            memMiB99p: 8.303620909197384,
            memMiB999p: 8.474378905438702,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 10,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 9.5367431640625,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.01,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 9.5367431640625,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "",
          type: "none",
          severity: 0,
        },
        indicatorState: "",
        wastePerHour: 0,
        wasteTimeFrameUsd: 0,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:43Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "konnectivity-agent-autoscaler",
          label_pod_template_hash: "cc5bd5684",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.00466024,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-container-watcher",
        name: "container-watcher",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "154f599e",
        revisionHash: "7cb5c44cf6",
        calculatedRevisionId: "154f599e",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0003246240487258391,
        wastePerHourAllReplicas: -0.00006855904955151909,
        hourlyCost: 0.0012045039603539869,
        timeframeCost: 0.04677490379374649,
        projectedTimeFrameCost: 0.6044756797961085,
        historicalTimeFrameWaste: 0.006111460729922852,
        estimatedHourlyCost: 0.004357964630976457,
        estimatedTimeframeCost: 0.05665354020269394,
        savingPct: -21.119522666486297,
        containers: {
          "container-watcher": {
            name: "container-watcher",
            runningMinutes: 2330,
            cpuCoresRequest: 0.025,
            cpuCoresLimits: 0.125,
            cpuCoresAvgUse: 0.016183238184532735,
            cpuCoresMaxUse: 0.1251491838645337,
            cpuCores80p: 0.018700409574868238,
            cpuCores90p: 0.020021458632855284,
            cpuCores95p: 0.02112983067573742,
            cpuCores99p: 0.02341391559419701,
            cpuCores999p: 0.028660532206913142,
            cpuThrottleAvgPct: 10.864628573563145,
            cpuThrottleMinutesAbove25: 1.1221833333333333,
            cpuThrottleMinutesAbove75: 0.6499666666666667,
            memMBRequest: 52.4288,
            memMBLimits: 367.0016,
            memMBAvgUse: 93.16367303428794,
            memMBMaxUse: 247.369728,
            memMB80p: 96.23603796141259,
            memMB90p: 97.76468264196052,
            memMB95p: 109.41865500342443,
            memMB99p: 117.9202902288118,
            memMB999p: 173.71909657167524,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuThrottling",
                type: "risk",
                severity: 2,
              },
              {
                name: "UnderProvisionedMemRequest",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.1251491838645337,
            aggregatedMaxMemMib: 247369728,
            aggregatedP95Cpu: 0.02112983067573742,
            aggregatedP95MemMib: 104.3497610124821,
            aggregatedP99Cpu: 0.02341391559419701,
            aggregatedP99MemMib: 112.4575521743887,
            aggregatedP100Cpu: 0.12514918281522114,
            aggregatedP100MemMib: 187.07812504470348,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 50,
            memMiBLimits: 350,
            memMiBAvgUse: 88.84780219486994,
            memMiBMaxUse: 235.91015625,
            memMiB80p: 91.77783771649608,
            memMiB90p: 93.23566688724567,
            memMiB95p: 104.3497610124821,
            memMiB99p: 112.4575521743887,
            memMiB999p: 165.6714406697037,
            recommendCpuCoresRequest: 0.019668594049862678,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 157.80374021233703,
            recommendMemMBLimits: 367.0016,
            recommendMemMiBRequest: 192.50000000000003,
            recommendMemMiBLimits: 385.00000000000006,
            specCpuCoresRequest: 0.025,
            specCpuCoresLimits: 0.125,
            specMemMiBRequest: 50,
            specMemMiBLimits: 350,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "c87f075d",
          },
        },
        maxIndicator: {
          name: "CpuThrottling",
          type: "risk",
          severity: 2,
        },
        indicatorState: "on",
        wastePerHour: -0.00017560141132986148,
        wasteTimeFrameUsd: -0.006819188139976288,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:11Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_container_watcher_unique_id: "632bdb98",
          label_controller_revision_hash: "7cb5c44cf6",
          label_k8s_app: "container-watcher",
          label_pod_template_generation: "1",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.009878636408947447,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.04677490379374649,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-gke-metrics-agent",
        name: "gke-metrics-agent",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "935cac54",
        revisionHash: "849545fd9c",
        calculatedRevisionId: "935cac54",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0002500617231242887,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.0009278435686400001,
        timeframeCost: 0.03603125858218667,
        projectedTimeFrameCost: 0.4656347262928739,
        historicalTimeFrameWaste: 0.02156872361052267,
        estimatedHourlyCost: 0.0027716352755528207,
        estimatedTimeframeCost: 0.03603125858218667,
        savingPct: 0,
        containers: {
          "core-metrics-exporter": {
            name: "core-metrics-exporter",
            runningMinutes: 2330,
            cpuCoresRequest: 0.006,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0013524500494086348,
            cpuCoresMaxUse: 0.0035710410849657637,
            cpuCores80p: 0.0017252572762656052,
            cpuCores90p: 0.0019014926357659074,
            cpuCores95p: 0.002047037214517521,
            cpuCores99p: 0.0024263983038753842,
            cpuCores999p: 0.0031750557912578006,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 52.4288,
            memMBLimits: 52.4288,
            memMBAvgUse: 23.814079061582227,
            memMBMaxUse: 27.42272,
            memMB80p: 24.854447725814484,
            memMB90p: 25.052218133346013,
            memMB95p: 25.280086957839067,
            memMB99p: 25.56354049956563,
            memMB999p: 26.446870547824826,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0035710410849657637,
            aggregatedMaxMemMib: 27422720,
            aggregatedP95Cpu: 0.002047037214517521,
            aggregatedP95MemMib: 24.10896964820773,
            aggregatedP99Cpu: 0.0024263983038753842,
            aggregatedP99MemMib: 24.37929201084674,
            aggregatedP100Cpu: 0.003571041086615878,
            aggregatedP100MemMib: 25.406249984167516,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 50,
            memMiBLimits: 50,
            memMiBAvgUse: 22.71087556989882,
            memMiBMaxUse: 26.15234375,
            memMiB80p: 23.703048444571003,
            memMiB90p: 23.89165700277902,
            memMiB95p: 24.10896964820773,
            memMiB99p: 24.37929201084674,
            memMiB999p: 25.221701190781427,
            recommendCpuCoresRequest: 0.006,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 52.4288,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 50,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.006,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 50,
            specMemMiBLimits: 50,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "1da6ff77",
          },
          "prometheus-metrics-collector": {
            name: "prometheus-metrics-collector",
            runningMinutes: 2330,
            cpuCoresRequest: 0.01,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.0005696201476642778,
            cpuCoresMaxUse: 0.005629151455791883,
            cpuCores80p: 0.0018593270224499863,
            cpuCores90p: 0.002201057939030685,
            cpuCores95p: 0.0026044750614756624,
            cpuCores99p: 0.0037861821279507653,
            cpuCores999p: 0.005000297231878903,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 47.18592,
            memMBLimits: 83.88608,
            memMBAvgUse: 29.087590611891912,
            memMBMaxUse: 40.026112,
            memMB80p: 31.162805402395136,
            memMB90p: 32.23833344603533,
            memMB95p: 33.24138200577967,
            memMB99p: 34.95320246559719,
            memMB999p: 38.17471995810938,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.005629151455791883,
            aggregatedMaxMemMib: 40026112,
            aggregatedP95Cpu: 0.0026044750614756624,
            aggregatedP95MemMib: 31.701452260760945,
            aggregatedP99Cpu: 0.0037861821279507653,
            aggregatedP99MemMib: 33.33397146758765,
            aggregatedP100Cpu: 0.005629151539409349,
            aggregatedP100MemMib: 37.17578133009374,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 45,
            memMiBLimits: 80,
            memMiBAvgUse: 27.740088092700873,
            memMiBMaxUse: 38.171875,
            memMiB80p: 29.719167139430176,
            memMiB90p: 30.74487061122449,
            memMiB95p: 31.701452260760945,
            memMiB99p: 33.33397146758765,
            memMiB999p: 36.40624996004999,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 47.18592,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 45,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.01,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 45,
            specMemMiBLimits: 80,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "b5c28e5d",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0,
        wasteTimeFrameUsd: 0,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:14Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_component: "gke-metrics-agent",
          label_controller_revision_hash: "849545fd9c",
          label_k8s_app: "gke-metrics-agent",
          label_pod_template_generation: "6",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.03603125858218667,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "cilium-test-1-deployment-echo-other-node",
        name: "echo-other-node",
        workloadType: "Deployment",
        namespace: "cilium-test-1",
        revisionId: "7efebd35",
        revisionHash: "86687ccf78",
        calculatedRevisionId: "7efebd35",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00006173969933967491,
        wastePerHourAllReplicas: -0.000027076026577613482,
        hourlyCost: 0.000684310763174323,
        timeframeCost: 0.0088960399212662,
        projectedTimeFrameCost: 0.11496420821328628,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.0009844161509430043,
        estimatedTimeframeCost: 0.012797409962259056,
        savingPct: -43.85513189600842,
        containers: {
          "dns-test-server": {
            name: "dns-test-server",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0007043017547926011,
            cpuCoresMaxUse: 0.002746596324029952,
            cpuCores80p: 0.0008484684380179416,
            cpuCores90p: 0.0009017680896048672,
            cpuCores95p: 0.0009460712132884129,
            cpuCores99p: 0.0010899852783596395,
            cpuCores999p: 0.0012717314905291927,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 11.792384,
            memMBMaxUse: 12.582912,
            memMB80p: 11.792384067783312,
            memMB90p: 11.792384333374024,
            memMB95p: 11.792384333394773,
            memMB99p: 11.796423290655277,
            memMB999p: 12.0545280345896,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.002746596324029952,
            aggregatedMaxMemMib: 12582912,
            aggregatedP95Cpu: 0.0009460712132884129,
            aggregatedP95MemMib: 11.246094067950033,
            aggregatedP99Cpu: 0.0010898863603898943,
            aggregatedP99MemMib: 11.249945917754436,
            aggregatedP100Cpu: 0.0027465963173654018,
            aggregatedP100MemMib: 11.49609406280797,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 11.24609375,
            memMiBMaxUse: 12,
            memMiB80p: 11.246093814643203,
            memMiB90p: 11.246094067930244,
            memMiB95p: 11.246094067950033,
            memMiB99p: 11.249945917754436,
            memMiB999p: 11.496093782987213,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
          "echo-other-node": {
            name: "echo-other-node",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.013342112910325546,
            cpuCoresMaxUse: 0.033088467225234106,
            cpuCores80p: 0.013647545474988042,
            cpuCores90p: 0.014191529126558276,
            cpuCores95p: 0.01465381822367755,
            cpuCores99p: 0.015396366954255298,
            cpuCores999p: 0.017354828575310434,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 36.41430000082073,
            memMBMaxUse: 39.043072,
            memMB80p: 36.837483709572695,
            memMB90p: 37.10932557320844,
            memMB95p: 37.3110706817906,
            memMB99p: 37.573751023342,
            memMB999p: 37.94038373144434,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.033088467225234106,
            aggregatedMaxMemMib: 39043072,
            aggregatedP95Cpu: 0.014652398729858464,
            aggregatedP95MemMib: 35.58260982684193,
            aggregatedP99Cpu: 0.015396366954255298,
            aggregatedP99MemMib: 35.83312132200432,
            aggregatedP100Cpu: 0.0330884681632142,
            aggregatedP100MemMib: 36.36328122857958,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 34.72738266069482,
            memMiBMaxUse: 37.234375,
            memMiB80p: 35.13096209485311,
            memMiB90p: 35.39021069832653,
            memMiB95p: 35.58260982684193,
            memMiB99p: 35.83312132200432,
            memMiB999p: 36.18276951927599,
            recommendCpuCoresRequest: 0.014127613575548996,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 36.837483709572695,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 35.2222816183553,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "4807c426",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0003003839675242533,
        wasteTimeFrameUsd: -0.003904991577815293,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:39Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_first: "echo",
          label_kind: "echo",
          label_name: "echo-other-node",
          label_pod_template_hash: "86687ccf78",
        },
        namespaceLabels: {
          label_app_kubernetes_io_name: "cilium-cli",
          label_kubernetes_io_metadata_name: "cilium-test-1",
          namespace: "cilium-test-1",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.003901370040992856,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.0088960399212662,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "atomic-hpa-deployment-php-apache",
        name: "php-apache",
        workloadType: "Deployment",
        namespace: "atomic-hpa",
        revisionId: "168b0c73",
        revisionHash: "d87b7ff46",
        calculatedRevisionId: "168b0c73",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0005740042225697675,
        wastePerHourAllReplicas: 0.000052058355432886345,
        hourlyCost: 0.006362150639104,
        timeframeCost: 0.08270795830835201,
        projectedTimeFrameCost: 1.068841307369472,
        historicalTimeFrameWaste: 0.08216648125969393,
        estimatedHourlyCost: 0.005785146,
        estimatedTimeframeCost: 0.075206898,
        savingPct: 9.069333183617644,
        containers: {
          "php-apache": {
            name: "php-apache",
            runningMinutes: 780,
            cpuCoresRequest: 0.2,
            cpuCoresLimits: 0.5,
            cpuCoresAvgUse: 0.000048208599748850473,
            cpuCoresMaxUse: 0.00007704647936180124,
            cpuCores80p: 0.00005212271864207228,
            cpuCores90p: 0.000053824351080498025,
            cpuCores95p: 0.000055664131421193936,
            cpuCores99p: 0.000059283373926235266,
            cpuCores999p: 0.00006627931421336093,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 9.428992,
            memMBMaxUse: 9.826304,
            memMB80p: 9.428992,
            memMB90p: 9.428992,
            memMB95p: 9.428992,
            memMB99p: 9.428992,
            memMB999p: 9.428992,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.00007704647936180124,
            aggregatedMaxMemMib: 9826304,
            aggregatedP95Cpu: 0.000055664131421193936,
            aggregatedP95MemMib: 8.9921875,
            aggregatedP99Cpu: 0.000059283373926235266,
            aggregatedP99MemMib: 8.9921875,
            aggregatedP100Cpu: 0.00007704647975970147,
            aggregatedP100MemMib: 8.9921875,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 8.9921875,
            memMiBMaxUse: 9.37109375,
            memMiB80p: 8.9921875,
            memMiB90p: 8.9921875,
            memMiB95p: 8.9921875,
            memMiB99p: 8.9921875,
            memMiB999p: 8.9921875,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.2,
            specCpuCoresLimits: 0.5,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "MemRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.004923185480565846,
        wasteTimeFrameUsd: 0.064001411247356,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:35Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_pod_template_hash: "d87b7ff46",
          label_run: "php-apache",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "atomic-hpa",
          namespace: "atomic-hpa",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: {
            cpu: {
              utilization: 80,
            },
          },
          HpaSpecMinReplicas: 1,
          HpaSpecMaxReplicas: 10,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 740.9872666666668,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: {
          cpu: {
            utilization: 80,
          },
        },
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.08270795830835201,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-cilium-envoy",
        name: "cilium-envoy",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "d88bbaee",
        revisionHash: "546954fcb9",
        calculatedRevisionId: "d88bbaee",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.00004743566635129102,
        wastePerHourAllReplicas: -0.000054887276134738446,
        hourlyCost: 0.000176008056724149,
        timeframeCost: 0.006834979536121119,
        projectedTimeFrameCost: 0.08832896631294986,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.0011341275000000001,
        estimatedTimeframeCost: 0.014743657500000002,
        savingPct: -115.70887552893964,
        containers: {
          "cilium-envoy": {
            name: "cilium-envoy",
            runningMinutes: 2330,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0028403824175641003,
            cpuCoresMaxUse: 0.004439692224460046,
            cpuCores80p: 0.0037723738996842887,
            cpuCores90p: 0.00387698650983547,
            cpuCores95p: 0.003944108492775081,
            cpuCores99p: 0.004076001683210151,
            cpuCores999p: 0.004235917721264117,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 12.602130601658937,
            memMBMaxUse: 13.410304,
            memMB80p: 12.611584000772584,
            memMB90p: 12.61568000041065,
            memMB95p: 12.619723976970658,
            memMB99p: 12.61977600005559,
            memMB999p: 12.61977600074945,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.004439692224460046,
            aggregatedMaxMemMib: 13410304,
            aggregatedP95Cpu: 0.003944108492775081,
            aggregatedP95MemMib: 12.035106636973055,
            aggregatedP99Cpu: 0.004076001683210151,
            aggregatedP99MemMib: 12.035156250053014,
            aggregatedP100Cpu: 0.004439692143932916,
            aggregatedP100MemMib: 12.039062499941792,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 12.01832828679937,
            memMiBMaxUse: 12.7890625,
            memMiB80p: 12.027343750736794,
            memMiB90p: 12.031250000391626,
            memMiB95p: 12.035106636973055,
            memMiB99p: 12.035156250053014,
            memMiB999p: 12.035156250714731,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "7fca62a1",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0002074843632435968,
        wasteTimeFrameUsd: -0.008057309439293009,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:12Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_name: "cilium-envoy",
          label_app_kubernetes_io_part_of: "cilium",
          label_controller_revision_hash: "546954fcb9",
          label_k8s_app: "cilium-envoy",
          label_name: "cilium-envoy",
          label_pod_template_generation: "1",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.007908677963878884,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.006834979536121119,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "default-\u003cnone\u003e-gcloud1",
        name: "gcloud1",
        workloadType: "\u003cnone\u003e",
        namespace: "default",
        revisionId: "f97caa29",
        revisionHash: "",
        calculatedRevisionId: "f97caa29",
        runningMinutes: 95,
        minReplicas: 65535,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0000012879019930553012,
        wastePerHourAllReplicas: -0.0003743796655552765,
        hourlyCost: 0.0000013016064,
        timeframeCost: 0.0000020608768,
        projectedTimeFrameCost: 0.000026632869415384614,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.000046241250000000004,
        estimatedTimeframeCost: 0.0006011362500000001,
        savingPct: -29068.95614526788,
        containers: {
          gcloud1: {
            name: "gcloud1",
            runningMinutes: 95,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 0.00006809500659768043,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0.000007458024178742512,
            cpuCores99p: 0.00005596760753265002,
            cpuCores999p: 0.00006688226378727918,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0.3072,
            memMBMaxUse: 2.53952,
            memMB80p: 0.3072,
            memMB90p: 0.3072,
            memMB95p: 0.9329170962962955,
            memMB99p: 2.0805738192592598,
            memMB999p: 2.3387965819259278,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.00006809500659768043,
            aggregatedMaxMemMib: 2539520,
            aggregatedP95Cpu: 0.000010214250505669077,
            aggregatedP95MemMib: 0.29296875,
            aggregatedP99Cpu: 0.000056518852798035344,
            aggregatedP99MemMib: 1.796074218750001,
            aggregatedP100Cpu: 0.00006809500337112695,
            aggregatedP100MemMib: 2.2578125,
            aggregatedCpuCount: 188,
            aggregatedMemCount: 189,
            latestCpuCount: 188,
            latestMemCount: 189,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0.29296875,
            memMiBMaxUse: 2.421875,
            memMiB80p: 0.29296875,
            memMiB90p: 0.29296875,
            memMiB95p: 0.8896990740740733,
            memMiB99p: 1.9841898148148154,
            memMiB999p: 2.230450231481483,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0003783633936000001,
        wasteTimeFrameUsd: -0.0005990753732000001,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [95] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-21T11:18:26Z",
        lastSeen: "2025-03-21T12:54:26.653Z",
        labels: {
          label_run: "gcloud1",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "default",
          namespace: "default",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 2,
        restartsObserved: 1,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.0005990753732000001,
        LastSeen: "2025-03-21T12:54:26.653Z",
        totalCost: 0.0000020608768,
        minParallelization: 65535,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 95,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: false,
          minutesLeftToMature: 4223,
        },
      },
      {
        id: "gmp-system-deployment-gmp-operator",
        name: "gmp-operator",
        workloadType: "Deployment",
        namespace: "gmp-system",
        revisionId: "fd587d29",
        revisionHash: "7f676dc4",
        calculatedRevisionId: "fd587d29",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00001767090819894483,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.00019586089347591902,
        timeframeCost: 0.002546191615186947,
        projectedTimeFrameCost: 0.0329046301039544,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.000099403,
        estimatedTimeframeCost: 0.001292239,
        savingPct: 49.248163716652535,
        containers: {
          operator: {
            name: "operator",
            runningMinutes: 780,
            cpuCoresRequest: 0.001,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.002779289533414271,
            cpuCoresMaxUse: 0.00387974242951949,
            cpuCores80p: 0.0029905625086555174,
            cpuCores90p: 0.0031226076843936445,
            cpuCores95p: 0.0032246430000256125,
            cpuCores99p: 0.0034580921527886596,
            cpuCores999p: 0.0036329215132383298,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 16,
            memMBLimits: 2000,
            memMBAvgUse: 22.270989332354688,
            memMBMaxUse: 24.65792,
            memMB80p: 22.752475101714296,
            memMB90p: 22.92946470723425,
            memMB95p: 23.00596425292132,
            memMB99p: 23.13748113860261,
            memMB999p: 23.594766320252933,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.00387974242951949,
            aggregatedMaxMemMib: 24657920,
            aggregatedP95Cpu: 0.0032246430000256125,
            aggregatedP95MemMib: 21.940197232171364,
            aggregatedP99Cpu: 0.0034580921527886596,
            aggregatedP99MemMib: 22.065621508219348,
            aggregatedP100Cpu: 0.0038797423358118976,
            aggregatedP100MemMib: 22.503906234982423,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 15.2587890625,
            memMiBLimits: 1907.3486328125,
            memMiBAvgUse: 21.239270527224242,
            memMiBMaxUse: 23.515625,
            memMiB80p: 21.698451139177603,
            memMiB90p: 21.867241580232857,
            memMiB95p: 21.940197232171364,
            memMiB99p: 22.065621508219348,
            memMiB999p: 22.501722641232426,
            recommendCpuCoresRequest: 0.001,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 16,
            recommendMemMBLimits: 2000,
            recommendMemMiBRequest: 15.2587890625,
            recommendMemMiBLimits: 1907.3486328125,
            specCpuCoresRequest: 0.001,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 15.2587890625,
            specMemMiBLimits: 1907.3486328125,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "",
          type: "none",
          severity: 0,
        },
        indicatorState: "",
        wastePerHour: 0,
        wasteTimeFrameUsd: 0,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:46Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app: "managed-prometheus-operator",
          label_app_kubernetes_io_component: "operator",
          label_app_kubernetes_io_name: "gmp-operator",
          label_app_kubernetes_io_part_of: "gmp",
          label_app_kubernetes_io_version: "0.15.1",
          label_pod_template_hash: "7f676dc4",
        },
        namespaceLabels: {
          label_addonmanager_kubernetes_io_mode: "Reconcile",
          label_kubernetes_io_metadata_name: "gmp-system",
          namespace: "gmp-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.002546191615186947,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "cilium-test-1-deployment-client2",
        name: "client2",
        workloadType: "Deployment",
        namespace: "cilium-test-1",
        revisionId: "74d84eda",
        revisionHash: "84576868b4",
        calculatedRevisionId: "74d84eda",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 8.611766401179916e-8,
        wastePerHourAllReplicas: -0.000034167914584530254,
        hourlyCost: 9.5451136e-7,
        timeframeCost: 0.00001240864768,
        projectedTimeFrameCost: 0.00016035790848000002,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.00037966500000000006,
        estimatedTimeframeCost: 0.004935645000000001,
        savingPct: -39675.84928900166,
        containers: {
          client2: {
            name: "client2",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 5e-324,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 0.22528,
            memMBMaxUse: 2.842624,
            memMB80p: 0.22528,
            memMB90p: 0.22528,
            memMB95p: 0.22528,
            memMB99p: 0.22528,
            memMB999p: 0.22527999999999998,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 5e-324,
            aggregatedMaxMemMib: 2842624,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 0.21484375,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 0.21484375,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 0.21484375,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 0.21484375,
            memMiBMaxUse: 2.7109375,
            memMiB80p: 0.21484375,
            memMiB90p: 0.21484375,
            memMiB95p: 0.21484375,
            memMiB99p: 0.21484375,
            memMiB999p: 0.21484374999999997,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0003787104886402789,
        wasteTimeFrameUsd: -0.004923236352323625,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:36Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_kind: "client",
          label_name: "client2",
          label_other: "client",
          label_pod_template_hash: "84576868b4",
        },
        namespaceLabels: {
          label_app_kubernetes_io_name: "cilium-cli",
          label_kubernetes_io_metadata_name: "cilium-test-1",
          namespace: "cilium-test-1",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.004923236352320001,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.00001240864768,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-clustermesh-apiserver",
        name: "clustermesh-apiserver",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "7e8f672e",
        revisionHash: "755557cbd8",
        calculatedRevisionId: "7e8f672e",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.000051064269035156584,
        wastePerHourAllReplicas: -0.00006155249247914408,
        hourlyCost: 0.0005659863797219934,
        timeframeCost: 0.007357822936385914,
        projectedTimeFrameCost: 0.09508571179329489,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.0012482221786355332,
        estimatedTimeframeCost: 0.01622688832226193,
        savingPct: -120.5392609003501,
        containers: {
          apiserver: {
            name: "apiserver",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.001367990118471115,
            cpuCoresMaxUse: 0.002934891231490988,
            cpuCores80p: 0.001587881660819372,
            cpuCores90p: 0.0017107123778115278,
            cpuCores95p: 0.0018311226527523198,
            cpuCores99p: 0.0022021528266683394,
            cpuCores999p: 0.0028318355434544216,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 21.06089202684871,
            memMBMaxUse: 21.89312,
            memMB80p: 21.08974774913152,
            memMB90p: 21.0944,
            memMB95p: 21.096479139198404,
            memMB99p: 21.311057334898614,
            memMB999p: 21.356544,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.002934891231490988,
            aggregatedMaxMemMib: 21893120,
            aggregatedP95Cpu: 0.0018311226527523198,
            aggregatedP95MemMib: 20.11917032165375,
            aggregatedP99Cpu: 0.0022021528266683394,
            aggregatedP99MemMib: 20.323808035753835,
            aggregatedP100Cpu: 0.0029348912808018213,
            aggregatedP100MemMib: 20.3671875,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 20.085231806610782,
            memMiBMaxUse: 20.87890625,
            memMiB80p: 20.11275076783325,
            memMiB90p: 20.1171875,
            memMiB95p: 20.11917032165375,
            memMiB99p: 20.323808035753835,
            memMiB999p: 20.3671875,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 21.08974774913152,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 20.06308544911375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "7a7d5bf8",
          },
          etcd: {
            name: "etcd",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.004877027989977448,
            cpuCoresMaxUse: 0.009512468507378285,
            cpuCores80p: 0.005557735666605282,
            cpuCores90p: 0.006086767144748201,
            cpuCores95p: 0.006481607057912996,
            cpuCores99p: 0.008723092811735115,
            cpuCores999p: 0.009221942256775948,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 22.669344665006776,
            memMBMaxUse: 50.696192,
            memMB80p: 22.94983205095247,
            memMB90p: 23.85255086988336,
            memMB95p: 23.91998565760255,
            memMB99p: 23.998878153006277,
            memMB999p: 24.821760000488283,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.009512468507378285,
            aggregatedMaxMemMib: 50696192,
            aggregatedP95Cpu: 0.006481607057912996,
            aggregatedP95MemMib: 22.811875970461415,
            aggregatedP99Cpu: 0.008723092811735115,
            aggregatedP99MemMib: 22.88711371708515,
            aggregatedP100Cpu: 0.009512468727734813,
            aggregatedP100MemMib: 23.67187500046566,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 21.619171776778007,
            memMiBMaxUse: 48.34765625,
            memMiB80p: 21.88666539283034,
            memMiB90p: 22.747565145381316,
            memMiB95p: 22.811875970461415,
            memMiB99p: 22.88711371708515,
            memMiB999p: 23.67187500046566,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 27.8829056,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 26.5912109375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "004e79ab",
          },
          kvstoremesh: {
            name: "kvstoremesh",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0007797249616434898,
            cpuCoresMaxUse: 0.0017704442907483717,
            cpuCores80p: 0.0010515199254870085,
            cpuCores90p: 0.001149767433776114,
            cpuCores95p: 0.001229956592622318,
            cpuCores99p: 0.001468269344435319,
            cpuCores999p: 0.001658689229748234,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 21.687635046425605,
            memMBMaxUse: 22.85568,
            memMB80p: 21.80671380582081,
            memMB90p: 21.882163169592936,
            memMB95p: 21.94302062528427,
            memMB99p: 22.022777514666664,
            memMB999p: 22.077440000699834,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0017704442907483717,
            aggregatedMaxMemMib: 22855680,
            aggregatedP95Cpu: 0.001229956592622318,
            aggregatedP95MemMib: 20.92649519470622,
            aggregatedP99Cpu: 0.001468269344435319,
            aggregatedP99MemMib: 21.002557291666665,
            aggregatedP100Cpu: 0.0017704442579997703,
            aggregatedP100MemMib: 21.12109375372529,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 20.682940527368167,
            memMiBMaxUse: 21.796875,
            memMiB80p: 20.796502881832893,
            memMiB90p: 20.868457002251564,
            memMiB95p: 20.92649519470622,
            memMiB99p: 21.002557291666665,
            memMiB999p: 21.054687500667413,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 21.80671380582081,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 20.692531862556493,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "ad76c81d",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0006981030628842849,
        wasteTimeFrameUsd: -0.009075339817495703,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:37Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_name: "clustermesh-apiserver",
          label_app_kubernetes_io_part_of: "cilium",
          label_k8s_app: "clustermesh-apiserver",
          label_pod_template_hash: "755557cbd8",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.008869065385876018,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.007357822936385914,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "default-deployment-simplepython",
        name: "simplepython",
        workloadType: "Deployment",
        namespace: "default",
        revisionId: "c8deeb8c",
        revisionHash: "c7445b47d",
        calculatedRevisionId: "c8deeb8c",
        runningMinutes: 8,
        minReplicas: 65535,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 9.058473497373317e-9,
        wastePerHourAllReplicas: -3.5588228078024323e-7,
        hourlyCost: 0.0000093906563072,
        timeframeCost: 0.0000012520875076266667,
        projectedTimeFrameCost: 0.000018492369343409232,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.0000034072500000000003,
        estimatedTimeframeCost: 0.00005062200000000001,
        savingPct: -3437.632132754028,
        containers: {
          "simple-python": {
            name: "simple-python",
            runningMinutes: 7,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0,
            cpuCoresMaxUse: 0,
            cpuCores80p: 0,
            cpuCores90p: 0,
            cpuCores95p: 0,
            cpuCores99p: 0,
            cpuCores999p: 0,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 2.424832,
            memMBMaxUse: 3.096576,
            memMB80p: 2.5059327999999996,
            memMB90p: 2.5329664,
            memMB95p: 2.5464832000000004,
            memMB99p: 2.55729664,
            memMB999p: 2.559729664,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemLimitNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0,
            aggregatedMaxMemMib: 3096576,
            aggregatedP95Cpu: 0,
            aggregatedP95MemMib: 2.428515625,
            aggregatedP99Cpu: 0,
            aggregatedP99MemMib: 2.438828125,
            aggregatedP100Cpu: 0,
            aggregatedP100MemMib: 2.44140625,
            aggregatedCpuCount: 0,
            aggregatedMemCount: 2,
            latestCpuCount: 0,
            latestMemCount: 2,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 2.3125,
            memMiBMaxUse: 2.953125,
            memMiB80p: 2.38984375,
            memMiB90p: 2.415625,
            memMiB95p: 2.428515625,
            memMiB99p: 2.438828125,
            memMiB999p: 2.4411484375,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.00036893282136320004,
        wasteTimeFrameUsd: -0.000049191042848426674,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [8] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-21T15:12:04Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app: "simplepython",
          label_pod_template_hash: "c7445b47d",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "default",
          namespace: "default",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 2,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.000049191042848426674,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.0000012520875076266667,
        minParallelization: 65535,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "jobset-system-deployment-jobset-controller-manager",
        name: "jobset-controller-manager",
        workloadType: "Deployment",
        namespace: "jobset-system",
        revisionId: "321b60d4",
        revisionHash: "694f54749",
        calculatedRevisionId: "321b60d4",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0014773068556598868,
        wastePerHourAllReplicas: 0.0001439283505770029,
        hourlyCost: 0.016374180513536,
        timeframeCost: 0.212864346675968,
        projectedTimeFrameCost: 2.750862326274048,
        historicalTimeFrameWaste: 0.21049349838105588,
        estimatedHourlyCost: 0.014778906800201313,
        estimatedTimeframeCost: 0.19212578840261704,
        savingPct: 9.74261711611112,
        containers: {
          manager: {
            name: "manager",
            runningMinutes: 780,
            cpuCoresRequest: 0.5,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0021258212816445826,
            cpuCoresMaxUse: 0.003754083977438897,
            cpuCores80p: 0.002317042029890276,
            cpuCores90p: 0.002436132830446999,
            cpuCores95p: 0.0025370682035896156,
            cpuCores99p: 0.0029178342232521596,
            cpuCores999p: 0.0032759545109929612,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 134.217728,
            memMBLimits: 4294.967296,
            memMBAvgUse: 24.844311953238563,
            memMBMaxUse: 27.295744,
            memMB80p: 24.86036822967161,
            memMB90p: 24.86767780486398,
            memMB95p: 25.088506259460416,
            memMB99p: 25.127119034085126,
            memMB999p: 25.384718336000002,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 3,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.003754083977438897,
            aggregatedMaxMemMib: 27295744,
            aggregatedP95Cpu: 0.0025370682035896156,
            aggregatedP95MemMib: 23.926342851918704,
            aggregatedP99Cpu: 0.0029178342232521596,
            aggregatedP99MemMib: 23.963088068089604,
            aggregatedP100Cpu: 0.0037540839102803147,
            aggregatedP100MemMib: 24.21484375,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 128,
            memMiBLimits: 4096,
            memMiBAvgUse: 23.69338221858841,
            memMiBMaxUse: 26.03125,
            memMiB80p: 23.70869467703973,
            memMiB90p: 23.715665631164534,
            memMiB95p: 23.926264056644836,
            memMiB99p: 23.963088068089604,
            memMiB999p: 24.208753906250003,
            recommendCpuCoresRequest: 0.451,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 123.28199202296716,
            recommendMemMBLimits: 3869.861674191872,
            recommendMemMiBRequest: 117.56853411712022,
            recommendMemMiBLimits: 3690.58768672168,
            specCpuCoresRequest: 0.5,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 128,
            specMemMiBLimits: 4096,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "OverProvisionedCpuRequest",
          type: "waste",
          severity: 3,
        },
        indicatorState: "on",
        wastePerHour: 0.0015952840888688224,
        wasteTimeFrameUsd: 0.020738693155294693,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 3,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:18Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_control_plane: "controller-manager",
          label_pod_template_hash: "694f54749",
        },
        namespaceLabels: {
          label_app_kubernetes_io_component: "manager",
          label_app_kubernetes_io_created_by: "jobset",
          label_app_kubernetes_io_instance: "system",
          label_app_kubernetes_io_managed_by: "kustomize",
          label_app_kubernetes_io_name: "namespace",
          label_app_kubernetes_io_part_of: "jobset",
          label_control_plane: "controller-manager",
          label_kubernetes_io_metadata_name: "jobset-system",
          namespace: "jobset-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.212864346675968,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-daemonset-cilium",
        name: "cilium",
        workloadType: "DaemonSet",
        namespace: "kube-system",
        revisionId: "a92cb029",
        revisionHash: "5fb88fbbf4",
        calculatedRevisionId: "a92cb029",
        runningMinutes: 2330,
        minReplicas: 2,
        maxReplicas: 3,
        avgReplicas: 3,
        pricePerHourAllReplicas: 0.0004910486942969668,
        wastePerHourAllReplicas: 7.778401688809698e-7,
        hourlyCost: 0.0018220156495764618,
        timeframeCost: 0.07075494105855261,
        projectedTimeFrameCost: 0.9143715459874491,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.005434066345297562,
        estimatedTimeframeCost: 0.0706428624888683,
        savingPct: 0.1584038768282848,
        containers: {
          "cilium-agent": {
            name: "cilium-agent",
            runningMinutes: 2330,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.021513225850300834,
            cpuCoresMaxUse: 0.06510434817522333,
            cpuCores80p: 0.034180708349982665,
            cpuCores90p: 0.035694317359970563,
            cpuCores95p: 0.03685058451057837,
            cpuCores99p: 0.040667947516247534,
            cpuCores999p: 0.05667440434027263,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 161.16888949139292,
            memMBMaxUse: 174.071808,
            memMB80p: 163.03904651524323,
            memMB90p: 163.7202226836045,
            memMB95p: 164.66936332276035,
            memMB99p: 166.30662437343776,
            memMB999p: 166.63328910010975,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 2,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.06510434817522333,
            aggregatedMaxMemMib: 174071808,
            aggregatedP95Cpu: 0.03685615550543382,
            aggregatedP95MemMib: 157.0409424998859,
            aggregatedP99Cpu: 0.040667947516247534,
            aggregatedP99MemMib: 158.60235631316925,
            aggregatedP100Cpu: 0.06510434712981805,
            aggregatedP100MemMib: 159.433594067581,
            aggregatedCpuCount: 4660,
            aggregatedMemCount: 4660,
            latestCpuCount: 4660,
            latestMemCount: 4660,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 153.70263051165858,
            memMiBMaxUse: 166.0078125,
            memMiB80p: 155.48615123295139,
            memMiB90p: 156.13577144966555,
            memMiB95p: 157.0409424998859,
            memMiB99p: 158.60235631316925,
            memMiB999p: 158.9138880730722,
            recommendCpuCoresRequest: 0.03388749743495299,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 163.03904651524323,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 154.95511374106005,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "1e892651",
          },
        },
        maxIndicator: {
          name: "MemRequestNotSet",
          type: "risk",
          severity: 2,
        },
        indicatorState: "on",
        wastePerHour: 0.000003054824614759236,
        wasteTimeFrameUsd: 0.000118629022539817,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [2330] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:57:08Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_name: "cilium-agent",
          label_app_kubernetes_io_part_of: "cilium",
          label_controller_revision_hash: "5fb88fbbf4",
          label_k8s_app: "cilium",
          label_pod_template_generation: "1",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.07075494105855261,
        minParallelization: 2,
        maxParallelization: 3,
        avgParallelization: 3,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 2330,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "cilium-test-1-deployment-echo-same-node",
        name: "echo-same-node",
        workloadType: "Deployment",
        namespace: "cilium-test-1",
        revisionId: "7324279d",
        revisionHash: "5c4dc4674d",
        calculatedRevisionId: "7324279d",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00006427872165286458,
        wastePerHourAllReplicas: -0.000026549331357179294,
        hourlyCost: 0.0007124527903535665,
        timeframeCost: 0.009261886274596363,
        projectedTimeFrameCost: 0.11969206877939917,
        historicalTimeFrameWaste: 0,
        estimatedHourlyCost: 0.0010067203912183524,
        estimatedTimeframeCost: 0.013087365085838581,
        savingPct: -41.30345264263065,
        containers: {
          "dns-test-server": {
            name: "dns-test-server",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0008479221981526745,
            cpuCoresMaxUse: 0.0012225146914788313,
            cpuCores80p: 0.000997097473174416,
            cpuCores90p: 0.0010493723335155197,
            cpuCores95p: 0.0010894623406211682,
            cpuCores99p: 0.0011585874459044026,
            cpuCores999p: 0.001221196765228825,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 11.941886370810755,
            memMBMaxUse: 12.845056,
            memMB80p: 11.952252541042391,
            memMB90p: 12.009364797395627,
            memMB95p: 12.013568,
            memMB99p: 12.016979571612904,
            memMB999p: 12.236787897848268,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0012225146914788313,
            aggregatedMaxMemMib: 12845056,
            aggregatedP95Cpu: 0.0010894623406211682,
            aggregatedP95MemMib: 11.457031249944091,
            aggregatedP99Cpu: 0.0011585874459044026,
            aggregatedP99MemMib: 11.460284778225807,
            aggregatedP100Cpu: 0.0012225146674609277,
            aggregatedP100MemMib: 11.70703125,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 11.38867032128406,
            memMiBMaxUse: 12.25,
            memMiB80p: 11.398556271593467,
            memMiB90p: 11.453022763629557,
            memMiB95p: 11.45703125,
            memMiB99p: 11.460284778225807,
            memMiB999p: 11.66991033348872,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 15,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
          "echo-same-node": {
            name: "echo-same-node",
            runningMinutes: 780,
            cpuCoresRequest: 0,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.013915068146004413,
            cpuCoresMaxUse: 0.017689472522630468,
            cpuCores80p: 0.0142349439152233,
            cpuCores90p: 0.01479234885216545,
            cpuCores95p: 0.01531621049239636,
            cpuCores99p: 0.016327592870150513,
            cpuCores999p: 0.0176477133623539,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 0,
            memMBLimits: 0,
            memMBAvgUse: 37.274398729278715,
            memMBMaxUse: 39.436288,
            memMB80p: 37.633809689060705,
            memMB90p: 37.95054597744632,
            memMB95p: 38.12477412453885,
            memMB99p: 38.27488411107693,
            memMB999p: 38.45179801217871,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuRequestNotSet",
                type: "risk",
                severity: 1,
              },
              {
                name: "MemRequestNotSet",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.017689472522630468,
            aggregatedMaxMemMib: 39436288,
            aggregatedP95Cpu: 0.01531621049239636,
            aggregatedP95MemMib: 36.35861790136228,
            aggregatedP99Cpu: 0.016327592870150513,
            aggregatedP99MemMib: 36.50177394015973,
            aggregatedP100Cpu: 0.017689472261736228,
            aggregatedP100MemMib: 36.78906251490116,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 0,
            memMiBLimits: 0,
            memMiBAvgUse: 35.54763672759887,
            memMiBMaxUse: 37.609375,
            memMiB80p: 35.890397728977874,
            memMiB90p: 36.19246099228508,
            memMiB95p: 36.35861790136228,
            memMiB99p: 36.50177394015973,
            memMiB999p: 36.670492183855735,
            recommendCpuCoresRequest: 0.01444076612089041,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 37.633809689060705,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 35.574559600136105,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 0,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "4807c426",
          },
        },
        maxIndicator: {
          name: "CpuRequestNotSet",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: -0.0002965753529403039,
        wasteTimeFrameUsd: -0.003855479588223951,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:40Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_kind: "echo",
          label_name: "echo-same-node",
          label_other: "echo",
          label_pod_template_hash: "5c4dc4674d",
        },
        namespaceLabels: {
          label_app_kubernetes_io_name: "cilium-cli",
          label_kubernetes_io_metadata_name: "cilium-test-1",
          namespace: "cilium-test-1",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0.0038254788112422177,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.009261886274596363,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-l7-default-backend",
        name: "l7-default-backend",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "6f8a666a",
        revisionHash: "56596b4968",
        calculatedRevisionId: "6f8a666a",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00003653676197599117,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.00040496633024000004,
        timeframeCost: 0.005264562293120001,
        projectedTimeFrameCost: 0.06803434348032,
        historicalTimeFrameWaste: 0.004986153246723471,
        estimatedHourlyCost: 0.00040496633024000004,
        estimatedTimeframeCost: 0.005264562293120001,
        savingPct: 0,
        containers: {
          "default-http-backend": {
            name: "default-http-backend",
            runningMinutes: 780,
            cpuCoresRequest: 0.01,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00007088928368231931,
            cpuCoresMaxUse: 0.00022005391467416508,
            cpuCores80p: 0.00010554555744561852,
            cpuCores90p: 0.00012441798064074947,
            cpuCores95p: 0.0001370077833771566,
            cpuCores99p: 0.0001717157861662429,
            cpuCores999p: 0.00019540856744912116,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 20.97152,
            memMBLimits: 0,
            memMBAvgUse: 4.057492099967873,
            memMBMaxUse: 6.713344,
            memMB80p: 4.102697974027688,
            memMB90p: 4.12629306254568,
            memMB95p: 4.143958428249921,
            memMB99p: 4.179810011502838,
            memMB999p: 4.269101054844485,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.00022005391467416508,
            aggregatedMaxMemMib: 6713344,
            aggregatedP95Cpu: 0.0001370077833771566,
            aggregatedP95MemMib: 3.951986721277162,
            aggregatedP99Cpu: 0.0001717157861662429,
            aggregatedP99MemMib: 3.9861774554279683,
            aggregatedP100Cpu: 0.00022005391684842834,
            aggregatedP100MemMib: 4.195312499534339,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 20,
            memMiBLimits: 0,
            memMiBAvgUse: 3.8695260047606213,
            memMiBMaxUse: 6.40234375,
            memMiB80p: 3.912637685802162,
            memMiB90p: 3.9351397157151036,
            memMiB95p: 3.951986721277162,
            memMiB99p: 3.9861774554279683,
            memMiB999p: 4.071332030148016,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 20.97152,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 14.30511474609375,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.01,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 20,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "",
          type: "none",
          severity: 0,
        },
        indicatorState: "",
        wastePerHour: 0.000025301330239999986,
        wasteTimeFrameUsd: 0.0003289172931199998,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:47Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "glbc",
          label_name: "glbc",
          label_pod_template_hash: "56596b4968",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.005264562293120001,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-metrics-server-v1.32.0",
        name: "metrics-server-v1.32.0",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "b17aa67b",
        revisionHash: "55977c986c",
        calculatedRevisionId: "b17aa67b",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.00014468208046771404,
        wastePerHourAllReplicas: 0,
        hourlyCost: 0.0016036279081599997,
        timeframeCost: 0.020847162806079997,
        projectedTimeFrameCost: 0.26940948857087993,
        historicalTimeFrameWaste: 0.01756579835298141,
        estimatedHourlyCost: 0.0016036279081599997,
        estimatedTimeframeCost: 0.020847162806079997,
        savingPct: 0,
        containers: {
          "metrics-server": {
            name: "metrics-server",
            runningMinutes: 780,
            cpuCoresRequest: 0.043,
            cpuCoresLimits: 0.043,
            cpuCoresAvgUse: 0.003925212108161623,
            cpuCoresMaxUse: 0.006392559665098647,
            cpuCores80p: 0.004287866740863477,
            cpuCores90p: 0.004536480819149993,
            cpuCores95p: 0.0047703745226625426,
            cpuCores99p: 0.0054333609618074504,
            cpuCores999p: 0.006163935027469968,
            cpuThrottleAvgPct: 9.700725597397286,
            cpuThrottleMinutesAbove25: 0.3935,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 57.67168,
            memMBLimits: 57.67168,
            memMBAvgUse: 25.285601925560133,
            memMBMaxUse: 27.242496,
            memMB80p: 25.596104856025786,
            memMB90p: 25.72809890587744,
            memMB95p: 25.75365786192767,
            memMB99p: 26.144030721912287,
            memMB999p: 26.25126400341797,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "CpuThrottling",
                type: "risk",
                severity: 1,
              },
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [
              {
                type: "memRequest",
                group: "ResourceInteraction",
                resource: "memRequest",
                name: "CPULimitRemovalRecommended",
                threshold: 57671680,
                unit: "MiB",
              },
            ],
            aggregatedMaxCpu: 0.006392559665098647,
            aggregatedMaxMemMib: 27242496,
            aggregatedP95Cpu: 0.0047703745226625426,
            aggregatedP95MemMib: 24.560602056434316,
            aggregatedP99Cpu: 0.0054333609618074504,
            aggregatedP99MemMib: 24.9328906268237,
            aggregatedP100Cpu: 0.006392559675077791,
            aggregatedP100MemMib: 25.039062525960617,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 55,
            memMiBLimits: 55,
            memMiBAvgUse: 24.114229131279117,
            memMiBMaxUse: 25.98046875,
            memMiB80p: 24.410347801233087,
            memMiB90p: 24.536227136495057,
            memMiB95p: 24.560602056434316,
            memMiB99p: 24.9328906268237,
            memMiB999p: 25.03515625325963,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 57.67168,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 24.15178413055837,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.043,
            specCpuCoresLimits: 0.043,
            specMemMiBRequest: 55,
            specMemMiBLimits: 55,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "CpuThrottling",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.0011802159628304955,
        wasteTimeFrameUsd: 0.01534280751679644,
        indicators: [],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-20T15:57:42Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "metrics-server",
          label_pod_template_hash: "55977c986c",
          label_version: "v1.32.0",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.020847162806079997,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "perfectscale-deployment-perfectscale-exporter",
        name: "perfectscale-exporter",
        workloadType: "Deployment",
        namespace: "perfectscale",
        revisionId: "10e81490",
        revisionHash: "fb669bf6d",
        calculatedRevisionId: "10e81490",
        runningMinutes: 780,
        minReplicas: 1,
        maxReplicas: 1,
        avgReplicas: 1,
        pricePerHourAllReplicas: 0.0006906513813616041,
        wastePerHourAllReplicas: 0.000058609386600529964,
        hourlyCost: 0.0076550449536,
        timeframeCost: 0.0995155843968,
        projectedTimeFrameCost: 1.2860475522048,
        historicalTimeFrameWaste: 0.08435935559897349,
        estimatedHourlyCost: 0.007005429965144522,
        estimatedTimeframeCost: 0.09107058954687879,
        savingPct: 8.48610285626054,
        containers: {
          exporter: {
            name: "exporter",
            runningMinutes: 780,
            cpuCoresRequest: 0.2,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.01737644997681479,
            cpuCoresMaxUse: 0.04653949769706335,
            cpuCores80p: 0.020923799955813227,
            cpuCores90p: 0.025585025477029386,
            cpuCores95p: 0.029869217549903487,
            cpuCores99p: 0.035634762144520056,
            cpuCores999p: 0.04263969707443553,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 314.5728,
            memMBLimits: 0,
            memMBAvgUse: 74.61558604901894,
            memMBMaxUse: 107.884544,
            memMB80p: 81.62577560786342,
            memMB90p: 84.28027220311209,
            memMB95p: 85.79939750850912,
            memMB99p: 88.42886140588448,
            memMB999p: 92.98772330725,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.04653949769706335,
            aggregatedMaxMemMib: 107884544,
            aggregatedP95Cpu: 0.029869217549903487,
            aggregatedP95MemMib: 81.82468176699554,
            aggregatedP99Cpu: 0.035634762144520056,
            aggregatedP99MemMib: 84.3323339518399,
            aggregatedP100Cpu: 0.04653949773637578,
            aggregatedP100MemMib: 93.94140586256981,
            aggregatedCpuCount: 1560,
            aggregatedMemCount: 1560,
            latestCpuCount: 1560,
            latestMemCount: 1560,
            memMiBRequest: 300,
            memMiBLimits: 0,
            memMiBAvgUse: 71.15896801854987,
            memMiBMaxUse: 102.88671875,
            memMiB80p: 77.8444057539591,
            memMiB90p: 80.3759309798356,
            memMiB95p: 81.82468176699554,
            memMiB99p: 84.3323339518399,
            memMiB999p: 88.68000345921516,
            recommendCpuCoresRequest: 0.18184988072129654,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 291.27809756078636,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 276.02058592165014,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0.2,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 300,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "06f4e742",
          },
        },
        maxIndicator: {
          name: "OverProvisionedCpuRequest",
          type: "waste",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.0005167716899440638,
        wasteTimeFrameUsd: 0.00671803196927283,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 1,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [780] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:48Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_app_kubernetes_io_instance: "perfectscale",
          label_app_kubernetes_io_name: "exporter",
          label_pod_template_hash: "fb669bf6d",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "perfectscale",
          label_name: "perfectscale",
          namespace: "perfectscale",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.0995155843968,
        minParallelization: 1,
        maxParallelization: 1,
        avgParallelization: 1,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 780,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
      {
        id: "kube-system-deployment-kube-dns",
        name: "kube-dns",
        workloadType: "Deployment",
        namespace: "kube-system",
        revisionId: "cd7ed469",
        revisionHash: "67c55f789",
        calculatedRevisionId: "cd7ed469",
        runningMinutes: 1560,
        minReplicas: 2,
        maxReplicas: 2,
        avgReplicas: 2,
        pricePerHourAllReplicas: 0.0016643394388852352,
        wastePerHourAllReplicas: 0.0001356568873453095,
        hourlyCost: 0.009223606559360002,
        timeframeCost: 0.23981377054336,
        projectedTimeFrameCost: 3.0991318039449602,
        historicalTimeFrameWaste: 0.23251508568449744,
        estimatedHourlyCost: 0.016943618687552002,
        estimatedTimeframeCost: 0.220267042938176,
        savingPct: 8.150794493950803,
        containers: {
          dnsmasq: {
            name: "dnsmasq",
            runningMinutes: 1560,
            cpuCoresRequest: 0.15,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0001314169887249192,
            cpuCoresMaxUse: 0.001176729414099977,
            cpuCores80p: 0.00016296983678239186,
            cpuCores90p: 0.0001951666558367407,
            cpuCores95p: 0.00021246024049434136,
            cpuCores99p: 0.00024557866957994573,
            cpuCores999p: 0.0003862837644981357,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 20.97152,
            memMBLimits: 0,
            memMBAvgUse: 5.853286375223771,
            memMBMaxUse: 6.88128,
            memMB80p: 5.8740842781209786,
            memMB90p: 5.883563209172826,
            memMB95p: 5.891559283045812,
            memMB99p: 5.9116855725616,
            memMB999p: 6.117311830192742,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.001176729414099977,
            aggregatedMaxMemMib: 6881280,
            aggregatedP95Cpu: 0.00021246024049434136,
            aggregatedP95MemMib: 5.618628771825611,
            aggregatedP99Cpu: 0.00024557866957994573,
            aggregatedP99MemMib: 5.637822697221375,
            aggregatedP100Cpu: 0.001176729414169131,
            aggregatedP100MemMib: 5.851562500273919,
            aggregatedCpuCount: 3120,
            aggregatedMemCount: 3120,
            latestCpuCount: 3120,
            latestMemCount: 3120,
            memMiBRequest: 20,
            memMiBLimits: 0,
            memMiBAvgUse: 5.5821288826215465,
            memMiBMaxUse: 6.5625,
            memMiB80p: 5.601963308449725,
            memMiB90p: 5.611003121540858,
            memMiB95p: 5.618628771825611,
            memMiB99p: 5.637822697221375,
            memMiB999p: 5.833923177902929,
            recommendCpuCoresRequest: 0.136,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 20.97152,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 20,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0.15,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 20,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
          kubedns: {
            name: "kubedns",
            runningMinutes: 1560,
            cpuCoresRequest: 0.1,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.00046895941974173347,
            cpuCoresMaxUse: 0.0010196883923649887,
            cpuCores80p: 0.0005667033789258529,
            cpuCores90p: 0.000611390313056799,
            cpuCores95p: 0.0006500861678598625,
            cpuCores99p: 0.0007194020754797371,
            cpuCores999p: 0.0008173867540245934,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 73.40032,
            memMBLimits: 220.20096,
            memMBAvgUse: 12.189118192145022,
            memMBMaxUse: 14.39744,
            memMB80p: 12.927505761068288,
            memMB90p: 13.02433466961905,
            memMB95p: 13.141514417140044,
            memMB99p: 13.352003823273295,
            memMB999p: 13.568032814875,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "OverProvisionedCpuRequest",
                type: "waste",
                severity: 1,
              },
              {
                name: "OverProvisionedMemRequest",
                type: "waste",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.0010196883923649887,
            aggregatedMaxMemMib: 14397440,
            aggregatedP95Cpu: 0.0006500861678598625,
            aggregatedP95MemMib: 12.53272477830891,
            aggregatedP99Cpu: 0.0007194020754797371,
            aggregatedP99MemMib: 12.733463118813797,
            aggregatedP100Cpu: 0.0010196884195465827,
            aggregatedP100MemMib: 13.039062477648258,
            aggregatedCpuCount: 3120,
            aggregatedMemCount: 3120,
            latestCpuCount: 3120,
            latestMemCount: 3120,
            memMiBRequest: 70,
            memMiBLimits: 210,
            memMiBAvgUse: 11.62444895948889,
            memMiBMaxUse: 13.73046875,
            memMiB80p: 12.328630219524658,
            memMiB90p: 12.42097346269517,
            memMiB95p: 12.53272477830891,
            memMiB99p: 12.733463118813797,
            memMiB999p: 12.939484419703483,
            recommendCpuCoresRequest: 0.091,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 67.560288,
            recommendMemMBLimits: 201.180864,
            recommendMemMiBRequest: 64.43051147460938,
            recommendMemMiBLimits: 191.86102294921875,
            specCpuCoresRequest: 0.1,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 70,
            specMemMiBLimits: 210,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "6980111e",
          },
          "kubedns-metrics-collector": {
            name: "kubedns-metrics-collector",
            runningMinutes: 1560,
            cpuCoresRequest: 0.01,
            cpuCoresLimits: 1,
            cpuCoresAvgUse: 0.00020390524581105548,
            cpuCoresMaxUse: 0.003701839660370393,
            cpuCores80p: 0.00035149181321162125,
            cpuCores90p: 0.00045999267142763885,
            cpuCores95p: 0.0005480609860713773,
            cpuCores99p: 0.0007862637969550958,
            cpuCores999p: 0.00145691687714968,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 47.18592,
            memMBLimits: 47.18592,
            memMBAvgUse: 16.504399165533005,
            memMBMaxUse: 17.637376,
            memMB80p: 16.569668615248407,
            memMB90p: 16.580476372397,
            memMB95p: 16.582121825981094,
            memMB99p: 16.592895997016026,
            memMB999p: 16.596991996582993,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [
              {
                name: "UnderProvisionedMemLimit",
                type: "risk",
                severity: 1,
              },
            ],
            restrictions: [],
            aggregatedMaxCpu: 0.003701839660370393,
            aggregatedMaxMemMib: 17637376,
            aggregatedP95Cpu: 0.0005480609860713773,
            aggregatedP95MemMib: 15.81394369695768,
            aggregatedP99Cpu: 0.0007862637969550958,
            aggregatedP99MemMib: 15.824218747205835,
            aggregatedP100Cpu: 0.003701839638523552,
            aggregatedP100MemMib: 15.828124996857703,
            aggregatedCpuCount: 3120,
            aggregatedMemCount: 3120,
            latestCpuCount: 3120,
            latestMemCount: 3120,
            memMiBRequest: 45,
            memMiBLimits: 45,
            memMiBAvgUse: 15.73982159188557,
            memMiBMaxUse: 16.8203125,
            memMiB80p: 15.80206738972512,
            memMiB90p: 15.81237447013569,
            memMiB95p: 15.81394369695768,
            memMiB99p: 15.82421874715426,
            memMiB999p: 15.828124996741288,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 1,
            recommendMemMBRequest: 47.18592,
            recommendMemMBLimits: 100,
            recommendMemMiBRequest: 45,
            recommendMemMiBLimits: 95.367431640625,
            specCpuCoresRequest: 0.01,
            specCpuCoresLimits: 1,
            specMemMiBRequest: 45,
            specMemMiBLimits: 45,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "1b82e1e2",
          },
          sidecar: {
            name: "sidecar",
            runningMinutes: 1560,
            cpuCoresRequest: 0.01,
            cpuCoresLimits: 0,
            cpuCoresAvgUse: 0.0008801470228400519,
            cpuCoresMaxUse: 0.00160229948746356,
            cpuCores80p: 0.0010104870320318245,
            cpuCores90p: 0.0010947083410966004,
            cpuCores95p: 0.0011556714208931429,
            cpuCores99p: 0.0013122880124345803,
            cpuCores999p: 0.0015257638916993973,
            cpuThrottleAvgPct: 0,
            cpuThrottleMinutesAbove25: 0,
            cpuThrottleMinutesAbove75: 0,
            memMBRequest: 20.97152,
            memMBLimits: 0,
            memMBAvgUse: 12.333900198819771,
            memMBMaxUse: 15.822848,
            memMB80p: 12.86074075432987,
            memMB90p: 13.149086774818452,
            memMB95p: 13.300987558474816,
            memMB99p: 13.716787389324203,
            memMB999p: 14.361616335171874,
            oomEvents: 0,
            latestOomChangeTS: "1970-01-01T00:00:00Z",
            indicators: [],
            restrictions: [],
            aggregatedMaxCpu: 0.00160229948746356,
            aggregatedMaxMemMib: 15822848,
            aggregatedP95Cpu: 0.0011556714208931429,
            aggregatedP95MemMib: 12.684810217356507,
            aggregatedP99Cpu: 0.0013122880124345803,
            aggregatedP99MemMib: 13.08134783680363,
            aggregatedP100Cpu: 0.0016022994686863967,
            aggregatedP100MemMib: 13.81249994598329,
            aggregatedCpuCount: 3120,
            aggregatedMemCount: 3120,
            latestCpuCount: 3120,
            latestMemCount: 3120,
            memMiBRequest: 20,
            memMiBLimits: 0,
            memMiBAvgUse: 11.762523840732356,
            memMiBMaxUse: 15.08984375,
            memMiB80p: 12.264958147363538,
            memMiB90p: 12.53994634134145,
            memMiB95p: 12.684810217356507,
            memMiB99p: 13.08134783680363,
            memMiB999p: 13.696304640933871,
            recommendCpuCoresRequest: 0.01,
            recommendCpuCoresLimits: 0,
            recommendMemMBRequest: 20.97152,
            recommendMemMBLimits: 0,
            recommendMemMiBRequest: 20,
            recommendMemMiBLimits: 0,
            specCpuCoresRequest: 0.01,
            specCpuCoresLimits: 0,
            specMemMiBRequest: 20,
            specMemMiBLimits: 0,
            cpuCoreAvgCostPerHour: 0.031611,
            memGBAvgCostPerHour: 0.004237,
            envHash: "3bc7461c",
          },
        },
        maxIndicator: {
          name: "UnderProvisionedMemLimit",
          type: "risk",
          severity: 1,
        },
        indicatorState: "on",
        wastePerHour: 0.0007517972155840005,
        wasteTimeFrameUsd: 0.019546727605184014,
        indicators: [
          {
            name: "OverProvisionedWorkload",
            type: "waste",
            severity: 3,
          },
        ],
        indicatorReasoning: [
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicatorAwareness: [0.000000] running at max replica pct, [0.000000] desired max replica min, [1560] running minutes",
          "HPAAtMaxReplicasObserved=0: HPAAtMaxReplicasObservedIndicator: [0.000000] running at max replica pct, [484209.262007] hours since last max replica",
        ],
        muteInfo: {
          isMuted: false,
          muteExpireDate: null,
          muteNote: null,
        },
        resilienceLevel: "",
        optimizationPolicy: "maxSavings",
        optimizationPolicyTimeWindow: "",
        jiraInfo: {
          ticket_id: "",
          ticket_url: "",
        },
        firstSeen: "2025-03-19T15:59:45Z",
        lastSeen: "2025-03-27T09:15:21.055Z",
        labels: {
          label_k8s_app: "kube-dns",
          label_pod_template_hash: "67c55f789",
        },
        namespaceLabels: {
          label_kubernetes_io_metadata_name: "kube-system",
          namespace: "kube-system",
        },
        annotations: {},
        hpaMetrics: {
          HpaSpecTargets: null,
          HpaSpecMinReplicas: 0,
          HpaSpecMaxReplicas: 0,
          HpaLatestSpecMaxReplicasChangeTS: "1970-01-01T00:00:00Z",
          HpaDesiredMinutesAtMin: 0,
          HpaDesiredMinutesAtMax: 0,
          HpaMinutesCurrentLessThanDesired: 0,
          HpaTriggered: 0,
        },
        hpaTargets: null,
        initsObserved: 0,
        restartsObserved: 0,
        latestRestartsObservedChangeTS: "1970-01-01T00:00:00Z",
        evictionsObserved: 0,
        latestEvictionChangeTS: "0001-01-01T00:00:00Z",
        costIncrease: 0,
        LastSeen: "2025-03-27T09:15:21.055Z",
        totalCost: 0.23981377054336,
        minParallelization: 2,
        maxParallelization: 2,
        avgParallelization: 2,
        _revisionAutomationTypeDebug: "none",
        revisionOrigin: "UserDeployed",
        workloadIsAutomatedBy: "none",
        workloadAutomationStatus: "none",
        workloadAutomationConfigExists: false,
        recommendationDate: "2025-03-28T09:15:43.221965289Z",
        hideRecommendations: false,
        node_selector: null,
        affinity: "",
        tolerations: null,
        runningMinutesByNodeGrp: {
          "pool4cpu-amd64-linux-n1-standard-4-us-central1-us-central1-c-false": 1560,
        },
        successfulMinutes: 780,
        maturityThreshold: {
          isMature: true,
          minutesLeftToMature: 0,
        },
      },
    ],
  },
  meta: {
    dataStatus: "partial",
    requestedMinutes: 10080,
    successfulMinutes: 780,
  },
};
