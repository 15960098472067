import { useCallback, useEffect, useState } from "react";

import {
  type Cluster,
  ClusterDiscoveryStatus,
  type ClusterWithCloudConnect,
  type EarlyAccessFeature,
  IntegrationModel,
  type KubernetesFeature,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useApiContext } from "../../api/context";
import { useCloudConnect } from "../../Components/hooks/useCloudConnect";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { getCachingKeys } from "../../utils/cachingKeys";
import { customerHasGKECluster } from "./api";

export interface UseClusterOptions {
  includeDeletedClusters?: boolean;
}

const defaultClusterOptions: UseClusterOptions = {
  includeDeletedClusters: false,
};

export function useCluster(id: Cluster["id"] | null | undefined) {
  const [clusters, loading] = useClusters();
  const cluster = clusters?.find((c) => c.id === id);
  return [cluster as Cluster | undefined, loading] as const;
}

export function useClusters(options: UseClusterOptions = defaultClusterOptions) {
  const { customer } = useCustomerContext({ allowNull: true });

  let query = getCollection(IntegrationModel)
    .doc("fsk8s")
    .collection("fsk8sClusters")
    .where("customerRef", "==", customer?.ref);

  if (!options.includeDeletedClusters) {
    query = query.where("discoveryStatus", "==", ClusterDiscoveryStatus.HEALTHY);
  }

  return useCollectionData(query, {
    caching: true,
    cachingKeys: getCachingKeys(customer?.id),
    idField: "id",
  });
}

export function useClustersWithCloudConnect(options: UseClusterOptions = defaultClusterOptions) {
  const { customer } = useCustomerContext({ allowNull: true });
  const [clusters, loading] = useClusters(options);
  const [cachedClusters, setCachedClusters] = useState<ClusterWithCloudConnect[]>([]);
  const [cloudConnectDocs, cloudConnectLoading] = useCloudConnect(customer);

  useEffect(() => {
    if (!clusters) return;

    const fetchCloudConnect = async () => {
      const updatedClusters = clusters.map((cluster: ClusterWithCloudConnect) => {
        if (!cluster?.cloudConnectRef?.path || cluster.cloudConnect) {
          return cluster;
        }

        const matchingCloudConnectDoc = cloudConnectDocs?.find((doc) => doc.id === cluster.cloudConnectRef.id);

        return {
          ...cluster,
          cloudConnect: matchingCloudConnectDoc,
        };
      });

      setCachedClusters(updatedClusters);
    };

    fetchCloudConnect();
  }, [clusters, cloudConnectDocs]);

  return [cachedClusters, loading || cloudConnectLoading] as const;
}

export function useKubernetesFeatures() {
  const { customer } = useCustomerContext({ allowNull: true });
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });

  const canCustomerAccessFeature = (feature: KubernetesFeature) => {
    if (isDoitEmployee || !customer || !Array.isArray(customer.earlyAccessFeatures) || !feature.featureFlag) {
      return true;
    }

    return customer.earlyAccessFeatures.includes(feature.featureFlag as EarlyAccessFeature);
  };

  const query = getCollection(IntegrationModel).doc("fsk8s").collection("fsk8sFeatures");

  const [features, loading] = useCollectionData(query, {
    idField: "id",
  });

  const availableFeatures = features?.filter(canCustomerAccessFeature);

  const featureMap: Record<KubernetesFeature["id"], KubernetesFeature> | undefined = availableFeatures?.reduce(
    (acc, feature) => {
      acc[feature?.id] = feature;
      return acc;
    },
    {}
  );

  return [availableFeatures, loading, featureMap] as const;
}

export function useGKEAndCloudConnect() {
  const [hasGKE, setHasGKE] = useState(false);
  const api = useApiContext();
  const { customer } = useCustomerContext();

  const fetchGKECluster = useCallback(async () => {
    const result = await customerHasGKECluster(api, customer.id);
    setHasGKE(Boolean(result.data));
  }, [api, customer.id]);

  useEffect(() => {
    fetchGKECluster();
  }, [fetchGKECluster]);

  const [cloudConnect] = useCloudConnect(customer);
  // Whether the customer has any healthy cloud connect doc, in which case we don't show the banner
  const hasCloudConnect =
    cloudConnect?.some((sa) => sa.cloudPlatform === "google-cloud" && sa.categoriesStatus?.["fsk8s-core"] === 1) ||
    false;

  // Whether the customer has any GCP organization where the fsk8s feature isn't enabled
  const unhealthyOrganization =
    cloudConnect?.find(
      (sa) =>
        sa.cloudPlatform === "google-cloud" && sa.categoriesStatus?.["fsk8s-core"] !== 1 && sa.scope === "organization"
    ) || null;

  return { hasGKE, hasCloudConnect, unhealthyOrganization };
}
