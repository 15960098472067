export const homeBullets = [
  {
    bold: "Visualize your progress:",
    text: "Track your spend against your committed goal in real-time.",
  },
  {
    bold: "Optimize your capacity planning:",
    text: "Identify areas for improvement to avoid overspending.",
  },
  {
    bold: "Customize your commitment:",
    text: "Tailor your plan to match your specific needs.",
  },
];

export const providers = [
  {
    name: "AWS",
    value: "amazon-web-services",
  },
  // {
  //   name: "Azure",
  //   value: "microsoft-azure",
  // },
  {
    name: "Google Cloud",
    value: "google-cloud",
  },
  // {
  //   name: "Snowflake",
  //   value: "snowflake",
  // },
];

export const stepLabels = ["General", "Commitment information", "Configuration"];

export const defaultMarketplaceLimitPercentage = "25";
