import { UserPermissions } from "@doitintl/cmp-models";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { useUserContext } from "../../Context/UserContext";
import CloudflowBlueprints from "./CloudflowBlueprints/CloudflowBlueprints";
import { CloudFlowEditor } from "./CloudflowBuilder/CloudflowEditor";
import CloudflowHistory from "./CloudflowHistory/CloudflowHistory";
import CloudflowRunDetails from "./CloudflowRunDetails/CloudflowRunDetails";
import Dashboard from "./Dashboard";
import LandingPage from "./LandingPage";

const Cloudflow = ({ pageId }) => {
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();

  const { loading: tiersLoading, isFeatureEntitled } = useTier();
  const isCloudFlowEntitled = isFeatureEntitled("governance:cloudflow", false);
  const isPresentationMode = isCustomerInPresentationMode(customer);

  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const hasCloudFlowPermission =
    userRoles.has(UserPermissions.CloudFlowManager) || userRoles.has(UserPermissions.CloudFlowViewer);

  const canSeeCloudflow = isPresentationMode || (isCloudFlowEntitled && (isDoitEmployee || hasCloudFlowPermission));

  if (tiersLoading) {
    return null;
  }

  if (canSeeCloudflow) {
    switch (pageId) {
      case "cloudflow:edit":
      case "blueprint:details":
        return <CloudFlowEditor />;
      case "cloudflow:history":
        return <CloudflowHistory />;
      case "cloudflow:blueprints":
        return <CloudflowBlueprints />;
      case "cloudflow:details":
        return <CloudflowRunDetails />;
      default:
        return <Dashboard pageId={pageId} />;
    }
  }

  return <LandingPage />;
};

export default Cloudflow;
