import { type ReactNode, useMemo } from "react";

import {
  type Member,
  ModelType,
  type OperationPointer,
  type StructureApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { Loader } from "../../../../Components/Loader";
import { useGetOperationById } from "../Common/hooks/useGetOperationById";
import { useUnwrappedApiActionModel } from "../Common/hooks/useUnwrappedApiActionModel";
import { ApiActionParametersForm, GenericForm } from "./ApiActionParametersForm";
import CloudSpecificParameterForm from "./CloudSpecificParameterForm";

export function ActionInputForm<TInput>({
  children,
  input,
  onInputChange,
  onInputValidityChange,
  operationPointer,
}: Readonly<{
  children?: ReactNode;
  input: TInput;
  onInputChange: (input: TInput) => void;
  onInputValidityChange: (isValid: boolean) => void;
  operationPointer: OperationPointer;
}>) {
  const {
    operationData: { operation },
    loading: isOperationLoading,
  } = useGetOperationById(operationPointer);
  const { model: operationInputModel, loading: isOperationModelLoading } = useUnwrappedApiActionModel(
    operationPointer,
    operation?.inputModel
  );
  const configurationInputModel = operation?.parameters;

  const inputModel = useMemo(
    (): StructureApiServiceModelDescriptor<Member> => ({
      type: ModelType.STRUCTURE,
      members: Object.fromEntries(
        (
          [
            ["configurationValues", { model: configurationInputModel }],
            ["formValues", { model: operationInputModel }],
          ] as const
        ).filter(([_, { model }]) => model)
      ),
    }),
    [operationInputModel, configurationInputModel]
  );

  return (
    <Loader loading={isOperationLoading || isOperationModelLoading}>
      <ApiActionParametersForm<TInput>
        inputModel={inputModel}
        values={input}
        onValidityChange={onInputValidityChange}
        onValuesChange={onInputChange}
      >
        {children}
        <Stack spacing={2}>
          {(configurationInputModel || operationInputModel) && (
            <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
              Parameters
            </Typography>
          )}

          {configurationInputModel && (
            <CloudSpecificParameterForm
              inputModel={configurationInputModel}
              provider={operationPointer.provider}
              rootPath="configurationValues"
            />
          )}
          {operationInputModel !== null && (
            <GenericForm inputModel={operationInputModel} fieldPath="formValues" rootPath="formValues" label="" />
          )}
        </Stack>
      </ApiActionParametersForm>
    </Loader>
  );
}
