import { Frequencies, type NodeConfigScheduleTriggerParameters } from "@doitintl/cmp-models";
import ScheduleIcon from "@mui/icons-material/Schedule";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Stack, type SystemStyleObject } from "@mui/system";
import { DateTime } from "luxon";

import { getTimeZoneSelectedKey } from "../../../../../utils/timeZone";

type Props = { sx?: SystemStyleObject } & {
  time: DateTime | null | undefined;
  startDate: DateTime | null | undefined;
} & Omit<NodeConfigScheduleTriggerParameters, "startDate" | "time">;

export function ScheduleInfo({ time, startDate, frequency, sx, ...rest }: Props) {
  if (!time?.isValid || !startDate?.isValid || !frequency) {
    return null;
  }

  return (
    <Box
      sx={(theme) => ({
        p: 1,
        borderRadius: 1,
        backgroundColor: theme.palette.action.hover,
        ...sx,
      })}
    >
      <Stack direction="row" spacing={1.5}>
        <ScheduleIcon sx={{ fontSize: 20 }} />
        <Typography variant="body2">{formatSchedule({ time, frequency, startDate, ...rest })}</Typography>
      </Stack>
    </Box>
  );
}

type formatScheduleInput = { time: DateTime<true>; startDate: DateTime<true> } & Omit<
  NodeConfigScheduleTriggerParameters,
  "startDate" | "time"
>;

function formatSchedule({
  time,
  startDate,
  frequency,
  timeZone,
  customFrequencyAmount,
  customFrequency,
}: formatScheduleInput) {
  const selectedTimeZoneKey = getTimeZoneSelectedKey(timeZone);
  const dateTime = time.setZone(selectedTimeZoneKey, { keepLocalTime: true });

  if (!dateTime.isValid) {
    return "Invalid time or timezone";
  }

  const formattedTime = dateTime.toLocal().toLocaleString(DateTime.TIME_24_SIMPLE);

  switch (frequency) {
    case Frequencies.Daily:
      return `Scheduled to send daily at ${formattedTime}`;
    case Frequencies.Weekly:
      return `Scheduled to send every 7 days at ${formattedTime}`;
    case Frequencies.Monthly:
      return `Scheduled to send every month on the ${startDate.day}${getDayOfMonthSuffix(startDate.day)} at ${formattedTime}`;
  }

  if (frequency === Frequencies.Custom && customFrequency && customFrequencyAmount) {
    const plural = customFrequencyAmount > 1 ? "s" : "";
    const customFreqDisplay = `${customFrequencyAmount} ${customFrequency.toLowerCase()}${plural}`;
    return `Scheduled to send every ${customFreqDisplay} at ${formattedTime}`;
  }

  return `Scheduled to send at ${formattedTime}`;
}

function getDayOfMonthSuffix(day: number) {
  switch (day) {
    case 1:
    case 21:
    case 31:
      return "st";
    case 2:
    case 22:
      return "nd";
    case 3:
    case 23:
      return "rd";
    default:
      return "th";
  }
}
