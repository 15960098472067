import { useMemo } from "react";

import { type CloudFlowNodeType, NODE_STATUS, type NodeParameters } from "@doitintl/cmp-models";
import { Stack } from "@mui/material";
import type { Node } from "@xyflow/react";

import { SideDrawer } from "../../../../Components/SideDrawer";
import { type HandleUpdateNodeFn, type NodeConfigs, type RFNode } from "../../types";
import { Header } from "./Header";
import { NodeConfigurationProvider } from "./NodeConfigurationContext";
import { useTabs } from "./Tabs/hooks";
import { TabContent } from "./Tabs/TabContent";
import { TabNavigation } from "./Tabs/TabNavigation";

type Props = {
  flowId: string;
  open: boolean;
  onClose: () => void;
  activeNode: Node<RFNode>;
  onUpdateNode: HandleUpdateNodeFn;
};

const NodeConfigurationPanel = ({ flowId, open, onClose, activeNode, onUpdateNode }: Props) => {
  const nodeConfig = useMemo(
    () =>
      ({
        flowId,
        id: activeNode.id,
        touched: activeNode.data.touched,
        name: activeNode.data.nodeData.name,
        parameters: activeNode.data.nodeData.parameters || ({} as unknown as NodeParameters),
        type: activeNode.type as CloudFlowNodeType,
        status: activeNode.data.nodeData.status || NODE_STATUS.VALIDATED,
        transitions: activeNode.data.nodeData.transitions,
        approval: activeNode.data.nodeData.approval,
        errors: activeNode.data.nodeData.errorMessages || {},
      }) satisfies NodeConfigs,
    [activeNode, flowId]
  );

  const tabs = useTabs(nodeConfig);

  return (
    <NodeConfigurationProvider nodeConfig={nodeConfig} updateNode={onUpdateNode}>
      <SideDrawer
        open={open}
        title=""
        onClose={onClose}
        variant="persistent"
        sideDrawerSx={{
          my: 6.6,
          borderRadius: 0,
          mx: 0,
          "& .MuiDivider-root": {
            marginBottom: 0,
          },
          height: (theme) => `calc(100vh - ${theme.spacing(7)})`,
        }}
        disableGutters={true}
        headerElement={<Header />}
        dataCy="nodeConfigurationPanel"
      >
        <Stack
          sx={{
            height: "100%",
          }}
        >
          {tabs.length > 1 && <TabNavigation tabs={tabs} />}
          <Stack sx={{ flexGrow: 1, overflowY: "auto" }}>
            <TabContent key={nodeConfig.id} />
          </Stack>
        </Stack>
      </SideDrawer>
    </NodeConfigurationProvider>
  );
};

export default NodeConfigurationPanel;
