import { useMemo } from "react";

import { type AttributionFilter, type MetadataCloud } from "@doitintl/cmp-models";
import { FormControl, MenuItem, Select, type SelectChangeEvent, Stack } from "@mui/material";

import { FilterDialogContainer } from "../../Components/Dialogs/CloudAnalytics/FilterDialogContainer";
import { type MetadataOption } from "../../types/Report";
import { AttributionChips } from "./AttributionChips";

type Props = {
  dimension: MetadataOption | null;
  value: AttributionFilter;
  onChange: (value: AttributionFilter) => void;
  cloudProviders?: MetadataCloud[];
  showInverseSelection?: boolean;
  disableRegexpFilter?: boolean;
};

export const AttributeSelector = ({
  dimension,
  value,
  onChange,
  cloudProviders,
  showInverseSelection = true,
  disableRegexpFilter = false,
}: Props) => {
  const syncedDimension = useMemo(() => {
    if (!dimension) {
      return null;
    }

    const synced = { ...dimension };
    if (disableRegexpFilter) {
      synced.data.disableRegexpFilter = disableRegexpFilter;
    }

    synced._filter = value.values ?? [];
    synced.data.nullFallback = null;

    if (value.inverse !== undefined && dimension._inverse !== value.inverse) {
      synced._inverse = value.inverse;
    }

    return synced;
  }, [dimension, value.inverse, disableRegexpFilter, value.values]);

  if (!syncedDimension) {
    return null;
  }

  const handleDropdownChange = (event: SelectChangeEvent<string>) => {
    onChange({ ...value, inverse: event.target.value === "false" });
  };

  return (
    <Stack spacing={3} direction="row">
      <FilterDialogContainer
        onSave={(filter) => {
          onChange(filter);
        }}
        dimension={syncedDimension}
        cloudProviders={cloudProviders}
        showExcludeSelection={false}
      />
      {showInverseSelection && (
        <FormControl variant="outlined" size="small" sx={{ width: 100, minWidth: 100 }}>
          <Select value={value?.inverse ? "false" : "true"} onChange={handleDropdownChange}>
            <MenuItem value="true">is</MenuItem>
            <MenuItem value="false">is not</MenuItem>
          </Select>
        </FormControl>
      )}
      <AttributionChips filter={value} />
    </Stack>
  );
};
