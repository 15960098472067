import { useState } from "react";

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, TextField } from "@mui/material";

import { useModalManager } from "../CloudflowBuilder/Common/CloudflowModalsProvider";
import { type DialogType } from "../types";

type Props = {
  title: string;
  onConfirm: (name: string) => void;
  header: string;
  dialogType: DialogType;
};

export const EditTitleDialog = ({ title, onConfirm, header, dialogType }: Props) => {
  const { closeModal, isModalVisible } = useModalManager();
  const [editedTitle, setEditedTitle] = useState(title);

  const handleSave = () => {
    const trimmedTitle = editedTitle.trim();
    if (trimmedTitle && trimmedTitle !== title) {
      onConfirm(trimmedTitle);
    }
    closeModal(dialogType);
  };

  const handleCancel = () => {
    closeModal(dialogType);
  };

  return (
    <Dialog open={isModalVisible(dialogType)} onClose={handleCancel} maxWidth="sm" fullWidth>
      <DialogTitle>{header}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="name"
          label="Name"
          required
          fullWidth
          value={editedTitle}
          onChange={(e) => {
            setEditedTitle(e.target.value);
          }}
        />
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" disabled={!editedTitle.trim() || editedTitle.trim() === title}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};
