import { type FC } from "react";

import { Avatar, Card, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";

import { cmpBaseColors } from "../../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { type CloudflowBlueprint } from "../../types";
import { useApiProviderLogo } from "../Common/utils";

export const BlueprintSearchItem: FC<{
  item: CloudflowBlueprint;
  onClick: (t: CloudflowBlueprint) => void;
  disabled: boolean;
}> = ({ item, onClick, disabled }) => {
  const getLogo = useApiProviderLogo();
  const isDarkMode = useDarkThemeCheck();
  const chipBackgroundColor = isDarkMode ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight;

  return (
    <Card>
      <ListItemButton
        disabled={disabled}
        onClick={() => {
          onClick(item);
        }}
      >
        <Stack>
          <ListItemText
            primary={
              <Typography variant="subtitle1" fontWeight={500} pb={0.5}>
                {item.name}
              </Typography>
            }
            secondary={item.description}
          />
          <Stack direction="row" spacing={1}>
            {item.tags?.cloud?.map((cloud) => (
              <Avatar
                key={cloud}
                alt={cloud}
                src={getLogo(cloud)}
                sx={{
                  backgroundColor: chipBackgroundColor,
                  width: 26,
                  height: 26,
                  padding: 0.5,
                }}
              />
            ))}
          </Stack>
        </Stack>
      </ListItemButton>
    </Card>
  );
};
