import { useCallback } from "react";

import { useAvaContext } from "../../Ava2/AvaContext";
import { useAvaDialogContext } from "../../Ava2/AvaDialogContextProvider";
import { AvaResizableChat } from "../../Ava2/AvaResizableChat";

export const AVADialog = () => {
  const { handleCloseAvaDialog } = useAvaDialogContext();
  const { setConversationId } = useAvaContext();

  const closeDialog = useCallback(() => {
    handleCloseAvaDialog();
    setConversationId();
  }, [handleCloseAvaDialog, setConversationId]);

  return <AvaResizableChat closeDialog={closeDialog} />;
};
