import { AccessLevel, type CloudConnectGoogleCloud, type Cluster, type ClusterType } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import ClusterBulkSelector from "./components/ClusterBulkSelector";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

interface SelectClustersStepProps {
  projectId: string | null;
  selectedClusters: Cluster[] | null;
  onSelect: (clusters: Cluster["id"][]) => void;
  clusterType?: ClusterType;
  filterAvailableClusters?: (cluster: Cluster) => boolean;
  gcpScope?: CloudConnectGoogleCloud["scope"];
}

const SelectClustersStep = ({
  projectId,
  selectedClusters,
  clusterType,
  onSelect,
  filterAvailableClusters,
  gcpScope = AccessLevel.ORGANIZATION,
}: SelectClustersStepProps) => {
  const selectorValue = selectedClusters?.map((c) => c.id) || [];

  const handleClusterSelect = (clusterIds: Cluster["id"][]) => {
    onSelect(clusterIds);
  };

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Select clusters you want to enable</Typography>
        <SelectedAccountAndCluster
          projectId={projectId}
          clusterType={clusterType}
          filterAvailableClusters={filterAvailableClusters}
          gcpScope={gcpScope}
          showClusterInfo={false}
        />
      </Stack>

      <ClusterBulkSelector
        projectId={projectId}
        onSelect={handleClusterSelect}
        value={selectorValue}
        filterAvailableClusters={filterAvailableClusters}
      />
    </Stack>
  );
};

export default SelectClustersStep;
