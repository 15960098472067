import orderBy from "lodash/orderBy";

import { formatDecimalNumber } from "../../../utils/common";
import { ALL_CLOUD_PROVIDERS, CLOUD_PROVIDERS_TITLES_MAP } from "../constants";
import { type NoAccessInsight } from "../types";
import { type CloudProviderDisplayInsights, type DisplayInsight, type InsightsByCloudProvider } from "./types";

export const convertInsights = (
  rawInsight: NoAccessInsight,
  supportCategoryToServiceMapping: Record<string, string>,
  currency: string
): DisplayInsight => {
  const dailySavings = rawInsight.potentialDailySavings?.value || 0;
  const yearlySavings = dailySavings * 365;
  const potentialSavings = `${currency}${formatDecimalNumber(yearlySavings, 0)}/year`;

  return {
    title: rawInsight.title,
    service: supportCategoryToServiceMapping[rawInsight?.supportCategory] || "-",
    easyWinDescription: (rawInsight.potentialDailySavings?.value || 0) !== 0 ? rawInsight.easyWinDescription : "",
    potentialSavings,
    potentialSavingsValue: yearlySavings,
    noSavingsAvailable: Math.floor(dailySavings) === 0,
  };
};

export const partitionByCloudTags = (
  data,
  supportCategoryToServiceMapping: Record<string, string>,
  currency: string
): InsightsByCloudProvider => {
  const result = {};

  data.forEach((item) => {
    item.cloudTags.forEach((tag) => {
      if (!result[tag]) {
        result[tag] = [];
      }
      result[tag].push(convertInsights(item, supportCategoryToServiceMapping, currency));
    });
    item.cloudTags.forEach((tag) => {
      result[tag] = orderBy(result[tag], "potentialSavingsValue", "desc");
    });
  });

  return result;
};

export const getCloudProvidersOrderedByPotentialSavings = (
  insightsByCloudProvider: InsightsByCloudProvider
): CloudProviderDisplayInsights[] => {
  const availableProviders = ALL_CLOUD_PROVIDERS.filter((provider) => insightsByCloudProvider[provider]?.length > 0);
  const orderedProviders = orderBy(
    availableProviders,
    (provider) => insightsByCloudProvider[provider].reduce((acc, insight) => acc + insight.potentialSavingsValue, 0),
    "desc"
  );

  return orderedProviders.map((providerTag) => ({
    cloudProviderTag: providerTag,
    cloudProviderName: CLOUD_PROVIDERS_TITLES_MAP[providerTag],
    insights: insightsByCloudProvider[providerTag],
  }));
};

export const calculateROI = (netProfit, investmentCost) => ((netProfit - investmentCost) / investmentCost) * 100;
