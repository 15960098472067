import { useCallback, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { Link, Typography } from "@mui/material";

import { useApiContext } from "../../api/context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useConcedefy } from "../../Pages/MPA/hooks/useConcedefy";
import useCreateTicket, { type ticketFields } from "../../Pages/MPA/hooks/useCreateTicket";
import { consoleErrorWithSentry } from "../../utils";
import useSegmentTrackEvent from "../../utils/useSegmentTrackEvent";
import { useSnackbar, useSuccessSnackbar } from "../SharedSnackbar/SharedSnackbar.context";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useTrpcContext } from "./AvaTRPCContext";

export const useCreateCaseIQTicket = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [concedefyEmail, setConcedefyEmail] = useState("");

  const { createTicket } = useCreateTicket();
  const showSuccessSnackbar = useSuccessSnackbar();
  const snackbar = useSnackbar();
  const { customer } = useCustomerContext({ allowNull: true });
  const { caseIQState } = useAvaCaseIQContext();
  const api = useApiContext();
  const { summarizeDescription } = useTrpcContext();
  const { getUserDetails } = useConcedefy();
  const { trackEvent } = useSegmentTrackEvent();

  const { content, platform, cloudProduct, asset, urgency } = caseIQState;

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    try {
      const { title } = await summarizeDescription(content);

      const ticketDetails = {
        platformInfoField: asset,
        platformField: platform as ticketFields["platformField"],
        productField: cloudProduct?.name,
        priority: urgency as ticketFields["priority"],
        subject: title,
        tags: [...(cloudProduct?.tags ?? []), "ava-case-iq"],
        description: content,
      };

      trackEvent("Case IQ ticket Submitted", {
        source: "Ava",
        feature: "support ticket",
        ...ticketDetails,
      });

      const response = await createTicket(customer?.id ?? "", ticketDetails);

      const userDetails = await getUserDetails(customer?.id ?? "");
      if (response.data.id && !userDetails.data.organization.organization_fields.concedefy_disabled) {
        const projectId = new FormData();
        projectId.append("platformInfoField", asset);
        try {
          const concedefyRes = await api.post(
            `/support/customers/${customer?.id}/concedefy/${response.data.id}`,
            projectId,
            { headers: { "Content-Type": "multipart/form-data" } }
          );

          if (concedefyRes.data?.email) {
            setConcedefyEmail(concedefyRes.data.email);
          }
        } catch (error) {
          consoleErrorWithSentry(error);
        }
      }

      setSubmitted(true);
      showSuccessSnackbar(
        <Typography variant="body1">
          Your support message has been sent. View your ticket{" "}
          <Link
            color="inherit"
            component={RouterLink}
            to={`/customers/${customer?.id}/support/tickets/${response.data.id}`}
            underline="always"
            variant="body1"
          >
            here
          </Link>
        </Typography>
      );
    } catch (e) {
      snackbar.onOpen({
        message: "Unable to create request ticket",
        variant: "error",
        autoHideDuration: 30000,
        withClose: true,
      });
    }
    setSubmitting(false);
  }, [
    createTicket,
    customer?.id,
    snackbar,
    content,
    platform,
    cloudProduct,
    asset,
    urgency,
    api,
    getUserDetails,
    showSuccessSnackbar,
    summarizeDescription,
    trackEvent,
  ]);

  return { isSubmitting, onSubmit, submitted, concedefyEmail };
};
