import { useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { CloudAnalyticsModel, type Collaborators, type PublicAccess } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";

import { useApiContext } from "../../../api/context";
import { analyticsAlertText } from "../../../assets/texts";
import { AlertsTxt } from "../../../assets/texts/CloudAnalytics";
import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { useCloudAnalyticsAdminCheck } from "../../../Components/hooks/useCloudAnalyticsAdminCheck";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { consoleErrorWithSentry } from "../../../utils";
import { CSPCustomerID } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import Invites from "../../IAM/InviteUserDialog/handleInvite";
import { type AnalyticsResourcesAlerts } from "../analyticsResources/types";
import { NoReplyEmail } from "../budgets/shared";
import { useCloudAnalyticsContext } from "../CloudAnalyticsContext";
import {
  alertMenuOptions,
  AnalyticsAlertMenuOptions,
  getCloudFromAssets,
  getNewCollaborators,
  getNewUsers,
  isEditor,
  isOwner,
  useCustomerIntegrations,
} from "../utilities";
import { copyAlert, createAlert, deleteAlert, deleteManyAlerts, shareAlert } from "./db";
import { type AnalyticsAlertWithRef } from "./types";

export const useCreateAlertHandler = ({
  mixpanelEventName,
  baseUrl,
  prevPageUrl,
}: {
  mixpanelEventName: string;
  baseUrl: string;
  prevPageUrl?: string;
}) => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const history = useHistory();
  const { customerOrPresentationModeCustomer: customer, userOrganization } = useCustomerContext();
  const routeMatchURL = useRouteMatchURL();

  return useCallback(async () => {
    const alertRef = await createAlert({
      userOrg: isDoitEmployee ? null : userOrganization,
      customerId: customer.id,
      customerRef: customer.ref,
      recipient: customer.presentationMode?.isPredefined ? NoReplyEmail : currentUser.email,
      email: currentUser.email,
    });

    mixpanel.track(mixpanelEventName, { alertId: alertRef?.id });
    history.push(`${baseUrl}/${alertRef?.id}`, { prevPage: prevPageUrl ?? routeMatchURL });
  }, [
    baseUrl,
    currentUser.email,
    customer.id,
    customer.presentationMode?.isPredefined,
    customer.ref,
    history,
    isDoitEmployee,
    mixpanelEventName,
    prevPageUrl,
    routeMatchURL,
    userOrganization,
  ]);
};

export const useThreeDotsAlertsMenu = ({ row, handleDelete, handleShare }) => {
  const successSnackbar = useSuccessSnackbar();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const isCurrentUserOwner = isOwner(currentUser.email, row.data);
  const isCurrentUserEditor = isEditor(currentUser.email, row.data);
  const isCloudAnalyticsAdmin = useCloudAnalyticsAdminCheck();
  const isRecipient = row.data.recipients?.includes(currentUser.email);
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  const handleCopyAlert = useCallback(async () => {
    if (!currentUser.email) {
      return;
    }
    await copyAlert(customer.ref, currentUser.email, row);
    successSnackbar(AlertsTxt.DUPLICATE_SUCCESSFUL);
  }, [currentUser.email, customer.ref, successSnackbar, row]);

  const handleSubscribe = useCallback(async () => {
    if (isRecipient) {
      row.data.recipients = row.data.recipients.filter((r) => r !== currentUser.email);
    } else {
      row.data.recipients.push(currentUser.email);
    }
    await row.ref.update({ recipients: row.data.recipients });
    successSnackbar(AlertsTxt.SUBSCRIPTION_UPDATED);
  }, [currentUser.email, isRecipient, row.data, row.ref, successSnackbar]);

  const actionMenu = useMemo(
    () => ({
      [AnalyticsAlertMenuOptions.SHARE]: handleShare,
      [AnalyticsAlertMenuOptions.DUPLICATE]: handleCopyAlert,
      [AnalyticsAlertMenuOptions.SUBSCRIBE]: handleSubscribe,
      [AnalyticsAlertMenuOptions.DELETE]: handleDelete,
    }),
    [handleCopyAlert, handleDelete, handleShare, handleSubscribe]
  );

  const actionHandler = useCallback(
    (action: string) => () => {
      actionMenu[action](true);
    },
    [actionMenu]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () =>
      alertMenuOptions
        .filter(
          (option) =>
            !(
              (option.value === AnalyticsAlertMenuOptions.DELETE && !isCurrentUserOwner) ||
              (option.value === AnalyticsAlertMenuOptions.SUBSCRIBE && isCurrentUserOwner) ||
              (option.value === AnalyticsAlertMenuOptions.SHARE && !(isCurrentUserEditor || isCloudAnalyticsAdmin))
            )
        )
        .map((option) => {
          let label = option.value;
          if (option.value === AnalyticsAlertMenuOptions.SUBSCRIBE) {
            label = isRecipient ? "Unsubscribe" : "Subscribe";
          }
          return {
            label: <Typography color={option.color}>{label}</Typography>,
            action: actionHandler(option.value),
            key: option.value,
          };
        }),
    [isCurrentUserOwner, isCurrentUserEditor, isCloudAnalyticsAdmin, actionHandler, isRecipient]
  );

  return <ThreeDotsMenu closeAfterSelect options={threeDotsMenuOptions} />;
};

export const useShareHandler = ({ closeDialog, clickedRow, selected, setLoading }) => {
  const { entities } = useEntitiesContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { customer } = useCustomerContext();
  const { handleMissingPermission } = useCloudAnalyticsContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();
  const api = useApiContext();

  const isBulk = selected.length >= 1;
  const isCurrentUserUserManager = userRoles.usersManager || userRoles.doitEmployee || userRoles.cloudAnalyticsAdmin;

  const inviteAction = useCallback(
    async (collaborators, alert) => {
      const emails = await getNewUsers({
        collaborators,
        users: userEmails,
        invites,
        baseEntity: alert,
        allUsersAndInvites,
      });
      if (emails.length && currentUser) {
        await Invites.handleInvite({
          newEmails: emails,
          customer,
          currentUser,
          api,
          entities,
        });
      }
    },
    [api, currentUser, customer, invites, userEmails, allUsersAndInvites, entities]
  );

  return useCallback(
    async (collaborators: Collaborators, publicAccess: PublicAccess) => {
      const alertsToShare = isBulk ? selected : [clickedRow];
      try {
        setLoading(true);

        for (const alert of alertsToShare) {
          if (isCurrentUserUserManager) {
            try {
              await inviteAction(collaborators, alert);
            } catch (e) {
              handleMissingPermission(e as string);
              return;
            }
          }

          await shareAlert({
            api,
            customer,
            alertId: alert.ref?.id || alert.id,
            publicAccess: publicAccess === "mixed" ? alert.data.public : publicAccess,
            collaborators: getNewCollaborators(collaborators, alert.data.collaborators),
          });
        }
        successSnackbar(analyticsAlertText.SHARE_SUCCESS);
        closeDialog();
      } catch (error: any) {
        consoleErrorWithSentry(error);
        errorSnackbar(error.response.data.error || analyticsAlertText.SHARE_ERROR);
      } finally {
        setLoading(false);
      }
    },
    [
      isBulk,
      selected,
      clickedRow,
      setLoading,
      successSnackbar,
      closeDialog,
      isCurrentUserUserManager,
      api,
      customer,
      inviteAction,
      handleMissingPermission,
      errorSnackbar,
    ]
  );
};

export const useDeleteHandler = ({
  closeDialog,
  clickedRow,
  selected,
}: {
  closeDialog: () => void;
  clickedRow: AnalyticsResourcesAlerts | AnalyticsAlertWithRef;
  selected: AnalyticsResourcesAlerts[] | AnalyticsAlertWithRef[];
}) => {
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const api = useApiContext();
  const customerId = useCustomerId();

  return useCallback(async () => {
    try {
      if (!clickedRow.data) {
        await deleteManyAlerts(
          api,
          customerId,
          selected.map((s: AnalyticsResourcesAlerts | AnalyticsAlertWithRef) => s.ref.id)
        );
      } else {
        await deleteAlert(api, customerId, clickedRow.ref.id);
      }
      successSnackbar(analyticsAlertText.DELETE_ALERT_SUCCESS);
      mixpanel.track("analytics.alerts.delete", { alertIds: selected.map((b) => b.ref.id).join(",") });
    } catch (error) {
      consoleErrorWithSentry(error);
      errorSnackbar(analyticsAlertText.DELETE_ALERT_ERROR);
    } finally {
      closeDialog();
    }
  }, [clickedRow, closeDialog, errorSnackbar, selected, successSnackbar, api, customerId]);
};

export const useAlertAnalytics = () => {
  const { customer } = useCustomerContext();
  const { customerIntegrations } = useCustomerIntegrations();
  const [extendedMetrics, extendedMetricsLoading] = useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("configs").collection("cloudAnalyticsConfigs").doc("extended-metrics")
  );

  const [datahubMetrics, datahubMetricsLoading] = useDocumentDataOnce(
    getCollection(CloudAnalyticsModel).doc("metrics").collection("datahubMetrics").doc(customer.id)
  );

  const filteredExtendedMetrics = useMemo(() => {
    const cloud = getCloudFromAssets(customer.assets);
    const visibility = customer.id === CSPCustomerID ? "csp" : "customer";

    return extendedMetrics?.metrics?.filter((metric) => {
      if (!!metric.cloud && !!cloud && metric.cloud !== cloud && !customerIntegrations?.includes(metric.cloud)) {
        return false;
      }

      return metric.visibility === "all" || metric.visibility === visibility;
    });
  }, [customer.assets, customer.id, customerIntegrations, extendedMetrics?.metrics]);

  return {
    filteredExtendedMetrics: filteredExtendedMetrics ?? [],
    extendedMetricsLoading,
    datahubMetrics: datahubMetrics?.metrics ?? [],
    datahubMetricsLoading,
  };
};
