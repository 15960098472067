import { useMemo, useState } from "react";

import CheckIcon from "@mui/icons-material/Check";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, Chip, Divider, Menu, MenuItem } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";

import { INITIAL_SKILLS_SHOWN, SKILLS_INCREMENT } from "./constants/searchFilters";
import { type FilterOption, type SkillGroup } from "./types/searchFilters";
import useStyles from "./utils/styles";

type FilterDropdownProps = {
  title: string;
  icon: React.ReactNode;
  filters: FilterOption[] | SkillGroup[];
  activeFilters: Set<string>;
  onSelect: (filter: FilterOption) => void;
  disabled?: boolean;
};

export const FilterDropdown = ({ title, icon, filters, activeFilters, onSelect, disabled }: FilterDropdownProps) => {
  const theme = useTheme();
  const unavailableColor = theme.palette.mode === "light" ? theme.palette.grey[400] : theme.palette.grey[600];
  const selectedBgColor =
    theme.palette.mode === "light" ? alpha(theme.palette.primary.main, 0.1) : theme.palette.action.selected;
  const classes = useStyles({
    selectedBgColor,
  });
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [shownSkillsCount, setShownSkillsCount] = useState<Map<string, number>>(new Map());
  const open = Boolean(anchorEl);

  const activeCount = useMemo(() => {
    if (Array.isArray(filters) && filters.length > 0) {
      if ("subGroups" in filters[0]) {
        // For skills: count across all subgroups
        return (filters as SkillGroup[]).reduce(
          (count, group) =>
            count +
            group.subGroups.reduce(
              (subCount, subGroup) =>
                subCount + subGroup.skills.filter((skill) => activeFilters.has(skill.query)).length,
              0
            ),
          0
        );
      } else {
        // For timezones and languages: count matches based on query
        return (filters as FilterOption[]).filter((filter) => {
          const lowercaseQuery = filter.query.toLowerCase();
          return (
            activeFilters.has(lowercaseQuery) ||
            Array.from(activeFilters).some((active) => active.toLowerCase().startsWith(lowercaseQuery))
          );
        }).length;
      }
    }
    return 0;
  }, [activeFilters, filters]);

  const handleShowMore = (groupLabel: string, totalSkills: number, event: React.MouseEvent) => {
    event.stopPropagation();
    const currentCount = shownSkillsCount.get(groupLabel) || INITIAL_SKILLS_SHOWN;
    const newCount = Math.min(currentCount + SKILLS_INCREMENT, totalSkills);
    setShownSkillsCount(new Map(shownSkillsCount).set(groupLabel, newCount));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShownSkillsCount(new Map());
  };

  const renderFilters = (filters: FilterOption[]) => {
    const sortedFilters = [...filters].sort((a, b) => {
      // First sort by selection status
      const aSelected = activeFilters.has(a.query);
      const bSelected = activeFilters.has(b.query);
      if (aSelected !== bSelected) return bSelected ? -1 : 1;

      // Then sort by availability
      if (a.available !== b.available) return a.available ? -1 : 1;

      // Then sort by count if available
      if (a._count !== undefined && b._count !== undefined) return b._count - a._count;

      // Finally sort alphabetically
      return a.label.localeCompare(b.label);
    });

    const selectedFilters = sortedFilters.filter((filter) => activeFilters.has(filter.query));
    const unselectedFilters = sortedFilters.filter((filter) => !activeFilters.has(filter.query));

    const currentlyShown = shownSkillsCount.get("languages") || INITIAL_SKILLS_SHOWN;
    const visibleFilterItems = [
      ...selectedFilters,
      ...unselectedFilters.slice(0, Math.max(0, currentlyShown - selectedFilters.length)),
    ];

    const remainingCount = unselectedFilters.length - (currentlyShown - selectedFilters.length);
    const hasMore = remainingCount > 0;

    return (
      <>
        {visibleFilterItems.map((filterItem) => {
          const isActive = activeFilters.has(filterItem.query);
          return (
            <MenuItem
              key={filterItem.query}
              onClick={() => {
                onSelect(filterItem);
              }}
              className={classes.filterDropdownMenuItem}
              sx={{
                bgcolor: isActive ? selectedBgColor : "inherit",
                color: filterItem.available ? theme.palette.text.primary : unavailableColor,
              }}
            >
              {filterItem.label}
              {isActive && <CheckIcon fontSize="small" color="primary" />}
            </MenuItem>
          );
        })}
        {hasMore && (
          <MenuItem
            onClick={(e) => {
              handleShowMore("languages", sortedFilters.length, e);
            }}
            className={classes.filterDropdownShowMore}
          >
            Show more
          </MenuItem>
        )}
      </>
    );
  };

  const renderSkillGroups = (filters: FilterOption[] | SkillGroup[]) => {
    if (!Array.isArray(filters) || filters.length === 0) return null;

    if ("subGroups" in filters[0]) {
      return (filters as SkillGroup[]).map((group) => (
        <Box key={group.label}>
          <MenuItem
            disabled
            className={classes.filterDropdownGroupHeader}
            sx={{
              backgroundColor: () => {
                switch (group.label) {
                  case "AWS":
                    return "#ff9900";
                  case "GCP":
                    return "#4285f4";
                  default:
                    return "#e91e63";
                }
              },
            }}
          >
            {group.label}
          </MenuItem>
          {group.subGroups.map((subGroup) => (
            <Box key={subGroup.label}>
              {subGroup.label && (
                <MenuItem disabled className={classes.filterDropdownSubGroupHeader}>
                  {subGroup.label}
                </MenuItem>
              )}
              {renderFilters(subGroup.skills)}
            </Box>
          ))}
          <Divider />
        </Box>
      ));
    }

    return renderFilters(filters as FilterOption[]);
  };

  return (
    <Box>
      <Button
        variant="outlined"
        onClick={(e) => {
          setAnchorEl(e.currentTarget);
        }}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={disabled}
        size="small"
        className={classes.filterDropdownButton}
        startIcon={icon}
        data-cy={`${title.toLowerCase()}-filter-button`}
      >
        {title}
        {activeCount > 0 && <Chip size="small" label={activeCount} color="primary" />}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          paper: {
            className: classes.filterDropdownMenuPaper,
          },
        }}
      >
        {renderSkillGroups(filters)}
      </Menu>
    </Box>
  );
};
