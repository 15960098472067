import React from "react";

import { useHistory } from "react-router";

import { useCustomerContext } from "../../../Context/CustomerContext";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { type InsightsAddPermissionsProperties } from "../types";
import { PermissionsAlert } from "./PermissionsAlert";

type Props = {
  accountsMissingPermissions: string[];
  insightsEvent?: InsightsAddPermissionsProperties;
};

export const MissingTrustedAdvisorPermissionsAlert: React.FC<Props> = ({
  accountsMissingPermissions,
  insightsEvent,
}) => {
  const history = useHistory();
  const { trackEvent } = useSegmentTrackEvent();

  const { customer } = useCustomerContext();

  const handleClick = () => {
    trackEvent("Insights Add Permissions Clicked", insightsEvent);
    history.push(`/customers/${customer.id}/settings/aws?filter=${accountsMissingPermissions.toString()}`);
  };

  return (
    <PermissionsAlert onClick={handleClick}>
      Enable Trusted Advisor insights on your accounts with an AWS Business or Enterprise Support plan to get
      recommendations here.
    </PermissionsAlert>
  );
};
