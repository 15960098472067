import { Stack, Typography } from "@mui/material";

export const PLESDescription = () => (
  <>
    <Stack spacing={2} sx={{ mt: 2, mb: 3 }}>
      <Typography variant="body1">
        PLES Special Program is an AWS collaboration to give certain customers Enterprise Support at the cost of
        Business Support.
      </Typography>
      <Stack spacing={1}>
        <Typography variant="body1">To manage this:</Typography>
        <ul style={{ margin: 0, paddingLeft: "1.5rem" }}>
          <li>
            <Typography variant="body1" component="span">
              AWS updates a spreadsheet monthly to indicate which accounts are on this programme
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              The sheet has to be uploaded by BizOps to the console before month-end
            </Typography>
          </li>
          <li>
            <Typography variant="body1" component="span">
              This ensures the latest source of truth from AWS is used for billing
            </Typography>
          </li>
        </ul>
      </Stack>
      <Stack spacing={1}>
        <Typography variant="body1">Important Notes:</Typography>
        <ul style={{ margin: 0, paddingLeft: "1.5rem", maxWidth: "900px" }}>
          <li>
            <Typography variant="body1" component="span">
              This special PLES programme cannot be read from the CUR, as we would never know which accounts are
              included or excluded from the programme
            </Typography>
          </li>
        </ul>
      </Stack>
    </Stack>
  </>
);
