import { Link } from "react-router-dom";
import { Card, CardContent } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { Preview } from "../Preview/Preview";
import { type Insight } from "../types";

export function InsightsListRow({ insight, isClickable = true }: { readonly insight: Insight; isClickable?: boolean }) {
  const {
    palette: { primary },
  } = useTheme();
  const routeMatchURL = useRouteMatchURL();

  return (
    <Card
      variant="outlined"
      sx={{
        "&:hover": isClickable ? { borderColor: primary.main } : {},
      }}
    >
      <CardContent sx={{ p: 3 }}>
        {isClickable ? (
          <Link
            to={`${routeMatchURL}/${insight.providerId}/${insight.key}`}
            style={{ color: "inherit", textDecoration: "none" }}
          >
            <Preview key={`${insight.providerId}#${insight.key}`} insight={insight} isListView={true} />
          </Link>
        ) : (
          <Preview key={`${insight.providerId}#${insight.key}`} insight={insight} isListView={true} />
        )}
      </CardContent>
    </Card>
  );
}
