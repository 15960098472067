import { CloudflowExecutionStatus } from "@doitintl/cmp-models";

import { type FAQ } from "../../Components/FAQ/FAQAccordion";

export const executionStatusNames = {
  [CloudflowExecutionStatus.COMPLETE]: "Complete",
  [CloudflowExecutionStatus.FAILED]: "Has errors",
  [CloudflowExecutionStatus.RUNNING]: "In progress",
  [CloudflowExecutionStatus.PENDING]: "Pending",
  [CloudflowExecutionStatus.PENDING_APPROVAL]: "Awaiting approval",
};

export const CLOUDFLOW_CONTENT_MAX_WIDTH = 550;

export const faqs: FAQ[] = [
  {
    question: "What is DoiT CloudFlow?",
    answer:
      "DoiT CloudFlow is a GenAI-powered, no-code FinOps workflow solution. It provides the automation and intelligence for you to manage FinOps processes with ease and ensure smooth operations and cost efficiency at scale.",
  },
  {
    question: "What are the benefits of DoiT CloudFlow?",
    answer:
      "DoiT CloudFlow empowers you to streamline cloud operations, enhance efficiency, and achieve better cost management through automation and proactive optimization.",
  },
  {
    question: "What are the core capabilities of DoiT CloudFlow?",
    answer:
      "DoiT CloudFlow connects your existing IT functions with crucial FinOps metrics such as cloud cost and usage, commitment plans, budgets and related monitoring, and cloud tagging standards. It benefits organizations by streamlining financial operations and decision-making.",
  },
  {
    question: "What APIs are used in DoiT CloudFlow?",
    answer:
      "DoiT CloudFlow integrates with a wide range of AWS and Google Cloud APIs, allowing you to build API-driven workflows.",
  },
  {
    question: "What permissions are needed to use DoiT CloudFlow?",
    answer:
      "At the basic level, DoiT CloudFlow inherits the permissions granted to DoiT when you connect your cloud accounts. You can verify whether you have the required permissions when configuring an action node, and follow the instructions provided in the DoiT console to add additional permissions if necessary.",
  },
];

export const ALL_ACCOUNTS = "All accounts";
