import { useMemo } from "react";

import { type CloudFlowNodeType, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import {
  Box,
  FormControl,
  FormControlLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { useFormikContext } from "formik";

import { cloudflowTexts } from "../../../../assets/texts";
import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useGetGcpProjects } from "../../hooks";
import { type GCPPermissionConfigValues } from "../ConfigurationPanel/hooks";
import { useNodeConfigurationContext } from "../ConfigurationPanel/NodeConfigurationContext";
import {
  GCPHierarchicalResource,
  PERMISSIONS_PROJECT_ID_KEY,
  PROJECT_ID_VARIATIONS,
  useGcpFormFields,
  useMergedProjectIds,
} from "../utils/gcpUtils";
import { useFieldValidationProps } from "./useFieldValidationProps";

interface GCPPermissionFormProps {
  readonly inputModel: StructureApiServiceModelDescriptor<Member>;
  readonly resetPermissions: () => void;
}

const GCPPermissionForm = ({ inputModel, resetPermissions }: GCPPermissionFormProps) => {
  const { values, setFormikState, getFieldProps, ...rest } = useFormikContext<GCPPermissionConfigValues>();
  const { nodeConfig, setActiveTab } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { customer } = useCustomerContext();

  const paramsProjectIdKey = useMemo(
    () => Object.keys(inputModel.members ?? {}).find((field) => PROJECT_ID_VARIATIONS.includes(field)),
    [inputModel]
  );
  const paramsProjectId = paramsProjectIdKey ? nodeConfig?.parameters?.configurationValues?.[paramsProjectIdKey] : null;

  const {
    organization,
    setOrganization,
    projectId,
    setProjectId,
    cloudConnectData,
    hierarchicalResource,
    isCloudConnectDataLoaded,
    setHierarchicalResource,
  } = useGcpFormFields(
    customer.id,
    values.organization || "",
    paramsProjectIdKey ? (values[paramsProjectIdKey] ?? null) : null,
    values.serviceAccount || "",
    values.hierarchicalResource || GCPHierarchicalResource.ORGANIZATION,
    PERMISSIONS_PROJECT_ID_KEY,
    {
      values,
      setFormikState,
      getFieldProps,
      ...rest,
    }
  );
  const allOrganizations = useMemo(() => cloudConnectData.flatMap((data) => data.organizations), [cloudConnectData]);
  const { data: projects, isLoading: gcpProjectsLoading } = useGetGcpProjects(customer.id, organization);
  const mergedProjectIds = useMergedProjectIds(cloudConnectData, projects);

  const projectIdFieldProps = useMemo(() => getFieldProps(PERMISSIONS_PROJECT_ID_KEY), [getFieldProps]);
  const { showError, errorMessage } = useFieldValidationProps(
    { values, setFormikState, getFieldProps, ...rest },
    projectIdFieldProps
  );

  return (
    <Stack sx={{ gap: 2 }}>
      <Box>
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS}
        </Typography>
        <Typography variant="body2" sx={{ mt: 1 }} color="text.secondary">
          {cloudflowTexts.PERMISSIONS.CHECK_PERMISSIONS_DESCRIPTION}
        </Typography>
        <Typography variant="subtitle2" sx={{ fontWeight: 500, mt: 1 }}>
          {cloudflowTexts.PERMISSIONS.PERMISSION_CHECK_ON}
        </Typography>
        <RadioGroup
          style={{ marginLeft: 8 }}
          name="hierarchicalResource"
          value={values.hierarchicalResource}
          onChange={(e) => {
            setHierarchicalResource(e.target.value as GCPHierarchicalResource);
            resetPermissions();
          }}
        >
          <FormControlLabel
            value={GCPHierarchicalResource.ORGANIZATION}
            control={<Radio size="small" />}
            label={<Typography variant="body2">Organization</Typography>}
          />
          <FormControlLabel
            value={GCPHierarchicalResource.PROJECT}
            control={<Radio size="small" />}
            label={<Typography variant="body2">Project</Typography>}
          />
        </RadioGroup>
      </Box>

      <Stack spacing={2}>
        {organization ? (
          <Stack direction="row" gap={1}>
            <Typography variant="body2">{cloudflowTexts.PERMISSIONS.ORGANIZATION}:</Typography>
            <Typography variant="subtitle2">
              {allOrganizations.find((org) => org.name === organization)?.displayName || organization}
            </Typography>
          </Stack>
        ) : (
          <FormControl fullWidth>
            <TextField
              id="organization"
              name="organization"
              variant="outlined"
              select
              label="Organization"
              value={organization}
              onChange={(e) => {
                setOrganization(e.target.value);
              }}
              required
            >
              {allOrganizations.map((org) => (
                <MenuItem key={org.name} value={org.name}>
                  {org.displayName}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        )}

        {hierarchicalResource === GCPHierarchicalResource.PROJECT &&
          (paramsProjectIdKey ? (
            <Stack direction="row" gap={1}>
              <Typography variant="body2">{cloudflowTexts.PERMISSIONS.PROJECT_ID}:</Typography>
              {paramsProjectId ? (
                <Typography variant="subtitle2">{paramsProjectId as string}</Typography>
              ) : (
                <Typography variant="subtitle2">{cloudflowTexts.PERMISSIONS.NO_PROJECT_ID}</Typography>
              )}
            </Stack>
          ) : (
            <FormControl fullWidth>
              <VirtualizedAutocomplete
                id="project"
                key={mergedProjectIds.length}
                loading={gcpProjectsLoading || !isCloudConnectDataLoaded}
                options={mergedProjectIds}
                onChange={(_, newValue) => {
                  setProjectId(newValue as string | null);
                  resetPermissions();
                }}
                renderOption={(props, option) => (
                  <MenuItem {...props} value={option}>
                    {option}
                  </MenuItem>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    type="text"
                    label="Project"
                    name="Project"
                    fullWidth
                    error={showError}
                    helperText={errorMessage}
                    variant="outlined"
                    required
                  />
                )}
                size="small"
                fullWidth
                value={projectId ?? null}
              />
            </FormControl>
          ))}
      </Stack>

      {(paramsProjectIdKey || !organization) && (
        <Typography variant="body2" color="text.secondary">
          {cloudflowTexts.PERMISSIONS.UPDATE_VALUES}{" "}
          <Link
            variant="body2"
            underline="hover"
            onClick={() => {
              setActiveTab("Parameters");
            }}
            style={{ cursor: "pointer" }}
          >
            {cloudflowTexts.PERMISSIONS.PARAMETERS_TAB}
          </Link>
        </Typography>
      )}
    </Stack>
  );
};

export default GCPPermissionForm;
