import { type Timestamp } from "@doitintl/models-types";
import { Divider } from "@mui/material";

import { getDatetimeAndDiffFromTimestamp } from "../../../../../utils/time";

interface StatusTooltipContentProps {
  updatedTimestamp?: Timestamp;
  body?: React.ReactNode;
}

const StatusTooltipContent = ({ updatedTimestamp, body }: StatusTooltipContentProps) => {
  const { exactDatetime, timeAgo } = getDatetimeAndDiffFromTimestamp(updatedTimestamp);

  const timeSection = (
    <>
      <p>Last updated {timeAgo}</p>
      <p>({exactDatetime})</p>
    </>
  );

  return (
    <>
      {timeSection}
      {!!body && (
        <>
          <Divider />
          {body}
        </>
      )}
    </>
  );
};

export default StatusTooltipContent;
