import { memo, useCallback, useMemo, useRef } from "react";

import { ComparativeFeature, PERCENT_AGGREGATORS, Renderer } from "@doitintl/cmp-models";
import { Box } from "@mui/system";

import { useReportExportContext } from "../Context";
import { isComparative } from "../utilities";
import { ECharts } from "./ECharts/ECharts";
import { type EChartType } from "./ECharts/types";
import { getChartsTreeSeries } from "./getChartsTreeSeries";
import useChartsSeries from "./useChartsSeries";
import { getWidgetFormatter } from "./WidgetChartRenderer";
import type ReportData from "../../../Pages/CloudAnalytics/ReportData";

const newTypesMapping: Record<string, EChartType> = {
  [Renderer.STACKED_COLUMN_CHART]: "stacked-column",
  [Renderer.STACKED_COLUMN_CHART_LEGACY]: "stacked-column",
  [Renderer.LINE_CHART]: "line",
  [Renderer.STACKED_AREA_CHART]: "stacked-area",
  [Renderer.COLUMN_CHART]: "column",
  [Renderer.BAR_CHART]: "bar",
  [Renderer.STACKED_BAR_CHART]: "stacked-bar",
  [Renderer.SPLINE_CHART]: "line-spline",
  [Renderer.AREA_SPLINE_CHART]: "area-spline",
  [Renderer.AREA_CHART]: "area",
  [Renderer.TREEMAP]: "tree-map",
};

type ChartsRendererProps = {
  type: string;
  data: ReportData;
  formatter: (value: any, short?: any, comparative?: ComparativeFeature) => any;
  isWidget?: boolean;
  comparative?: ComparativeFeature;
  logScale?: boolean;
  height?: string;
};

const ChartsRenderer = ({
  type,
  data,
  formatter,
  isWidget = false,
  comparative = ComparativeFeature.NONE,
  logScale = false,
  height = "100%",
}: ChartsRendererProps) => {
  const { chartInstanceRef } = useReportExportContext();
  const localRef = useRef();

  const isCompReport = !!comparative && isComparative(comparative);
  const { categories, series } = useChartsSeries({
    data,
    isComparative: isCompReport,
  });

  const treeMapSeries = useMemo(() => {
    if (type !== Renderer.TREEMAP) {
      return [];
    }
    return getChartsTreeSeries(data);
  }, [type, data]);

  const formatterWithComparative = useCallback(
    (value: string | number, short?: boolean) => formatter(value, short, comparative),
    [formatter, comparative]
  );

  const categoryFormatter = useCallback(
    (value: string) => {
      if (isWidget) {
        return getWidgetFormatter(data?.getCols())(value);
      } else {
        return value;
      }
    },
    [isWidget, data]
  );

  return (
    <Box sx={{ height }}>
      <ECharts
        ref={chartInstanceRef ?? localRef}
        logScale={logScale}
        widgetView={isWidget}
        type={newTypesMapping[type]}
        categories={categories}
        series={series}
        treeSeries={treeMapSeries}
        valueFormatter={formatterWithComparative}
        categoryFormatter={categoryFormatter}
        forecastMode={data?.forecastSettings?.mode}
        forecasts={data?.forecasts}
        isUsingPercent={PERCENT_AGGREGATORS.includes(data.props.aggregator)}
      />
    </Box>
  );
};

export default memo(ChartsRenderer);
