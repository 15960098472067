import { useCallback } from "react";

import { AssetTypeAwsStandalone, type AWSNodeConfigApiParameters } from "@doitintl/cmp-models";

import { useApiContext } from "../../../../../api/context";
import { useCustomerId } from "../../../../../Components/hooks/useCustomerId";
import { useAssetsContext } from "../../../../../Context/customer/AssetContext";
import { type Assets } from "../../../../../types";
import { getApiServiceDescriptor } from "../../utils/getApiServiceDescriptor";

type AWSPermissions = {
  [permissionsNamespace: string]: string[];
};

type GrantedAWSPermissions = {
  awsPermissions: AWSPermissions;
  hasPolicy: boolean;
};

type AwsPermissionsCheckResult = {
  permissionExists: boolean;
  awsPermission: string;
  commandToGrantPermission: string;
};

function getRoleName(assets: Assets, accountId: string) {
  const assetExists = Object.values(assets).some((entityAssets) =>
    entityAssets[AssetTypeAwsStandalone]?.find(({ id }) => accountId === id)
  );
  return assetExists ? "doitintl_cmp" : "doitintl-cmp";
}

function generateCreatePolicyCommand(roleName: string, policyName: string, awsPermission: string) {
  return `aws iam put-role-policy \\
--role-name ${roleName} \\
--policy-name ${policyName} \\
--policy-document '${JSON.stringify({
    Version: "2012-10-17",
    Statement: [
      {
        Action: [awsPermission],
        Resource: "*",
        Effect: "Allow",
      },
    ],
  })}'`;
}

function generateUpdatePolicyCommand(roleName: string, policyName: string, awsPermission: string) {
  return `aws iam put-role-policy \\
--role-name ${roleName} \\
--policy-name ${policyName} \\
--policy-document "$(aws iam get-role-policy --role-name ${roleName} --policy-name ${policyName} --output json | jq '. | {Statement: .PolicyDocument.Statement, Version: .PolicyDocument.Version} | .Statement[0].Action += ["${awsPermission}"]'  | jq -c)"`;
}

function isAwsPermissionGranted(
  awsPermissions: AWSPermissions,
  permissionsNamespace: string,
  operationName: string
): boolean {
  return awsPermissions[permissionsNamespace]?.some((grantedPermission) =>
    new RegExp(`^${grantedPermission.replace("*", "\\w+")}$`).test(operationName)
  );
}

export function useAWSPermissionsCheck(cloudflowId: string) {
  const { assets } = useAssetsContext();
  const customerId = useCustomerId();
  const api = useApiContext();

  return useCallback(
    async (parameters: AWSNodeConfigApiParameters): Promise<AwsPermissionsCheckResult> => {
      const accountId = parameters.configurationValues.accountId;

      const [
        serviceDescriptor,
        {
          data: { awsPermissions, hasPolicy },
        },
      ] = await Promise.all([
        getApiServiceDescriptor(parameters.operation.provider, parameters.operation.service),
        api.request<GrantedAWSPermissions>({
          method: "GET",
          url: `/v1/customers/${customerId}/cloudflow/get-aws-permissions`,
          params: {
            accountId,
            cloudflowId,
          },
        }),
      ]);

      if (serviceDescriptor.permissionsNamespace === undefined) {
        throw new Error(`Missing permissions namespace for service: ${serviceDescriptor.serviceName}`);
      }

      const awsPermission = `${serviceDescriptor.permissionsNamespace}:${parameters.operation.id}`;

      const permissionExists = isAwsPermissionGranted(
        awsPermissions,
        serviceDescriptor.permissionsNamespace,
        parameters.operation.id
      );

      let commandToGrantPermission = "";

      if (!permissionExists) {
        const roleName = getRoleName(assets, accountId);
        const policyName = `DoiT-CloudFlow-${cloudflowId}`;
        commandToGrantPermission = hasPolicy
          ? generateUpdatePolicyCommand(roleName, policyName, awsPermission)
          : generateCreatePolicyCommand(roleName, policyName, awsPermission);
      }

      return {
        awsPermission,
        permissionExists,
        commandToGrantPermission,
      };
    },
    [api, assets, customerId, cloudflowId]
  );
}
