import { type FlexsaveFlavour, Status, type StatusTransition } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Chip, Tooltip } from "@mui/material";
import { DateTime } from "luxon";

import { dateFormatCompleteDateWithoutSeconds } from "../../../../utils/dateTimeFormats";
import { getChipColor } from "./utils";

const getAction = (status: Status) => {
  switch (status) {
    case Status.active: {
      return "Enabled";
    }
    case Status.disabled: {
      return "Disabled";
    }
    case Status.pending: {
      return "Set to Pending";
    }
    default:
      return "";
  }
};

export const getTitle = (
  status: Status,
  lastStatusTransition: StatusTransition<FlexsaveFlavour> | undefined
): string | null => {
  if (!lastStatusTransition) {
    return null;
  }

  const action = getAction(status);

  if (!action) {
    return null;
  }

  return `${action} by ${lastStatusTransition.changedBy} at ${DateTime.fromJSDate(
    new Date(lastStatusTransition.updatedAt)
  ).toFormat(dateFormatCompleteDateWithoutSeconds)} ${
    lastStatusTransition.reason ? `'${lastStatusTransition.reason}'` : ""
  }`;
};

type Props = {
  status: Status | "";
  lastStatusTransition: StatusTransition<FlexsaveFlavour> | undefined;
};

export default function PayerChip({ status, lastStatusTransition }: Props) {
  if (!status) {
    return null;
  }

  const title = getTitle(status, lastStatusTransition);

  return (
    <Box
      sx={{
        display: "flex",
        alignContent: "center",
        gap: 1,
      }}
    >
      <Chip
        variant="status"
        label={status}
        color={getChipColor(status)}
        icon={
          title ? (
            <Tooltip title={title} arrow>
              <InfoOutlinedIcon />
            </Tooltip>
          ) : undefined
        }
      />
    </Box>
  );
}
