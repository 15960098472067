import { useEffect, useMemo, useState } from "react";

import { consoleErrorWithSentry } from "@client/src/utils";
import { AssetTypeMicrosoftAzure, type CloudConnectAzureCloud, CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore/";

import { useApiContext } from "../../../api/context";
import { AzureConnectApi } from "./api";
import type { AzureSubscription } from "./types";

export function useAzureConnectApi() {
  const api = useApiContext();
  return useMemo(() => new AzureConnectApi(api), [api]);
}

export function useCloudConnectData(customerId: string): [CloudConnectAzureCloud[], boolean, boolean] {
  const [cloudConnectData, setCloudConnectData] = useState<CloudConnectAzureCloud[]>([]);
  const [hasConnectedServiceAccount, setHasConnectedServiceAccount] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(
    () =>
      getCollection(CustomerModel)
        .doc(customerId)
        .collection("cloudConnect")
        .where("cloudPlatform", "==", AssetTypeMicrosoftAzure)
        .narrow<CloudConnectAzureCloud>()
        .onSnapshot((querySnapshot) => {
          setCloudConnectData(
            querySnapshot.docs.map((doc) => ({
              ...doc.asModelData(),
              id: doc.id,
            }))
          );
          setIsLoaded((prevState) => {
            if (!prevState) {
              setHasConnectedServiceAccount(querySnapshot.size > 0);
              return true;
            }

            return prevState;
          });
        }),
    [customerId]
  );
  return [cloudConnectData, hasConnectedServiceAccount, isLoaded];
}

export function useCustomerSubscriptions(customerID: string): [AzureSubscription[], boolean, boolean] {
  const [subscriptions, setSubscriptions] = useState<AzureSubscription[]>([]);
  const [hasSubscriptions, setHasSubscriptions] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const api = useAzureConnectApi();

  useEffect(() => {
    const fetchSubscriptions = async () => {
      try {
        const subscriptions = await api.listTenantsSubscriptions(customerID);
        setSubscriptions(subscriptions);
        setIsLoaded(true);
        setHasSubscriptions(subscriptions.length > 0);
      } catch (err) {
        consoleErrorWithSentry(err);
        setIsLoaded(true);
      }
    };

    fetchSubscriptions().then().catch();
  }, [customerID, api]);

  return [subscriptions, hasSubscriptions, isLoaded];
}
