import {
  type AssetModel,
  type AvaFeedback,
  type SupportServiceModel,
  type ZendeskPlatformModel,
} from "@doitintl/cmp-models";

import { type AvaConversationRef } from "../Ava/useAvaConversationRefs";

export enum ConversationHistoryPeriod {
  TODAY = "today",
  PREV_7 = "prev7",
  PREV_30 = "prev30",
  OLDER = "older",
}

export type ConversationHistory = Partial<Record<ConversationHistoryPeriod, AvaConversationRef[]>>;

export type TicketAsset = {
  identifier: string;
  asset: string;
};

export type CaseIQState = {
  content: string;
  platform: string;
  cloudProduct: SupportServiceModel | null;
  asset: string;
  urgency: string;
  technicalDetails: Array<{ name: string; value: string | null }>;
};

export type AvaDialogContextType = {
  caseIQConversationId: string;
  setCaseIQConversationId: (caseIQConversationId?: string) => void;
  caseIQState: CaseIQState;
  handleCaseIQStateChange: (field: string, value: string | SupportServiceModel | null) => void;
  loading: boolean;
  platformList: ZendeskPlatformModel[];
  categoryList: SupportServiceModel[];
  assetsList: AssetModel[];
};

export type MessageAva = {
  content: string;
  role: "ava";
  answerId?: string;
  completed: boolean;
  feedback?: AvaFeedback;
  createdAt: Date;
  isReport: boolean;
  reportBlocked?: boolean;
  additionalData?: string;
};

export type MessageUser = {
  role: "user";
  content: string;
  createdAt: Date;
};

export type Message = MessageAva | MessageUser;

export type FetcherData = {
  clientName: string;
  question: string;
  conversationId: string | undefined;
};
