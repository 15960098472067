import { useEffect, useState } from "react";

import { type ApiServiceBaseOperationDescriptor, CloudflowEngine, type OperationPointer } from "@doitintl/cmp-models";
import { getCollection, type WithFirebaseModel } from "@doitintl/models-firestore";

import { usePromiseStateSetter } from "./usePromiseStateSetter";

export async function getOperationByPointer({
  id,
  provider,
  service,
  version,
}: OperationPointer): Promise<WithFirebaseModel<ApiServiceBaseOperationDescriptor>> {
  const operationURI = `cloudflowEngine/integrations/apis/${provider}/services/${service}/versions/${version}/operations/${id}`;

  const cachedOperationData = sessionStorage.getItem(operationURI);
  if (cachedOperationData !== null) {
    return JSON.parse(cachedOperationData);
  }

  const operationData = (
    await getCollection(CloudflowEngine)
      .doc("integrations")
      .collection("apis")
      .doc(provider)
      .collection("services")
      .doc(service)
      .collection("versions")
      .doc(version)
      .collection("operations")
      .doc(id)
      .get()
  ).asModelData();
  if (operationData === undefined) {
    throw new Error(`Unable to fetch model ${operationURI}`);
  }
  sessionStorage.setItem(operationURI, JSON.stringify(operationData));
  return operationData;
}

type OperationData = {
  operation: WithFirebaseModel<ApiServiceBaseOperationDescriptor> | null;
  operationPointer: OperationPointer | null;
};

export const useGetOperationById = (operationPointer: OperationPointer) => {
  const [operationData, setOperationData] = useState<OperationData>({ operation: null, operationPointer: null });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const setStateWithPromise = usePromiseStateSetter();

  useEffect(() => {
    setLoading(true);
    setOperationData({ operation: null, operationPointer });
    try {
      return setStateWithPromise(getOperationByPointer(operationPointer), (operation) => {
        setOperationData({ operation, operationPointer });
        setLoading(false);
      });
    } catch (exception) {
      setError(exception?.toString() ?? "unknown error");
      setLoading(false);
    }
  }, [operationPointer, setStateWithPromise]);

  return { operationData, loading, error };
};
