import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { cloudflowTexts, globalText } from "../../../../../../assets/texts";

export function useFilterDialogTexts(mode: "add" | "edit", nodeType: CloudFlowNodeType) {
  switch (nodeType) {
    case CloudFlowNodeType.CONDITION:
      return {
        dialogTitle: mode === "add" ? cloudflowTexts.ADD_NEW_CONDITION : cloudflowTexts.EDIT_CONDITION,
        submitButtonText: mode === "add" ? cloudflowTexts.ADD_CONDITION : globalText.EDIT,
      };
    default:
      return {
        dialogTitle: mode === "add" ? cloudflowTexts.ADD_NEW_FILTER : cloudflowTexts.EDIT_FILTER,
        submitButtonText: mode === "add" ? cloudflowTexts.ADD_FILTER : globalText.EDIT,
      };
  }
}
