import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useFormikContext } from "formik";

import { AutocompleteCurrency } from "../../../Components/CreateBillingProfile/BillingForm/components/AutocompleteCurrency";
import { providers } from "../consts";
import { ContractsSelector } from "../ContractsSelector";
import { type ContractType } from "../types";

interface GeneralStepValues {
  contractType: ContractType;
  name: string;
  selectedContractIds: string[];
  currency: string;
}

export const GeneralStep = () => {
  const { values, handleChange, setFieldValue, errors, touched, submitCount } = useFormikContext<GeneralStepValues>();

  return (
    <Stack sx={{ width: "50%" }}>
      <Typography variant="h4" mb={3}>
        General
      </Typography>
      <Typography variant="subtitle1" fontWeight={500}>
        Select the provider{" "}
        <Typography component="span" color="error">
          *
        </Typography>
      </Typography>

      <FormControl component="fieldset" required error={submitCount > 0 && Boolean(errors.contractType)}>
        <RadioGroup value={values.contractType} onChange={(event) => setFieldValue("contractType", event.target.value)}>
          {providers.map(({ name, value }, index) => (
            <FormControlLabel key={index} value={value} control={<Radio />} label={name} />
          ))}
        </RadioGroup>
        {submitCount > 0 && Boolean(errors.contractType) && (
          <FormHelperText error>{errors.contractType}</FormHelperText>
        )}
      </FormControl>
      <Stack spacing={3} mt={3}>
        <TextField
          label="Commitment manager name"
          required
          variant="outlined"
          fullWidth
          name="name"
          value={values.name}
          onChange={handleChange}
          error={touched.name && Boolean(errors.name)}
          helperText={touched.name && errors.name}
        />
        <ContractsSelector
          selectedContractIds={values.selectedContractIds}
          setSelectedContractIds={(contractIds) => setFieldValue("selectedContractIds", contractIds)}
          filterType={values.contractType}
        />

        <AutocompleteCurrency
          value={values.currency}
          onChange={(currency) => setFieldValue("currency", currency)}
          label="Currency"
          size="small"
        />
      </Stack>
    </Stack>
  );
};
