import React from "react";

import { type CommitmentManagersModel, type ContractType } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

import AWSLogo from "../../assets/aws-logo.svg";
import AWSLogoDark from "../../assets/aws-logo-dark-mode.svg";
import GCPLogo from "../../assets/gcp-logo.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { formatCurrency, getCurrencyByCode } from "../../utils/common";
import { StatusChip } from "../RampPlans/components/StatusChips";
import { CommitmentChart } from "./CommitmentChart";
import { getCurrentPeriodIndex, getPeriodStatus, processCommitmentPeriods } from "./utils";

interface CommitmentCardProps {
  commitment: ModelId<CommitmentManagersModel>;
  onCardClick: (commitmentId: string) => void;
}

export const CommitmentCard: React.FC<CommitmentCardProps> = React.memo(({ commitment, onCardClick }) => {
  const isDarkMode = useDarkThemeCheck();

  const getCommitmentData = (commitment: ModelId<CommitmentManagersModel>) => {
    if (!commitment.periods || commitment.periods.length === 0) {
      return {
        hasPeriods: false,
        firstPeriod: undefined,
        lastPeriod: undefined,
        startDate: undefined,
        endDate: undefined,
        endDateDT: undefined,
      };
    }

    const firstPeriod = commitment.periods[0];
    const lastPeriod = commitment.periods[commitment.periods.length - 1];
    const startDate = firstPeriod.startDate.toDate();
    const endDate = lastPeriod.endDate.toDate();
    const endDateDT = DateTime.fromJSDate(lastPeriod.endDate.toDate()).toUTC();

    return {
      hasPeriods: true,
      firstPeriod,
      lastPeriod,
      startDate,
      endDate,
      endDateDT,
    };
  };

  const getProviderLogo = (contractType: ContractType): string => {
    if (contractType === "google-cloud") {
      return GCPLogo as string;
    }
    return (isDarkMode ? AWSLogoDark : AWSLogo) as string;
  };

  const getTotalCommitmentValue = (commitment: ModelId<CommitmentManagersModel>) => {
    const { hasPeriods } = getCommitmentData(commitment);

    if (!hasPeriods) {
      return 0;
    }

    return commitment.periods.reduce((total, period) => total + (period.commitmentValue || 0), 0);
  };

  const getCommitmentStatus = (commitment: ModelId<CommitmentManagersModel>) => {
    const { hasPeriods, startDate, endDate } = getCommitmentData(commitment);

    if (!hasPeriods || !startDate || !endDate) {
      return "notStarted";
    }

    return getPeriodStatus(startDate, endDate);
  };

  const getTimeRemaining = (commitment: ModelId<CommitmentManagersModel>) => {
    const { hasPeriods, endDateDT } = getCommitmentData(commitment);
    const now = DateTime.now().toUTC();

    if (!hasPeriods || !endDateDT) {
      return "N/A";
    }

    if (endDateDT < now) {
      return "Completed";
    }

    const diff = endDateDT.diff(now, ["months", "days"]);
    const months = Math.floor(diff.months);
    const days = Math.floor(diff.days);

    if (months > 0) {
      return `${months} month${months > 1 ? "s" : ""}${days > 0 ? ` ${days} day${days > 1 ? "s" : ""}` : ""}`;
    } else if (days > 0) {
      return `${days} day${days > 1 ? "s" : ""}`;
    } else {
      return "Less than a day";
    }
  };

  const getSpendToDate = (commitment: ModelId<CommitmentManagersModel>) => {
    if (!commitment.periodsSpend || commitment.periodsSpend.length === 0) {
      return 0;
    }

    return commitment.periodsSpend.reduce((total, periodSpend) => total + (periodSpend.total || 0), 0);
  };

  const status = getCommitmentStatus(commitment);
  const totalCommitment = getTotalCommitmentValue(commitment);
  const spendToDate = getSpendToDate(commitment);
  const timeRemaining = getTimeRemaining(commitment);
  const logo = getProviderLogo(commitment.contractType);
  const currency = getCurrencyByCode(commitment.currency);
  const processedPeriods = processCommitmentPeriods(commitment);
  const periodIndex = getCurrentPeriodIndex(commitment.periods);

  const tableCellStyles = {
    cell: {
      py: 0.5,
      pr: 2,
      whiteSpace: "nowrap",
    },
    labelTypography: {
      variant: "body2" as const,
      color: "text.secondary",
      sx: { fontWeight: "bold" },
    },
    valueTypography: {
      variant: "body2" as const,
    },
  };

  return (
    <Card
      key={commitment.id}
      sx={{
        my: 2,
        p: 3,
      }}
    >
      <Stack direction="row" spacing={3}>
        <Stack
          spacing={2}
          sx={{
            flexShrink: 0,
            width: "auto",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
              <Box
                component="img"
                src={logo}
                alt={commitment.contractType === "google-cloud" ? "Google Cloud" : "AWS"}
                sx={{ height: "32px", width: "32px", mr: 1.5 }}
              />
              <Typography variant="h4" noWrap sx={{ fontWeight: "bold" }}>
                {commitment.name}
              </Typography>
            </Box>

            <Box component="table" sx={{ borderCollapse: "collapse", width: "min-content" }}>
              <Box component="tbody">
                <Box component="tr">
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.labelTypography}>Status:</Typography>
                  </Box>
                  <Box component="td" sx={tableCellStyles.cell}>
                    <StatusChip status={status} />
                  </Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.labelTypography}>Contract duration:</Typography>
                  </Box>
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.valueTypography}>
                      {commitment.periods && commitment.periods.length > 0 && (
                        <>
                          {DateTime.fromJSDate(commitment.periods[0].startDate.toDate()).toFormat("d MMM yyyy")} to{" "}
                          {DateTime.fromJSDate(
                            commitment.periods[commitment.periods.length - 1].endDate.toDate()
                          ).toFormat("d MMM yyyy")}
                        </>
                      )}
                    </Typography>
                  </Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.labelTypography}>Time remaining:</Typography>
                  </Box>
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.valueTypography}>{timeRemaining}</Typography>
                  </Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.labelTypography}>Commitment value:</Typography>
                  </Box>
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.valueTypography}>
                      {formatCurrency(totalCommitment, currency)}
                    </Typography>
                  </Box>
                </Box>

                <Box component="tr">
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.labelTypography}>Spend to date:</Typography>
                  </Box>
                  <Box component="td" sx={tableCellStyles.cell}>
                    <Typography {...tableCellStyles.valueTypography}>
                      {formatCurrency(spendToDate, currency)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                onCardClick(commitment.id);
              }}
            >
              View details
            </Button>
          </Box>
        </Stack>

        <Stack spacing={2} sx={{ flex: 1, display: { xs: "none", md: "flex" } }}>
          {processedPeriods.length > 0 && periodIndex < processedPeriods.length && (
            <CommitmentChart seriesData={processedPeriods[periodIndex].seriesData} currencyCode={currency} />
          )}
        </Stack>
      </Stack>
    </Card>
  );
});

CommitmentCard.displayName = "CommitmentCard";
