import { FormControl, InputLabel, MenuItem, Select, Stack, Typography } from "@mui/material";

import { type FormState } from "../types";

type Props = {
  formState: FormState;
  onRegionChange: (string) => void;
};

const regionOptions = ["US1", "US3", "US5", "EU1", "US1-FED", "AP1"];

export default function ParentOrgDetailsStep({ formState, onRegionChange }: Props) {
  return (
    <Stack data-cy="parent-org-details-step" spacing={4}>
      <Typography mb={4} variant="h3">
        Parent organization details
      </Typography>

      <FormControl sx={{ m: 1 }} size="small" fullWidth>
        <InputLabel required id="select-region-label">
          Parent org ID region
        </InputLabel>
        <Select
          labelId="select-region-label"
          label="Parent org ID region"
          value={formState.orgIdRegion}
          onChange={(event) => {
            onRegionChange(event.target.value);
          }}
        >
          {regionOptions.map((region) => (
            <MenuItem key={region} value={region}>
              {region}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
}
