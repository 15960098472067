import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  AccountManagerCompanies,
  CustomerModel,
  type CustomerModelPublicDashboardsModel,
  DashboardModel,
  DashboardType,
  type Renderer,
  UserNotification,
  type UserPrivateDashboard,
  type UserPublicAttachedDashboard,
  type Widget,
  type WidgetConfig,
  type WidgetDoc,
} from "@doitintl/cmp-models";
import {
  getBatch,
  getCollection,
  type ModelIdRef,
  type ModelReference,
  runTransaction,
  useCollectionData,
  useDocumentData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import { type FieldValue } from "firebase/firestore";
import castArray from "lodash/castArray";
import filter from "lodash/filter";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import groupBy from "lodash/groupBy";
import isEqual from "lodash/isEqual";
import maxBy from "lodash/maxBy";
import sortBy from "lodash/sortBy";

import { useIsCustomerUsingBQ } from "../Components/hooks/useBqLens";
import { useIsCustomerUsingMap } from "../Components/hooks/useMapLens";
import { useGetDashboardSubscriptionsQuery } from "../Components/NotificationSubscription/db";
import { GlobalDashboardId } from "../utils/common";
import { arrayRemove, arrayUnion, deleteField } from "../utils/firebase";
import mixpanel from "../utils/mixpanel";
import Permissions from "../utils/permissions";
import { reactRouterAllowedURLChars } from "../utils/string";
import { useAuthContext } from "./AuthContext";
import { useCustomerContext } from "./CustomerContext";
import { useCachedHook } from "./DynamicContext/useCachedHook";
import { isCustomerInPresentationMode } from "./useCustomerOrPresentationModeCustomer";
import { useUserContext } from "./UserContext";

type PrivateDashboard = ModelIdRef<UserPrivateDashboard> & { dashboardType: undefined };

type PublicDashboard = Omit<ModelIdRef<UserPublicAttachedDashboard>, "publicDashboardId"> & {
  name: string;
  widgets: Widget[];
};

export type Dashboard = (PrivateDashboard | PublicDashboard) & { reachedMaxWidgets?: boolean };

const accountDashboardId = GlobalDashboardId.Home;

const defaultWidgets = ["accountManagers", "entities", "activeCloudIncidents", "supportGraph"];

export const MAX_WIDGETS_PER_DASHBOARD = 30;
export const reachedMaxWidgetsMessage = `You’ve reached the limit of ${MAX_WIDGETS_PER_DASHBOARD} widgets for this dashboard. Remove some or create a new dashboard to add more.`;

export type DashboardsContextType = {
  dashboards: Dashboard[];
  defaultDashboard?: string | null;
  widgetsConfig: Record<string, WidgetConfig>;
  publicDashboards: ModelIdRef<CustomerModelPublicDashboardsModel>[];
  deleteDashboardByName: (name: string) => Promise<void>;
  detachDashboardByName: (name: string) => Promise<void>;
  attachDashboard: (dashboardId: string) => Promise<boolean>;
  canDeleteDashboard: (name: string) => boolean;
  setDefaultDashboard: (name: string) => Promise<void>;
  createDashboard: ({
    id,
    name,
    isPublic,
    allowToEdit,
    widgets,
    hidden,
  }: {
    id?: string;
    name: string;
    isPublic: boolean;
    allowToEdit?: boolean;
    widgets?: Widget[];
    hidden?: boolean;
  }) => Promise<string | undefined>;
  editDashboard: ({
    dashboardId,
    name,
    isPublic,
    allowToEdit,
  }: {
    dashboardId: string;
    name: string;
    isPublic: boolean;
    allowToEdit: boolean;
  }) => Promise<boolean>;

  clearDashboardWidgets: (dashboardId: string) => Promise<void>;
  removeWidgetFromDashboard: (
    dashboardId: string,
    name: string,
    options?: {
      isPublicDashboard?: boolean;
    }
  ) => Promise<void>;
  addWidgetToDashboard: (
    dashboardId: string,
    name: string,
    options?: {
      width?: number;
      isPublicDashboard?: boolean;
    }
  ) => Promise<void>;
  isDashboardNameValid: (name: string) => [false, "empty" | "chars" | "exists"] | [true, undefined];
};

export type WidgetDescription = {
  name: string;
  description: string;
  title: string;
  type?: "custom" | "preset" | "managed";
  reportType?: Renderer;
  owner?: string;
  timeUpdated?: Date;
};

type WidgetsConfig = Record<string, WidgetConfig>;

const useNonCachedDashboardsContext = () => {
  const { isDoitPartner, isDoitEmployee, partnerCompany, currentUser } = useAuthContext({ mustHaveUser: true });
  const { userOrganization, customer, customerOrPresentationModeCustomer } = useCustomerContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { isCustomerUsingBQ } = useIsCustomerUsingBQ();
  const { isCustomerUsingMap } = useIsCustomerUsingMap();

  const [defaultDashboardIds, setDefaultDashboardIds] = useState<string[] | null>();
  const [widgetsConfig, setWidgetsConfig] = useState<WidgetsConfig>({});

  const getSubscriptionsQuery = useGetDashboardSubscriptionsQuery({ useAllOrgs: true });

  const transform = useCallback(
    (data: WithFirebaseModel<WidgetDoc>) =>
      data.widgets.reduce(
        (acc, widget) => {
          acc[widget.key] = widget;
          return acc;
        },
        {} as DashboardsContextType["widgetsConfig"]
      ),
    []
  );

  const [data] = useDocumentData(getCollection(DashboardModel).doc("widgets"), {
    transform,
  });
  const widgetsConfigData = useMemo<WidgetsConfig>(() => data || {}, [data]);

  /*
   * Using custom deep memoizer as `useMemo` only does shallow comparison.
   */
  const useDeepCompareMemoize = <T>(value: T): T | undefined => {
    const ref = useRef<T>();
    if (!isEqual(value, ref.current)) {
      ref.current = value;
    }
    return ref.current;
  };

  const memoizedWidgetsConfigData = useDeepCompareMemoize(widgetsConfigData);

  useEffect(() => {
    setWidgetsConfig(memoizedWidgetsConfigData ?? {});
  }, [memoizedWidgetsConfigData]);

  const [dashboardsData, setDashboardsData] =
    useState<ModelIdRef<UserPrivateDashboard | UserPublicAttachedDashboard>[]>();

  const publicDashboardsDocRef = useMemo(
    () => getCollection(CustomerModel).doc(customer.id).collection("publicDashboards"),
    [customer.id]
  );

  const publicDashboardsQuery = useMemo(() => {
    if (!customer?.id) {
      return;
    }

    const query = publicDashboardsDocRef;
    // only retrieve dashboards for partners relevant to the partner
    if (isDoitPartner) {
      if (partnerCompany === AccountManagerCompanies.GCP) {
        return query.where("dashboardType", "in", [
          DashboardType.Pulse,
          DashboardType.GcpLens,
          DashboardType.GkeLensV2,
        ]);
      }

      if (partnerCompany === AccountManagerCompanies.AWS) {
        return query.where("dashboardType", "in", [DashboardType.Pulse, DashboardType.AwsLens]);
      }
    }

    return query;
  }, [customer?.id, isDoitPartner, partnerCompany, publicDashboardsDocRef]);

  const [presentationPublicDashboards] = useCollectionData(
    isCustomerInPresentationMode(customer)
      ? getCollection(CustomerModel)
          .doc(customerOrPresentationModeCustomer.id)
          .collection("publicDashboards")
          .where("dashboardType", "in", Object.values(DashboardType))
      : undefined,
    {
      idField: "id",
      refField: "ref",
    }
  );

  const [publicDashboardsDocs, publicDashboardsDocsLoading] = useCollectionData(publicDashboardsQuery, {
    idField: "id",
    refField: "ref",
  });

  const filteredPublicDashboards = useMemo<DashboardsContextType["publicDashboards"] | undefined>(() => {
    if (
      publicDashboardsDocsLoading ||
      !publicDashboardsDocs ||
      isCustomerUsingBQ === undefined ||
      isCustomerUsingMap === undefined
    ) {
      return undefined;
    }

    const showGKELens = publicDashboardsDocs.some((d) => d.dashboardType === DashboardType.GkeLensV2);
    const hasBq = publicDashboardsDocs.some((d) => d.dashboardType === DashboardType.BqLens);
    const hasMap = publicDashboardsDocs.some((d) => d.dashboardType === DashboardType.AWSMAPLens);
    const showBq = isCustomerUsingBQ && hasBq;
    const showMap = isCustomerUsingMap && hasMap;

    const isConditionalDashboard = (dashboardType?: DashboardType | null) =>
      dashboardType &&
      [DashboardType.BqLens, DashboardType.GkeLensV2, DashboardType.AWSMAPLens].includes(dashboardType);

    const shouldShowGKEDashboard = (dashboardType?: DashboardType | null) =>
      dashboardType === DashboardType.GkeLensV2 && showGKELens;

    const shouldShowBQDashboard = (dashboardType?: DashboardType | null) =>
      dashboardType === DashboardType.BqLens && showBq;

    const shouldShowMAPLensDashboard = (dashboardType?: DashboardType | null) =>
      dashboardType === DashboardType.AWSMAPLens && showMap;

    return filter(
      publicDashboardsDocs,
      (d) =>
        !isConditionalDashboard(d.dashboardType) ||
        shouldShowGKEDashboard(d.dashboardType) ||
        shouldShowBQDashboard(d.dashboardType) ||
        shouldShowMAPLensDashboard(d.dashboardType)
    ) as DashboardsContextType["publicDashboards"];
  }, [isCustomerUsingBQ, publicDashboardsDocs, publicDashboardsDocsLoading, isCustomerUsingMap]);

  const newDashboardsDocRef = useMemo(
    () =>
      currentUser?.uid
        ? getCollection(DashboardModel)
            .doc("customization")
            .collection("users")
            .doc(currentUser.uid)
            .collection("duc")
            .doc(customer.id)
            .collection("dashboards")
        : undefined,
    [currentUser.uid, customer.id]
  );

  const isAccountDashboardName = (name: string | undefined): boolean => Boolean(name && ["Account"].includes(name));

  useEffect(() => {
    if (!newDashboardsDocRef) {
      return;
    }

    const createAccountDashboard = async () => {
      const privateDashboard: UserPrivateDashboard = {
        dashboardType: undefined,
        name: "Account",
        sortNumber: 0,
        customerId: customer.id,
        widgets: defaultWidgets.map((name) => ({ name })),
      };
      await newDashboardsDocRef.doc(accountDashboardId).set(privateDashboard);
    };

    return newDashboardsDocRef.onSnapshot(async (querySnapshot) => {
      const result = querySnapshot.docs.reduce(
        (acc, doc) => {
          const data = doc.asModelData();

          if (!data.isPublic && isAccountDashboardName(data.name)) {
            acc.hasAccountDashboard = true;

            if (!isDoitEmployee && !isDoitPartner && userOrganization?.data.disableAccountDashboard) {
              return acc;
            }
          }

          if (data.defaultDashboard) {
            if (!acc.currentDefaultDashboardIds) {
              acc.currentDefaultDashboardIds = [doc.id];
            } else {
              acc.currentDefaultDashboardIds.push(doc.id);
            }
          }

          // attached dashboard
          if (data.isPublic) {
            acc.dashboards.push({
              id: doc.id,
              ref: doc.modelRef as ModelReference<UserPublicAttachedDashboard>,
              ...data,
              isPublic: true,
              publicDashboardId: data.publicDashboardId!,
            });
          } else {
            acc.dashboards.push({
              id: doc.id,
              ref: doc.modelRef as ModelReference<UserPrivateDashboard>,
              ...data,
              isPublic: false,
              widgets: data.widgets!,
              name: data.name!,
              dashboardType: undefined,
            });
          }

          return acc;
        },
        {
          currentDefaultDashboardIds: null,
          dashboards: [],
          hasAccountDashboard: false,
        } as {
          currentDefaultDashboardIds: string[] | null;
          dashboards: NonNullable<typeof dashboardsData>;
          hasAccountDashboard: boolean;
        }
      );

      setDashboardsData(() => {
        setDefaultDashboardIds(result.currentDefaultDashboardIds ?? null);

        return result.dashboards;
      });

      if (!result.hasAccountDashboard) {
        await createAccountDashboard();
      }
    });
  }, [customer.id, isDoitEmployee, isDoitPartner, newDashboardsDocRef, userOrganization?.data.disableAccountDashboard]);

  const dashboards = useMemo((): Dashboard[] => {
    if (dashboardsData === undefined || dashboardsData.length === 0 || filteredPublicDashboards === undefined) {
      return [];
    }

    const filterDashboardWidgets = (dashboards: DashboardsContextType["dashboards"]) => {
      dashboards.forEach((dashboard) => {
        if (!dashboard.widgets) {
          return;
        }

        dashboard.widgets = dashboard.widgets.filter((widget) => {
          if (widget.name in Permissions.widgetPermissionsRequired) {
            return userRoles.has(Permissions.widgetPermissionsRequired[widget.name]);
          }
          // disable support card for presentation mode customers
          if (isCustomerInPresentationMode(customer) && widget.name === "supportGraph") {
            return false;
          }
          // Needs to be === false since it is "undefined" on initial load since it has not received the organization yet.
          // This condition does not apply to when disableAccountDashboard is true since we only fetch data that they can see, if applied it will filter ALL widgets.
          return !(
            !userOrganization?.data.disableAccountDashboard &&
            userOrganization?.data.allowCustomDashboards === false &&
            widget.name !== "accountManagers"
          );
        });
      });
    };

    const fixDuplicateDashboardNames = (dashboards: DashboardsContextType["dashboards"]) => {
      const dashboardsByName = groupBy(dashboards, "name");
      Object.entries(dashboardsByName).forEach(([_dashboardName, dashboardsWithSameName]) => {
        if (dashboardsWithSameName.length === 1) {
          return;
        }

        dashboardsWithSameName.forEach((item, index) => {
          if (index === 0) {
            return;
          }
          const dashboard = find(dashboards, { id: item.id });
          if (dashboard) {
            dashboard.name = `${item.name} (${index + 1})`;
          }
        });
      });
    };

    const isUserHasPermissionsToDashboard = (dashboard: DashboardsContextType["publicDashboards"][number]) => {
      if (dashboard.requiredPermissions) {
        return dashboard.requiredPermissions.every((permission) => userRoles.permissions.has(permission));
      }
      return true;
    };

    const isUserOrganizationDashboard = (publicDashboard: DashboardsContextType["publicDashboards"][number]) => {
      if (userOrganization?.data?.dashboards && publicDashboard.dashboardType) {
        return userOrganization.data.dashboards.includes(publicDashboard.id);
      }

      // if the id is not in the global dashboards, then it is a custom dashboard
      return true;
    };
    // in case of presentation mode, we need to combine predefined public Doit dashboards with the public user defined dashboards
    let nonProcessedDashboards = filteredPublicDashboards;
    if (customer.presentationMode?.enabled) {
      nonProcessedDashboards = [
        ...(presentationPublicDashboards || []).filter((d) => d.dashboardType),
        ...filteredPublicDashboards.filter((d) => !d.dashboardType),
      ];
    }
    // this is a combined list of doit dashboards and public dashboards that the user has attached to
    const userPublicDashboards: PublicDashboard[] | undefined = nonProcessedDashboards.flatMap((publicDashboard) => {
      const userHasPermissionsToDashboard =
        isDoitEmployee ||
        (isUserHasPermissionsToDashboard(publicDashboard) && isUserOrganizationDashboard(publicDashboard));

      if (!userHasPermissionsToDashboard) {
        return [];
      }

      // if it is not a global dashboard
      // if the user is not the owner of the dashboard, then they will come from the attached dashboards
      if (
        !publicDashboard.dashboardType &&
        publicDashboard.ownerId !== currentUser.uid &&
        findIndex(dashboardsData, { id: publicDashboard.id }) === -1
      ) {
        return [];
      }

      const dashboard: PublicDashboard = {
        id: publicDashboard.id,
        ref: publicDashboard.ref.narrow<UserPublicAttachedDashboard>(),
        allowToEdit: publicDashboard.allowToEdit,
        dashboardType: publicDashboard.dashboardType,
        isPublic: true,
        name: publicDashboard.name,
        sortNumber: publicDashboard.sortNumber,
        widgets: publicDashboard.widgets,
        ownerId: publicDashboard.ownerId,
        heights: publicDashboard.heights,
        customerId: customer.id,
      };

      return [dashboard];
    });

    const userDashboards: Dashboard[] = dashboardsData
      .map((dashboard): Dashboard | null => {
        if (dashboard.isPublic) {
          const publicDashboardIndex = findIndex(userPublicDashboards, { id: dashboard.id });

          if (publicDashboardIndex > -1 && userPublicDashboards && !dashboard.dashboardType) {
            const publicDashboard = userPublicDashboards[publicDashboardIndex];
            userPublicDashboards.splice(publicDashboardIndex, 1);
            return {
              ...dashboard,
              ...publicDashboard,
              ref: publicDashboard.ref.narrow<UserPublicAttachedDashboard>(),
            } satisfies Dashboard;
          }

          return null;
        }

        return {
          ...dashboard,
          ref: dashboard.ref.narrow<UserPrivateDashboard>(),
          isPublic: false,
        } satisfies Dashboard;
      })
      .filter((dashboard): dashboard is Dashboard => dashboard !== null);

    const result: Dashboard[] = [
      ...userDashboards,
      ...(userPublicDashboards?.filter((d) => !find(userDashboards, { id: d.id })) ?? []),
    ];
    filterDashboardWidgets(result);
    fixDuplicateDashboardNames(result);

    result.forEach((dashboard) => {
      dashboard.reachedMaxWidgets = Boolean((dashboard.widgets || []).length > MAX_WIDGETS_PER_DASHBOARD);
    });

    return sortBy(result, ["sortNumber"]);
  }, [
    dashboardsData,
    filteredPublicDashboards,
    presentationPublicDashboards,
    customer,
    userOrganization?.data.disableAccountDashboard,
    userOrganization?.data.allowCustomDashboards,
    userOrganization?.data.dashboards,
    userRoles,
    isDoitEmployee,
    currentUser.uid,
  ]);

  const sortNumber = useMemo(() => {
    const maxSortDashboard = maxBy(dashboards, "sortNumber");
    return maxSortDashboard?.sortNumber ? maxSortDashboard.sortNumber + 1 : 1;
  }, [dashboards]);

  const findPrivateDashboard = useCallback(
    (nameOrId: string | string[]) => {
      const nameOrIds = castArray(nameOrId);
      for (const value of nameOrIds) {
        const dashboard = find(
          dashboards,
          (d) => value.localeCompare(d.name, undefined, { sensitivity: "base" }) === 0 || d.id === value
        );

        if (dashboard) {
          return dashboard;
        }
      }
    },
    [dashboards]
  );

  const isDashboardNameValid: DashboardsContextType["isDashboardNameValid"] = useCallback(
    (name: string) => {
      if (!name) {
        return [false, "empty"];
      }

      if (name.localeCompare("default", undefined, { sensitivity: "base" }) === 0) {
        return [false, "exists"];
      }

      if (name.trim() !== name) {
        return [false, "chars"];
      }

      if (!reactRouterAllowedURLChars(name)) {
        return [false, "chars"];
      }

      if (findPrivateDashboard(name)) {
        return [false, "exists"];
      }

      return [true, undefined];
    },
    [findPrivateDashboard]
  );

  const createDashboard = useCallback(
    async ({
      id,
      name,
      isPublic,
      allowToEdit,
      widgets,
    }: {
      id?: string;
      name: string;
      isPublic: boolean;
      allowToEdit?: boolean;
      widgets?: Widget[];
    }): Promise<string | undefined> => {
      const [isNameValid] = isDashboardNameValid(name);
      if (!isNameValid) {
        return;
      }
      if (isPublic) {
        const newPublicDashboardDoc = id ? publicDashboardsDocRef.doc(id) : publicDashboardsDocRef.newDoc();
        await newPublicDashboardDoc.set({
          customerId: customer.id,
          allowToEdit: allowToEdit ?? false,
          name,
          sortNumber,
          ownerId: currentUser.uid,
          widgets: widgets ?? [],
        });
        return newPublicDashboardDoc.id;
      }

      if (!newDashboardsDocRef) {
        return;
      }

      const privateDashboard: UserPrivateDashboard = {
        name,
        sortNumber,
        customerId: customer.id,
        widgets: widgets ?? [],
        dashboardType: undefined,
      };

      const newPrivateDashboardDoc = await newDashboardsDocRef.add(privateDashboard);
      return newPrivateDashboardDoc.id;
    },
    [isDashboardNameValid, newDashboardsDocRef, sortNumber, publicDashboardsDocRef, customer.id, currentUser.uid]
  );

  const findPublicDashboard = useCallback(
    (nameOrId: string) => find(filteredPublicDashboards, (d) => d.name === nameOrId || d.id === nameOrId),
    [filteredPublicDashboards]
  );

  const findDashboard = useCallback(
    (nameOrId: string, isPublicDashboard: boolean) => {
      if (isPublicDashboard) {
        return findPublicDashboard(nameOrId);
      }
      return findPrivateDashboard(nameOrId);
    },
    [findPrivateDashboard, findPublicDashboard]
  );

  const attachDashboard = useCallback(
    async (dashboardId: string) => {
      const publicDashboard = findPublicDashboard(dashboardId);

      if (!publicDashboard) {
        return false;
      }

      await newDashboardsDocRef?.doc(publicDashboard.id).narrow<UserPublicAttachedDashboard>().set({
        isPublic: true,
        customerId: customer.id,
        publicDashboardId: publicDashboard.id,
        sortNumber,
      });

      return true;
    },
    [customer.id, findPublicDashboard, newDashboardsDocRef, sortNumber]
  );

  const editDashboard = useCallback(
    async ({
      dashboardId,
      name,
      isPublic,
      allowToEdit,
    }: {
      dashboardId: string;
      name: string;
      isPublic: boolean;
      allowToEdit: boolean;
    }) => {
      if (!newDashboardsDocRef) {
        return false;
      }

      const [isValid, reason] = isDashboardNameValid(name);
      if (!isValid && reason !== "exists") {
        return false;
      }

      const dashboard = findPrivateDashboard(dashboardId);
      if (!dashboard) {
        return false;
      }

      const batch = getBatch();
      if (dashboard.isPublic !== isPublic) {
        const subscriptions = (await getSubscriptionsQuery({ dashboardPath: dashboard.ref.path }).get()).docs;
        if (dashboard.isPublic) {
          // convert public to private
          const privateDashboard: UserPrivateDashboard = {
            name,
            sortNumber: dashboard.sortNumber,
            customerId: customer.id,
            widgets: dashboard.widgets,
            dashboardType: undefined,
            heights: dashboard.heights,
          };

          batch.set(newDashboardsDocRef.doc(dashboardId), privateDashboard);
          batch.delete(publicDashboardsDocRef.doc(dashboardId));
          batch.delete(publicDashboardsDocRef.doc(dashboardId));

          subscriptions.forEach((subscription) => {
            batch.update(
              subscription.ref,
              // @ts-expect-error
              `selectedNotifications.${UserNotification.DashboardSubscription}.dashboardPath`,
              newDashboardsDocRef.doc(dashboardId).path
            );
          });
        } else {
          // convert private to public
          batch.set(publicDashboardsDocRef.doc(dashboard.id), {
            customerId: customer.id,
            allowToEdit,
            name,
            ownerId: currentUser.uid,
            widgets: dashboard.widgets,
            heights: dashboard.heights,
          });
          batch.set(newDashboardsDocRef.doc(dashboard.id), {
            isPublic: true,
            customerId: customer.id,
            sortNumber: dashboard.sortNumber,
          });

          subscriptions.forEach((subscription) => {
            batch.update(
              subscription.ref,
              // @ts-expect-error
              `selectedNotifications.${UserNotification.DashboardSubscription}.dashboardPath`,
              publicDashboardsDocRef.doc(dashboard.id).path
            );
          });
        }
      } else if (isPublic) {
        batch.update(publicDashboardsDocRef.doc(dashboardId), {
          allowToEdit,
          name,
        });
      } else {
        batch.update(newDashboardsDocRef.doc(dashboardId), {
          name,
        });
      }

      await batch.commit();

      return true;
    },
    [
      newDashboardsDocRef,
      isDashboardNameValid,
      findPrivateDashboard,
      getSubscriptionsQuery,
      customer.id,
      publicDashboardsDocRef,
      currentUser.uid,
    ]
  );

  const canDeleteDashboard = useCallback(
    (name: string) => {
      const dashboard = findPrivateDashboard(name);

      if (!dashboard) {
        return false;
      }

      return (dashboard.isPublic && dashboard.ownerId === currentUser.uid) || !dashboard.isPublic;
    },
    [currentUser.uid, findPrivateDashboard]
  );

  const deleteDashboardByName = useCallback(
    async (name: string) => {
      const dashboard = findPrivateDashboard(name);

      if (!dashboard || !newDashboardsDocRef) {
        return;
      }

      await runTransaction(async (tx) => {
        if (dashboard.isPublic) {
          if (dashboard.ownerId === currentUser?.uid) {
            // owner delete - should trigger cloud function that deletes the users who attached this dashboard

            tx.delete(getCollection(CustomerModel).doc(customer.id).collection("publicDashboards").doc(dashboard.id));
          }
        }

        tx.delete(newDashboardsDocRef.doc(dashboard.id));
      });

      mixpanel.track("dashboards.delete-dashboard");
    },
    [currentUser?.uid, findPrivateDashboard, newDashboardsDocRef, customer.id]
  );

  const detachDashboardByName = useCallback(
    async (name: string) => {
      const dashboard = findPrivateDashboard(name);

      if (!dashboard) {
        return;
      }

      await newDashboardsDocRef?.doc(dashboard.id).delete();

      mixpanel.track("dashboards.detach");
    },
    [findPrivateDashboard, newDashboardsDocRef]
  );

  const setDefaultDashboard = useCallback(
    async (name: string) => {
      if (!newDashboardsDocRef) {
        return;
      }

      const dashboard = findPrivateDashboard(name);

      if (!dashboard) {
        return;
      }

      const batch = getBatch();
      const defaultDashboards = dashboards.filter((dashboard) => dashboard.defaultDashboard);

      defaultDashboards.forEach((currentDashboard) => {
        batch.update(newDashboardsDocRef.doc(currentDashboard.id), { defaultDashboard: deleteField() });
      });

      batch.set(
        newDashboardsDocRef.doc(dashboard.id),
        { isPublic: dashboard.isPublic ? true : undefined, defaultDashboard: true },
        { merge: true }
      );

      await batch.commit();
    },
    [dashboards, newDashboardsDocRef, findPrivateDashboard]
  );

  const defaultDashboard = useMemo(() => {
    if (defaultDashboardIds === undefined || dashboards.length === 0) {
      return undefined;
    }

    const name = defaultDashboardIds ? findPrivateDashboard(defaultDashboardIds)?.name : null;

    if (!name) {
      const firstDashboardWithName = find(dashboards, (d) => !!d.name);

      return firstDashboardWithName?.name ?? null;
    }

    return name;
  }, [dashboards, defaultDashboardIds, findPrivateDashboard]);

  const updateDashboardWidgets = useCallback(
    async (dashboard: Pick<(typeof dashboards)[number], "id" | "isPublic">, fieldValue: FieldValue | []) => {
      if (!dashboard?.id || !newDashboardsDocRef) {
        return;
      }

      if (dashboard?.isPublic) {
        if (!publicDashboardsDocRef) {
          return;
        }

        await publicDashboardsDocRef.doc(dashboard.id).update({
          widgets: fieldValue,
        });
      } else {
        if (!dashboard) {
          return;
        }

        await newDashboardsDocRef.doc(dashboard.id).update({
          widgets: fieldValue,
        });
      }
    },
    [newDashboardsDocRef, publicDashboardsDocRef]
  );

  const clearDashboardWidgets = useCallback(
    async (dashboardId: string) => {
      const dashboard = findPrivateDashboard(dashboardId);

      if (!dashboard) {
        return;
      }

      const widgets = defaultWidgets.map((widgetName) => ({
        name: widgetName,
        cardWidth: 4,
        template: false,
        visible: true,
      }));

      if (newDashboardsDocRef) {
        await newDashboardsDocRef.doc(dashboard.id).update({
          widgets,
        });
      }
    },
    [findPrivateDashboard, newDashboardsDocRef]
  );

  const removeWidgetFromDashboard = useCallback(
    async (dashboardId: string, name: string, options?: { isPublicDashboard?: boolean }) => {
      const dashboard = findDashboard(dashboardId, options?.isPublicDashboard ?? false);

      if (!dashboard) {
        return;
      }

      const widget = find(dashboard.widgets, { name });

      if (widget) {
        await updateDashboardWidgets(dashboard, arrayRemove(widget));
      }
    },
    [findDashboard, updateDashboardWidgets]
  );

  const addWidgetToDashboard = useCallback(
    async (dashboardId: string, widgetName: string, options?: { width?: number; isPublicDashboard?: boolean }) => {
      const dashboard = findDashboard(dashboardId, options?.isPublicDashboard ?? false);

      if (!dashboard) {
        return;
      }

      await updateDashboardWidgets(
        dashboard,
        arrayUnion({
          name: widgetName,
          cardWidth: options?.width ?? null,
          template: false,
          visible: true,
        })
      );
    },
    [findDashboard, updateDashboardWidgets]
  );

  return useMemo<DashboardsContextType>(
    () => ({
      publicDashboards: filteredPublicDashboards ?? [],
      dashboards,
      widgetsConfig,
      canDeleteDashboard,
      deleteDashboardByName,
      detachDashboardByName,
      attachDashboard,
      defaultDashboard,
      setDefaultDashboard,
      createDashboard,
      editDashboard,
      clearDashboardWidgets,
      removeWidgetFromDashboard,
      addWidgetToDashboard,
      isDashboardNameValid,
    }),
    [
      filteredPublicDashboards,
      dashboards,
      widgetsConfig,
      canDeleteDashboard,
      deleteDashboardByName,
      detachDashboardByName,
      attachDashboard,
      defaultDashboard,
      setDefaultDashboard,
      createDashboard,
      editDashboard,
      clearDashboardWidgets,
      removeWidgetFromDashboard,
      addWidgetToDashboard,
      isDashboardNameValid,
    ]
  );
};

export function useDashboardsContext() {
  return useCachedHook(useNonCachedDashboardsContext);
}
