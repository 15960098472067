import { useState } from "react";

import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { Stepper } from "../../../../Components/Stepper";
import { type Step } from "../../../../Components/Stepper/Types";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useUserContext } from "../../../../Context/UserContext";
import { customersRoute } from "../../../../Navigation/core/utils";
import AccountsAndAppKeyStep from "./Steps/AccountsAndAppKeyStep";
import APIKeyStep from "./Steps/APIKeyStep";
import ParentOrgDetailsStep from "./Steps/ParentOrgDetailsStep";
import RolesAndPermissionsStep from "./Steps/RolesAndPermissionsStep";
import SubscriptionStep from "./Steps/SubscriptionStep/SubscriptionStep";
import ValidationStep from "./Steps/ValidationStep";
import { type FormState, type Subscription } from "./types";
import useSubmit from "./utils/useSubmit";

export default function DatadogOnboarding() {
  const history = useHistory();
  const customerId = useCustomerId();
  const { userRoles } = useUserContext();

  const [formState, setFormState] = useState<FormState>({
    roleCreationConfirmed: false,
    applicationKey: "",
    APIKey: "",
    orgIdRegion: "",
    connectionTestResult: null,
    connectionError: "",
    loading: false,
    productOptions: [],
  });

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);

  const [submit] = useSubmit({
    formState,
    onChange: setFormState,
    onChangeSubscriptions: setSubscriptions,
  });

  const { isDoitEmployee } = useAuthContext();
  const hasPermissionToOnboard = userRoles?.billingProfilesAdmin || isDoitEmployee;
  const showErrorSnackbar = useErrorSnackbar();

  const returnToHomePage = () => {
    history.push(customersRoute(customerId));
  };

  const clearSubscriptions = () => {
    setSubscriptions([]);
  };

  const handleChange = (field: "applicationKey" | "APIKey" | "orgIdRegion", value: string) => {
    setFormState({
      ...formState,
      [field]: value,
      connectionTestResult: null,
      connectionError: "",
      productOptions: [],
    });

    clearSubscriptions();
  };

  const steps: Step[] = [
    {
      children: (
        <RolesAndPermissionsStep
          roleCreationConfirmed={formState.roleCreationConfirmed}
          onRoleCreationConfirmChange={(confirmed: boolean) => {
            setFormState({ ...formState, roleCreationConfirmed: confirmed });
          }}
        />
      ),
      label: "Access",
      order: 0,
      required: true,
      state: formState.roleCreationConfirmed ? "complete" : "incomplete",
    },
    {
      children: (
        <AccountsAndAppKeyStep
          applicationKey={formState.applicationKey}
          onApplicationKeyChange={(value: string) => {
            handleChange("applicationKey", value);
          }}
        />
      ),
      label: "Accounts",
      order: 1,
      required: true,
      state: formState.applicationKey !== "" ? "complete" : "incomplete",
    },
    {
      children: (
        <APIKeyStep
          APIKey={formState.APIKey}
          onAPIKeyChange={(value: string) => {
            handleChange("APIKey", value);
          }}
        />
      ),
      label: "API Key",
      order: 2,
      required: true,
      state: formState.APIKey !== "" ? "complete" : "incomplete",
    },
    {
      children: (
        <ParentOrgDetailsStep
          formState={formState}
          onRegionChange={(value: string) => {
            handleChange("orgIdRegion", value);
          }}
        />
      ),
      label: "Parent org",
      order: 3,
      required: true,
      state: formState.orgIdRegion ? "complete" : "incomplete",
    },
    {
      children: (
        <ValidationStep formState={formState} onChange={setFormState} clearSubscriptions={clearSubscriptions} />
      ),
      label: "Validation",
      order: 4,
      required: true,
      state: formState.connectionTestResult && !formState.loading ? "complete" : "incomplete",
    },
  ];

  if (formState.productOptions.length) {
    steps.splice(5, 0, {
      children: (
        <SubscriptionStep
          subscriptions={subscriptions}
          onChange={setSubscriptions}
          productOptions={formState.productOptions}
        />
      ),
      label: "Subscription",
      order: 5,
      required: true,
      state: subscriptions.length ? "complete" : "incomplete",
    });
  }

  return (
    <Box mt={6} mb={9}>
      <Stepper
        loading={formState.loading}
        onCancel={returnToHomePage}
        onSubmit={() => {
          submit(subscriptions);
        }}
        nextButtonClickHandler={async () => {
          if (!hasPermissionToOnboard) {
            showErrorSnackbar("Permission Denied: Billing Profile Admin role required");
            return false;
          }

          return true;
        }}
        steps={steps}
        submitButtonLabel={formState.connectionTestResult ? "Finish" : undefined}
        contentSx={{
          mt: 4,
          mb: 3,
        }}
        footerMaxWidth={860}
        maxWidth={850}
      />
    </Box>
  );
}
