import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteIcon from "@mui/icons-material/Delete";
import { Stack } from "@mui/material";
import IconButton from "@mui/material/IconButton";

const ActionButtons = ({
  canDuplicate,
  canRemove,
  onDuplicateRule,
  onRemoveRule,
}: {
  canDuplicate: boolean;
  canRemove: boolean;
  onDuplicateRule: () => void;
  onRemoveRule: () => void;
}) => (
  <Stack direction="row">
    <IconButton onClick={onDuplicateRule} aria-label="copy" disabled={!canDuplicate}>
      <ContentCopyIcon color={!canDuplicate ? "disabled" : "action"} />
    </IconButton>
    <IconButton onClick={onRemoveRule} aria-label="delete" disabled={!canRemove}>
      <DeleteIcon color={!canRemove ? "disabled" : "action"} />
    </IconButton>
  </Stack>
);

export default ActionButtons;
