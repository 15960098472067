import { useCallback } from "react";

import { Stack } from "@mui/material";

import { EditTitleDialog } from "../../Dialog/EditTitleDialog";
import { useModalManager } from "../Common/CloudflowModalsProvider";
import { useCloudflowState } from "../Common/hooks/useCloudflowState";
import Title from "../Common/Title";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

export const Header = () => {
  const { updateNode, nodeConfig } = useNodeConfigurationContext();
  const { isModalVisible } = useModalManager();
  const { isPublished, isBlueprint } = useCloudflowState();

  const handleUpdateTitle = useCallback(
    (title: string) => {
      updateNode({ name: title });
    },
    [updateNode]
  );

  return (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <Title
        disabled={isPublished || isBlueprint}
        title={nodeConfig.name}
        placeholder="Add Node name"
        titleType="node"
      />
      {isModalVisible("editNodeTitle") && (
        <EditTitleDialog
          title={nodeConfig.name || ""}
          onConfirm={handleUpdateTitle}
          header="Edit node name"
          dialogType="editNodeTitle"
        />
      )}
    </Stack>
  );
};
