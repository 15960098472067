/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, TableCell, TableRow } from "@mui/material";

import { roundToPrecision } from "../../../../../utils/numbers";
import { useRowHighlighting } from "./hooks";

interface ResourceMetrics {
  cpuCoresRequest: number;
  cpuCoresLimits: number;
  memMiBRequest: number;
  memMiBLimits: number;
}

interface Container extends ResourceMetrics {
  name: string;
}

interface WorkloadsTableRowProps {
  row: any;
}

const sumByProperty = (array: Container[], property: keyof ResourceMetrics): number =>
  array.reduce((acc: number, item: Container) => acc + item[property], 0);

const WorkloadsTableRow = ({ row }: WorkloadsTableRowProps) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const rowHighlightingProps = useRowHighlighting();

  const containers: Container[] = Object.values(row.containers);

  const workloadContainersSummary: ResourceMetrics = useMemo(
    () => ({
      cpuCoresLimits: sumByProperty(containers, "cpuCoresLimits"),
      cpuCoresRequest: sumByProperty(containers, "cpuCoresRequest"),
      memMiBLimits: sumByProperty(containers, "memMiBLimits"),
      memMiBRequest: sumByProperty(containers, "memMiBRequest"),
    }),
    [containers]
  );

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const renderResourceMetricCells = (metric: ResourceMetrics) => (
    <>
      <TableCell align="right">{roundToPrecision(metric.cpuCoresRequest, 3)}</TableCell>
      <TableCell align="right">{roundToPrecision(metric.cpuCoresLimits, 3)}</TableCell>
      <TableCell align="right">{roundToPrecision(metric.memMiBRequest, 3)} Mi</TableCell>
      <TableCell align="right">{roundToPrecision(metric.memMiBLimits, 3)} Mi</TableCell>
    </>
  );

  return (
    <>
      <TableRow {...rowHighlightingProps}>
        <TableCell>
          <IconButton onClick={handleToggle} size="small">
            {isCollapsed ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          {row.name} ({containers.length})
        </TableCell>
        <TableCell>{row.workloadType}</TableCell>
        <TableCell>{row.namespace}</TableCell>
        <TableCell align="right">${Math.ceil(row.totalCost * 1000) / 1000}</TableCell>
        <TableCell>All</TableCell>
        {renderResourceMetricCells(workloadContainersSummary)}
      </TableRow>
      {!isCollapsed &&
        containers.map((container: Container) => (
          <TableRow {...rowHighlightingProps} key={container.name}>
            <TableCell sx={{ height: 47 }} /> {/* Same height as the collapse icon */}
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell />
            <TableCell>{container.name}</TableCell>
            {renderResourceMetricCells(container)}
          </TableRow>
        ))}
    </>
  );
};

export default WorkloadsTableRow;
