import { type Cluster, type KubernetesFeature } from "@doitintl/cmp-models";

import MultiClusterFeatureStatus from "./MultiClusterFeatureConnectionStatus";
import SingleClusterFeatureStatus from "./SingleClusterFeatureConnectionStatus";

interface FeatureStatusProps {
  selectedFeatures: KubernetesFeature[];
  feature: KubernetesFeature;
  clusters: Cluster[];
  loading: boolean;
}

const FeatureStatus = ({ feature, selectedFeatures, clusters, loading }: FeatureStatusProps) => {
  if (clusters.length === 1) {
    return <SingleClusterFeatureStatus feature={feature} cluster={clusters[0]} loading={loading} />;
  }

  return (
    <MultiClusterFeatureStatus
      feature={feature}
      selectedFeatures={selectedFeatures}
      clusters={clusters}
      loading={loading}
    />
  );
};

export default FeatureStatus;
