import { useMemo } from "react";

import { type BudgetConfig, type PublicAccess, type SlackChannel } from "@doitintl/cmp-models";
import isEqual from "lodash/isEqual";
import isEqualWith from "lodash/isEqualWith";

import { type FirestoreTimestamp, TimestampFromDate } from "../../../../utils/firebase";

type NotificationData = {
  recipients: string[];
  recipientsSlackChannels?: SlackChannel[];
  newRecipientsSlackChannels?: SlackChannel[];
  publicAccess: PublicAccess;
};

export type EqualityCheckProps = {
  current: { name: string; description: string; config: BudgetConfig; notificationData: NotificationData };
  initial: { name: string; description: string; config: BudgetConfig; notificationData: NotificationData };
  draft: boolean;
};

export const compareDates = (
  value1: string | Date | FirestoreTimestamp,
  value2: string | Date | FirestoreTimestamp
) => {
  if (!value1 || !value2) {
    return value1 === value2;
  }
  if (typeof value1 === "string" || value1 instanceof Date) {
    value1 = TimestampFromDate(new Date(value1));
  }
  if (typeof value2 === "string" || value2 instanceof Date) {
    value2 = TimestampFromDate(new Date(value2));
  }

  return value1.isEqual(value2);
};

export const getEqualityCheck = ({
  current,
  initial,
  draft,
}: EqualityCheckProps): { basic: boolean; advanced: boolean } => {
  const validate = (name: keyof typeof current | keyof typeof initial) => current[name] === initial[name];

  const basic = validate("name") && validate("description");
  const advanced =
    Object.entries(current.notificationData).every(([key, value]) =>
      isEqualWith(value, initial.notificationData[key], (curr, prev) => {
        switch (key) {
          case "recipients":
            return isEqual(curr, prev);
          case "recipientsSlackChannels":
          case "newRecipientsSlackChannels":
            return isEqual(
              curr?.map((v) => v.id),
              prev?.map((v) => v.id)
            );
          case "publicAccess":
            return isEqual(curr, prev);
          default:
            return false;
        }
      })
    ) &&
    Object.entries(current.config).every(([key, value]) =>
      isEqualWith(value, initial.config[key], (curr, prev) => {
        switch (key) {
          case "recipients":
          case "collaborators":
          case "renderer":
            return true;
          case "alerts":
            return Object.keys(curr).every((key) =>
              Object.keys(curr[key]).every((k) => (k === "amount" ? true : isEqual(curr[key][k], prev[key][k])))
            );
          case "scope":
            return isEqual(
              curr.map((v) => v.id),
              prev.map((v) => v.id)
            );
          case "startPeriod":
          case "endPeriod":
            if (draft && (!curr || !prev)) {
              return true;
            }
            return compareDates(curr, prev);
          case "filters":
          case "amount":
          case "metric":
          case "timeInterval":
          case "growthPerPeriod":
          case "type":
          case "originalAmount":
          case "currency":
          case "usePrevSpend":
          case "allowGrowth":
          case "dataSource":
          case "rollover":
          case "amortizedCost":
            return isEqual(curr, prev);
          default:
            return false;
        }
      })
    );
  return {
    basic,
    advanced,
  };
};

const useEqualityCheck = ({ current, initial, draft }: EqualityCheckProps) =>
  useMemo(
    () =>
      getEqualityCheck({
        current,
        initial,
        draft,
      }),
    [current, initial, draft]
  );

export default useEqualityCheck;
