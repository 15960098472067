import { useEffect, useMemo, useState } from "react";

import { type OperationPointer } from "@doitintl/cmp-models";
import { type FormikProps } from "formik";

import { type GCPCloudConnectData, useCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { type ProjectInfo } from "../../types";

export const DEFAULT_PROJECT_ID_KEY = "projectId";
export const PROJECT_ID_VARIATIONS = [DEFAULT_PROJECT_ID_KEY, "projectsId", "appsId", "project"];
export const PERMISSIONS_PROJECT_ID_KEY = "permissionsProjectId";

export function mapCloudConnectData(gcpCloudConnectData: GCPCloudConnectData[]) {
  return gcpCloudConnectData.flatMap((connect) => {
    const organizations =
      connect.data?.organizations?.map((org) => ({
        name: org.name,
        displayName: org.displayName,
        serviceAccount: connect.data?.clientEmail,
      })) || [];

    return {
      organizations,
      ...(connect.data?.projectId && {
        project: {
          id: connect.data.projectId,
          serviceAccount: connect.data.clientEmail,
        },
      }),
    };
  });
}

export function useMergedProjectIds(cloudConnectData: ReturnType<typeof mapCloudConnectData>, projects: ProjectInfo[]) {
  return useMemo(() => {
    const idsFromCloudConnect = cloudConnectData.map((item) => item.project?.id).filter(Boolean);

    const idsFromApi = projects.map((proj) => proj.projectId).filter(Boolean);

    return Array.from(new Set([...idsFromCloudConnect, ...idsFromApi]));
  }, [cloudConnectData, projects]);
}

export function getGcpPermission(operation: OperationPointer) {
  const idSegments = operation.id.split(".");
  const [resource, method] = idSegments.slice(-2);

  // Adjust the resource name for logging service
  const permissionResource = operation.service === "logging" && resource === "entries" ? "logEntries" : resource;

  return `${operation.service}.${permissionResource}.${method}`;
}

export type GCPConfigValues = {
  organization: string;
  serviceAccount: string;
  hierarchicalResource: GCPHierarchicalResource;
  [key: string]: string | null | undefined;
};

export enum GCPHierarchicalResource {
  ORGANIZATION = "organization",
  PROJECT = "project",
}

export function useGcpFormFields(
  customerId: string,
  initialOrganization: string,
  initialProjectId: string | null,
  initialServiceAccount: string,
  initialHierarchicalResource: GCPHierarchicalResource,
  projectIdFieldName: string,
  { setFormikState, validateForm, values }: FormikProps<GCPConfigValues>
) {
  const [rawCloudConnectData, , isCloudConnectDataLoaded] = useCloudConnectData(customerId);
  const cloudConnectData = useMemo(() => mapCloudConnectData(rawCloudConnectData), [rawCloudConnectData]);

  const [hierarchicalResource, setHierarchicalResource] =
    useState<GCPHierarchicalResource>(initialHierarchicalResource);
  const [organization, setOrganization] = useState<string>(initialOrganization);
  const [serviceAccount, setServiceAccount] = useState<string>(initialServiceAccount);
  const [projectId, setProjectId] = useState<string | null>(initialProjectId);

  const firstProjectData = useMemo(() => cloudConnectData.find((data) => data.project?.id), [cloudConnectData]);
  const firstOrgData = useMemo(
    () => cloudConnectData.find((data) => data.organizations.length > 0),
    [cloudConnectData]
  );
  const [projectIdInitialized, setProjectIdInitialized] = useState(false);

  useEffect(() => {
    if (!projectIdInitialized && isCloudConnectDataLoaded) {
      if (firstProjectData?.project?.id && !projectId) {
        setProjectId(firstProjectData.project.id);
      }
      setProjectIdInitialized(true);
    }
  }, [firstProjectData?.project?.id, projectIdInitialized, isCloudConnectDataLoaded, projectId]);

  const [organizationInitialized, setOrganizationInitialized] = useState(false);

  useEffect(() => {
    if (!organizationInitialized && isCloudConnectDataLoaded) {
      const defaultOrgName = firstOrgData?.organizations[0]?.name;
      if (defaultOrgName && !organization) {
        setOrganization(defaultOrgName);
      }
      setOrganizationInitialized(true);
    }
  }, [firstOrgData?.organizations, isCloudConnectDataLoaded, organizationInitialized, organization]);

  useEffect(() => {
    setFormikState((prev) => ({
      ...prev,
      values: {
        ...prev.values,
        ...(organization && { organization }),
        ...(serviceAccount && { serviceAccount }),
        ...(hierarchicalResource && { hierarchicalResource }),
        [projectIdFieldName]: projectId,
      },
    }));
  }, [organization, projectId, projectIdFieldName, serviceAccount, hierarchicalResource, setFormikState]);

  /* TODO: A quick fix for the form being invalid after hierarchicalResource is first set. Should remove when underlying issue is found*/
  useEffect(() => {
    validateForm();
  }, [values, validateForm]);

  useEffect(() => {
    if (projectId) {
      const foundForProject = cloudConnectData.find((data) => data.project?.id === projectId);
      if (foundForProject?.project?.serviceAccount) {
        setServiceAccount(foundForProject.project.serviceAccount);
        return;
      }
    }
    if (organization) {
      const foundForOrg = cloudConnectData.find((data) => data.organizations.some((org) => org.name === organization));
      if (foundForOrg) {
        const matchedOrg = foundForOrg.organizations.find((org) => org.name === organization);
        if (matchedOrg?.serviceAccount) {
          setServiceAccount(matchedOrg.serviceAccount);
        }
      }
    }
  }, [projectId, organization, cloudConnectData]);

  const memoizedReturn = useMemo(
    () => ({
      cloudConnectData,
      isCloudConnectDataLoaded,
      firstOrgData,
      firstProjectData,
      organization,
      setOrganization,
      serviceAccount,
      setServiceAccount,
      projectId,
      setProjectId,
      hierarchicalResource,
      setHierarchicalResource,
    }),
    [
      cloudConnectData,
      firstOrgData,
      firstProjectData,
      isCloudConnectDataLoaded,
      organization,
      setOrganization,
      serviceAccount,
      setServiceAccount,
      projectId,
      setProjectId,
      hierarchicalResource,
      setHierarchicalResource,
    ]
  );

  return memoizedReturn;
}
