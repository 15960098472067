import { Stack, Typography } from "@mui/material";

export const PLESErrorComponent = ({ isMonthError, errors }: { isMonthError: boolean; errors: string[] }) => {
  if (isMonthError || errors.length === 0) return null;

  return (
    <Stack
      sx={{
        bgcolor: "#FFF3F3",
        p: 3,
        borderRadius: 1,
        border: "1px solid #EF5350",
      }}
      spacing={1}
    >
      <Typography variant="subtitle2" color="error" sx={{ mb: 1 }}>
        Validation Errors:
      </Typography>
      {[...errors].sort().map((error, index) => (
        <Typography key={index} variant="body2" color="error">
          • {error}
        </Typography>
      ))}
    </Stack>
  );
};
