import { useEffect } from "react";

import * as FullStory from "@fullstory/browser";
import axios from "axios";

import { isFullstoryEnabled } from "../../constants";
import { consoleErrorWithSentry } from "../../utils";
import { useAuthContext } from "../AuthContext";

export function UserBasedSdkInit() {
  const { impersonate, currentUser, isDoitEmployee, isDoitPartner, tokenValue } = useAuthContext();

  useEffect(() => {
    if (currentUser?.uid && tokenValue) {
      axios
        .request({
          method: "get",
          url: "/api/auth/fullstory",
          headers: {
            Authorization: `Bearer ${tokenValue}`,
          },
        })
        .then((response) => {
          let displayName = currentUser.email;
          if (impersonate?.email) {
            displayName = `${impersonate.email} as ${displayName}`;
          }
          if (!isFullstoryEnabled || !response.data.token) {
            return;
          }

          // disable data capture for doers
          if (isDoitEmployee) {
            FullStory.shutdown();
            return;
          }

          FullStory.identify(response.data.token, {
            displayName,
            email: currentUser.email,
            doitEmployee_bool: isDoitEmployee,
            doitPartner_bool: isDoitPartner,
            project_str: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          });
        })
        .catch(consoleErrorWithSentry);
    }
  }, [currentUser?.email, currentUser?.uid, isDoitEmployee, isDoitPartner, impersonate?.email, tokenValue]);

  return null;
}
