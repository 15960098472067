import {
  type Dispatch,
  type FC,
  type ForwardedRef,
  forwardRef,
  type ReactNode,
  useCallback,
  useId,
  useImperativeHandle,
  useRef,
} from "react";

import { areReferencedNodeValuesEqual, isReferencedNodeValue } from "@doitintl/cloudflow-commons";
import { type ReferencedNodeValue, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/Add";
import { Button, FormControl, FormHelperText, InputLabel, OutlinedInput, Stack } from "@mui/material";

import { cmpBaseColors } from "../../../../../../../cmpBaseColors";
import { ThemeModes } from "../../../../../../../muiThemeTypes";
import { useToggle } from "../../../../../../../utils/useToggle";
import { getInitialValueForModel } from "../../../useApiActionParametersSchema";
import { type FieldCommonProps } from "../../../useFieldCommonProps";
import { ReferencedFieldChip } from "./ReferencedFieldChip";
import { ReferencedFieldMenu } from "./ReferencedFieldMenu";
import { useReferencedFieldContext } from "./useReferencedFieldContext";
import { type MenuItemData, useReferencedFieldMenuItems } from "./useReferencedFieldMenuItems";

export type ReferencedFieldWrapperFieldProps = Omit<FieldCommonProps, "autoComplete">;

export const ReferencedFieldWrapper: FC<{
  commonFieldProps: ReferencedFieldWrapperFieldProps;
  children?: ReactNode;
  disabled?: boolean;
  model: UnwrappedApiServiceModelDescriptor;
  rootReferencedNodeValue?: ReferencedNodeValue;
}> = forwardRef((props, forwardedRef: ForwardedRef<HTMLDivElement>) => {
  const { children, commonFieldProps, disabled, model, rootReferencedNodeValue, ...forwardedProps } = props;
  const [isMenuShown, showMenu, hideMenu] = useToggle(false);
  const { referenceableNodes } = useReferencedFieldContext();
  const inputId = useId();
  const helperTextId = useId();
  const anchorEl = useRef<HTMLDivElement>(null);

  useImperativeHandle(forwardedRef, () => anchorEl.current!);

  const { menuItems, currentlyDisplayedPath } = useReferencedFieldMenuItems(
    commonFieldProps.value,
    commonFieldProps.name
  );

  const setFieldValue = useCallback(
    (value: typeof commonFieldProps.value) => {
      commonFieldProps.onChange({
        target: {
          value,
          name: commonFieldProps.name,
        },
      });
    },
    [commonFieldProps]
  );

  const triggerOnBlur = useCallback(() => {
    commonFieldProps.onBlur({
      target: {
        name: commonFieldProps.name,
      },
    });
  }, [commonFieldProps]);

  const onAppendMenuItem: Dispatch<MenuItemData> = (menuItem) => {
    let value = commonFieldProps.value;
    if (menuItem.type === "node") {
      value = {
        referencedField: [],
        referencedNodeId: menuItem.nodeId,
      };
    } else if (isReferencedNodeValue(value)) {
      value = { ...value, referencedField: [...currentlyDisplayedPath, menuItem.name] };
    }

    if (!menuItem.hasContinuation) {
      hideMenu();
    }
    setFieldValue(value);
  };

  const onDeleteReferencedNodeValue = useCallback(() => {
    setFieldValue(rootReferencedNodeValue ?? getInitialValueForModel(model));
    triggerOnBlur();
  }, [model, rootReferencedNodeValue, setFieldValue, triggerOnBlur]);

  const onPopMenuItem = () => {
    if (currentlyDisplayedPath.length > 0) {
      const referencedField = Array.from(currentlyDisplayedPath);
      referencedField.length -= 1;
      setFieldValue({ ...commonFieldProps.value, referencedField });
    } else {
      setFieldValue(rootReferencedNodeValue ?? getInitialValueForModel(model));
    }
  };

  const disallowValueTrimming = areReferencedNodeValuesEqual(commonFieldProps.value, rootReferencedNodeValue);
  const disableDelete = !rootReferencedNodeValue && !getInitialValueForModel(model);

  if (referenceableNodes.length === 0 && !disabled) {
    return <>{children}</>;
  }
  return (
    <Stack
      ref={anchorEl}
      {...forwardedProps}
      sx={({ palette }) => ({
        display: "flex",
        flexFlow: "row nowrap",
        minWidth: 0,
        flexGrow: 1,
        ".MuiFormControl-root:not(:last-child) .MuiOutlinedInput-root": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        "&:hover .MuiInputBase-root:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled) .MuiOutlinedInput-notchedOutline, &:hover button:not(.Mui-disabled)":
          {
            borderColor: palette.action.active,
          },
      })}
    >
      {isReferencedNodeValue(commonFieldProps.value) || children === undefined ? (
        <FormControl fullWidth error={commonFieldProps.error && !disabled}>
          <InputLabel required={commonFieldProps.required} htmlFor={inputId}>
            {commonFieldProps.label}
          </InputLabel>
          <OutlinedInput
            onClick={showMenu}
            size="small"
            id={inputId}
            label={commonFieldProps.label}
            required={commonFieldProps.required}
            error={commonFieldProps.error}
            value="&nbsp;"
            readOnly
            startAdornment={
              <ReferencedFieldChip
                value={commonFieldProps.value}
                onDelete={disallowValueTrimming || disableDelete ? undefined : onDeleteReferencedNodeValue}
              />
            }
            aria-describedby={helperTextId}
            sx={{
              pr: "14px",
              cursor: "pointer",
            }}
            disabled={disabled}
            onBlur={triggerOnBlur}
          />
          {commonFieldProps.error && <FormHelperText id={helperTextId}>{commonFieldProps.helperText}</FormHelperText>}
        </FormControl>
      ) : (
        children
      )}
      <ReferencedFieldMenu
        open={isMenuShown}
        anchorEl={anchorEl.current}
        hideBackButton={disallowValueTrimming}
        onClose={() => {
          triggerOnBlur();
          hideMenu();
        }}
        menuItems={menuItems}
        onAppendMenuItem={onAppendMenuItem}
        onPopMenuItem={onPopMenuItem}
      />
      <Button
        variant="contained"
        color="inherit"
        disabled={disabled}
        sx={({ palette }) => ({
          minWidth: "unset",
          width: 40,
          height: 40,
          backgroundColor:
            palette.mode === ThemeModes.LIGHT ? cmpBaseColors.backgroundLight : palette.general.backgroundDark,
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderStyle: "solid",
          borderWidth: "1px 1px 1px 0",
          borderColor: palette.general.outlineBorder,
          transitionProperty: "background-color, box-shadow, color",
          "&:hover:not(.Mui-disabled)": {
            backgroundColor: palette.action.hover,
          },
        })}
        disableElevation
        onClick={showMenu}
      >
        <AddIcon sx={({ palette }) => ({ fill: palette.action.active })} />
      </Button>
    </Stack>
  );
});

ReferencedFieldWrapper.displayName = "ReferencedFieldWrapper";
