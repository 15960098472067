import { type CurrencyCode } from "@doitintl/cmp-models";
import { darken, lighten, useTheme } from "@mui/material/styles";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { formatValueWithCurrency } from "../../Components/hooks/useFormatter";
import { ThemeModes } from "../../muiThemeTypes";
import { getCurrencySymbol } from "../../utils/common";
import { type SeriesData } from "./types";

interface ChartProps {
  seriesData: SeriesData;
  currencyCode: CurrencyCode;
  marketplaceLimitAmount?: number;
}

export const CommitmentChart = ({ seriesData, currencyCode, marketplaceLimitAmount }: ChartProps) => {
  const theme = useTheme();
  const currencySymbol = getCurrencySymbol(currencyCode);

  // Create series array with required series
  const chartSeries = [
    {
      name: "Total eligible",
      data: seriesData.totalEligible,
      color: theme.palette.info.light,
      type: "area",
    },
    {
      name: "Commitment",
      data: seriesData.commitment,
      type: "line",
      color:
        theme.palette.mode === ThemeModes.LIGHT
          ? lighten(theme.palette.text.disabled, 0.2)
          : darken(theme.palette.text.disabled, 0.4),
    },
  ];

  const hasNonZeroMarketplaceData = seriesData.marketplace?.some((point) => point.y > 0);
  if (hasNonZeroMarketplaceData) {
    chartSeries.splice(1, 0, {
      name: "Marketplace",
      data: seriesData.marketplace,
      type: "area",
      color: theme.palette.success.main,
    });
  }

  return (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={true}
      options={{
        chart: {
          height: 320,
          backgroundColor: theme?.palette?.background.paper,
        },
        exporting: {
          enabled: false,
        },
        title: {
          text: "",
        },
        xAxis: {
          left: 38,
          type: "datetime",
          labels: {
            style: {
              color: theme?.palette?.text?.primary,
            },
          },
          accessibility: {
            rangeDescription: "",
          },
        },
        yAxis: {
          title: {
            text: "",
          },
          labels: {
            style: {
              color: theme?.palette?.text?.primary,
            },
          },
          plotLines:
            marketplaceLimitAmount && marketplaceLimitAmount > 0
              ? [
                  {
                    value: marketplaceLimitAmount,
                    color: theme.palette.divider,
                    width: 3,
                    zIndex: 3,
                    label: {
                      text: `${formatValueWithCurrency(marketplaceLimitAmount, 0, currencyCode, true)} Marketplace limit`,
                      align: "left",
                      style: {
                        color: theme.palette.text.secondary,
                      },
                    },
                  },
                ]
              : [],
        },
        tooltip: {
          shared: true,
          pointFormat: `<span style="color:{point.color}">\u25CF<b> {series.name}</b></span> : {point.y}<br/>`,
          valuePrefix: currencySymbol,
          valueDecimals: 0,
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          series: {
            animation: true,
          },
          area: {
            fillOpacity: 0.35,
          },
        },
        series: chartSeries,
        legend: {
          layout: "horizontal",
          enabled: true,
          itemStyle: {
            color: theme.palette.text.secondary,
            fontWeight: 400,
          },
        },
      }}
    />
  );
};
