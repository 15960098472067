import React from "react";

import { Autocomplete, TextField } from "@mui/material";
import { useField, useFormikContext } from "formik";

import { getTimeZoneSelectedKey } from "../../../utils/timeZone";
import TimezoneIOSList from "../../../utils/timezoneIOSList";
import { RequiredLabel } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function TimeZoneInput({ disabled }: SubscriptionInputProps) {
  const [timeZone, meta] = useField<SubscriptionFormValues["timeZone"]>("timeZone");
  const { setFieldValue, values } = useFormikContext<SubscriptionFormValues>();

  return (
    <Autocomplete
      options={Object.values(TimezoneIOSList)}
      value={TimezoneIOSList[timeZone.value as string] || null}
      onChange={(_event, newValue) => {
        const selectedKey = getTimeZoneSelectedKey(newValue);
        if (selectedKey) {
          setFieldValue("timeZone", selectedKey);
          setFieldValue("time", values.time.setZone(selectedKey, { keepLocalTime: true }));
          setFieldValue("startDate", values.startDate.setZone(selectedKey, { keepLocalTime: true }));
        }
      }}
      renderInput={(params) => (
        <TextField
          data-cy="timezone-input"
          {...params}
          label={RequiredLabel("Time zone")}
          variant="outlined"
          error={!!meta.error}
          fullWidth
        />
      )}
      fullWidth
      disabled={disabled}
    />
  );
}
