import CircleIcon from "@mui/icons-material/Circle";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import { type Theme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { type DateTime } from "luxon";

import { type InAppNotificationWithRead } from "../../../../Context/inAppNotifications/types";
import { getNotificationDisplayDate } from "./getNotificationDisplayDate";

export const cyIds = {
  item: (id: string): string => `notification-list-item-${id}`,
  markAsRead: (id: string): string => `notification-list-item-mark-as-read-${id}`,
  unreadIcon: (id: string): string => `notification-list-item-unread-icon-${id}`,
};

export const NotificationsListItem = ({
  n,
  handleClickNotification,
  markAsRead,
  now,
}: {
  n: InAppNotificationWithRead;
  handleClickNotification: () => Promise<void>;
  markAsRead: () => Promise<void>;
  now: DateTime;
}) => {
  const notificationDisplayDate = getNotificationDisplayDate(n, now);

  const unReadBGColor = (theme: Theme) => (theme.palette.mode === "dark" ? "#fafaff1a" : "#fafaff");

  return (
    <ListItem divider disablePadding sx={{ backgroundColor: n.read ? "" : unReadBGColor }} data-cy={cyIds.item(n.id)}>
      <ListItemButton onClick={() => handleClickNotification()} sx={{ p: 1.5 }}>
        <ListItemText
          slotProps={{
            secondary: {
              component: Box,
            },
          }}
          primary={
            <Stack
              direction="row"
              sx={{
                gap: 0.5,
                alignItems: "center",
                mb: 1.5,
              }}
            >
              <Typography
                sx={{
                  display: "inline-block",
                  padding: "0px 8px",
                  borderRadius: 4,
                  backgroundColor: n.color,
                  color: "#000",
                  fontWeight: 400,
                  font: "roboto",
                  letterSpacing: 1.6,
                  fontSize: 12,
                }}
              >
                {n.type}
              </Typography>

              {!n.read && (
                <CircleIcon
                  data-cy={cyIds.unreadIcon(n.id)}
                  sx={{
                    color: "primary.main",
                    fontSize: 10,
                  }}
                />
              )}

              {!n.read && (
                <Button
                  variant="text"
                  data-cy={cyIds.markAsRead(n.id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    markAsRead();
                  }}
                  sx={{
                    my: "-6px",
                    ml: "auto",
                    display: "none",
                    ".MuiListItemButton-root:hover &, .MuiListItemButton-root:focus-visible &": {
                      display: "inline-flex",
                    },
                  }}
                >
                  Mark as read
                </Button>
              )}
            </Stack>
          }
          secondary={
            <Stack sx={{ gap: 0.5 }}>
              <Typography variant="caption" color="text.secondary">
                {notificationDisplayDate}
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  color: "text.primary",
                  maxWidth: "100%",
                  overflow: "visible !important",
                  textOverflow: "unset !important",
                }}
              >
                {n.title}
              </Typography>
              {typeof n.description === "string" ? (
                <Typography variant="body2" sx={{ color: "text.secondary" }}>
                  {n.description}
                </Typography>
              ) : (
                n.description
              )}
            </Stack>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
