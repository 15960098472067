import { type Cluster, type KubernetesFeature, KubernetesFeatureStatus } from "@doitintl/cmp-models";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { CardContent, CircularProgress, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

import ClusterStatusDescription from "./ClusterStatusDescription";

interface SingleClusterFeatureStatusProps {
  feature: KubernetesFeature;
  cluster: Cluster;
  loading: boolean;
}

const SingleClusterFeatureStatus = ({ feature, cluster, loading }: SingleClusterFeatureStatusProps) => {
  const clusterFeature = cluster.features?.[feature.id] ?? null;

  if (!clusterFeature) {
    return <Typography variant="body2">Cluster feature not supported: {feature.id}</Typography>;
  }

  const featureIsHealthy = clusterFeature.status === KubernetesFeatureStatus.HEALTHY;

  const getIcon = () => {
    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (clusterFeature.status === KubernetesFeatureStatus.HEALTHY) {
      return <CheckCircleIcon color="success" />;
    }

    return <ErrorIcon color="error" />;
  };

  return (
    <>
      <Divider />
      <CardContent key={feature.id}>
        <div style={{ display: "flex", gap: 16 }}>
          <Box>{getIcon()}</Box>
          <Box sx={{ whiteSpace: "normal", wordBreak: "break-word", overflow: "hidden" }}>
            <Typography fontWeight={400} variant="body1">
              {feature.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              <ClusterStatusDescription featureIsHealthy={featureIsHealthy} issues={clusterFeature.issues} />
            </Typography>
          </Box>
        </div>
      </CardContent>
    </>
  );
};

export default SingleClusterFeatureStatus;
