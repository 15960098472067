import { type Dispatch, type SetStateAction } from "react";

import { type ContractSupport } from "@doitintl/cmp-models";
import isEmpty from "lodash/isEmpty";

import { type ContractStateType } from "../../types";
import { isAmazonWebServices } from "../../utils";
import { nonDigitsOutOfRange } from "./AccountBox/AccountBox";

export const validateAwsSupportStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  if (!isAmazonWebServices(state)) {
    return false;
  }

  const errors: Record<string, any> = {};

  if (!state.support || !state.disableNextFromAwsSupport) {
    errors.support = true;
  }

  for (const key in state.support) {
    if (key === "") {
      errors.support = true;
    }

    const payerAccount = state.support[key] as ContractSupport;

    if ((payerAccount.flatRate === null && payerAccount.discount === null) || payerAccount.mpaRef === null) {
      errors.support = true;
    }

    const val = payerAccount.discount ?? payerAccount.flatRate;
    if (val === null || nonDigitsOutOfRange(val)) {
      errors.support = true;
    }
  }

  setState({ ...state, errors });

  return isEmpty(errors);
};
