import { useMemo } from "react";

import { AppModel } from "@doitintl/cmp-models";
import { getCollection, useDocumentData } from "@doitintl/models-firestore";
import { Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../../Context/useCustomerOrPresentationModeCustomer";
import { LinearProgressWithLabel } from "../../ProgressWithLabel";
import { AvaIcon } from "./AvaIcon";

type Props = {
  customerId: string;
};
export default function LoadingCustomData({ customerId }: Props) {
  const [customerMetadata] = useDocumentData(
    getCollection(AppModel).doc("ava").collection("customersMetadata").doc(customerId)
  );

  const { customer } = useCustomerContext({ allowNull: true });

  const progress = useMemo<number | null>(() => {
    if (!customer) {
      return null;
    }

    const isPresentationMode = isCustomerInPresentationMode(customer);

    if (!customerMetadata || isPresentationMode) {
      return null;
    }

    if (typeof customerMetadata?.progress === "undefined") {
      return null;
    }

    if (!isFinite(customerMetadata.progress)) {
      return null;
    }

    if (customerMetadata.progress >= 99.1) {
      return null;
    }

    return Math.round(customerMetadata.progress);
  }, [customer, customerMetadata]);

  if (progress === null) {
    return null;
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      sx={{
        pb: 2,
        mt: 2,
        px: 2,
      }}
    >
      <AvaIcon />
      <Stack
        sx={{
          flexGrow: 1,
        }}
      >
        <Typography variant="caption">Fetching your data, you can still interact with Ava</Typography>
        <LinearProgressWithLabel value={progress} />
      </Stack>
    </Stack>
  );
}
