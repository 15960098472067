import {
  type Classification,
  type CurrencyCode,
  type CustomerSegment,
  EarlyAccessFeature,
  type ProductEnum,
  type TierModel,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";

import { type FirestoreTimestamp } from "../../../utils/firebase";

export type SettingsValuesType = {
  currency: CurrencyCode;
  domains: { name: string; isPrimary: boolean }[];
  customerName: string;
  linesPerInvoice: number | null;
  cloudProvider: ProductEnum | null;
  customerClassification: Classification | null;
  earlyAccessFeatures: EarlyAccessFeature[];
  skipRemedyBreach: boolean;
  customerSegment: CustomerSegment | undefined;
  navigatorTierTrialStartDate: FirestoreTimestamp | null;
  navigatorTierTrialEndDate: FirestoreTimestamp | null;
  navigatorTierId?: string;
  navigatorTrialTier?: ModelReference<TierModel>;
  navigatorTrialCustomLength?: number | null;
  solveTierTrialStartDate: FirestoreTimestamp | null;
  solveTierTrialEndDate: FirestoreTimestamp | null;
  solveTierId?: string;
  solveTrialTier?: ModelReference<TierModel>;
};

export type SettingsTouchedFieldsType = {
  [K in keyof SettingsValuesType]?: boolean;
};

export const earlyAccessFeaturesOptions = [
  EarlyAccessFeature.FSGCP_DISABLED,
  EarlyAccessFeature.FSAWS_DISABLED,
  EarlyAccessFeature.FSGCP_MARKETPLACE_DISABLED,
  EarlyAccessFeature.FLEXSAVE_AWS_STANDALONE,
  EarlyAccessFeature.GCP_CLOUD_CONNECT_V2,
  EarlyAccessFeature.DISABLE_CREDIT_CARD_FEES,
  EarlyAccessFeature.ACCELERATOR_PROGRAM,
  EarlyAccessFeature.ALLOCATIONS_PAGES,
  EarlyAccessFeature.NOTIFICATION_CENTER,
  EarlyAccessFeature.THREADS,
  EarlyAccessFeature.FSK8S,
  EarlyAccessFeature.FORECAST_BQ_ML,
  EarlyAccessFeature.AWS_MAP_LENS,
  EarlyAccessFeature.TRUSTED_ADVISOR_INSIGHTS,
  EarlyAccessFeature.COST_OPTIMIZATION_HUB_INSIGHTS,
  EarlyAccessFeature.GCP_RECOMMENDER_BIGQUERY_INSIGHTS,
  EarlyAccessFeature.DATADOG_CONNECT,
  EarlyAccessFeature.CLOUDDIAGRAMS,
  EarlyAccessFeature.AZURE_CLOUD_CONNECT,
];
