import { collection, type Reference } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

@collection("featureDemand")
export class FeatureDemandModel {
  feature!: string;

  customer!: Reference<CustomerModel>;

  data?: Record<string, unknown>;
}
