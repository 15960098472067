import {
  type AttributionFilter,
  type Collaborators,
  type DashboardModelAttributionModel,
  type PublicAccess,
} from "@doitintl/cmp-models";
import { type AxiosInstance, type Method } from "axios";

import { globalText, httpMethods } from "../../../assets/texts";
import mixpanel from "../../../utils/mixpanel";

export const deleteAllocations = async (
  api: AxiosInstance,
  customerId: string,
  allocationId: string[],
  mixpanelFrom?: string
) => {
  // TODO: refactor to segment tracking
  mixpanel.track("analytics.attributions.delete", { allocationId, ...(mixpanelFrom && { from: mixpanelFrom }) });
  return api.request({
    method: globalText.DELETE as Method,
    url: `/v1/customers/${customerId}/analytics/allocations`,
    data: { ids: allocationId },
  });
};

export const shareAllocation = async (
  api: AxiosInstance,
  customerId: string,
  allocationId: string,
  collaborators: Collaborators,
  role: PublicAccess
) => {
  await api.request({
    method: httpMethods.PATCH as Method,
    url: `/v1/customers/${customerId}/analytics/allocations/${allocationId}/share`,
    data: {
      collaborators,
      public: role,
    },
  });
};

export const createAllocation = async (
  api: AxiosInstance,
  customerId: string,
  data: {
    name: string;
    description: string;
    rules: { name: string; action: string; filters: AttributionFilter[]; formula: string; draft: boolean }[];
  }
) =>
  api.request({
    method: httpMethods.POST as Method,
    url: `/v1/customers/${customerId}/analytics/allocations`,
    data,
  });

export const updateAllocation = async (
  api: AxiosInstance,
  customerId: string,
  allocationId: string,
  data: Partial<DashboardModelAttributionModel>
) =>
  api.request({
    method: httpMethods.PUT as Method,
    url: `/v1/customers/${customerId}/analytics/allocations/${allocationId}`,
    data,
  });
