import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Button, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box, Stack } from "@mui/system";

import cloudflowVisualDark from "../../assets/cloudflow/cf-dark-img.jpg";
import cloudflowVisualLight from "../../assets/cloudflow/cf-light-img.jpg";
import { cloudflowTexts } from "../../assets/texts";
import { cmpBaseColors } from "../../cmpBaseColors";
import { FAQAccordion, originalSkin } from "../../Components/FAQ/FAQAccordion";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { CLOUDFLOW_CONTENT_MAX_WIDTH, faqs } from "./consts";

type CloudFlowOverviewProps = {
  onCreateCloudflow: () => void;
  createCloudFlowLoading: boolean;
};

export const CloudFlowOverview = ({ onCreateCloudflow, createCloudFlowLoading }: CloudFlowOverviewProps) => {
  const isDarkMode = useDarkThemeCheck();
  const cloudflowVisual = isDarkMode ? cloudflowVisualDark : cloudflowVisualLight;
  const shadowColor = isDarkMode ? "#FFFFFF0F" : "#0000001F";
  return (
    <Stack direction={{ xs: "column", sm: "row" }} justifyContent="space-between" mb={4} spacing={4} mx={1}>
      <Stack spacing={2} justifyContent="space-between" maxWidth={CLOUDFLOW_CONTENT_MAX_WIDTH} width={"100%"}>
        <Typography variant="h1" gutterBottom>
          {cloudflowTexts.DEFAULT_STATE.TITLE}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {cloudflowTexts.DEFAULT_STATE.INTRODUCTION}
        </Typography>
        <Stack>
          <Typography variant="body1" color="text.secondary">
            {cloudflowTexts.DEFAULT_STATE.FEATURES_INTRO}
          </Typography>
          <List sx={{ pl: 2, margin: 0, py: 0 }}>
            {cloudflowTexts.DEFAULT_STATE.FEATURE_LIST.map((item) => (
              <ListItem key={item} disablePadding>
                <ListItemText primary={`• ${item}`} sx={{ color: "text.secondary", margin: 0 }} />
              </ListItem>
            ))}
          </List>
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2} pb={1}>
          <Button variant="contained" color="primary" onClick={onCreateCloudflow} loading={createCloudFlowLoading}>
            {cloudflowTexts.DEFAULT_STATE.CREATE_FIRST_CLOUDFLOW}
          </Button>
          <Button
            variant="text"
            endIcon={<OpenInNewIcon />}
            href="https://help.doit.com/docs/cloudflow/"
            sx={{ mt: 1 }}
            target="_blank"
          >
            {cloudflowTexts.DEFAULT_STATE.LEARN_MORE}
          </Button>
        </Stack>
      </Stack>
      <Stack
        alignSelf={{ sm: "center", lg: "flex-start" }}
        maxWidth={CLOUDFLOW_CONTENT_MAX_WIDTH}
        justifyContent="center"
        p={1}
        boxShadow={`0px 4px 19px 2px ${shadowColor}`}
        borderRadius={5}
        width={"100%"}
      >
        <Box component="img" src={cloudflowVisual} alt="CloudFlow visual" maxWidth="100%" />
      </Stack>
    </Stack>
  );
};

export const CloudflowFAQ = () => {
  const theme = useTheme();
  const isDarkMode = useDarkThemeCheck();
  const { backgroundDark, backgroundLight } = cmpBaseColors;
  return (
    <Stack spacing={2} py={4} mx={2}>
      <Typography variant="h4" fontWeight={500}>
        {cloudflowTexts.DEFAULT_STATE.FAQ}
      </Typography>
      <FAQAccordion
        faqs={faqs}
        props={{
          ...originalSkin(theme),
          AccordionSummaryProps: {
            ...originalSkin(theme).AccordionSummaryProps,
            expandIcon: <ExpandMoreIcon />,
            sx: (theme) => ({
              backgroundColor: isDarkMode ? backgroundDark : backgroundLight,
              ...((originalSkin(theme).AccordionSummaryProps?.sx as object) || {}),
            }),
          },
          AccordionDetailsProps: {
            sx: {
              backgroundColor: isDarkMode ? backgroundDark : backgroundLight,
            },
          },
          QuestionTypographyProps: {
            sx: {
              variant: "body1",
              color: "text.primary",
            },
          },
        }}
      />
    </Stack>
  );
};
