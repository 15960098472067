import { useMemo } from "react";

import { ReportOrgMetadataModel } from "@doitintl/cmp-models";
import { getCollectionGroup, useCollectionData } from "@doitintl/models-firestore";

import { useAuthContext } from "../../../Context/AuthContext";
import { useOrgsContext } from "../../../Context/customer/OrgsProvider";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { getOrganizationRef } from "../../../Pages/CloudAnalytics/utilities";
import { getCachingKeys } from "../../../utils/cachingKeys";

export const useHasCloudAnalyticsMetadata = (): undefined | boolean => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();

  const { isDoitEmployee } = useAuthContext();
  const { userOrganization, organizationsLoading } = useOrgsContext();

  const query = useMemo(() => {
    if (!customerOrPresentationModeCustomer?.ref || isDoitEmployee === undefined || organizationsLoading) {
      return;
    }

    const orgRef = getOrganizationRef(isDoitEmployee, userOrganization, customerOrPresentationModeCustomer.ref.id);

    return getCollectionGroup(ReportOrgMetadataModel)
      .where("customer", "==", customerOrPresentationModeCustomer.ref)
      .where("organization", "==", orgRef)
      .limit(1);
  }, [customerOrPresentationModeCustomer?.ref, isDoitEmployee, organizationsLoading, userOrganization]);

  const [data, loading] = useCollectionData(query, {
    idField: "id",
    caching: true,
    cachingKeys: getCachingKeys(customerOrPresentationModeCustomer?.ref.id),
  });

  return useMemo(() => {
    if (loading || data?.length === undefined) {
      return undefined;
    }
    return data?.length > 0;
  }, [data, loading]);
};
