import { Box, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import CloudflowGraphicDark from "../../assets/cloudflow/Cloudflow-graphic-dark.svg";
import CloudflowGraphicLight from "../../assets/cloudflow/Cloudflow-graphic-light.svg";
import { cloudflowTexts } from "../../assets/texts";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { useFullScreen } from "../../utils/dialog";

const LandingPage = () => {
  const isDarkMode = useDarkThemeCheck();
  const { isMobile } = useFullScreen();
  const Graphic = isDarkMode ? CloudflowGraphicDark : CloudflowGraphicLight;

  return (
    <Box
      sx={{
        mx: -2,
        mt: -1,
        bgcolor: "general.backgroundDark",
      }}
    >
      <Container maxWidth="lg" sx={{ pb: 8, pt: 6 }}>
        <Grid container>
          <Grid
            size={{
              sm: 6,
              xs: 12,
            }}
          >
            <Typography variant="h1">{cloudflowTexts.TITLE}</Typography>
            <Box
              sx={{
                mb: 1,
              }}
            />
            <Box
              sx={{
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              CloudFlow enables you to automate process and workflows within your organization through an intuitive and
              user-friendly UI. This GenAI-powered, no-code automation platform allows you to put your FinOps processes
              on autopilot, reduce cloud expenses, and ensure compliance.
            </Typography>
            <Box
              sx={{
                mb: 2,
              }}
            />
            <Typography
              variant="body1"
              sx={{
                color: "text.secondary",
              }}
            >
              CloudFlow will let you:
              <Box component="ul" sx={{ pl: 4, m: 0 }}>
                <li>Schedule resources</li>
                <li>Automate tagging</li>
                <li>Take action on recommendations</li>
              </Box>
              ...and much more
            </Typography>
            <Box
              sx={{
                mb: 2,
              }}
            />
          </Grid>
          <Grid
            size={{
              sm: 6,
              xs: 12,
            }}
          >
            <Box
              component="img"
              src={Graphic}
              alt="CloudFlow graphic"
              sx={{
                display: "block",
                margin: "0 auto",
                p: isMobile ? 4 : undefined,
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LandingPage;
