import { cloudAnalyticsText, globalText } from "../../../../assets/texts";
import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../Components/FilterTable/filterTableUtils";
import { type AllocationRowItem } from "./AllocationBrowser";

const itemDescriptors: FilterTableItemDescriptor<AllocationRowItem>[] = [
  {
    headerText: allocationTxt.TABLE_HEADERS.NAME,
    value: "data.name",
    filterType: "string",
  },
  {
    headerText: allocationTxt.TABLE_HEADERS.TYPE,
    value: "type",
    filterType: "string",
  },
  {
    headerText: allocationTxt.TABLE_HEADERS.OWNER,
    value: "owner",
    filterType: "string",
  },
  {
    headerText: globalText.LAST_MODIFIED,
    value: "data.timeModified",
    filterType: "date",
  },
  {
    headerText: cloudAnalyticsText.CLOUD_ANALYTICS_RESOURCES_TABLE.TYPE,
    value: "data.type",
    filterType: "string",
    hiddenByDefault: true,
  },
  {
    headerText: globalText.TIME_CREATED,
    value: "data.timeCreated",
    filterType: "date",
    hiddenByDefault: true,
  },
  {
    headerText: "",
    value: "@",
  },
] as const;

export const { filters, headerColumns } = generateHeadersAndFilters(itemDescriptors);
