import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { CommitmentManagersModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import { Card, Stack } from "@mui/material";

import { getCurrencyByCode } from "../../utils/common";
import { CommitmentChart } from "./CommitmentChart";
import { CommitmentDataTable } from "./CommitmentDataTable";
import { CommitmentManagerHeader } from "./CommitmentManagerHeader";
import { CommitmentMetadataCards } from "./CommitmentMetadataCards";
import { getCurrentPeriodIndex, processCommitmentPeriods } from "./utils";

export const CommitmentManager = () => {
  const { commitmentManagerId } = useParams<{ commitmentManagerId: string }>();
  const history = useHistory();
  const [commitmentManager, setCommitmentManager] = useState<CommitmentManagersModel | undefined>(undefined);
  const [docRef, setDocRef] = useState<FirebaseModelReference<CommitmentManagersModel> | undefined>(undefined);
  const [periodIndex, setPeriodIndex] = useState<number | undefined>(undefined);
  const currencyCode = getCurrencyByCode(commitmentManager?.currency ?? "USD");

  const processedPeriods = useMemo(() => processCommitmentPeriods(commitmentManager), [commitmentManager]);

  const marketplaceLimitAmount = useCallback(
    (periodIndex: number) => {
      if (!commitmentManager) {
        return 0;
      }

      const period = commitmentManager.periods[periodIndex];
      return (Number(period.marketplaceLimitPercentage) / 100) * period.commitmentValue;
    },
    [commitmentManager]
  );

  useEffect(() => {
    if (!commitmentManagerId) {
      return;
    }

    return getCollection(CommitmentManagersModel)
      .doc(commitmentManagerId)
      .onSnapshot((snap) => {
        setDocRef(snap.ref);
        const data = snap.asModelData();

        if (!data) {
          history.push(history.location.pathname.split("/").slice(0, -1).join("/"));
          return;
        }

        setCommitmentManager(data);

        if (data.periods?.length) {
          setPeriodIndex(getCurrentPeriodIndex(data.periods));
        }
      });
  }, [commitmentManagerId, history]);

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      {commitmentManager && (
        <>
          <CommitmentManagerHeader
            name={commitmentManager.name}
            docRef={docRef}
            periods={commitmentManager.periods}
            selectedPeriod={periodIndex ?? 0}
            onPeriodChange={setPeriodIndex}
          />

          {periodIndex !== undefined &&
            docRef &&
            commitmentManager.periodsSpend?.[periodIndex]?.MonthlySpend &&
            processedPeriods[periodIndex] && (
              <>
                <Card>
                  <CommitmentChart
                    key={`chart-${periodIndex}`}
                    seriesData={processedPeriods[periodIndex].seriesData}
                    currencyCode={currencyCode}
                    marketplaceLimitAmount={marketplaceLimitAmount(periodIndex)}
                  />
                </Card>
                <CommitmentMetadataCards
                  commitmentManagerRef={docRef}
                  commitmentManager={commitmentManager}
                  periodIndex={periodIndex}
                  seriesData={processedPeriods[periodIndex].seriesData}
                  currencyCode={currencyCode}
                />
                <CommitmentDataTable
                  key={`table-${periodIndex}`}
                  tableData={processedPeriods[periodIndex].tableData}
                  monthlySpend={commitmentManager.periodsSpend[periodIndex].MonthlySpend}
                  currencyCode={currencyCode}
                  marketplaceLimitAmount={marketplaceLimitAmount(periodIndex)}
                />
              </>
            )}
        </>
      )}
    </Stack>
  );
};
