import { useCallback, useState } from "react";

import { AxiosError, type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { type AwsApiSupportCredentialsResponse } from "../../../api/endpoints";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";

export type Flow =
  | "aws-mpa-strategic"
  | "aws-mpa-administrator"
  | "aws-mpa-billing-and-support"
  | "support-request-passthrough";

export type GetLinkResponse = {
  console_url: string;
};

export type Role = {
  allowed: boolean;
  notAllowedReason: string;
};

export type Roles = Record<Flow, Role>;

type UserDetails = {
  organization: {
    organization_fields: {
      concedefy_disabled?: boolean;
    };
  };
};

export const useConcedefy = (): {
  getAuthorizedAWSAccountLink: (
    flow: Flow,
    accountId: string,
    customerId: string
  ) => Promise<AxiosResponse<GetLinkResponse>>;
  getAuthorizedCLICredentials: (
    flow: Flow,
    accountId: string,
    customerId: string
  ) => Promise<AwsApiSupportCredentialsResponse>;
  getRoles: (customerId: string) => Promise<AxiosResponse<Roles>>;
  getUserDetails: (customerId: string) => Promise<AxiosResponse<UserDetails>>;
} => {
  const api = useApiContext();

  const getAuthorizedAWSAccountLink = useCallback(
    (flow: string, accountId: string, customerId: string) =>
      api.request<GetLinkResponse>({
        method: "post",
        url: `v1/customers/${customerId}/assets/amazon-web-services/account-access`,
        data: {
          flow,
          customerId,
          awsAccountId: accountId,
        },
      }),
    [api]
  );

  const getAuthorizedCLICredentials = useCallback(
    (flow: string, accountId: string, customerId: string): Promise<AwsApiSupportCredentialsResponse> =>
      api.request({
        method: "post",
        url: `v1/customers/${customerId}/assets/amazon-web-services/account-access`,
        data: {
          flow,
          customerId,
          awsAccountId: accountId,
          json: true,
        },
      }),
    [api]
  );

  const getRoles = useCallback(
    (customerId: string) =>
      api.request<Roles>({
        method: "get",
        url: `v1/customers/${customerId}/assets/amazon-web-services/account-access/roles`,
      }),
    [api]
  );

  const getUserDetails = useCallback(
    (customerId: string) =>
      api.request({
        method: "get",
        url: `/support/customers/${customerId}/users`,
      }),
    [api]
  );

  return { getAuthorizedAWSAccountLink, getAuthorizedCLICredentials, getRoles, getUserDetails };
};

export const useAccessAccount = () => {
  const { getRoles, getAuthorizedAWSAccountLink } = useConcedefy();
  const [roles, setRoles] = useState<Roles>();
  const snackbar = useSnackbar();

  const syncRoles = async (customerId) => {
    const roles = await getRoles(customerId);
    setRoles(roles.data);
  };

  const onAccessAccount = useCallback(
    async (accessType: Flow, accountId: string, customerId: string) => {
      try {
        const res = await getAuthorizedAWSAccountLink(accessType, accountId, customerId);
        window.open(res.data.console_url, "_blank");
      } catch (e) {
        if (e instanceof AxiosError) {
          const message = e.response?.status === 401 ? e.response?.data?.error : "Failed to redirect to AWS console";
          snackbar.onOpen({
            autoHideDuration: 3000,
            message,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            withClose: true,
            variant: "error",
          });
        }
      }
    },
    [getAuthorizedAWSAccountLink, snackbar]
  );
  return { roles, syncRoles, onAccessAccount };
};
