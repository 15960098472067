import { LoadingButton } from "@mui/lab";
import { Button, Stack } from "@mui/material";

import useSegmentTrackEvent from "../../utils/useSegmentTrackEvent";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useAvaContext } from "./AvaContext";
import { AvaCreateTicket } from "./AvaCreateTicket";
import { useAvaDialogContext } from "./AvaDialogContextProvider";
import { CaseIQSuccess } from "./CaseIQSuccess";
import { useCreateCaseIQTicket } from "./createTicketHook";
import { useIsBigWidth } from "./hooks";

export const AvaCaseIQ = () => {
  const { ref, bigWidth } = useIsBigWidth();
  const { setCaseIQConversationId, caseIQState, caseIQConversationId } = useAvaCaseIQContext();
  const { onSubmit, isSubmitting, submitted, concedefyEmail } = useCreateCaseIQTicket();
  const { setConversationId } = useAvaContext();
  const { handleCloseAvaDialog } = useAvaDialogContext();
  const { trackEvent } = useSegmentTrackEvent();
  const { content, platform, cloudProduct, asset } = caseIQState;

  const isAssetNotRequired =
    platform === "cloud_management_platform" || platform === "credits___request" || platform === "finance___billing";

  return (
    <Stack
      ref={ref}
      sx={{
        backgroundColor: "general.backgroundDark",
        justifyContent: "space-between",
        overflow: "auto",
        flex: 1,
      }}
    >
      {submitted ? <CaseIQSuccess concedefyEmail={concedefyEmail} /> : <AvaCreateTicket bigWidth={bigWidth} />}
      <Stack
        p={1}
        alignItems={bigWidth ? "center" : "flex-end"}
        sx={{ borderTop: "1px solid", borderColor: "divider", backgroundColor: "background.paper" }}
      >
        <Stack width={bigWidth ? 980 : "auto"} alignItems="center" direction="row" justifyContent="flex-end">
          {!submitted && (
            <Button
              variant="text"
              sx={{ pr: 2 }}
              onClick={() => {
                setCaseIQConversationId();
                trackEvent("Case IQ ticket back to chat", {
                  source: "Ava",
                  feature: "support ticket",
                  conversationId: caseIQConversationId,
                });
              }}
            >
              Back to chat
            </Button>
          )}
          <LoadingButton
            variant="contained"
            color="primary"
            sx={{ width: "fit-content" }}
            onClick={() => {
              if (submitted) {
                handleCloseAvaDialog();
                setConversationId(undefined);
              } else {
                onSubmit();
              }
            }}
            loading={isSubmitting}
            disabled={!content || !platform || !cloudProduct || (!isAssetNotRequired && !asset)}
          >
            {submitted ? "Finish" : "Submit"}
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
