import { type Dispatch, type SetStateAction } from "react";

import isEmpty from "lodash/isEmpty";

import { type ContractStateType } from "../../types";
import { isLooker } from "../../utils";

export const validateLookerSubscriptionStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  if (!isLooker(state)) {
    return false;
  }
  const errors: Record<string, any> = {};

  if (!state.lookerInvoiceFrequency) {
    errors.lookerInvoiceFrequency = true;
  }

  setState({ ...state, errors });

  return isEmpty(errors);
};
