import { type JSX, useCallback, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { AssetTypeAwsStandalone, type EntityModel } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import TableCell from "@mui/material/TableCell";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import capitalize from "lodash/capitalize";
import { DateTime } from "luxon";

import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../../Context/AuthContext";
import { useIsPLESCustomer } from "../../../../Support/Components/TicketSteps/hooks";
import { type AmazonWebServicesAssetsType, type AnyAsset, type Asset } from "../../../../types";
import { type Handshake } from "../../../../types/Handshake";
import { truncate } from "../../../../utils/common";
import { useGetLoginUrl } from "../../../../utils/useGetLoginUrl";
import { useAccessAccount } from "../../../MPA/hooks/useConcedefy";
import { useGetCustomerMPAs } from "../../../MPA/hooks/useGetCustomerMPAs";
import {
  BasicAWSSupportTierText,
  buildBillingProfileString,
  doesAssetBelongToPayerAccountOne,
  SupportTierOverriddenOn,
} from "../../assetUtils";
import { RemoveDialog } from "../../Cards/components/RemoveDialog";
import { AwsDirectSupportFailureDialog } from "../../Dialogs/AwsDirectSupportFailureDialog";
import { SupportCLICredentials } from "./SupportCLICredentials";
import { linkLabelWithIcon, NotApplicable, NotAvailable } from "./typographics";

export type AWSAssetRowData = {
  id: string;
  asset: AnyAsset<AmazonWebServicesAssetsType> | null;
  assetName: string;
  supportTier: string | JSX.Element;
  payerAccountName: string | JSX.Element;
  accountId: string;
  billingProfile: string | JSX.Element;
  awsAccountId: string | JSX.Element;
  inviteStatus: string | JSX.Element;
  rootEmail: string;
  assetTag: string;
  shouldTooltipForTierShow?: boolean;
  isMPA?: boolean;
  bucketId?: string;
  entityId?: string;
};

export type AWSAssetExportData = {
  assetName: string;
  awsAccountId: string;
  entityId: string;
  payerAccountName: string | JSX.Element;
  payerAccountId: string | JSX.Element;
  rootEmail: string;
  inviteState: string | JSX.Element;
  billingProfile: string | JSX.Element;
  bucketId: string;
  bucketName?: string;
};

type Props = {
  row: AWSAssetRowData;
  onRemoveAsset: (asset: AnyAsset<AmazonWebServicesAssetsType>) => () => void;
};

export const inviteName = "Amazon Web Services Invite";

function isAsset(item: Asset<AmazonWebServicesAssetsType> | Handshake): item is Asset<AmazonWebServicesAssetsType> {
  return !("data" in item && "arn" in item.data);
}

export const getAssetName = (properties): string => {
  if (!properties) {
    // CY testing for asset page does not yet pass through properties.
    // Once those tests are built properly this guard clause can be removed.
    // JIRA https://doitintl.atlassian.net/browse/CMP-3413
    return NotAvailable;
  }
  const { accountId, friendlyName, name } = properties;
  if (friendlyName && friendlyName !== accountId) {
    return friendlyName;
  }
  return name || accountId;
};
const getAssetEmail = (properties): string =>
  properties.organization?.email ? properties.organization.email : NotAvailable;
const getPayerAccount = (properties) => {
  if (!properties) {
    return NotAvailable;
  }
  const org = properties.organization;
  if (!org) {
    return NotAvailable;
  }
  return typeof org.payerAccount === "string" ? org.payerAccount : org.payerAccount?.id;
};
const getPayerAccountName = (asset: Asset<AmazonWebServicesAssetsType>): string | JSX.Element => {
  if (!asset.data.properties) {
    return NotAvailable;
  }
  const org = asset.data.properties.organization;
  if (!org) {
    return NotAvailable;
  }
  return org.payerAccount.displayName;
};

const getSupportTier = (properties): string => {
  const supportTierFromAsset = properties?.support?.overridingSupportTier || properties?.support?.originalSupportTier;
  const supportTierExists = supportTierFromAsset && properties?.supportTier !== "Basic AWS support";
  return supportTierExists
    ? `${supportTierFromAsset
        .split(" ")
        .map((w) => capitalize(w))
        .join(" ")} support`
    : BasicAWSSupportTierText;
};

const areSupportTierOverrideAndOrigIdentical = (properties): boolean =>
  properties?.support?.overridingSupportTier !== properties?.support?.originalSupportTier;

const populateFromAsset = (
  asset: AnyAsset<AmazonWebServicesAssetsType>,
  entities: Record<string, EntityModel>,
  customerMPAIds: string[]
): AWSAssetRowData => {
  const { properties } = asset.data;
  let assetName: string = NotAvailable;
  let supportTier: string | JSX.Element = BasicAWSSupportTierText;
  let accountId: string = NotAvailable;
  let rootEmail: string = NotAvailable;
  let shouldTooltipForTierShow = false;
  if (properties) {
    assetName = getAssetName(properties);
    supportTier = getSupportTier(properties);
    accountId = properties.accountId;
    rootEmail = getAssetEmail(properties);
    shouldTooltipForTierShow = areSupportTierOverrideAndOrigIdentical(properties);
  }

  const entityModel = asset.data.entity?.id ? entities[asset.data.entity.id] : null;
  const builtBillingProfile = buildBillingProfileString(entityModel);
  const billingProfileOutput = truncate(builtBillingProfile, 20, false);
  const isMPA = customerMPAIds.includes(accountId);
  const assetTag = asset.data.tags?.length ? asset.data.tags[0] : NotAvailable;
  const bucketId = asset.data.bucket?.id;
  const entityId = asset.data.entity?.id;

  return {
    id: asset.id,
    asset,
    assetName,
    supportTier,
    payerAccountName: getPayerAccountName(asset),
    accountId,
    billingProfile: entityModel ? billingProfileOutput : NotAvailable,
    awsAccountId: getPayerAccount(properties),
    inviteStatus: NotApplicable,
    rootEmail,
    assetTag,
    shouldTooltipForTierShow,
    isMPA,
    bucketId,
    entityId,
  } as const;
};

const populateFromHandshake = (handshake: Handshake): AWSAssetRowData => {
  const { data } = handshake;
  return {
    id: data.id,
    asset: null,
    assetName: inviteName,
    supportTier: NotApplicable,
    payerAccountName: data.payerAccount.displayName,
    accountId: data.target,
    billingProfile: NotApplicable,
    awsAccountId: data.payerAccount.id,
    inviteStatus: data.state,
    rootEmail: NotApplicable,
    assetTag: NotApplicable,
  };
};

/**
 * Transforms an Asset or Handshake into row data ready to be used by the table component.
 * @param item Asset or handshake at hand
 * @param entities object containing entity models indexed by their IDs
 * @param customerMPAIds
 * @return rowData used _only_ within the assets filter table. For handling assets or invites elsewhere use Asset or Handshake types.
 */
export const transformToRowData = (
  item: AnyAsset<AmazonWebServicesAssetsType> | Handshake,
  entities: Record<string, EntityModel>,
  customerMPAIds: string[]
): AWSAssetRowData => (isAsset(item) ? populateFromAsset(item, entities, customerMPAIds) : populateFromHandshake(item));

const AwsAssetRow = ({ row, onRemoveAsset }: Props) => {
  const { isDoitEmployee, isDoitOwner } = useAuthContext({ mustHaveUser: true });
  const routeMatchURL = useRouteMatchURL();
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const getLoginUrl = useGetLoginUrl();

  const [ackAssetRemoveDialog, setAckAssetRemoveDialog] = useState(false);
  const [openSupportCLICredentials, setOpenSupportCLICredentials] = useState<boolean>(false);
  const [openAwsDirectSupportFailure, setOpenAwsDirectSupportFailure] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { roles, syncRoles, onAccessAccount } = useAccessAccount();
  const customerId = row.asset?.data.customer.id;
  const isPLESCustomer = useIsPLESCustomer(customerId);
  const { getAwsAssetMPAByAccountId } = useGetCustomerMPAs(false);

  const menuOptions: ThreeDotsMenuOption[] = [];
  const menuItemKey = (key: string) => `${row.id}-menuItem${key}`;

  const mpaInfo = getAwsAssetMPAByAccountId(String(row.accountId));

  const closeRemoveDialog = useCallback(() => {
    setAckAssetRemoveDialog(false);
  }, []);
  const handleRemoveDialogOK = useCallback(() => {
    row.asset && onRemoveAsset(row.asset)();
    closeRemoveDialog();
  }, [closeRemoveDialog, onRemoveAsset, row.asset]);
  const handleOpenSupportCLI = useCallback(() => {
    setOpenSupportCLICredentials(true);
  }, []);
  const handleCloseSupportCLI = useCallback(() => {
    setOpenSupportCLICredentials(false);
  }, []);

  const handleOpenAwsDirectSupportFailure = useCallback(() => {
    setOpenAwsDirectSupportFailure(true);
  }, []);
  const handleCloseAwsDirectSupportFailure = useCallback(() => {
    setOpenAwsDirectSupportFailure(false);
    setErrorMessage("");
  }, []);

  const createAssetMenu = () => {
    const pushSupportMenuIfNeeded = () => {
      if (row.asset?.data.type === AssetTypeAwsStandalone) {
        return;
      }

      const openAWSSupport = async () => {
        if (row.accountId && customerId) {
          try {
            const url = mpaInfo ? await getLoginUrl(mpaInfo.masterPayerAccountId, true, customerId) : "#";
            if (url !== "#") {
              window.open(url, "_blank");
            }
          } catch (error: any) {
            handleOpenAwsDirectSupportFailure();
            setErrorMessage(error.message);
          }
        }
      };

      menuOptions.push({
        label: linkLabelWithIcon("Go to AWS support"),
        type: "link",
        action: openAWSSupport,
        key: `${row.id}-awsDirectSupport`,
        disabled: mpaInfo?.supportModel === "partner-led" && !isPLESCustomer && !isDoitEmployee, // enabled for resold
      });
    };

    menuOptions.push({
      menuItem: (
        <MenuItem key={menuItemKey("awsAdvancedSettings")} component={RouterLink} to={`${routeMatchURL}/${row.id}`}>
          Advanced settings
        </MenuItem>
      ),
    });
    menuOptions.push({
      menuItem: <Divider key={menuItemKey("awsDivider")} />,
    });
    menuOptions.push({
      label: linkLabelWithIcon("AWS console"),
      type: "link",
      href: `https://${row.accountId}.signin.aws.amazon.com/console`,
      newTab: true,
      key: menuItemKey("awsConsole"),
    });

    if (isDoitEmployee) {
      pushSupportMenuIfNeeded();
    }
    if (isDoitOwner) {
      menuOptions.push({
        label: <Typography color="error">Remove asset</Typography>,
        key: menuItemKey("removeAsset"),
        action: () => {
          setAckAssetRemoveDialog(true);
        },
      });
    }
    if (isDoitEmployee) {
      menuOptions.push({
        menuItem: (
          <MenuItem key={menuItemKey("supportCLICredentials")} onClick={handleOpenSupportCLI}>
            Support CLI Credentials
          </MenuItem>
        ),
        action: () => {
          setOpenSupportCLICredentials(true);
        },
      });
    }

    if (customerId && isDoitEmployee && row.isMPA) {
      menuOptions.push({
        menuItem: (
          <MenuItem
            key={menuItemKey("accountAccessStrategic")}
            onClick={() => onAccessAccount("aws-mpa-strategic", String(row.accountId), customerId)}
            disabled={!roles?.["aws-mpa-strategic"].allowed}
          >
            {linkLabelWithIcon("Access MPA as Strategic")}
          </MenuItem>
        ),
      });
    }

    if (
      customerId &&
      isDoitEmployee && // TODO: remove this to enable access to customers
      row.isMPA
    ) {
      menuOptions.push({
        menuItem: (
          <MenuItem
            key={menuItemKey("accountAccessAdmin")}
            onClick={() => onAccessAccount("aws-mpa-administrator", String(row.accountId), customerId)}
            disabled={!roles?.["aws-mpa-administrator"].allowed}
          >
            {linkLabelWithIcon("Access MPA as Admin")}
          </MenuItem>
        ),
      });
    }
  };

  const isAssetByName = row.assetName !== inviteName;
  isAssetByName && createAssetMenu();

  const assetAccountIsntPayerOne = Boolean(
    row.asset?.data.properties.organization &&
      !doesAssetBelongToPayerAccountOne(row.asset?.data.properties.organization.payerAccount?.id)
  );

  const longTextLimit = 30;

  const overriddenDate = row.asset?.data?.properties?.support?.overriddenOn;
  const prettifiedDate = overriddenDate && DateTime.fromJSDate(overriddenDate?.toDate()).toFormat("dd MMM yyyy");
  const supportOverriddenTooltipTitle = overriddenDate && `${SupportTierOverriddenOn} ${prettifiedDate}`;

  const handleThreeDotMenuClick = () => {
    if (!roles && row.isMPA) {
      syncRoles(customerId);
    }
  };

  return (
    <>
      {isDoitOwner && ackAssetRemoveDialog && (
        <RemoveDialog
          open={ackAssetRemoveDialog}
          onCancel={closeRemoveDialog}
          onAccept={handleRemoveDialogOK}
          name={row.assetName.toString()}
        />
      )}
      <CellsWrapper>
        <TableCell data-cy="assetName">
          <>
            <Tooltip title={row.assetName.length > longTextLimit ? row.assetName : undefined} arrow placement="right">
              <Typography
                component={isAssetByName ? RouterLink : "span"}
                to={isAssetByName ? `${routeMatchURL}/${row.id}` : undefined}
                color="inherit"
                noWrap={mdUp}
                variant="body2"
                sx={{ display: "inline-block", maxWidth: "30ch" }}
              >
                {row.assetName}
              </Typography>
            </Tooltip>
            {assetAccountIsntPayerOne && (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Typography color="textSecondary" noWrap={mdUp} variant="body2">
                  {row.isMPA ? "MPA / " : ""}
                  {row.supportTier}
                </Typography>
                {overriddenDate && row.shouldTooltipForTierShow && (
                  <Tooltip title={supportOverriddenTooltipTitle} sx={{ ml: 0.5, mt: 0.3, color: "text.secondary" }}>
                    <InfoOutlinedIcon color="primary" fontSize="inherit" />
                  </Tooltip>
                )}
              </Box>
            )}
          </>
        </TableCell>
        <TableCell data-cy="accountId">
          <Typography variant="body2" component="span" noWrap>
            {row.accountId}
          </Typography>
        </TableCell>
        {isDoitEmployee && (
          <TableCell
            data-cy="payerAccountName"
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
              },
            }}
          >
            <Typography variant="body2" component="span" noWrap>
              {row.payerAccountName}
            </Typography>
          </TableCell>
        )}
        {isDoitEmployee && (
          <TableCell
            data-cy="payerAccountId"
            sx={{
              display: {
                xs: "none",
                sm: "table-cell",
              },
            }}
          >
            <Typography variant="body2" component="span">
              {row.awsAccountId}
            </Typography>
          </TableCell>
        )}
        <TableCell
          data-cy="rootEmail"
          sx={{
            display: {
              xs: "none",
              lg: "table-cell",
            },
          }}
        >
          <Tooltip title={row.rootEmail.length > longTextLimit ? row.rootEmail : undefined} arrow placement="right">
            <Typography variant="body2" component="span" noWrap sx={{ display: "inline-block", maxWidth: "30ch" }}>
              {row.rootEmail}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          data-cy="inviteState"
          sx={{
            display: {
              xs: "none",
              xl: "table-cell",
            },
          }}
        >
          <Typography variant="body2" component="span">
            {row.inviteStatus}
          </Typography>
        </TableCell>
        <TableCell
          data-cy="billingProfile"
          sx={{
            display: {
              xs: "none",
              xl: "table-cell",
            },
          }}
        >
          <Typography variant="body2" component="span">
            {row.billingProfile}
          </Typography>
        </TableCell>
        <TableCell
          data-cy="assetTag"
          sx={{
            display: {
              xs: "none",
              xl: "table-cell",
            },
          }}
        >
          <Typography variant="body2" component="span">
            {row.assetTag}
          </Typography>
        </TableCell>
        <TableCell padding="checkbox" onClick={handleThreeDotMenuClick} data-cy="three-dots">
          {menuOptions.length !== 0 && <ThreeDotsMenu options={menuOptions} size="small" />}
        </TableCell>
      </CellsWrapper>
      {openSupportCLICredentials && (
        <SupportCLICredentials
          accountId={row.accountId.toString()}
          open={openSupportCLICredentials}
          onClose={handleCloseSupportCLI}
        />
      )}
      {openAwsDirectSupportFailure && (
        <AwsDirectSupportFailureDialog
          error={errorMessage}
          open={openAwsDirectSupportFailure}
          onClose={handleCloseAwsDirectSupportFailure}
        />
      )}
    </>
  );
};

export default AwsAssetRow;
