import Chip, { type ChipProps } from "@mui/material/Chip";

import { type RampPlanStatus } from "../types";

type StatusChipConfig = Record<
  RampPlanStatus,
  {
    color: ChipProps["color"];
    label: string;
  }
>;

export const StatusChip = ({ status = "active", ...props }: { status: RampPlanStatus }) => {
  const statusChipConfig: StatusChipConfig = {
    active: {
      color: "success",
      label: "Active",
    },
    inactive: {
      color: "error",
      label: "Inactive",
    },
    notStarted: {
      color: "default",
      label: "Not Started",
    },
  };

  return (
    <Chip variant="status" color={statusChipConfig[status].color} label={statusChipConfig[status].label} {...props} />
  );
};
