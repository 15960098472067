import { Box } from "@mui/material";

import { useCloudflowState } from "./hooks/useCloudflowState";

const DisablePublishedFlowGuard = ({ children }) => {
  const { isPublished, isBlueprint } = useCloudflowState();

  if (!isPublished && !isBlueprint) {
    return children;
  }
  return (
    <Box
      role="group"
      aria-disabled="true"
      sx={{
        opacity: 0.5,
        pointerEvents: "none",
      }}
    >
      {children}
    </Box>
  );
};

export default DisablePublishedFlowGuard;
