import { Link, useHistory } from "react-router-dom";
import { Link as MUILink } from "@mui/material";

import { Alert } from "../../../Components/Alerts";
import { useCustomerContext } from "../../../Context/CustomerContext";

interface AllocationsMigrationAlertProps {
  isEditPage?: boolean;
  text?: string;
  resourceId?: string;
}
export const AllocationsMigrationAlert = ({ isEditPage = false, text, resourceId }: AllocationsMigrationAlertProps) => {
  const { customer } = useCustomerContext();
  const history = useHistory();

  const docsUrl = "https://help.doit.com/docs/cloud-analytics/allocations";

  const buttonProps = isEditPage
    ? {
        text: "View in Allocations",
        onClick: () => {
          history.push(`/customers/${customer.id}/analytics/allocations/${resourceId}`);
        },
      }
    : {
        text: "Read more",
        onClick: () => window.open(docsUrl, "_blank"),
      };

  const content = isEditPage ? (
    text
  ) : (
    <>
      We've combined Attributions and Attribution Groups into a single, unified experience called{" "}
      <MUILink component={Link} to={`/customers/${customer.id}/analytics/allocations`} color="inherit">
        Allocations
      </MUILink>
      . You can now access all previous functionality within Allocations.
    </>
  );

  return (
    <Alert
      severity="warning"
      buttonProps={{
        color: "inherit",
        sx: { whiteSpace: "nowrap" },
        ...buttonProps,
      }}
    >
      {content}
    </Alert>
  );
};

export default AllocationsMigrationAlert;
