import { type Timestamp } from "@doitintl/models-types";

export const getDatetimeAndDiffFromTimestamp = (timestamp?: Timestamp): { exactDatetime: string; timeAgo: string } => {
  if (!timestamp) {
    return { exactDatetime: "N/A", timeAgo: "N/A" };
  }

  const date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6));
  const exactDatetime = date.toLocaleString();
  const now = new Date();
  const timeDiff = Math.abs(now.getTime() - date.getTime());

  const getTimeAgo = (ms: number) => {
    const seconds = Math.floor(ms / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) return `${days} day${days > 1 ? "s" : ""} ago`;
    if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
  };

  const timeAgo = getTimeAgo(timeDiff);
  return { exactDatetime, timeAgo };
};

export const getHoursSinceTimestamp = (timestamp: Timestamp | undefined): number => {
  if (!timestamp) return 0;

  const date = new Date(timestamp.seconds * 1000 + Math.floor(timestamp.nanoseconds / 1e6));
  const now = new Date();
  const timeDiff = Math.abs(now.getTime() - date.getTime());
  return timeDiff / 1000 / 60 / 60;
};
