import { createContext, type FC, type ReactNode, useCallback, useContext, useEffect, useState } from "react";

import { getModelByPath } from "@doitintl/cloudflow-commons";
import {
  type CloudFlowNodeType,
  ModelType,
  type NodeConfigTransformParameters,
  type NodeTransformation,
  type NodeTransformationType,
  type ReferencedNodeValue,
} from "@doitintl/cmp-models";
import { Form, Formik } from "formik";
import noop from "lodash/noop";
import * as yup from "yup";

import { type HandleUpdateNodeFn } from "../../../../types";
import { type NodeWitOutputModel } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { FormChangesListener } from "../../../Common/FormChangesListener";
import { useErrorUpdates, useInitialErrors } from "../hooks";
import { baseTransformationSchema } from "./TransformationActionPicker";

export type TransformationNodeFormValues = {
  referencedNodeField: ReferencedNodeValue | undefined;
  transformation: NodeTransformation | null;
  type: NodeTransformationType | null;
};

export type TransformationNodeFormContext = {
  referenceableNodes: NodeWitOutputModel[];
  setTransformationSchema: <T extends yup.Schema = yup.AnyObjectSchema>(schema: T) => void;
};

const TransformationNodeFormContext = createContext<TransformationNodeFormContext>({
  referenceableNodes: [],
  setTransformationSchema: noop,
});

function generateTransformationNodeSchema(referenceableNodes: NodeWitOutputModel[]) {
  return yup
    .object({
      referencedNodeField: yup
        .object({
          referencedNodeId: yup.string().required().default(""),
          referencedField: yup.array().of(yup.string().required()).default([]).required(),
        })
        .test(
          "referenced-field-model",
          "Referenced field must reference a field with type structure or list of structures",
          (value) => {
            const referencedNodeModel = referenceableNodes.find(({ id }) => id === value.referencedNodeId)?.outputModel;
            if (referencedNodeModel === undefined) {
              return false;
            }
            try {
              const referencedModel = getModelByPath(referencedNodeModel, value.referencedField);
              return (
                (referencedModel.type === ModelType.LIST &&
                  referencedModel.member.model.type === ModelType.STRUCTURE) ||
                referencedModel.type === ModelType.STRUCTURE
              );
            } catch (error) {
              return false;
            }
          }
        ),
    })
    .concat(baseTransformationSchema)
    .noUnknown();
}

function generateFormInput(
  nodeId: string,
  nodeParameters: NodeConfigTransformParameters
): { nodeId: string; initialValues: TransformationNodeFormValues } {
  return {
    nodeId,
    initialValues: {
      referencedNodeField: {
        referencedNodeId: nodeParameters?.referencedNodeId,
        referencedField: nodeParameters?.referencedField,
      },
      type: nodeParameters?.transformations?.[0]?.type ?? null,
      transformation: nodeParameters?.transformations?.[0] ?? null,
    },
  };
}

export function useTransformationNodeSchemaContext() {
  return useContext(TransformationNodeFormContext);
}

export const TransformationNodeForm: FC<{
  children: ReactNode;
  nodeId: string;
  nodeParameters: NodeConfigTransformParameters;
  onNodeConfigChange: (values: Parameters<HandleUpdateNodeFn<CloudFlowNodeType.TRANSFORMATION>>[1]) => void;
  referenceableNodes: NodeWitOutputModel[];
}> = ({ children, nodeId, nodeParameters, onNodeConfigChange, referenceableNodes }) => {
  const [formInput, setFormInput] = useState(generateFormInput(nodeId, nodeParameters));

  useEffect(() => {
    setFormInput((current) => (current.nodeId === nodeId ? current : generateFormInput(nodeId, nodeParameters)));
  }, [nodeParameters, nodeId]);

  const [validationSchema, setValidationSchema] = useState<yup.Schema>(
    generateTransformationNodeSchema(referenceableNodes)
  );

  const [isTransformationValid, setIsTransformationValid] = useState<boolean>();

  useErrorUpdates<CloudFlowNodeType.TRANSFORMATION>({
    isValid: !!isTransformationValid,
    errorKey: "param_error",
    errorMessage: "Invalid params",
    isFormLoading: isTransformationValid === undefined,
  });

  const [context, setContext] = useState<TransformationNodeFormContext>({
    referenceableNodes,
    setTransformationSchema: (transformation: yup.Schema) => {
      setValidationSchema((currentSchema) => currentSchema.concat(yup.object({ transformation })));
    },
  });
  useEffect(() => {
    setValidationSchema((currentSchema) => currentSchema.concat(generateTransformationNodeSchema(referenceableNodes)));
    setContext((context) => ({
      ...context,
      referenceableNodes,
    }));
  }, [referenceableNodes]);

  const onValuesChange = useCallback(
    ({
      referencedNodeField,
      transformation,
      type,
    }: {
      referencedNodeField: ReferencedNodeValue | undefined;
      transformation: NodeTransformation;
      type: NodeTransformationType;
    }) => {
      onNodeConfigChange((prevNodeConfig) => {
        const currentReferencedNode = referenceableNodes.find((n) => n.id === referencedNodeField?.referencedNodeId);
        const previousReferencedNode = referenceableNodes.find(
          (n) => n.id === prevNodeConfig.parameters?.referencedNodeId
        );
        const hasUpdatedName =
          prevNodeConfig.name !== `Transform ${previousReferencedNode?.name}` && prevNodeConfig.name !== "Transform";

        return {
          ...(!hasUpdatedName && currentReferencedNode && { name: `Transform ${currentReferencedNode.name}` }),
          parameters: {
            referencedField: referencedNodeField?.referencedField ?? [],
            referencedNodeId: referencedNodeField?.referencedNodeId ?? "",
            transformations: !type ? [] : [transformation],
          },
        };
      });
    },
    [onNodeConfigChange, referenceableNodes]
  );

  const initialErrors = useInitialErrors(validationSchema, formInput.initialValues);

  return (
    <TransformationNodeFormContext.Provider value={context}>
      <Formik
        validateOnChange
        validateOnBlur
        validateOnMount
        initialValues={formInput.initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={noop}
        initialErrors={initialErrors}
      >
        <Form>
          <FormChangesListener onValidityChange={setIsTransformationValid} onValuesChange={onValuesChange} />
          {children}
        </Form>
      </Formik>
    </TransformationNodeFormContext.Provider>
  );
};
