import { type DateTime } from "luxon";

export type ProductOption = {
  id: string;
  label: string;
  usageType: string;
};

const COMMON_SIZES = {
  Single: "Single",
  Hundred: "100",
  Thousand: "K",
  TenThousand: "10K",
  HundredThousand: "100K",
  Million: "M",
} as const;

export const PRODUCT_MULTIPLIERS = { ...COMMON_SIZES } as const;
export type ProductMultipliers = (typeof PRODUCT_MULTIPLIERS)[keyof typeof PRODUCT_MULTIPLIERS];

export const PRODUCT_UNITS = {
  ...COMMON_SIZES,
  GB: "GB",
} as const;

export type ProductUnit = (typeof PRODUCT_UNITS)[keyof typeof PRODUCT_UNITS];

export type Product = {
  uniqueId: string;
  id: string;
  label: string;
  usageType: string;
  quantity: number | null;
  multiplier: ProductMultipliers | null;
  price: number | null;
  unit: ProductUnit | null;
  errors: { [key: string]: string };
};

export type Subscription = {
  startDate: DateTime | null;
  endDate: DateTime | null;
  products: Product[];
  errors: { [key: string]: string };
  uniqueId?: string;
};

export type FormState = {
  roleCreationConfirmed: boolean;
  applicationKey: string;
  APIKey: string;
  orgIdRegion: string;
  connectionTestResult: boolean | null;
  connectionError: string;
  loading: boolean;
  productOptions: ProductOption[];
};
