import { useState } from "react";

import { useHistory } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, IconButton, Stack, Typography } from "@mui/material";

import { useCustomerContext } from "../../../../Context/CustomerContext";
import TimeRangeSelector, { TimeRange } from "./Components/TimeRangeSelector";

const ClusterDetailHeader = () => {
  const history = useHistory();
  const { customer } = useCustomerContext();

  const [timeRange, setTimeRange] = useState(TimeRange.T_7D);

  const goBack = () => {
    history.push(`/customer/${customer.id}/flexsave-kubernetes`);
  };

  return (
    <Box justifyContent="space-between" display="flex" alignItems="center" flexDirection="row">
      <Stack direction="row" alignItems="center" spacing={1}>
        <IconButton onClick={goBack}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h1">my-cluster-name</Typography>
      </Stack>
      <TimeRangeSelector value={timeRange} onChange={setTimeRange} />
    </Box>
  );
};

export default ClusterDetailHeader;
