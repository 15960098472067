import { createContext, type ReactNode, useContext, useState } from "react";

import noop from "lodash/noop";

import { consoleErrorWithSentry } from "../../utils";
import mixpanel from "../../utils/mixpanel";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";

export enum AvaOpeningSource {
  FLOATING_BUTTON = "ava.floating_button",
  NAV = "ava.console.icon",
  SUPPORT = "ava.support.button",
  DASHBOARDS = "ava.dashboards.button",
  PLATFORM_DROPDOWN = "ava.support.platform_dropdown",
  URL = "ava.url",
}

type AvaDialogContextType = {
  isAvaDialogOpen: boolean;
  handleOpenAvaDialog: (source: AvaOpeningSource, dashboardId?: string) => void;
  handleCloseAvaDialog: () => void;
  dashboardId: string;
  isFullscreenMode: boolean;
  toggleFullscreenMode: () => void;
  setDashboardId: (dashboardId: string) => void;
  setConversationHasOccurred: (conversationHasOccurred: boolean) => void;
  isAvaDialogMinimized: boolean;
  setIsAvaDialogMinimized: (isAvaDialogMinimized: boolean) => void;
  width: string;
  height: string;
  setWidth: (width: string) => void;
  setHeight: (height: string) => void;
};

type Props = {
  children: ReactNode;
};

export const AvaDialogContext = createContext<AvaDialogContextType>({
  isAvaDialogOpen: false,
  handleOpenAvaDialog: noop,
  handleCloseAvaDialog: noop,
  dashboardId: "",
  isFullscreenMode: false,
  toggleFullscreenMode: noop,
  setDashboardId: noop,
  setConversationHasOccurred: noop,
  isAvaDialogMinimized: false,
  setIsAvaDialogMinimized: noop,
  width: "550px",
  height: "700px",
  setWidth: noop,
  setHeight: noop,
});

export const AvaDialogContextProvider = ({ children }: Props) => {
  const [conversationHasOccurred, setConversationHasOccurred] = useState<boolean>(false);
  const [isAvaDialogOpen, setIsAvaDialogOpen] = useState(false);
  const [dashboardId, setDashboardId] = useState("");
  const [isFullscreenMode, setIsFullscreenMode] = useState(false);
  const [isAvaDialogMinimized, setIsAvaDialogMinimized] = useState(false);
  const [width, setWidth] = useState(window.innerWidth < 550 ? "90vw" : "550px");
  const [height, setHeight] = useState("700px");

  const { setCaseIQConversationId } = useAvaCaseIQContext();

  const handleOpenAvaDialog = (source: AvaOpeningSource, dashboardId?: string) => {
    try {
      mixpanel.track(source);
    } catch (error) {
      consoleErrorWithSentry(error);
    }

    setIsAvaDialogOpen(true);
    setIsAvaDialogMinimized(false);
    setDashboardId(dashboardId ?? "");
  };

  const handleCloseAvaDialog = () => {
    if (conversationHasOccurred) {
      try {
        mixpanel.track("ava.console.abort");
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    }
    document.body.style.overflow = "auto";
    setIsFullscreenMode(false);
    setIsAvaDialogOpen(false);
    setCaseIQConversationId("");
  };

  return (
    <AvaDialogContext.Provider
      value={{
        isAvaDialogOpen,
        handleOpenAvaDialog,
        handleCloseAvaDialog,
        dashboardId,
        isFullscreenMode,
        toggleFullscreenMode: () => {
          setIsFullscreenMode(!isFullscreenMode);
          if (!isFullscreenMode) {
            setHeight("98vh");
            setWidth("98vw");
          } else {
            setHeight("700px");
            setWidth(window.innerWidth < 550 ? "90vw" : "550px");
          }
          document.body.style.overflow = !isFullscreenMode ? "hidden" : "auto";
        },
        setDashboardId,
        setConversationHasOccurred,
        isAvaDialogMinimized,
        setIsAvaDialogMinimized,
        width,
        height,
        setWidth,
        setHeight,
      }}
    >
      {children}
    </AvaDialogContext.Provider>
  );
};

export const useAvaDialogContext = () => useContext(AvaDialogContext);
