import React, { type Dispatch, type SetStateAction, useCallback, useEffect } from "react";

import { Box, Button, Stack, Typography } from "@mui/material";
import { v4 as uuidv4 } from "uuid";

import { type ProductOption, type Subscription as SubscriptionType } from "../../types";
import Subscription from "./Subscription";

type Props = {
  subscriptions: SubscriptionType[];
  onChange: Dispatch<SetStateAction<SubscriptionType[]>>;
  productOptions: ProductOption[];
};

export default function SubscriptionStep({ subscriptions, onChange, productOptions }: Props) {
  const handleAddSubscription = useCallback(
    (firstSubscription?: boolean) => {
      const subscription: SubscriptionType = {
        errors: {},
        startDate: null,
        endDate: null,
        uniqueId: uuidv4(),
        products: firstSubscription
          ? productOptions.map((product) => ({
              ...product,
              quantity: null,
              multiplier: null,
              price: null,
              unit: null,
              uniqueId: uuidv4(),
              errors: {},
            }))
          : [
              {
                label: "",
                id: "",
                usageType: "",
                quantity: null,
                multiplier: null,
                price: null,
                unit: null,
                uniqueId: uuidv4(),
                errors: {},
              },
            ],
      };
      onChange((prevItems) => [...prevItems, subscription]);
    },
    [onChange, productOptions]
  );

  useEffect(() => {
    if (subscriptions.length === 0) {
      handleAddSubscription(true);
    }
  }, [subscriptions.length, handleAddSubscription]);

  const handleUpdateSubscription = useCallback(
    (index: number, data: SubscriptionType) => {
      onChange((prevItems) => {
        const newItems = [...prevItems];
        newItems[index] = data;
        return newItems;
      });
    },
    [onChange]
  );

  const handleRemoveSubscription = useCallback(
    (index: number) => {
      onChange((prevItems) => prevItems.filter((_, i) => i !== index));
    },
    [onChange]
  );

  return (
    <Stack spacing={3}>
      <Stack spacing={1}>
        <Typography variant="h3" sx={{ fontWeight: 500 }}>
          Subscription details
        </Typography>

        <Typography variant="body1">
          We’ve detected {productOptions.length} products and populated them below. Please review this information and
          provide the corresponding quantity and pricing information.
        </Typography>
      </Stack>

      {subscriptions.map((subscription, index) => (
        <Subscription
          key={subscription.uniqueId}
          subscription={subscription}
          productOptions={productOptions}
          onChange={handleUpdateSubscription}
          onRemove={handleRemoveSubscription}
          index={index}
        />
      ))}

      <Box>
        <Button
          variant="outlined"
          onClick={() => {
            handleAddSubscription();
          }}
        >
          Add new subscription details
        </Button>
      </Box>
    </Stack>
  );
}
