import { SupportZendeskModel, type SupportZendeskTicketModel } from "@doitintl/cmp-models";
import { type CollectionDataHook, getCollection, useCollectionData } from "@doitintl/models-firestore";

import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useUserContext } from "../../Context/UserContext";

export function useListZendeskTickets(): CollectionDataHook<SupportZendeskTicketModel> {
  const { customer } = useCustomerContext();
  const { userModel } = useUserContext();
  const { isDoitEmployee } = useAuthContext();

  const customerRef = customer.ref;

  let query = getCollection(SupportZendeskModel)
    .doc("zendesk")
    .collection("tickets")
    .where("customerRef", "==", customerRef);

  if (!isDoitEmployee && !customer?.zendeskOrganizationInfo?.sharedTickets) {
    query = query.where("requesterEmail", "==", userModel?.email);
  }

  const data = useCollectionData(query);

  if (!isDoitEmployee && userModel?.customer?.ref !== customerRef) {
    return [[], false, undefined];
  }

  return data;
}
