import { type JSX, type MouseEvent, useCallback, useRef } from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Tooltip } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import { useFullScreen } from "../../../../utils/dialog";

type Props = {
  id: string;
  rightHolder?: boolean;
  height?: number | string;
  width?: number | string;
  grabbing?: boolean;
  disabled?: boolean;
  children: JSX.Element;
  alignItems?: string;
};

/**
 * A holder for a content that we want to allow dragging
 * @param id - uniq id (one of the ids from the DraggableContainer state)
 * @param children - the content to display
 * @param grabbing - control the cursor of the item
 * @param disabled - control draggability
 * @param height
 * @param width
 */
export const AllocationDraggableHolder = ({
  id,
  children,
  grabbing = false,
  disabled = false,
  height = 200,
  width = "100%",
  alignItems = "center",
}: Props) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id,
    disabled,
  });
  const { isMobile } = useFullScreen();
  const delay = useRef(0);
  const style = {
    display: isMobile ? "none" : "flex",
    cursor: grabbing ? "grabbing" : disabled ? "not-allowed" : "grab",
    zIndex: 999,
  };

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const { target } = event;
      const type = (target as Element).getAttribute("type");
      const desiredAction = (): void => {
        listeners?.onMouseDown?.(event);
      };

      if (type) {
        // this will prevent dragging in case the event has type (like button)
        return;
      }

      if (window.getComputedStyle(target as Element).cursor === "grab") {
        desiredAction();
        return;
      }
      delay.current = window.setTimeout(desiredAction, 100);
    },
    [listeners]
  );

  const handleMouseUp = useCallback(() => {
    clearTimeout(delay.current);
  }, [delay]);

  return (
    <div ref={setNodeRef} style={{ transform: CSS.Transform.toString(transform), transition }}>
      {isDragging ? (
        <Box
          sx={({ palette }) => ({
            border: "2px dashed",
            borderColor: palette.divider,
            borderRadius: 1,
            backgroundColor: palette.background.default,
            width,
            height: 370,
          })}
        />
      ) : (
        <Paper
          elevation={grabbing ? 2 : 0}
          variant={!grabbing ? "outlined" : undefined}
          sx={({ palette }) => ({
            width,
            height,
            backgroundColor: palette.general.backgroundDark,
          })}
        >
          <Stack
            direction="row"
            sx={{
              justifyContent: "space-between",
              alignItems,
              width,
              height,
            }}
          >
            <div {...listeners} {...attributes} style={style} onMouseUp={handleMouseUp} onMouseDown={handleMouseDown}>
              <Tooltip
                title={allocationTxt.ALLOCATION_RULE_TOOLTIP}
                disableHoverListener={disabled}
                open={grabbing ? false : undefined}
              >
                <DragIndicatorIcon color={disabled ? "disabled" : "action"} sx={{ mt: 2, ml: 2 }} />
              </Tooltip>
            </div>
            <Box
              data-cy={`title-${id}`}
              sx={{
                width: "calc(100% - 24px)",
              }}
            >
              {children}
            </Box>
          </Stack>
        </Paper>
      )}
    </div>
  );
};
