import { useMemo } from "react";

import { AvaConversationsModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import { type DateTime } from "luxon";

import { useAuthContext } from "../../Context/AuthContext";
import { getDateTimeFromFirestoreTimestamp } from "../../utils/common";

export type AvaConversationRef = {
  conversationId: string;
  title: string;
  timeUpdated: DateTime;
};

export const truncatedQueryLimit = 50;

export const useAvaConversationRefs = (expandedQuery: boolean = false) => {
  const { userId } = useAuthContext({ mustHaveUser: true });
  let query = getCollection(AvaConversationsModel).where("userId", "==", userId).orderBy("timeUpdated", "desc");

  if (!expandedQuery) {
    query = query.limit(truncatedQueryLimit);
  }
  const [docs, loading] = useCollectionData(query, { idField: "conversationId" });

  return useMemo((): [AvaConversationRef[], boolean] => {
    if (!docs || loading) {
      return [[], true];
    }

    return [
      docs.map((doc) => ({
        title: doc.title,
        conversationId: doc.conversationId,
        timeUpdated: getDateTimeFromFirestoreTimestamp(doc.timeUpdated),
      })),
      false,
    ];
  }, [docs, loading]);
};
