import { useCallback, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid2";
import Radio from "@mui/material/Radio";

import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import { AccessLevel, AssetTypeAmazonWebServices, AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import { getCloudConnectStatus } from "../../../utils/common";
import mixpanel from "../../../utils/mixpanel";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { helpURLs } from "../../../assets/urls";
import { getServiceAccountsBQFinOps } from "../../../Components/Dashboard/BigQueryLens/utils";
import Hide from "../../../Components/HideChildren/Hide";
import { useCloudConnectContext } from "../../../Context/customer/CloudCOnnectContext";
import { useApiContext } from "../../../api/context";
import LocationSelector from "./LocationSelector";
import ConfirmationDialog from "./ConfirmationDialog";
import ErrorDialog from "./ErrorDialog";

const errorMsg = {
  MissingPermissions: {
    title: "Oops, some permissions are still missing",
    body: "The following of the requested permissions are still missing on the IAM Role:",
  },
  KeyFileNotValid: {
    title: "Argh! Something went wrong",
    body: "We can't read the provided service account details file. Please make sure to upload the file in JSON format",
  },
  organizationExist: {
    title: "Are you sure?",
    body: "It looks like there is already a service account and role configured for this Google Cloud Org. Please remove the existing service account or provide a service account from another organization",
  },
  ArnRoleNotValid: {
    title: "Argh! Something went wrong",
    body: "The provided role ARN is not valid",
  },
  enableService: { title: "Enable Service", body: "" },
};

const rowsPerPage = 3;

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  uploadInput: {
    // border: "1px solid #c4c4c4",
    // borderRadius: 6,
    // padding: 6,
    // marginTop: 16,
    // [theme.breakpoints.down("sm")]: {
    //   minWidth: 100,
    // },
  },
  textField: {},
  fileInput: {
    display: "none",
  },
  row: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  rowAWS: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    minWidth: 400,
    [theme.breakpoints.down("md")]: {
      minWidth: 180,
    },
  },
  serviceList: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  errorDiv: {
    minHeight: 25,
    maxHeight: 75,
    overflow: "auto",
    marginTop: 4,
  },
  uploadBox: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  gridCell: {
    maxWidth: 250,
    [theme.breakpoints.down("md")]: {
      maxWidth: 180,
    },
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  uploadText: {
    width: 200,
    [theme.breakpoints.down("md")]: {
      width: 220,
    },
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  accountDetails: {
    fontWeight: 500,
  },
  link: {},
  textInputField: {
    padding: "15.5px 14px",
  },
  gcloudCommand: {
    backgroundColor: "#f1f0f1",
    padding: 8,
    borderRadius: 2,
  },
}));

function AddServiceAccountGCP({ handleAddFiles, loading, accessLevel }) {
  const classes = useStyles();
  const inputFile = useRef(null);
  const onButtonClick = () => {
    inputFile.current.click();
  };
  return (
    <div className={classes.uploadInput}>
      <div className={classes.row}>
        <div style={{ flex: 1, alignItems: "center" }}>
          <input
            id="file-upload-button"
            type="file"
            ref={inputFile}
            multiple
            className={classes.fileInput}
            onChange={(e) => handleAddFiles(e, accessLevel === AccessLevel.PROJECT)}
            onClick={(event) => {
              event.target.value = null;
            }}
          />
        </div>
        <LoadingButton
          variant="contained"
          onClick={onButtonClick}
          color="primary"
          disabled={loading}
          loading={loading}
          mixpanelEventId="settings.gcp-platform-configuration.upload-key"
        >
          Upload file
        </LoadingButton>
      </div>
    </div>
  );
}

function AddAwsRole({ handleChange, values, customerID, awsAccountId }) {
  const classes = useStyles();

  return (
    <div>
      <Grid spacing={2} style={{ marginBottom: 14, marginTop: 8 }} container>
        <Grid>
          <Grid>
            <Typography variant="body2" className={classes.accountDetails}>
              Our AWS Account:
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2" className={classes.accountDetails}>
              Your External ID:
            </Typography>
          </Grid>
        </Grid>

        <Grid>
          <Grid>
            <Typography variant="body2" color="textSecondary">
              {awsAccountId}
            </Typography>
          </Grid>
          <Grid>
            <Typography variant="body2" color="textSecondary">
              {customerID}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.rowAWS}>
        <TextField
          id="outlined-name"
          label="Role ARN"
          className={classes.textField}
          value={values.roleName}
          onChange={handleChange("roleName")}
          variant="outlined"
          fullWidth
          slotProps={{
            input: {
              classes: {
                input: classes.textInputField,
              },
            },
          }}
        />
      </div>
    </div>
  );
}

function PlatformConfigurationDialog({
  open,
  customerID,
  data,
  onClose,
  onDelete,
  type,
  title,
  subTitle,
  awsAccountId,
  settings,
  selectedOrg,
  handleChangeOrg,
  selectedCategories,
  accessLevel,
  onComplete,
}) {
  const classes = useStyles();
  const api = useApiContext();
  const snackbar = useSnackbar();
  const [file, setFile] = useState();
  const [page, setPage] = useState(0);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [enableServiceError, setEnableServiceError] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState(null);
  const [locationSelectorOpen, setLocationSelectorOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const cloudConnect = useCloudConnectContext();
  const [values, setValues] = useState({
    serviceName: "",
    roleName: "",
    error: null,
    loading: false,
  });
  const bigqueryFinopsPermission = getServiceAccountsBQFinOps(cloudConnect);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChange = (name) => (event) => {
    setValues({ ...values, error: null, [name]: event.target.value });
  };

  const uploadKey = useCallback(
    async (formData, partial) => {
      try {
        onComplete(false);
        const res = await api.request({
          method: "post",
          url: `/v1/customers/${customerID}/cloudconnect/${type}${partial ? "/partial" : ""}`,
          data: formData,
          config: { headers: { "Content-Type": "multipart/form-data" } },
        });

        if (res.data.error) {
          setIsErrorOpen(true);
          setValues({
            ...values,
            error: res.data,
            loading: false,
          });
        } else {
          setValues({
            ...values,
            loading: false,
            error: null,
            roleName: "",
          });
          setFile(null);

          switch (type) {
            case AssetTypeGoogleCloud: {
              const clientId = res.data?.clientId;

              if (clientId) {
                api.request({
                  method: "post",
                  url: `/v1/customers/${customerID}/bq-lens/onboarding`,
                  data: { clientId },
                });
              }
              mixpanel.track("credentials.google-cloud.set");
              break;
            }
            case AssetTypeAmazonWebServices: {
              mixpanel.track("credentials.amazon-web-services.set");
              break;
            }
            default:
              break;
          }
          onClose();
        }
      } catch (error) {
        setIsErrorOpen(true);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error &&
          type === AssetTypeGoogleCloud &&
          error.response.data.error.indexOf("Enable it by") > -1
        ) {
          setEnableServiceError(error.response.data.error);
          setValues({
            ...values,
            error: { error: "enableService" },
            loading: false,
          });
        } else {
          errorMsg.ArnRoleNotValid.body = error.response.data.error;
          setValues({
            ...values,
            error: { error: "KeyFileNotValid" },
            loading: false,
          });

          if (error.response.data?.isValid) {
            snackbar.onOpen({
              message: error.response.data.error,
              variant: "error",
              autoHideDuration: 50000,
              withClose: true,
            });
          }
        }
      }
      onComplete(true);
      const promises = [];
      if (type === AssetTypeAmazonWebServices) {
        await api.request({
          method: "get",
          url: `/v1/customers/${customerID}/amazon-web-services/service-limits`,
        });
      } else {
        promises.push(
          api.request({
            method: "get",
            url: `/v1/customers/${customerID}/google-cloud/service-limits`,
          })
        );
        promises.push(
          api.request({
            method: "post",
            url: `/v1/customers/${customerID}/recommender/update`,
          })
        );
        promises.push(
          api.request({
            method: "get",
            url: `/v1/customers/${customerID}/attachDashboard`,
          })
        );
        await Promise.all(promises);
      }
    },
    [api, customerID, onClose, onComplete, snackbar, type, values]
  );

  const handleAddServiceAccount = useCallback(
    async (sa, partial = false) => {
      setValues({
        ...values,
        loading: true,
        error: null,
      });
      setEnableServiceError(null);
      const formData = new FormData();
      switch (type) {
        case AssetTypeGoogleCloud:
          formData.append("service_key", sa ? sa[0] : file[0]);
          break;
        case AssetTypeAmazonWebServices:
          formData.append("arn", values.roleName);
          break;

        default:
          break;
      }

      if (type === AssetTypeGoogleCloud && selectedCategories?.some((c) => c.id === "bigquery-finops")) {
        setLocationSelectorOpen(formData);
      } else {
        await uploadKey(formData, partial);
      }
    },
    [file, selectedCategories, type, uploadKey, values]
  );

  const onConfirmDatasetLocation = useCallback(() => {
    const formData = locationSelectorOpen;
    formData.append("location", selectedLocation ?? "US");
    uploadKey(formData);
    setLocationSelectorOpen(false);
  }, [locationSelectorOpen, selectedLocation, uploadKey]);
  const handleAddFiles = useCallback(
    (event, partial = false) => {
      setValues({ ...values, error: null });
      if (type === AssetTypeGoogleCloud) {
        handleAddServiceAccount(event.target.files, partial);
      }
      setFile(event.target.files);
    },
    [handleAddServiceAccount, type, values]
  );

  const handleRemoveFile = () => {
    setFile(null);
    setValues({ ...values, error: null });
  };
  const handleCloseErrorDialog = () => {
    setIsErrorOpen(false);
  };
  const getAccountItem = (service) => {
    switch (type) {
      case AssetTypeGoogleCloud:
        return service.data.clientEmail;
      case AssetTypeAmazonWebServices:
        return service.data.roleName;
      case "azure":
        break;
      default:
        break;
    }
  };
  const deleteServiceAccount = (service) => {
    if (service.data.cloudPlatform === AssetTypeGoogleCloud) {
      setServiceToDelete(service);
      setOpenConfirmationDialog(true);
    } else {
      onDelete(service);
    }
  };
  const onConfirmDelete = useCallback(() => {
    onDelete(serviceToDelete);

    if (bigqueryFinopsPermission && bigqueryFinopsPermission.length > 0) {
      const clientId = bigqueryFinopsPermission[0]?.clientId;

      if (clientId) {
        api.request({
          method: "post",
          url: `/v1/customers/${customerID}/bq-lens/offboarding`,
          data: { clientId },
        });
      }
    }
  }, [api, bigqueryFinopsPermission, onDelete, serviceToDelete, customerID]);
  const onSelectLocation = useCallback((event, location) => {
    setSelectedLocation(location?.value);
  }, []);

  return (
    <div>
      <div>
        {(open || type === AssetTypeAmazonWebServices) && (
          <>
            <Typography variant="h6">{title}</Typography>
            {subTitle}
            {type !== AssetTypeGoogleCloud && (
              <Link
                href={type === AssetTypeGoogleCloud ? helpURLs.CONNECT_GCP_SA : helpURLs.ADD_AWD_IAM_ROLE}
                target="_blank"
                rel="noopener"
                className={classes.link}
              >
                {type === AssetTypeGoogleCloud
                  ? "How To Connect Your Google Cloud Organization"
                  : "How To Connect Your AWS Account"}
              </Link>
            )}
            <Hide>
              {type === AssetTypeGoogleCloud && (!data[selectedOrg]?.id || values.loading) && settings && (
                <AddServiceAccountGCP
                  handleChange={handleChange}
                  values={values}
                  handleAddFiles={handleAddFiles}
                  handleRemoveFile={handleRemoveFile}
                  handleAddServiceAccount={handleAddServiceAccount}
                  files={file}
                  loading={values.loading}
                  accessLevel={accessLevel}
                />
              )}
            </Hide>
            {type === AssetTypeAmazonWebServices && (
              <AddAwsRole
                handleChange={handleChange}
                values={values}
                handleAddFiles={handleAddFiles}
                handleRemoveFile={handleRemoveFile}
                handleAddServiceAccount={handleAddServiceAccount}
                files={file}
                loading={values.loading}
                customerID={customerID}
                awsAccountId={awsAccountId}
              />
            )}

            {data && data.length > 0 && data[0].id && !settings && (
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: type === AssetTypeAmazonWebServices ? 24 : 0,
                  }}
                >
                  <Typography variant="h6" gutterBottom>
                    {type === AssetTypeAmazonWebServices ? "Configured Accounts" : ""}
                  </Typography>
                </div>

                <Table size="small" className={classes.table} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {type === AssetTypeGoogleCloud && <TableCell padding="none" />}
                      {type === AssetTypeAmazonWebServices && (
                        <Hide mdDown>
                          <TableCell>Account ID</TableCell>
                        </Hide>
                      )}
                      <TableCell>{type === AssetTypeGoogleCloud ? "Service Account" : "Role"}</TableCell>
                      {type !== AssetTypeAmazonWebServices && (
                        <Hide mdDown>
                          <TableCell>Organization</TableCell>
                        </Hide>
                      )}
                      <TableCell>Status</TableCell>
                      {onDelete && <TableCell align="right" />}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((service, i) => (
                      <TableRow key={getAccountItem(service)}>
                        {type === AssetTypeGoogleCloud && (
                          <TableCell padding="none">
                            <Radio
                              checked={i === selectedOrg}
                              onChange={handleChangeOrg}
                              value={i}
                              name="radio-button-demo"
                              color="primary"
                              inputProps={{ "aria-label": "B" }}
                            />
                          </TableCell>
                        )}
                        {type === AssetTypeAmazonWebServices && (
                          <Hide mdDown>
                            <TableCell className={classes.gridCell}>
                              <Typography variant="inherit" noWrap>
                                {service.data.accountId}
                              </Typography>
                            </TableCell>
                          </Hide>
                        )}
                        <TableCell className={classes.gridCell}>
                          <Typography variant="inherit" noWrap>
                            {getAccountItem(service)}
                          </Typography>
                        </TableCell>
                        <Hide mdDown>
                          {type !== AssetTypeAmazonWebServices && (
                            <TableCell>
                              <Typography variant="inherit">
                                {service.data.organizations && service.data.organizations[0]?.displayName}
                              </Typography>
                            </TableCell>
                          )}
                        </Hide>
                        <TableCell>
                          <Typography
                            variant="inherit"
                            style={{ color: getCloudConnectStatus(service.data.status).color }}
                          >
                            {getCloudConnectStatus(service.data.status).text.toUpperCase()}
                          </Typography>
                        </TableCell>
                        {onDelete && (
                          <TableCell align="right">
                            <IconButton
                              onClick={() => deleteServiceAccount(service)}
                              size="small"
                              edge="end"
                              aria-label="delete"
                            >
                              <DeleteIcon />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                {data.length > rowsPerPage && (
                  <TablePagination
                    rowsPerPageOptions={[rowsPerPage]}
                    component="div"
                    count={data.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    slotProps={{
                      actions: {
                        previousButton: {
                          "aria-label": "Previous Page",
                        },
                        nextButton: {
                          "aria-label": "Next Page",
                        },
                      },
                    }}
                    labelRowsPerPage=""
                    onPageChange={handleChangePage}
                  />
                )}
              </div>
            )}
          </>
        )}

        {type === "amazon-web-services" && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <LoadingButton
              variant="contained"
              onClick={handleAddServiceAccount}
              color="primary"
              autoFocus
              disabled={
                type === "google-cloud" ? Boolean(!file) || values.loading : values.loading || values.roleName === ""
              }
              loading={values.loading}
              style={{ marginBottom: -15, marginTop: 20 }}
              mixpanelEventId="settings.gcp-platform-configuration.add"
            >
              Add
            </LoadingButton>
          </div>
        )}
      </div>

      <ErrorDialog
        title={errorMsg[values.error?.error]?.title}
        content={errorMsg[values.error?.error]?.body}
        missingPermissions={values.error?.missingPermissions}
        handleClose={handleCloseErrorDialog}
        enableServiceError={enableServiceError}
        open={isErrorOpen}
      />
      <ConfirmationDialog
        open={openConfirmationDialog}
        onConfirmDelete={onConfirmDelete}
        onClose={() => setOpenConfirmationDialog(false)}
      />
      {locationSelectorOpen && (
        <LocationSelector onConfirm={onConfirmDatasetLocation} onSelectLocation={onSelectLocation} />
      )}
    </div>
  );
}
export default PlatformConfigurationDialog;
