import { useState } from "react";

import { Link } from "react-router-dom";
import { ClusterType, type ClusterWithCloudConnect } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, Button, IconButton, Stack } from "@mui/material";

import useRouteMatchURL from "../../../../../Components/hooks/useRouteMatchURL";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { getAwsCloudConnectSettingsLink } from "../../../../Settings/AWS/utils";
import { getGcpCloudConnectSettingsLink } from "../../../../Settings/GCP/utils";

interface ClusterConnectionIssueAlertProps {
  cluster: ClusterWithCloudConnect;
}

const ClusterConnectionIssueAlert = ({ cluster }: ClusterConnectionIssueAlertProps) => {
  const routeMatchURL = useRouteMatchURL();
  const { customer } = useCustomerContext();

  const [showAlert, setShowAlert] = useState(true);

  if (!showAlert) {
    return null;
  }

  const messages: Partial<Record<ClusterType, string>> = {
    [ClusterType.GKE]: `Connection issue in GCP project ${cluster.projectId}. Go to GCP settings to fix this issue.`,
    [ClusterType.EKS]: `Connection issue in AWS account ${cluster.projectId}. Go to AWS settings to fix this issue.`,
  };

  const urls: Partial<Record<ClusterType, string>> = {
    [ClusterType.GKE]: getGcpCloudConnectSettingsLink({
      customer,
      cloudConnect: cluster.cloudConnect,
      callbackUrl: routeMatchURL,
    }),
    [ClusterType.EKS]: getAwsCloudConnectSettingsLink({
      customer,
      cloudConnect: cluster.cloudConnect,
      callbackUrl: routeMatchURL,
    }),
  };

  const url = urls[cluster.type] ?? "";
  const message = messages[cluster.type] ?? "";

  return (
    <Alert
      severity="warning"
      action={
        <Stack direction="row">
          <Button component={Link} to={url} color="inherit">
            Fix issue
          </Button>
          <IconButton
            color="inherit"
            size="small"
            onClick={() => {
              setShowAlert(false);
            }}
            sx={{ width: 32 }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </Stack>
      }
    >
      {message}
    </Alert>
  );
};

export default ClusterConnectionIssueAlert;
