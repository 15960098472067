import { useCallback, useEffect, useMemo, useState } from "react";

import { platformsData } from "../consts";
import { type PlatformFilter, type PlatformFilterInfo, type Ticket } from "../types";
import { getProductId } from "../utils";

export const useFilterCloud = (currentTicketList: Ticket[]) => {
  const [selectedFilter, setSelectedFilter] = useState<PlatformFilter[]>([]);
  const [filteredTickets, setFilteredTickets] = useState<Ticket[]>(currentTicketList);

  const filterTickets = useCallback(
    (filter: PlatformFilter[]) => {
      if (filter.length > 0) {
        const filterTickets = currentTicketList.filter((ticket) =>
          filter.find((product) => product === getProductId(ticket))
        );
        setFilteredTickets(filterTickets);
      } else {
        setFilteredTickets(currentTicketList);
      }
    },
    [currentTicketList]
  );

  const platformsAvailable = useMemo(() => {
    const products = new Set<PlatformFilterInfo>();

    currentTicketList.forEach((ticket) => {
      const productType = getProductId(ticket);
      if (productType) {
        const product = platformsData.find((item) => item.value === productType);
        if (product) {
          products.add(product);
        }
      }
    });
    return Array.from(products);
  }, [currentTicketList]);

  useEffect(() => {
    filterTickets(selectedFilter);
  }, [filterTickets, selectedFilter]);

  const onClickFilter = (filter: PlatformFilter[]) => {
    setSelectedFilter(filter);
    filterTickets(filter);
  };

  return { selectedFilter, onClickFilter, filteredTickets, platformsAvailable };
};
