import { AccessLevel, type CloudConnectCategory, type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";

import { type Customer } from "../../../types";

// For each AccessLevel, define which permissions to remove. These are the permissions that only apply to different scopes
const scopeConfigs: Record<AccessLevel, (category: CloudConnectCategory) => string[]> = {
  [AccessLevel.DATASET]: (category) => [
    ...(category.orgLevelOnlyPermissions ?? []),
    ...(category.projectLevelOnlyPermissions ?? []),
  ],
  [AccessLevel.PROJECT]: (category) => [
    ...(category.orgLevelOnlyPermissions ?? []),
    ...(category.datasetLevelOnlyPermissions ?? []),
  ],
  [AccessLevel.ORGANIZATION]: (category) => [
    ...(category.projectLevelOnlyPermissions ?? []),
    ...(category.datasetLevelOnlyPermissions ?? []),
  ],
};

// Returns the category's base permissions minus any that are specific to a different scope.
export function getScopedCategoryPermissions(category: CloudConnectCategory, scope: AccessLevel): string[] {
  // If this scope does not exist in scopeConfigs, return everything.
  const removeList = scopeConfigs[scope]?.(category) ?? [];

  // Filter out any permissions that are in removeList.
  return category.permissions.filter((permission) => !removeList.includes(permission));
}

export const getProjectIdFromServiceAccount = (clientEmail?: string): string | undefined =>
  clientEmail?.split("@")[1]?.split(".")[0];

interface GcpCloudConnectLinkParams {
  customer: Customer;
  cloudConnect?: CustomerModelCloudConnectModel;
  callbackUrl?: string;
}

export const getGcpCloudConnectSettingsLink = ({
  customer,
  cloudConnect,
  callbackUrl,
}: GcpCloudConnectLinkParams): string => {
  let base = `/customers/${customer.id}/settings/gcp`;

  if (cloudConnect) {
    base += `/connect/${cloudConnect.scope}/${cloudConnect.id}`;
  }

  if (callbackUrl) {
    base += `?callbackUrl=${encodeURIComponent(callbackUrl)}`;
  }

  return base;
};

/**
 * Validates that the dataset name starts with a letter or underscore,
 * and is followed by letters, numbers, or underscores.
 */
export function validateDatasetName(name: string): boolean {
  return /^[A-Za-z_][A-Za-z0-9_]*$/.test(name);
}

/**
 * Validates that the service account identifier is between 1 and 21 characters long,
 * starts with a lowercase letter, ends with a letter or number, and only contains
 * lowercase letters, numbers, and dashes.
 */
export function validateServiceAccountIdentifier(identifier: string): boolean {
  return /^[a-z][a-z0-9-]*[a-z0-9]$/.test(identifier) && identifier.length >= 1 && identifier.length <= 21;
}
