import { useMemo } from "react";

import { useHistory } from "react-router-dom";
import { AssetTypeSnowflake, type DataConnectorSnowflakeModel } from "@doitintl/cmp-models";
import { Button } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import NoAssets from "./components/NoAssets";
import SnowflakeAssetsTabHeader from "./components/snowflake/SnowflakeAssetsTabHeader";
import SnowflakeAssetTable from "./components/snowflake/SnowflakeAssetTable";

const SnowflakeAssetsTab = ({ sfConfigs }: { sfConfigs: DataConnectorSnowflakeModel[] }) => {
  const { customer } = useCustomerContext();
  const history = useHistory();

  const connectButton = useMemo(
    () => (
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          history.push(`/customers/${customer.id}/cloud-onboarding-snowflake`);
        }}
      >
        Connect new account
      </Button>
    ),
    [customer.id, history]
  );

  if (sfConfigs && sfConfigs.length === 0) {
    return <NoAssets assetType={AssetTypeSnowflake}>{connectButton}</NoAssets>;
  }

  return (
    <>
      <SnowflakeAssetsTabHeader connectButton={connectButton} />
      <SnowflakeAssetTable configs={sfConfigs} />
    </>
  );
};

export default SnowflakeAssetsTab;
