import { useState } from "react";

import { type Cluster } from "@doitintl/cmp-models";
import { Box, Checkbox, CircularProgress, Divider, Stack, TextField, Typography } from "@mui/material";

import { useClustersWithCloudConnect } from "../../../hooks";

interface ClusterBulkSelectorProps {
  projectId: string | null;
  filterAvailableClusters?: (cluster: Cluster) => boolean;
  onSelect: (clusters: Cluster["id"][]) => void;
  value?: Cluster["id"][] | null;
}

const ClusterBulkSelector = ({
  projectId,
  onSelect,
  filterAvailableClusters,
  value = [],
}: ClusterBulkSelectorProps) => {
  const [search, setSearch] = useState("");
  const [clusters, clustersAreLoading] = useClustersWithCloudConnect();

  const availableClusters = clusters?.filter((c) =>
    filterAvailableClusters ? filterAvailableClusters(c) : c.projectId === projectId
  );
  const filteredClusters = availableClusters?.filter((c) => c.name.toLowerCase().includes(search.toLowerCase()));

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleSelect = (cluster: Cluster) => {
    if (!value) return;

    const newSelectedClusters = value?.includes(cluster.id)
      ? value.filter((c) => c !== cluster.id)
      : [...value, cluster.id];

    onSelect(newSelectedClusters);
  };

  const handleSelectAll = () => {
    if (!value || !availableClusters) return;
    const newSelectedClusters = value.length === availableClusters.length ? [] : availableClusters.map((c) => c.id);
    onSelect(newSelectedClusters);
  };

  if (clustersAreLoading) {
    return <CircularProgress />;
  }

  const allClustersAreSelected = availableClusters?.map((c) => c.id)?.every((id) => value?.includes(id));

  if (availableClusters.length === 0) {
    return <Typography variant="body1">No clusters available</Typography>;
  }

  return (
    <Stack spacing={2}>
      <TextField onChange={handleSearch} label="Cluster name" variant="outlined" fullWidth />
      <Stack>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Checkbox onChange={handleSelectAll} checked={allClustersAreSelected} />
          <Typography sx={{ cursor: "pointer" }} onClick={handleSelectAll} variant="body1">
            All clusters ({availableClusters?.length})
          </Typography>
        </Box>
        <Divider />
        <Stack sx={{ overflowY: "auto", maxHeight: "40vh" }}>
          {filteredClusters?.map((cluster) => (
            <Box key={cluster.id} sx={{ display: "flex", alignItems: "center" }}>
              <Checkbox
                onChange={() => {
                  handleSelect(cluster);
                }}
                checked={value?.includes(cluster.id) ?? false}
              />
              <Typography
                variant="body1"
                onClick={() => {
                  handleSelect(cluster);
                }}
                style={{ cursor: "pointer" }}
              >
                {cluster.name} ({cluster.region})
              </Typography>
            </Box>
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ClusterBulkSelector;
