import { type ClusterWithCloudConnect, type KubernetesFeature } from "@doitintl/cmp-models";
import { CircularProgress, Stack, Typography } from "@mui/material";

import Fsk8sFeatureSelector from "./components/Fsk8sFeatureSelector";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

type SelectFeaturesStepProps = {
  projectId: string | null;
  clusters: ClusterWithCloudConnect[] | null;
  selectedFeatures: KubernetesFeature[];
  setSelectedFeatures: (features: KubernetesFeature[]) => void;
};

const SelectFeaturesStep = ({
  projectId,
  clusters,
  selectedFeatures,
  setSelectedFeatures,
}: SelectFeaturesStepProps) => {
  if (!clusters || clusters.length === 0) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Select features you want to enable</Typography>
        <SelectedAccountAndCluster projectId={projectId} selectedClusters={clusters} />
      </Stack>

      <Fsk8sFeatureSelector selectedFeatures={selectedFeatures} onSelect={setSelectedFeatures} />
    </Stack>
  );
};

export default SelectFeaturesStep;
