import ClusterPageHeader from "./Header";
import { ClusterTable } from "./Table";

const ClustersPage = () => (
  <>
    <ClusterPageHeader />
    <ClusterTable />
  </>
);

export default ClustersPage;
