import { Box, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Form, Formik } from "formik";

import LoadingButton from "../../../Components/LoadingButton";

type FormValues = {
  anomalyDetection: boolean;
};

type Props = {
  onSubmit: (data: FormValues) => Promise<void>;
  onCancel: () => void;
  initialValues: FormValues;
};

export const AllocationSettingsForm = ({ initialValues, onCancel, onSubmit }: Props) => (
  <Formik
    initialValues={initialValues}
    onSubmit={async (data, { setSubmitting, resetForm }) => {
      try {
        await onSubmit(data);
      } catch (e: unknown) {
        setSubmitting(false);
        resetForm();
      }
    }}
  >
    {({ values, isSubmitting, handleChange }) => (
      <Form>
        <Box sx={{ mx: 2 }}>
          <FormControlLabel
            value={values.anomalyDetection}
            checked={values.anomalyDetection}
            control={
              <Switch
                sx={{ mr: "10px", ml: "6px" }}
                name="anomalyDetection"
                onChange={handleChange}
                color="primary"
                size="small"
              />
            }
            label="Monitor for Cost Anomalies"
            sx={{ fontSize: "16px", fontWeight: 400, color: "text.secondary" }}
          />
        </Box>

        <Divider sx={{ width: "100%", mt: 2 }} />

        <DialogActions>
          <Button variant="text" onClick={onCancel} disabled={isSubmitting}>
            Cancel
          </Button>

          <LoadingButton
            color="primary"
            variant="contained"
            type="submit"
            loading={isSubmitting}
            disabled={isSubmitting}
            mixpanelEventId="analytics.attribution-settings.save"
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Form>
    )}
  </Formik>
);
