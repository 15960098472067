import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material";

export enum Percentile {
  P_99_9 = "99.9%",
  P_99 = "99%",
  P_95 = "95%",
  P_80 = "80%",
  MAX = "MAX",
  AVG = "AVG",
  MIN = "MIN",
}

interface PercentileOption {
  label: string;
  value: Percentile;
}

const percentileOptions: PercentileOption[] = [
  { label: "99.9%", value: Percentile.P_99_9 },
  { label: "99%", value: Percentile.P_99 },
  { label: "95%", value: Percentile.P_95 },
  { label: "80%", value: Percentile.P_80 },
  { label: "Max", value: Percentile.MAX },
  { label: "Average", value: Percentile.AVG },
  { label: "Min", value: Percentile.MIN },
];

interface PercentileSelectorProps {
  value?: Percentile;
  onChange?: (value: Percentile) => void;
}

const PercentileSelector = ({ value = Percentile.P_99, onChange = (_) => {} }: PercentileSelectorProps) => {
  const handleChange = (event: SelectChangeEvent<Percentile>) => {
    const selectedValue = event.target.value as Percentile;
    onChange(selectedValue);
  };

  const label = "Percentile";

  return (
    <FormControl sx={{ width: 120 }}>
      <InputLabel id="percentile-selector-label">{label}</InputLabel>
      <Select labelId="percentile-selector-label" size="small" label={label} value={value} onChange={handleChange}>
        {percentileOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PercentileSelector;
