import { useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material";

import { useIsDCIEssentialsTier } from "../../Support/hooks/useIsDCIEssentialsTier";
import useSegmentTrackEvent from "../../utils/useSegmentTrackEvent";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useAvaContext } from "./AvaContext";
import { useTrpcContext } from "./AvaTRPCContext";

type Props = {
  conversationId?: string;
  isSideChatMenu?: boolean;
};

export const MoreOptionsMenu = ({ conversationId, isSideChatMenu }: Props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { setCaseIQConversationId } = useAvaCaseIQContext();
  const { deleteConversation } = useTrpcContext();
  const { setConversationId } = useAvaContext();
  const isDCIEssentials = useIsDCIEssentialsTier();
  const { trackEvent } = useSegmentTrackEvent();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteChat = () => {
    if (conversationId) {
      deleteConversation(conversationId);
      if (isSideChatMenu) {
        setConversationId();
      }
    }
    handleMenuClose();
  };

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Stack>
      <IconButton onClick={handleMenuClick} sx={{ p: isSideChatMenu ? 1 : 0.5 }}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        slotProps={{
          root: {
            style: {
              zIndex: 1201,
            },
          },
        }}
      >
        <MenuItem
          onClick={() => {
            setCaseIQConversationId(conversationId ?? "");
            handleMenuClose();
            trackEvent("Case IQ Create a request", {
              source: "Ava",
              feature: "support ticket",
              conversationId,
            });
          }}
          disabled={isDCIEssentials}
        >
          Open request
        </MenuItem>
        {/* <MenuItem onClick={() => {}}>Rename conversation</MenuItem> */}
        <MenuItem onClick={handleDeleteChat}>
          <Typography color="error.main">Delete conversation</Typography>
        </MenuItem>
      </Menu>
    </Stack>
  );
};
