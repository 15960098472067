import { CLOUD_FLOW_CREATION_STATUS, type CloudflowCreationStatus } from "@doitintl/cmp-models";
// import { Chip, type ChipProps, type Theme, useTheme } from "@mui/material";
import { Chip, type ChipProps } from "@mui/material";

type StatusChipConfig = {
  // background?: string;
  color: ChipProps["color"];
  label: string;
};

type Props = {
  status?: CloudflowCreationStatus;
};

const getStatusChipConfig = (status: CloudflowCreationStatus | undefined): StatusChipConfig => {
  switch (status) {
    case CLOUD_FLOW_CREATION_STATUS.PUBLISHED:
    case CLOUD_FLOW_CREATION_STATUS.NEW:
      return {
        // background: theme.palette.success.background,
        color: "success",
        label: "Published",
      };
    case CLOUD_FLOW_CREATION_STATUS.DRAFT:
      return {
        // background: theme.palette.action.disabledBackground,
        color: "default",
        label: "Draft",
      };
    default:
      return {
        // background: theme.palette.action.disabledBackground,
        color: "default",
        label: "Loading...",
      };
  }
};

export const StatusChip = ({ status }: Props) => {
  // const theme = useTheme();
  // const { background, color, label } = getStatusChipConfig(status, theme);
  const { color, label } = getStatusChipConfig(status);
  return (
    <Chip variant="status" color={color} label={label} data-cy="cloudFlowStatus" />
    // <Chip
    //   component="span"
    //   size="small"
    //   label={label}
    //   data-cy="cloudFlowStatus"
    //   sx={{
    //     background,
    //     color,
    //     py: 1.8,
    //     fontWeight: 400,
    //     borderRadius: 1,
    //   }}
    // />
  );
};
