import { useCallback, useEffect, useMemo, useState } from "react";

import { ClusterType } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { type AWSAccount, type AWSFeatureName } from "../../../Settings/AWS/types";
import { type GCPCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { useClustersWithCloudConnect } from "../../hooks";
import AwsAccountSelector from "./components/AwsAccountSelector";
import { ClusterTypeSelector } from "./components/ClusterTypeSelector";
import GcpCloudConnectSelector from "./components/GcpCloudConnectSelector";

interface SelectClusterTypeStepProps {
  selectedType: ClusterType | null;
  onSelectType: (type: ClusterType) => void;
  selectedProjectId: string | GCPCloudConnectData | null;
  onSelectProjectId: (value: string | GCPCloudConnectData | null) => void;
  targetAwsFeatures?: AWSFeatureName[];
  targetGcpCategories?: string[];
}

const SelectClusterTypeStep = ({
  selectedType,
  onSelectType,
  selectedProjectId,
  onSelectProjectId,
  targetAwsFeatures,
  targetGcpCategories,
}: SelectClusterTypeStepProps) => {
  const [clusters, clustersLoading] = useClustersWithCloudConnect();

  const [typeSelectorTouched, setTypeSelectorTouched] = useState(false);

  const awsAccountsWithClusters: AWSAccount["accountId"][] = useMemo(
    () => clusters.filter((c) => c.type === ClusterType.EKS).map((c) => c.projectId),
    [clusters]
  );

  const gcpCloudConnectsWithClusters: GCPCloudConnectData["id"][] = useMemo(() => {
    const gcpClusters = clusters.filter((c) => c.type === ClusterType.GKE);
    return gcpClusters.map((c) => c.cloudConnect?.id as GCPCloudConnectData["id"]);
  }, [clusters]);

  const getDisabledTypes = () => {
    const disabledTypes: ClusterType[] = [];

    if (awsAccountsWithClusters.length === 0) {
      disabledTypes.push(ClusterType.EKS);
    }

    if (gcpCloudConnectsWithClusters.length === 0) {
      disabledTypes.push(ClusterType.GKE);
    }

    return disabledTypes;
  };

  const disabledTypes = getDisabledTypes();

  // If there's only one enabled type, select it by default
  const preselectType = useCallback(() => {
    if (!typeSelectorTouched && disabledTypes.length === 1 && !selectedType) {
      onSelectType(disabledTypes.includes(ClusterType.EKS) ? ClusterType.GKE : ClusterType.EKS);
      setTypeSelectorTouched(true);
    }
  }, [disabledTypes, onSelectType, selectedType, typeSelectorTouched]);

  useEffect(() => {
    preselectType();
  }, [preselectType]);

  const handleSelectType = (type: ClusterType) => {
    onSelectType(type);
    setTypeSelectorTouched(true);
  };

  return (
    <Stack spacing={4}>
      <Typography variant="h1">Select a cluster type to enable features</Typography>

      <ClusterTypeSelector
        selectedClusterType={selectedType}
        onSelect={handleSelectType}
        disabledTypes={getDisabledTypes()}
        loading={clustersLoading}
      />

      {!!selectedType && (
        <Stack spacing={2}>
          <Typography variant="body2">
            {selectedType === ClusterType.EKS
              ? "Select the AWS account that contains your clusters"
              : "Select the GCP project/organization that contains your clusters"}
          </Typography>

          <Stack spacing={2} sx={{ width: "50%" }}>
            {selectedType === ClusterType.EKS && (
              <AwsAccountSelector
                selectedAccountId={selectedProjectId as string}
                onSelect={onSelectProjectId}
                targetFeatures={targetAwsFeatures}
                filterAvailableOptions={(account) => awsAccountsWithClusters.includes(account.accountId)}
              />
            )}

            {selectedType === ClusterType.GKE && (
              <GcpCloudConnectSelector
                value={selectedProjectId as GCPCloudConnectData}
                onChange={onSelectProjectId}
                targetCategories={targetGcpCategories}
                filterAvailableOptions={(cloudConnect) => gcpCloudConnectsWithClusters.includes(cloudConnect.id)}
              />
            )}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default SelectClusterTypeStep;
