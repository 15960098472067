import { useCallback } from "react";

import { IntegrationModel } from "@doitintl/cmp-models";
import { getBatch, getCollection } from "@doitintl/models-firestore";
import { getMessaging, getToken } from "firebase/messaging";

import { useAuthContext } from "../Context/AuthContext";
import { isMessagingSupported, serverTimestamp } from "./firebase";
import mixpanel from "./mixpanel";

const getNotificationToken = async () => {
  const messaging = getMessaging();

  try {
    await Notification.requestPermission();
  } catch (e) {
    mixpanel.people.set({
      notifications: "blocked",
    });
  }

  // Register the service worker and get the token
  const registration = await navigator.serviceWorker.register(process.env.__FCM_SERVICE_FILE__ as string);
  const token = await getToken(messaging, { serviceWorkerRegistration: registration });

  if (token) {
    mixpanel.people.set({
      notifications: "allowed",
    });
  }

  return token;
};

export const useNotificationsPermission = () => {
  const { currentUser, userId } = useAuthContext({ mustHaveUser: true });

  const requestNotificationPermission = useCallback(async () => {
    const isSupported = await isMessagingSupported();
    if (isSupported && Notification.permission === "default") {
      const token = await getNotificationToken();
      if (token) {
        const batch = getBatch();

        const data = {
          displayName: currentUser.displayName ?? undefined,
          email: currentUser.email,
          token,
          topics: [],
          timestamp: serverTimestamp(),
        };

        const basePath = getCollection(IntegrationModel).doc("fcm").collection("tokens");
        batch.set(basePath.doc(currentUser.uid), data);
        batch.set(basePath.doc(userId), data);

        await batch.commit();
      }
    }
  }, [currentUser.displayName, currentUser.email, currentUser.uid, userId]);

  return { requestNotificationPermission };
};
