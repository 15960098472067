import { useCallback, useEffect, useState } from "react";

import { type ClusterWithCloudConnect, type KubernetesFeature } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Box, Card, CardContent, CircularProgress, Stack, Typography, useTheme } from "@mui/material";

import { useApiContext } from "../../../../api/context";
import { consoleErrorWithSentry } from "../../../../utils";
import {
  type ClusterFeatureActivationPayload,
  updateClusterFeatureActivationStatus,
  updateClusterFeatureHealthStatus,
} from "../../api";
import { useKubernetesFeatures } from "../../hooks";
import FeatureStatus from "./components/FeatureConnectionStatus/FeatureConnectionStatus";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

interface TestConnectionStepProps {
  projectId: string | null;
  clusters: ClusterWithCloudConnect[] | null;
  selectedFeatures: KubernetesFeature[];
}

const TestConnectionStep = ({ projectId, clusters, selectedFeatures }: TestConnectionStepProps) => {
  const api = useApiContext();

  const [availableFeatures] = useKubernetesFeatures();
  const [connectionHasBeenTested, setConnectionHasBeenTested] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retryLoading, setRetryLoading] = useState(false);

  const theme = useTheme();

  const cluster = clusters?.[0];

  const updateClusterFeatures = useCallback(
    async (cluster) => {
      if (!cluster?.id) return;

      const payload: ClusterFeatureActivationPayload = { newFeatureStates: {} };

      availableFeatures?.forEach((feature) => {
        const isSelected = selectedFeatures.some((selectedFeature) => selectedFeature.id === feature.id);
        payload.newFeatureStates[feature.id] = isSelected;
      });

      try {
        await updateClusterFeatureActivationStatus(api, cluster?.id, payload);
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    },
    [api, availableFeatures, selectedFeatures]
  );

  const updateFeaturesAndTestConnection = useCallback(
    async (cluster) => {
      await updateClusterFeatures(cluster);
      return updateClusterFeatureHealthStatus(api, cluster?.id);
    },
    [api, updateClusterFeatures]
  );

  const handleTestConnection = useCallback(async () => {
    if (!cluster?.id || loading) return;

    setLoading(true);
    try {
      setConnectionHasBeenTested(false);

      const promises = clusters?.map(updateFeaturesAndTestConnection);
      await Promise.allSettled(promises ?? []);
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setLoading(false);
      setConnectionHasBeenTested(true);
    }
  }, [cluster?.id, loading, clusters, updateFeaturesAndTestConnection]);

  useEffect(() => {
    if (!connectionHasBeenTested && selectedFeatures.length > 0 && availableFeatures) {
      handleTestConnection();
    }
  }, [handleTestConnection, connectionHasBeenTested, availableFeatures, selectedFeatures.length]);

  const handleRetryConnection = async () => {
    if (!cluster?.id) return;

    setRetryLoading(true);
    try {
      const promises = clusters?.map(updateFeaturesAndTestConnection);
      await Promise.allSettled(promises ?? []);
    } catch (error) {
      consoleErrorWithSentry(error);
    } finally {
      setRetryLoading(false);
    }
  };

  if (!cluster) {
    return <CircularProgress />;
  }

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">Test cluster connection</Typography>
        <SelectedAccountAndCluster projectId={projectId} selectedClusters={clusters} />
      </Stack>

      {loading && !connectionHasBeenTested && (
        <Stack
          spacing={1}
          sx={{ p: 5, display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}
        >
          <CircularProgress />
          <Typography variant="subtitle2" fontWeight={500}>
            Testing cluster connection
          </Typography>
          <Typography variant="body2">Please wait before leaving this page</Typography>
        </Stack>
      )}

      {connectionHasBeenTested && (
        <Card>
          <CardContent sx={{ backgroundColor: theme.palette.general.backgroundDark }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Stack spacing={1}>
                <Typography fontWeight={500} variant="body1">
                  Test summary
                </Typography>
              </Stack>
              <Box>
                <LoadingButton
                  onClick={handleRetryConnection}
                  loading={retryLoading}
                  variant="outlined"
                  sx={{ width: "fit-content" }}
                >
                  Retry
                </LoadingButton>
              </Box>
            </Box>
          </CardContent>
          {selectedFeatures.map((feature) => (
            <FeatureStatus
              selectedFeatures={selectedFeatures}
              loading={retryLoading}
              key={feature.id}
              clusters={clusters}
              feature={feature}
            />
          ))}
        </Card>
      )}
    </Stack>
  );
};

export default TestConnectionStep;
