import { Box, Card, Radio, Stack, Typography, useTheme } from "@mui/material";

import { type ClusterTypeSelectorOption } from "./ClusterTypeSelector";

interface ClusterTypeOptionProps {
  option: ClusterTypeSelectorOption;
  onClick: () => void;
  isSelected: boolean;
  disabled?: boolean;
}

const ClusterTypeOption = ({ option, onClick, isSelected, disabled }: ClusterTypeOptionProps) => {
  const theme = useTheme();

  const handleClick = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <Card
      onClick={handleClick}
      sx={{
        display: "flex",
        width: "100%",
        borderColor: isSelected ? "primary.main" : theme.palette.general.outlineBorder,
        borderWidth: 1,
        borderRadius: 2,
        height: 70,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <Box sx={{ pl: 1, display: "flex", alignItems: "center" }}>
          <Radio size="small" color="primary" disabled={!!disabled} checked={isSelected} />
        </Box>
        <img
          src={option.logoSrc}
          width={24}
          alt={option.label}
          title={option.label}
          style={{
            opacity: disabled ? 0.5 : 1,
          }}
        />
        <Typography
          sx={{ fontWeight: isSelected ? 500 : "auto" }}
          color={disabled ? "textSecondary" : "inherit"}
          component="div"
          variant="body1"
        >
          {option.label}
        </Typography>
      </Stack>
    </Card>
  );
};

export default ClusterTypeOption;
