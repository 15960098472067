import { useCallback, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { routes } from "../../constants/navigation";
import { useAuthContext } from "../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../utils";
import { useAnnounceKitToken } from "../../utils/announcekit/announcekitHooks";
import useQueryString from "../hooks/useQueryString";

export function AnnouncekitAuthRedirect() {
  const { tokenValue, currentUser } = useAuthContext();
  const qs = useQueryString();
  const history = useHistory();
  const theme = useTheme();
  const { announcekitToken } = useAnnounceKitToken();

  const redirectHandler = useCallback(
    async (redirectParam: unknown) => {
      if (typeof redirectParam !== "string") {
        throw new Error(`typeof redirectParam !== "string"`);
      }
      const redirectUrl = new URL(redirectParam);
      if (!redirectUrl?.hostname.endsWith("announcekit.app") && !redirectUrl?.hostname.endsWith("doit.com")) {
        throw new Error(`redirect_url param has an invalid redirect hostname: ${redirectUrl?.hostname}`);
      }

      if (announcekitToken) {
        redirectUrl.searchParams.set("user_token", announcekitToken);
      }
      window.location.href = redirectUrl.toString();
    },
    [announcekitToken]
  );

  useEffect(() => {
    if (!currentUser?.uid) {
      history.push(routes.signin);
      return;
    }
    if (!tokenValue) {
      return; // waiting until got user token
    }
    redirectHandler(qs.redirect_url).catch((e) => {
      e.message = `failed to redirect user back to announcekit with error: ${e.message}`;
      consoleErrorWithSentry(e);
      history.push("/");
    });
  }, [qs, currentUser?.uid, tokenValue, redirectHandler, history]);

  return (
    <Stack direction="row" sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "50vh" }}>
      <Typography variant="subtitle1">Redirecting... </Typography>
      <CircularProgress disableShrink={true} color="primary" size={24} sx={{ margin: theme.spacing(2) }} />
    </Stack>
  );
}
