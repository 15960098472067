import {
  type BudgetConfig,
  CloudAnalyticsModel,
  type CloudAnalyticsModelBudgetModel,
  Roles,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { type Customer } from "../../../types";
import { deleteField, serverTimestamp } from "../../../utils/firebase";
import { NoReplyEmail } from "./shared";

export const deleteManyBudgets = async (api: AxiosInstance, customerId: string, budgetIds: string[]) => {
  await api.delete(`/v1/customers/${customerId}/analytics/budgets/deleteMany`, { data: { ids: budgetIds } });
};

export const updateBudget = async ({
  ref,
  name,
  description,
  config,
}: {
  name: string;
  description: string;
  config: BudgetConfig;
  ref: ModelReference<CloudAnalyticsModelBudgetModel>;
}) => {
  await ref.update({
    name,
    description,
    config,
    timeModified: serverTimestamp(),
    draft: false,
  });
};

export const createNewBudget = async ({
  name,
  description,
  config,
  email,
  customer,
}: {
  name: string;
  description: string;
  config: BudgetConfig;
  email: string;
  customer: Customer;
}) => {
  const ref = getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets").newDoc();

  await ref.set({
    customer: customer.ref,
    name,
    description,
    config,
    public: null,
    collaborators: [
      {
        email,
        role: Roles.OWNER,
      },
    ],
    recipients: customer.presentationMode?.isPredefined ? [NoReplyEmail] : [email],
    recipientsSlackChannels: [],
    timeCreated: serverTimestamp(),
    timeModified: serverTimestamp(),
    isValid: true,
  });

  return ref;
};

export const updateBudgetSubscriptions = async (
  api: AxiosInstance,
  customerId: string,
  budgetId: string,
  newRecipients: string[]
) => {
  await getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets").doc(budgetId).update({
    recipients: newRecipients,
  });
  await api.patch(`/v1/customers/${customerId}/analytics/budgets/${budgetId}/update-enforced-by-metering`);
};

export const deleteBudgetUtilizationAndData = async (budgetId: string) => {
  const timeModified = serverTimestamp();
  await getCollection(CloudAnalyticsModel).doc("budgets").collection("cloudAnalyticsBudgets").doc(budgetId).update({
    utilization: deleteField(),
    data: deleteField(),
    timeModified,
  });
};
