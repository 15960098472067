import * as yup from "yup";
import { create } from "zustand";

// Define the store state type
type AllocationDndStore = {
  items: Record<string, string[]>;
  itemsOriginal: Record<string, string[]>;
  hasUnallocatedCosts: boolean;
  unallocatedCosts: string;
  isGroup: boolean;
  isAllocationOwner: boolean;
  setIsAllocationOwner: (value: boolean) => void;
  setIsGroup: (value: boolean) => void;
  setHasUnallocatedCosts: (value: boolean) => void;
  setUnallocatedCosts: (value: string) => void;
  addItem: (item: string) => void;
  removeItem: (item: string) => void;
  setItems: (items: Record<string, string[]>) => void;
  setAllocationName: (value: string) => void;
  clear: () => void;
  allocationName: string;
  fieldErrors: Record<string, string>;
};

const formSchema = yup.object({
  unallocatedCosts: yup.string().trim().min(1).required("Unallocated name is required"),
});

// Create the store
const useAllocationDndStore = create<AllocationDndStore>((set, get) => ({
  // Initial state
  items: { main: [] },
  itemsOriginal: { main: [] },
  hasUnallocatedCosts: false,
  unallocatedCosts: "Unallocated",
  fieldErrors: {},
  allocationName: "",
  isGroup: false,
  isAllocationOwner: true,

  setIsAllocationOwner: (value) => {
    set(() => ({ isAllocationOwner: value }));
  },

  setIsGroup: (value) => {
    set(() => ({ isGroup: value }));
  },

  setHasUnallocatedCosts: (value) => {
    set(() => ({ hasUnallocatedCosts: value }));
  },

  setAllocationName: (value) => {
    set(() => ({ allocationName: value }));
  },

  setUnallocatedCosts: async (value) => {
    set(() => ({ unallocatedCosts: value }));
    try {
      const { unallocatedCosts } = get();
      await formSchema.validate({ unallocatedCosts }, { abortEarly: false });
      set({ fieldErrors: {} });
      // If validation passes, update external save permission store
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const errors = error.inner.reduce(
          (acc, err) => ({
            ...acc,
            ...(err.path ? { [err.path]: err.message } : {}),
          }),
          {}
        );
        set({ fieldErrors: errors });
      }
    }
  },

  addItem: (item) => {
    set((state) => ({
      items: { main: [...state.items.main, item] },
      itemsOriginal: { main: [...state.items.main, item] },
    }));
  },

  removeItem: (item) => {
    set((state) => ({ items: { main: state.items.main.filter((id) => id !== item) } }));
  },

  setItems: (items) => {
    set({ items: { main: items.main } });
  },
  clear: () => {
    set({
      allocationName: "",
      unallocatedCosts: "Unallocated",
      hasUnallocatedCosts: false,
      isGroup: false,
      isAllocationOwner: true,
      items: { main: [] },
      itemsOriginal: { main: [] },
      fieldErrors: {},
    });
  },
}));

export default useAllocationDndStore;
