import { type ChangeEvent, Fragment, type ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useParams } from "react-router-dom";
import { Aggregator, CurrencyCodes, Metadata, Metric, TimeInterval } from "@doitintl/cmp-models";
import { FormControlLabel, Switch } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { DateTime } from "luxon";

import { globalText } from "../../../assets/texts";
import { budgetTxt } from "../../../assets/texts/CloudAnalytics/budget";
import { DefinitionList, DefinitionListDesc, DefinitionListTerm } from "../../../Components/DefinitionList";
import { useBudgets } from "../../../Components/hooks/cloudAnalytics/budgets/useBudgets";
import { useCloudAnalyticsTransforms } from "../../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import useFormatter from "../../../Components/hooks/useFormatter";
import { MetadataCard } from "../../../Components/MetadataCard";
import { useAttributionsContext } from "../../../Context/AttributionsContext";
import { CSPCustomerID, sanitizeDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import mixpanel from "../../../utils/mixpanel";
import { BudgetTypes } from "../utilities";
import { getAttributionScope } from "../utils/getScope";
import BudgetPerformanceChart from "./BudgetPerformanceChart";
import { type BudgetChartData, type BudgetChartPointData, type BudgetDefinitionListItem } from "./budgetViewTypes";
import { BUDGET_CHART_HEIGHT, DEFAULT_BUDGET_AMOUNT, MAX_DIMENSIONS_NUM } from "./const";
import { useBudgetRefresh } from "./hooks";
import LoadingBudgetCardWrapper from "./LoadingBudgetCardWrapper";
import { getAlertAmountFromPercentage } from "./shared";
import {
  formatBudgetValue,
  getBudgetRowsDates,
  getCumulativeForecastedRowsValues,
  getCumulativeRowsValues,
  getDataRowsValues,
  getFilteredDatesUpToToday,
  getFixedBudgetTimeRange,
  getFrequencyPhrase,
  previewAmountByInterval,
} from "./utils";
import { ViewBudgetHeader } from "./ViewBudgetHeader";
import type { Budget } from "../../../types";

const defaultRowFirstDateIndex = 0;
const forecastedRowFirstDateIndex = 1;

export const ViewBudget = () => {
  const { attributions } = useAttributionsContext();
  const [, budgets] = useBudgets();
  const transforms = useCloudAnalyticsTransforms();
  const [budget, setBudget] = useState<Budget | undefined>(undefined);
  const [isBudgetLoading, setIsBudgetLoading] = useState<boolean>(true);

  const { budgetId } = useParams<{ budgetId: string }>();
  const { handleRefresh, loading: loadingRefreshedBudget } = useBudgetRefresh({
    isViewPage: true,
    budgetParams: {
      budgetId,
    },
  });

  const theme = useTheme();
  const { isMobile } = useFullScreen("lg");

  const customerId = useCustomerId();
  const isCSP = customerId === CSPCustomerID;

  const variancePositiveColor = theme.palette.success.main;
  const varianceNegativeColor = theme.palette.error.main;
  const amount = budget?.data.config.amount || DEFAULT_BUDGET_AMOUNT;
  const alerts = budget?.data.config.alerts.map((alert) => ({
    ...alert,
    amount: getAlertAmountFromPercentage(alert.percentage, amount),
  }));

  const timeInterval = budget?.data.config.timeInterval || TimeInterval.MONTH;
  const actual = budget?.data.utilization?.current || 0;
  const type = budget?.data.config.type;
  const frequency = type === BudgetTypes.RECURRING ? budgetTxt.RECCURING : budgetTxt.FIXED_PERIOD;
  const currency = budget?.data.config.currency || CurrencyCodes.USD;
  const startPeriod = budget?.data.config.startPeriod
    ? DateTime.fromJSDate(budget?.data.config.startPeriod.toDate()).toUTC()
    : sanitizeDate(DateTime.utc());
  const endPeriod = budget?.data.config.endPeriod
    ? DateTime.fromJSDate(budget?.data.config.endPeriod.toDate()).toUTC()
    : sanitizeDate(DateTime.utc());
  const isFixedBudget: boolean = type === BudgetTypes.FIXED;
  const isBudgetEndDateExists = !!budget?.data.config?.endPeriod;
  const budgetDateTerm = `Budget ${isBudgetEndDateExists ? "dates:" : "start date"}`;
  const budgetDates = `${startPeriod.toFormat("d MMM yyyy")}${isBudgetEndDateExists && isFixedBudget ? ` - ${endPeriod.toFormat("d MMM yyyy")}` : ""}`;
  const forecastedDate = budget?.data.utilization?.forecastedTotalAmountDate
    ? DateTime.fromJSDate(budget?.data.utilization?.forecastedTotalAmountDate?.toDate()).toUTC()
    : null;
  const amortizedCost = Boolean(budget?.data.config?.amortizedCost);
  const rows = budget?.data.data?.rows ?? null;
  const forecastRows = budget?.data.data?.forecastRows ?? null;
  const dataTimeInterval = budget?.data?.data?.timeInterval ?? TimeInterval.MONTH;

  const shouldUseForecastRows = forecastRows && Object.keys(forecastRows).length > 0;
  const shouldUseRows = rows && Object.keys(rows).length > 0;

  const fixedBudgetTimeRange = budget ? getFixedBudgetTimeRange(budget, dataTimeInterval) : [];

  const actualDataPreviewAmount = isFixedBudget
    ? getFilteredDatesUpToToday(fixedBudgetTimeRange)
    : previewAmountByInterval[dataTimeInterval];

  const budgetRowsDates = useMemo(
    () => getBudgetRowsDates(rows, forecastRows, dataTimeInterval),
    [rows, forecastRows, dataTimeInterval]
  );

  const noBudgetDataAvailable = !budget?.data?.data;
  const budgetRowsEmpty = !shouldUseForecastRows && !shouldUseRows;
  const showSkeleton = (!budget && !budgetRowsDates.length) || (budget && budgetRowsEmpty);
  const isBudgetUnavailable = !budget;
  const isRefreshedBudgetLoading = isBudgetLoading || loadingRefreshedBudget || noBudgetDataAvailable;
  const isBudgetEmpty = isBudgetUnavailable && !showSkeleton && !isRefreshedBudgetLoading;
  const isBudgetChartRowsEmpty = !isBudgetUnavailable && budgetRowsEmpty;
  const hasRefreshedRef = useRef(false);

  // Rows data points
  const rowsBudgetData = useMemo(
    () => getDataRowsValues(rows, dataTimeInterval, defaultRowFirstDateIndex, amortizedCost, isCSP),
    [amortizedCost, isCSP, rows, dataTimeInterval]
  );

  // Forecasted rows data points
  const forecastRowsBudgetData = useMemo(
    () => getDataRowsValues(forecastRows, dataTimeInterval, forecastedRowFirstDateIndex, false, false),
    [forecastRows, dataTimeInterval]
  );

  // Budget amount total data points
  const budgetAmountData = budgetRowsDates.map((timestamp) => ({ x: timestamp, y: amount }));

  // Forecast data points
  const forecasted = budget?.data.utilization?.forecasted ?? 0;

  const maxBudgetByInterval = budgetRowsDates.length > 0 ? amount / budgetRowsDates.length : 0;

  const budgetAmountToDateData = budgetRowsDates
    .slice(0, shouldUseRows ? Object.keys(rowsBudgetData).length : actualDataPreviewAmount)
    .map((i, index: number) => ({
      x: i,
      y: maxBudgetByInterval * (index + 1),
    }));

  // Current period data
  const budgetAmountToDate = budgetAmountToDateData[budgetAmountToDateData.length - 1]?.y ?? null;
  const forecastToUse = shouldUseForecastRows ? forecastRowsBudgetData : [];

  const forecastAmountForEntirePeriod = forecastToUse.length && type === BudgetTypes.RECURRING ? forecasted : null;
  const variance = !isRefreshedBudgetLoading ? budgetAmountToDate - actual : null;

  const formatter = useFormatter({ aggregator: Aggregator.TOTAL, currency, metric: Metric.COST });

  useEffect(() => {
    const refreshIfNeeded = async () => {
      const foundBudget = budgets.find((b) => b.snapshot.id === budgetId);

      if (foundBudget) {
        setBudget(foundBudget);

        if (!foundBudget.data.utilization && !hasRefreshedRef.current) {
          await handleRefresh();

          hasRefreshedRef.current = true;
        }
        setIsBudgetLoading(false);
      }
    };

    refreshIfNeeded();
  }, [budgets, budgetId, handleRefresh]);

  useEffect(() => {
    if (!budgetId) {
      return;
    }
    mixpanel.track("analytics.budgets.view", { budgetId });
  }, [budgetId]);

  const budgetDimensionArray = useMemo(() => {
    const filters = budget?.data.config.filters ?? [];
    if (filters.length > 0) {
      return filters.flatMap((filter) => {
        const filterKey = filter.type === Metadata.ATTRIBUTION_GROUP ? filter.type : filter.id;
        return filter.values?.map((value) => transforms?.[filterKey]?.(value) ?? value);
      });
    }

    const scope = getAttributionScope(budget?.data.config.scope ?? [], attributions);
    if (scope.length > 0) {
      return scope.map((scopeItem) => scopeItem?.data?.name);
    }

    return [];
  }, [budget, attributions, transforms]);

  const [isCumulativeChart, setIsCumulativeChart] = useState(true);

  const chartData = useMemo(() => {
    const actualChartData = shouldUseRows ? rowsBudgetData : [];
    const forecastChartData = shouldUseForecastRows ? (forecastRowsBudgetData as BudgetChartData[]) : [];

    const newChartData: BudgetChartPointData = {
      actualData: actualChartData,
      budgetAmountData: [],
      budgetAmountToDateData: [],
      forecastData: forecastChartData,
    };

    if (isCumulativeChart) {
      const cumulativeActualData = getCumulativeRowsValues(actualChartData);
      const cumulativeLastDataPoint = cumulativeActualData[cumulativeActualData.length - 1]?.y;

      newChartData.actualData = cumulativeActualData;
      newChartData.budgetAmountData = budgetAmountData;
      newChartData.budgetAmountToDateData = budgetAmountToDateData;
      newChartData.forecastData = getCumulativeForecastedRowsValues(forecastChartData, cumulativeLastDataPoint);
    }

    return newChartData;
  }, [
    shouldUseRows,
    rowsBudgetData,
    shouldUseForecastRows,
    forecastRowsBudgetData,
    budgetAmountData,
    budgetAmountToDateData,
    isCumulativeChart,
  ]);

  const handleToggleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setIsCumulativeChart(event.target.checked);
  }, []);

  const chartContent = useMemo(() => {
    if (isRefreshedBudgetLoading) {
      return (
        <Stack
          sx={{
            position: "relative",
            height: BUDGET_CHART_HEIGHT,
          }}
        >
          <Skeleton animation="wave" variant="rectangular" width="100%" height={BUDGET_CHART_HEIGHT} />
        </Stack>
      );
    }

    if (isBudgetEmpty || isBudgetChartRowsEmpty) {
      return (
        <Stack
          component={Paper}
          variant="outlined"
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: BUDGET_CHART_HEIGHT,
          }}
        >
          <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>{budgetTxt.NO_DATA_YET}</Typography>
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <Typography variant="subtitle1" sx={{ whiteSpace: "pre-line" }}>
              {`${budgetTxt.NO_BILLUNG_DATA}\n${budgetTxt.CHECK_LATER_AGAIN}`}
            </Typography>
          </Box>
        </Stack>
      );
    }

    return (
      <Box
        sx={{
          height: BUDGET_CHART_HEIGHT,
        }}
      >
        <Box display="flex" justifyContent="flex-end" alignItems="center" mb={1}>
          <FormControlLabel
            control={<Switch checked={isCumulativeChart} onChange={handleToggleChange} />}
            label="Show cumulative"
          />
        </Box>
        <BudgetPerformanceChart
          isCumulative={isCumulativeChart}
          theme={theme}
          categories={budgetRowsDates}
          valueFormatter={formatter}
          actualData={chartData.actualData}
          budgetAmountData={chartData.budgetAmountData}
          budgetAmountToDateData={chartData.budgetAmountToDateData}
          forecastData={chartData.forecastData}
          alerts={alerts ?? []}
        />
      </Box>
    );
  }, [
    isRefreshedBudgetLoading,
    isBudgetEmpty,
    isBudgetChartRowsEmpty,
    isCumulativeChart,
    handleToggleChange,
    theme,
    budgetRowsDates,
    formatter,
    chartData.actualData,
    chartData.budgetAmountData,
    chartData.budgetAmountToDateData,
    chartData.forecastData,
    alerts,
  ]);

  const errorContent = (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: 500, fontSize: 18, mb: 1.5 }}>{budgetTxt.NO_DATA_YET}</Typography>
    </Stack>
  );

  const renderDefinitionListItems = (items: BudgetDefinitionListItem[]): ReactNode =>
    items.map(({ term, value, loading, color }) => (
      <Fragment key={term}>
        <DefinitionListTerm>
          <LoadingBudgetCardWrapper loading={loading} wrapperWidth="100%">
            {term}
          </LoadingBudgetCardWrapper>
        </DefinitionListTerm>

        <DefinitionListDesc>
          <LoadingBudgetCardWrapper loading={loading} wrapperWidth="60%">
            {color ? <span style={{ color }}>{value}</span> : value}
          </LoadingBudgetCardWrapper>
        </DefinitionListDesc>
      </Fragment>
    ));

  const budgetSummaryItems = [
    { term: budgetTxt.BUDGET_AMOUNT_TERM, value: formatBudgetValue(amount, currency), loading: isBudgetUnavailable },
    {
      term: isFixedBudget ? budgetTxt.FIXED_TYPE : budgetTxt.TYPE_AND_FREQUENCY,
      value: getFrequencyPhrase(timeInterval, frequency, isFixedBudget),
      loading: isBudgetUnavailable,
    },
    { term: budgetDateTerm, value: budgetDates, loading: isBudgetUnavailable },
    {
      term: budgetTxt.DIMENSION,
      value: (
        <>
          {budgetDimensionArray.slice(0, MAX_DIMENSIONS_NUM).join(", ")}
          {budgetDimensionArray.length > MAX_DIMENSIONS_NUM && ` +${budgetDimensionArray.length - MAX_DIMENSIONS_NUM}`}
        </>
      ),
      loading: isBudgetUnavailable,
    },
    {
      term: budgetTxt.AMORTIZED_COST,
      value: amortizedCost ? globalText.YES : globalText.NO,
      loading: isBudgetUnavailable,
    },
  ];

  const currentPeriodItems = [
    {
      term: budgetTxt.BUDGET_AMOUNT_TO_DATE_TERM,
      value: formatBudgetValue(budgetAmountToDate, currency),
      loading: isRefreshedBudgetLoading,
    },
    { term: budgetTxt.ACTUALS_TO_DATE, value: formatBudgetValue(actual, currency), loading: isRefreshedBudgetLoading },
    {
      term: budgetTxt.VARIANS_TO_DATE,
      value:
        variance !== null ? (
          <span style={{ color: variance < 0 ? varianceNegativeColor : variancePositiveColor }}>
            {formatBudgetValue(variance, currency)}
          </span>
        ) : (
          budgetTxt.DATA_LOADING
        ),
      loading: isRefreshedBudgetLoading,
    },
    {
      term: budgetTxt.FORECASTED_AMOUNT_FOR_ENTIRE_PERIOD,
      value: formatBudgetValue(forecastAmountForEntirePeriod ?? 0, currency),
      loading: isRefreshedBudgetLoading,
    },
    {
      term: budgetTxt.FORECASTED_TO_HIT_BUDGET_AMOUNT,
      value: forecastedDate ? forecastedDate.toFormat("d MMMM yyyy") : budgetTxt.NO_FORECASTED_DATE,
      loading: isRefreshedBudgetLoading,
    },
  ];

  return (
    <>
      {budget && <ViewBudgetHeader budget={budget} />}
      <Stack
        data-cy="view-budget-content"
        sx={{
          flexDirection: "column",
          flexWrap: "nowrap",
          gap: 2.4,
        }}
      >
        {chartContent}
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          sx={{
            justifyContent: "stretch",
            mt: 6,
          }}
        >
          <MetadataCard
            title={budgetTxt.BUDGET_SUMMARY}
            loading={isBudgetUnavailable}
            error={isBudgetEmpty}
            errorContent={errorContent}
          >
            <DefinitionList>{renderDefinitionListItems(budgetSummaryItems)}</DefinitionList>
          </MetadataCard>

          <MetadataCard
            title={isFixedBudget ? budgetTxt.FIXED_BUDGET_PERIOD_TERM : budgetTxt.CURRENT_PERIOD}
            loading={isBudgetUnavailable}
            error={isBudgetEmpty}
            errorContent={errorContent}
          >
            <DefinitionList>{renderDefinitionListItems(currentPeriodItems)}</DefinitionList>
          </MetadataCard>
        </Stack>
      </Stack>
    </>
  );
};
