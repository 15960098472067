import { useEffect, useMemo } from "react";

import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import { Autocomplete, Checkbox, Chip, MenuItem, TextField, Typography } from "@mui/material";

import { useContractsContext } from "../../Context/customer/ContractsContext";
import { type Contract } from "../../types";
import { providers } from "./consts";
import { getContractName } from "./utils";

type Props = {
  selectedContractIds: string[];
  setSelectedContractIds: (contractIds: string[]) => void;
  filterType?: string;
};

export const ContractsSelector = ({ selectedContractIds, setSelectedContractIds, filterType }: Props) => {
  const { contracts } = useContractsContext();

  const filteredContracts = useMemo(() => {
    if (filterType) {
      return contracts.filter((contract) => contract.type === filterType && contract.isCommitment === true);
    }

    const providerValues = providers.map((provider) => provider.value);
    return contracts.filter((contract) => providerValues.includes(contract.type) && contract.isCommitment === true);
  }, [contracts, filterType]);

  // When filterType changes, keep only contracts that match the current filter
  useEffect(() => {
    if (!filterType || selectedContractIds.length === 0) return;

    const validContractIds = filteredContracts.map((contract) => contract.id);
    const filteredSelectedIds = selectedContractIds.filter((id) => validContractIds.includes(id));

    if (filteredSelectedIds.length !== selectedContractIds.length) {
      setSelectedContractIds(filteredSelectedIds);
    }
  }, [filterType, filteredContracts, selectedContractIds, setSelectedContractIds]);

  // Get the selected contracts for display
  const selectedContracts = useMemo(
    () => contracts.filter((contract) => selectedContractIds.includes(contract.id)),
    [contracts, selectedContractIds]
  );

  const handleChange = (_: React.SyntheticEvent, value: Contract[]) => {
    const newSelectedIds = value.map((contract) => contract.id);
    setSelectedContractIds(newSelectedIds);
  };

  return (
    <Autocomplete
      multiple
      disableCloseOnSelect
      options={filteredContracts}
      getOptionLabel={(option) => option.type}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={selectedContracts}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            variant="filled"
            size="small"
            label={getContractName(option.type, option.startDate.toDate(), option.endDate?.toDate())}
            sx={{ backgroundColor: "#BBDEFB", color: "#01579B" }}
            {...getTagProps({ index })}
            key={index}
          />
        ))
      }
      renderOption={(props, option, { selected }) => (
        <MenuItem {...props} key={option.timestamp.toMillis()}>
          <Checkbox
            icon={<CheckBoxOutlineBlank fontSize="small" />}
            checkedIcon={<CheckBox fontSize="small" />}
            checked={selected}
          />
          <Typography>{getContractName(option.type, option.startDate.toDate(), option.endDate?.toDate())}</Typography>
        </MenuItem>
      )}
      renderInput={(params) => (
        <TextField {...params} variant="outlined" label="Select contract(s)" fullWidth size="small" />
      )}
      onChange={handleChange}
    />
  );
};
