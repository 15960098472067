import { useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouteLink, useHistory } from "react-router-dom";
import { getBatch } from "@doitintl/models-firestore";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import {
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import Grid from "@mui/material/Grid2";
// Material UI
import { lighten } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import find from "lodash/find";
import { DateTime } from "luxon";

import { DeleteButton } from "../../Components/DeleteButton";
import { FilterContextProvider } from "../../Components/FilterTable/Context";
import EnhancedTableFilter from "../../Components/FilterTable/EnhancedTableFilter";
import Hide from "../../Components/HideChildren/Hide";
import useTableState from "../../Components/hooks/useTableState";
import { Loader } from "../../Components/Loader";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useImpersonateContext } from "../../Context/impersonateContext";
import { useUserContext } from "../../Context/UserContext";
import { ThemeModes } from "../../muiThemeTypes";
import { type InviteWithRole, type Role, type UserOrInviteWithRole, type UserWithRole } from "../../types";
import { type DataColumn } from "../../types/FilterTable";
import { consoleErrorWithSentry } from "../../utils";
import { capitalizeStartCase, jobFunction as jobFunctions, permissions, sanitizeDate } from "../../utils/common";
import { useFullScreen } from "../../utils/dialog";
import { increment } from "../../utils/firebase";
import mixpanel from "../../utils/mixpanel";
import { type NestedObjectLeaves } from "../../utils/NestedKeyOf";
import { text } from "../UserView/consts";
import { BulkUpdateUsersForm } from "./BulkUpdateUsersForm";
import { updateUsers } from "./db";
import { InviteUserButton } from "./InviteUserButton";
import { InviteUserDialog } from "./InviteUserDialog";

const headCells = [
  { id: "email", showMobile: false, label: "E-Mail", width: "20%" },
  { id: "displayName", showMobile: true, label: "Name", width: "10%" },
  { id: "jobFunction", showMobile: false, label: "Job Function", width: "15%" },
  { id: "status", showMobile: false, label: "Status", width: "10%" },
  { id: "roles.name", showMobile: false, label: "Role", width: "10%" },
  { id: "lastLogin", showMobile: false, label: "Last Login", width: "99%" },
] as const;

const filterColumns: DataColumn[] = [
  {
    path: "displayName",
    label: "Name",
    toOption: (value) => ({
      value: value ?? "",
      label: value ?? "",
    }),
  },
  {
    path: "email",
    label: "Email",
  },
  {
    path: "jobFunction",
    label: "Job Function",
    comparators: ["==", "!="],
    toOption: (value) => ({
      value: jobFunctions[value - 1]?.value ?? -1,
      label: jobFunctions[value - 1]?.name ?? "",
    }),
  },
  {
    path: "status",
    label: "Status",
    toOption: (value) => ({ value, label: capitalizeStartCase(value) }),
  },
  {
    path: "roles.name",
    label: "Role",
    toOption: (value) => (value ? { value, label: value } : { value: "", label: "" }),
  },
  {
    path: "lastLogin",
    label: "Last Login",
    comparators: ["<", "<=", ">", ">="],
    placeholder: "YYYY-MM-DD",
    type: "DateTime",
    transform: (value: DateTime) => (value.isValid ? sanitizeDate(value) : null),
    validate: (value: DateTime) => value.isValid,
    toOption: (value: DateTime) => {
      if (!value) {
        return { value: "", label: "" };
      }

      const strValue = value.toLocaleString(DateTime.DATE_MED);
      return {
        value: strValue,
        label: strValue,
      };
    },
  },
];

const useStyles = makeStyles((theme) => ({
  root: {},
  tableWrapper: {
    overflowX: "auto",
  },
  table: {
    width: "100%",
  },
  tableToolbar: {
    padding: theme.spacing(1),
  },
  centered: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
  actions: {
    display: "flex",
    marginRight: theme.spacing(-1),
  },
  highlight: {
    color: theme.palette.text.primary,
    backgroundColor:
      theme.palette.mode === ThemeModes.DARK
        ? lighten(theme.palette.secondary.light, 0.45)
        : lighten(theme.palette.secondary.light, 0.85),
  },
  spacer: {
    flex: "1 1 100%",
  },
  title: {
    flex: "0 0 auto",
  },
  customerActions: {
    display: "flex",
  },
  badge: {
    top: "15%",
    right: -6,
    backgroundColor: green[500],
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

type HeadProps = {
  onClick: () => void;
  rowCount: number;
  numSelected: number;
  order?: "asc" | "desc";
  sortField?: string;
  onRequestSort: (sort: NestedObjectLeaves<Row>) => () => void;
};

const EnhancedTableHead = ({ order, onClick, sortField, numSelected, rowCount, onRequestSort }: HeadProps) => {
  const classes = useStyles();
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            inputProps={{ "aria-label": "select all users" }}
            onClick={onClick}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <Hide key={headCell.id} mdDown={!headCell.showMobile}>
            <TableCell
              align="left"
              sortDirection={sortField === headCell.id ? order : false}
              style={{ width: headCell.width }}
            >
              <TableSortLabel
                active={sortField === headCell.id}
                direction={sortField === headCell.id ? order : "asc"}
                onClick={onRequestSort(headCell.id)}
              >
                {headCell.label}
                {sortField === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          </Hide>
        ))}
      </TableRow>
    </TableHead>
  );
};

const DeleteUserButton = ({ onClick, disabled }) => {
  const { isMobile } = useFullScreen();

  return <DeleteButton onDelete={onClick} disabled={disabled} floating={isMobile} />;
};

const getUserStatus = (userOrInvite: UserOrInviteWithRole) => {
  const inviteStatus = userOrInvite.invite ? "Invited" : "Active";

  return !userOrInvite.invite && userOrInvite.disabled ? "Disabled" : inviteStatus;
};

type Row = Omit<UserWithRole | InviteWithRole, "lastLogin" | "jobFunction"> & {
  permissions: string[];
  jobFunction: string;
  lastLogin: DateTime<true | false> | DateTime<false>;
  status: "Invited" | "Active" | "Disabled";
};

type Props = {
  finishImpersonating: () => void;
  successfulImpersonation: (success: boolean) => void;
  impersonating: boolean;
  userTab: {
    users?: UserWithRole[];
    invites?: InviteWithRole[];
  };
  isRestrictedIamDueToSso?: boolean;
  rolesList: Role[];
  setSelectedUser: (userOrInvite?: UserOrInviteWithRole | null) => void;
};

const Users = ({
  impersonating,
  successfulImpersonation,
  finishImpersonating,
  userTab,
  isRestrictedIamDueToSso,
  rolesList,
  setSelectedUser,
}: Props) => {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const { customer, organizations } = useCustomerContext();
  const { handleImpersonateUserOfCustomer } = useImpersonateContext();
  const { isDoitEmployee } = useAuthContext();
  const classes = useStyles();
  const { isMobile } = useFullScreen();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState<string[]>([]);
  const [isBulkUpdateUsersFormOpen, setIsBulkUpdateUsersFormOpen] = useState(false);
  const [isInviteUserDialogOpen, setIsInviteUserDialogOpen] = useState(false);

  useEffect(() => {
    mixpanel.track("users.open");
    if (!userRoles.users) {
      history.push("/");
    }
  }, [history, userRoles]);

  const handleImpersonateUserFromSelected = useCallback(async () => {
    if (selected.length === 1) {
      setIsLoading(true);
      try {
        const [userId] = selected;
        const success = await handleImpersonateUserOfCustomer(customer.id, userId);
        if (!success) {
          successfulImpersonation(success);
        }
      } finally {
        setIsLoading(false);
      }
    }
  }, [customer.id, handleImpersonateUserOfCustomer, selected, successfulImpersonation]);

  useEffect(() => {
    if (impersonating) {
      handleImpersonateUserFromSelected().catch(consoleErrorWithSentry);
      finishImpersonating();
    }
  }, [finishImpersonating, handleImpersonateUserFromSelected, impersonating]);

  const usersAndInvites = useMemo((): UserOrInviteWithRole[] | undefined => {
    const users = userTab.users;
    const invites = userTab.invites;
    if (!users || !invites) {
      return;
    }

    return [
      ...users.map((user): UserOrInviteWithRole => ({ ...user, invite: false })),
      ...invites.map((invite): UserOrInviteWithRole => ({ ...invite, invite: true })),
    ];
  }, [userTab.invites, userTab.users]);

  const handleClick = useCallback(
    (id: string) => {
      const selectedIndex = selected.indexOf(id);
      let newSelected: string[] = [];

      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
      }

      if (newSelected.length === 1) {
        const selectedUser = find(usersAndInvites, (user) => user.id === newSelected[0]);
        setSelectedUser(selectedUser ?? null);
      } else {
        setSelectedUser(null);
      }

      setSelected(newSelected);
    },
    [selected, setSelectedUser, usersAndInvites]
  );

  const isSelected = useCallback((id: string) => selected.includes(id), [selected]);

  const handleDeleteUsers = useCallback(async () => {
    const batch = getBatch();
    selected.forEach((selectedId) => {
      const selectedUser = find(usersAndInvites, (user) => selectedId === user.id);
      if (selectedUser) {
        mixpanel.track("users.delete", {
          "users.delete.email": selectedUser.email,
          "users.delete.id": selectedUser.id,
        });
        batch.delete(selectedUser.ref);
        if (selectedUser.roles?.type === "custom" && selectedUser.roles.ref && selectedUser.roles.inUse > 0) {
          batch.update(selectedUser.roles.ref, { inUse: increment(-1) });
        }
      }
    });
    try {
      await batch.commit();
    } catch (error) {
      consoleErrorWithSentry(error);
    }
    setSelected([]);
  }, [selected, usersAndInvites]);

  const toggleBulkUpdateUsersForm = useCallback(() => {
    setIsBulkUpdateUsersFormOpen((prevIsBulkUpdateUsersFormOpen) => !prevIsBulkUpdateUsersFormOpen);
  }, []);

  const bulkUpdateUsers =
    (selectedUsers) =>
    async ({ userNotifications, role, jobFunction, notificationsNotToOverride }) => {
      const selectedUsersMetadata = selectedUsers.map((selectedUser) => ({
        email: selectedUser.email,
        userNotifications: selectedUser.userNotifications ?? [],
        permissions: [],
      }));

      const updateUsersData = selectedUsersMetadata.map((updateUserData) => {
        const userNotificationsToKeep = updateUserData.userNotifications.filter((notification) =>
          notificationsNotToOverride.includes(notification)
        );
        updateUserData.userNotifications = userNotifications.concat(userNotificationsToKeep);

        if (role) {
          updateUserData.roles = [role];
        }

        if (jobFunction) {
          updateUserData.jobFunction = jobFunction;
        }

        return updateUserData;
      });

      // TODO:restore bulk update default dashboard (if needed at all)
      await updateUsers(updateUsersData, customer);

      toggleBulkUpdateUsersForm();
      setSelected([]);
    };

  const jobFunctionMap = jobFunctions.reduce((memo, v) => {
    memo[v.value] = v.name;
    return memo;
  }, {});

  const allUsersToFilter = useMemo(
    () =>
      (usersAndInvites ?? []).map((userOrInvite) => {
        const permissionsIds =
          userOrInvite.roles?.permissions?.flatMap((userPermission) => {
            const permission = permissions.find((permissions) => permissions.value === userPermission.id);
            if (!permission) {
              return [];
            }

            return [permission.value];
          }) ?? [];

        const { lastLogin, displayName, jobFunction, ...restUser } = userOrInvite;
        return {
          ...restUser,
          displayName: displayName ?? "",
          jobFunction: jobFunction ? (jobFunctionMap[jobFunction] ?? "") : "",
          lastLogin: lastLogin ? DateTime.fromJSDate(lastLogin.toDate()).toUTC() : DateTime.invalid("no input date"),
          permissions: permissionsIds,
          status: getUserStatus(userOrInvite),
        };
      }),
    [jobFunctionMap, usersAndInvites]
  );

  const {
    tableState,
    filteredRows: filteredUsers,
    rows,
    handleChangePage,
    handleChangeRowsPerPage,
    handleRequestSort,
    handleRequestFilter,
  } = useTableState(allUsersToFilter ?? [], {
    sort: "email",
    persistenceKey: "users",
  });

  const selectedUsers = useMemo(
    () => filteredUsers.filter((user) => selected.includes(user.id)),
    [filteredUsers, selected]
  );

  const orgsList = useMemo(
    () =>
      organizations.map((org) => ({
        ...org.data,
        id: org.snapshot.id,
        ref: org.ref,
      })),
    [organizations]
  );

  const defaultFilters = [
    {
      value: "Active",
      label: "Active",
      column: filterColumns[3],
    },
  ];

  return (
    <Loader loading={usersAndInvites === undefined}>
      <FilterContextProvider
        columns={filterColumns}
        persistenceKey={isDoitEmployee ? undefined : "users_filter"}
        defaultValue={defaultFilters}
      >
        <Toolbar disableGutters className={classes.tableToolbar}>
          <Grid
            container
            spacing={1}
            sx={{
              alignItems: "center",
            }}
            size="grow"
          >
            <Grid size="grow">
              <EnhancedTableFilter
                data={allUsersToFilter}
                columns={filterColumns}
                onFilter={handleRequestFilter}
                placeholder="Filter users"
                limitTags={isMobile ? 1 : -1}
                defaultValue={defaultFilters}
              />
            </Grid>
            {selected.length === 1 && (
              <Grid>
                <DeleteUserButton onClick={handleDeleteUsers} disabled={!userRoles.users || isLoading} />
              </Grid>
            )}
            {selected.length === 0 && (
              <Grid>
                <InviteUserButton
                  toolTipText={
                    isRestrictedIamDueToSso && !isMobile
                      ? text.INVITE_USER_DISABLED_DUE_TO_SSO_ENABLED_TOOLTIP
                      : undefined
                  }
                  disabled={isRestrictedIamDueToSso}
                  onClick={() => {
                    setIsInviteUserDialogOpen(true);
                  }}
                />
              </Grid>
            )}
            {selected.length > 1 && (
              <Grid>
                <Button
                  variant="contained"
                  color="primary"
                  aria-label="edit"
                  onClick={toggleBulkUpdateUsersForm}
                  startIcon={<FormatListBulletedIcon />}
                >
                  Edit
                </Button>
              </Grid>
            )}
          </Grid>
        </Toolbar>
        <div className={classes.tableWrapper}>
          <Table aria-labelledby="tableTitle" className={classes.table}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={tableState.direction}
              sortField={tableState.sort}
              onRequestSort={handleRequestSort}
              rowCount={filteredUsers.length}
              onClick={() => {
                setSelected(selected.length === filteredUsers.length ? [] : filteredUsers.map((user) => user.id));
              }}
            />
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.invite ? `invite-${row.id}` : `user-${row.id}`}
                  hover
                  onClick={() => {
                    handleClick(row.id);
                  }}
                  role="checkbox"
                  aria-checked={isSelected(row.id)}
                  tabIndex={-1}
                  selected={isSelected(row.id)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox checked={isSelected(row.id)} />
                  </TableCell>

                  <TableCell style={{ width: headCells[0].width }}>
                    <Typography
                      variant="body2"
                      color="inherit"
                      style={{ textDecoration: "underline" }}
                      component={RouteLink}
                      to={`/customers/${customer?.id}/iam/users/${row.id}`}
                    >
                      {row.email}
                    </Typography>
                  </TableCell>
                  <Hide mdDown>
                    <TableCell style={{ width: headCells[1].width }}>{row.displayName}</TableCell>
                    <TableCell style={{ width: headCells[2].width }}>{row.jobFunction}</TableCell>
                    <TableCell style={{ width: headCells[3].width }}>{row.status}</TableCell>
                    <TableCell style={{ width: headCells[4].width }}>{row.roles?.name}</TableCell>
                    <TableCell style={{ width: headCells[5].width }}>
                      {row.lastLogin.isValid ? row.lastLogin.toLocaleString(DateTime.DATE_MED) : ""}
                    </TableCell>
                  </Hide>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {filteredUsers.length > 0 ? (
            <TablePagination
              component="div"
              count={filteredUsers.length}
              rowsPerPage={tableState.rowsPerPage}
              labelRowsPerPage="Rows"
              page={tableState.page}
              slotProps={{
                actions: {
                  previousButton: {
                    "aria-label": "Previous Page",
                  },
                  nextButton: {
                    "aria-label": "Next Page",
                  },
                },
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          ) : (
            <Toolbar>
              <Typography variant="body2">
                Sorry, no users were found matching your filter. Please try again or
                <Button
                  disableFocusRipple
                  disableRipple
                  size="small"
                  onClick={() => {
                    setIsInviteUserDialogOpen(true);
                  }}
                >
                  Invite user
                </Button>
              </Typography>
            </Toolbar>
          )}
        </div>
        {isInviteUserDialogOpen && (
          <InviteUserDialog
            open={true}
            onClose={() => {
              setIsInviteUserDialogOpen(false);
            }}
            roleList={rolesList}
            orgsList={orgsList}
          />
        )}
        {isBulkUpdateUsersFormOpen && (
          <BulkUpdateUsersForm
            open={true}
            onClose={toggleBulkUpdateUsersForm}
            roles={rolesList}
            emails={selectedUsers.map((user) => user.email)}
            onUpdate={bulkUpdateUsers(selectedUsers)}
            usersNotifications={selectedUsers.map((user) => user.userNotifications ?? [])}
            onDelete={async () => {
              await handleDeleteUsers();
              toggleBulkUpdateUsersForm();
            }}
          />
        )}
      </FilterContextProvider>
    </Loader>
  );
};

export default Users;
