import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Typography } from "@mui/material";

import BlackDot from "./BlackDot";
import GreenCheck from "./GreenCheck";

const desktopSize = 1200;

type Props = {
  detectedDetails?: Array<{ name: string; value: string | null }>;
  setMinHeight: (height: number) => boolean;
  avaChat?: boolean;
};

export default function CaseIQ({ detectedDetails, setMinHeight, avaChat }: Props) {
  const { t } = useTranslation("services");

  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current && window.innerWidth >= desktopSize) {
      setMinHeight(ref.current.offsetHeight);
    } else {
      setMinHeight(0);
    }
  }, [setMinHeight, detectedDetails]);

  if (!detectedDetails || detectedDetails?.length === 0) {
    return null;
  }

  let avaProps = {};

  if (avaChat) {
    avaProps = {
      border: 1,
      borderColor: "primary.main",
      borderRadius: 1,
      p: 1,
    };
  }

  return (
    <Box
      ref={ref}
      data-cy="case-iq"
      sx={{
        backgroundColor: "general.backgroundDark",
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
        borderRadius: 1,
        paddingLeft: 1.5,
        paddingRight: 1.5,
        paddingTop: 1,
        paddingBottom: 1,
        [`@media (min-width: ${desktopSize}px)`]: avaChat
          ? {}
          : {
              position: "absolute",
              width: 245,
              top: 0,
              right: -272,
              minHeight: 265,
            },
        [`@media (max-width: ${desktopSize - 1}px)`]: {
          position: "relative",
          width: "100%",
          marginTop: 1,
        },
        ...avaProps,
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center",
          }}
        >
          <AutoAwesomeIcon
            sx={{
              color: (theme) => theme.palette.primary.main,
            }}
          />
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: 14,
            }}
          >
            {t("CaseIQ.description")}
          </Typography>
        </Box>

        <Box sx={{ mt: 1, display: "flex", gap: 1, flexDirection: "column" }}>
          {detectedDetails.map((line) => {
            if (line.value) {
              return <GreenCheck content={line.name} key={line.name} />;
            }

            return <BlackDot content={line.name} key={line.name} />;
          })}
        </Box>
      </Box>
      <Typography
        variant="caption"
        sx={{
          color: "text.secondary",
          marginTop: 2,
        }}
      >
        Powered by AI
      </Typography>
    </Box>
  );
}
