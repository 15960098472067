import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Button, Dialog, DialogActions, Divider } from "@mui/material";
import IconButton from "@mui/material/IconButton";

import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import mixpanel from "../../utils/mixpanel";
import TicketEscalation from "./EscalateStepper/TicketEscalation";

type Props = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  billingProfile?: string;
  setBillingProfile: (profile: string) => void;
};

export const TermsAgreementDialog = ({ open, onClose, onConfirm, billingProfile, setBillingProfile }: Props) => {
  const { onOpen: showSnackbar, onClose: hideSnackbar } = useSnackbar();
  const [hasAgreedToCosts, setCostChecked] = useState<boolean>(false);
  const [isAuthorizedToMakeDecisions, setAuthorizedChecked] = useState<boolean>(false);
  const [hasAgreedToTerms, setAgreedToTerms] = useState<boolean>(false);

  const hasConfirmed = hasAgreedToCosts && isAuthorizedToMakeDecisions && hasAgreedToTerms && billingProfile;

  const handleConfirm = () => {
    if (hasConfirmed) {
      onConfirm();
      showSnackbar({
        message: "You have accepted the terms and conditions. You may escalate your ticket.",
        variant: "success",
        autoHideDuration: 5000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={hideSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      mixpanel.track("ava.essentials.escalate.confirm-payment", {
        flow: "ava.essentials.open-ticket",
      });
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" PaperProps={{ sx: { pt: 2 } }}>
      <TicketEscalation
        billingProfile={billingProfile}
        setBillingProfile={setBillingProfile}
        hasAgreedToCosts={hasAgreedToCosts}
        setCostChecked={setCostChecked}
        isAuthorizedToMakeDecisions={isAuthorizedToMakeDecisions}
        setAuthorizedChecked={setAuthorizedChecked}
        hasAgreedToTerms={hasAgreedToTerms}
        setAgreedToTerms={setAgreedToTerms}
      />
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleConfirm} variant="contained" disabled={!hasConfirmed}>
          Confirm payment
        </Button>
      </DialogActions>
    </Dialog>
  );
};
