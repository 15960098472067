import { useMemo } from "react";

import { isReportAvaReply, isReportBlocked } from "@doitintl/ava-components/src/Messenger/hooks";
import { AvaConversationsModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionData } from "@doitintl/models-firestore";
import type { Message } from "@doitintl/ava-components";

export const useAvaConversation = (conversationId: string | undefined | null): [Message[], boolean] => {
  const docPath = useMemo(() => {
    if (!conversationId) {
      return;
    }
    return getCollection(AvaConversationsModel).doc(conversationId);
  }, [conversationId]);

  const [historyDocs, historyLoading] = useCollectionData(docPath?.collection("chatHistory").orderBy("timeCreated"), {
    idField: "messageId",
  });
  const history = useMemo(() => {
    if (!historyDocs || historyLoading) {
      return [];
    }

    const { messages } = historyDocs.reduce(
      (acc, message) => {
        // If the message is from 'me', expect the next user to be 'ava'
        if (message.role === "user") {
          acc.messages.push({ content: message.content, createdAt: message.timeCreated.toDate(), role: "user" });
          return acc;
        }

        if (message.role === "ava") {
          acc.messages.push({
            content: message.content,
            completed: !message.stopped,
            feedback: message.messageFeedback,
            answerId: message.messageId,
            createdAt: message.timeCreated.toDate(),
            role: "ava",
            isReport: isReportAvaReply(message.content),
            reportBlocked: isReportBlocked(message.content),
            additionalData: message.additionalData ?? "",
          });
          return acc;
        }

        return acc;
      },
      { messages: [] as Message[] }
    );

    return messages;
  }, [historyDocs, historyLoading]);

  return [history, conversationId ? historyLoading : false];
};
