import { type DoitRole, RoleModel, type UserPermission, UserPermissions } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Customer } from "../types";

export default class Permissions {
  readonly doitEmployee: boolean;

  readonly doitOwner: boolean;

  readonly doitDeveloper: boolean;

  readonly doitPartner: boolean;

  readonly userId: string | undefined;

  readonly billingProfilesAdmin: boolean;

  readonly assetsManager: boolean;

  readonly users: boolean;

  readonly invoicesViewer: boolean;

  readonly sandboxAdmin: boolean;

  readonly sandboxUser: boolean;

  readonly flexsaveAdmin: boolean;

  readonly cloudAnalyticsUser: boolean;

  readonly manageSettings: boolean;

  readonly usersManager: boolean;

  readonly spotScaling: boolean;

  readonly contractsViewer: boolean;

  readonly anomaliesViewer: boolean;

  readonly perksViewer: boolean;

  readonly issuesViewer: boolean;

  readonly budgetsManager: boolean;

  readonly metricsManager: boolean;

  readonly attributionsManager: boolean;

  readonly supportRequester: boolean;

  readonly rampPlansViewer: boolean;

  readonly cloudAnalyticsAdmin: boolean;

  readonly labelsManager: boolean;

  readonly insightsManager: boolean;

  readonly dataHubAdmin: boolean;

  readonly threadsManager: boolean;

  readonly cloudFlowViewer: boolean;

  readonly cloudFlowManager: boolean;

  readonly permissions: Set<UserPermission | DoitRole>;

  constructor(
    private readonly user:
      | {
          permissions?: UserPermission[];
          roles?: { name: string; permissions: { id: string }[] }[];
        }
      | null
      | undefined,
    {
      isDoitEmployee,
      isDoitPartner,
      isDoitDeveloper,
      isDoitOwner,
      userId,
    }: {
      isDoitEmployee: boolean | undefined;
      isDoitPartner: boolean | undefined;
      isDoitDeveloper: boolean | undefined;
      isDoitOwner: boolean | undefined;
      userId: string | undefined;
    },
    doitRoles?: DoitRole[]
  ) {
    this.user = user;
    const userPermissions = this.user?.permissions
      ? new Set(this.user.permissions)
      : new Set<UserPermission | DoitRole>();
    const userRoles = this.user?.roles ?? [];
    userRoles.forEach((role) => {
      if (role.permissions) {
        role.permissions.forEach((permission) => {
          userPermissions.add(permission.id as UserPermission);
        });
      }
    });

    doitRoles?.forEach((role) => {
      userPermissions.add(role);
    });

    this.permissions = userPermissions;
    this.doitEmployee = isDoitEmployee ?? false;
    this.doitOwner = isDoitOwner ?? false;
    this.doitDeveloper = isDoitDeveloper ?? false;
    this.doitPartner = isDoitPartner ?? false;
    this.userId = userId;
    this.billingProfilesAdmin = isDoitEmployee || this.permissions.has(UserPermissions.BillingProfilesAdmin);
    this.assetsManager = isDoitEmployee || this.permissions.has(UserPermissions.AssetsManager);
    this.users = isDoitEmployee || this.permissions.has(UserPermissions.IAM);
    this.invoicesViewer = isDoitEmployee || this.permissions.has(UserPermissions.InvoicesViewer);
    this.sandboxAdmin = this.permissions.has(UserPermissions.SandboxAdmin);
    this.sandboxUser = this.permissions.has(UserPermissions.SandboxUser);
    this.flexsaveAdmin = this.permissions.has(UserPermissions.FlexibleRI);
    this.cloudAnalyticsUser = this.permissions.has(UserPermissions.CloudAnalyticsUser);
    this.manageSettings = this.permissions.has(UserPermissions.Settings);
    this.usersManager = this.permissions.has(UserPermissions.IAM); // This is the same as users
    this.spotScaling = this.permissions.has(UserPermissions.SpotScaling);
    this.contractsViewer = this.permissions.has(UserPermissions.ContractsViewer);
    this.anomaliesViewer = this.permissions.has(UserPermissions.AnomaliesViewer);
    this.perksViewer = this.permissions.has(UserPermissions.PerksViewer);
    this.insightsManager = this.permissions.has(UserPermissions.InsightsManager);
    this.dataHubAdmin = isDoitEmployee || this.permissions.has(UserPermissions.DataHubAdmin);
    this.issuesViewer = isDoitEmployee || this.permissions.has(UserPermissions.IssuesViewer);
    this.budgetsManager = this.permissions.has(UserPermissions.BudgetsManager);
    this.metricsManager = this.permissions.has(UserPermissions.MetricsManager);
    this.attributionsManager = this.permissions.has(UserPermissions.AttributionsManager);
    this.supportRequester = this.permissions.has(UserPermissions.SupportRequester);
    this.rampPlansViewer = this.permissions.has(UserPermissions.RampPlansViewer);
    this.cloudAnalyticsAdmin = this.permissions.has(UserPermissions.CloudAnalyticsAdmin);
    this.labelsManager = isDoitEmployee || this.permissions.has(UserPermissions.LabelsManager);
    this.threadsManager = this.permissions.has(UserPermissions.ThreadsManager);
    this.cloudFlowViewer = this.permissions.has(UserPermissions.CloudFlowViewer);
    this.cloudFlowManager = this.permissions.has(UserPermissions.CloudFlowManager);
  }

  has(permissionId: UserPermission): boolean {
    return this.doitEmployee || this.permissions.has(permissionId);
  }

  check(permissionId: UserPermission | DoitRole): boolean {
    return this.permissions.has(permissionId);
  }

  roleName() {
    if (!this.doitEmployee && this.user?.roles?.length) {
      // CMP uses only one role per user currently
      return this.user.roles[0].name;
    }
  }

  static widgetPermissionsRequired = {
    invoices: UserPermissions.InvoicesViewer,
    credits: UserPermissions.InvoicesViewer,
  };
}

export function getRoles(roleId: string) {
  return getCollection(RoleModel).doc(roleId);
}

export const PresetRoles = {
  get ADMIN_REF() {
    return getRoles("59w2TJPTCa3XPsJ3KITY");
  },
  get POWER_USER_REF() {
    return getRoles("Y2IN1X2rmWoZhTJDsYAN");
  },
  get FINANCE_USER_REF() {
    return getRoles("vkrmC1ioimecNyJ6vjPZ");
  },
  get STANDARD_USER_REF() {
    return getRoles("e66fNltAJjdtpHwaXb6I");
  },
  get IT_MANAGER_REF() {
    return getRoles("K634tvqvUvQGLPnqtubh");
  },
  get SUPPORT_USER_REF() {
    return getRoles("YcZKjDZJHHUAl9yPCBmy");
  },
  get PARTNER_ACCOUNT_MANAGER_REF() {
    return getRoles("K8fE5kgTdEhQotv39Bcc");
  },
};

export const isAdmin = (roles: { id: string }[] | undefined) =>
  !!roles?.map((r) => r.id).includes(PresetRoles.ADMIN_REF.id);

/** @description: the default role ref for new users */
export const getDefaultRoleRefByCustomer = (customer: Pick<Customer, "type" | "defaultRole">) => {
  if (customer.defaultRole) {
    return getRoles(customer.defaultRole.id);
  }

  return PresetRoles.SUPPORT_USER_REF;
};
