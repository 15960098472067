import { type Dispatch, type SetStateAction, useCallback, useEffect, useMemo, useState } from "react";

import { Link as RouterLink } from "react-router-dom";
import { type SlackChannel } from "@doitintl/cmp-models";
import { CheckBox as CheckBoxIcon, CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon } from "@mui/icons-material";
import LockIcon from "@mui/icons-material/Lock";
import RefreshIcon from "@mui/icons-material/RefreshRounded";
import {
  Alert,
  Autocomplete,
  type AutocompleteChangeReason,
  Checkbox,
  Chip,
  Link,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { CircularProgress } from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import IconButton from "@mui/material/IconButton";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../utils";
import { sortAlphabetically } from "../../../utils/common";
import useStyles from "./SlackChannelSelection.styles";

type SlackChannelSelectionProps = {
  customerId: string;
  disabled?: boolean;
  text?: string;
  channels: SlackChannel[];
  setChannels: Dispatch<SetStateAction<SlackChannel[]>> | ((channels: SlackChannel[]) => void);
  includeNoOptionsBox?: boolean;
  includeSlackLink?: boolean;
  newSlackLink?: boolean;
};

export const SlackChannelSelection = ({
  customerId,
  disabled,
  text,
  channels = [],
  setChannels,
  includeNoOptionsBox = false,
  includeSlackLink = true,
  newSlackLink,
}: SlackChannelSelectionProps) => {
  const classes = useStyles();
  const { getSlackChannels, slackChannels: options } = useCustomerContext();
  const [loading, setLoading] = useState<boolean>(true);
  const [inputFocused, setInputFocused] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [prevRefreshCount, setPrevRefreshCount] = useState(0);
  const [inputValue, setInputValue] = useState("");
  const checkBoxOutlineBlankIcon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkBoxIcon = <CheckBoxIcon fontSize="small" />;
  const theme = useTheme();

  useEffect(() => {
    const fetchChannels = async () => {
      setLoading(true);
      try {
        await getSlackChannels(prevRefreshCount !== refreshCount);
        if (prevRefreshCount !== refreshCount) {
          setPrevRefreshCount(refreshCount);
        }
      } catch (e) {
        consoleErrorWithSentry(e);
      }
      setLoading(false);
    };
    fetchChannels();
  }, [customerId, refreshCount, prevRefreshCount, getSlackChannels]);

  const renderSlackLink = (internal = false) => {
    if (!includeSlackLink) {
      return null;
    }

    const spacer = (
      <Typography variant="body2" className={internal ? "" : classes.link}>
        &nbsp;
      </Typography>
    );

    if (!options) {
      return spacer;
    }

    const fullyConfigured = options.find(({ shared }) => shared) && options.find(({ shared }) => !shared);
    if (!internal && fullyConfigured) {
      return spacer;
    }

    if (newSlackLink) {
      return (
        <Alert
          sx={{
            mt: 2,
            alignItems: "center",
            ".MuiAlert-action": {
              padding: 0,
            },
          }}
          severity="warning"
          action={
            <Link
              color="inherit"
              component={RouterLink}
              to={`/customers/${customerId}/integrations/slack`}
              underline="none"
              variant="body2"
              target="_blank"
              sx={{
                fontWeight: 500,
                pr: 2,
                pt: 0,
                padding: 0,
              }}
            >
              Configure Slack
            </Link>
          }
        >
          <Typography variant="body2">Configure Slack integration to select your Slack channels</Typography>
        </Alert>
      );
    }

    return (
      <Typography variant="body2" className={internal ? "" : classes.link}>
        Update your&nbsp;
        <Link
          component={RouterLink}
          to={`/customers/${customerId}/integrations/slack`}
          underline="always"
          variant="body2"
          target="_blank"
        >
          Slack settings
        </Link>
      </Typography>
    );
  };

  const renderNoOptionsBox = () => {
    if (!includeNoOptionsBox || loading || !!options?.length) {
      return null;
    }

    return (
      <Alert
        severity="warning"
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="body2" color="textSecondary">
          Configure Slack integration to select your Slack channels &nbsp; &nbsp;
          <Link
            component={RouterLink}
            to={`/customers/${customerId}/integrations/slack`}
            underline="always"
            variant="body2"
            target="_blank"
          >
            Configure Slack
          </Link>
        </Typography>
      </Alert>
    );
  };

  const sortCategorically = (a: SlackChannel, b: SlackChannel) => {
    if (a.shared === b.shared) {
      return 0;
    }
    return a.shared ? -1 : 1;
  };

  const sortChannelType = (a: SlackChannel, b: SlackChannel) => {
    if (a.type === b.type) {
      return 0;
    }
    return a.type === "private" ? -1 : 1;
  };

  const sortChannels: (channelsToSort: SlackChannel[]) => SlackChannel[] = useCallback((channelsToSort) => {
    //  sort channels by name & shared property
    channelsToSort.sort((a, b) => sortAlphabetically(a.name, b.name));
    return channelsToSort.sort(sortChannelType).sort(sortCategorically);
  }, []);

  const sortedOptions = useMemo(() => sortChannels(options ?? []), [options, sortChannels]);

  const OptionLabel = ({
    option,
    search,
    highlightSearch,
  }: {
    option: SlackChannel;
    search: string;
    highlightSearch?: boolean;
  }) => {
    const parts = option.name.split(new RegExp(`(${search})`, "gi"));

    return (
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          gap: 1,
        }}
      >
        {option.type === "private" ? <LockIcon fontSize="inherit" /> : "#"}
        <Typography variant="body1">
          {parts.map((part, index) => {
            const highlightPart = part.toLowerCase() === search.toLowerCase() && highlightSearch;
            return (
              <span
                key={index}
                style={{
                  color: highlightPart ? theme.palette.primary.main : undefined,
                  fontWeight: highlightPart ? 600 : 400,
                }}
              >
                {part}
              </span>
            );
          })}
        </Typography>
      </Stack>
    );
  };

  const RefreshBtn = () => (
    <IconButton
      onClick={() => {
        setRefreshCount(refreshCount + 1);
      }}
      disabled={loading}
      color="primary"
      size="small"
      sx={{
        borderRadius: 0,
      }}
    >
      <RefreshIcon sx={{ ml: -1 }} />
      <Typography
        variant="body2"
        sx={{
          fontWeight: 500,
          ml: 1,
        }}
      >
        Refresh
      </Typography>
    </IconButton>
  );

  const loaderComponent = (
    <Stack
      direction="row"
      sx={{
        alignItems: "center",
        gap: 1,
      }}
    >
      <CircularProgress size={14} />
      <Typography variant="body2">&nbsp; Loading Slack channels...</Typography>
    </Stack>
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 250,
    stringify: (option: SlackChannel) => option.name,
  });

  return (
    <>
      <Typography className={classes.header} color="inherit" variant="body2">
        {text}
      </Typography>
      <Autocomplete
        multiple
        disableCloseOnSelect
        limitTags={3}
        options={sortedOptions}
        getOptionLabel={(option) => option.name}
        isOptionEqualToValue={(option, value) => option.name === value.name}
        groupBy={({ workspace, shared, type }) =>
          shared ? "Shared DoiT Slack channel" : `${workspace} ${type} workspace channels`
        }
        value={sortChannels(channels)}
        disabled={loading || disabled || !options || options.length < 1}
        noOptionsText={
          inputValue.length > 0
            ? "No matching Slack channels"
            : includeSlackLink
              ? renderSlackLink(true)
              : "Once configured, your Slack channels will appear here"
        }
        filterOptions={filterOptions}
        inputValue={inputValue}
        onInputChange={(event, value) => {
          setInputValue(value);
        }}
        open={inputValue.length > 0}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              variant="outlined"
              size="small"
              label={<OptionLabel option={option} search={inputValue} highlightSearch={false} />}
              color="primary"
              {...getTagProps({ index })}
              key={index}
            />
          ))
        }
        renderOption={(props, option, { selected }) => (
          <li {...props} key={option.id}>
            <Checkbox
              icon={checkBoxOutlineBlankIcon}
              checkedIcon={checkBoxIcon}
              style={{ left: "-10px" }}
              checked={selected}
            />
            <OptionLabel option={option} search={inputValue} highlightSearch={inputValue.length > 2} />
          </li>
        )}
        onFocus={() => {
          setInputFocused(true);
        }}
        onBlur={() => {
          setInputFocused(false);
        }}
        renderInput={(params) => {
          const { InputProps, ...otherParams } = params;
          return (
            <TextField
              {...otherParams}
              variant="outlined"
              label={
                loading ? loaderComponent : inputFocused ? "Select Slack channels" : "Type to search Slack channels"
              }
              fullWidth
              size="small"
              slotProps={{
                input: { ...InputProps, endAdornment: null },
              }}
            />
          );
        }}
        onChange={(event, value: SlackChannel[], reason: AutocompleteChangeReason) => {
          switch (reason) {
            case "selectOption":
            case "clear":
            case "removeOption":
              setChannels(value);
              break;
          }
        }}
      />
      {renderNoOptionsBox()}
      {renderSlackLink()}
      <RefreshBtn />
    </>
  );
};
