import awsLogoDark from "@client/src/assets/amazon-web-services-logo-white.png";
import awsLogo from "@client/src/assets/amazon-web-services-new-square.png";
import AzureLogo from "@client/src/assets/azure-logo.svg";
import GCPLogo from "@client/src/assets/gcp-logo.svg";
import { useDarkThemeCheck } from "@client/src/Components/hooks/useDarkThemeCheck";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

const AvailabilityChips = () => {
  const darkMode = useDarkThemeCheck();

  return (
    <>
      <Chip
        icon={<Box component="img" src={darkMode ? awsLogoDark : awsLogo} sx={{ width: 16, height: 16 }} />}
        label="Available"
        sx={{ pl: "6px", backgroundColor: "general.backgroundDark", color: "text.primary" }}
      />
      <Chip
        icon={<Box component="img" src={AzureLogo} sx={{ width: 16, height: 16 }} />}
        label="Coming soon"
        sx={{ backgroundColor: "general.backgroundDark", pl: "6px", color: "text.primary" }}
      />
      <Chip
        icon={<Box component="img" src={GCPLogo} sx={{ width: 16, height: 16 }} />}
        label="Coming soon"
        sx={{ backgroundColor: "general.backgroundDark", pl: "6px", color: "text.primary" }}
      />
    </>
  );
};

export default AvailabilityChips;
