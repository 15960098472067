import { useEffect, useState } from "react";

import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";

import { useContractFormContext } from "../../../ContractsFormContext";
import { isGoogleCloudPartnerLedPremiumSupport } from "../../../utils";

export const PlpsDatePicker = () => {
  const { state, handleChangeDate, setState } = useContractFormContext();

  const [minDate, setMinDate] = useState<DateTime | undefined>(undefined);

  useEffect(() => {
    if (!isGoogleCloudPartnerLedPremiumSupport(state)) {
      return;
    }

    const fetchLatestDate = async () => {
      if (!state.gcpContract?.length) {
        setMinDate(undefined);
        return;
      }

      const snapshots = await Promise.all(state.gcpContract.map((ref) => ref.get()));

      // Find latest date
      const timestamps = snapshots.map((contract) => contract.asModelData()?.startDate.toMillis() ?? 0);
      const mostRecent = DateTime.fromMillis(Math.max(...timestamps));

      setMinDate(mostRecent);
      setState((prev) => ({
        ...prev,
        errors: { ...prev.errors, startDate: mostRecent && mostRecent > state.startDate },
      }));
    };
    fetchLatestDate();
  }, [setState, state]);

  if (!isGoogleCloudPartnerLedPremiumSupport(state)) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid
        size={{
          xs: 12,
          md: 6,
        }}
      >
        <DatePicker
          renderInput={(params) => (
            <TextField
              data-cy="plps-contract-start-date"
              helperText="Select the contract start date"
              margin="dense"
              fullWidth
              {...params}
              error={!!state.errors?.startDate}
            />
          )}
          minDate={minDate}
          label="Start Date"
          value={state.startDate}
          onChange={handleChangeDate("startDate")}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLL, yyyy"
          disabled={state.isEditForbidden}
        />
      </Grid>

      <Grid
        size={{
          xs: 12,
          md: 6,
        }}
      >
        <DatePicker
          renderInput={(params) => (
            <TextField
              data-cy="plps-contract-end-date"
              margin="dense"
              helperText="Select the contract end date (exclusive)"
              fullWidth
              {...params}
              error={!!state.errors.endDate}
            />
          )}
          label="End Date"
          value={state.endDate}
          onChange={handleChangeDate("endDate")}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLL, yyyy"
          minDate={state.startDate}
        />
      </Grid>
    </Grid>
  );
};
