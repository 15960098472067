import {
  AssetModelBillingAnomalyModel,
  type CurrencyCode,
  CurrencyCodes,
  InAppNotificationType,
} from "@doitintl/cmp-models";
import { getCollectionGroup } from "@doitintl/models-firestore";
import { Chip, Typography } from "@mui/material";

import { levelText } from "../../../Pages/AnomalyDetection/utils";
import { formatCurrency } from "../../../utils/common";
import { type Mapper } from "../types";

export class CostAnomaly implements Mapper<AssetModelBillingAnomalyModel> {
  readonly type = InAppNotificationType.CostAnomaly;

  static readonly levelChipColor = {
    0: {
      backgroundColor: "#0277bd",
      color: "white",
    },
    1: {
      backgroundColor: "#0277bd",
      color: "white",
    },
    2: {
      backgroundColor: "#FF9800",
      color: "black",
    },
    3: {
      backgroundColor: "#E53935",
      color: "white",
    },
  };

  static readonly color = "#D9FCF9";

  getCollection: Mapper<AssetModelBillingAnomalyModel>["getCollection"] = (customerRef, startTime) =>
    getCollectionGroup(AssetModelBillingAnomalyModel)
      .where("customer", "==", customerRef)
      .where("timestamp", ">", startTime)
      .orderBy("timestamp", "desc");

  userHasPermission: Mapper<AssetModelBillingAnomalyModel>["userHasPermission"] = (userRoles) =>
    userRoles ? userRoles.anomaliesViewer : false;

  toNotification: Mapper<AssetModelBillingAnomalyModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    const created = docData.metadata?.timestamp ?? docData.timestamp;
    const updated = docData.timestamp !== created ? docData.timestamp : undefined;
    const assetName = docSnap.ref.parentModel().parentModel()?.id;
    const projectOrAccount = docData.metadata.platform === "google-cloud" ? "project" : "account";
    const anomalyType = docData.metadata.type?.toLowerCase();
    const serviceString = docData.metadata.service_name ? ` for ${docData.metadata.service_name}` : "";
    const costString =
      anomalyType === "cost" &&
      docData.metadata.excess &&
      docData.metadata.unit &&
      Object.values<string>(CurrencyCodes).includes(docData.metadata.unit)
        ? ` with ${anomalyType} of ${formatCurrency(docData.metadata.excess, docData.metadata.unit as CurrencyCode)}`
        : "";
    const description = (
      <>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          Detected in {projectOrAccount} {docData.metadata.project_id}
          {costString}.
        </Typography>
        {!!docData.metadata.severity && levelText[docData.metadata.severity] && (
          <Typography
            variant="body2"
            component="span"
            sx={{
              color: "text.secondary",
            }}
          >
            Severity:{" "}
            <Chip
              label={levelText[docData.metadata.severity]}
              size="small"
              sx={{
                ...CostAnomaly.levelChipColor[docData.metadata.severity],
                padding: "0px",
                height: 20,
              }}
            />
          </Typography>
        )}
      </>
    );
    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.CostAnomaly,
      created,
      updated,
      title: `Cost anomaly detected${serviceString}`,
      description,
      urlPath: `/customers/${docData.customer.id}/anomaly/${assetName}/${docSnap.id}`,
      color: CostAnomaly.color,
    };
  };
}
