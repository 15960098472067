import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useLocation } from "react-router";
import {
  type Cluster,
  ClusterConnectionStatus,
  type ClusterType,
  type ClusterWithCloudConnect,
  type CustomerModelCloudConnectModel,
  type KubernetesFeature,
} from "@doitintl/cmp-models";
import { modelFromPath } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { customersRoute } from "../../../Navigation/core/utils";
import { consoleErrorWithSentry } from "../../../utils";
import { getHoursSinceTimestamp } from "../../../utils/time";
import { activateCluster, testClusterConnection } from "../api";
import { useClusters, useKubernetesFeatures } from "../hooks";

export const useKubernetesClusterActivation = (clusterType?: ClusterType) => {
  const api = useApiContext();
  const history = useHistory();
  const location = useLocation();
  const customerId = useCustomerId();
  const successSnackbar = useSuccessSnackbar();

  const [clusters] = useClusters();
  const [availableFeatures] = useKubernetesFeatures();

  const [currentStep, setCurrentStep] = useState<number>(0);

  const [selectedProjectId, setSelectedProjectId] = useState<string | null>(null);
  const [selectedClusterId, setSelectedClusterId] = useState<string | null>(null);
  const [selectedFeatures, setSelectedFeatures] = useState<KubernetesFeature[]>([]);
  const [defaultSelectedFeaturesHaveBeenSet, setDefaultSelectedFeaturesHaveBeenSet] = useState<boolean>(false);
  const [cloudConnect, setCloudConnect] = useState<CustomerModelCloudConnectModel | undefined>(undefined);

  const selectedCluster: ClusterWithCloudConnect | null = useMemo(() => {
    const cluster = clusters?.find((c) => c.id === selectedClusterId);
    return cluster ? { ...cluster, cloudConnect } : null;
  }, [clusters, selectedClusterId, cloudConnect]);

  const fetchCloudConnectDoc = useCallback(async () => {
    if (!selectedCluster?.cloudConnectRef.path) return;

    const ref = modelFromPath(selectedCluster?.cloudConnectRef.path);
    const doc = await ref.get();
    const data = doc.data();

    if (!data) return;

    setCloudConnect(data as CustomerModelCloudConnectModel);
  }, [selectedCluster]);

  useEffect(() => {
    if (!selectedCluster || cloudConnect) return;

    fetchCloudConnectDoc();
  }, [selectedClusterId, selectedCluster, fetchCloudConnectDoc, cloudConnect]);

  useEffect(() => {
    if (!availableFeatures || !selectedCluster?.features || defaultSelectedFeaturesHaveBeenSet) return;

    const defaultSelectedFeatures =
      availableFeatures?.filter((f) => selectedCluster?.features?.[f.id]?.isActivated) ?? [];
    setSelectedFeatures(defaultSelectedFeatures);
    setDefaultSelectedFeaturesHaveBeenSet(true);
  }, [availableFeatures, selectedCluster, defaultSelectedFeaturesHaveBeenSet]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const projectId = queryParams.get("projectId");
    const clusterId = queryParams.get("clusterId");
    const startingStep = parseInt(queryParams.get("startingStep") ?? "0", 10);

    if (projectId) setSelectedProjectId(projectId);
    if (clusterId) setSelectedClusterId(clusterId);
    if (startingStep) setCurrentStep(startingStep);
  }, [location.search]);

  const goBack = useCallback(() => {
    history.push(`${customersRoute(customerId)}/assets/kubernetes`);
  }, [history, customerId]);

  const handleSubmit = async () => {
    if (!selectedCluster) return;

    if (!selectedCluster.isActivated) {
      await activateCluster(api, selectedCluster?.id);
    }

    successSnackbar(`${clusterType} cluster successfully activated`);
    goBack();
  };

  return {
    currentStep,
    selectedProjectId,
    selectedCluster,
    selectedFeatures,
    setCurrentStep,
    setSelectedProjectId,
    setSelectedClusterId,
    setSelectedFeatures,
    goBack,
    handleSubmit,
  };
};

export const useClusterConnectionStatusCheck = (cluster: Cluster | null) => {
  const api = useApiContext();

  const [statusHasBeenChecked, setStatusHasBeenChecked] = useState(false);
  const [statusCheckLoading, setStatusCheckLoading] = useState(false);

  const checkClusterStatus = useCallback(async () => {
    if (!cluster?.id) return;

    const hoursSinceLastCheck = getHoursSinceTimestamp(cluster.connectionStatusUpdated);

    if (hoursSinceLastCheck < 24 && cluster.connectionStatus === ClusterConnectionStatus.HEALTHY) {
      setStatusHasBeenChecked(true);
      return;
    }

    try {
      setStatusCheckLoading(true);
      await testClusterConnection(api, cluster.id);
    } catch (e) {
      consoleErrorWithSentry(e);
    } finally {
      setStatusHasBeenChecked(true);
      setStatusCheckLoading(false);
    }
  }, [cluster, api]);

  useEffect(() => {
    if (!statusHasBeenChecked) {
      checkClusterStatus();
    }
  }, [cluster, statusHasBeenChecked, checkClusterStatus]);

  return { statusHasBeenChecked, statusCheckLoading, checkClusterStatus };
};
