import { useHistory } from "react-router-dom";
import { AssetTypeDatadog, type DataConnectorDatadogModel } from "@doitintl/cmp-models";
import { Button, CardActions } from "@mui/material";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { type AssetsTabPropsOuterProps } from "../types";
import DatadogAssetsTabHeader from "./components/datadog/DatadogAssetsTabHeader";
import DatadogAssetTable from "./components/datadog/DatadogAssetTable";
import NoAssets from "./components/NoAssets";

type Props = AssetsTabPropsOuterProps & { datadogConfigs: DataConnectorDatadogModel[] | undefined };

const DatadogAssetsTab = ({ datadogConfigs, billingProfileFilter }: Props) => {
  const { customer } = useCustomerContext();
  const history = useHistory();

  if (datadogConfigs?.length === 0) {
    return (
      <>
        <CardActions sx={{ justifyContent: "flex-end" }}>{billingProfileFilter}</CardActions>
        <NoAssets assetType={AssetTypeDatadog}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              history.push(`/customers/${customer.id}/cloud-onboarding-datadog`);
            }}
          >
            Connect new account
          </Button>
        </NoAssets>
      </>
    );
  }

  return (
    <>
      <CardActions sx={{ justifyContent: "flex-end" }}>{billingProfileFilter}</CardActions>
      <DatadogAssetsTabHeader />
      <DatadogAssetTable configs={datadogConfigs} />
    </>
  );
};

export default DatadogAssetsTab;
