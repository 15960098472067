import { type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel } from "./Customer";

export enum InAppNotificationType {
  CostAnomaly = "COST ANOMALY",
  Budget = "BUDGET",
  Alert = "ALERT",
  InvoiceReminder = "INVOICE REMINDER",
  NewInvoice = "NEW INVOICE",
  CloudIncident = "CLOUD INCIDENT",
}

@subCollection("affectedByKnownIssues")
export class InAppNotificationKnownIssueModel {
  issueDocId!: string;

  platform!: string;

  products!: string[];

  status!: string;

  created!: Timestamp;

  customerRef!: Reference<CustomerModel>;

  updated!: Timestamp;

  expireBy!: Timestamp;
}
