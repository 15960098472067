import {
  Box,
  Chip,
  type ChipOwnProps,
  type ChipPropsSizeOverrides,
  type SxProps,
  type Theme,
  Tooltip,
} from "@mui/material";
import { type OverridableStringUnion } from "@mui/types";

export interface StatusChipWithTooltipProps {
  color?: ChipOwnProps["color"];
  label: string;
  tooltipContent?: React.ReactNode | null;
  chipSize?: OverridableStringUnion<"small" | "medium", ChipPropsSizeOverrides>;
  disabled?: boolean;
  tooltipSx?: SxProps<Theme>;
  boxSx?: SxProps<Theme>;
  chipSx?: SxProps<Theme>;
}

const StatusChipWithTooltip = ({
  color,
  label,
  tooltipSx,
  boxSx,
  chipSx,
  tooltipContent = null,
  disabled = false,
  chipSize = "small",
}: StatusChipWithTooltipProps) => (
  <Tooltip title={tooltipContent} sx={{ ...tooltipSx }}>
    <Box sx={{ cursor: tooltipContent ? "help" : "default", ...boxSx }}>
      <Chip
        size={chipSize}
        disabled={disabled}
        color={color}
        sx={{ textTransform: "capitalize", ...chipSx }}
        label={label}
      />
    </Box>
  </Tooltip>
);

export default StatusChipWithTooltip;
