import { type AccessLevel, CategoryStatus } from "@doitintl/cmp-models";
import {
  Box,
  Checkbox,
  Chip,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { CollapsableTableRow } from "../../../Components/CollapsableTableRow";
import { SafeHtml } from "../../../Components/SafeHtml";
import { useIsEntitledToCategory, usePermissionCategoriesByEntity } from "./hooks";
import { ServiceAccountStatusChip } from "./ServiceAccountStatusChip";

export type FeaturesTableProps = {
  entity: AccessLevel;
  selectedFeatures: string[];
  onFeatureSelect: (feature: string, selected: boolean) => void;
  forceIncludeCategoryIds?: string[];
  categoryStatuses?: Record<string, CategoryStatus>;
};

export const FeaturesTable = ({
  entity,
  selectedFeatures,
  onFeatureSelect,
  forceIncludeCategoryIds = [],
  categoryStatuses = {},
}: FeaturesTableProps) => {
  const availableCategories = usePermissionCategoriesByEntity(entity);
  const isEntitledToCategory = useIsEntitledToCategory();

  if (!availableCategories.length) {
    return <CircularProgress />;
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell width={15} />
          <TableCell>Available features</TableCell>
          <TableCell align="right" padding="checkbox">
            Include
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {availableCategories.map((category) => {
          const isEntitledToThisCategory = isEntitledToCategory(category);

          return (
            <CollapsableTableRow
              key={category.id}
              hiddenContent={
                <Box m={1}>
                  <Typography variant="subtitle1">
                    <SafeHtml html={category.description} />
                  </Typography>

                  <ul>
                    {category.entityPermissions.map((permission) => (
                      <li key={permission}>{permission}</li>
                    ))}
                  </ul>
                  {(category.additionalPermissions?.length ?? 0) > 0 && (
                    <Box mt={2}>
                      <Typography variant="subtitle2">
                        <SafeHtml html={category.additionalPermissionsDescription} />
                      </Typography>
                      <ul>
                        {category.additionalPermissions?.map((permission) => <li key={permission}>{permission}</li>)}
                      </ul>
                    </Box>
                  )}
                </Box>
              }
            >
              <TableCell component="th" scope="row">
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="body2" sx={{ opacity: isEntitledToThisCategory ? 1 : 0.4 }}>
                    {category.name}
                  </Typography>

                  {isEntitledToThisCategory ? (
                    <ServiceAccountStatusChip
                      status={categoryStatuses?.[category.id] || CategoryStatus.NotConfigured}
                    />
                  ) : (
                    <Chip label="Not included in your plan" size="small" color="secondary" />
                  )}
                </Stack>
              </TableCell>

              <TableCell padding="none">
                {/* Don't allow unselecting force included categories (defaults / previously selected), but in case they are not selected for some reason, allow selecting them */}
                <Checkbox
                  color="primary"
                  disabled={
                    (forceIncludeCategoryIds?.includes(category.id) && selectedFeatures.includes(category.id)) ||
                    !isEntitledToThisCategory
                  }
                  checked={selectedFeatures.includes(category.id)}
                  onChange={(event) => {
                    onFeatureSelect(category.id, event.target.checked);
                  }}
                />
              </TableCell>
            </CollapsableTableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
