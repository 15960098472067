import { useCallback, useEffect } from "react";

import { Fade } from "react-awesome-reveal";
import { CustomMarkdown } from "@doitintl/ava-components/src/Messenger/CustomMarkdown";
import { Feedback } from "@doitintl/ava-components/src/Messenger/Feedback";
import { type AvaFeedback } from "@doitintl/cmp-models";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Alert, Button, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { AvaIcon } from "../Ava/Messenger/AvaIcon";
import { AvaWidgetCard } from "../Ava/Messenger/AvaWidgetCard";
import { CopyToClipboardButton } from "../CopyToClipboardButton";
import { useDarkThemeCheck } from "../hooks/useDarkThemeCheck";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useAvaContext } from "./AvaContext";
import { AvaLoading } from "./AvaLoading";
import { useTrpcContext } from "./AvaTRPCContext";
import { useAvaReports } from "./reportsHook";
import { type MessageAva } from "./types";
import { UpsellNotification } from "./UpsellNotification";

type PropsAva = {
  message: MessageAva;
  disableAnimation: boolean;
  showError?: string;
  onRetryClicked?: () => void;

  handleAskQuestion?: (question: string) => void;
  isGeneratingReport?: boolean;
};

export const MessageAvaComponent = ({
  message,
  disableAnimation,
  showError,
  onRetryClicked,
  handleAskQuestion,
  isGeneratingReport,
}: PropsAva) => {
  const isDarkMode = useDarkThemeCheck();
  const { conversationId } = useAvaContext();
  const { fetchFeedback } = useTrpcContext();
  const { setCaseIQConversationId } = useAvaCaseIQContext();

  const { content, completed, isReport, reportBlocked, additionalData } = message;
  const { handleReportCreate, widgetData, onAvaReportResponse, onWidgetRendererChange } = useAvaReports();
  const handleFeedbackSubmitted = (feedback: AvaFeedback) => {
    if (message.answerId) {
      if (!conversationId) {
        return;
      }

      fetchFeedback(conversationId, message.answerId, feedback);
    }
  };

  useEffect(() => {
    if (message?.isReport && message.answerId) {
      onAvaReportResponse(message.content);
    }
  }, [message, onAvaReportResponse]);

  const handleOpenTicket = useCallback(() => {
    if (conversationId) {
      setCaseIQConversationId(conversationId);
    }
  }, [conversationId, setCaseIQConversationId]);

  return (
    <Stack p={2}>
      <Stack sx={{ maxWidth: 800, alignSelf: "center", width: "100%" }} direction="row" spacing={1.5}>
        <AvaIcon />
        <Stack
          maxWidth="100%"
          sx={{
            overflow: "hidden",
          }}
        >
          {!(content || additionalData) && !showError && !reportBlocked && (
            <Stack>
              <AvaLoading isGeneratingReport={isGeneratingReport} />
            </Stack>
          )}
          {message.answerId && message.isReport && (
            <AvaWidgetCard
              widgetKey={message.answerId}
              onWidgetRendererChange={onWidgetRendererChange}
              widgetData={widgetData}
              handleReportCreate={handleReportCreate}
            />
          )}
          {additionalData && (
            <Typography variant="body2" component="div" mt={1}>
              <CustomMarkdown
                line={additionalData}
                handleAskQuestion={handleAskQuestion}
                isDarkMode={isDarkMode}
                handleOpenTicket={handleOpenTicket}
              />
            </Typography>
          )}
          {!isReport && !reportBlocked && !additionalData && (
            <Typography variant="body2" component="div">
              <CustomMarkdown
                line={content}
                handleAskQuestion={handleAskQuestion}
                isDarkMode={isDarkMode}
                handleOpenTicket={handleOpenTicket}
              />
            </Typography>
          )}

          {!!showError && (
            <Alert
              severity="warning"
              action={
                <Button color="inherit" size="small" onClick={onRetryClicked}>
                  Ask again
                </Button>
              }
            >
              I couldn't get to your question due to a service issue.
              <br />
              {showError}
            </Alert>
          )}
          {reportBlocked && <UpsellNotification />}
          {completed && isReport && !reportBlocked && (
            <Typography variant="body2" component="div" mt={1}>
              <Typography
                variant="body2"
                component="span"
                sx={{
                  cursor: "pointer",
                  color: "#3B40B5",
                  textDecoration: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
                onClick={() => {
                  if (handleReportCreate && message.answerId) {
                    handleReportCreate(message.answerId);
                  }
                }}
              >
                Open the report in Cloud Analytics
                <OpenInNewIcon fontSize="small" sx={{ verticalAlign: "middle", marginLeft: 1 }} />
              </Typography>
            </Typography>
          )}
          {completed && (content || additionalData) && !reportBlocked && (
            <Fade triggerOnce fraction={0.5} duration={disableAnimation ? 0 : 500}>
              <Stack direction="row">
                <Feedback
                  feedback={message.feedback}
                  onFeedbackClicked={handleFeedbackSubmitted}
                  disableAnimation={disableAnimation}
                >
                  {!isReport && <CopyToClipboardButton color="primary" text={content} />}
                </Feedback>
              </Stack>
            </Fade>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};
