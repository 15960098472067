import { type KeyboardEvent, useEffect, useRef, useState } from "react";

import { useSearchBox } from "react-instantsearch";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import { Box, Chip, IconButton, InputBase } from "@mui/material";

import { useSearch } from "./SearchContext";
import { useStyles } from "./utils/styles";

// Define compound terms that should be combined
const COMPOUND_TERMS = new Map([
  ["cloud composer", "cloud-composer"],
  ["cloud build", "cloud-build"],
  ["cloud run", "cloud-run"],
  ["cloud storage", "cloud-storage"],
  ["cloud functions", "cloud-functions"],
  // Add more compound terms as needed
]);

export const CustomSearchBox = () => {
  const { refine } = useSearchBox();
  const { chips, setChips } = useSearch();
  const classes = useStyles({});
  const [inputValue, setInputValue] = useState("");
  const [selectedChipIndex, setSelectedChipIndex] = useState<number | null>(null);
  const lastKeyPressRef = useRef<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const inputTimerRef = useRef<NodeJS.Timeout | null>(null);

  const checkAndCombineCompoundTerms = (newChips: string[]): string[] => {
    const result: string[] = [];
    let skipNext = false;

    for (let i = 0; i < newChips.length; i++) {
      if (skipNext) {
        skipNext = false;
        continue;
      }

      const currentChip = newChips[i];
      const nextChip = newChips[i + 1];

      if (nextChip) {
        const potentialCompound = `${currentChip} ${nextChip}`.toLowerCase();
        const compound = COMPOUND_TERMS.get(potentialCompound);

        if (compound) {
          result.push(compound);
          skipNext = true;
          continue;
        }
      }

      result.push(currentChip);
    }

    return result;
  };

  const addChip = (value: string) => {
    if (value.trim()) {
      // Clear the timer when manually adding a chip
      if (inputTimerRef.current) {
        clearTimeout(inputTimerRef.current);
        inputTimerRef.current = null;
      }

      const newChips = [...chips, value.trim()];
      const combinedChips = checkAndCombineCompoundTerms(newChips);
      setChips(combinedChips);
      refine(combinedChips.join(" "));
      setInputValue("");
      setSelectedChipIndex(null);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);

    // Clear any existing timer
    if (inputTimerRef.current) {
      clearTimeout(inputTimerRef.current);
    }

    // Set new timer if there's input
    if (event.target.value.trim()) {
      inputTimerRef.current = setTimeout(() => {
        addChip(event.target.value);
      }, 1500);
    }
  };

  // Clean up timer when component unmounts
  useEffect(
    () => () => {
      if (inputTimerRef.current) {
        clearTimeout(inputTimerRef.current);
      }
    },
    []
  );

  const handleDeleteChip = (chipToDelete: string) => {
    const newChips = chips.filter((chip) => chip !== chipToDelete);
    const combinedChips = checkAndCombineCompoundTerms(newChips);
    setChips(combinedChips);
    refine(combinedChips.join(" "));
    setSelectedChipIndex(null);
  };

  const handleEscape = () => {
    setSelectedChipIndex(null);
    inputRef.current?.focus();
  };

  const getNewIndexAfterDeletion = (currentIndex: number | null, isBackspace: boolean): number | null => {
    if (currentIndex === null) return null;
    if (isBackspace && currentIndex > 0) {
      return currentIndex - 1;
    }
    return currentIndex < chips.length - 1 ? currentIndex : null;
  };

  const handleChipDeletion = (event: KeyboardEvent<HTMLInputElement>, isBackspace: boolean) => {
    event.preventDefault();
    handleDeleteChip(chips[selectedChipIndex!]);
    setSelectedChipIndex(getNewIndexAfterDeletion(selectedChipIndex, isBackspace));
  };

  const handleBackspaceAtStart = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.currentTarget.selectionStart === 0 && chips.length > 0) {
      event.preventDefault();
      setSelectedChipIndex(chips.length - 1);
    }
  };

  const handleArrowLeft = (event: KeyboardEvent<HTMLInputElement>) => {
    if (selectedChipIndex === null && event.currentTarget.selectionStart === 0 && chips.length > 0) {
      setSelectedChipIndex(chips.length - 1);
    } else if (selectedChipIndex !== null) {
      setSelectedChipIndex(Math.max(0, selectedChipIndex - 1));
    }
  };

  const handleArrowRight = () => {
    if (selectedChipIndex === null) return;

    if (selectedChipIndex < chips.length - 1) {
      setSelectedChipIndex(selectedChipIndex + 1);
    } else {
      setSelectedChipIndex(null);
      inputRef.current?.focus();
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    lastKeyPressRef.current = event.key;

    switch (event.key) {
      case "Escape":
        handleEscape();
        break;

      case "Backspace":
      case "Delete":
        if (selectedChipIndex !== null) {
          handleChipDeletion(event, event.key === "Backspace");
        } else if (event.key === "Backspace") {
          handleBackspaceAtStart(event);
        }
        break;

      case "ArrowLeft":
      case "ArrowRight":
        event.preventDefault();
        event.key === "ArrowLeft" ? handleArrowLeft(event) : handleArrowRight();
        break;
    }
  };

  const handleKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.key === " " || event.key === "Enter") && lastKeyPressRef.current !== "Backspace" && inputValue.trim()) {
      event.preventDefault();
      addChip(inputValue);
    }
  };

  const handleClearAll = () => {
    if (inputTimerRef.current) {
      clearTimeout(inputTimerRef.current);
      inputTimerRef.current = null;
    }
    setChips([]);
    setInputValue("");
    refine("");
    setSelectedChipIndex(null);
  };

  const handleChipClick = (index: number) => {
    setSelectedChipIndex(index === selectedChipIndex ? null : index);
  };

  return (
    <Box className={classes.searchBox}>
      <SearchIcon className={classes.searchIcon} />
      <Box className={classes.chipBox}>
        {chips.map((chip) => (
          <Chip
            key={chip}
            label={chip}
            onDelete={() => {
              handleDeleteChip(chip);
            }}
            onClick={() => {
              handleChipClick(chips.indexOf(chip));
            }}
            size="small"
            color={selectedChipIndex === chips.indexOf(chip) ? "secondary" : "primary"}
            className={selectedChipIndex === chips.indexOf(chip) ? classes.selectedChip : undefined}
          />
        ))}
        <InputBase
          inputRef={inputRef}
          className={classes.searchInput}
          placeholder="Search..."
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          onKeyUp={handleKeyUp}
          inputProps={{
            "data-cy": "whois-search-input",
          }}
        />
      </Box>
      {(chips.length > 0 || inputValue) && (
        <IconButton className={classes.clearButton} onClick={handleClearAll} size="small">
          <ClearIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  );
};
