import { type ReactElement } from "react";

import { AccessLevel } from "@doitintl/cmp-models";
import SettingsIcon from "@mui/icons-material/Settings";

import Icon from "../../Components/Icons/Icon";

export enum TabValue {
  GENERAL = "general",
  GCP = "gcp",
  AWS = "aws",
  SLACK = "slack",
}

export const defaultTab = Object.values(TabValue)[0];

export const TabValuesToLabels: Record<TabValue, string> = {
  [TabValue.GENERAL]: "General",
  [TabValue.GCP]: "Google Cloud",
  [TabValue.AWS]: "Amazon Web Services",
  [TabValue.SLACK]: "Slack",
};

export const TabValuesToTabIcons: Record<TabValue, ReactElement> = {
  [TabValue.GENERAL]: <SettingsIcon />,
  [TabValue.GCP]: <Icon icon="googleCloud" />,
  [TabValue.AWS]: <Icon icon="aws" />,
  [TabValue.SLACK]: <Icon icon="slack" />,
};

export const AccessLevelsOptions = [
  {
    value: AccessLevel.ORGANIZATION,
    label: "Organization",
  },
  { value: AccessLevel.PROJECT, label: "Project" },
];
