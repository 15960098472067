import { useState } from "react";

import { useParams } from "react-router";
import { Button, DialogContent, Tooltip, Typography } from "@mui/material";

import SimpleDialog from "../../../../../../Components/SimpleDialog";
import { useErrorSnackbar } from "../../../../../Integrations/Slack/utils";
import contractHooks, { useBackToList } from "../../../../hooks";

export const TerminateContractButton = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const updateContractStatus = contractHooks.useUpdateContractStatus();
  const backToList = useBackToList();
  const errorSnackbar = useErrorSnackbar();

  return (
    <>
      <Tooltip title="To be used when a customer offboards from DoiT">
        <Button
          variant="outlined"
          color="error"
          onClick={() => {
            setDialogOpen(true);
          }}
          data-cy="terminate-contract-button"
        >
          Terminate contract
        </Button>
      </Tooltip>
      <SimpleDialog
        open={dialogOpen}
        title="Terminate contract"
        onConfirm={async () => {
          await updateContractStatus(contractId, { active: false, terminated: true }, backToList, () => {
            errorSnackbar("Failed to cancel contract");
          });
        }}
        onCancel={() => {
          setDialogOpen(false);
        }}
        confirmButtonText="Terminate contract"
        confirmButtonColor="error"
      >
        <DialogContent>
          <Typography
            sx={{
              mt: 3,
            }}
          >
            Are you sure you want to terminate this contract of offboarded customer?
          </Typography>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};
