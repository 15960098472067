import { type MouseEvent, useCallback, useState } from "react";

import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import {
  AccessLevel,
  AssetTypeGoogleCloud,
  CategoryStatus,
  type CloudConnectGoogleCloud,
  CustomerModel,
  EarlyAccessFeature,
} from "@doitintl/cmp-models";
import {
  getCollection,
  type TransformMethod,
  useCollectionData,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import {
  type FirebaseDocumentSnapshotModel,
  type FirebaseQueryDocumentSnapshotModel,
} from "@doitintl/models-firestore/src/core";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
  MenuItem,
  Stack,
  TableCell,
  Typography,
} from "@mui/material";
import type { PopoverProps } from "@mui/material/Popover";

import { EllipsisCell } from "../../../Components/EllipsisCell";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { CellsWrapper } from "../../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../../Components/HideChildren/Hide";
import { LimitedListWithTooltip } from "../../../Components/LimitedListWithTooltip";
import { CircularProgressLoader } from "../../../Components/Loader";
import LoadingButton from "../../../Components/LoadingButton";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu } from "../../../Components/ThreeDotsMenu";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { getCachingKeys } from "../../../utils/cachingKeys";
import { capitalizeStartCase } from "../../../utils/common";
import { ServiceAccountStatusChip } from "./ServiceAccountStatusChip";
import { filters, headerColumns } from "./table";
import { type EnhancedCloudConnectGoogleCloud } from "./types";
import { useCloudConnectHealthCheck } from "./useCloudConnectHealthCheck";
import { useDeleteServiceAccount } from "./useDeleteServiceAccount";
import { usePermissionCategories } from "./usePermissionCategories";

export const ServiceAccountsPage = () => {
  const { customer } = useCustomerContext();
  const categories = usePermissionCategories();
  const history = useHistory();

  const hasGCPRecommenderBigQueryExportInsightsAccess =
    customer?.earlyAccessFeatures?.includes(EarlyAccessFeature.GCP_RECOMMENDER_BIGQUERY_INSIGHTS) ?? false;

  function getEntityDisplayName(doc: CloudConnectGoogleCloud): string {
    const displayNameGenerators: Record<AccessLevel, (doc: CloudConnectGoogleCloud) => string> = {
      [AccessLevel.ORGANIZATION]: (doc) => doc.organizations?.[0]?.displayName ?? "N/A",
      [AccessLevel.PROJECT]: (doc) => doc.projectId ?? "N/A",
      [AccessLevel.DATASET]: (doc) => doc.datasetId ?? "N/A",
    };

    const scope = doc.scope || AccessLevel.ORGANIZATION;

    return displayNameGenerators[scope] ? displayNameGenerators[scope](doc) : "N/A";
  }

  const transformSnapshot: TransformMethod<CloudConnectGoogleCloud, EnhancedCloudConnectGoogleCloud> = useCallback(
    (
      cloudConnectDoc: WithFirebaseModel<CloudConnectGoogleCloud>,
      snapshot:
        | FirebaseDocumentSnapshotModel<CloudConnectGoogleCloud>
        | FirebaseQueryDocumentSnapshotModel<CloudConnectGoogleCloud>
    ): EnhancedCloudConnectGoogleCloud => {
      const data: WithFirebaseModel<CloudConnectGoogleCloud> = {
        ...cloudConnectDoc,
        scope: cloudConnectDoc.scope || AccessLevel.ORGANIZATION,
      };

      const enabledFeatures = Object.entries(cloudConnectDoc.categoriesStatus)
        .filter(([_, status]) => status === CategoryStatus.Healthy)
        .toSorted(
          ([keyA]: [string, CategoryStatus | undefined], [keyB]: [string, CategoryStatus | undefined]): number =>
            keyA === "core" ? -1 : keyA.localeCompare(keyB)
        )
        .map(([key]) => categories.find((category) => category.id === key)?.name || "")
        .filter((name) => !!name);

      return {
        data,
        id: snapshot.id,
        entityId: getEntityDisplayName(data),
        enabledFeatures,
      };
    },
    [categories]
  );

  const [cloudConnectDocs, isLoadingCloudConnectDocs] = useCollectionData(
    getCollection(CustomerModel)
      .doc(customer.id)
      .collection("cloudConnect")
      .where("cloudPlatform", "==", AssetTypeGoogleCloud)
      .narrow<CloudConnectGoogleCloud>(),
    {
      transform: transformSnapshot,
      caching: true,
      cachingKeys: getCachingKeys(customer.id),
    }
  );

  const [deletionPrompt, setDeletionPrompt] = useState<EnhancedCloudConnectGoogleCloud | null>(null);

  const [isDeletionInProgress, deleteServiceAccount] = useDeleteServiceAccount();
  const showSuccess = useSuccessSnackbar();
  const showError = useErrorSnackbar();

  // Run health check on page load
  useCloudConnectHealthCheck(customer.id);

  const onConfirmDelete = async () => {
    if (!deletionPrompt) return;
    try {
      await deleteServiceAccount(deletionPrompt);
      setDeletionPrompt(null);
      showSuccess("Service account has been successfully deleted");
    } catch {
      showError("An error occurred during service account deletion");
    }
  };

  const [anchorEl, setAnchorEl] = useState<PopoverProps["anchorEl"] | null>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box p={2}>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          alignItems={{ xs: "flex-start", sm: "center" }}
          justifyContent="space-between"
          gap={2}
        >
          <Typography variant="h1">Google Cloud access &amp; features</Typography>

          <Stack direction="row" gap={2}>
            <Button
              variant="contained"
              onClick={handleClick}
              endIcon={<ArrowDropDownIcon />}
              sx={{ whiteSpace: "nowrap" }}
            >
              Connect
            </Button>
            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
              <MenuItem
                component={Link}
                to={`/customers/${customer.id}/settings/gcp/connect/organization`}
                onClick={handleClose}
              >
                Organization
              </MenuItem>
              <MenuItem
                component={Link}
                to={`/customers/${customer.id}/settings/gcp/connect/project`}
                onClick={handleClose}
              >
                Project
              </MenuItem>
              {hasGCPRecommenderBigQueryExportInsightsAccess && (
                <MenuItem
                  component={Link}
                  to={`/customers/${customer.id}/settings/gcp/connect/dataset`}
                  onClick={handleClose}
                >
                  Dataset
                </MenuItem>
              )}
            </Menu>
          </Stack>
        </Stack>

        {isLoadingCloudConnectDocs ? (
          <CircularProgressLoader />
        ) : (
          <FilterTable
            rowComponent={({ row }) => {
              const editPath = `/customers/${customer.id}/settings/gcp/connect/${row.data.scope}/${row.id}`;

              return (
                <CellsWrapper>
                  <TableCell data-cy="org-cell">
                    <Typography component={Link} to={editPath} color="inherit" variant="body2">
                      {row.entityId}
                    </Typography>
                  </TableCell>

                  <Hide mdDown>
                    {/* This might get very long, make sure it's one of the first columns that shrinks, but doesn't break line */}
                    <EllipsisCell maxWidth="250px" data-cy="email-cell">
                      {row.data.clientEmail}
                    </EllipsisCell>

                    <TableCell>{capitalizeStartCase(row.data.scope)}</TableCell>
                    <TableCell>
                      <LimitedListWithTooltip items={row.enabledFeatures} limit={2} title="Enabled features" />
                    </TableCell>
                  </Hide>

                  <TableCell data-cy="status-cell">
                    <ServiceAccountStatusChip status={row.data.status} />
                  </TableCell>

                  <TableCell padding="checkbox">
                    <ThreeDotsMenu
                      options={[
                        {
                          key: "edit",
                          label: "Edit",
                          dataCy: "edit",
                          action: () => {
                            history.push(editPath);
                          },
                        },
                        {
                          key: "delete",
                          label: "Delete connection",
                          dataCy: "delete",
                          color: "error.main",
                          action: () => {
                            setDeletionPrompt(row);
                          },
                        },
                      ]}
                    />
                  </TableCell>
                </CellsWrapper>
              );
            }}
            tableItems={cloudConnectDocs}
            headerColumns={headerColumns}
            filterColumns={filters}
            filterBarPlaceholder="Filter Google Cloud connections"
            filterAreaSXProp={{ mb: 3 }}
            emptyTableComponent={
              <Typography variant="body2" textAlign="center" my={3} sx={{ opacity: 0.6 }}>
                No organizations or projects have been connected
              </Typography>
            }
            emptyTableCellBorder={false}
            defaultSortingColumnValue="data.status"
            defaultSortDirection="desc"
            rowDataCyId={true}
          />
        )}
      </Box>

      <Dialog
        open={deletionPrompt !== null}
        onClose={() => {
          setDeletionPrompt(null);
        }}
      >
        <DialogTitle>Warning</DialogTitle>
        <DialogContent>
          {/* TODO(laura): Update this to be dynamic depending on the enabled features */}
          Removing the integration with Google Cloud will disable the following features:
          <ul>
            <li>BigQuery Lens</li>
            <li>IAM Folder support in Cloud Analytics</li>
            <li>Disposable Cloud Environments (DCEs)</li>
            <li>Compute Rightsizing Insights &amp; Recommendations</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setDeletionPrompt(null);
            }}
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            onClick={onConfirmDelete}
            variant="contained"
            color="error"
            loading={isDeletionInProgress}
            mixpanelEventId="settings.gcp-confirmation.confirm"
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
