import React from "react";

import { CopyCodeBlock } from "@client/src/Components/CopyCodeBlock/CopyCodeBlock";
import { AZURE_FEATURE_APP } from "@client/src/Pages/Settings/Azure/consts";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button, TextField, Typography } from "@mui/material";
import { Container } from "@mui/system";

type Props = {
  tenantId: string;
  setTenantId: React.Dispatch<React.SetStateAction<string>>;
};

export const CreateServicePrincipalStep = ({ tenantId, setTenantId }: Props) => {
  const createServicePrincipalCmd = `az ad sp create --id ${AZURE_FEATURE_APP.clientId}`;

  return (
    <>
      <Container
        maxWidth={false}
        sx={{ maxWidth: "688px" }}
        disableGutters
        key={"connect-azure-service-principal-container"}
      >
        <>
          <Typography
            variant="h3"
            sx={{
              mb: 3,
              fontSize: 20,
            }}
          >
            Connect Microsoft Azure tenant to DoiT
          </Typography>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              mt: 4,
            }}
            noValidate
            autoComplete="off"
          >
            <Typography variant="body2">1. Enter tenant ID</Typography>
            <TextField
              label="Tenant ID"
              variant="outlined"
              value={tenantId}
              onChange={(e) => {
                setTenantId(e.target.value);
              }}
            />

            <>
              <Typography variant="body2">3. Run the following commands on the Azure portal</Typography>

              <Button
                startIcon={<OpenInNewIcon />}
                variant="outlined"
                sx={{ alignSelf: "flex-start" }}
                onClick={() => window.open("https://portal.azure.com/#cloudshell", "_blank")}
              >
                Open Azure portal
              </Button>

              <Typography variant="body2" mb={-2}>
                Create Doit International application
              </Typography>
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", mb: 1 }}>
                <CopyCodeBlock base={createServicePrincipalCmd} />
              </Box>
            </>
          </Box>
        </>
      </Container>
    </>
  );
};
