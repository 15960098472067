import { useEffect, useMemo, useState } from "react";

import { type FieldInputProps, type FormikErrors, getIn, useFormikContext } from "formik";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FieldCommonProps<T = any> = ReturnType<typeof useFieldCommonProps<T>>;

export function useFieldCommonProps<T = any>(
  { value, name }: Pick<FieldInputProps<T>, "value" | "name">,
  label: string,
  required: boolean
) {
  const formikProps = useFormikContext();
  const [showError, setShowError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    const isTouched = getIn(formikProps.touched, name) === true;
    const errorMessage = getErrorsByFieldName(formikProps.errors, name);
    setErrorMessage(isTouched && typeof errorMessage === "string" ? errorMessage : undefined);
    setShowError(errorMessage !== undefined && isTouched);
  }, [name, formikProps.touched, formikProps.errors, errorMessage]);

  return useMemo(
    () => ({
      value,
      label,
      name,
      onChange: formikProps.handleChange,
      onBlur: formikProps.handleBlur,
      required,
      helperText: errorMessage,
      error: showError,
      autoComplete: "off",
    }),
    [errorMessage, formikProps.handleBlur, formikProps.handleChange, label, name, required, showError, value]
  );
}

export function useCheckboxCommonProps(fieldProps: FieldInputProps<boolean>, label: string) {
  const { setFieldValue } = useFormikContext();
  const commonFieldProps = useFieldCommonProps(fieldProps, label, false);

  return useMemo(
    () => ({
      ...commonFieldProps,
      value: "on",
      checked: fieldProps.value ?? false,
      onChange: (_, checked) => setFieldValue(fieldProps.name, checked),
    }),
    [commonFieldProps, fieldProps.name, fieldProps.value, setFieldValue]
  );
}

function collectErrorMessage(errors: FormikErrors<object>, path: (string | number)[]): string | undefined {
  const [current, ...rest] = path;

  if (errors[current] === undefined) {
    return undefined;
  }

  if (rest.length) {
    return collectErrorMessage(errors[current], rest);
  }

  if (typeof current === "number") {
    return Array.isArray(errors) ? (errors as string[])[current] : undefined;
  }

  if (current in errors && typeof errors[current] === "string") {
    return errors[current];
  }

  return undefined;
}

function getErrorsByFieldName(errors: FormikErrors<unknown>, name: string): string | undefined {
  const path = name
    .split(/[[\].]/)
    .map((token) => (/^\d+$/.test(token) ? parseInt(token, 10) : token))
    .filter((token) => token !== "");
  return collectErrorMessage(errors, path);
}
