import { useCallback, useEffect, useMemo, useState } from "react";

import isEqual from "lodash/isEqual";

import useAllocationDndStore from "./useAllocationDnd";
import { formStoreManager } from "./useAllocationFormState";

type AllocationStoreMap = {
  [key: string]: boolean;
};

const useAllocationSaveValidation = ({ isEdit }: { isEdit: boolean | undefined; allocationId: string }) => {
  const [isDirty, setIsDirty] = useState(false);
  const [isRuleSelected, setIsRuleSelected] = useState(false);

  const { items, itemsOriginal, hasUnallocatedCosts, isGroup, fieldErrors } = useAllocationDndStore();
  const [formValidationsMap, setFormValidationsMap] = useState({});
  // Check if allocation is single, if yes then on edit change to Save as flow
  const isSaveAs = useMemo(
    () => !isGroup && (items.main.length > 1 || hasUnallocatedCosts) && isEdit,
    [isGroup, items.main.length, isEdit, hasUnallocatedCosts]
  );

  const isDuplicate = useMemo(
    () => items.main.length < 2 && !hasUnallocatedCosts && !isEdit && isRuleSelected,
    [items.main.length, hasUnallocatedCosts, isEdit, isRuleSelected]
  );

  const getRuleStoreErrors = useCallback((items: string[]) => {
    const formStoresMap = items.reduce(
      (acc: AllocationStoreMap, item: string) => ({
        ...acc,
        [item]: formStoreManager.getStore(item).getState().isValid,
      }),
      {}
    );
    setFormValidationsMap(formStoresMap);
  }, []);

  const subscribeToStores = useCallback(
    (items: string[]) => {
      const subs: (() => void)[] = items.map((item) =>
        formStoreManager.getStore(item).subscribe(({ isValid, isDirty, selectedExistingRule }) => {
          setFormValidationsMap((prev) => ({ ...prev, [item]: isValid }));
          if (items.length < 2) {
            setIsRuleSelected(!!selectedExistingRule);
          }
          setIsDirty(isDirty || !isEqual(itemsOriginal, items));
        })
      );
      return subs;
    },
    [itemsOriginal]
  );

  // Check if there are any errors in the form, if yes disable the save button
  useEffect(() => {
    // In case when we delete instance of rule we need to trigger check on items change
    if (items.main.length) {
      getRuleStoreErrors(items.main);
      const subs = subscribeToStores(items.main);
      return () => {
        subs.forEach((sub) => {
          sub();
        });
      };
    }
  }, [getRuleStoreErrors, items.main, items.main.length, subscribeToStores]);

  return {
    isError:
      Object.keys(formValidationsMap).some((key) => !formValidationsMap[key]) || Object.keys(fieldErrors).length > 0,
    isSaveAs,
    isDirty,
    isDuplicate,
  };
};

export default useAllocationSaveValidation;
