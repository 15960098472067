import { useCallback, useEffect, useMemo, useState } from "react";

import { KnownIssueModel, TimeInterval as TI, TimeSettingsMode } from "@doitintl/cmp-models";
import { getCollection, type ModelId } from "@doitintl/models-firestore";
import { Box } from "@mui/material";
import CardHeader from "@mui/material/CardHeader";

import { FilterContextProvider } from "../../Components/FilterTable/Context";
import { FilterTableSkeleton } from "../../Components/FilterTable/FilterTableSkeleton";
import { isServiceUsedByCustomer } from "../../Components/KnownIssues/KnownIssuesUptimeChart/funcs";
import { useGetCustomerServices } from "../../Components/KnownIssues/KnownIssuesUptimeChart/hooks";
import KnownIssuesUptimeChart from "../../Components/KnownIssues/KnownIssuesUptimeChart/KnownIssuesUptimeChart";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useFullScreen } from "../../utils/dialog";
import { getTimeSettings, type TimeRangeOption } from "../CloudAnalytics/utilities";
import { AddKnownIssue } from "./AddKnownIssue";
import {
  checkRegion,
  getAllProducts,
  getCustomerLocations,
  getCustomerRegions,
  reduceDocData,
  removeKnownIssuesDocuments,
  updateKnownIssuesDocuments,
} from "./db";
import { DeleteKnownIssues } from "./DeleteKnownIssues";
import { EditKnownIssue } from "./EditKnownIssue";
import { KnownIssuesFilterbar } from "./KnownIssuesFilterbar";
import {
  onlyOngoingKnownIssuesFilter,
  platformColumn,
  productColumn,
  statusColumn,
  titleColumn,
} from "./KnownIssuesFilterbar/consts";
import { KnownIssuesTable } from "./KnownIssuesTable";
import { KnownIssuesHeader } from "./style";
import { type KnownIssue, type Product } from "./types";

const columns = [statusColumn, platformColumn, productColumn, titleColumn];

const defaultTimeRangeOption: TimeRangeOption = {
  mode: TimeSettingsMode.Last,
  amount: 7,
  time: TI.DAY,
  includeCurrent: true,
};

const defaultMobileTimeRangeOption: TimeRangeOption = {
  mode: TimeSettingsMode.Last,
  amount: 2,
  time: TI.DAY,
  includeCurrent: true,
};

type KnownIssuesProps = {
  isAllowedToModify?: boolean;
};

export const KnownIssues = ({ isAllowedToModify }: KnownIssuesProps) => {
  const { customer } = useCustomerContext({ allowNull: true });
  const { isMobile } = useFullScreen();
  const [products, setProducts] = useState<Product[]>([]);
  const [issuesList, setIssuesList] = useState<ModelId<KnownIssue>[]>();
  const [filteredKnownIssues, setFilteredKnownIssues] = useState<KnownIssue[]>([]);
  const [selectedKnownIssuesIds, setSelectedKnownIssuesIds] = useState<string[]>([]);
  const [regions, setRegions] = useState<string[] | undefined>();
  const [locations, setLocations] = useState<string[] | undefined>();

  const { customerServices } = useGetCustomerServices();

  const isCustomerPage = useMemo(() => Boolean(customer?.id), [customer?.id]);

  const defaultTimeRange: TimeRangeOption = useMemo(
    () => (isMobile ? defaultMobileTimeRangeOption : defaultTimeRangeOption),
    [isMobile]
  );

  const [timeRangeOption, setTimeRangeOption] = useState<TimeRangeOption>(defaultTimeRange);

  const applyRegionCheck = useCallback(
    (issues: KnownIssue[]): KnownIssue[] => {
      if (!regions || !locations) {
        return [];
      }
      return reduceDocData(issues, (doc) => checkRegion(doc, regions, locations, isCustomerPage));
    },
    [regions, locations, isCustomerPage]
  );

  useEffect(() => {
    if (isCustomerPage && (!regions || !locations || !customerServices)) {
      return;
    }

    if (!timeRangeOption) {
      return;
    }
    const response = getTimeSettings(timeRangeOption);

    return getCollection(KnownIssueModel)
      .where("dateTime", ">=", response.from.toJSDate())
      .where("dateTime", "<", response.to.endOf("day").toJSDate())
      .onSnapshot((issuesSnapshots) => {
        const allIssues = issuesSnapshots.docs.map((doc) => ({
          ...doc.asModelData(),
          id: doc.id,
        }));
        if (isCustomerPage) {
          const issuesDocumentsData = applyRegionCheck(allIssues);
          const issuesAffectingTheCustomer = customerServices
            ? isServiceUsedByCustomer(issuesDocumentsData, customerServices)
            : [];
          setIssuesList(issuesAffectingTheCustomer);
        } else {
          setIssuesList(allIssues);
        }
      });
  }, [applyRegionCheck, customerServices, isCustomerPage, locations, regions, timeRangeOption]);

  useEffect(() => {
    if (!customer?.id) {
      return;
    }
    Promise.all([getCustomerRegions(customer.id), getCustomerLocations(customer.id)]).then(([regions, locations]) => {
      setRegions(regions);
      setLocations(locations);
    });
  }, [customer?.id]);

  useEffect(() => {
    getAllProducts().then((products) => {
      setProducts(products);
    });
  }, []);

  const updateKnownIssues = useCallback(
    (knownIssueData: KnownIssue) => updateKnownIssuesDocuments(knownIssueData, selectedKnownIssuesIds),
    [selectedKnownIssuesIds]
  );

  const removeKnownIssues = useCallback(async () => {
    await removeKnownIssuesDocuments(selectedKnownIssuesIds);
    setSelectedKnownIssuesIds([]);
  }, [selectedKnownIssuesIds]);

  const handleFilter = useCallback(
    (filterFunction?: (issuesList: KnownIssue[]) => KnownIssue[]) => {
      setFilteredKnownIssues(filterFunction ? filterFunction(issuesList ?? []) : (issuesList ?? []));
    },
    [issuesList]
  );

  const handleAllKnownIssuesSelect = useCallback(
    (checked: boolean) => {
      setSelectedKnownIssuesIds(checked ? filteredKnownIssues.map(({ id }) => id) : []);
    },
    [filteredKnownIssues]
  );

  const handleKnownIssueSelect = useCallback(
    (knownIssueId: KnownIssue["id"], checked: boolean) => {
      if (isAllowedToModify) {
        setSelectedKnownIssuesIds(
          checked
            ? selectedKnownIssuesIds.concat(knownIssueId)
            : selectedKnownIssuesIds.filter((selectedKnownIssueId) => selectedKnownIssueId !== knownIssueId)
        );
      }
    },
    [isAllowedToModify, selectedKnownIssuesIds]
  );

  const selectedKnownIssueDataForEdit = useMemo(() => {
    const [selectedKnownIssueId] = selectedKnownIssuesIds;
    return filteredKnownIssues.find((knownIssue) => knownIssue.id === selectedKnownIssueId);
  }, [selectedKnownIssuesIds, filteredKnownIssues]);

  const isEditOn = useMemo(
    () => isAllowedToModify && Boolean(selectedKnownIssuesIds.length === 1),
    [isAllowedToModify, selectedKnownIssuesIds.length]
  );

  const isDeleteOn = useMemo(
    () => isAllowedToModify && selectedKnownIssuesIds.length > 0,
    [isAllowedToModify, selectedKnownIssuesIds.length]
  );

  if (!issuesList || !customerServices) {
    return <FilterTableSkeleton />;
  }

  return (
    <FilterContextProvider columns={columns} defaultValue={[onlyOngoingKnownIssuesFilter]} persistenceKey={undefined}>
      <Box
        sx={{
          ml: -2,
          mt: -1,
        }}
      >
        <CardHeader id="dialog-title" titleTypographyProps={{ variant: "h1" }} title="Cloud incidents" />

        <KnownIssuesUptimeChart
          allIssues={issuesList ?? []}
          handleTimeRangeChange={setTimeRangeOption}
          defaultTimeRange={defaultTimeRange}
        />

        <CardHeader id="dialog-title" titleTypographyProps={{ variant: "h3" }} title="Cloud incident details" />

        {issuesList && (
          <>
            <KnownIssuesHeader>
              <KnownIssuesFilterbar knownIssues={issuesList ?? []} onFilter={handleFilter} />
              {isAllowedToModify && <AddKnownIssue productsSuggestions={products} />}
              {isEditOn && (
                <EditKnownIssue
                  knownIssueData={selectedKnownIssueDataForEdit}
                  updateKnownIssue={updateKnownIssues}
                  productsSuggestions={products}
                />
              )}
              {isDeleteOn && <DeleteKnownIssues removeKnownIssues={removeKnownIssues} />}
            </KnownIssuesHeader>
            <KnownIssuesTable
              knownIssues={filteredKnownIssues}
              selectedKnownIssuesIds={selectedKnownIssuesIds}
              onAllKnownIssuesSelect={handleAllKnownIssuesSelect}
              onKnownIssueSelect={handleKnownIssueSelect}
              isAllowedToModify={isAllowedToModify}
            />
          </>
        )}
      </Box>
    </FilterContextProvider>
  );
};
