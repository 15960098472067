import React, { useCallback, useEffect, useState } from "react";

import { Button, ClickAwayListener } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { allocationTxt } from "../../../../../assets/texts/CloudAnalytics/allocation";
import { attributionTxt } from "../../../../../assets/texts/CloudAnalytics/attribution";
import HtmlTooltip from "../../../../../Components/Tooltip/HtmlTooltip";
import { useFullScreen } from "../../../../../utils/dialog";
import { getLetterForIndex } from "../../../../../utils/string";
import { type FormActions, type FormState } from "../../hooks/useAllocationFormState";

const LOGIC_PART_WIDTH = 540;

interface LogicalFormulaInputProps {
  formStore: () => FormState & FormActions;
  guidedExperienceMode?: boolean;
  disabled?: boolean;
}

const LogicalFormulaInput = ({ formStore, guidedExperienceMode = false, disabled }: LogicalFormulaInputProps) => {
  const { values, errors, setFormula } = formStore();
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const { isMobile } = useFullScreen();

  const handleTooltipOpen = useCallback((e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsTooltipOpen(true);
  }, []);

  const handleTooltipClose = useCallback(() => {
    setIsTooltipOpen(false);
  }, []);

  useEffect(() => {
    setFormula(values.filterFields.map((_, idx) => getLetterForIndex(idx)).join(" AND "));
  }, [setFormula, values.filterFields]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.toUpperCase();
    setFormula(newValue);
  };

  return (
    <Grid container gap={1} sx={{ flexDirection: "column" }}>
      <Grid sx={{ width: { md: LOGIC_PART_WIDTH } }}>
        <TextField
          fullWidth
          required
          variant="outlined"
          label={allocationTxt.DEFINE_LOGIC}
          size="small"
          sx={{ mt: 1 }}
          value={values.formula}
          onChange={handleChange}
          error={!!errors.formula}
          disabled={disabled || isMobile || values.filterFields.length < 2}
          helperText={<>{errors.formula}</>}
          slotProps={{
            htmlInput: {
              "data-cy": "formulaTextField",
              sx: { textTransform: "uppercase", backgroundColor: "background.paper" },
              autoComplete: "off",
            },
          }}
        />
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <span>
            <HtmlTooltip
              placement="bottom"
              onClose={handleTooltipClose}
              open={isTooltipOpen}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <Box>
                  <Typography variant="subtitle1" fontWeight={500} mb={1}>
                    {attributionTxt.LOGIC_ALERT_TITLE}
                  </Typography>
                  <Stack gap={2}>
                    {attributionTxt.LOGIC_ALERT_RULES.map((val) => (
                      <Typography key={val}>{val}</Typography>
                    ))}
                  </Stack>
                </Box>
              }
            >
              <Button variant="text" onClick={handleTooltipOpen}>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  sx={{ borderBottom: "1px dotted", cursor: "pointer" }}
                >
                  {allocationTxt.LEARN_HOW_TO_DEFINE_LOGIC_TITLE}
                </Typography>
              </Button>
            </HtmlTooltip>
          </span>
        </ClickAwayListener>
      </Grid>
      {guidedExperienceMode && (
        <Typography
          variant="caption"
          color="textSecondary"
          sx={{
            pl: 1,
          }}
        >
          {attributionTxt.OPERATORS_BETWEEN_RULES}
          <Link
            color="inherit"
            href="https://help.doit.com/docs/cloud-analytics/attributing-cloud-spend/create-attribution#create-an-attribution-from-scratch"
            target="_blank"
            variant="inherit"
            sx={{
              pl: 0.5,
            }}
          >
            {attributionTxt.LEARN_MORE}
          </Link>
        </Typography>
      )}
    </Grid>
  );
};

export default LogicalFormulaInput;
