import { type ReactNode } from "react";

import { loadBundle } from "@doitintl/models-firestore";
import { useQuery } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { useApiContext } from "../../api/context";
import { useAuthContext } from "../../Context/AuthContext";

export const LoadBundleData = ({ children }: { children: ReactNode }) => {
  const { userId } = useAuthContext();
  const api = useApiContext();

  const { data } = useQuery<ArrayBuffer, AxiosError>({
    queryKey: ["authUserData"],
    queryFn: async () => {
      const { data } = await api.request<ArrayBuffer>({
        method: "get",
        url: "/auth/data/authUserData",
        responseType: "arraybuffer",
      });

      await loadBundle(data);

      return data;
    },
    enabled: !!userId,
    staleTime: 0, // caching comes from browser
  });

  if (!data) {
    return null;
  }

  return <>{children}</>;
};
