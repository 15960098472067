// noinspection JSUnusedGlobalSymbols

import { collection, type Reference, subCollection, type Timestamp } from "@doitintl/models-types";

import { type AccountManagerModel } from "./AccountManager";
import { type AssetModel } from "./Asset";
import { type CloudAnalyticsModelAttributionGroupsModel } from "./CloudAnalytics";
import { type ProductEnum } from "./Collection";
import { type UpdatedBy } from "./Contract";
import { type CurrencyCode } from "./Currency";
import { type DashboardModelAttributionModel, type DashboardType } from "./Dashboard";
import { type CustomerEnrichment } from "./Enrichment";
import { type EntityModel } from "./Entity";
import { type InAppNotificationKnownIssueModel } from "./InAppNotifications";
import { type ReportOrgMetadataModel } from "./Metadata";
import { type MetadataCopyModel } from "./MetadataCopies";
import { type NotificationModel } from "./Notifications";
import { type ChannelPartnerModel } from "./Partner";
import { type RoleModel } from "./Role";
import { type TierModel, type TierPackageType } from "./Tier";
import { type UserPermission } from "./User";

type SupportedFeature = {
  hasRequiredPermissions: boolean;
  name: string;
};

export enum AccessLevel {
  ORGANIZATION = "organization",
  PROJECT = "project",
  DATASET = "dataset",
}

export type DashboardRowHeight = number | null;

@subCollection("accountAssumption")
export class CustomerModelAccountAssumption {
  customer!: Reference<CustomerModel>;

  accountAssumptionUntil!: Timestamp | null;

  requestedBy!: string;
}

@subCollection("cloudServices")
export class CustomerModelCloudServices {
  costCurrentMonth?: number;

  costLastMonth?: number;

  costLastThreeMonth?: number;

  customer!: Reference<CustomerModel>;

  entity!: Reference<EntityModel>;

  serviceName!: string;

  type!: "amazon-web-services" | "google-cloud";
}

export type CloudConnectModelGoogleCloudStandalone = {
  billingAccountId: string;
  customer: Reference<CustomerModel>;
  serviceAccountEmail: string;
  type: "google-cloud-standalone";
};

export type CloudConnectModelAWSStandalone = {
  accountId: string;
  arn: string;
  billing_etl: {
    manifest_file_history?: { [key: string]: Timestamp | null };
    settings: {
      active: boolean;
      bucket: string;
      cur_base_path: string;
      doit_arn: "arn:aws:iam::068664126052:role/doitintl_cmp_prod";
    };
  };
  customer: Reference<CustomerModel>;
  type: "amazon-web-services-standalone";
};

enum CloudConnectAmazonWebServicesErrors {
  Empty = "",
  RoleIsNotValid = "role is not valid",
  UnauthorizedPermissionRequest = "unauthorized permission request",
}

type CloudConnectAmazonWebServicesError = `${CloudConnectAmazonWebServicesErrors}`;

// aws
export type CloudConnectAmazonWebServices = {
  accountId: string;
  arn: string;
  cloudPlatform: "amazon-web-services";
  customer: Reference<CustomerModel>;
  error: CloudConnectAmazonWebServicesError;
  roleId: string;
  roleName: string;
  status: number;
  supportedFeatures?: SupportedFeature[];
  timeLinked: Timestamp | null;
};

export type CloudConnectAzureCloud = {
  customer: Reference<CustomerModel>;
  tenantId: string;
  cloudPlatform: "microsoft-azure";
  supportedFeatures?: SupportedFeature[];
  status: number;
  timeLinked: Timestamp | null;
};

export enum CategoryStatus {
  NotConfigured,
  Healthy,
  Unhealthy,
  Critical,
  Partial,
}

export type CloudConnectCategoryStatus = {
  "bigquery-finops": CategoryStatus;
  "bigquery-finops-advanced": CategoryStatus;
  "bigquery-finops-editions": CategoryStatus;
  "bigquery-finops-insights": CategoryStatus;
  core: CategoryStatus;
  "fsk8s-core"?: CategoryStatus;
  governance?: CategoryStatus;
  "rightsizing-implementation": CategoryStatus;
  "rightsizing-recommendation": CategoryStatus;
  sandboxes?: CategoryStatus;
};

export const CATEGORY_FRIENDLY_NAMES: Record<string, string> = {
  "bigquery-finops": "BigQuery Lens",
  "bigquery-finops-advanced": "BigQuery Lens Advanced",
  "bigquery-finops-editions": "BigQuery Lens Editions",
  "bigquery-finops-insights": " BigQuery Lens Insights",
};

// gcp
export type CloudConnectGoogleCloud = {
  categoriesStatus: CloudConnectCategoryStatus;
  clientEmail: string;
  clientId: string;
  cloudPlatform: "google-cloud";
  customer: Reference<CustomerModel>;
  key: {
    data: number[];
    type: "Buffer";
  };
  organizations: SandboxOrganization[] | null;
  projectId?: string;
  datasetId?: string;
  roleId?: string;
  scope?: AccessLevel;
  sinkDestination?: string;
  status: number;
  bigQueryLensVPCAccessStatus?: CategoryStatus;
  workloadIdentityFederationStatus: CategoryStatus;
};

@subCollection("cloudConnect")
export class CustomerModelCloudConnectModel {
  id?: string;

  accountId!: string;

  arn!: string;

  cloudPlatform!: "amazon-web-services" | "google-cloud" | "microsoft-azure";

  customer!: Reference<CustomerModel>;

  error?: CloudConnectAmazonWebServicesError;

  roleId?: string;

  roleName?: string;

  status!: number;

  supportedFeatures?: SupportedFeature[];

  timeLinked?: Timestamp | null;

  billing_etl?: {
    manifest_file_history?: { [key: string]: Timestamp | null };
    settings?: {
      active: boolean;
      bucket: string;
      cur_base_path: string;
      doit_arn: "arn:aws:iam::068664126052:role/doitintl_cmp_prod";
    };
  };

  categoriesStatus?: CloudConnectCategoryStatus;

  clientEmail?: string;

  clientId?: string;

  key?: {
    data: number[];
    type: "Buffer";
  };

  organizations?:
    | {
        displayName: string;
        name: string;
      }[]
    | null;

  projectId?: string;

  scope?: AccessLevel;

  sinkDestination?: string;

  bigQueryLensVPCAccessStatus?: CategoryStatus;

  workloadIdentityFederationStatus?: number;

  billingAccountId?: string;

  serviceAccountEmail?: string;

  type?: "google-cloud-standalone" | "amazon-web-services-standalone";

  notified?: boolean;
}

export enum WidgetState {
  Processing = "processing",
  Success = "success",
  Failed = "failed",
}

export type Widget = {
  cardWidth?: number | null; // do not use!!! DEPRECATED
  width?: number;
  name: string;
  state?: WidgetState;
};

@subCollection("publicDashboards")
export class CustomerModelPublicDashboardsModel {
  ID?: string;

  allowToEdit!: boolean;

  customerId!: string;

  dashboardType?: DashboardType | null;

  isPublic?: true;

  name!: string;

  ownerId!: string;

  requiredPermissions?: UserPermission[] | null;

  sortNumber?: number;

  heights?: DashboardRowHeight[];

  widgets!: Widget[];
}

export type AccountManagersRole =
  | "account_manager"
  | "customer_engineer"
  | "partner_sales_engineer"
  | "strategic_accounts_manager"
  | "customer_success_manager"
  | "technical_account_manager"
  | "all";

export enum AccountManagerCompanies {
  GCP = "google_cloud_platform",
  AWS = "amazon_web_services",
  MicrosoftAzure = "microsoft_azure",
  DOIT = "doit",
}

export type AccountManagersCompany = `${AccountManagerCompanies}`;

export enum AccountManagerStatuses {
  INIT = "INIT",
  HIRED = "HIRED",
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  TERMINATED = "TERMINATED",
}

export type AccountManagersStatus = `${AccountManagerStatuses}`;

@subCollection("customerOrgs")
export class CustomerModelOrganizationModel {
  allowCustomDashboards!: boolean;

  customer!: Reference<CustomerModel>;

  dashboards!: string[];

  description!: string;

  disableAccountDashboard?: boolean;

  lastAccessed?: Timestamp;

  name!: string;

  parent!: Reference<CustomerModelOrganizationModel> | null;

  scope!: Reference<DashboardModelAttributionModel>[];

  timeCreated!: Timestamp;

  timeModified!: Timestamp;

  subCollections?: {
    assetsReportMetadata: {
      subCollections: {
        reportOrgMetadata: ReportOrgMetadataModel;
      };
    };
  };
}

@subCollection("slackChannel")
export class CustomerModelSlackChannelModel {
  id?: string;

  is_shared?: boolean;

  workspace?: string;

  invitationUrl?: string;

  num_members!: number;

  name!: string;
}

@subCollection("jiraInstances")
export class CustomerModelJiraInstancesModel {
  status!: string;

  url!: string;

  installationCode!: string;
}

export type CompanyAccountManager = {
  ref: Reference<AccountManagerModel>;
  notification: number;
};

export enum Classification {
  Business = "business",
  Inactive = "inactive",
  Terminated = "terminated",
  Strategic = "strategic",
  SuspendedForNonPayment = "suspendedForNonPayment",
}

type Alert = {
  trigger: boolean;
  remainingAmount: number;
  lastMonthAmount: number;
};

export type CreditType = ProductEnum.GoogleCloud | ProductEnum.AmazonWebServices;

@subCollection("customerCredits")
export class CustomerCreditModel {
  alerts?: Record<string, Alert>;

  amount!: number;

  assets!: Reference<AssetModel>[] | null;

  currency!: CurrencyCode;

  customer!: Reference<CustomerModel>;

  depletionDate!: Timestamp | null;

  endDate!: Timestamp;

  entity!: Reference<EntityModel> | null;

  metadata!: Record<string, unknown>;

  name!: string;

  scope?: string[];

  startDate!: Timestamp;

  timestamp!: Timestamp;

  type!: CreditType;

  updatedBy!: UpdatedBy | string;

  utilization!: Record<string, Record<string, number>>;

  _status?: string;

  _remaining?: number;

  _entity?: string;
}

@subCollection("sandboxPolicies")
export class CustomerModelSandboxPoliciesModel {
  action!: string;

  active!: boolean;

  amount!: number;

  billingAccount!: string;

  email!: string;

  folder!: null | SandboxFolder;

  interval!: string;

  limit!: number;

  namePrefix!: string;

  organization!: SandboxOrganization;

  timestamp!: Timestamp;

  type!: string;
}

export type SandboxFolder = {
  displayName: string;
  name: string;
};

export type SandboxOrganization = {
  displayName: string;
  name: string;
};

export type AccountManagers = Partial<
  Record<AccountManagersCompany, Partial<Record<AccountManagersRole, CompanyAccountManager | null>>>
>;

export type AccountTeamMember = {
  company: AccountManagersCompany;
  ref: Reference<AccountManagerModel>;
  supportNotificationLevel: number;
};

export type AccountTeam = AccountTeamMember[];

export type CustomerSettingsInvoicing = {
  maxLineItems: number;
};

export type CustomerSettings = {
  currency: CurrencyCode;
  invoicing?: CustomerSettingsInvoicing;
  timezone?: string;
};

export enum CustomerSegmentValues {
  NA = "N/A",
  Invest = "Invest",
  Incubate = "Incubate",
  Accelerate = "Accelerate",
}

export type CustomerSegment = {
  currentSegment: CustomerSegmentValues;
  overrideSegment?: CustomerSegmentValues | null;
  monthlyCloudSpend?: number;
};

export enum CreatedBy {
  Superquery = "superquery",
  TrialSignup = "trial-signup",
}

export enum FlexsaveTypes {
  AWS = "AWS",
  GCP = "GCP",
}

export type FlexsaveType = `${FlexsaveTypes}`;

export enum EarlyAccessFeature {
  Governance = "Governance",
  AWS_TCO = "AWS TCO Analysis",
  DASHBOARD_V2 = "Dashboard V2",
  GCP_CLOUD_CONNECT_V2 = "GCP Cloud Connect V2",
  FSGCP_DISABLED = "FSGCP Disabled",
  FSGCP_MARKETPLACE_DISABLED = "FSGCP Marketplace disabled",
  FLEXSAVE_GCP_STANDALONE = "Flexsave GCP Standalone",
  FLEXSAVE_AWS_STANDALONE = "Flexsave AWS Standalone",
  FSAWS_DISABLED = "FSAWS Disabled",
  DAILY_DIGEST = "Daily Digest",
  COUNT_AGGREGATION = "Count Aggregation",
  ATTRIBUTION_GROUPS = "Attribution Groups",
  DISABLE_CREDIT_CARD_FEES = "Disable Credit Card Fees",
  ACCELERATOR_PROGRAM = "Accelerator Program",
  EKS_ONBOARDING = "EKS Onboarding",
  ALLOCATIONS_PAGES = "View Allocation Page",
  REPORT_TEMPLATES = "Report templates",
  NOTIFICATION_CENTER = "Notification Center",
  EXTENDED_METRIC = "Extended metric",
  THREADS = "Threads",
  FSK8S = "FSK8S",
  FORECAST_GROUPING_MODE = "Forecast Grouping Mode",
  BUDGET_CONFIGURATION_ROLLOVER = "Budget Configuration Rollover",
  FORECAST_BQ_ML = "BigQuery ML Forecasting",
  AWS_MAP_LENS = "AWS MAP Lens",
  TRUSTED_ADVISOR_INSIGHTS = "Trusted Advisor Insights",
  COST_OPTIMIZATION_HUB_INSIGHTS = "Cost Optimization Hub Insights",
  GCP_RECOMMENDER_BIGQUERY_INSIGHTS = "Insights from GCP Recommender BigQuery export",
  DATADOG_CONNECT = "Datadog Connect",
  CLOUDDIAGRAMS = "Cloud Diagrams",
  SUPPORT_FIRESTORE = "Load Support from Firestore",
  AZURE_CLOUD_CONNECT = "Azure Cloud Connect",
  BUDGET_SEASONALITY = "Budget Seasonality",
}

@subCollection("customerPricebooks")
export class GCPCustomerPricebooksModel {
  assets!: Reference<AssetModel>[] | null;

  customer!: Reference<CustomerModel>;

  endDate!: Timestamp;

  entity!: Reference<EntityModel>;

  metadata?: GCPCustomerPricebookModelMetadata;

  startDate!: Timestamp;

  tableRef!: string;

  type!: "google-cloud";

  updatedBy?: string;
}

export type CustomerType = "standalone" | "trial" | "saasConsole";

export type GCPCustomerPricebookModelMetadata = {
  customer: {
    name: string;
    primaryDomain: string;
  };
  entity: {
    priorityId?: string;
  };
};

@subCollection("customerInvoiceAdjustments")
export class CustomerInvoiceAdjustmentModel {
  amount!: number;

  currency!: CurrencyCode;

  customer!: Reference<CustomerModel>;

  description!: string;

  details!: string;

  entity!: Reference<EntityModel> | null;

  finalized!: boolean;

  invoiceMonths!: Timestamp[];

  metadata!: {
    customer: {
      name: string;
      primaryDomain: string;
      priorityId?: string;
    };
    isRenewDone?: boolean;
    isSentReminderEmail?: boolean;
  };

  timestamp!: Timestamp;

  type!: string;

  updatedBy!: UpdatedBy | string;
}

export type CustomerAuthSso = {
  useSsoWithoutProxy?: boolean;
  saml?: "enabled" | "disabled" | "configured";
  oidc?: "enabled" | "disabled" | "configured";
};

export type CustomerAuthAutoProvision = {
  enabled?: boolean;
  allowedDomains?: string[];
};

export type CustomerAuth = {
  sso?: CustomerAuthSso;
  autoProvision?: CustomerAuthAutoProvision;
};

export const CustomerSecurityMode = {
  NONE: "none",
  RESTRICTED: "restricted",
};

@subCollection("flexsaveStandalone")
export class FlexsaveStandaloneModel {
  billingImportStatus!: {
    maxStartTime: Timestamp;
    maxTotalExecutionTime: Timestamp;
    status: string;
    error?: string;
  };

  customer!: Reference<CustomerModel>;
}

type DefaultEmailsModel = {
  docs: {
    emailCC: Record<string, string>;
  };
};

@subCollection("billingStandalone")
export class BillingStandaloneModel {
  billingImportStatus!: {
    maxStartTime: Timestamp;
    maxTotalExecutionTime: Timestamp;
    status: string;
    error?: string;
  };

  customer!: Reference<CustomerModel>;
}

export type Providers = {
  providers: ProductEnum[];
};

export type NavigatorOnboarding = Providers & {
  isTierBillingConfigured?: boolean;
  hasSeenWelcomeDialog?: boolean;
};

export class Onboarding {
  startTrialInitiated?: boolean;

  isOnboardingCallDone?: boolean;

  advantage?: Providers;

  navigator?: NavigatorOnboarding;

  solve?: Providers;
}

export class Salesforce {
  id?: string;

  opportunityId?: string;
}

class Marketplace {
  accountExists?: boolean;

  doitConsole?: boolean;
}

export class CustomerMarketplaces {
  AWS?: Marketplace;

  GCP?: Marketplace;
}

export enum AuthenticationProvider {
  Google = "google",
  Microsoft = "microsoft",
  Password = "password",
}

type CustomerModelAdminModel = {
  "g-suite"?: string[];
};

export enum SaaSConsoleType {
  AWS = "AWS",
  GCP = "GCP",
  AZURE = "AZURE",
  SNOWFLAKE = "SNOWFLAKE",
  DATADOG = "DATADOG",
}

export type CloudSelectorType = SaaSConsoleType;

export class CustomerTier {
  trialStartDate?: Timestamp | null;

  trialEndDate?: Timestamp | null;

  trialCanceledDate?: Timestamp | null;

  tier?: Reference<TierModel>;

  trialCustomLength?: number | null;
}

export type CloudOnHoldType =
  | "amazon-web-services"
  | "g-suite"
  | "google-cloud"
  | "microsoft-azure"
  | "office-365"
  | "navigator"
  | "solve"
  | "doit-cloud-intelligence";

type CloudOnHoldDetails = {
  note: string;
  email: string;
  timestamp: Timestamp;
};

export type InvoicesOnHold = Partial<Record<CloudOnHoldType, CloudOnHoldDetails>>;

export type PresentationMode = {
  isPredefined: boolean;
  enabled: boolean;
  customerId?: string;
};

export type ZendeskOrgInfo = {
  sharedTickets: boolean;
  sharedComments: boolean;
};

@collection("customers")
export class CustomerModel {
  _name!: string;

  accountManager!: null | Reference<AccountManagerModel>;

  accountManagers!: AccountManagers;

  accountTeam?: AccountTeam;

  allowAuthProvider?: null | AuthenticationProvider;

  assets?: ProductEnum[];

  canBypassPles?: boolean;

  classification?: Classification;

  createdBy?: CreatedBy;

  customerSegment?: CustomerSegment;

  defaultRole?: Reference<RoleModel>;

  domains!: string[];

  earlyAccessFeatures?: EarlyAccessFeature[];

  enabledFlexsave?: Record<FlexsaveType, boolean>; // flexsave refers here to SaaS flow only

  enabledSaaSConsole?: Record<SaaSConsoleType, boolean>;

  enrichment!: CustomerEnrichment;

  enrichmentUpdateTime?: Timestamp;

  entities!: Reference<EntityModel>[];

  invoiceAttributionGroup?: Reference<CloudAnalyticsModelAttributionGroupsModel>;

  name!: string;

  onboarding?: Onboarding;

  primaryDomain!: string;

  salesforce?: Salesforce;

  securityMode?: "none" | "restricted";

  selfEarlyAccessFeature?: EarlyAccessFeature[];

  settings?: CustomerSettings;

  sharedDriveFolderId!: null | string;

  skipRemedyBreach?: boolean;

  subscribers?: string[];

  timeCreated?: Timestamp;

  treemapRenderConfig?: string;

  trialEndDate?: Timestamp | null;

  tiers?: Record<TierPackageType, CustomerTier>;

  auth?: CustomerAuth;

  type?: CustomerType;

  marketplace?: CustomerMarketplaces;

  gcpCustomerSegment?: string;

  concedefyDisabled?: boolean;

  partnerOfRecord?: Reference<ChannelPartnerModel>;

  presentationMode?: PresentationMode;

  invoicesOnHold?: InvoicesOnHold;

  zendeskOrganizationInfo?: ZendeskOrgInfo;

  companyProfile?: {
    companySize: string;
    goals: string[];
  };

  subCollections?: {
    defaultEmails: DefaultEmailsModel;
    publicDashboards: CustomerModelPublicDashboardsModel;
    cloudConnect: CustomerModelCloudConnectModel;
    customerOrgs: CustomerModelOrganizationModel;
    accountAssumption: CustomerModelAccountAssumption;
    customerPricebooks: GCPCustomerPricebooksModel;
    customerCredits: CustomerCreditModel;
    customerInvoiceAdjustments: CustomerInvoiceAdjustmentModel;
    flexsaveStandalone: FlexsaveStandaloneModel;
    billingStandalone: BillingStandaloneModel;
    sandboxPolicies: CustomerModelSandboxPoliciesModel;
    slackChannel: CustomerModelSlackChannelModel;
    jiraInstances: CustomerModelJiraInstancesModel;
    admins: CustomerModelAdminModel;
    cloudServices: CustomerModelCloudServices;
    notifications: NotificationModel;
    affectedByKnownIssues: InAppNotificationKnownIssueModel;
    metadataCopies: MetadataCopyModel;
  };
}
