import { createContext, useContext, useEffect, useState } from "react";

import axios from "axios";

import { useAuthContext } from "../../Context/AuthContext";
import { paths } from "../../Pages/Login/helpers";

type AnnounceKitContextType = {
  openAnnounceKit: () => void;
  unread?: number;
};

export const announceKitContext = createContext<AnnounceKitContextType | undefined>(undefined);

export const useAnnounceKit = () => {
  const context = useContext(announceKitContext);

  if (!context) {
    throw new Error("useAnnounceKit must be used within a AnnounceKitWrapper");
  }

  return context;
};

export const useAnnounceKitToken = () => {
  const [announcekitToken, setAnnouncekitToken] = useState<string | null>(null);
  const { tokenValue } = useAuthContext({ mustHaveUser: true });

  useEffect(() => {
    if (!tokenValue) {
      return;
    }

    axios
      .request({
        method: "get",
        url: paths.authAnnounceKit,
        headers: {
          Authorization: `Bearer ${tokenValue}`,
        },
      })
      .then((response) => {
        setAnnouncekitToken(response.data.token);
      });
  }, [tokenValue]);

  return { announcekitToken };
};
