import {
  APP_KEY,
  CloudFlowNodeType,
  type INodeModel,
  NODE_STATUS,
  type NodeModel,
  type NodeParameters,
  type NodeTransitionList,
} from "@doitintl/cmp-models";
import { type Edge, getConnectedEdges, getIncomers, getOutgoers, type Node } from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { type BaseCloudflowHit, type CreateOrUpdateNode, type RFNode } from "../../types";
import { mapToCreateNodePayload, mapToUpdateNodePayload } from "./nodeTransformUtils";

const position = { x: 0, y: 0 };

// TODO: Implement the getNode function correctly as per the node types
export const initializeNode: (nodeType: CloudFlowNodeType, nodeId: string) => Node<RFNode> = (
  nodeType: CloudFlowNodeType,
  nodeId: string
) => {
  switch (nodeType) {
    case CloudFlowNodeType.TRIGGER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.TRIGGER,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Schedule",
            status: NODE_STATUS.ERROR,
            display: {
              position,
            },
            type: CloudFlowNodeType.TRIGGER,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
            errorMessages: { param_error: "Incorrect form parameters" },
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.MANUAL_TRIGGER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.MANUAL_TRIGGER,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Manually start",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.MANUAL_TRIGGER,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.FILTER:
      return {
        id: nodeId,
        type: CloudFlowNodeType.FILTER,
        position: position ?? { x: 0, y: 300 },
        data: {
          touched: false,
          nodeData: {
            name: "Filter",
            status: NODE_STATUS.ERROR,
            display: {
              position,
            },
            parameters: {
              referencedNodeId: "",
              referencedField: [],
              conditionGroups: [],
            },
            type: CloudFlowNodeType.FILTER,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
            errorMessages: { param_error: "Incorrect form parameters" },
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.ACTION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Perform action",
            status: NODE_STATUS.ERROR,
            display: {
              position,
            },
            type: CloudFlowNodeType.ACTION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
            errorMessages: { param_error: "Incorrect form parameters" },
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.CONDITION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.CONDITION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "IF statement",
            status: NODE_STATUS.ERROR,
            display: {
              position,
            },
            parameters: {
              referencedNodeId: "",
              referencedField: [],
              conditionGroups: [],
            },
            type: CloudFlowNodeType.CONDITION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
            errorMessages: { param_error: "Incorrect form parameters" },
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.TRANSFORMATION:
      return {
        id: nodeId,
        type: CloudFlowNodeType.TRANSFORMATION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Transform",
            status: NODE_STATUS.ERROR,
            display: {
              position,
            },
            // TODO: Implement the correct parameters for the transformation node
            parameters: {
              referencedNodeId: "",
              referencedField: [],
              transformations: [],
            },
            type: CloudFlowNodeType.TRANSFORMATION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
            errorMessages: { param_error: "Incorrect form parameters" },
          },
        },
      } satisfies Node<RFNode>;
    case CloudFlowNodeType.START_STEP:
      return {
        id: nodeId,
        type: CloudFlowNodeType.START_STEP,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Start flow",
            status: NODE_STATUS.PENDING,
            display: {
              position,
            },
            type: CloudFlowNodeType.START_STEP,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
    default:
      return {
        id: nodeId,
        type: CloudFlowNodeType.ACTION,
        position,
        data: {
          touched: false,
          nodeData: {
            name: "Perform action",
            status: NODE_STATUS.VALIDATED,
            display: {
              position,
            },
            type: CloudFlowNodeType.ACTION,
            transitions: null,
            appKey: APP_KEY.INTERNAL,
          },
        },
      } satisfies Node<RFNode>;
  }
};

export const isGhostNode = (node: Node | NodeModel<CloudFlowNodeType>) => node.type === CloudFlowNodeType.GHOST;

export const findNodeById = (nodes: Node<RFNode>[], id: string) => nodes.find((node) => node.id === id);

export const getTreeOfOutgoers = (
  nodes: Node<RFNode<CloudFlowNodeType>>[],
  edges: Edge[],
  id: string
): { nodes: Node<RFNode>[]; edges: Edge[] } => {
  const currentNode = findNodeById(nodes, id);
  if (!currentNode) return { nodes: [], edges: [] };

  const outgoers = getOutgoers<Node<RFNode>>(currentNode, nodes, edges).filter((node) => !isGhostNode(node));
  if (!outgoers.length) return { nodes: [], edges: [] };

  const connectedEdges = getConnectedEdges<Node<RFNode>>(outgoers, edges);

  const hasMultipleChildren = outgoers.some((outgoer) => {
    const outgoerChildren = getOutgoers<Node<RFNode>>(outgoer, nodes, edges);
    return outgoerChildren.length > 1;
  });

  // If the current node has multiple children, treat it as a root and return only the current outgoers
  if (hasMultipleChildren) {
    return {
      nodes: outgoers,
      edges: connectedEdges,
    };
  }

  return outgoers.reduce(
    (acc, outgoer) => {
      const childResult = getTreeOfOutgoers(nodes, edges, outgoer.id);

      return {
        nodes: [...acc.nodes, ...childResult.nodes],
        edges: [...acc.edges, ...childResult.edges],
      };
    },
    { nodes: outgoers, edges: connectedEdges }
  );
};

export const getIncomerNode = (
  node: Node<RFNode<CloudFlowNodeType>>,
  nodes: Node<RFNode<CloudFlowNodeType>>[],
  edges: Edge[]
) => getIncomers(node, nodes, edges)[0];

const buildNodeWithParams = (
  node: Node<RFNode<CloudFlowNodeType>>,
  item: BaseCloudflowHit,
  additionalNodeData?: Partial<INodeModel<CloudFlowNodeType>>
) => {
  const parameters = {
    provider: item.provider,
    operation: {
      id: item.operationName,
      service: item.serviceNameShort,
      version: item.versionId,
      provider: item.provider,
    },
    formValues: {},
    configurationValues: {},
  };

  return {
    ...node,
    data: {
      ...node.data,
      nodeData: {
        ...node.data.nodeData,
        name: item.operationName,
        status: NODE_STATUS.ERROR,
        statusMessage: "Additional permissions needed",
        parameters: parameters as NodeParameters,
        ...additionalNodeData,
      },
    },
  };
};

export const getCreateActionNodePayload = (
  item: BaseCloudflowHit,
  parentNode: Node<RFNode<CloudFlowNodeType>>,
  incomingEdgeLabel: string | undefined,
  targetNode: Node<RFNode<CloudFlowNodeType>>
) => {
  const newNodeId = uuidv4();
  const newNode = initializeNode(CloudFlowNodeType.ACTION, newNodeId);

  const nodeWithParams = buildNodeWithParams(newNode, item);

  const newNodeRequestData: CreateOrUpdateNode = {
    transition: {
      parentNodeId: parentNode.id,
      targetNodeId: newNodeId,
      label: incomingEdgeLabel,
    },
    node: mapToCreateNodePayload(nodeWithParams, isGhostNode(targetNode) ? undefined : targetNode),
  };
  return newNodeRequestData;
};

export const getUpdateActionNodePayload = (
  nodeId: string,
  transitions: NodeTransitionList,
  item: BaseCloudflowHit,
  targetNode: Node<RFNode<CloudFlowNodeType>>
) => {
  const newNode = initializeNode(CloudFlowNodeType.ACTION, nodeId);

  const nodeWithParams = buildNodeWithParams(newNode, item, { transitions });

  const newNodeRequestData: CreateOrUpdateNode = {
    node: mapToCreateNodePayload(nodeWithParams, isGhostNode(targetNode) ? undefined : targetNode),
  };

  return newNodeRequestData;
};

export const getTriggerNodePayload = (
  triggerNode: Node<RFNode<CloudFlowNodeType>>,
  type: CloudFlowNodeType,
  flowId: string
) => {
  const triggerNodeWithDefaultParams = initializeNode(type, triggerNode.id);
  const transitions = (triggerNode.data.nodeData.transitions || []).filter((t) => !t.targetNodeId.includes("-ghost"));
  const triggerNodeWithTransitions = {
    ...triggerNodeWithDefaultParams,
    data: {
      ...triggerNodeWithDefaultParams.data,
      nodeData: {
        ...triggerNodeWithDefaultParams.data.nodeData,
        transitions,
      },
    },
    name: triggerNodeWithDefaultParams.data.name,
  };
  const updateNodesPayload = {
    flowId,
    updatedNodes: [
      {
        node: mapToUpdateNodePayload(triggerNodeWithTransitions),
      },
    ],
  };
  return {
    triggerNodeWithTransitions,
    updateNodesPayload,
  };
};

export const createTransitionPayload = (
  incomerNodeId: string,
  label: string | undefined,
  newNode: Node<RFNode<CloudFlowNodeType>>,
  targetNode: Node<RFNode<CloudFlowNodeType>> | undefined
): CreateOrUpdateNode => ({
  transition: {
    parentNodeId: incomerNodeId,
    targetNodeId: newNode.id,
    label,
  },
  node: mapToCreateNodePayload(newNode, targetNode),
});
