import React, { type ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { createTRPCProxyClient, httpBatchLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";

import { useAuthContext } from "../../../Context/AuthContext";
import { type FirebaseUserWithEmail } from "../../../Context/UserContext";
import { useCurrentLanguage } from "../../../utils/useCurrentLanguage";
import { SeverityLevels } from "../../types";
import type { CaseIQRouter } from "../../../../../services/chatgpt/case-iq/src/router";

const minWords = 4;
let caseIqSessionId = "";
let zdTicketId = "";

export const setCaseIqSessionId = (sessionId: string) => {
  caseIqSessionId = sessionId;
};
export const setZdTicketId = (ticketId: string) => {
  zdTicketId = ticketId;
};

const caseIQBaseUrlV1trpc = "/api/caseiq/v1/trpc";

export const caseIQTrpc = createTRPCReact<CaseIQRouter>();

export const CaseIQClientProvider = ({ children }: { children: ReactNode }) => {
  const { currentUser } = useAuthContext();

  const getToken = useCallback(
    async (forceRefresh?: boolean) => currentUser?.getIdTokenResult(forceRefresh),
    [currentUser]
  );
  const queryClient = useQueryClient();
  const [trpcClient] = useState(() =>
    caseIQTrpc.createClient({
      links: [
        httpBatchLink({
          url: `${window.location.origin}${caseIQBaseUrlV1trpc}`,
          async headers() {
            const currentToken = await getToken();
            return {
              Authorization: currentToken ? `Bearer ${currentToken.token}` : undefined,
              "x-caseiq-session-id": caseIqSessionId,
              "x-zendesk-ticket-id": zdTicketId,
            };
          },
        }),
      ],
    })
  );

  return (
    <caseIQTrpc.Provider client={trpcClient} queryClient={queryClient}>
      {children}
    </caseIQTrpc.Provider>
  );
};

export function getClient(currentUser: FirebaseUserWithEmail | null | undefined) {
  return createTRPCProxyClient<CaseIQRouter>({
    links: [
      httpBatchLink({
        url: `${window.location.origin}${caseIQBaseUrlV1trpc}`,
        async headers() {
          const currentToken = await currentUser?.getIdTokenResult();
          return {
            Authorization: currentToken ? `Bearer ${currentToken.token}` : undefined,
            "x-caseiq-session-id": caseIqSessionId,
            "x-zendesk-ticket-id": zdTicketId,
          };
        },
      }),
    ],
  });
}

export const stripHtmlTags = (html: string): string => html.replace(/<\/?[^>]+(>|$)/g, "");

type CaseIQResponse = {
  technicalDetails: Array<{ name: string; value: string | null }>;
  requiredFields: {
    platform: string;
    product: string;
    severity: string;
    assetId: string | null;
  };
};

export type CaseIQPayload = {
  description: string;
  userSelectedFields: {
    subject?: string;
    product?: string;
    platform?: string;
  };
  conversationId?: string;
};

const caseIQIsEmpty = (caseIQpayload: CaseIQPayload) => !caseIQpayload.description;

export const useCaseIQByDetails = (caseIQpayload: CaseIQPayload) => {
  const [caseIQDetails, setCaseIQDetails] = useState<CaseIQResponse | null>(null);
  const [loading, setLoading] = useState(false);

  const hasEnoughWords = caseIQpayload.description ? caseIQpayload.description.split(" ").length >= minWords : true;

  const { mutateAsync } = caseIQTrpc.v2.ticketDetailsInference.useMutation();

  useEffect(() => {
    if (hasEnoughWords && !caseIQIsEmpty(caseIQpayload)) {
      setLoading(true);
      mutateAsync(caseIQpayload).then((data) => {
        setCaseIQDetails(data);
        setLoading(false);
      });
    } else {
      setCaseIQDetails(null);
    }
  }, [hasEnoughWords, mutateAsync, caseIQpayload]);

  return { caseIQDetails, loading };
};

export const useCaseIQByID = () => {
  const { mutateAsync } = caseIQTrpc.v2.ticketDetailsInference.useMutation();

  const fetchCaseIQ = async (conversationId: string) => {
    const data: CaseIQResponse = await mutateAsync({ conversationId });
    return data;
  };

  return fetchCaseIQ;
};

const supportedProducts = [
  "gcp/compute-engine",
  "gcp/bigquery",
  "aws/amazon-ec2",
  "aws/amazon-rds",
  "gcp/cloud-load-balancing",
  "gcp/cloud-composer",
  "gcp/identity-and-access-management-iam",
  "aws/amazon-elastic-kubernetes-service-eks",
  "gcp/cloud-storage",
  "gcp/app-engine",
  "gcp/cloud-functions",
  "gcp/cloud-run",
  "gcp/cloud-sql",
  "gcp/google-kubernetes-engine-gke",
  "gcp/looker",
];

type Props = {
  severity: SeverityLevels | undefined;
  cloudProduct: string | undefined;
};

export const useABTestingCaseIQ = ({ severity, cloudProduct }: Props): boolean => {
  const lang = useCurrentLanguage();

  return useMemo(() => {
    if (!severity || !cloudProduct) {
      return false;
    }

    if (![SeverityLevels.high, SeverityLevels.urgent].includes(severity)) {
      return false;
    }

    if (!supportedProducts.includes(cloudProduct)) {
      return false;
    }

    if (lang !== "en") {
      return false;
    }

    return true;
  }, [lang, severity, cloudProduct]);
};
