import { type Dispatch, useState } from "react";

import { CustomerModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { CircularProgress, Link, Switch, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Stack from "@mui/material/Stack";

import { ssoTexts } from "../../../../assets/texts";
import NoEntitlement from "../../../../Components/NoEntitlement/NoEntitlement";
import { useSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { getEnabledConfig, providers } from "../helpers";
import { useAsyncActions, useIsAllowedToConfigureSso } from "../hooks";
import { type AuthAction, AuthActionKind, type AuthState, type SSOProviders } from "../types";
import SsoDialog from "./SsoDialog";
import { SsoProviderConfigCard } from "./SsoProviderConfigCard";

type Props = {
  state: AuthState;
  dispatch: Dispatch<AuthAction>;
};
const Sso = ({ state, dispatch }: Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const asyncActions = useAsyncActions(dispatch);
  const sharedSnackbar = useSnackbar();
  const { customer } = useCustomerContext();

  const isSsoEntitled = useIsAllowedToConfigureSso();

  const toggleEnableSSO = async () => {
    dispatch({ type: AuthActionKind.TOGGLE_SSO, payload: !state.ssoEnabled });
    if (state.ssoEnabled) {
      await asyncActions.updateProviderConfigs(getEnabledConfig(state, false, false));
      return getCollection(CustomerModel)
        .doc(customer.id)
        .update("auth.sso", {
          saml: customer.auth?.sso?.saml === "enabled" ? "disabled" : customer.auth?.sso?.saml,
          oidc: customer.auth?.sso?.oidc === "enabled" ? "disabled" : customer.auth?.sso?.oidc,
        });
    }
    if (!state.ssoEnabled && (state.saml.id || state.oidc.id)) {
      await asyncActions.updateProviderConfigs(
        getEnabledConfig(state, customer.auth?.sso?.saml === "disabled", customer.auth?.sso?.oidc === "disabled")
      );

      return getCollection(CustomerModel)
        .doc(customer.id)
        .update("auth.sso", {
          saml: customer.auth?.sso?.saml === "disabled" ? "enabled" : customer.auth?.sso?.saml,
          oidc: customer.auth?.sso?.oidc === "disabled" ? "enabled" : customer.auth?.sso?.oidc,
        });
    }
  };

  const switchProviderConfigs = async () => {
    setOpenDialog(false);
    if (state.ssoEnabled) {
      const config = getEnabledConfig(state, !state.saml.enabled, !state.oidc.enabled);
      await asyncActions.updateProviderConfigs(config);
      await getCollection(CustomerModel)
        .doc(customer.id)
        .update("auth.sso", {
          saml: customer.auth?.sso?.saml === "enabled" ? "configured" : "enabled",
          oidc: customer.auth?.sso?.oidc === "enabled" ? "configured" : "enabled",
        });
    } else if (state.saml.id && state.oidc.id) {
      await getCollection(CustomerModel)
        .doc(customer.id)
        .update("auth.sso", {
          saml: customer.auth?.sso?.saml === "disabled" ? "configured" : "disabled",
          oidc: customer.auth?.sso?.oidc === "disabled" ? "configured" : "disabled",
        });
    }

    if (state.error) {
      sharedSnackbar.onOpen({
        message: ssoTexts.FAILURE_SNACKBAR_TEXT(
          state.ssoEnabled && customer.auth?.sso?.saml === "enabled" ? "oidc" : "saml"
        ),
        variant: "error",
        autoHideDuration: 3000,
        action: [
          <Link
            key={customer.id}
            href={`/customers/${customer.id}/support/new`}
            color="inherit"
            sx={{ ml: 3, textDecoration: "none" }}
          >
            CONTACT SUPPORT
          </Link>,
        ],
      });
    } else {
      sharedSnackbar.onOpen({
        message: ssoTexts.SUCCESS_SNACKBAR_TEXT(
          (state.ssoEnabled && customer.auth?.sso?.saml === "enabled") ||
            (!state.ssoEnabled && customer.auth?.sso?.saml === "disabled")
            ? "oidc"
            : "saml"
        ),
        variant: "success",
        autoHideDuration: 3000,
      });
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLButtonElement> = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      toggleEnableSSO();
    }
  };

  if (!isSsoEntitled) {
    return <NoEntitlement feature="SSO" />;
  }

  return (
    <Stack
      direction="column"
      spacing={4}
      sx={(theme) => ({ [theme.breakpoints.up("md")]: { ml: 2 }, maxWidth: 600, width: "100%", marginBottom: 2 })}
      data-cy="sso"
    >
      <Typography variant="h1">{ssoTexts.SSO_AUTHENTICATION}</Typography>
      <Stack direction="row" spacing={1}>
        <FormControlLabel
          control={
            <Switch
              checked={state.ssoEnabled}
              disabled={!(state.saml.id || state.oidc.id)}
              onKeyDown={handleKeyDown}
              onChange={toggleEnableSSO}
              color="primary"
              size="small"
              data-cy="enable-SSO"
            />
          }
          label={ssoTexts.ENABLE_SSO}
        />
        {state.loading && !openDialog && <CircularProgress disableShrink={true} color="primary" size={24} />}
      </Stack>
      {providers.map((type: SSOProviders) => (
        <SsoProviderConfigCard
          key={type}
          type={type}
          description={ssoTexts.DESCRIPTION(type.toUpperCase())}
          configured={!!state[type].id}
          ssoEnabled={state.ssoEnabled}
          selected={
            (state.ssoEnabled && customer.auth?.sso?.[type] === "enabled") ||
            (!state.ssoEnabled && customer.auth?.sso?.[type] === "disabled")
          }
          openProviderDialog={() => {
            setOpenDialog(true);
          }}
          state={state}
          data-cy={`SsoProviderConfigCard-${type}`}
        />
      ))}
      <SsoDialog
        loading={state.loading}
        open={openDialog}
        description={ssoTexts.DIALOG_SELECT_DESCRIPTION(
          (state.ssoEnabled && customer.auth?.sso?.saml === "enabled") ||
            (!state.ssoEnabled && customer.auth?.sso?.saml === "disabled")
        )}
        title={ssoTexts.DIALOG_SELECT_TITLE}
        onConfirm={switchProviderConfigs}
        onClose={() => {
          setOpenDialog(false);
        }}
      />
    </Stack>
  );
};
export default Sso;
