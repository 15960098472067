import {
  type AwsMarketplaceAccountModel,
  ContractTypeDoitCloudIntelligence,
  type CustomerModel,
} from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import { type AxiosInstance } from "axios";

import { defaultCurrencySymbol } from "../../../constants/common";
import { type TierWithRef } from "../../../Context/TierProvider";
import { consoleErrorWithSentry } from "../../../utils";
import { awsMarketplaceSubscriptionListingCode } from "./consts";

export const createMarketplaceContracts = async ({
  api,
  tiers,
  customerRef,
  mpAccountData,
}: {
  api: AxiosInstance;
  tiers: TierWithRef[];
  customerRef: ModelReference<CustomerModel>;
  mpAccountData: AwsMarketplaceAccountModel;
}) => {
  let currency;

  try {
    currency = (await customerRef.get()).asModelData()?.settings?.currency;
  } catch (e) {
    consoleErrorWithSentry(e);
  } finally {
    if (!currency) {
      currency = defaultCurrencySymbol;
    }
  }
  const startDate = mpAccountData.createdAt.toDate().toISOString();
  const dimensionsToData =
    mpAccountData.entitlementsData?.reduce(
      (acc, data) => ({
        ...acc,
        [data.Dimension]: data,
      }),
      {}
    ) ?? {};
  return Promise.all(
    tiers.map((tier) => {
      const dimension = tier.sku.replace(/-/g, "_");

      return api.post("/v1/contract/create", {
        contract: {
          customerID: customerRef.id,
          tier: tier.ref.id,
          startDate,
          endDate: dimensionsToData[dimension]?.ExpirationDate?.toDate().toISOString(),
          type:
            mpAccountData.awsAccountData?.ProductCode === awsMarketplaceSubscriptionListingCode
              ? ContractTypeDoitCloudIntelligence
              : tier.packageType,
          pointOfSale: "aws-marketplace",
          currency,
        },
      });
    })
  ).catch(() => null);
};
