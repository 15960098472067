import React from "react";

import { CollapsableTableRow } from "@client/src/Components/CollapsableTableRow";
import { SafeHtml } from "@client/src/Components/SafeHtml";
import { camelToTitleCase } from "@client/src/Pages/Settings/Azure/utils";
import {
  Box,
  Checkbox,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import type { AzureFeaturePermission } from "@services/cmp/models";

export type SelectFeaturesStepProps = {
  azureFeatures: AzureFeaturePermission[] | undefined;
  selectedFeatures: string[];
  setSelectedFeatures: (features: string[]) => void;
};

export const SelectFeaturesStep = ({
  azureFeatures,
  selectedFeatures,
  setSelectedFeatures,
}: SelectFeaturesStepProps) => {
  if (!azureFeatures?.length) {
    return <CircularProgress />;
  }

  const handleSelectionChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      setSelectedFeatures([...selectedFeatures, name]);
    } else {
      setSelectedFeatures(selectedFeatures.filter((feature) => feature != name));
    }
  };

  return (
    <Container maxWidth={false} sx={{ maxWidth: "688px" }} disableGutters key={"connect-azure-select-features"}>
      <>
        <Typography
          variant="h3"
          sx={{
            mb: 3,
            fontSize: 20,
          }}
        >
          Select which features you want to enable
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell width={15} />
              <TableCell>Available features</TableCell>
              <TableCell align="right" padding="checkbox">
                Include
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {azureFeatures.map((feature) => (
              // const isEntitledToThisCategory = isEntitledToCategory(category);

              <CollapsableTableRow
                key={feature.name}
                hiddenContent={
                  <Box m={1}>
                    <ul>
                      {feature.roles.map((role) => (
                        <li key={role.name}>
                          {camelToTitleCase(role.scope)}/{role.name}
                        </li>
                      ))}
                    </ul>
                  </Box>
                }
              >
                <TableCell component="th" scope="row">
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">
                      <SafeHtml html={feature.description} />
                    </Typography>
                  </Stack>
                </TableCell>

                <TableCell padding="none">
                  {/* Don't allow unselecting force included categories (defaults / previously selected), but in case they are not selected for some reason, allow selecting them */}
                  <Checkbox
                    color="primary"
                    disabled={false}
                    checked={selectedFeatures.includes(feature.name)}
                    onChange={(event) => {
                      handleSelectionChange(event, feature.name);
                    }}
                  />
                </TableCell>
              </CollapsableTableRow>
            ))}
          </TableBody>
        </Table>
      </>
    </Container>
  );
};
