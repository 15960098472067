import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import k8sLogo from "../../../assets/kubernetes-logo.svg";

const ClusterPageHeader = () => (
  <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: "8px 0" }}>
    <Stack spacing={1} direction="row" alignItems="center" justifyContent="space-between">
      <img src={k8sLogo} width={24} alt="Kubernetes" title="Kubernetes" />
      <Typography variant="h1" display="inline">
        Kubernetes
      </Typography>
    </Stack>
  </Stack>
);

export default ClusterPageHeader;
