import { AccessLevel } from "@doitintl/cmp-models";

import { datasetProjectAccessRoleID, datasetRoleID, doitConnectSA, roleID, serviceAccountName } from "./constants";

export interface Command {
  header: string;
  body?: string;
}

export type CommandGenerators = Partial<Record<AccessLevel, CommandGenerator>>;

export const getDatasetLevelCommands = (
  permissionList: string[],
  outOfScopePermissions: string[],
  datasetName: string,
  serviceAccountIdentifier: string
): Command[] => [
  {
    header: "Assign your Google IAM Project ID",
    body: "PROJ=<YOUR_PROJECT_ID>",
  },
  {
    header: "Create the DoiT console service account for this dataset",
    body: `gcloud iam service-accounts create doit-cmp-${serviceAccountIdentifier} --project=$PROJ --display-name "DoiT Console" --description "DoiT International Console"`,
  },
  {
    header: "Create a custom role for project-level permissions that are prerequisites for dataset access.",
    body: `gcloud iam roles create ${datasetProjectAccessRoleID} --project=$PROJ --title "DoiT Console dataset access role" --stage GA --permissions ${outOfScopePermissions?.join(",")}`,
  },
  {
    header: "Bind the role to the service account under your project",
    body: `gcloud projects add-iam-policy-binding $PROJ --member=serviceAccount:doit-cmp-${serviceAccountIdentifier}@$PROJ.iam.gserviceaccount.com --role=projects/$PROJ/roles/${datasetProjectAccessRoleID}`,
  },
  {
    header: "Create a custom role for dataset level permissions",
    body: `gcloud iam roles create ${datasetRoleID} --project=$PROJ --title "DoiT Console dataset access role" --stage GA --permissions ${permissionList.join(",")}`,
  },
  {
    header: "Export dataset IAM policy",
    body: `bq show --format=prettyjson "\${PROJ}:${datasetName}" > dataset_policy.json.json`,
  },
  {
    header: "Edit dataset_policy.json.json",
    body: `jq --arg proj "\${PROJ}" --arg roleID "${datasetRoleID}" --arg sa "doit-cmp-${serviceAccountIdentifier}" '.access += [{"role": ("projects/"+$proj+"/roles/"+$roleID), "userByEmail": ($sa+"@"+$proj+".iam.gserviceaccount.com")}]' dataset_policy.json.json > updated_policy.json && mv updated_policy.json dataset_policy.json.json`,
  },
  {
    header: "Update dataset IAM policy",

    body: `bq update --source dataset_policy.json.json $PROJ:${datasetName}`,
  },
  {
    header: "Verify dataset IAM policy update",
    body: `bq show --format=prettyjson $PROJ:${datasetName}`,
  },
  {
    header: "Bind the ServiceAccountTokenCreator role to the service account",
    body: `gcloud iam service-accounts add-iam-policy-binding doit-cmp-${serviceAccountIdentifier}@$PROJ.iam.gserviceaccount.com --project=$PROJ --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`,
  },
  {
    header: "Download the service account details",
    body: `gcloud iam service-accounts describe doit-cmp-${serviceAccountIdentifier}@$PROJ.iam.gserviceaccount.com --project=$PROJ --format="json" > doit_cmp_dataset_sa_details.json`,
  },
  {
    header: "Upload the 'doit_cmp_dataset_sa_details.json' file created in the previous step",
  },
];

export type CommandGenerator = (
  permissions: string[],
  outOfScopePermissions: string[],
  datasetName?: string,
  serviceAccountIdentifier?: string
) => Command[];

export const getOrgLevelCommands: CommandGenerator = (permissionList) => [
  {
    header: "Create service account",
    body: `gcloud iam service-accounts create ${serviceAccountName} --display-name "DoiT Console" --description "DoiT International Console"`,
  },
  {
    header: "Enable service usage and resource manager APIs",
    body: "gcloud services enable serviceusage.googleapis.com cloudresourcemanager.googleapis.com",
  },
  {
    header: "Discover your Google IAM organization ID",
    body: "ORG=$(gcloud organizations list --format 'value(ID)')",
  },
  {
    header: "Create custom role",
    body: `gcloud iam roles create ${roleID} --organization $ORG --title "DoiT Console Service Account" --stage GA --permissions ${permissionList.join(",")}`,
  },
  {
    header: "Bind the role to the service account under your organization",
    body: `gcloud organizations add-iam-policy-binding $ORG --member=serviceAccount:${serviceAccountName}@$(gcloud config get-value project).iam.gserviceaccount.com --role=organizations/$ORG/roles/${roleID}`,
  },
  {
    header: "Bind ServiceAccountTokenCreator role to the service account under DoiT organization",
    body: `gcloud iam service-accounts add-iam-policy-binding ${serviceAccountName}@$(gcloud config get-value project).iam.gserviceaccount.com --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`,
  },
  {
    header: "Download the service account details",
    body: `gcloud iam service-accounts describe ${serviceAccountName}@$(gcloud config get-value project).iam.gserviceaccount.com --format="json" > doit_cmp_sa_details.json`,
  },
  {
    header: "Upload the 'doit_cmp_sa_details.json' file created in the previous step",
  },
];

export const getProjectLevelCommands = (permissionList: string[]) => [
  {
    header: "Discover your Google IAM Project ID",
    body: "PROJ=<YOUR_PROJECT_ID>",
  },
  {
    header: "Create service account",
    body: `gcloud iam service-accounts create ${serviceAccountName} --project=$PROJ --display-name "DoiT Console" --description "DoiT International Console"`,
  },
  {
    header: "Enable service usage and resource manager APIs",
    body: "gcloud services enable serviceusage.googleapis.com cloudresourcemanager.googleapis.com --project=$PROJ",
  },
  {
    header: "Create custom role",
    body: `gcloud iam roles create ${roleID} --project=$PROJ --title "DoiT Console Service Account" --stage GA --permissions ${permissionList.join(",")}`,
  },
  {
    header: "Bind the role to the service account under your project",
    body: `gcloud projects add-iam-policy-binding $PROJ --member=serviceAccount:${serviceAccountName}@$PROJ.iam.gserviceaccount.com --role=projects/$PROJ/roles/${roleID}`,
  },
  {
    header: "Bind the ServiceAccountTokenCreator role to the service account",
    body: `gcloud iam service-accounts add-iam-policy-binding ${serviceAccountName}@$PROJ.iam.gserviceaccount.com --project=$PROJ --member=serviceAccount:${doitConnectSA} --role=roles/iam.serviceAccountTokenCreator`,
  },
  {
    header: "Download the service account details",
    body: `gcloud iam service-accounts describe ${serviceAccountName}@$PROJ.iam.gserviceaccount.com --project=$PROJ --format="json" > doit_cmp_sa_details.json`,
  },
  {
    header: "Upload the 'doit_cmp_sa_details.json' file created in the previous step",
  },
];

export const commandGenerators: CommandGenerators = {
  [AccessLevel.PROJECT]: getProjectLevelCommands,
  [AccessLevel.ORGANIZATION]: getOrgLevelCommands,
  [AccessLevel.DATASET]: (permissions, outOfScopePermissions, datasetName, serviceAccountIdentifier) =>
    getDatasetLevelCommands(
      permissions,
      outOfScopePermissions || [],
      datasetName || "",
      serviceAccountIdentifier || ""
    ),
};
