import { useState } from "react";

import { useParams } from "react-router-dom";
import { CloudFlowNodeType, type NodeConfigApiParametersBase } from "@doitintl/cmp-models";
import { useMutation } from "@tanstack/react-query";

import { useApiContext } from "../../../../api/context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../../../utils";
import { testNode } from "../../api";
import { useTrackCloudflowEvent } from "../../hooks";
import { type TestNodeResponse } from "../../types";
import { useNodeConfigurationContext } from "./NodeConfigurationContext";

export type TestInput = Pick<NodeConfigApiParametersBase, "formValues" | "configurationValues">;

export type TestNodeState = TestNodeResponse | null;

export const useTestNode = () => {
  const { flowId } = useParams<{ customerId: string; flowId: string }>();
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const track = useTrackCloudflowEvent();

  const [state, setState] = useState<TestNodeState>(null);

  const { mutate, isLoading } = useMutation({
    mutationFn: async (testInput: TestInput) => {
      const res = await testNode(api, customer.id, {
        cloudflowId: flowId,
        nodeId: nodeConfig.id,
        input: {
          params: {
            ...testInput,
            provider: nodeConfig.parameters.provider,
            operation: nodeConfig.parameters.operation,
          },
        },
      });

      return res.data;
    },
    onSuccess: (response: TestNodeResponse) => {
      setState(response);

      const { id, service } = nodeConfig.parameters.operation;

      track("Node Tested", {
        flowId,
        apiName: `${service}.${id}`,
        apiProvider: nodeConfig.parameters.provider,
        nodeType: CloudFlowNodeType.ACTION,
        testSuccessful: true,
      });
    },
    onError: (e) => {
      consoleErrorWithSentry(e);

      setState(null);

      track("Node Tested", {
        flowId,
        apiName: nodeConfig.parameters.operation.id,
        apiProvider: nodeConfig.parameters.provider,
        nodeType: CloudFlowNodeType.ACTION,
        testSuccessful: false,
      });
    },
  });

  return { state, mutate, isLoading };
};
