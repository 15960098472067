import { InsightModel } from "@doitintl/cmp-models/src";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore/src";

import { useCustomerContext } from "../../../../../Context/CustomerContext";

export const useRelevantTrustedAdvisorCustomerAccounts = () => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();

  const [trustedAdvisorCustomerDoc, loading, error] = useDocumentDataOnce(
    getCollection(InsightModel)
      .doc("trusted-advisor")
      .collection("relevantCustomersAccounts")
      .doc(customerOrPresentationModeCustomer.id)
  );

  if (loading) {
    return undefined;
  }

  if (error) {
    return null;
  }

  return trustedAdvisorCustomerDoc?.accounts;
};
