import { useCallback, useEffect, useState } from "react";

import { type MessageAva } from "./types";

export const useScroll = (stackRef: React.RefObject<HTMLDivElement>, messageAvaInProgress: MessageAva) => {
  const [keepScrollingToBottom, setKeepScrollingToBottom] = useState(true);

  const handleScroll = useCallback(() => {
    if (stackRef.current) {
      const { scrollHeight, scrollTop, clientHeight } = stackRef.current;
      setKeepScrollingToBottom(scrollHeight - scrollTop <= clientHeight + 2);
    }
  }, [stackRef]);

  useEffect(() => {
    if (keepScrollingToBottom) {
      if (!stackRef.current) {
        return;
      }

      stackRef.current.scrollTop = stackRef.current.scrollHeight;
    }
  }, [messageAvaInProgress, keepScrollingToBottom, stackRef]);

  return { handleScroll };
};
