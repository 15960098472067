import React from "react";

import { Box, Stack, Typography } from "@mui/material";

import { CloudProvidersIcons } from "../Preview/CloudProvidersIcons";
import { type CloudProviderTag } from "../types";
import { Insight } from "./Insight";
import { type DisplayInsight } from "./types";

type Props = {
  serviceTitle: string;
  cloudInsights: DisplayInsight[];
  cloudTags: CloudProviderTag[];
  responsiveConfig: {
    isMobile: boolean;
    below1244: boolean;
  };
};

export const InsightsUpsellList: React.FC<Props> = ({ serviceTitle, cloudTags, cloudInsights, responsiveConfig }) => (
  <Box sx={{ mb: 4, px: responsiveConfig.isMobile ? 0 : 9.25 }}>
    <Stack direction="row" mb={4}>
      <CloudProvidersIcons cloudTags={cloudTags} style={{ height: 32, padding: "5px 0" }} />
      <Typography
        ml={1.5}
        variant="h4"
        fontWeight={500}
        color="text.primary"
        fontSize={18}
        lineHeight={responsiveConfig.below1244 ? "26px" : "32px"}
      >
        {serviceTitle}
      </Typography>
    </Stack>
    <Box
      sx={{
        display: responsiveConfig.below1244 ? "flex" : "grid",
        flexDirection: responsiveConfig.below1244 ? "column" : "none",
        gridTemplateColumns: "2fr 3fr 2fr 1fr",
      }}
    >
      <Insight service="Service" title="Insight" potentialSavings="Potential savings" headerRow />

      {cloudInsights?.map((service) => (
        <Insight key={service.title} {...service} easyWinDescription={service.easyWinDescription} />
      ))}
    </Box>
  </Box>
);
