import { Stack, Typography } from "@mui/material";

export const SubscriptionPendingAWS = () => (
  <Stack
    spacing={1}
    sx={{
      alignItems: "center",
      pt: 16,
    }}
  >
    <Typography variant="h1">Onboarding in process</Typography>
    <Typography variant="subtitle1">Please wait and do not refresh the page</Typography>
  </Stack>
);
