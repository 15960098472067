import { createContext, type ReactNode, useCallback, useContext, useEffect, useMemo, useState } from "react";

import {
  type EntitlementKey,
  type EntitlementLimitType,
  EntitlementModel,
  type EntitlementsAccess,
  type NavigatorSKUs,
  type SolveSKUs,
  TierModel,
  type TierPackageType,
  TierTypes,
} from "@doitintl/cmp-models";
import {
  type CollectionDataHook,
  type ModelId,
  type ModelIdRef,
  type ModelRef,
  type ModelReference,
} from "@doitintl/models-firestore";
import { useCacheQueryData } from "@doitintl/models-firestore";
import flatten from "lodash/flatten";
import { DateTime, Interval } from "luxon";

import { defaultCurrencySymbol } from "../constants/common";
import { type Customer } from "../types";
import { getCustomerTierTrialDates } from "../utils/tiers";
import { useAuthContext } from "./AuthContext";

export type TierWithRef = ModelRef<TierModel>;

export type EntitlementWithId = ModelId<EntitlementModel>;

export const useTiers = () => useCacheQueryData("tiers", { idField: "id", refField: "ref", model: TierModel });

export const useEntitlements = (tierPackageType?: TierPackageType) => {
  const [data, loading, error] = useCacheQueryData("entitlements", {
    idField: "id",
    model: EntitlementModel,
  });

  const filterData = useMemo(() => {
    if (!data) {
      return;
    }

    if (!tierPackageType) {
      return data;
    }

    return data.filter((entitlement) => entitlement.type === tierPackageType);
  }, [data, tierPackageType]);

  return [filterData, loading, error] as CollectionDataHook<ModelId<EntitlementModel>>;
};

export type TierConfig = {
  tiers: TierWithRef[] | undefined;
  // @deprecated use isFeatureEntitledWithUndefined instead
  isFeatureEntitled: (entitlementName: EntitlementKey, isFeatureGateDisabledForDoer?: boolean) => boolean;
  isFeatureEntitledWithUndefined: (
    entitlementName: EntitlementKey,
    isFeatureGateDisabledForDoer?: boolean
  ) => boolean | undefined;
  isFeatureEntitledMultiple: (
    entitlements: EntitlementKey[],
    isFeatureGateDisabledForDoer?: boolean
  ) => EntitlementsAccess;
  getFeatureLimit: (
    entitlementName: EntitlementKey,
    isFeatureLimitDisabledForDoer?: boolean
  ) => EntitlementLimitType | null;
  getTierPrice: (tierType: TierPackageType) => number | null;
  getFeatureName: (entitlementName: EntitlementKey) => string | null;
  getFeatureKey: (entitlementID: string) => string | null;
  getFeatureId: (entitlementName: EntitlementKey) => string | undefined;
  getCustomerTier: (tierType: TierPackageType) => TierWithRef | undefined;
  getTrialTierRef: (tierType: TierPackageType) => ModelReference<TierModel> | undefined;
  getInternalTiers: (tierType: TierPackageType) => Record<string, ModelReference<TierModel>> | undefined;
  getTierFeature: (sku: NavigatorSKUs | SolveSKUs, entitlementKey: EntitlementKey) => EntitlementModel | undefined;
  isActiveTrialCustomer: (tierType: TierPackageType) => boolean;
  loading: boolean;
  allTiers: TierWithRef[] | undefined;
};

const tierContext = createContext<TierConfig | undefined>(undefined);

export const useTier = () => {
  const context = useContext(tierContext);

  if (!context) {
    throw new Error("useTier must be used within a TierProvider");
  }

  return context;
};

export const TierProvider = ({
  children,
  customer,
}: {
  children?: ReactNode;
  customer: Customer | undefined | null;
}) => {
  const { isDoitEmployee, isDoitOwner } = useAuthContext();
  const [allTiers, allTiersLoading, allTiersError] = useTiers();
  const [allEntitlementsList, isEntitlementsLoading] = useEntitlements();

  const [customerEntitlementsIds, setCustomerEntitlementsIds] = useState<Set<string>>();
  const [customerEntitlementsKeyToId, setCustomerEntitlementsKeyToId] = useState<Map<EntitlementKey, string>>();
  const [allEntitlementsById, setAllEntitlementsById] = useState<Record<string, EntitlementWithId>>();
  const [processingCustomerEntitlementsToId, setProcessingCustomerEntitlementsToId] = useState<boolean>(true);

  const [trialTiers, isTrialTiersLoading] = useMemo((): CollectionDataHook<ModelIdRef<TierModel>> => {
    if (allTiersError) {
      return [undefined, false, allTiersError];
    }

    if (allTiersLoading) {
      return [undefined, true, undefined];
    }

    return [allTiers?.filter((tier) => tier.trialTier) ?? [], false, undefined];
  }, [allTiers, allTiersError, allTiersLoading]);

  const [internalTiers, isInternalTiersLoading] = useMemo((): CollectionDataHook<ModelIdRef<TierModel>> => {
    if (allTiersError) {
      return [undefined, false, allTiersError];
    }

    if (allTiersLoading) {
      return [undefined, true, undefined];
    }

    return [allTiers?.filter((tier) => tier.type === TierTypes.INTERNAL) ?? [], false, undefined];
  }, [allTiers, allTiersError, allTiersLoading]);

  const [tiers, isCustomerTierLoading] = useMemo((): CollectionDataHook<ModelIdRef<TierModel>> => {
    if (allTiersError) {
      return [undefined, false, allTiersError];
    }

    if (allTiersLoading || !customer?.id || !allTiers) {
      return [undefined, true, undefined];
    }

    if (!customer?.tiers) {
      return [[], false, undefined];
    }

    const tiersIds = Object.values(customer.tiers)
      .map((t) => t.tier?.id)
      .filter((t) => !!t);

    const tiersIdsUnique = new Set(tiersIds);
    return [allTiers.filter((tier) => tiersIdsUnique.has(tier.id)), false, undefined];
  }, [allTiers, allTiersError, allTiersLoading, customer?.id, customer?.tiers]);

  useEffect(() => {
    if (!tiers) {
      return;
    }

    setCustomerEntitlementsIds(new Set(flatten(tiers.map((tier) => tier.entitlements) || []).map((e) => e.id)));
  }, [tiers]);

  useEffect(() => {
    const byId = allEntitlementsList?.reduce(
      (acc, curr) => {
        acc[curr.id] = curr;

        return acc;
      },
      {} as Record<string, EntitlementWithId>
    );

    setAllEntitlementsById(byId);
  }, [allEntitlementsList]);

  useEffect(() => {
    if (!customerEntitlementsIds || !allEntitlementsById) {
      return;
    }

    const keys: Map<EntitlementKey, string> = new Map();
    for (const id of customerEntitlementsIds) {
      if (allEntitlementsById[id]) {
        keys.set(allEntitlementsById[id].key, id);
      }
    }
    setCustomerEntitlementsKeyToId(keys);
    setProcessingCustomerEntitlementsToId(false);
  }, [allEntitlementsById, customerEntitlementsIds]);

  const isFeatureEntitled = useCallback(
    (featureKey: EntitlementKey, isFeatureGateDisabledForDoer = false) => {
      // Bypass doer for some routes
      if (isFeatureGateDisabledForDoer && (isDoitEmployee || isDoitOwner)) {
        return true;
      }

      if (!customerEntitlementsKeyToId) {
        return false;
      }

      return customerEntitlementsKeyToId.has(featureKey);
    },
    [customerEntitlementsKeyToId, isDoitEmployee, isDoitOwner]
  );

  const isFeatureEntitledWithUndefined = useCallback(
    (featureKey: EntitlementKey, isFeatureGateDisabledForDoer = false) => {
      if (isFeatureGateDisabledForDoer === undefined) {
        return;
      }

      // Bypass doer for some routes
      if (isFeatureGateDisabledForDoer && (isDoitEmployee || isDoitOwner)) {
        return true;
      }

      if (!customerEntitlementsKeyToId) {
        return false;
      }

      return customerEntitlementsKeyToId.has(featureKey);
    },
    [customerEntitlementsKeyToId, isDoitEmployee, isDoitOwner]
  );

  const isFeatureEntitledMultiple = useCallback(
    (featureKeys: EntitlementKey[], isFeatureGateDisabledForDoer?: boolean): EntitlementsAccess =>
      featureKeys.reduce((acc, key) => ({ ...acc, [key]: isFeatureEntitled(key, isFeatureGateDisabledForDoer) }), {}),
    [isFeatureEntitled]
  );

  const getFeatureLimit = useCallback(
    (featureKey: EntitlementKey, isFeatureLimitDisabledForDoer?: boolean) => {
      if (!allEntitlementsById || !customerEntitlementsKeyToId) {
        return null;
      }

      // Do not limit features for Doers
      if (isFeatureLimitDisabledForDoer && (isDoitEmployee || isDoitOwner)) {
        return null;
      }

      const entitlementId = customerEntitlementsKeyToId.get(featureKey);
      if (!customerEntitlementsKeyToId.has(featureKey) || !entitlementId) {
        return null;
      }

      return allEntitlementsById[entitlementId]?.limit || null;
    },
    [allEntitlementsById, customerEntitlementsKeyToId, isDoitEmployee, isDoitOwner]
  );

  const getTierPrice = useCallback(
    (tierType: TierPackageType): number | null => {
      if (!tiers) {
        return null;
      }

      const customerCurrency = customer?.settings?.currency || defaultCurrencySymbol;
      return tiers.find((tier) => tier.packageType === tierType)?.price[customerCurrency] || null;
    },
    [customer?.settings?.currency, tiers]
  );

  const getCustomerTier = useCallback(
    (tierType: TierPackageType): TierWithRef | undefined => tiers?.find((tier) => tier.packageType === tierType),
    [tiers]
  );

  const getTrialTierRef = useCallback(
    (tierType: TierPackageType): ModelReference<TierModel> | undefined => {
      if (!trialTiers) {
        return undefined;
      }

      return trialTiers.find((tier) => tier.packageType === tierType)?.ref;
    },
    [trialTiers]
  );

  const getInternalTiers = useCallback(
    (tierType: TierPackageType): Record<string, ModelReference<TierModel>> | undefined => {
      if (!internalTiers) {
        return undefined;
      }

      return Object.fromEntries(
        internalTiers.filter((tier) => tier.packageType === tierType).map((tier) => [tier.name, tier.ref])
      );
    },
    [internalTiers]
  );

  const getFeatureName = useCallback(
    (featureKey: EntitlementKey): string | null => {
      if (!allEntitlementsById) {
        return null;
      }

      const entitlement = Object.values(allEntitlementsById).find(
        (entitlement: EntitlementWithId) => entitlement.key === featureKey
      );
      if (!entitlement) {
        return null;
      }

      return entitlement.displayName || null;
    },
    [allEntitlementsById]
  );

  const getFeatureKey = useCallback(
    (entitlementID: string): string | null => {
      if (!allEntitlementsById) {
        return null;
      }

      const entitlement = allEntitlementsById[entitlementID];
      if (!entitlement) {
        return null;
      }

      return entitlement.key || null;
    },
    [allEntitlementsById]
  );

  const getFeatureId = useCallback(
    (entitlementName: EntitlementKey) => customerEntitlementsKeyToId?.get(entitlementName),
    [customerEntitlementsKeyToId]
  );

  const isActiveTrialCustomer = useCallback(
    (tierType: TierPackageType): boolean => {
      const tier = getCustomerTier(tierType);

      if (!tier?.trialTier) {
        return false;
      }

      const { start, end } = getCustomerTierTrialDates(customer, tierType);
      if (!start || !end) {
        return false;
      }

      return Interval.fromDateTimes(start, end).contains(DateTime.now());
    },
    [getCustomerTier, customer]
  );

  const getTierFeature = useCallback(
    (sku: NavigatorSKUs | SolveSKUs, entitlementKey: EntitlementKey) => {
      if (!allTiers || !allEntitlementsById) {
        return undefined;
      }
      const tier = allTiers.find((t) => t.sku === sku);

      if (!tier) {
        return undefined;
      }

      const tierEntitlements = tier.entitlements.map((entitlement) => allEntitlementsById[entitlement.id]);

      return tierEntitlements.find((tierEntitlement) => tierEntitlement?.key === entitlementKey) ?? undefined;
    },
    [allEntitlementsById, allTiers]
  );

  const tierProviderData = useMemo(
    () => ({
      tiers,
      isFeatureEntitled,
      isFeatureEntitledWithUndefined,
      isFeatureEntitledMultiple,
      getFeatureLimit,
      loading:
        isCustomerTierLoading ||
        isEntitlementsLoading ||
        isTrialTiersLoading ||
        isInternalTiersLoading ||
        processingCustomerEntitlementsToId,
      getTierPrice,
      getCustomerTier,
      getTrialTierRef,
      getInternalTiers,
      getFeatureName,
      getFeatureKey,
      getFeatureId,
      getTierFeature,
      isActiveTrialCustomer,
      allTiers,
    }),
    [
      getFeatureLimit,
      getTierPrice,
      isFeatureEntitled,
      isFeatureEntitledWithUndefined,
      isFeatureEntitledMultiple,
      isCustomerTierLoading,
      isEntitlementsLoading,
      isTrialTiersLoading,
      isInternalTiersLoading,
      tiers,
      getCustomerTier,
      getTrialTierRef,
      getInternalTiers,
      getFeatureName,
      getFeatureKey,
      getFeatureId,
      getTierFeature,
      isActiveTrialCustomer,
      allTiers,
      processingCustomerEntitlementsToId,
    ]
  );

  return <tierContext.Provider value={tierProviderData}>{children}</tierContext.Provider>;
};

export const TierProviderForTesting = ({
  children,
  value,
}: {
  children?: ReactNode;
  value?: Partial<Omit<TierConfig, "isFeatureEntitled">>;
}) => {
  const actualValue = useMemo(
    (): Partial<TierConfig> => ({
      isFeatureEntitled: (entitlementName: EntitlementKey, isFeatureGateDisabledForDoer?: boolean) => {
        if (value?.isFeatureEntitledWithUndefined) {
          return value.isFeatureEntitledWithUndefined(entitlementName, isFeatureGateDisabledForDoer) ?? false;
        }

        return false;
      },
      isFeatureEntitledWithUndefined: () => false,
      isFeatureEntitledMultiple: () => ({}),
      isActiveTrialCustomer: () => false,
      getCustomerTier: () => undefined,
      getTrialTierRef: () => undefined,
      getFeatureLimit: (_entitlementName: EntitlementKey, _isFeatureLimitDisabledForDoer?: boolean) => 3,
      ...value,
    }),
    [value]
  );

  return <tierContext.Provider value={actualValue as TierConfig}>{children}</tierContext.Provider>;
};

export const useIsFeatureEntitled = (
  entitlementName: EntitlementKey,
  isFeatureGateDisabledForDoer?: boolean
): boolean | undefined => {
  const { isFeatureEntitledWithUndefined } = useTier();

  return isFeatureEntitledWithUndefined(entitlementName, isFeatureGateDisabledForDoer);
};

export const useIsFeatureEntitledMultiple = (
  entitlements: EntitlementKey[],
  isFeatureGateDisabledForDoer?: boolean
) => {
  const { isFeatureEntitledMultiple } = useTier();

  return useMemo(
    () => isFeatureEntitledMultiple(entitlements, isFeatureGateDisabledForDoer) ?? {},
    [entitlements, isFeatureGateDisabledForDoer, isFeatureEntitledMultiple]
  );
};
