import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Button } from "@mui/material";

import { onboardingTxt } from "../../../assets/texts/EKS/onboarding";

interface OpenAWSConsoleProps {
  region?: string;
}

export const OpenAWSConsole = ({ region }: OpenAWSConsoleProps) => {
  const baseUrl = "https://console.aws.amazon.com/console/home";
  const href = region ? `${baseUrl}?region=${region}` : baseUrl;

  return (
    <Button startIcon={<OpenNewIcon />} variant="outlined" sx={{ width: "fit-content" }} href={href} target="_blank">
      {onboardingTxt.OPEN_AWS_CONSOLE}
    </Button>
  );
};
