import { TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import {
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import useMountEffect from "../../../../../Components/hooks/useMountEffect";
import { useContractTypesContext } from "../../../../ContractsTabs/ContractsTypesContext";
import { useContractFormContext } from "../../ContractsFormContext";
import { isOneTimeServiceFee } from "../../utils";
import ContractCurrencySelector from "../components/ContractCurrencySelector";
import { DateSelectorComponent } from "./components/DateSelectorComponent";
import { OneTimeServiceTypeSelector } from "./components/OneTimeServiceTypeSelector";

export const OneTimeServiceFeeDetailsStep = () => {
  const { state, setState, handleChange } = useContractFormContext();
  const { getContractTypeLabel } = useContractTypesContext();

  // TODO: use the useTiers hooks and filter by packageType and name
  const [tier] = useCollectionDataOnce(
    getCollection(TierModel).where("packageType", "==", "solve").where("name", "==", "standard"),
    {
      idField: "id",
      refField: "ref",
    }
  );

  useMountEffect(() => {
    if (state.endDate === null) {
      setState({ ...state, endDate: state.startDate.plus({ months: 3 }) });
    }
  });

  if (!isOneTimeServiceFee(state)) {
    return null;
  }

  return (
    <Container maxWidth="sm" data-cy="one-time-service-fee-details-step">
      <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
        {getContractTypeLabel(state.type)} description
      </Typography>
      <Grid size={12}>
        <OneTimeServiceTypeSelector
          data-cy="one-time-service-type-selector"
          value={state.typeContext}
          onChange={handleChange("typeContext")}
          error={!!state.errors.typeContext}
        />
      </Grid>
      <Grid size={12}>
        <DateSelectorComponent
          value={state.startDate}
          onChange={(date) => {
            if (date) {
              setState({ ...state, startDate: date });
            }
          }}
          label="Start Date"
          error={!!state.errors.startDate}
          disabled={state.isEditForbidden}
          helperText="Start Date should be when the Accelerator hits the CRE queue"
        />
      </Grid>
      <Grid size={12}>
        <DateSelectorComponent
          value={state.endDate ?? state.startDate}
          onChange={(date) => {
            if (date) {
              setState({ ...state, endDate: date });
            }
          }}
          label="End Date"
          error={!!state.errors.endDate}
          disabled={state.isEditForbidden}
          minDate={state.startDate}
          helperText="End Date is standard 90 days, but should be edited later when Accelerator is applied to the customer"
        />
      </Grid>
      <Grid
        sx={{
          display: "flex",
        }}
      >
        <ContractCurrencySelector
          value={state.currency}
          disabled={state.isEditForbidden}
          onChange={handleChange("currency")}
          options={tier ? Object.keys(tier[0].price) : []}
        />
        <TextField
          data-cy="one-time-service-fee-charge"
          label="Charge"
          margin="normal"
          value={state.chargePerTerm}
          onChange={handleChange("chargePerTerm")}
          type="number"
          fullWidth
          required
          error={!!state.errors.chargePerTerm}
        />
      </Grid>
      <Grid size={12}>
        <FormControl size="small" fullWidth margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                checked={state.showEstimatedFunding ?? false}
                onChange={handleChange("showEstimatedFunding")}
                data-cy="show-estimated-funding-checkbox"
              />
            }
            label={
              <>
                <Typography variant="body2">Anticipated Partner Funding</Typography>
                <Typography variant="caption" color="textSecondary">
                  Check this if the SOW details partner funding
                </Typography>
              </>
            }
          />
        </FormControl>
        {state.showEstimatedFunding && (
          <TextField
            label="Anticipated funding amount"
            fullWidth
            margin="normal"
            required
            type="number"
            value={state.estimatedFunding}
            onChange={handleChange("estimatedFunding")}
            data-cy="estimated-funding-amount"
            error={!!state.errors.estimatedFunding}
            slotProps={{
              input: {
                startAdornment: <InputAdornment position="start">{state.currency}</InputAdornment>,
              },
            }}
          />
        )}
      </Grid>
    </Container>
  );
};
