import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { useCustomerId } from "@client/src/Components/hooks/useCustomerId";
import { customersRoute } from "@client/src/Navigation/core/utils";
import { getStepStatus } from "@client/src/Pages/Customer/SaaSConsoleOnboarding/Azure/utils";
import { getAzureFeatures } from "@client/src/Pages/Settings/Azure/db";
import ConnectResourcesStep from "@client/src/Pages/Settings/Azure/Stepper/ConnectResourcesStep";
import { CreateServicePrincipalStep } from "@client/src/Pages/Settings/Azure/Stepper/CreateServicePrincipalStep";
import { SelectFeaturesStep } from "@client/src/Pages/Settings/Azure/Stepper/SelectFeaturesStep";
import { consoleErrorWithSentry } from "@client/src/utils";
import { Alert, Snackbar } from "@mui/material";
import { type AzureFeaturePermission } from "@services/cmp/models";
import type { AzureRole, RoleRequest } from "@client/src/Pages/Settings/Azure/types";
import type { AlertColor, AlertPropsColorOverrides } from "@mui/material/Alert/Alert";
import type { OverridableStringUnion } from "@mui/types";

import { Stepper } from "../../../../Components/Stepper";

export const LinkAccountStepper = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [submitClicked, setSubmitClicked] = useState(0);
  const customerId = useCustomerId();
  const history = useHistory();
  const handleCancel = () => {
    history.push(`${customersRoute(customerId)}/settings/azure`);
  };

  const [tenantId, setTenantId] = useState("");
  const [selectedFeatures, setSelectedFeatures] = useState<string[]>([]);
  const [roles, setRoles] = useState<AzureRole[]>([]);
  const [roleRequestData, setRoleRequestData] = useState<RoleRequest | undefined>(undefined);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarTitle, setSnackbarTitle] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<OverridableStringUnion<AlertColor, AlertPropsColorOverrides>>("success");

  const showSnackbar = (severity: OverridableStringUnion<AlertColor, AlertPropsColorOverrides>, title: string) => {
    setSnackbarSeverity(severity);
    setSnackbarTitle(title);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (_event: React.SyntheticEvent<Element, Event> | Event, reason?: string): void => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarTitle("");
    setOpenSnackbar(false);
  };

  const [azureFeatures, setAzureFeatures] = useState<AzureFeaturePermission[]>([]);

  useEffect(() => {
    getAzureFeatures()
      .then((data) => {
        setAzureFeatures(data || []);
      })
      .catch((err) => {
        showSnackbar("error", "error reading applications");
        consoleErrorWithSentry(err);
      });
  }, []);

  const handleSubmit = () => {
    setSubmitClicked(submitClicked + 1);
  };

  return (
    <>
      <Stepper
        getCurrentStep={setCurrentStep}
        disableSubmit={submitDisabled}
        loading={false}
        submitButtonLabel="Done"
        onCancel={handleCancel}
        onSubmit={handleSubmit}
        contentSx={{
          mt: 3,
          mb: 3,
        }}
        footerMaxWidth={860}
        maxWidth={1000}
        steps={[
          {
            children: (
              <SelectFeaturesStep
                azureFeatures={azureFeatures}
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeatures}
              />
            ),
            label: "Select features",
            order: 0,
            required: true,
            state: getStepStatus(0, currentStep),
          },
          {
            children: <CreateServicePrincipalStep tenantId={tenantId} setTenantId={setTenantId} />,
            label: "Connect",
            order: 1,
            required: true,
            state: getStepStatus(1, currentStep),
          },
          {
            children: (
              <ConnectResourcesStep
                tenantID={tenantId}
                submitClicked={submitClicked}
                setSubmitDisabled={setSubmitDisabled}
                azureFeatures={azureFeatures}
                selectedFeaturesNames={selectedFeatures}
                roles={roles}
                setRoles={setRoles}
                roleRequestData={roleRequestData}
                setRoleRequestData={setRoleRequestData}
              />
            ),
            label: "Grant access",
            order: 2,
            required: true,
            state: getStepStatus(2, currentStep),
          },
        ]}
      />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: "100%" }}>
          {snackbarTitle}
        </Alert>
      </Snackbar>
    </>
  );
};
