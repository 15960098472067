import { createContext, type ReactNode, useCallback, useContext, useState } from "react";

import { type SupportServiceModel } from "@doitintl/cmp-models";
import noop from "lodash/noop";

import { useCaseIQByID } from "../../Support/Components/CaseIQ/utils";
import { useTrpcContext } from "./AvaTRPCContext";
import { filterAssetsByPlatform, useCategoryList, usePlatformList, useUserAssets } from "./hooks";
import { type AvaDialogContextType, type CaseIQState } from "./types";

type Props = {
  children: ReactNode;
};

const emptyState = {
  content: "",
  platform: "",
  cloudProduct: null,
  asset: "",
  urgency: "",
  technicalDetails: [],
};

export const AvaCaseIQContext = createContext<AvaDialogContextType>({
  caseIQConversationId: "",
  setCaseIQConversationId: noop,
  caseIQState: emptyState,
  handleCaseIQStateChange: noop,
  loading: true,
  platformList: [],
  categoryList: [],
  assetsList: [],
});

export const AvaCaseIQProvider = ({ children }: Props) => {
  const [caseIQConversationId, setCaseIQConversationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [caseIQState, setCaseIQState] = useState<CaseIQState>(emptyState);

  const { summarizeConversation } = useTrpcContext();
  const fetchCaseIQByID = useCaseIQByID();

  const platformList = usePlatformList();

  const categoryList = useCategoryList();

  const assetsList = useUserAssets();

  const handleCaseIQStateChange = useCallback((field: string, value: string | SupportServiceModel | null) => {
    setCaseIQState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  }, []);

  const handleCaseIQConversationIdChange = useCallback(
    async (caseIQConversationId?: string) => {
      if (!caseIQConversationId) {
        setCaseIQState(emptyState);
        setCaseIQConversationId("");
        return;
      }

      setCaseIQConversationId(caseIQConversationId);
      setLoading(true);
      try {
        const summarizePromise = summarizeConversation(caseIQConversationId).catch(() => null);
        const fetchCaseIQPromise = fetchCaseIQByID(caseIQConversationId);

        const [summaryResponse, fetchCaseIQResponse] = await Promise.all([summarizePromise, fetchCaseIQPromise]);

        if (!fetchCaseIQResponse) {
          setLoading(false);
          return;
        }
        const { platform, product, assetId, severity } = fetchCaseIQResponse.requiredFields;

        const cloudProduct = categoryList.find((category) => category.id === product) ?? null;

        const newAssets = filterAssetsByPlatform(assetsList, platform);

        const found = newAssets.find((asset) => asset.identifier === assetId);

        const caseIQState = {
          content: summaryResponse?.summary ?? "",
          platform,
          cloudProduct,
          asset: found ? (assetId ?? "") : "",
          urgency: severity.toLowerCase(),
          technicalDetails: fetchCaseIQResponse.technicalDetails,
        };

        setCaseIQState(caseIQState);
      } catch (error) {
        setCaseIQState(emptyState);
      }
      setLoading(false);
    },
    [fetchCaseIQByID, summarizeConversation, categoryList, assetsList]
  );

  return (
    <AvaCaseIQContext.Provider
      value={{
        caseIQConversationId,
        setCaseIQConversationId: handleCaseIQConversationIdChange,
        caseIQState,
        handleCaseIQStateChange,
        loading,
        platformList,
        categoryList,
        assetsList,
      }}
    >
      {children}
    </AvaCaseIQContext.Provider>
  );
};

export const useAvaCaseIQContext = () => useContext(AvaCaseIQContext);
