import { type MouseEvent, useState } from "react";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { cloudReportCardText } from "../../../assets/texts";

type Props = {
  widgetKey: string;
  handleReportCreate: (widgetKey: string) => Promise<void>;
};
export const AvaWidgetHeader = ({ widgetKey, handleReportCreate }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const reportCreateWrapperFunc = () => {
    handleReportCreate(widgetKey);
  };
  return (
    <>
      <IconButton
        id="widget-menu-button"
        aria-controls="basic-menu"
        aria-expanded={open ? "true" : undefined}
        onClick={(e) => {
          handleOpenMenu(e);
          e.stopPropagation();
        }}
        aria-haspopup="true"
        size="large"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="widget-menu"
        anchorEl={anchorEl}
        onClick={(e) => {
          e.stopPropagation();
        }}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        slotProps={{
          root: {
            style: {
              zIndex: 1201,
            },
          },
        }}
      >
        <MenuItem onClick={reportCreateWrapperFunc}>{cloudReportCardText.OPEN_REPORT}</MenuItem>
      </Menu>
    </>
  );
};
