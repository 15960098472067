import { type ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";

import { AutoSizer, List as VirtualizedList } from "react-virtualized";
import { Metadata, type MetadataCloud } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/AddRounded";
import ClearIcon from "@mui/icons-material/ClearRounded";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { type AlertColor } from "@mui/material/Alert";
import ListItemButton from "@mui/material/ListItemButton";
import { makeStyles } from "@mui/styles";
import debounce from "lodash/debounce";
import difference from "lodash/difference";
import intersection from "lodash/intersection";
import sortBy from "lodash/sortBy";
import union from "lodash/union";
import without from "lodash/without";

import { useApiContext } from "../../../api/context";
import AmazonWebServicesIcon from "../../../assets/aws-logo.svg";
import AmazonWebServicesIconDarkMode from "../../../assets/aws-logo-dark-mode.svg";
import MicrosoftAzureIcon from "../../../assets/azure-logo.svg";
import DatadogIcon from "../../../assets/datadog-logo.png";
import DatadogIconDarkMode from "../../../assets/datadog-logo-white.png";
import DoitLogo from "../../../assets/doit-logo-hero-square.svg";
import GoogleCloudIcon from "../../../assets/google-cloud.png";
import SnowflakeIcon from "../../../assets/snowflake.svg";
import { alertTexts, globalText } from "../../../assets/texts";
import { filterDialogText } from "../../../assets/texts/CloudAnalytics";
import { globalURLs } from "../../../assets/urls";
import {
  createFilterOptions,
  FixedFilters,
  isAttributionDimension,
  Positions,
} from "../../../Pages/CloudAnalytics/utilities";
import { dimensionProvidersValues } from "../../../Pages/CloudAnalytics/utils/cloudProviders";
import { type MetadataOption } from "../../../types";
import { useFullScreen } from "../../../utils/dialog";
import { useCloudAnalyticsTransforms } from "../../hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { useDarkThemeCheck } from "../../hooks/useDarkThemeCheck";
import TooltipWithMaxWidth from "../../Tooltip/TooltipWithMaxWidth";

const LIST_ITEM_HEIGHT = 34;
const INCREASED_LIST_ITEM_HEIGHT = LIST_ITEM_HEIGHT + 12;
const REGULAR_DIALOG_HEIGHT = 510;
const INCREASED_DIALOG_HEIGHT = 586;

const selectAllId = "select-all";

type FilterProps = {
  selected: MetadataOption;
  onSaveFilters: (filtersOrRegex: string[] | string) => void;
  touched?: Set<string>;
};

type SimpleFilterProps = FilterProps & {
  handleCancelFilter?: (selected: MetadataOption[]) => void;
  isOptionDisabled?: (option: string) => boolean;
  cloudProviders?: MetadataCloud[] | null;
  inverse: boolean;
  numOfListItems: number;
};

const useStyles = makeStyles((theme) => ({
  listItem: {
    height: LIST_ITEM_HEIGHT,
    maxHeight: LIST_ITEM_HEIGHT,
    outline: "none",
    "&:focus": {
      outline: "none",
    },
  },
  listItemIcon: {
    minWidth: theme.spacing(3),
  },
  spacer: {
    flexGrow: 1,
  },
  invertCheckbox: {
    marginLeft: theme.spacing(0),
  },
  alert: {
    marginBottom: theme.spacing(2),
  },
}));

const getPlural = (selected: MetadataOption) => {
  const label = selected.data.label ?? selected.data.field;
  const plural = selected.data.plural ?? label;
  return { plural, label };
};

const useAdvancedFilterDialog = ({ selected, onSaveFilters, touched }: FilterProps) => {
  const api = useApiContext();
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState(selected._regexp ?? "");
  const [validatingRegex, setValidatingRegex] = useState(false);
  const inputRef = useRef<HTMLInputElement>();

  useEffect(() => {
    setError(false);
    setInputValue(selected._regexp ?? "");
  }, [selected]);

  const { plural } = getPlural(selected);

  const validateInputForRE2 = useCallback(
    async (inputValue: string) => {
      try {
        await api.request({
          method: "post",
          url: "/v1/regexp",
          data: {
            regexp: inputValue,
          },
        });
        setError(false);
        return true;
      } catch (err: any) {
        setError(true);
        return false;
      } finally {
        setValidatingRegex(false);
      }
    },
    [api]
  );

  const debounceFn = debounce(validateInputForRE2, 1000);

  const saveFilterChanges = useCallback(async () => {
    const validRE2 = await validateInputForRE2(inputValue);
    if (validRE2) {
      onSaveFilters(inputValue);
    }
  }, [onSaveFilters, inputValue, validateInputForRE2]);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setInputValue(event.target.value);
    setValidatingRegex(true);
    touched?.add("regexp");
    await debounceFn(event.target.value);
  };

  const disabled = useMemo(
    () => validatingRegex || error || !inputValue || touched?.size === 0,
    [validatingRegex, error, inputValue, touched?.size]
  );

  const dialog = (
    <DialogContent data-testid="AdvancedFilterContent">
      <TextField
        inputRef={inputRef}
        label={filterDialogText.REGEX_LABEL}
        helperText={error ? filterDialogText.REGEX_WRONG_SYNTAX : filterDialogText.FILTER_USING_REGEX(plural)}
        margin="normal"
        placeholder=".*"
        variant="outlined"
        value={inputValue}
        error={error}
        onChange={handleChange}
        fullWidth
        autoFocus
        slotProps={{
          inputLabel: {
            shrink: true,
          },
        }}
      />

      <Alert variant="standard" severity="info" sx={{ whiteSpace: "pre-line" }}>
        {filterDialogText.REGEX_ALERT_PART_1}
        <Link
          target="_blank"
          rel="noopener noreferrer"
          href={globalURLs.REGEXP_HELP}
          color="inherit"
          underline="always"
        >
          {filterDialogText.RE2}
        </Link>
        {filterDialogText.REGEX_ALERT_PART_2}
        <strong>{filterDialogText.REGEX_ALERT_EXAMPLE}</strong> {filterDialogText.REGEX_ALERT_EXAMPLE_TEXT}
      </Alert>
    </DialogContent>
  );

  return { dialog, disabled, saveFilterChanges };
};

const useSimpleFilterDialog = ({
  selected,
  onSaveFilters,
  handleCancelFilter,
  isOptionDisabled,
  cloudProviders,
  inverse,
  numOfListItems,
}: SimpleFilterProps) => {
  const { fullScreen } = useFullScreen();
  const filterAlerts = useMemo(
    () => ({
      [`${Metadata.FIXED}:project_ancestry_names`]: (md) => {
        const t = (md?.data?.values?.slice(1) ?? []).findIndex((v) => /[a-zA-Z]/.test(v));
        if (t === -1) {
          return {
            severity: "info",
            action: (
              <Button
                color="inherit"
                size="small"
                component={Link}
                href="https://help.doit.com/google-cloud/connect-google-cloud-service-account"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Learn more"
                startIcon={<OpenNewIcon />}
                style={{ whiteSpace: "nowrap" }}
              >
                {alertTexts.LEARN_MORE}
              </Button>
            ),
            message: <>Connect your Google Cloud organization to show folder names instead of folder numbers</>,
          };
        }
      },
    }),
    []
  );

  const transforms = useCloudAnalyticsTransforms();
  const [filtersStateChanged, setFiltersStateChanged] = useState(false);
  const inputRef = useRef<HTMLInputElement>();
  const classes = useStyles();
  const alert = filterAlerts?.[selected.id]?.(selected) ?? null;
  const isFixed = selected.data.type === "fixed";
  const [options, setOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [filters, setFilters] = useState(selected._filter ?? []);
  const [checkbox, setCheckbox] = useState<CheckboxState>({
    primary: "",
    indeterminate: false,
    checked: false,
  });

  const isAttribution = isAttributionDimension(selected);

  const isDarkMode = useDarkThemeCheck();

  useEffect(() => {
    setFiltersStateChanged(false);
    setOptions([]);
    setInputValue("");
    setFilters(selected._filter ?? []);
    setCheckbox({
      primary: "",
      indeterminate: false,
      checked: false,
    });
  }, [selected]);

  const { label, plural } = getPlural(selected);

  const transform = useMemo(() => {
    if (!transforms) {
      return null;
    }

    if (selected.data.type === Metadata.ATTRIBUTION_GROUP) {
      return transforms[Metadata.ATTRIBUTION_GROUP] ?? null;
    }

    return transforms[selected.id] ?? null;
  }, [transforms, selected]);

  const filteredOptions = useMemo(() => {
    const filterOptions = createFilterOptions<string>({
      trim: true,
      ignoreAccents: true,
      ignoreCase: true,
      matchFrom: "any",
      stringify: (option: string) => transform?.(option, selected.data.nullFallback) ?? option,
    });
    return filterOptions(options, {
      inputValue,
      getOptionLabel: (option) => option,
    });
  }, [transform, selected.data.nullFallback, options, inputValue]);

  const canAddCustomValue =
    selected.data.key !== FixedFilters.CLOUD &&
    selected.data.key !== Metadata.ATTRIBUTION &&
    selected.data.key !== Metadata.ATTRIBUTION_GROUP;
  const numSelectedOptions = filters.length;
  const numOptions = options.length;
  const numFilteredOptions = filteredOptions.length;

  useEffect(() => {
    if (options.length) {
      return;
    }

    let newOptions: string[] = [];
    const isUserCreatedDimensions =
      selected.data.type === Metadata.ATTRIBUTION || selected.data.type === Metadata.ATTRIBUTION_GROUP;

    if (!cloudProviders?.length || isUserCreatedDimensions) {
      newOptions = selected.data.values ?? [];
    } else {
      newOptions = dimensionProvidersValues(selected, cloudProviders);
    }

    if (isUserCreatedDimensions) {
      newOptions = union(filters, newOptions);
    } else {
      newOptions = union(sortBy(filters), sortBy(newOptions));
    }

    if (selected.data.nullFallback) {
      newOptions = union([selected.data.nullFallback], newOptions);
    }

    setOptions(newOptions);
  }, [selected, cloudProviders, filters, options.length]);

  useEffect(() => {
    if (inputValue === "") {
      if (numSelectedOptions === 0) {
        setCheckbox((checkbox) => ({ ...checkbox, primary: filterDialogText.ALL_OPTIONS(plural, numOptions) }));
      } else if (numSelectedOptions === numOptions) {
        setCheckbox((checkbox) => ({
          ...checkbox,
          primary: filterDialogText.ALL_OPTIONS_SELECTED(numOptions > 1 ? numOptions : ""),
        }));
      } else {
        setCheckbox((checkbox) => ({
          ...checkbox,
          primary: filterDialogText.SELECTED_OUT_OF(numSelectedOptions, numOptions),
        }));
      }
    } else {
      setCheckbox((checkbox) => ({ ...checkbox, primary: filterDialogText.FILTERED_RESULTS(numFilteredOptions) }));
    }
  }, [inputValue, numOptions, numSelectedOptions, numFilteredOptions, plural]);

  const addCustomOption = useCallback(() => {
    setOptions((options) => {
      const newOptions = options.slice();
      newOptions.splice(selected.data.nullFallback ? 1 : 0, 0, inputValue);
      return newOptions;
    });
    setFilters((filters) => [...filters, inputValue]);
    setInputValue("");
  }, [inputValue, selected.data.nullFallback]);

  const showNoMatchesLine = useMemo(() => {
    const noExactMatch = inputValue.length > 0 && !filteredOptions.includes(inputValue);
    return (canAddCustomValue && noExactMatch) || (!canAddCustomValue && numFilteredOptions === 0);
  }, [inputValue, filteredOptions, canAddCustomValue, numFilteredOptions]);

  let regularVirtualListHeight = LIST_ITEM_HEIGHT * numOfListItems;

  if (showNoMatchesLine) {
    regularVirtualListHeight = LIST_ITEM_HEIGHT * (numOfListItems - 1);
  }

  const saveFilterChanges = useCallback(async () => {
    if (filters.length === 0 && handleCancelFilter && selected._position === Positions.UNUSED) {
      handleCancelFilter([selected]);
    } else {
      onSaveFilters(filters);
    }
  }, [onSaveFilters, filters, handleCancelFilter, selected]);

  const disabled = useMemo(
    () => !filtersStateChanged && (selected._position === Positions.UNUSED || isAttribution),
    [filtersStateChanged, isAttribution, selected._position]
  );

  useEffect(() => {
    if (selected._position === Positions.UNUSED || isAttribution) {
      if (selected._inverse !== inverse) {
        setFiltersStateChanged(true);
        return;
      }
      const diff1 = difference(selected._filter ?? [], filters);
      const diff2 = difference(filters, selected._filter ?? []);
      if (diff1.length > 0 || diff2.length > 0) {
        setFiltersStateChanged(true);
      } else {
        setFiltersStateChanged(false);
      }
    }
  }, [filters, inverse, isAttribution, selected._filter, selected._inverse, selected._position]);

  useEffect(() => {
    const numFilteredSelectedOptions = intersection(filteredOptions, filters).length ?? 0;
    const allSelected = numFilteredSelectedOptions === numFilteredOptions;
    setCheckbox((checkbox) => ({
      ...checkbox,
      indeterminate: !allSelected && numFilteredSelectedOptions > 0,
      checked: numFilteredSelectedOptions !== 0,
    }));
  }, [filteredOptions, filters, numFilteredOptions]);

  const renderRow = useCallback(
    (props) => {
      const { key, index, style } = props;
      const option = filteredOptions[index];
      const checked = filters.includes(option);

      const aws = (selected._values.awsValues ?? []).includes(option);
      const gcp = (selected._values.gcpValues ?? []).includes(option);
      const azure = (selected._values.azureValues ?? []).includes(option);
      const datahub = (selected._values.datahub ?? []).includes(option);
      const snowflake = (selected._values.snowflake ?? []).includes(option);
      const datadog = (selected._values.datadog ?? []).includes(option);

      const onClick = () => {
        if (checked) {
          setFilters(without(filters, option));
        } else {
          setFilters(union(filters, [option]));
        }
      };

      return (
        <Stack
          spacing={1}
          direction="row"
          key={key}
          style={style}
          onClick={onClick}
          data-testid={index}
          sx={{
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <Checkbox
            id={option}
            checked={checked}
            disableRipple
            disabled={isOptionDisabled?.(option) ?? false}
            inputProps={{ "aria-labelledby": option }}
            edge="start"
            size="small"
            color="primary"
            data-testid={option}
            sx={{ p: 0 }}
          />
          <Box sx={{ flexGrow: 1, maxWidth: "calc(100% - 75px)", textOverflow: "ellipsis" }}>
            <TooltipWithMaxWidth title={option?.length > 60 ? option : ""}>
              <Typography noWrap variant="body2">
                {transform?.(option, selected.data.nullFallback) ?? option}
              </Typography>
            </TooltipWithMaxWidth>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            {aws && (
              <Box sx={{ width: "20px" }}>
                <Box
                  component="img"
                  src={isDarkMode ? AmazonWebServicesIconDarkMode : AmazonWebServicesIcon}
                  sx={{ height: "20px", width: "20px" }}
                  aria-hidden
                />
              </Box>
            )}
            {gcp && (
              <Box sx={{ width: "20px" }}>
                <Box component="img" src={GoogleCloudIcon} sx={{ height: "20px", width: "20px" }} aria-hidden />
              </Box>
            )}
            {azure && (
              <Box sx={{ width: "20px" }}>
                <Box component="img" src={MicrosoftAzureIcon} sx={{ height: "20px", width: "20px" }} aria-hidden />
              </Box>
            )}
            {datahub && (
              <Box sx={{ width: "20px" }}>
                <Box component="img" src={DoitLogo} sx={{ height: "20px", width: "20px" }} aria-hidden />
              </Box>
            )}
            {snowflake && (
              <Box sx={{ width: "20px" }}>
                <Box component="img" src={SnowflakeIcon} sx={{ height: "20px", width: "20px" }} aria-hidden />
              </Box>
            )}

            {datadog && (
              <Box sx={{ width: "20px" }}>
                <Box
                  component="img"
                  src={isDarkMode ? DatadogIconDarkMode : DatadogIcon}
                  sx={{ height: "20px", width: "20px" }}
                  aria-hidden
                />
              </Box>
            )}
          </Box>
        </Stack>
      );
    },
    [
      filteredOptions,
      filters,
      selected._values.awsValues,
      selected._values.gcpValues,
      selected._values.azureValues,
      selected._values.datahub,
      selected._values.snowflake,
      selected._values.datadog,
      selected.data.nullFallback,
      isOptionDisabled,
      transform,
      isDarkMode,
    ]
  );

  const listItemTextPrimary =
    numFilteredOptions > 0 ? filterDialogText.NO_EXACT_MATCH(inputValue) : filterDialogText.NO_MATCHES(inputValue);

  const listItemTextSecondary = canAddCustomValue ? filterDialogText.CLICK_TO_ADD : undefined;

  const dialog = (
    <DialogContent data-testid="RegularFilterContent" sx={{ display: "flex", flexDirection: "column", pb: 0 }}>
      {alert && (
        <Alert
          variant="standard"
          severity={alert.severity ?? "info"}
          action={alert.action ?? undefined}
          className={classes.alert}
        >
          {alert.message}
        </Alert>
      )}
      <TextField
        inputRef={inputRef}
        label={isFixed ? label : `${label} values`}
        margin="normal"
        placeholder={filterDialogText.TYPE_TO_FILTER}
        data-testid="simple-filter"
        variant="outlined"
        value={inputValue}
        onChange={(event) => {
          setInputValue(event.target.value);
        }}
        fullWidth
        autoFocus
        slotProps={{
          input: {
            endAdornment: inputValue !== "" && (
              <InputAdornment position="end">
                <IconButton
                  size="small"
                  onClick={() => {
                    setInputValue("");
                    if (inputRef.current) {
                      inputRef.current.focus();
                    }
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </InputAdornment>
            ),
          },

          inputLabel: {
            shrink: true,
          },
        }}
      />
      {options.length > 0 ? (
        <>
          {showNoMatchesLine && (
            <List disablePadding>
              <ListItem
                divider
                sx={{ height: INCREASED_LIST_ITEM_HEIGHT }}
                secondaryAction={
                  canAddCustomValue && (
                    <IconButton edge="end" aria-label="add" size="small" onClick={addCustomOption}>
                      <AddIcon />
                    </IconButton>
                  )
                }
              >
                <ListItemText
                  primary={listItemTextPrimary}
                  secondary={listItemTextSecondary}
                  primaryTypographyProps={{ variant: "subtitle2" }}
                  secondaryTypographyProps={{ variant: "caption" }}
                />
              </ListItem>
            </List>
          )}
          {numFilteredOptions > 0 && (
            <ListItemButton
              divider
              onClick={() => {
                if (checkbox.indeterminate) {
                  setFilters(union(filters, filteredOptions));
                } else if (checkbox.checked) {
                  setFilters(difference(filters, filteredOptions));
                } else {
                  setFilters(union(filters, filteredOptions));
                }
              }}
              sx={{ height: INCREASED_LIST_ITEM_HEIGHT, flexGrow: 0, pl: 0.35 }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <Checkbox
                  checked={checkbox.checked}
                  indeterminate={checkbox.indeterminate}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": "main-checkbox" }}
                  edge="start"
                  size="small"
                  color="primary"
                />
              </ListItemIcon>
              <ListItemText
                id="main-checkbox"
                primary={checkbox.primary}
                primaryTypographyProps={{ variant: "subtitle2" }}
              />
            </ListItemButton>
          )}
          <Box data-testid="list-items" sx={{ flexGrow: 1 }}>
            <AutoSizer disableHeight={!fullScreen}>
              {({ width, height }) => (
                <VirtualizedList
                  rowHeight={LIST_ITEM_HEIGHT}
                  height={fullScreen ? height : regularVirtualListHeight}
                  rowCount={numFilteredOptions}
                  rowRenderer={renderRow}
                  width={width}
                />
              )}
            </AutoSizer>
          </Box>
        </>
      ) : null}
      <Divider />
    </DialogContent>
  );

  return { dialog, disabled, saveFilterChanges };
};

const useFilterDialog = ({
  advancedFilter,
  inverse,
  onSaveFilters,
  selected,
  cloudProviders,
  isOptionDisabled,
  touched,
  numOfListItems,
}: SimpleFilterProps & { advancedFilter: boolean }) => {
  const result1 = useAdvancedFilterDialog({ selected, onSaveFilters, touched });
  const result2 = useSimpleFilterDialog({
    selected,
    onSaveFilters,
    inverse,
    cloudProviders,
    isOptionDisabled,
    numOfListItems,
  });

  return advancedFilter ? result1 : result2;
};

type CheckboxState = {
  primary: string;
  indeterminate: boolean;
  checked: boolean;
};

export type OnSave = (filterType: "regexp" | "values", filtersOrRegex: string[] | string, inverse: boolean) => void;

type Props = {
  cloudProviders?: MetadataCloud[] | null;
  disableEscapeKeyDown?: boolean;
  onCancel?: (selected: MetadataOption[]) => void;
  isOptionDisabled?: (option: string) => boolean;
  onClose: () => void;
  onSave: OnSave;
  open: boolean;
  selected: MetadataOption;
  showExcludeSelection?: boolean;
  alertText?: string;
  alertSeverity?: AlertColor;
};

const FilterDialog = ({
  cloudProviders,
  disableEscapeKeyDown,
  onCancel,
  isOptionDisabled,
  onClose,
  onSave,
  open,
  selected,
  showExcludeSelection = true,
  alertText,
  alertSeverity,
}: Props) => {
  const classes = useStyles();
  const { fullScreen } = useFullScreen();
  const { current: touched } = useRef<Set<string>>(new Set());
  const [advancedFilter, setAdvancedFilter] = useState(!!selected._regexp);
  const [inverse, setInverse] = useState(selected._inverse ?? false);
  const isAttribution = isAttributionDimension(selected);

  useEffect(() => {
    setAdvancedFilter(!!selected._regexp);
    setInverse(selected._inverse ?? false);
  }, [selected._regexp, selected._inverse]);

  const onSaveFilters = useCallback(
    (filtersOrRegex: string[] | string) => {
      const filterType = advancedFilter ? "regexp" : "values";
      onSave(filterType, filtersOrRegex, inverse);
    },
    [advancedFilter, inverse, onSave]
  );

  const { dialog, disabled, saveFilterChanges } = useFilterDialog({
    advancedFilter,
    selected,
    onSaveFilters,
    inverse,
    handleCancelFilter: onCancel,
    cloudProviders,
    isOptionDisabled,
    touched,
    numOfListItems: alertText ? 7 : 6,
  });

  const { disableRegexpFilter } = selected.data;

  const { label, plural } = getPlural(selected);

  const saveFilterChangesAndClose = useCallback(async () => {
    await saveFilterChanges();
    onClose();
  }, [onClose, saveFilterChanges]);

  const onCancelDialog = useCallback(() => {
    // on cancel, remove the field if it has no filter values/regexp selected
    if (
      onCancel &&
      !selected._filter &&
      !selected._regexp &&
      (selected._position === Positions.UNUSED || isAttribution)
    ) {
      onCancel([selected]);
    }
    onClose();
  }, [onCancel, isAttribution, onClose, selected]);

  const handleClose = useCallback(
    (_, reason) => {
      if (reason === "backdropClick") {
        return;
      }

      if (reason === "escapeKeyDown") {
        if (disableEscapeKeyDown) {
          return;
        }

        onCancelDialog();
        return;
      }

      onClose();
    },
    [disableEscapeKeyDown, onCancelDialog, onClose]
  );

  const saveFilterButton = useCallback(
    () => (
      <Button
        color="primary"
        variant="contained"
        data-testid="save-button"
        disabled={disabled}
        onClick={saveFilterChangesAndClose}
      >
        {globalText.SAVE}
      </Button>
    ),
    [disabled, saveFilterChangesAndClose]
  );

  const onKeyDown = useCallback(
    async (event) => {
      if (event.key === "Enter") {
        await saveFilterChangesAndClose();
      }
    },
    [saveFilterChangesAndClose]
  );

  const handleInverse = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setInverse(checked);
    touched.add("inverse");
  };

  return (
    <Dialog
      data-testid="dialog"
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      maxWidth="sm"
      fullScreen={fullScreen}
      fullWidth
      PaperProps={{
        sx: {
          height: alertText ? INCREASED_DIALOG_HEIGHT : REGULAR_DIALOG_HEIGHT,
        },
      }}
      onKeyDown={onKeyDown}
    >
      <DialogTitle id="dialog-title">{filterDialogText.TITLE(plural)}</DialogTitle>
      {dialog}
      <DialogContent sx={{ flexGrow: 0, pt: 2, pb: 2 }}>
        {showExcludeSelection && (
          <ListItem tabIndex={-1} className={classes.listItem} sx={{ pl: 0.35 }}>
            <ListItemIcon className={classes.listItemIcon}>
              <Checkbox
                id={selectAllId}
                disableRipple
                checked={inverse}
                onChange={handleInverse}
                edge="start"
                size="small"
                color="primary"
              />
            </ListItemIcon>
            <label htmlFor={selectAllId}>
              <ListItemText
                primary={filterDialogText.EXCLUDE_SELECTION}
                primaryTypographyProps={{
                  variant: "body2",
                }}
                sx={{ cursor: "pointer", paddingRight: 2 }}
              />
            </label>
          </ListItem>
        )}
        {alertText && (
          <Alert variant="standard" severity={alertSeverity}>
            {alertText}
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Tooltip title={disableRegexpFilter ? filterDialogText.REGEX_NOT_SUPPORTED(label) : ""}>
          <span>
            <Button
              color="primary"
              style={{ width: !advancedFilter ? 98 : "auto" }}
              variant="text"
              data-testid="advanced-button"
              disabled={disableRegexpFilter}
              onClick={() => {
                setAdvancedFilter((advancedFilter) => !advancedFilter);
              }}
            >
              {advancedFilter ? filterDialogText.USE_BASIC_FILTERING : filterDialogText.USE_REGEX}
            </Button>
          </span>
        </Tooltip>
        <div className={classes.spacer} />
        <Button color="primary" variant="text" onClick={onCancelDialog} data-testid="cancel-button">
          {globalText.CANCEL}
        </Button>
        {saveFilterButton()}
      </DialogActions>
    </Dialog>
  );
};

export default FilterDialog;
