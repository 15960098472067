import React from "react";

import { TextField } from "@mui/material";
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useField } from "formik";

import { RequiredLabel } from "../helpers";
import { type SubscriptionFormValues, type SubscriptionInputProps } from "../types";

export function TimeInput({ disabled }: SubscriptionInputProps) {
  const [time, meta, formikHelpers] = useField<SubscriptionFormValues["time"]>("time");

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon}>
      <TimePicker
        ampm
        label={RequiredLabel("Time")}
        value={time.value}
        onChange={(value) => {
          if (!value) {
            return;
          }
          formikHelpers.setValue(value);
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            data-cy="time-input"
            {...params}
            error={!!meta.error}
            fullWidth
            variant="outlined"
            slotProps={{
              inputLabel: {
                shrink: true,
              },
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
