import { useEffect } from "react";

import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { Allocation } from "./Allocation";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { trackAllocationEvent } from "./utils";

export const AllocationsCreate = () => {
  const { addItem, clear } = useAllocationDndStore();
  const { trackEvent } = useSegmentTrackEvent();

  useEffect(() => {
    trackAllocationEvent({
      trackEvent,
      eventName: "Allocation Rule Opened",
      ruleCount: 0,
      hasUnallocatedCosts: false,
    });
  }, [trackEvent]);

  useEffect(() => {
    addItem(crypto.randomUUID());
    return () => {
      clear();
    };
  }, [addItem, clear]);
  return <Allocation />;
};
