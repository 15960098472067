import { type ReactNode, type SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import SearchIcon from "@mui/icons-material/Search";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { type SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import { useAuthContext } from "../../../Context/AuthContext";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import { ALL_CATEGORIES, ALL_CLOUD_PROVIDERS } from "../constants";
import { useInsightsContext } from "../context";
import {
  useAccessType,
  useAccountsWithMissingPermissions,
  useInsights,
  useRelevantAccountsWithoutCostOptPermissions,
} from "../hooks";
import { NotFound } from "../NotFound/NotFound";
import {
  type BreakdownData,
  type Category,
  type CloudProviderTag,
  type Filters,
  type Insight,
  InsightProviderId,
  type InsightsAddPermissionsProperties,
  type InsightsListViewedProperties,
  type SavingsPeriod,
} from "../types";
import { calculateTotalSavingsForTimePeriod, sortByTotalPotentialDailySavings } from "../utils";
import { InsightsListRow } from "./InsightsListRow";
import { InsightsListRowUpsell } from "./InsightsListRowUpsell";
import { MissingPermissionsAlerts } from "./MissingPermissionsAlerts";

const VALUE_ASCENDING = "ascending";
const VALUE_DESCENDING = "descending";
const CREATE_CUSTOM_INSIGHT = "Create new insight";

function capitalizeProvider(provider: string): string {
  const capitalizationMap: Record<string, string> = {
    aws: "AWS",
    gcp: "Google Cloud",
    azure: "Azure",
    snowflake: "Snowflake",
  };
  return capitalizationMap[provider] || "";
}

type FilterConfig = {
  title: string;
  items: string[];
  filterKey: keyof Filters;
  getLabel: (item: string) => string;
  filterFunction: (insight: Insight, item: string) => boolean;
};

type FilterSection = {
  config: FilterConfig;
  filters: Filters;
  handleFilterChange: (category: keyof Filters, value: string) => void;
  insights: Insight[] | undefined;
};

function FilterSection({ config, filters, handleFilterChange, insights }: Readonly<FilterSection>) {
  const { title, items, filterKey, getLabel, filterFunction } = config;
  const currentFilterValues = filters[filterKey].map(String);

  return (
    <Box key={title} sx={{ mb: 3 }}>
      <Typography
        variant="subtitle2"
        sx={{
          mb: 1,
        }}
      >
        {title}
      </Typography>
      <FormGroup>
        {items.map((item) => (
          <FormControlLabel
            key={item}
            control={
              <Checkbox
                size="small"
                checked={currentFilterValues.includes(item)}
                onChange={() => {
                  handleFilterChange(filterKey, item);
                }}
              />
            }
            label={
              <Typography variant="body2" component="span">
                {getLabel(item)}{" "}
                <Typography variant="body2" component="span" color="textSecondary">
                  ({insights?.filter((insight) => filterFunction(insight, item)).length ?? 0})
                </Typography>
              </Typography>
            }
            sx={{ mb: 1 }}
          />
        ))}
      </FormGroup>
    </Box>
  );
}

type PageWrapperProps = {
  children: ReactNode;
  hideTitle?: boolean;
};

function PageWrapper({ children, hideTitle }: Readonly<PageWrapperProps>) {
  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mt: 6,
          mb: 4,
        }}
      >
        {!hideTitle && (
          <>
            <Typography
              data-cy="insights-title"
              variant="h1"
              sx={{
                mb: 1,
              }}
            >
              Insights
            </Typography>
            <Typography
              data-cy="insights-description"
              variant="body1"
              color="textSecondary"
              sx={{
                fontWeight: 400,
                mb: 6,
              }}
            >
              Uncover optimization opportunities based on your cloud billing data.
            </Typography>
          </>
        )}
        {children}
      </Box>
    </Container>
  );
}

type InsightsListProps = {
  hideTitle?: boolean;
  hideFilters?: boolean;
};

export function InsightsList({ hideTitle, hideFilters }: Readonly<InsightsListProps>) {
  const { insights, isLoading, nonEntitledSummary, noAccessResults } = useInsights();

  const { trustedAdvisor: trustedAdvisorAccessType } = useAccessType(insights);
  const { trustedAdvisorAccounts: accountsWithoutTAPermissions } = useAccountsWithMissingPermissions();

  const {
    hasNoAccessToRelevantAccounts: hasNoAccessToCostOptHub,
    relevantAccountsWithoutPermissions: accountsWithoutCostOptPermissions,
  } = useRelevantAccountsWithoutCostOptPermissions();

  const { isDoitEmployee } = useAuthContext();
  const theme = useTheme();
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();
  const { trackEvent } = useSegmentTrackEvent();
  const { savingsPeriod, setSavingsPeriod, setFilters, filters, setSelectedTab, selectedTab } = useInsightsContext();

  const [sortOrder, setSortOrder] = useState(VALUE_DESCENDING);
  const [searchQuery, setSearchQuery] = useState("");
  const [isFirstRender, setIsFirstRender] = useState(true);

  const createNewInsight = () => {
    history.push(`${routeMatchURL}/new-insight`);
  };

  useEffect(() => {
    setIsFirstRender(false);
  }, []);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const changeSavingsPeriod = (event: SelectChangeEvent) => {
    const newValue = event.target.value as SavingsPeriod;
    setSavingsPeriod(newValue);
  };

  const changeSortOrder = (event: SelectChangeEvent) => {
    const newValue = event.target.value;
    setSortOrder(newValue);
  };

  const handleFilterChange = (category: keyof Filters, value: string) => {
    setFilters((prev) => {
      const currentValues = prev[category] as string[];
      const index = currentValues.indexOf(value);
      const newValues = index === -1 ? [...currentValues, value] : currentValues.filter((item) => item !== value);
      return {
        ...prev,
        [category]: newValues,
      };
    });
  };

  const permissionsNeededInsights = useMemo(() => {
    const taInsights =
      trustedAdvisorAccessType === "no_access"
        ? (noAccessResults?.filter((insight) => insight.providerId === InsightProviderId.TRUSTED_ADVISOR) ?? [])
        : [];

    const costOptHubInsights = hasNoAccessToCostOptHub
      ? (noAccessResults?.filter((insight) => insight.providerId === "aws-cost-optimization-hub") ?? [])
      : [];

    const permissionsNeededInsights = [...taInsights, ...costOptHubInsights];

    return permissionsNeededInsights.map<Insight>((insight) => ({
      providerId: insight.providerId,
      key: insight.key,
      canBeIndividuallyExecuted: false,
      customerId: insight.customerId,
      lastUpdated: new Date().toISOString(), // Placeholder date
      title: insight.title,
      shortDescription: insight.shortDescription,
      detailedDescriptionMdx: "",
      cloudTags: insight.cloudTags as CloudProviderTag[],
      otherTags: [],
      categories: insight.categories as Category[],
      isInternal: false,
      supportCategory: insight.supportCategory,
      results: null,
      reportUrl: "",
      customInsightAttributes: null,
      userStatusChanges: null,
      easyWinDescription: insight.easyWinDescription,
      publishingUser: null,
      displayStatus: "permissions needed",
      lastStatusChange: null,
      permissions: null,
    }));
  }, [hasNoAccessToCostOptHub, trustedAdvisorAccessType, noAccessResults]);

  const allSources = useMemo(() => ["DoiT Cloud Intelligence™", "DoiT Experts"], []);

  const FILTER_CONFIGS = useMemo<FilterConfig[]>(
    () => [
      {
        title: "Provider",
        items: ALL_CLOUD_PROVIDERS,
        filterKey: "providers",
        getLabel: capitalizeProvider,
        filterFunction: (insight, item) => insight.cloudTags.includes(item as CloudProviderTag),
      },
      {
        title: "Category",
        items: ALL_CATEGORIES,
        filterKey: "categories",
        getLabel: (item) => item,
        filterFunction: (insight, item) =>
          insight.categories?.some((cat) => cat.toLowerCase() === item.toLowerCase()) ?? false,
      },
      {
        title: "Source",
        items: allSources,
        filterKey: "sources",
        getLabel: (item) => item,
        filterFunction: (insight, item) =>
          item === "DoiT Cloud Intelligence™" ? insight.providerId !== "custom" : insight.providerId === "custom",
      },
    ],
    [allSources]
  );

  const filteredInsights = insights || [];

  const allInsights = [...filteredInsights, ...permissionsNeededInsights];
  const sortedInsights = sortByTotalPotentialDailySavings(allInsights, sortOrder === VALUE_ASCENDING);

  interface TabConfig {
    label: string;
    filterFunction: (insight: Insight) => boolean;
    shouldRender: boolean;
  }

  const TAB_CONFIGS = useMemo<TabConfig[]>(
    () => [
      {
        label: "All",
        filterFunction: () => true,
        shouldRender: true,
      },
      {
        label: "Actionable",
        filterFunction: (insight) => insight.displayStatus === "actionable",
        shouldRender: true,
      },
      {
        label: "Optimized",
        filterFunction: (insight) => insight.displayStatus === "optimized",
        shouldRender: true,
      },
      {
        label: "Dismissed",
        filterFunction: (insight) => insight.displayStatus === "dismissed",
        shouldRender: true,
      },
      {
        label: "Permissions needed",
        filterFunction: (insight) => insight.displayStatus === "permissions needed",
        shouldRender: permissionsNeededInsights.length > 0,
      },
    ],
    [permissionsNeededInsights]
  );

  const filterInsight = useCallback(
    (insight: Insight) =>
      FILTER_CONFIGS.every(({ filterKey, filterFunction }) => {
        const currentFilterValues = filters[filterKey].map(String);
        if (currentFilterValues.length === 0) return true;
        return currentFilterValues.some((item) => filterFunction(insight, item));
      }),
    [FILTER_CONFIGS, filters]
  );

  const insightsByTab = useMemo(
    () => TAB_CONFIGS.map((tabConfig) => sortedInsights.filter(tabConfig.filterFunction).filter(filterInsight)),
    [TAB_CONFIGS, sortedInsights, filterInsight]
  );

  const nonEntitledDailySavings = nonEntitledSummary?.potentialDailySavings.value || 0;
  const customProblems = nonEntitledSummary?.customProblems.numberOfActionableInsights || 0;
  const potentialDailySaving = nonEntitledSummary?.potentialDailySavings.numberOfActionableInsights || 0;
  const nonEntitledTotalInsights = customProblems + potentialDailySaving;

  const selectedInsights: (Insight | { isUpsell: true })[] = useMemo(() => {
    const selected = insightsByTab[selectedTab]
      .filter((insight) => {
        const searchText = searchQuery.toLowerCase();
        return (
          insight.title.toLowerCase().includes(searchText) ||
          insight.shortDescription.toLowerCase().includes(searchText) ||
          insight.detailedDescriptionMdx.toLowerCase().includes(searchText)
        );
      })
      .filter(filterInsight);

    return selected;
  }, [insightsByTab, selectedTab, searchQuery, filterInsight]);

  const events = useMemo(() => {
    const totalSavings = calculateTotalSavingsForTimePeriod(selectedInsights as Insight[], savingsPeriod);

    const insightsHighestSavings = (selectedInsights as Insight[]).reduce((max, insight) => {
      const dailySavings =
        insight.results?.potentialDailySavings?.breakdown?.data.reduce(
          (sum: number, dataItem: BreakdownData) => sum + dataItem.value,
          0
        ) || 0;

      const totalSavings = dailySavings * (savingsPeriod === "daily" ? 1 : savingsPeriod === "monthly" ? 30 : 365);
      return Math.max(max, totalSavings);
    }, 0);

    const insightsOrderOnSelectedTab = (selectedInsights as Insight[]).map((insight) => insight.key);

    const customInsightsCount = (selectedInsights as Insight[]).filter(
      (insight) => insight.providerId === "custom"
    ).length;

    const insightsTotalSavings = totalSavings.toString();

    const listViewedEvent: InsightsListViewedProperties = {
      pageType: "Governance",
      pageSubType: "Insights",
      feature: "Insights",
      isPageOpenEvent: isFirstRender,
      insightsSortOrder: sortOrder,
      insightsSavingsPeriod: savingsPeriod,
      insightsFiltersSelected: [...filters.providers, ...filters.categories, ...filters.sources],
      insightsSearchTerms: searchQuery,
      selectedInsightsCount: selectedInsights.length,
      customInsightsCount,
      insightsTotalSavings,
      insightsHighestSavings,
      insightsOrderOnSelectedTab,
      permissionsNeededInsights: permissionsNeededInsights.length,
      actionableInsights: insightsByTab[1].length,
      optimizedInsights: insightsByTab[2].length,
      dismissedInsights: insightsByTab[3].length,
      selectedInsightsTab: TAB_CONFIGS[selectedTab].label,
    };

    const addTAPermissionClickedEvent: InsightsAddPermissionsProperties = {
      pageType: "Governance",
      pageSubType: "Insights",
      feature: "Insights",
      isPageOpenEvent: false,
      insightsSavingsPeriod: savingsPeriod,
      selectedInsightsCount: selectedInsights.length,
      customInsightsCount,
      insightsTotalSavings,
      insightsHighestSavings,
      permissionsNeededInsights: permissionsNeededInsights.length,
      actionableInsights: insightsByTab[1].length,
      optimizedInsights: insightsByTab[2].length,
      dismissedInsights: insightsByTab[3].length,
      accountsMissingPermissions: accountsWithoutTAPermissions,
    };

    const addCostOptPermissionClickedEvent: InsightsAddPermissionsProperties = {
      pageType: "Governance",
      pageSubType: "Insights",
      feature: "Insights",
      isPageOpenEvent: false,
      insightsSavingsPeriod: savingsPeriod,
      selectedInsightsCount: selectedInsights.length,
      customInsightsCount,
      insightsTotalSavings,
      insightsHighestSavings,
      permissionsNeededInsights: permissionsNeededInsights.length,
      actionableInsights: insightsByTab[1].length,
      optimizedInsights: insightsByTab[2].length,
      dismissedInsights: insightsByTab[3].length,
      accountsMissingPermissions: accountsWithoutCostOptPermissions,
    };

    return { listViewedEvent, addTAPermissionClickedEvent, addCostOptPermissionClickedEvent };
  }, [
    isFirstRender,
    sortOrder,
    savingsPeriod,
    filters,
    searchQuery,
    selectedInsights,
    permissionsNeededInsights,
    insightsByTab,
    TAB_CONFIGS,
    selectedTab,
    accountsWithoutTAPermissions,
    accountsWithoutCostOptPermissions,
  ]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    trackEvent("Insight List Viewed", events.listViewedEvent);
  }, [isLoading, events.listViewedEvent, trackEvent]);

  if (isLoading) {
    return (
      <PageWrapper hideTitle={hideTitle}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60vh",
            width: "100%",
          }}
        >
          <SyncLoader size={10} color={theme.palette.primary.main} loading />
        </Box>
      </PageWrapper>
    );
  }

  if (!insights?.length) {
    return (
      <PageWrapper hideTitle={hideTitle}>
        <MissingPermissionsAlerts
          accountsWithoutTAPermissions={accountsWithoutTAPermissions}
          accountsWithoutCostOptHubPermissions={accountsWithoutCostOptPermissions}
          addTAPermissionClickedEvent={events.addTAPermissionClickedEvent}
          addCostOptHubPermissionClickedEvent={events.addCostOptPermissionClickedEvent}
        />
        <NotFound />
        {isDoitEmployee && (
          <Stack
            sx={{
              alignItems: "center",
              mt: 5,
              maxWidth: 1200,
            }}
          >
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={createNewInsight}
              sx={{ whiteSpace: "nowrap" }}
            >
              {CREATE_CUSTOM_INSIGHT}
            </Button>
          </Stack>
        )}
      </PageWrapper>
    );
  }

  return (
    <PageWrapper hideTitle={hideTitle}>
      <MissingPermissionsAlerts
        accountsWithoutTAPermissions={accountsWithoutTAPermissions}
        accountsWithoutCostOptHubPermissions={accountsWithoutCostOptPermissions}
        addTAPermissionClickedEvent={events.addTAPermissionClickedEvent}
        addCostOptHubPermissionClickedEvent={events.addCostOptPermissionClickedEvent}
      />
      <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
        {!hideFilters && (
          <Box sx={{ width: { xs: "100%", md: 250 }, mb: { xs: 3, md: 0 }, mr: { md: 3 } }}>
            {FILTER_CONFIGS.map((config) => (
              <FilterSection
                key={config.title}
                config={config}
                filters={filters}
                handleFilterChange={handleFilterChange}
                insights={insights}
              />
            ))}
          </Box>
        )}
        <Box
          sx={{
            width: { xs: "100%", md: 966 },
            flexGrow: 1,
          }}
        >
          <Stack
            direction={{ xs: "column", md: "row" }}
            spacing={2}
            sx={{
              alignItems: { xs: "flex-start", md: "center" },
              justifyContent: "space-between",
              mb: 3,
              flexWrap: "wrap",
              gap: 2,
            }}
          >
            <TextField
              variant="outlined"
              size="small"
              placeholder="Search insights..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              sx={{ flex: 1, minWidth: 250, maxWidth: 500 }}
              slotProps={{
                input: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon color="action" />
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Stack
              direction="row"
              spacing={2}
              sx={{
                alignItems: "center",
                mt: { xs: 2, md: 0 },
                gap: 2,
                flexWrap: "wrap",
              }}
            >
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, ml: 0 }}>
                <InputLabel>Savings period</InputLabel>
                <Select label="Savings period" value={savingsPeriod} onChange={changeSavingsPeriod}>
                  <MenuItem value="yearly">Yearly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="daily">Daily</MenuItem>
                </Select>
              </FormControl>
              <FormControl variant="outlined" size="small" sx={{ minWidth: 120, marginLeft: "0!important" }}>
                <InputLabel>Sort by</InputLabel>
                <Select label="Sort by" value={sortOrder} onChange={changeSortOrder}>
                  <MenuItem value={VALUE_DESCENDING}>Value high to low</MenuItem>
                  <MenuItem value={VALUE_ASCENDING}>Value low to high</MenuItem>
                </Select>
              </FormControl>
              {isDoitEmployee && (
                <Button
                  size="large"
                  variant="contained"
                  color="primary"
                  onClick={createNewInsight}
                  sx={{ marginLeft: "0!important" }}
                >
                  {CREATE_CUSTOM_INSIGHT}
                </Button>
              )}
            </Stack>
          </Stack>

          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="Insights Categories"
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              minHeight: "48px",
              marginBottom: 3,
              borderBottom: "1px solid",
              borderColor: "divider",
            }}
          >
            {TAB_CONFIGS.map((tabConfig, index) =>
              tabConfig.shouldRender ? (
                <Tab
                  key={tabConfig.label}
                  label={`${tabConfig.label} (${insightsByTab[index]?.length ?? 0})`}
                  sx={{ textTransform: "none" }}
                />
              ) : null
            )}
          </Tabs>

          {selectedInsights.length ? (
            <Stack direction="column" spacing={2}>
              {selectedInsights.map((item, index) =>
                "isUpsell" in item ? (
                  <InsightsListRowUpsell
                    key={`upsell-${index}`}
                    totalInsights={nonEntitledTotalInsights}
                    savings={nonEntitledDailySavings}
                  />
                ) : (
                  <InsightsListRow
                    key={`${item.providerId}#${item.key}#${item.displayStatus}`}
                    insight={item}
                    isClickable={item.displayStatus !== "permissions needed"}
                  />
                )
              )}
            </Stack>
          ) : (
            <NotFound message="We couldn't find any relevant insights with the selected filters." showIcon={false} />
          )}
        </Box>
      </Stack>
    </PageWrapper>
  );
}
