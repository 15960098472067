import React, { useMemo, useState } from "react";

import { type AttributionFilter } from "@doitintl/cmp-models";
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";

import { useCloudAnalyticsTransforms } from "../hooks/cloudAnalytics/useCloudAnalyticsTransforms";

type Props = {
  filter: AttributionFilter;
  nullFallback: string | null;
  title: string;
  ruleFilterWidth: number;
  maxChips?: number;
};

export default function AllocationRuleChips({ filter, nullFallback, maxChips = 2, title, ruleFilterWidth }: Props) {
  const [showDialog, setShowDialog] = useState(false);
  const filteredValues = useMemo(
    () => filter.values?.filter((v) => v && v !== nullFallback) || [],
    [filter.values, nullFallback]
  );
  const showMaxChip = filteredValues.length > maxChips;
  const transforms = useCloudAnalyticsTransforms();

  const toggleDialog = () => {
    setShowDialog((prev) => !prev);
  };

  const maxWidth = useMemo(() => {
    if (filteredValues.length === 1) {
      return 420 - ruleFilterWidth * 7;
    } else {
      return 200 - ruleFilterWidth * 4.5;
    }
  }, [filteredValues.length, ruleFilterWidth]);

  return (
    <>
      {filteredValues.length > 0 && (
        <>
          {filteredValues.slice(0, maxChips).map((value) => (
            <Chip
              key={value}
              label={transforms?.[filter.id]?.(value, nullFallback) ?? value}
              size="small"
              color="primary"
              sx={{ maxWidth }}
              variant="outlined"
            />
          ))}

          {showMaxChip && (
            <Typography
              variant="caption"
              fontSize={12}
              color="primary"
              sx={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={toggleDialog}
            >{`+${filteredValues.length - maxChips}`}</Typography>
          )}
        </>
      )}

      <Dialog open={showDialog} onClose={toggleDialog} fullWidth maxWidth="sm">
        <DialogTitle>{title} Properties</DialogTitle>
        <DialogContent dividers={true}>
          <Typography variant="body2">
            This dimension consists of the following {filteredValues.length} properties:
          </Typography>
          <Box component="ul" sx={{ mt: 1, pl: 2 }}>
            {filteredValues.map((value) => (
              <li key={value}>
                <Typography variant="body2">{value}</Typography>
              </li>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleDialog} variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
