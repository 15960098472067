import { type FC, useCallback, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { type SupportZendeskTicketModel } from "@doitintl/cmp-models";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

import { OPERATOR_AND } from "../../../Components/FilterTable/constants";
import { FilterTable } from "../../../Components/FilterTable/FilterTable";
import { FilterTableSkeleton } from "../../../Components/FilterTable/FilterTableSkeleton";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { Comparators, type DefaultFilterColumn, type DefaultFilterColumns } from "../../../types/FilterTable";
import { transformStatus } from "../../../utils/common";
import { useZendeskTicketsContext } from "../../Context/ZendeskTicketsContext";
import NoTickets from "../NoTickets";
import SupportTicketsRow from "./SupportTicketsRow";
import { ticketsTableColumnsData, ticketsTableColumnsHeaders, zendeskTicketColumns } from "./TicketsTableColumns";

type SupportTicketsTableProps = {
  zendeskTickets: SupportZendeskTicketModel[];
  ticketsLoading: boolean;
  isDoitEmployee: boolean;
  setShowLoadingSnackbar: (show: boolean) => void;
  setForceOldTable: (force: boolean) => void;
};

/**
 * Table for displaying support tickets stored in firestore
 * (as opposed to the redux state used to populate the previous iteration of this table)
 */
const SupportTicketsTable: FC<SupportTicketsTableProps> = ({
  zendeskTickets,
  ticketsLoading,
  isDoitEmployee,
  setShowLoadingSnackbar,
  setForceOldTable,
}) => {
  const { t } = useTranslation("services");
  const { customer } = useCustomerContext();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { assigneeUsers, assigneeUsersLoading } = useZendeskTicketsContext();

  useEffect(() => {
    if (ticketsLoading || assigneeUsersLoading) {
      setShowLoadingSnackbar(true);
    } else {
      setShowLoadingSnackbar(false);
    }
  }, [assigneeUsersLoading, ticketsLoading, setShowLoadingSnackbar]);

  const rowComponent = useCallback(
    ({ row }) => (
      <SupportTicketsRow isDoitEmployee={isDoitEmployee} supportZendeskTicket={row} assigneeUsers={assigneeUsers} />
    ),
    [assigneeUsers, isDoitEmployee]
  );

  const getFilterDefaultValue = (): DefaultFilterColumns<SupportZendeskTicketModel> => {
    const closedFilter: DefaultFilterColumn<SupportZendeskTicketModel> = {
      column: {
        label: zendeskTicketColumns(t)[0].label,
        path: "ticket.status",
      },
      comparator: Comparators.NOT_EQUAL_TO,
      value: "closed",
      label: transformStatus("closed", t),
    };

    const emailFilter: DefaultFilterColumn<SupportZendeskTicketModel> = {
      column: {
        label: zendeskTicketColumns(t)[2].label,
        path: "ticket.requester_email",
      },
      value: currentUser.email,
      label: currentUser.email,
    };

    return isDoitEmployee ? [closedFilter] : [closedFilter, OPERATOR_AND, emailFilter];
  };

  return (
    <>
      <Alert
        sx={{ mt: 2 }}
        severity="info"
        action={
          <Button
            onClick={() => {
              setForceOldTable(true);
            }}
          >
            Switch to Old Table
          </Button>
        }
      >
        Tickets loaded from firestore
      </Alert>
      {ticketsLoading || assigneeUsersLoading ? (
        <FilterTableSkeleton />
      ) : (
        <FilterTable<SupportZendeskTicketModel>
          toolbarProps={{ allowToEditColumns: false }}
          rowComponent={rowComponent}
          emptyTableComponent={<NoTickets />}
          defaultFilters={getFilterDefaultValue()}
          headerColumns={ticketsTableColumnsHeaders(t)}
          filterColumns={ticketsTableColumnsData(t)}
          tableItems={zendeskTickets}
          defaultSortDirection="desc"
          defaultSortingColumnValue="ticket.updated_at"
          persistenceKey={`support_filter_${customer.id}`}
          data-cy="supportTicketsTable"
        />
      )}
    </>
  );
};

export default SupportTicketsTable;
