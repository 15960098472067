type Metadata = { details?: unknown; httpStatusCode?: number };

export type ApiOperationExceptionPayload = {
  name: string;
  message: string;
  httpStatusCode: number | undefined;
  details: unknown;
};

export class ApiOperationException extends Error {
  private constructor(
    public readonly name: string,
    public readonly message: string,
    cause: unknown,
    private readonly metadata?: Metadata
  ) {
    super(message, { cause });
  }

  public static fromException(originalException: unknown, metadata?: Metadata) {
    if (originalException instanceof Error) {
      return new ApiOperationException(originalException.name, originalException.message, originalException, metadata);
    }
    return new ApiOperationException("ApiOperationException", "Unknown exception", originalException, metadata);
  }

  public serialize(): ApiOperationExceptionPayload {
    return {
      name: this.name,
      message: this.message,
      httpStatusCode: this.metadata?.httpStatusCode,
      details: this.metadata?.details,
    };
  }
}
