import { Alert, CircularProgress, Stack, Typography } from "@mui/material";

interface LoadingAlertProps {
  message: string;
  onClose: () => void;
}

const LoadingAlert = ({ message, onClose }: LoadingAlertProps) => (
  <Alert
    severity="info"
    onClose={onClose}
    sx={{
      ".MuiAlert-message": {
        width: "100%",
      },
    }}
  >
    <Stack direction="row" spacing={2} style={{ display: "flex", alignItems: "center" }}>
      <Typography>{message}</Typography>
      <CircularProgress size={22} thickness={4} color="inherit" />
    </Stack>
  </Alert>
);

export default LoadingAlert;
