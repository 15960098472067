import { useCallback } from "react";

import { DateTime } from "luxon";

import { useCustomerContext } from "../../Context/CustomerContext";
import { type Contract } from "../../types/Contract";
import { getDateTimeFromFirestoreTimestamp } from "../../utils/common";
import { isDoitConsoleBaseService, isStandaloneAppPlatform } from "../utils";

export const useGetCustomerContractAndAssetDetails = () => {
  const { contracts, customer } = useCustomerContext({ allowNull: true });

  // is the customer missing assets for this platform?
  const customerDoesNotHaveAssets = useCallback(
    (platform: string): boolean =>
      !customer?.assets
        ?.map((asset) => asset.toString().replace("-standalone", ""))
        .includes(platform.replace("-project", "").replace("-standalone", "")) && !isDoitConsoleBaseService(platform),
    [customer?.assets]
  );

  // is the customer missing a billing account for this platform?
  const isPlatformWithoutBillingAccount = useCallback(
    (platform: string): boolean =>
      !isDoitConsoleBaseService(platform) &&
      customerDoesNotHaveAssets(platform) && // if customer has assets, then they must have a billing account.
      !contracts?.some((contract) => contract.type === platform.replace("-project", "")),
    [contracts, customerDoesNotHaveAssets]
  );

  // does the customer have an active contract for this platform?
  const hasActiveContract = useCallback(
    (platform: string) => {
      const isContractActive = (contract: Contract) => {
        if (!contract.active) {
          return false;
        }
        if (contract.endDate === null) {
          return true;
        }

        const now = DateTime.now().toUTC();
        const cStart = getDateTimeFromFirestoreTimestamp(contract.startDate).toUTC();
        const cEnd = getDateTimeFromFirestoreTimestamp(contract.endDate).toUTC();
        return now > cStart && now < cEnd;
      };
      return contracts?.some((contract) => contract.type === platform && isContractActive(contract));
    },
    [contracts]
  );

  const isStandaloneAppWithoutContractOrAssets = useCallback(
    (platform: string) => {
      const platformNoProject = platform.replace("-project", "");
      return (
        isStandaloneAppPlatform(platformNoProject) &&
        !hasActiveContract(platformNoProject) &&
        customerDoesNotHaveAssets(platformNoProject)
      );
    },
    [customerDoesNotHaveAssets, hasActiveContract]
  );

  return {
    customerDoesNotHaveAssets,
    hasActiveContract,
    isPlatformWithoutBillingAccount,
    isStandaloneAppWithoutContractOrAssets,
  };
};
