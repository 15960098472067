import { Box, MenuItem, Stack, TextField } from "@mui/material";

import { usePriorities } from "../../Support/consts";

type Props = {
  onChange: (value: string) => void;
  value: string;
};

export const SeverityDropdown = ({ onChange, value }: Props) => {
  const priorities = usePriorities();

  return (
    <TextField
      select
      required
      value={value}
      label="Severity"
      size="medium"
      onChange={(event) => {
        onChange(event.target.value);
      }}
      slotProps={{
        select: {
          MenuProps: {
            sx: { maxHeight: "460px", maxWidth: "300px", zIndex: 1201 },
          },
        },
      }}
    >
      {priorities.map((priority) => (
        <MenuItem key={priority.value} value={priority.value}>
          <Stack direction="row" alignItems="center">
            <Box
              sx={{
                width: 6,
                height: 24,
                backgroundColor: priority.color,
                marginRight: 1,
                borderRadius: 8,
              }}
            />

            {priority.title}
          </Stack>
        </MenuItem>
      ))}
    </TextField>
  );
};
