import { FormControl, InputLabel, MenuItem, Select, type SelectChangeEvent } from "@mui/material";

export enum TimeRange {
  T_1H = "1h",
  T_4H = "4h",
  T_12H = "12h",
  T_1D = "1d",
  T_3D = "3d",
  T_7D = "7d",
  T_14D = "14d",
  T_30D = "30d",
}

interface TimeRangeOption {
  label: string;
  value: TimeRange;
}

const timeRangeOptions: TimeRangeOption[] = [
  { label: "Last hour", value: TimeRange.T_1H },
  { label: "Last 4 hours", value: TimeRange.T_4H },
  { label: "Last 12 hours", value: TimeRange.T_12H },
  { label: "Last day", value: TimeRange.T_1D },
  { label: "Last 3 days", value: TimeRange.T_3D },
  { label: "Last 7 days", value: TimeRange.T_7D },
  { label: "Last 14 days", value: TimeRange.T_14D },
  { label: "Last 30 days", value: TimeRange.T_30D },
];

interface TimeRangeSelectorProps {
  value?: TimeRange;
  onChange?: (value: TimeRange) => void;
}

const TimeRangeSelector = ({ value = TimeRange.T_1H, onChange = (_) => {} }: TimeRangeSelectorProps) => {
  const handleChange = (event: SelectChangeEvent<TimeRange>) => {
    const selectedValue = event.target.value as TimeRange;
    onChange(selectedValue);
  };

  const label = "Time range";

  return (
    <FormControl sx={{ width: 200 }}>
      <InputLabel id="time-range-selector-label">{label}</InputLabel>
      <Select labelId="time-range-selector-label" size="small" label={label} value={value} onChange={handleChange}>
        {timeRangeOptions.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimeRangeSelector;
