import { type ReactNode, useCallback, useMemo, useState } from "react";

import AnnounceKit from "announcekit-react";

import { useAuthContext } from "../../Context/AuthContext";
import mixpanel from "../mixpanel";
import { useNotificationsPermission } from "../useNotificationsPermission";
import { announceKitContext, useAnnounceKitToken } from "./announcekitHooks";

export const AnnounceKitWrapper = ({ children }: { children: ReactNode }) => {
  const { requestNotificationPermission } = useNotificationsPermission();
  const [unread, setUnread] = useState<number>();

  const { currentUser } = useAuthContext({ mustHaveUser: true });

  const { announcekitToken } = useAnnounceKitToken();

  const openAnnounceKit = useCallback(async () => {
    await requestNotificationPermission();

    const widget = globalThis.announcekit?.widgets?.[0];
    if (widget) {
      widget.open();

      mixpanel.track("app.whatsnew");
    }
  }, [requestNotificationPermission]);

  const onWidgetUnread = useCallback(({ unread }: { unread: number }) => {
    setUnread(unread);
  }, []);

  const providerValue = useMemo(() => ({ openAnnounceKit, unread }), [openAnnounceKit, unread]);

  return (
    <announceKitContext.Provider value={providerValue}>
      <AnnounceKit
        widget="https://announcekit.app/widgets/v2/2CpiTe"
        name="notificationTrigger"
        customConfig={{
          user_token: announcekitToken,
        }}
        boosters={false}
        onWidgetUnread={onWidgetUnread}
        user={{
          id: currentUser.uid,
          email: currentUser.email,
          name: currentUser.displayName,
        }}
      >
        {children}
      </AnnounceKit>
    </announceKitContext.Provider>
  );
};
