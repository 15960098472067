import React, { useMemo } from "react";

import { type AttributionFilter } from "@doitintl/cmp-models";
import { TextField } from "@mui/material";

import { allocationTxt } from "../../../../../assets/texts/CloudAnalytics/allocation";
import { AllocationSelect } from "../../../../../Components/Selects/CloudAnalytics/AllocationSelect";
import { useAttributionsContext } from "../../../../../Context/AttributionsContext";
import { type AttributionWRef } from "../../../../../types";
import { useFullScreen } from "../../../../../utils/dialog";

type Props = {
  viewType: string;
  isEditMode: boolean;
  isSingleRule: boolean;
  values: { name: string; formula: string; filterFields: AttributionFilter[] };
  selectedExistingRule: AttributionWRef | null;
  onAllocationNameChanged: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectExistingAllocation: (value: AttributionWRef | null) => void;
  disabled?: boolean;
};

const AllocationNameField = ({
  viewType,
  isEditMode,
  isSingleRule,
  values,
  selectedExistingRule,
  onAllocationNameChanged,
  onSelectExistingAllocation,
  disabled,
}: Props) => {
  const { attributions: allocations } = useAttributionsContext();
  const managedAllocations = useMemo(() => allocations.filter((a) => a.data.type !== "managed"), [allocations]);
  const { isMobile } = useFullScreen();

  if (viewType === "new") {
    return (
      <TextField
        required
        name="name"
        label={allocationTxt.NAME_YOUR_ALLOCATION}
        sx={{ width: "100%", maxWidth: { md: 540 } }}
        margin="none"
        slotProps={{
          htmlInput: {
            sx: { backgroundColor: "background.paper" },
          },
        }}
        disabled={isMobile}
        value={values.name}
        onChange={onAllocationNameChanged}
      />
    );
  }

  if (isEditMode && isSingleRule) {
    return (
      <TextField
        required
        label={allocationTxt.NAME_YOUR_ALLOCATION}
        sx={({ palette }) => ({
          width: "100%",
          maxWidth: { md: 540 },
          backgroundColor: palette.general.backgroundDefault,
        })}
        margin="none"
        disabled={isMobile || disabled}
        value={values.name}
        onChange={onAllocationNameChanged}
      />
    );
  }

  return (
    <AllocationSelect
      allocation={selectedExistingRule}
      allocations={managedAllocations}
      sx={{ width: "100%", maxWidth: { md: 540 } }}
      onChange={onSelectExistingAllocation}
      disabled={isMobile}
    />
  );
};

export default AllocationNameField;
