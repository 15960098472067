import { useEffect } from "react";

import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers";
import { useFormikContext } from "formik";
import { DateTime } from "luxon";

import { useContractsContext } from "../../../Context/customer/ContractsContext";
import { CommitmentTable } from "../CommitmentTable";
import { defaultMarketplaceLimitPercentage } from "../consts";
import { CustomCheckbox } from "../CustomCheckbox";
import { type CommitmentPeriod } from "../types";

interface CommitmentStepValues {
  startDate: DateTime;
  numberOfPeriods: number;
  excess: boolean;
  excessValue: number;
  shortfall: boolean;
  shortfallValue: number;
  selectedContractIds: string[];
  periods: CommitmentPeriod[];
}

export const CommitmentStep = () => {
  const { values, handleChange, errors, touched, setValues } = useFormikContext<CommitmentStepValues>();
  const { contracts } = useContractsContext();

  // clear rollover if there is only one period
  useEffect(() => {
    if (values.numberOfPeriods === 1 && (values.excess || values.shortfall)) {
      setValues(
        {
          ...values,
          excess: false,
          excessValue: 0,
          shortfall: false,
          shortfallValue: 0,
        },
        false
      );
    }
  }, [values, setValues]);

  useEffect(() => {
    if (!values.periods.length && values.selectedContractIds.length === 1) {
      const selectedContract = contracts.find((contract) => contract.id === values.selectedContractIds[0]);
      const commitments = selectedContract?.commitmentPeriods;

      if (selectedContract && commitments && commitments.length > 0) {
        const newPeriods = commitments.map((commitment) => {
          const startDate = DateTime.fromSeconds(commitment.startDate.seconds);
          const endDate = DateTime.fromSeconds(commitment.endDate.seconds);
          const diffMonths = endDate.diff(startDate, "months").months;

          return {
            startDate: commitment.startDate.toDate(),
            endDate: commitment.endDate.toDate(),
            commitmentValue: commitment.value.toString(),
            periodLength: Math.floor(diffMonths),
            marketplaceLimitPercentage: defaultMarketplaceLimitPercentage,
          };
        });

        setValues(
          {
            ...values,
            numberOfPeriods: commitments.length,
            startDate: DateTime.fromSeconds(commitments[0].startDate.seconds),
            periods: newPeriods,
          },
          false
        );
      }
    }
  }, [values.selectedContractIds, contracts, setValues, values]);

  return (
    <Stack spacing={3}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: 500,
        }}
      >
        Commitment information
      </Typography>
      <Stack direction="row" spacing={1.5}>
        <DatePicker
          disableMaskedInput
          label="Start date"
          value={values.startDate}
          inputFormat="dd MMMM yyyy"
          onChange={(e) => {
            handleChange({ target: { name: "startDate", value: e } });
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              error={touched.startDate && Boolean(errors.startDate)}
              fullWidth
              required
              variant="outlined"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          )}
        />

        <FormControl
          variant="outlined"
          fullWidth
          required
          error={touched.numberOfPeriods && Boolean(errors.numberOfPeriods)}
        >
          <InputLabel>Number of periods</InputLabel>
          <Select
            label="Number of periods"
            name="numberOfPeriods"
            value={values.numberOfPeriods}
            onChange={handleChange}
            size="small"
          >
            {[...Array(5)].map((_, i) => (
              <MenuItem key={i + 1} value={i + 1}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
          {touched.numberOfPeriods && errors.numberOfPeriods && (
            <FormHelperText>{errors.numberOfPeriods}</FormHelperText>
          )}
        </FormControl>
      </Stack>
      <CommitmentTable numberOfPeriods={values.numberOfPeriods} startDate={values.startDate} />
      <Stack
        sx={{
          pt: 2,
        }}
      >
        {values.numberOfPeriods > 1 && (
          <>
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 500,
                pb: 1.5,
              }}
            >
              Commitment rollover
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
              }}
            >
              Excess
            </Typography>
            <CustomCheckbox
              checked={values.excess}
              name="excess"
              handleChange={handleChange}
              title="Yes, this plan has excess rollover"
              subtitle="Any excess will be rolled over to the next period"
              value={values.excessValue}
              errors={errors}
              touched={touched}
              required
            />

            <Typography
              variant="subtitle2"
              sx={{
                fontWeight: 500,
                pt: 2,
              }}
            >
              Shortfall
            </Typography>
            <CustomCheckbox
              checked={values.shortfall}
              name="shortfall"
              handleChange={handleChange}
              title="Yes, this plan has shortfall rollover"
              subtitle="Fill in the percentage of the shortfall that will be rolled over to the next period"
              value={values.shortfallValue}
              errors={errors}
              touched={touched}
              required
            />
          </>
        )}
      </Stack>
    </Stack>
  );
};
