import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, Button } from "@mui/material";

interface AwsConsoleConnectionProps {
  onOpenConsole: () => void;
}

const AwsConsoleConnection = ({ onOpenConsole }: AwsConsoleConnectionProps) => (
  <Box
    sx={{
      pt: 3,
    }}
  >
    <Button
      variant="contained"
      color="primary"
      startIcon={<OpenInNewIcon />}
      sx={{ mt: 3 }}
      onClick={onOpenConsole}
      data-testid="setup-cloud-diagrams-in-aws-console"
    >
      Set up Cloud Diagrams in AWS Console
    </Button>
  </Box>
);

export default AwsConsoleConnection;
