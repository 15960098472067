import React, { useEffect } from "react";

import { CurrencyCodes } from "@doitintl/cmp-models";
import { Container, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { formatCurrency } from "../../../../../utils/common";
import { useContractFormContext } from "../../ContractsFormContext";
import { isDoiTCloudIntelligence } from "../../utils";
import { DciCommitmentMonthsSelector } from "./components/DciCommitmentMonthsSelector";
import { DciDatePicker } from "./components/DciDatePicker";
import { DciSubscriptionTypeSelector } from "./components/DciSubscriptionTypeSelector";
import { DciTierSelector } from "./components/DciTierSelector";

export const DciSubscriptionStep = () => {
  const { state, setState, handleChange } = useContractFormContext();

  const isEssentials = isDoiTCloudIntelligence(state) && state.tier?.name === "essentials";

  useEffect(() => {
    if (isEssentials) {
      setState((prevState) => ({
        ...prevState,
        minimumServiceFee: undefined,
        cloudSpendPercentage: undefined,
        paymentTerm: undefined,
        errors: {
          ...prevState.errors,
          minimumServiceFee: false,
          cloudSpendPercentage: false,
          paymentTerm: false,
        },
      }));
    }
  }, [isEssentials, setState]);

  useEffect(() => {
    if (!state.isCommitment) {
      setState((prevState) => ({
        ...prevState,
        commitmentMonths: undefined,
        endDate: null,
      }));
    }
  }, [setState, state.isCommitment]);

  if (!isDoiTCloudIntelligence(state)) {
    return null;
  }

  return (
    <Container maxWidth="sm">
      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
        DoiT Cloud Intelligence™ details
      </Typography>
      <Grid container spacing={3}>
        <Grid size={12}>
          <DciTierSelector />
        </Grid>

        <Grid size={12}>
          <DciSubscriptionTypeSelector />
        </Grid>

        <Grid size={12}>
          <DciCommitmentMonthsSelector disabled={!state.isCommitment} />
        </Grid>

        <Grid size={12}>
          <DciDatePicker label="Subscription start date" fieldName="startDate" />
        </Grid>

        <Grid size={12}>
          <DciDatePicker
            label="Subscription end date"
            fieldName="endDate"
            disabled={state.commitmentMonths !== -1}
            minDate={state.startDate}
          />
        </Grid>

        <Grid container size={12}>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{
              flexGrow: 1,
            }}
          >
            <TextField
              select
              label="Currency"
              value={state.currency}
              onChange={handleChange("currency")}
              disabled={isEssentials}
              sx={{ flexBasis: "112px" }}
            >
              <MenuItem value={CurrencyCodes.USD}>{CurrencyCodes.USD}</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Minimum service fee"
              value={state.minimumServiceFee ?? ""}
              onChange={handleChange("minimumServiceFee")}
              disabled={isEssentials}
              error={state.errors.minimumServiceFee}
              type="number"
            />
          </Stack>
        </Grid>

        <Grid size={12}>
          <TextField
            fullWidth
            label="Monthly cloud spend percentage"
            value={state.cloudSpendPercentage ?? ""}
            onChange={handleChange("cloudSpendPercentage")}
            type="number"
            disabled={isEssentials}
            error={state.errors.cloudSpendPercentage}
            slotProps={{
              input: {
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              },
            }}
          />
        </Grid>
      </Grid>

      <Grid
        sx={{
          mt: 2,
        }}
      >
        <Typography variant="subtitle2">Monthly sales price:</Typography>
        <Typography variant="body2">
          {isEssentials && formatCurrency(0, state.currency, 0)}
          {!!state.minimumServiceFee && formatCurrency(state.minimumServiceFee, state.currency, 0)}
          {!!state.minimumServiceFee && !!state.cloudSpendPercentage && " OR "}
          {!!state.cloudSpendPercentage && `${state.cloudSpendPercentage}% of the monthly cloud spend`}
        </Typography>
        {!!state.minimumServiceFee && !!state.cloudSpendPercentage && (
          <Typography
            sx={{
              color: "text.secondary",
              fontSize: 12,
              lineHeight: "20px",
            }}
          >
            Whichever is greater will be billed to the customer
          </Typography>
        )}
      </Grid>
    </Container>
  );
};
