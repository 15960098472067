import { useMemo } from "react";

import {
  AmazonWebServicesAssetModel,
  type CloudConnectAmazonWebServices,
  CustomerModel,
} from "@doitintl/cmp-models/src";
import { getCollection, useCollectionData } from "@doitintl/models-firestore/src";
import { type AxiosInstance } from "axios";

import { useCustomerContext } from "../../Context/CustomerContext";
import type { InsightMissingPermissionsResponse, InsightResultsResponse, ServerInsight } from "./types";

// TODO: This file should only consist of functions with api calls and relevant functionality
// Hooks (including useQuery) and utility functions should be moved to separate files

export const createInsight = async ({ api, insight }) => api.post(`/insights/results`, insight);

type GetInsightsProps = {
  customerId: string;
};

export const getInsights = async (api: AxiosInstance, { customerId }: GetInsightsProps) => {
  const response = await api.get<InsightResultsResponse<ServerInsight>>("insights/results", {
    params: { customerID: customerId },
  });

  return response.data;
};

export const getInsightsMissingPermissions = async (api: AxiosInstance, { customerId }: GetInsightsProps) => {
  const response = await api.get<InsightMissingPermissionsResponse>("insights/missing-permissions", {
    params: { customerID: customerId },
  });

  return response.data;
};

export const useAccounts = () => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();
  const queryCloudConnect = getCollection(CustomerModel)
    .doc(customerOrPresentationModeCustomer.id)
    .collection("cloudConnect")
    .where("cloudPlatform", "==", "amazon-web-services")
    .narrow<CloudConnectAmazonWebServices>();

  return useCollectionData(queryCloudConnect);
};

const useCustomerAssets = () => {
  const { customerOrPresentationModeCustomer } = useCustomerContext();

  const customer = getCollection(CustomerModel).doc(customerOrPresentationModeCustomer.id);

  const queryAssets = getCollection(AmazonWebServicesAssetModel)
    .where("customer", "==", customer)
    .where("type", "==", "amazon-web-services")
    .narrow<AmazonWebServicesAssetModel>();

  return useCollectionData(queryAssets);
};

export const useCustomerPayerAccounts = () => {
  const [assets] = useCustomerAssets();

  const payerAccounts = useMemo(() => {
    if (!assets) return [];

    return Array.from(
      new Set(
        assets
          .filter((asset) => asset.properties.organization?.payerAccount !== undefined)
          .map((asset) => asset.properties.organization!.payerAccount.id)
      )
    );
  }, [assets]);

  return payerAccounts;
};

export const sendUpsellUpgradeNotification = async ({ api, customerID }) =>
  api.post(`/insights/notification/upsell-upgrade?customerID=${customerID}`);
