import * as Yup from "yup";

import { type CreateBillingProfileData } from "../BillingProfileForm.models";
import { BillingForm, type BillingFormSubmitFn } from "./BillingForm";
import { verifyTaxId } from "./utils";

const EMPTY_BILLING_PROFILE: CreateBillingProfileData = {
  address: "",
  state: "",
  stateCode: "",
  zip: "",
  country: undefined,
  city: "",

  companyName: "",
  taxId: "",
  vatRegistrationType: null,

  firstName: "",
  lastName: "",
  email: "",
  phone: "",

  currency: "",
};

const addressRegExp = /^[\p{L}\p{N}\s,.'-]+$/u;
const cityRegExp = /^[\p{L}\s,.'-]+$/u;
const nameRegExp = /^[\p{L}\s'-]+$/u;
const phoneRegExp = /^(\+?\d{1,3}[\s\-()]*)?[\d\s\-()]*?(?:\s*(?:ext\.?|x\.?|#)\s*\d+)?$/;
const zipRegExp = /^[A-Za-z0-9\s-]+$/;

export const createBillingProfileFromValidationSchema: Yup.ObjectSchema<CreateBillingProfileData> = Yup.object({
  country: Yup.string().required("Country is required"),
  address: Yup.string()
    .trim()
    .matches(addressRegExp, "Invalid address format")
    .max(100, "Address is too long")
    .required("Address is required"),
  zip: Yup.string()
    .required("Zip code is required")
    .matches(zipRegExp, "Invalid zip code format")
    .min(3, "Zip code is too short")
    .max(15, "Zip code is too long"),
  city: Yup.string()
    .trim()
    .matches(cityRegExp, "Invalid city format")
    .max(50, "City name is too long")
    .nullable()
    .default(null),
  state: Yup.string().trim().nullable().default(null),
  stateCode: Yup.string().trim().nullable().default(null),

  companyName: Yup.string().trim().required("Company name is required"),
  taxId: Yup.string()
    .trim()
    .test("is-valid-tax-id", "Must be a valid Tax ID", (_value, testContext) => verifyTaxId(testContext.parent)),
  vatRegistrationType: Yup.string()
    .nullable()
    .when("country", {
      is: "Israel",
      then: (schema) => schema.oneOf(["1", "2", "3"], "Invalid VAT registration type for Israel"),
    })
    .default(null),

  firstName: Yup.string()
    .trim()
    .matches(nameRegExp, "Invalid first name format")
    .max(50, "First name is too long")
    .required("First name is required"),

  lastName: Yup.string()
    .trim()
    .matches(nameRegExp, "Invalid last name format")
    .max(50, "Last name is too long")
    .required("Last name is required"),
  email: Yup.string().email().max(100, "Email is too long").required("Email is required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .max(25, "Phone number is too long")
    .required("Phone number is required"),
  currency: Yup.string().required("Currency is required"),
});

export type CreateBillingProfileFormProps = { onSubmit: BillingFormSubmitFn<CreateBillingProfileData> };

export const CreateBillingProfileForm = ({ onSubmit }: CreateBillingProfileFormProps) => (
  <BillingForm
    initialValues={EMPTY_BILLING_PROFILE}
    onSubmit={onSubmit}
    validationSchema={createBillingProfileFromValidationSchema}
  />
);
