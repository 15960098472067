import { Category, type CloudProviderName, type CloudProviderTag } from "./types";

export const ALL_CLOUD_PROVIDERS: CloudProviderTag[] = ["aws", "gcp", "azure", "snowflake"];

export const CLOUD_PROVIDERS_TITLES_MAP: Record<CloudProviderTag, CloudProviderName> = {
  aws: "Amazon Web Services",
  gcp: "Google Cloud",
  azure: "Azure",
  snowflake: "Snowflake",
};

export const ALL_CATEGORIES: Category[] = Object.values(Category);
