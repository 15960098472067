import { useRef } from "react";

import { Avatar, Badge, Box, Card, CardContent, Link, Tooltip, Typography } from "@mui/material";
import clsx from "clsx";
import { type Hit } from "instantsearch.js";

import SlackIcon from "../../../assets/slack-mark.svg";
import { Person } from "./Person";
import { type HighlightResult, type PersonData } from "./types/types";
import { timezoneToCountry } from "./utils/helpers";
import { categorizeSkills, HighlightedText, renderAllSkills, renderCategorizedSkills } from "./utils/personcardUtils";
import { useStyles } from "./utils/styles";

type PersonHit = Hit<PersonData>;

type PersonCardProps = {
  hit: PersonHit;
  isExpanded: boolean;
  onExpand: () => void;
};

export const PersonCard = ({ hit, isExpanded, onExpand }: PersonCardProps) => {
  const classes = useStyles({});
  const person = new Person(hit);
  const skillsBoxRef = useRef<HTMLDivElement>(null);

  // Convert all skills to HighlightResult format
  const specialtySkillsWithHighlight = person.specialitySkills
    .map((skill) => {
      // Check if this skill is in the highlighted list
      const highlightedSkill = person.highlightedSpecialitySkills.find(
        (hs) => hs.value.replace(/<\/?mark>/g, "") === skill
      );

      return {
        value: highlightedSkill ? highlightedSkill.value : skill,
        matchLevel: highlightedSkill ? ("full" as const) : ("none" as const),
        matchedWords: highlightedSkill?.matchedWords || [],
        type: "specialty" as const,
      };
    })
    .sort((a, b) => {
      // Sort highlighted skills to the top
      if (a.matchLevel === "full" && b.matchLevel !== "full") return -1;
      if (a.matchLevel !== "full" && b.matchLevel === "full") return 1;
      return a.value.localeCompare(b.value); // Secondary sort by name
    });

  const allSkills: (HighlightResult & { type?: "primary" | "secondary" | "specialty" })[] = [
    ...specialtySkillsWithHighlight,
    ...person.primarySkills.map((skill) => ({
      value: skill,
      matchLevel: "none" as const,
      matchedWords: [],
      type: "primary" as const,
    })),
    ...person.secondarySkills.map((skill) => ({
      value: skill,
      matchLevel: "none" as const,
      matchedWords: [],
      type: "secondary" as const,
    })),
  ];

  // Show EXPERT badge if:
  // - Person has specialty skills AND
  // - Either there are no highlighted skills OR there are highlighted specialty skills
  const shouldShowExpertBadge =
    person.hasSpecialtySkills && (!person.hasHighlightedSkills || person.hasHighlightedSpecialitySkill);

  const categorizedSkills = categorizeSkills(person.highlightedSkills as HighlightResult[]);
  const skillTypeOrder = ["service", "category", "platform", "activity", "pro-serv"] as const;
  const specialtySkills = person.highlightedSkills.filter((skill) => skill.type === "specialty");
  const remainingSkills = skillTypeOrder.flatMap((skillType) =>
    categorizedSkills[skillType]?.filter(
      (skill) =>
        !specialtySkills.some(
          (specialtySkill) => specialtySkill.value.replace(/<\/?mark>/g, "") === skill.value.replace(/<\/?mark>/g, "")
        )
    )
  );

  const handleCardClick = () => {
    onExpand();
  };

  return (
    <>
      {isExpanded && (
        <button
          type="button"
          className={classes.backdrop}
          onClick={() => {
            onExpand();
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              e.preventDefault();
              onExpand();
            }
          }}
          aria-label="Close expanded view"
        />
      )}
      <Box position="relative">
        {shouldShowExpertBadge && !isExpanded && (
          <Box className={classes.expertBadge} data-cy="specialist-badge">
            SPECIALIST
          </Box>
        )}
        <Card
          className={clsx(classes.personCard, {
            [classes.clickableCard]: !isExpanded,
            [classes.expandedPersonCard]: isExpanded,
          })}
          sx={{
            height: isExpanded ? "auto" : "300px",
          }}
          data-cy={isExpanded ? "person-card-expanded" : "person-card-small"}
        >
          <CardContent
            onClick={handleCardClick}
            className={classes.personCardContent}
            sx={{
              height: isExpanded ? "auto" : "300px",
            }}
            data-cy={isExpanded ? "person-card-content-expanded" : "person-card-content-small"}
          >
            <Box className={classes.personInfoBox}>
              <Box className={classes.personHeaderBox}>
                <Box className={classes.avatarContainer}>
                  <Badge
                    overlap="circular"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    variant="dot"
                    className={`${classes.badge} ${person.isAvailable ? classes.available : classes.unavailable}`}
                    badgeContent={person.titleEmoji}
                  >
                    <Avatar className={classes.avatar} src={person.avatar} />
                  </Badge>
                  <Tooltip
                    title={(timezoneToCountry as Record<string, string>)[person.timezone] ?? person.timezone}
                    arrow
                  >
                    <Box className={classes.timezoneBox}>
                      <Typography variant="body2" className={classes.countryEmoji}>
                        {person.countryFlagEmoji}
                      </Typography>
                    </Box>
                  </Tooltip>
                </Box>
                <Box className={classes.personInfoContent}>
                  <Typography variant="h6" component="div" className={classes.nameText}>
                    <Link
                      href={`https://doitintl.zendesk.com/agent/users/${person.zendeskId}/`}
                      className={classes.nameLink}
                      data-cy={isExpanded ? "person-name-link-expanded" : "person-name-link-small"}
                    >
                      <HighlightedText
                        text={person.name}
                        highlightedValue={person._highlightResult?.name?.value as string}
                      />
                    </Link>
                  </Typography>
                  <Typography variant="subtitle1" className={classes.titleText}>
                    {person.title}
                  </Typography>
                  <Box className={classes.slackBox}>
                    <Link href={`slack://user?team=T2TG0KM5E&id=${person.slack}`} target="_blank">
                      <Box component="img" src={SlackIcon as string} className={classes.slackIcon} />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box
                className={classes.skillsBox}
                ref={skillsBoxRef}
                data-cy={isExpanded ? "person-skills-section-expanded" : "person-skills-section-small"}
              >
                {isExpanded
                  ? renderAllSkills(person, allSkills, classes)
                  : person.hasHighlightedSkills && renderCategorizedSkills(specialtySkills, remainingSkills, classes)}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};
