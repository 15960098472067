import { useEffect, useState } from "react";

import assign from "lodash/assign";

import { type MetadataOption } from "../../../types";
import { useAnalyticsDimensions } from "../../hooks/cloudAnalytics/useAnalyticsDimensions/useAnalyticsDimensions";
import { useCloudAnalyticsMetadata } from "../../hooks/cloudAnalytics/useCloudAnalyticsMetadata";

export const useMetadata = () => {
  const { metadata: metadataSnapshots, fetchMetadata } = useCloudAnalyticsMetadata();

  useEffect(() => {
    fetchMetadata();
  }, [fetchMetadata]);

  return metadataSnapshots;
};

export const useDimension = (dimensionId: string) => {
  const metadataSnapshots = useMetadata();
  const { dimensions, getLabelsDimensions } = useAnalyticsDimensions({
    metadataSnapshots,
  });
  const [dimensionWithLabels, setDimensionWithLabels] = useState<MetadataOption | null>(null);

  useEffect(() => {
    const fetchDimensionWithLabels = async () => {
      if (!dimensions) return;

      const dimension = dimensions.find((m) => m.id === dimensionId);
      if (!dimension) {
        setDimensionWithLabels(null);
        return;
      }

      if (dimension.data.values?.length) {
        setDimensionWithLabels(dimension);
        return;
      }

      const { newLabels } = await getLabelsDimensions([{ key: dimension.data.key, type: dimension.data.type }]);
      if (newLabels.length > 0) {
        const updatedOption = assign(dimension, newLabels[0]);
        setDimensionWithLabels(updatedOption);
      } else {
        setDimensionWithLabels(dimension);
      }
    };

    fetchDimensionWithLabels();
  }, [dimensions, dimensionId, getLabelsDimensions]);

  return dimensionWithLabels;
};
