import { useState } from "react";

import { useTranslation } from "react-i18next";
import {
  type EntitlementsAccess,
  type KnownIssuePlatform,
  type SupportServiceModel,
  type ZendeskPlatformModel,
} from "@doitintl/cmp-models";
import { Alert, Box, Button } from "@mui/material";

import { essentialsEscalatePrice } from "../../../constants";
import { productAlertList } from "../../consts";
import { useIsDCIEssentialsTier } from "../../hooks/useIsDCIEssentialsTier";
import { type Severity } from "../../types";
import KnownIssuesList from "../KnownIssuesList";
import SelectCategory from "../SelectCategory";
import { ContentHeader } from "../Stepper/ContentHeader";
import SupportPlatformDropdown from "../SupportPlatformDropdown";
import { TermsAgreementDialog } from "../TermsAgreementDialog";

type ProductStepProps = {
  handleChangePlatform: (data: React.ChangeEvent<Element>) => void;
  platforms: ZendeskPlatformModel[];
  sauronRole: boolean;
  categories: SupportServiceModel[];
  platform: string;
  error: boolean;
  helperText: string;
  handleChangeCategory: (event: React.ChangeEvent<{ value: unknown }>) => void;
  selectedPlatform: ZendeskPlatformModel;
  category: string;
  changeCategoryInfo: (event: React.ChangeEvent<{ value: unknown }>) => void;
  categoryInfo: string;
  categoryInfoList: { asset: string }[];
  knownIssues: { platform: KnownIssuePlatform; title: string; docId: string }[];
  supportEntitlementsCheck: EntitlementsAccess;
  setCurrentStep: (step: number) => void;
  billingProfile: string;
  setBillingProfile: (profile: string | null) => void;
  handleChangePriority: (priority: Severity) => void;
};

const ProductStep = ({
  handleChangePlatform,
  platforms,
  categories,
  platform,
  error,
  helperText,
  handleChangeCategory,
  selectedPlatform,
  category,
  changeCategoryInfo,
  categoryInfo,
  categoryInfoList,
  knownIssues,
  setCurrentStep,
  billingProfile,
  setBillingProfile,
}: ProductStepProps) => {
  const { t } = useTranslation("services");
  const [open, setOpen] = useState(false);
  const isDCIEssentials = useIsDCIEssentialsTier();

  const shouldShowAlert = productAlertList.includes(selectedPlatform?.value);

  return (
    <Box sx={{ pl: 1 }}>
      <ContentHeader title={t("ProductStep.title")} />
      <Box sx={{ mt: 4 }}>
        <SupportPlatformDropdown
          platform={platform}
          platformsList={platforms}
          handleChangePlatformField={handleChangePlatform}
          error={error && !platform}
        />
        <SelectCategory
          helperText={helperText}
          handleChangeCategory={handleChangeCategory}
          platforms={platforms}
          selectedPlatform={selectedPlatform}
          category={category ? category : null}
          categories={categories}
          changeCategoryInfo={changeCategoryInfo}
          categoryInfo={categoryInfo}
          error={error}
          categoryInfoList={categoryInfoList}
        />
        <Box
          sx={{
            mt: 5,
            mb: 5,
          }}
        >
          <KnownIssuesList platform={platform} issues={knownIssues} />
          {shouldShowAlert && isDCIEssentials && (
            <Alert
              severity="info"
              sx={{ ".MuiAlert-action": { minWidth: "fit-content", alignItems: "center" }, mt: 2 }}
              action={
                <Button
                  disabled={category === null}
                  variant="text"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  {t("ProductStep.dciEssentialsAlertAction")}
                </Button>
              }
            >
              {t("ProductStep.dciEssentialsAlert", { price: essentialsEscalatePrice })}
            </Alert>
          )}
        </Box>
      </Box>
      <TermsAgreementDialog
        open={open}
        onClose={() => {
          setBillingProfile(null);
          setOpen(false);
        }}
        onConfirm={() => {
          setOpen(false);
          setCurrentStep(1);
        }}
        billingProfile={billingProfile}
        setBillingProfile={setBillingProfile}
      />
    </Box>
  );
};

export default ProductStep;
