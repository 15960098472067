import { type Cluster, ClusterDiscoveryStatus, ClusterType } from "@doitintl/cmp-models";
import { CircularProgress } from "@mui/material";

import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { CLUSTER_PAGE_MESSAGES } from "../../messages";

interface ClusterThreeDotsMenuProps {
  cluster: Cluster;
  onTestConnection?: () => Promise<void>;
  onActivateCluster?: () => Promise<void>;
  onDeactivateCluster?: () => Promise<void>;
  onDeleteCluster?: () => void;
  onEditCluster?: () => void;
  onInstallAgent?: () => void;
  loading?: boolean;
}

const ClusterThreeDotsMenu = ({
  cluster,
  onTestConnection,
  onActivateCluster,
  onDeactivateCluster,
  onDeleteCluster,
  onEditCluster,
  onInstallAgent,
  loading = false,
}: ClusterThreeDotsMenuProps) => {
  const clusterIsDeleted = cluster.discoveryStatus === ClusterDiscoveryStatus.DELETED;

  const getOptionParameters = () => {
    if (clusterIsDeleted) {
      return {
        tooltip: CLUSTER_PAGE_MESSAGES.DELETED_CLUSTER,
        disabled: true,
      };
    }

    if (cluster.isPrivate) {
      return {
        tooltip: CLUSTER_PAGE_MESSAGES.PRIVATE_CLUSTER,
        disabled: true,
      };
    }

    return {
      disabled: false,
    };
  };

  const options: ThreeDotsMenuOption[] = [
    {
      label: "Install agent",
      key: "install-agent",
      action: onInstallAgent,
    },
    ...(cluster.isActivated
      ? [
          {
            ...getOptionParameters(),
            label: "Deactivate",
            key: "deactivate-cluster",
            action: onDeactivateCluster,
          },
          {
            ...getOptionParameters(),
            label: "Test connection",
            key: "test-cluster-connection",
            action: onTestConnection,
          },
        ]
      : [
          {
            ...getOptionParameters(),
            label: "Activate",
            key: "activate-cluster",
            action: onActivateCluster,
          },
        ]),
  ];

  if (cluster.isActivated && cluster.type === ClusterType.GKE) {
    options.push({
      ...getOptionParameters(),
      label: "Enable features",
      key: "enable-features",
      action: onActivateCluster,
    });
  }

  if (cluster.type === ClusterType.SELF_MANAGED) {
    options.push(
      {
        label: "Delete",
        key: "delete-cluster",
        action: onDeleteCluster,
      },
      {
        label: "Edit",
        key: "edit-cluster",
        action: onEditCluster,
      }
    );
  }

  options.forEach((option) => {
    option.tooltipPlacement = "left";
    option.dataCy = option.dataCy ?? option.key;
  });

  if (loading) {
    return <CircularProgress size={24} />;
  }

  return <ThreeDotsMenu options={options} size="small" closeAfterSelect />;
};

export default ClusterThreeDotsMenu;
