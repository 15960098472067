import { CollectionModel, type CollectionModelInvoiceReminderModel, InAppNotificationType } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";

import { type Mapper } from "../types";

export class InvoiceReminder implements Mapper<CollectionModelInvoiceReminderModel> {
  readonly type = InAppNotificationType.InvoiceReminder;

  getCollection: Mapper<CollectionModelInvoiceReminderModel>["getCollection"] = (customerRef, startTime) =>
    getCollection(CollectionModel)
      .doc("invoice-reminders")
      .collection("invoiceReminders")
      .where("customer", "==", customerRef)
      .where("date", ">", startTime)
      .where("reminderNumber", "==", 1) // a week before the due date
      .orderBy("date", "desc");

  userHasPermission: Mapper<CollectionModelInvoiceReminderModel>["userHasPermission"] = (userRoles) =>
    userRoles?.invoicesViewer ?? false;

  toNotification: Mapper<CollectionModelInvoiceReminderModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.InvoiceReminder,
      created: docData.date,
      updated: undefined,
      title: "Invoices will be due soon",
      description: "Here's a friendly reminder that charges for invoices will be due soon.",
      urlPath: `/customers/${docData.customer.id}/invoices`,
      color: "#FFF7CF",
    };
  };
}
