import { useCallback, useEffect, useMemo, useState } from "react";

import { type SupportZendeskTicketModel } from "@doitintl/cmp-models";

import { platformsData } from "../consts";
import { type PlatformFilter, type PlatformFilterInfo } from "../types";
import { getPlatform } from "../utils";

export type FirestoreFilterCloudResult = {
  selectedPlatformFilter: PlatformFilter[];
  onClickSupportFilter: (filter: PlatformFilter[]) => void;
  filteredTicketsFirestore: SupportZendeskTicketModel[];
  supportPlatformsAvailable: PlatformFilterInfo[];
};

/**
 * Hook for filtering support tickets from firestore.
 * @param currentTicketList - The list of support tickets to filter.
 * @returns The filtered tickets and the selected platform filter.
 */
export const useFilterCloudFirestore = (
  currentTicketList?: SupportZendeskTicketModel[]
): FirestoreFilterCloudResult => {
  const [selectedPlatformFilter, setSelectedPlatformFilter] = useState<PlatformFilter[]>([]);
  const [filteredTicketsFirestore, setFilteredTicketsFirestore] = useState<SupportZendeskTicketModel[]>(
    currentTicketList ?? []
  );

  useEffect(() => {
    setFilteredTicketsFirestore(currentTicketList ?? []);
  }, [currentTicketList]);

  const filterTickets = useCallback(
    (filter: PlatformFilter[]) => {
      if (!currentTicketList?.length) {
        return; // nothing to filter
      }

      // only filter if there's a criteria to filter for
      if (filter.length > 0) {
        const filterTickets = currentTicketList.filter((zdt) => filter.find((product) => product === getPlatform(zdt)));
        setFilteredTicketsFirestore(filterTickets);
      } else {
        setFilteredTicketsFirestore(currentTicketList);
      }
    },
    [currentTicketList]
  );

  const supportPlatformsAvailable = useMemo(() => {
    if (!currentTicketList?.length) {
      return [];
    }

    const products = new Set<PlatformFilterInfo>();

    currentTicketList.forEach((zdt) => {
      const productType = getPlatform(zdt);
      if (productType) {
        const product = platformsData.find((item) => item.value === productType);
        if (product) {
          products.add(product);
        }
      }
    });
    return Array.from(products);
  }, [currentTicketList]);

  useEffect(() => {
    filterTickets(selectedPlatformFilter);
  }, [filterTickets, selectedPlatformFilter]);

  const onClickSupportFilter = (filter: PlatformFilter[]) => {
    setSelectedPlatformFilter(filter);
    filterTickets(filter);
  };
  return { selectedPlatformFilter, onClickSupportFilter, filteredTicketsFirestore, supportPlatformsAvailable };
};
