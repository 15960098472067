import { Box } from "@mui/material";

interface ClusterStatusDescriptionProps {
  featureIsHealthy: boolean;
  issues: string[];
}

const ClusterStatusDescription = ({ featureIsHealthy, issues }: ClusterStatusDescriptionProps) => {
  if (featureIsHealthy && !issues.length) {
    return "Successfully activated";
  }

  return (
    <Box sx={{ maxHeight: 250, overflow: "auto" }}>
      <span>Issues:</span>
      <ol>{issues?.map((issue, index) => <li key={index}>{issue}</li>)}</ol>
    </Box>
  );
};

export default ClusterStatusDescription;
