import { type KubernetesFeature } from "@doitintl/cmp-models";
import { Box, Checkbox, Stack, TableCell, Typography } from "@mui/material";

import { CollapsableTableRow } from "../../../../../../Components/CollapsableTableRow";
import FeaturePermissions from "./FeaturePermissions";

interface Fsk8sFeatureRowProps {
  feature: KubernetesFeature;
  selectedFeatures: KubernetesFeature[];
  onToggleFeature: (featureId: string) => void;
}

const Fsk8sFeatureRow = ({ feature, onToggleFeature, selectedFeatures }: Fsk8sFeatureRowProps) => {
  const hiddenContent = (
    <Box m={1}>
      <FeaturePermissions feature={feature} />
    </Box>
  );

  const handleChange = () => {
    onToggleFeature(feature.id);
  };

  const isChecked = selectedFeatures.map((f) => f.id).includes(feature.id);

  return (
    <CollapsableTableRow hiddenContent={hiddenContent}>
      <TableCell component="th" scope="row">
        <Stack>
          <Typography variant="body2">{feature.name}</Typography>
          <Typography variant="caption" color="text.secondary">
            {feature.description}
          </Typography>
        </Stack>
      </TableCell>
      <TableCell padding="none">
        <Checkbox color="primary" checked={isChecked} onChange={handleChange} />
      </TableCell>
    </CollapsableTableRow>
  );
};

export default Fsk8sFeatureRow;
