import { type Cluster } from "@doitintl/cmp-models";
import * as yup from "yup";

import { type FormField } from "../../../../Components/FormItem";
import { type CreateClusterPayload, type TestClusterConnectionPayload, type UpdateClusterPayload } from "../../api";

export interface ClusterFormValues {
  name: string;
  region: string;
  host: string;
  caCert: string;
  serviceAccountKey: string;
}

const defaultInitialValues: ClusterFormValues = {
  name: "",
  region: "",
  host: "",
  caCert: "",
  serviceAccountKey: "",
};

const getFields = (cluster?: Cluster): FormField[] => {
  const isEditMode = !!cluster;

  return [
    {
      name: "name",
      disabled: isEditMode,
      required: true,
    },
    {
      name: "region",
      placeholder: "Optional. You can use this for your own organization purposes if you want.",
      disabled: isEditMode,
    },
    {
      name: "host",
      required: true,
    },
    {
      name: "caCert",
      label: "Cluster CA certificate",
      multiline: true,
      minRows: 5,
      maxRows: 10,
      required: true,
    },
    {
      name: "serviceAccountKey",
      label: "Service account token",
      required: !isEditMode,
      placeholder: isEditMode ? "Leave empty to keep the existing token." : undefined,
    },
  ];
};

const getSchema = (cluster?: Cluster) => {
  const isEditMode = !!cluster;

  return yup.object().shape({
    name: yup
      .string()
      .when([], {
        is: () => !isEditMode,
        then: (schema) => schema.required("Name is required."),
      })
      .min(3, "Name must be at least 3 characters.")
      .max(50, "Name must be at most 50 characters."),

    region: yup
      .string()
      .matches(/^[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+)*$/, "Invalid region. Must be in the format 'us-west-2'.")
      .max(50, "Region must be at most 50 characters."),

    host: yup
      .string()
      .when([], {
        is: () => !isEditMode,
        then: (schema) => schema.required("Host is required."),
      })
      .min(3, "Host must be at least 3 characters.")
      .max(255, "Host must be at most 255 characters."),

    caCert: yup
      .string()
      .when([], {
        is: () => !isEditMode,
        then: (schema) => schema.required("Cluster CA certificate is required."),
      })
      .matches(/-----BEGIN CERTIFICATE-----[\s\S]+?-----END CERTIFICATE-----/, "Invalid cluster CA cert.")
      .max(8192, "Cluster CA certificate must be at most 8192 characters."),

    serviceAccountKey: yup
      .string()
      .when([], {
        is: () => !isEditMode,
        then: (schema) => schema.required("Service account token is required."),
      })
      .matches(
        /^[A-Za-z0-9-_]+?\.[A-Za-z0-9-_]+?\.[A-Za-z0-9-_]+$/,
        "Invalid service account token. Can only contain letters, digits, hyphens, and underscores."
      )
      .max(4096, "Service account token must be at most 4096 characters."),
  });
};

const getInitialValues = (cluster?: Cluster): ClusterFormValues => {
  const isEditMode = !!cluster;

  if (!isEditMode) {
    return defaultInitialValues;
  }

  return {
    name: cluster.name,
    region: cluster.region,
    host: cluster.host,
    caCert: atob(cluster.caCertBase64),
    serviceAccountKey: "",
  };
};

export const CLUSTER_FORM = {
  getFields,
  getSchema,
  getInitialValues,
};

export const getCreateClusterPayload = (values: ClusterFormValues): CreateClusterPayload => ({
  host: values.host,
  name: values.name,
  region: values.region,
  serviceAccountKey: values.serviceAccountKey,
  caCertBase64: btoa(values.caCert),
});

export const getUpdateClusterPayload = (values: ClusterFormValues): UpdateClusterPayload => ({
  caCertBase64: btoa(values.caCert),
  serviceAccountKey: values.serviceAccountKey,
  host: values.host,
});

export const getTestClusterConnectionPayload = (
  values: ClusterFormValues,
  clusterId?: string
): TestClusterConnectionPayload => ({
  caCertBase64: btoa(values.caCert),
  host: values.host,
  token: values.serviceAccountKey,
  clusterId,
});

export const getTrimmedValues = (values: ClusterFormValues): ClusterFormValues =>
  Object.fromEntries(
    Object.entries(values).map(([k, v]) => [k, typeof v === "string" ? v.trim() : v])
  ) as ClusterFormValues;
