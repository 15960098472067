import { Tab, Tabs, Tooltip } from "@mui/material";

import { cloudflowTexts } from "../../../../../assets/texts";
import { type ConfigurationTab } from "../../../types";
import { useCloudflowState } from "../../Common/hooks/useCloudflowState";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

export const TabNavigation = ({ tabs }: { tabs: ConfigurationTab[] }) => {
  const { activeTab, setActiveTab } = useNodeConfigurationContext();
  const { isBlueprint, isDemoFlow } = useCloudflowState();
  const handleTabChange = (event: React.SyntheticEvent, newValue: ConfigurationTab) => {
    setActiveTab(newValue);
  };

  return (
    <Tabs
      value={activeTab}
      onChange={handleTabChange}
      variant="fullWidth"
      sx={{
        borderBottom: 1,
        borderColor: "divider",
        ".MuiTab-root": {
          textTransform: "none",
        },
      }}
    >
      {tabs.map((tab) => {
        const isDisabled = tab === "Test" && (isBlueprint || isDemoFlow);

        return isDisabled ? (
          <Tooltip key={tab} title={cloudflowTexts.DISABLED_TEST_TOOLTIP} arrow>
            <span>
              <Tab label={tab} value={tab} disabled />
            </span>
          </Tooltip>
        ) : (
          <Tab key={tab} label={tab} value={tab} />
        );
      })}
    </Tabs>
  );
};
