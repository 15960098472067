import { useState } from "react";

import { useHistory } from "react-router-dom";
import { type CommitmentManagersModel } from "@doitintl/cmp-models";
import { type FirebaseModelReference } from "@doitintl/models-firestore/src/core";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";

import { ThreeDotsMenu } from "../../Components/ThreeDotsMenu";

interface CommitmentManagerHeaderProps {
  docRef?: FirebaseModelReference<CommitmentManagersModel>;
  name?: string;
  fromYear?: number;
  toYear?: number;
  periods?: CommitmentManagersModel["periods"];
  selectedPeriod: number;
  onPeriodChange: (newValue: number) => void;
}

export const CommitmentManagerHeader = ({
  name,
  docRef,
  periods = [],
  selectedPeriod,
  onPeriodChange,
}: CommitmentManagerHeaderProps) => {
  const history = useHistory();
  const pathname = history.location.pathname.split("/").slice(0, -1).join("/");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const handleDeleteConfirm = async () => {
    if (docRef) {
      await docRef.delete();
      history.push(pathname);
    }
    setIsDeleteDialogOpen(false);
  };

  const handleDeleteCancel = () => {
    setIsDeleteDialogOpen(false);
  };

  const threeDotsMenuOptions = [
    {
      label: "Edit commitment manager",
      key: "edit-commitment-manager",
      action: () => {
        const commitmentId = history.location.pathname.split("/").pop();
        history.push(`${pathname}/${commitmentId}/edit`);
      },
    },
    {
      label: "Delete commitment manager",
      key: "delete-commitment-manager",
      action: () => {
        setIsDeleteDialogOpen(true);
      },
    },
  ];

  const handlePeriodChange = (event: SelectChangeEvent<number>) => {
    onPeriodChange(event.target.value as number);
  };

  return (
    <>
      <Stack
        direction={{ xs: "column", sm: "row" }}
        sx={{
          alignItems: "flex-start",
          mb: 2,
          mt: 2,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Stack direction="row" alignItems="center">
          <IconButton
            aria-label="Back"
            onClick={() => {
              history.push(pathname);
            }}
            size="small"
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography
            variant="h1"
            sx={{
              ml: 1,
              mr: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {name}
          </Typography>
          <ThreeDotsMenu options={threeDotsMenuOptions} size="medium" closeAfterSelect onClick={() => {}} />
        </Stack>

        {periods.length > 1 && (
          <Select value={selectedPeriod} onChange={handlePeriodChange} sx={{ minWidth: 300 }}>
            {periods.map((period, index) => (
              <MenuItem key={index} value={index}>
                {`Period ${index + 1} (${period.startDate.toDate().toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })} - ${period.endDate.toDate().toLocaleDateString(undefined, {
                  day: "2-digit",
                  month: "short",
                  year: "numeric",
                })})`}
              </MenuItem>
            ))}
          </Select>
        )}
      </Stack>

      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="delete-dialog-title"
        aria-describedby="delete-dialog-description"
      >
        <DialogTitle id="delete-dialog-title">Delete Commitment Manager</DialogTitle>
        <DialogContent>
          <DialogContentText id="delete-dialog-description">
            Are you sure you want to delete this commitment manager? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleDeleteCancel}>Cancel</Button>
          <Button onClick={handleDeleteConfirm} color="error" variant="contained" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
