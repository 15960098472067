import { type ChipProps } from "@mui/material";
import { type TFunction } from "i18next";

import { defaultHeaderStyle, standardToOption } from "../../../Components/FilterTable/Table/FilterTableHeader";
import { Comparators, type DataColumn, type HeaderColumn } from "../../../types/FilterTable";
import { transformPriority, transformStatus } from "../../../utils/common";

/*
 * Columns
 */

export const getStatusColor = (status): ChipProps["color"] => {
  switch (status) {
    case "new":
      return "info";
    case "open":
      return "primary";
    case "pending":
      return "info";
    case "hold":
      return "primary";
    case "solved":
      return "success";
    case "closed":
      return "default";
    default:
      return "default";
  }
};

export const ticketsTableColumnsData: (t: TFunction<"services">) => DataColumn[] = (t) => [
  {
    path: "id",
    label: "Ticket ID",
    toOption: standardToOption,
  },
  {
    path: "subject",
    label: t("TicketsTableColumns.subject"),
    comparators: ["contains"],
  },
  {
    path: "priority",
    label: t("TicketsTableColumns.severity"),
    toOption: (value) => {
      if (value) {
        return {
          value,
          label: transformPriority(value, t),
        };
      }
    },
  },
  {
    path: "requester",
    label: t("TicketsTableColumns.requester"),
  },
  {
    path: "satisfaction_rating.score",
    label: t("TicketsTableColumns.satisfaction"),
  },
  {
    path: "status",
    label: t("TicketsTableColumns.status"),
    comparators: [Comparators.EQUAL_TO, Comparators.NOT_EQUAL_TO],
    toOption: (value) => ({
      value,
      label: transformStatus(value, t),
    }),
  },
];

/*
 * Headers
 */

export const ticketsTableColumnsHeaders: (t: TFunction<"services">) => HeaderColumn[] = (t) => {
  const columnsData = ticketsTableColumnsData(t);
  return [
    {
      value: columnsData[0].path,
      label: t("TicketsTableColumns.id"),
      style: defaultHeaderStyle,
    },
    {
      value: columnsData[1].path,
      label: columnsData[1].label,
      style: defaultHeaderStyle,
      hidden: { smDown: false },
    },
    {
      label: t("TicketsTableColumns.platform"),
      value: "@",
      hidden: { smDown: true },
    },
    {
      value: "assignee.name",
      label: t("TicketsTableColumns.specialist"),
      style: defaultHeaderStyle,
      hidden: { mdDown: true },
    },
    {
      value: columnsData[2].path,
      label: columnsData[2].label,
      style: defaultHeaderStyle,
      hidden: { smDown: true },
    },
    {
      value: columnsData[3].path,
      label: columnsData[3].label,
      style: defaultHeaderStyle,
      hidden: { mdDown: true },
    },
    {
      value: columnsData[4].path,
      label: columnsData[4].label,
      style: defaultHeaderStyle,
      hidden: { smDown: true },
    },
    {
      value: "updated_at",
      label: t("TicketsTableColumns.lastModified"),
      style: defaultHeaderStyle,
      hidden: { smDown: true },
    },
    {
      value: columnsData[5].path,
      label: columnsData[5].label,
      style: defaultHeaderStyle,
      hidden: { xsDown: true },
    },
  ];
};

export const filterColumns = (t: TFunction<"services">) => [
  {
    path: "status",
    label: "Status",
    comparators: ["==", "!="],
    toOption: (value) => ({
      value,
      label: transformStatus(value, t),
    }),
  },
  {
    path: "id",
    label: "Ticket ID",
    toOption: (value) => ({
      value,
      label: value.toString(),
    }),
  },
  {
    path: "requester",
    label: "Requester",
  },
  {
    path: "subject",
    label: "Subject",
    comparators: ["contains"],
  },
  {
    path: "priority",
    label: "Severity",
    toOption: (value) => {
      if (value) {
        return {
          value,
          label: transformPriority(value, t),
        };
      }
    },
  },
];

export const zendeskTicketColumns = (t: TFunction<"services">) => [
  {
    path: "ticket.status",
    label: "Status",
    comparators: ["==", "!="],
    toOption: (value) => ({
      value,
      label: transformStatus(value, t),
    }),
  },
  {
    path: "ticket.id",
    label: "Ticket ID",
    toOption: (value) => ({
      value,
      label: value.toString(),
    }),
  },
  {
    path: "ticket.requester_email",
    label: "Requester",
  },
  {
    path: "ticket.subject",
    label: "Subject",
    comparators: ["contains"],
  },
  {
    path: "ticket.priority",
    label: "Severity",
    toOption: (value) => {
      if (value) {
        return {
          value,
          label: transformPriority(value, t),
        };
      }
    },
  },
];
