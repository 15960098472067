import { CloudAnalyticsAlertsDetectedModel, InAppNotificationType } from "@doitintl/cmp-models";
import { getCollectionGroup } from "@doitintl/models-firestore";
import { Typography } from "@mui/material";

import { type Mapper } from "../types";

export class Alert implements Mapper<CloudAnalyticsAlertsDetectedModel> {
  readonly type = InAppNotificationType.Alert;

  getCollection: Mapper<CloudAnalyticsAlertsDetectedModel>["getCollection"] = (
    customerRef,
    startTime,
    userEmail,
    isDoitEmployee
  ) => {
    const col = getCollectionGroup(CloudAnalyticsAlertsDetectedModel)
      .where("customer", "==", customerRef)
      .where("timeDetected", ">", startTime)
      .orderBy("timeDetected", "desc");

    if (!isDoitEmployee) {
      return col.where("recipients", "array-contains", userEmail);
    }

    return col;
  };

  userHasPermission: Mapper<CloudAnalyticsAlertsDetectedModel>["userHasPermission"] = (userRoles) =>
    userRoles?.cloudAnalyticsUser ?? false;

  toNotification: Mapper<CloudAnalyticsAlertsDetectedModel>["toNotification"] = (docSnap) => {
    const docData = docSnap.asModelData();
    const alertName = docData.alertName;
    const condition = docData.condition;
    // old alerts might not have name or condition
    if (!alertName || !condition) {
      return null;
    }

    let breakdown = docData.breakdown;
    if (breakdown && docData.breakdownLabel === "cloud_reports_user") {
      const parts = breakdown.split("_");
      const domain = parts.slice(-2).join(".");
      const user = parts.slice(0, -2).join(".");
      breakdown = `${user}@${domain}`;
    }
    let breakdownLabel = docData.breakdownLabel?.replace(/_/g, " ");
    if (breakdownLabel) {
      breakdownLabel = breakdownLabel.charAt(0).toUpperCase() + breakdownLabel.slice(1);
    }

    const description = (
      <>
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {condition}
        </Typography>
        {!!breakdownLabel && !!breakdown && (
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
            }}
          >
            {`${breakdownLabel}: ${breakdown}`}
          </Typography>
        )}
      </>
    );

    // remove any previous filter from the analytics alerts table
    window.localStorage.removeItem("enhancedTableFilter_analytics_alerts_tab");

    return {
      id: docSnap.id,
      ref: docSnap.modelRef,
      type: InAppNotificationType.Alert,
      created: docData.timeDetected,
      updated: undefined,
      title: alertName,
      description,
      urlPath: `/customers/${docData.customer.id}/analytics/alerts?name=${alertName}`,
      color: "#E7D5F6",
    };
  };
}
