import { type ClusterKubernetesFeature } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { useKubernetesFeatures } from "../../../hooks";
import { type ClusterTableItem } from "../types";

interface FeatureListProps {
  cluster: ClusterTableItem;
  title: string;
  filterFeatures: (f: ClusterKubernetesFeature) => boolean;
}

const FeatureList = ({ cluster, title, filterFeatures }: FeatureListProps) => {
  const [, , featureMap] = useKubernetesFeatures();

  return (
    <Box style={{ paddingTop: 8 }}>
      {title}:{" "}
      <ul>
        {Object.entries(cluster.features ?? {})
          .filter(([_, f]) => filterFeatures(f))
          .map(([k, _]) => featureMap?.[k]?.name ?? k)
          .map((name) => (
            <li key={name}>{name}</li>
          ))}
      </ul>
    </Box>
  );
};

export default FeatureList;
