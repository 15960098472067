import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";

import BlurredChart from "../../assets/ava/blurred-chart.png";
import { ContactSalesButton } from "../NoEntitlement/NoEntitlement";

export const UpsellNotification = () => (
  <Card style={{ position: "relative" }}>
    <CardMedia component="img" image={BlurredChart} alt="blurred chart" />
    <CardContent
      style={{
        position: "absolute",
        top: "5%",
        left: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        overflow: "auto",
      }}
    >
      <Typography variant="subtitle1" fontWeight={500}>
        Ava can give you cost insights in Analytics
      </Typography>
      <Typography mt={1} variant="body1" color="text.secondary" textAlign="center">
        Upgrade your DoiT Cloud Navigator plan to get cost insights <br /> into your cloud spend
      </Typography>

      <Box mt={1}>
        <ContactSalesButton customMixpanelEventId="ava.upselling-contact.button" />
      </Box>
    </CardContent>
  </Card>
);
