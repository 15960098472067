import { CheckCircle } from "@mui/icons-material";
import OpenNewIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Button, Link, Stack, Typography } from "@mui/material";

import { helpURLs } from "../../assets/urls";
import { CopyCodeBlock } from "../../Components/CopyCodeBlock/CopyCodeBlock";
import { OpenNewWindowLink } from "../../Pages/Flexsave/Standalone/GCP/Steps/Common/OpenNewWindowLink";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";

type Props = {
  concedefyEmail?: string;
};

const cloudFormationURI =
  "https://console.aws.amazon.com/cloudformation/home?account=[##ACCOUNT_ID##]/stacks/create/review?templateURL=https://doit-support.s3.amazonaws.com/doit-support.json&stackName=doit-support-gateway";

const deployRole = (accountId: string) => {
  window.open(cloudFormationURI.replace("[##ACCOUNT_ID##]", accountId), "_blank", "noopener");
};

export const CaseIQSuccess = ({ concedefyEmail }: Props) => {
  const { caseIQState } = useAvaCaseIQContext();

  let dynamicContent: React.ReactNode = null;
  if (caseIQState.platform === "amazon_web_services") {
    dynamicContent = (
      <Stack textAlign="center" alignItems="center" pt={4} sx={{ borderTop: "1px solid", borderColor: "divider" }}>
        <Typography variant="caption" color="text.secondary" fontWeight={700}>
          {`By clicking DEPLOY STACK, you can grant our support team temporary read-only access to your account
    ${caseIQState.asset} to help investigate your support request more efficiently. This access is optional and will be
    strictly limited to DoiT employees working on your ticket.`}
        </Typography>
        <OpenNewWindowLink
          text="Learn more about the required permissions."
          url="https://support.doit-intl.com/en/articles/5645276-what-is-stack-deployment"
        />

        <Button
          variant="outlined"
          sx={{ width: "fit-content", mt: 2 }}
          onClick={() => {
            deployRole(caseIQState.asset);
          }}
        >
          <OpenNewIcon />
          Deploy Stack
        </Button>
      </Stack>
    );
  }

  if (caseIQState.platform === "google_cloud_platform") {
    dynamicContent = (
      <Stack sx={{ borderTop: "1px solid", borderColor: "divider", pt: 4 }} spacing={2}>
        <Typography component="span">
          To expedite your request, please grant read-only access to your Google Cloud environment by running the
          following command. The access will be automatically removed when this request is resolved.{" "}
          <Link target="_blank" href={helpURLs.GRANT_GCP_ACCESS}>
            Learn more
          </Link>
        </Typography>
        <CopyCodeBlock
          base="gcloud projects add-iam-policy-binding abrar-playground --member=group:$concedefyEmail --role=roles/viewer --condition=None"
          variables={{ $concedefyEmail: { value: concedefyEmail ?? "", name: "concedefyEmail" } }}
        />
      </Stack>
    );
  }

  return (
    <Stack alignItems="center" spacing={2} justifyContent="center" height="100%" maxWidth={400} alignSelf="center">
      <Stack alignItems="center" spacing={3.2} pb={4}>
        <Box
          sx={{
            backgroundColor: "#4CAF504D",
            borderRadius: "50%",
            height: 54,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: 54,
          }}
        >
          <CheckCircle sx={{ color: "success.main" }} fontSize="large" />
        </Box>
        <Stack spacing={1} textAlign="center">
          <Typography variant="subtitle1" fontWeight={500}>
            Thanks for opening a ticket for our team!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            We will respond within 4 hours, during business hours
          </Typography>
        </Stack>
      </Stack>
      {dynamicContent}
    </Stack>
  );
};
