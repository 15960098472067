import { CloudFlowProvider, type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";

import AWSParameterForm from "./AWSParameterForm";
import GCPParameterForm from "./GCPParameterForm";

const CloudSpecificParameterForm = ({
  provider,
  inputModel,
  rootPath,
}: {
  provider: CloudFlowProvider;
  inputModel: StructureApiServiceModelDescriptor<Member>;
  rootPath?: string;
}) => {
  switch (provider) {
    case CloudFlowProvider.AWS:
      return <AWSParameterForm inputModel={inputModel} rootPath={rootPath} />;
    case CloudFlowProvider.GCP:
      return <GCPParameterForm inputModel={inputModel} rootPath={rootPath} />;
    default:
      return null;
  }
};

export default CloudSpecificParameterForm;

export function withRootPath(fieldName: string, rootPath?: string) {
  return [rootPath, fieldName].filter(Boolean).join(".");
}
