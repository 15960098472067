import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";

import {
  AppModel,
  AssetModel,
  AssetTypeGoogleCloudProject,
  CustomerSecurityMode,
  IntegrationModel,
  type SupportServiceModel,
} from "@doitintl/cmp-models";
import { getCollection, useCollectionData, useDocumentData } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../Context/CustomerContext";
import { getProductsProperties, platformName } from "../../Support/utils";
import { useAvaContext } from "./AvaContext";
import { type TicketAsset } from "./types";

export const useNewQuestionAsked = () => {
  const [prevConversationId, setPrevConversationId] = useState<string>();
  const [newQuestionAsked, setNewQuestionAsked] = useState(false);
  const { conversationId } = useAvaContext();

  useEffect(() => {
    if (conversationId !== prevConversationId) {
      if (prevConversationId) {
        setNewQuestionAsked(false);
      }
      setPrevConversationId(conversationId);
    }
  }, [conversationId, setNewQuestionAsked, prevConversationId]);

  return {
    newQuestionAsked,
    setNewQuestionAsked,
  };
};

export const useConversationIdChanged = (callback: () => void) => {
  const [prevConversationId, setPrevConversationId] = useState<string>();
  const { conversationId } = useAvaContext();

  useEffect(() => {
    if (conversationId !== prevConversationId) {
      setPrevConversationId(conversationId);
      callback();
    }
  }, [conversationId, setPrevConversationId, prevConversationId, callback]);

  return prevConversationId;
};

export function useIsBigWidth() {
  const ref = useRef<HTMLDivElement | null>(null);
  const [width, setWidth] = useState<number>(0);

  useLayoutEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver(([entry]) => {
        setWidth(entry.contentRect.width);
      });
      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return { ref, bigWidth: width > 1100, width };
}

export const usePlatformList = () => {
  const ref = getCollection(IntegrationModel).doc("zendesk").collection("ticketFields").doc("platforms");
  const [ticketsFields] = useDocumentData(ref);
  return ticketsFields?.values || [];
};

export const useCategoryList = () => {
  const ref = getCollection(AppModel).doc("support").collection("services").where("blacklisted", "==", false);
  const [categories] = useCollectionData(ref);
  return categories || [];
};

export const useCategoryListFromPlatform = (categories: SupportServiceModel[], platform: string) =>
  useMemo(
    () => categories?.filter((category) => category.platform === platformName(platform)) || [],
    [categories, platform]
  );

export const useUserAssets = (): AssetModel[] => {
  const { customer } = useCustomerContext({ allowNull: true });

  let assetsQuery = getCollection(AssetModel).where("customer", "==", customer?.ref ?? "");
  if (customer?.securityMode === CustomerSecurityMode.RESTRICTED) {
    assetsQuery = assetsQuery.where("type", "!=", AssetTypeGoogleCloudProject);
  }

  const [assets] = useCollectionData(assetsQuery);
  return assets || [];
};

export const filterAssetsByPlatform = (assets: AssetModel[], platform: string) => {
  const parsedAssets = assets?.map((asset) => getProductsProperties(asset)) || [];
  const filteredAssets =
    parsedAssets?.filter((asset) => {
      if (asset === null) {
        return false;
      }
      if (platform === "amazon_web_services") {
        return asset.asset === "amazon-web-services";
      } else if (platform === "google_cloud_platform") {
        return asset.asset === "google-cloud-project";
      }
      return platformName(platform) === asset.asset;
    }) || [];

  // delete the duplicate
  const filteredAssetsNoDuplicates = Array.from(new Set(filteredAssets.map((asset) => JSON.stringify(asset)))).map(
    (asset) => JSON.parse(asset) as TicketAsset
  );

  return filteredAssetsNoDuplicates;
};
