import { type AttributionFilter } from "@doitintl/cmp-models";
import { Chip, Stack, Typography } from "@mui/material";

import { useGetLabelText } from "./hooks";

type ChipProps = {
  text: string;
};

const AttributionChip = ({ text }: ChipProps) => <Chip label={text} variant="outlined" color="primary" size="small" />;

type Props = {
  filter: AttributionFilter;
};

export const AttributionChips = ({ filter }: Props) => {
  const getLabelText = useGetLabelText(filter);

  const amountOfValues = filter?.values?.length ?? 0;

  const showExtraTag = amountOfValues > 3;
  const filterValues = filter?.values?.slice(0, 3);

  return (
    <Stack direction="row" alignItems="center" spacing={1.5}>
      {filterValues?.map((filterValue, index) => (
        <Stack key={index} direction="row">
          <AttributionChip text={getLabelText(filterValue)} />
          {index < filterValues.length - 1 && <Typography ml={1.5}>or</Typography>}
        </Stack>
      ))}
      {filter?.regexp && (
        <>
          <Typography variant={"caption"}>matches regular expression</Typography>
          <AttributionChip text={filter.regexp} />
        </>
      )}
      {showExtraTag && (
        <Typography variant="caption" color="primary.main">
          +{amountOfValues - 3}
        </Typography>
      )}
    </Stack>
  );
};
