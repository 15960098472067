import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip } from "@mui/material";

export const MarketplaceText =
  "Marketplace spending counts towards your commitment, depending on the limit specified in your agreement. For example, a limit of 25% for a $1 million commitment, up to $250,000 can be Marketplace spend.";

export const FlexsaveText =
  "We charge you a flexsave rate, as shown in the reports. The commitment, however, reflects our discounted cost from the cloud providers";

export const SupportText =
  "We charge you a support rate, as shown in the reports. The commitment, however, reflects our discounted cost from the cloud providers";

export const InfoTooltip = ({ text }: { text: string }) => (
  <Tooltip title={text} arrow placement="right">
    <Box component="span" sx={{ display: "inline-flex", alignItems: "center", ml: 0.5 }}>
      <InfoOutlinedIcon fontSize="small" color="action" />
    </Box>
  </Tooltip>
);
