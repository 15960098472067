import { Fragment, useState } from "react";

import { TierPackageTypes } from "@doitintl/cmp-models";
import CheckIcon from "@mui/icons-material/Check";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, Link, Stack, Typography, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";

import { useApiContext } from "../../api/context";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import useCreateTicket from "../../Pages/MPA/hooks/useCreateTicket";
import { sendUpsellUpgradeNotification } from "../Insights/api";

const alertText = "Thanks for your interest. We'll get back to you within one business day.";

export const UpgradeOptions = ({
  open,
  onClose,
  sendUpsellEvent,
  requiredTiers = ["Enhanced", "Enterprise"],
  featureName = "Insights",
}: {
  open: boolean;
  onClose: () => void;
  sendUpsellEvent: () => void;
  requiredTiers?: string[];
  featureName?: string;
}) => {
  const theme = useTheme();
  const { customer } = useCustomerContext({ allowNull: true });
  const api = useApiContext();
  const [notificationSent, setNotificationSent] = useState(false);
  const [loading, setLoading] = useState(false);
  const { getCustomerTier } = useTier();
  const navigatorCustomerTier = getCustomerTier(TierPackageTypes.NAVIGATOR);
  const showError = useErrorSnackbar();
  const { createTicket } = useCreateTicket();

  const sendUpgradeRequestNotification = async () => {
    setLoading(true);

    try {
      // TODO: This is a temporary solution. This file will be refactored and enhanced in future updates.
      if (featureName === "Insights") {
        await sendUpsellUpgradeNotification({ api, customerID: customer?.id });
      } else {
        await createTicket(customer?.id ?? "", {
          subject: `Upgrade request for ${featureName}`,
          description: `Customer is interested in upgrading to access ${featureName} feature.`,
          priority: "normal",
          platformField: "doit",
          tags: ["upsell", "upgrade-request", featureName.toLowerCase()],
        });
      }
      setNotificationSent(true);
      sendUpsellEvent();
    } catch (error) {
      showError("An error occurred while sending the upgrade request notification.");
    }

    setLoading(false);
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Stack gap={2} mb={4} px={3} pt={3}>
        <DialogTitle sx={{ p: 0 }}>Upgrade options</DialogTitle>
        <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
          Your current plan is <b>{navigatorCustomerTier?.displayName}</b>. Upgrade to{" "}
          {requiredTiers.map((tier, index) => (
            <Fragment key={tier}>
              <b>{tier}</b>
              {index < requiredTiers.length - 1 ? (index === requiredTiers.length - 2 ? " or " : ", ") : ""}
            </Fragment>
          ))}{" "}
          to unlock access to {featureName} and more advanced features.&nbsp;
          <Link
            color="primary.main"
            sx={{ display: "inline", alignItems: "center", gap: "3px", cursor: "pointer", textDecoration: "none" }}
            href="https://www.doit.com/pricing/"
            target="_blank"
          >
            Learn more&nbsp;
            <OpenInNewIcon sx={{ height: "14px", width: "14px", verticalAlign: "middle", marginBottom: "1px" }} />
          </Link>
        </Typography>
        <Typography variant="h4" fontWeight={500} color="text.primary" fontSize={18} lineHeight="26px">
          We're here to help you
        </Typography>
        <Typography variant="body1" color="text.primary" fontSize={16} lineHeight="24px">
          Your account manager is ready to provide more information about upgrading and getting you started.
        </Typography>
        {notificationSent ? (
          <Alert
            icon={<CheckIcon fontSize="inherit" sx={{ color: "#2E7D32" }} />}
            sx={{
              background: "transparent",
              color: "#2E7D32",
              padding: 0,
              "& .MuiAlert-message": {
                background: "none",
              },
            }}
            severity="success"
          >
            {alertText}
          </Alert>
        ) : (
          <LoadingButton
            variant="contained"
            loading={loading}
            sx={{ background: "primary.main", width: "221px", whiteSpace: "nowrap" }}
            size="large"
            onClick={sendUpgradeRequestNotification}
          >
            Contact me about upgrading
          </LoadingButton>
        )}
      </Stack>
      <DialogActions sx={{ borderTop: `1px solid ${theme.palette.divider}` }}>
        <Button autoFocus onClick={onClose} sx={{ color: "primary.main" }}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
