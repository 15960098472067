import { type JSX } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Tooltip, type TooltipProps } from "@mui/material";

type InfoTooltipProps = {
  description: string;
  children: JSX.Element;
  placement?: TooltipProps["placement"];
};

export const InfoTooltip = ({ children, description, placement = "top" }: InfoTooltipProps) => (
  <Box
    sx={{
      display: "flex",
      alignItems: "center",
    }}
  >
    {children}

    <Box
      sx={{
        px: 1,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Tooltip title={description} arrow placement={placement}>
        <InfoOutlinedIcon cursor="pointer" color="action" fontSize="small" data-cy="info-icon" />
      </Tooltip>
    </Box>
  </Box>
);
