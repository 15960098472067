import { useState } from "react";

import { useParams } from "react-router";

import LoadingButton from "../../../../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../../../Integrations/Slack/utils";
import contractHooks, { useBackToList } from "../../../../hooks";

export const UndoContractTerminationButton = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const [loading, setLoading] = useState(false);
  const updateContractStatus = contractHooks.useUpdateContractStatus();
  const backToList = useBackToList();
  const errorSnackbar = useErrorSnackbar();

  const handleUndoTerminate = () => {
    setLoading(true);
    updateContractStatus(
      contractId,
      { active: true, terminated: false },
      () => {
        setLoading(false);
        backToList();
      },
      () => {
        setLoading(false);
        errorSnackbar("Failed to undo termination of contract");
      }
    );
  };

  return (
    <LoadingButton
      mixpanelEventId=""
      loading={loading}
      data-cy="undo-terminate-contract-button"
      variant="outlined"
      color="primary"
      onClick={handleUndoTerminate}
    >
      Undo Termination
    </LoadingButton>
  );
};
