import React from "react";

import { type CustomerModelCloudConnectModel } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";

import { useCustomerContext } from "../../../Context/CustomerContext";
import { PermissionsAlert } from "./PermissionsAlert";

type Props = {
  unhealthyOrganization: ModelIdRef<CustomerModelCloudConnectModel> | null;
};

export const MissingCloudConnectPermissionsAlert: React.FC<Props> = ({ unhealthyOrganization }) => {
  const { customer } = useCustomerContext();

  let buttonText = "Connect organization or project";
  let message =
    "Connect your organization or project and enable Kubernetes insights to get recommendations for your Google Kubernetes Engine (GKE) clusters";
  let link = `/customers/${customer.id}/settings/gcp`;

  if (unhealthyOrganization !== null) {
    buttonText = "Add permissions to organization";
    message =
      "Enable Kubernetes insights on your organization to get recommendations for your Google Kubernetes Engine (GKE) clusters.";
    link = `/customers/${customer.id}/settings/gcp/connect/organization/${unhealthyOrganization.id}`;
  }

  return (
    <PermissionsAlert link={link} buttonText={buttonText}>
      {message}
    </PermissionsAlert>
  );
};
