import { Link as RouterLink, useHistory } from "react-router-dom";
import { CategoryStatus } from "@doitintl/cmp-models";
import { Chip, type ChipProps, TableCell, Tooltip, Typography } from "@mui/material";

import { accountsTexts } from "../../../../assets/texts";
import Hide from "../../../../Components/HideChildren/Hide";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../../Components/ThreeDotsMenu";
import { getCloudConnectStatus } from "../../../../utils/common";
import { dateFormatCompleteDateWithoutSeconds } from "../../../../utils/dateTimeFormats";
import { AccountStatus, type AWSAccount } from "../types";

export type AWSAccountsRowProps = {
  row: AWSAccount;
  onDeleteClicked: (account: AWSAccount) => void;
};

type ChipColor = ChipProps["color"];

const getChipColor = (status: AccountStatus): ChipColor => {
  const getColor = (categoryStatus: CategoryStatus): ChipColor =>
    getCloudConnectStatus(categoryStatus).color as ChipColor;

  switch (status) {
    case AccountStatus.notConfigured:
      return getColor(CategoryStatus.NotConfigured);
    case AccountStatus.unhealthy:
      return getColor(CategoryStatus.Unhealthy);
    case AccountStatus.healthy:
      return getColor(CategoryStatus.Healthy);
    default:
      return "default";
  }
};

export const AWSAccountsRow = ({ row, onDeleteClicked }: AWSAccountsRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();

  const handleEditClicked = () => {
    history.push(`${routeMatchURL}/${row.accountId}`);
  };

  const showEdit = row.status === AccountStatus.healthy;
  const menuOptions: ThreeDotsMenuOption[] = [];
  if (showEdit) {
    menuOptions.push({
      label: <Typography>{accountsTexts.AWS.MENU.EDIT}</Typography>,
      action: handleEditClicked,
      key: `edit-${row.id}`,
    });
  }
  if (row.status !== AccountStatus.notConfigured) {
    menuOptions.push({
      label: <Typography color="error">{accountsTexts.AWS.MENU.UNLINK}</Typography>,
      action: () => {
        onDeleteClicked(row);
      },
      key: `unlink-${row.id}`,
    });
  }

  return (
    <>
      <TableCell data-cy="account">
        {showEdit ? (
          <Typography component={RouterLink} to={`${routeMatchURL}/${row.accountId}`} color="inherit" variant="body2">
            {row.accountId}
          </Typography>
        ) : (
          <Typography variant="body2">{row.accountId}</Typography>
        )}
      </TableCell>
      <Hide smDown>
        <TableCell data-cy="role">{row.roleName}</TableCell>
      </Hide>
      <Hide lgDown>
        <TableCell data-cy="enabled-features">
          {[...row.featureNames].sort((a, b) => a.localeCompare(b)).join(", ")}
        </TableCell>
      </Hide>
      <TableCell data-cy="status">
        <Tooltip title={row.error ?? ""}>
          <Chip variant="status" label={row.status} color={getChipColor(row.status)} />
        </Tooltip>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy="link-time">
          {row.timeLinked !== null ? row.timeLinked.toFormat(dateFormatCompleteDateWithoutSeconds) : "N/A"}
        </TableCell>
      </Hide>
      <TableCell padding="checkbox">{menuOptions.length !== 0 && <ThreeDotsMenu options={menuOptions} />}</TableCell>
    </>
  );
};
