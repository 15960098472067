import { type Cluster } from "@doitintl/cmp-models";
import { type AxiosInstance } from "axios";

export type CreateClusterPayload = {
  name: string;
  region: string;
  host: string;
  caCertBase64: string;
  serviceAccountKey: string;
};

export type UpdateClusterPayload = Omit<CreateClusterPayload, "name" | "region" | "type">;

export type TestClusterConnectionPayload = {
  host: string;
  caCertBase64: string;
  token: string;
  clusterId?: string;
};

export type ClusterFeatureActivationPayload = {
  newFeatureStates: Record<string, boolean>;
};

export const createCluster = async (api: AxiosInstance, cluster: CreateClusterPayload, customerId: string) =>
  api.post(`fsk8s/clusters?customerID=${customerId}`, cluster);

export const testClusterConnection = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/connectioncheck`);

export const testClusterConnectionFromPayload = async (api: AxiosInstance, payload: TestClusterConnectionPayload) =>
  api.post(`fsk8s/clusters/connectioncheck`, payload);

export const connectEksCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/eks-connect`);

export const activateCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/activate`);

export const deactivateCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/deactivate`);

export const deleteCluster = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.delete(`fsk8s/clusters/${clusterId}`);

export const updateCluster = async (api: AxiosInstance, cluster: UpdateClusterPayload, clusterId: Cluster["id"]) =>
  api.patch(`fsk8s/clusters/${clusterId}`, cluster);

export const updateClusterFeatureActivationStatus = async (
  api: AxiosInstance,
  clusterId: Cluster["id"],
  payload: ClusterFeatureActivationPayload
) => api.post(`fsk8s/clusters/${clusterId}/update-feature-activation-status`, payload);

export const updateClusterFeatureHealthStatus = async (api: AxiosInstance, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/healthcheck`);

export const customerHasGKECluster = async (api: AxiosInstance, customerId: string) =>
  api.get(`fsk8s/insights/customer-with-gke-cluster?customerID=${customerId}`);

export const getAgentInstallationCommand = async (api: AxiosInstance, customerId: string, clusterId: Cluster["id"]) =>
  api.post(`fsk8s/clusters/${clusterId}/install-perfectscale?customerID=${customerId}`);

export const discoverEksClusters = async (api: AxiosInstance, customerId: string, cloudConnectId: string) =>
  api.post(`fsk8s/clusters/discover/eks/${customerId}/${cloudConnectId}?customerID=${customerId}`, {});
