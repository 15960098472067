import { useCallback, useEffect, useMemo, useState } from "react";

import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Box, Checkbox, FormHelperText, Stack, TextField, Tooltip, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import concat from "lodash/concat";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";
import PropTypes from "prop-types";

import CreatableSelectBox from "../../../Components/CreatableSelectBox";
import useMountEffect from "../../../Components/hooks/useMountEffect";
import BillingAccountSelect from "../../../Components/Selects/BilingAccountSelect";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { isJapanese, japaneseRegexStr } from "../../../utils";
import { useFullScreen } from "../../../utils/dialog";
import { Platform } from "../../types";
import { debouncedContentChange, isEMEAorNorthAmerica } from "../../utils";
import DetailsAlertBox from "../DetailsAlertBox";
import Editor from "../Editor/Editor";
import {
  resourceDetailsComponentTypeMap,
  resourceDetailsPriorityTrigger,
} from "../ResourceDetails/getResourceDetailsComponent";
import ResourceDetailsWrapper from "../ResourceDetails/ResourceDetailsWrapper";
import SelectCategory from "../SelectCategory";
import { ContentHeader } from "../Stepper/ContentHeader";
import { useIsABTesting, useIsPLESCustomer } from "./hooks";
import { useTranslation } from "react-i18next";
import { withStyles } from "@mui/styles";
import { CaseIQClientProvider, useABTestingCaseIQ, useCaseIQByDetails } from "../CaseIQ/utils";
import CaseIQ from "../CaseIQ/CaseIQ";
import SelectMpaFromResource from "../SelectMpaFromResource";
import AwsDirectSupport from "../../../Components/AwsDirectSupport";

const MRR = 20000;

const standaloneProducts = [
  "cmp/analytics-reports",
  "cmp/assets",
  "cmp/aws-lens",
  "cmp/consulting-support",
  "cmp/contract-management",
  "cmp/flexsave-aws",
  "cmp/flexsave-gcp",
  "cmp/flexsave-standalone",
  "cmp/gcp-lens",
  "cmp/general-settings",
  "cmp/identity-management",
  "cmp/invoice-management",
  "cmp/widgets",
];

export const creCheckboxCloudServices = [Platform.azure, Platform.aws, Platform.gcp, Platform.cmp];

const styles = (theme) => ({
  root: {
    flex: 1,
    width: "100%",
    maxWidth: 950,
    padding: 34,
  },
  rtl: {
    direction: "rtl",
  },
  chip: {
    margin: theme.spacing(0, 0.25, 0.25, 0),
  },
  textField: {
    marginTop: 25,
  },
  subject: {
    marginTop: 15,
  },
  uploadContainer: {
    display: "flex",
  },
  chipsContainer: {
    marginLeft: theme.spacing(1),
    display: "flex",
    alignItems: "center",
  },
  inputRoot2: {
    flexWrap: "wrap",
  },
  creatableSelect: {
    zIndex: 90,
  },
  fileInput: {
    display: "none",
  },
  editor: {
    [theme.breakpoints.up("md")]: {
      height: 270,
    },
    [theme.breakpoints.down("lg")]: {
      height: 290,
    },
    [theme.breakpoints.down("sm")]: {
      height: 314,
    },
  },
});

function renderInput(inputProps, smDown) {
  const { InputProps, classes, ref, ...other } = inputProps;
  const chipSize = smDown ? 2 : 3;
  return (
    <TextField
      margin="dense"
      size="medium"
      variant="outlined"
      multiline={true}
      maxRows={3}
      className={classes.subject}
      {...other}
      slotProps={{
        input: {
          inputRef: ref,
          classes: {
            root: InputProps.startAdornment.length < chipSize ? classes.inputRoot : classes.inputRoot2,
            input: classes.inputInput,
          },
          ...InputProps,
        },
      }}
    />
  );
}

export const validateSubject = (str) => {
  // must not be empty
  if (!str) {
    return false;
  }

  // must start with alphanumeric, and cannot contain cell separators
  const japanese = japaneseRegexStr.replaceAll(/[[\]]/g, "");
  const startsWithAlphaNumericOrJapanese = `^[A-Za-z0-9"${japanese}]`;

  const regex = new RegExp(`${startsWithAlphaNumericOrJapanese}[^\t,]*$`);
  return regex.test(str);
};

const DetailsStep = (props) => {
  const {
    classes,
    cloudProduct,
    cloudPlatform,
    sauronRole,
    categoryInfo,
    selectedPlatform,
    platforms,
    changeCategoryInfo,
    categoryInfoList,
    severity,
  } = props;
  const [users, setUsers] = useState([]);
  const [checkSpeakWithCre, setCheckSpeakWithCre] = useState(false);
  const { isMobile: smDown } = useFullScreen();
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const { customer, contracts, isProductOnlyCustomer } = useCustomerContext();
  const resourceDetailsEnabled = useIsABTesting();
  const [localContent, setLocalContent] = useState("");
  const caseIQABTesting = useABTestingCaseIQ({ severity: severity?.value, cloudProduct: cloudProduct?.id });

  const { t } = useTranslation("services");

  const isPlesCustomer = useIsPLESCustomer(customer.id);

  useEffect(() => {
    const arr = props.customerUsers.map((i) => {
      const val = i.displayName ? i.displayName : i.email;
      return { value: i.email, label: val };
    });
    setUsers(arr);
  }, [props.customerUsers]);

  useMountEffect(() => {
    props.handleChangeResourceDetails?.([]);
  });

  const isMRRAbove20k = useMemo(() => {
    if (!contracts) {
      return false;
    }
    return contracts?.reduce((acc, contract) => acc + (contract.estimatedValue ?? 0), 0) / 12 > MRR;
  }, [contracts]);

  const filterByMRR = useCallback((platform) => platform !== Platform.cmp || isMRRAbove20k, [isMRRAbove20k]);
  const shouldShowCheckbox = useIsFeatureEntitled("support:ticket:call-check");

  const filterByEMEAorNorthAmerica = useCallback(
    (platform) => platform !== Platform.cmp || isEMEAorNorthAmerica(customer?.enrichment?.geo?.country ?? ""),
    [customer?.enrichment?.geo?.country]
  );

  const handleAddFiles = (event) => {
    const newFiles = Array.from(event.target.files);
    event.target.value = null;
    props.setFiles((files) => uniqBy(concat(files.slice(), newFiles), "name"));
  };

  const handleRemoveFile = (i) => () => {
    const files = props.files.slice();
    files.splice(i, 1);
    props.setFiles(files);
  };

  const onSelectRequester = (selectedItem) => {
    let selected = selectedItem;
    if (typeof selectedItem === "object" && selectedItem !== null) {
      selected = selectedItem?.value;
    }
    props.onSelectUser(selected);
  };

  const onCheckSpeakWithCre = (event) => {
    setCheckSpeakWithCre(event.target.checked);
    props.handleChangeSpeakWithCre(event.target.checked);
  };

  const placeholder = `${t("DetailsStep.placeholder1")} ${
    props.userDetails?.name ? props.userDetails.name.substring(0, props.userDetails.name.indexOf(" ")) : ""
  }? ${t("DetailsStep.placeholder2")}`;

  const productsList = useMemo(() => {
    if (isProductOnlyCustomer && props.categories) {
      return props.categories?.filter((product) => standaloneProducts.includes(product.id));
    }
    return props.categories;
  }, [isProductOnlyCustomer, props.categories]);

  const getVideoCallText = useMemo(
    () => (cloudPlatform === Platform.cmp ? t("DetailsStep.call") : t("DetailsStep.15MinCall")),
    [cloudPlatform, t]
  );

  const showBillingAccountDropDown = useMemo(
    () =>
      props.cloudProduct?.id?.replaceAll("-", "")?.replaceAll(" ", "")?.toLowerCase()?.includes("partnerledpremium"),
    [props.cloudProduct]
  );

  const handleBillingAccountChanged = (values) => {
    props.changeCategoryInfo({ asset: "billing-account", identifier: values.join() });
  };

  const [minHeight, setMinHeight] = useState(0);

  const [showComfortableWithEnglish, setShowComfortableWithEnglish] = useState(isJapanese(props.textArea));

  const payload = useMemo(
    () => ({
      description: props.textArea,
      userSelectedFields: {
        platform: props.cloudPlatform,
        product: props.cloudProduct.id,
        subject: props.subject,
      },
    }),
    [props]
  );

  const { caseIQDetails } = useCaseIQByDetails(payload);

  return (
    <Box>
      {selectedPlatform?.value === "amazon_web_services" &&
        isPlesCustomer &&
        severity?.value === "urgent" &&
        // Customer cannot use PLES if they have any assets connected to a shared MPA.
        // shared MPAs are deprecated, and no new ones will be created.
        categoryInfoList.every(
          (item) =>
            (!!item.mpa && !["017920819041", "279843869311", "561602220360", "896363362129"].includes(item.mpa)) || true
        ) && (
          <Box sx={{ pt: 4 }}>
            <AwsDirectSupport
              accountId={categoryInfo}
              sauronRole={sauronRole}
              onCancel={() => {
                changeCategoryInfo(null);
              }}
            >
              <SelectMpaFromResource
                onChangeValue={changeCategoryInfo}
                assetAccountId={categoryInfo}
                assetsList={categoryInfoList}
                platforms={platforms}
                selectedPlatform={selectedPlatform}
              />
            </AwsDirectSupport>
          </Box>
        )}
      {props.severity?.value === "urgent" && (
        <>
          <Typography
            variant="h4"
            sx={{
              color: "text.primary",
              mb: 1,
              mt: 3,
            }}
          >
            {t("DetailsStep.title")}
          </Typography>

          <Typography variant="body1" sx={{ mb: 4 }} data-cy="detailsstep-urgent-sev-message">
            {t("DetailsStep.subtitle")}
          </Typography>
        </>
      )}
      {creCheckboxCloudServices.filter(filterByMRR).filter(filterByEMEAorNorthAmerica).includes(cloudPlatform) &&
        shouldShowCheckbox &&
        props.severity?.value !== "urgent" && (
          <>
            <ContentHeader title="Details" />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                my: 3,
              }}
            >
              <Checkbox
                data-cy="detailsstep-callMe-checkbox"
                onChange={onCheckSpeakWithCre}
                checked={checkSpeakWithCre}
              />
              <Stack>
                <Typography variant="body1">{getVideoCallText}</Typography>
                <FormHelperText>{t("DetailsStep.helperText1")}</FormHelperText>
              </Stack>
            </Box>
            {cloudPlatform === Platform.cmp && (
              <Box sx={{ pl: 5, mb: 2, mt: -1 }}>
                <FormHelperText>{t("DetailsStep.helperText2")}</FormHelperText>
              </Box>
            )}
          </>
        )}
      {isDoitEmployee && (
        <CreatableSelectBox
          value={props.selectedCustomerUser}
          placeholder={t("DetailsStep.requester")}
          options={users}
          onChange={onSelectRequester}
          helperText={props.requesterError ?? " "}
          error={props?.requesterError}
        />
      )}
      <TextField
        id="outlined-name"
        label={t("DetailsStep.subject")}
        required
        fullWidth
        value={props.subject}
        sx={{ mt: 1 }}
        onChange={props.handleChangeSubject}
        size="medium"
        variant="outlined"
        helperText={
          !!props.subject && !validateSubject(props.subject)
            ? "Only letters and numbers allowed"
            : t("DetailsStep.subjectHelper")
        }
        error={!!props.subject && !validateSubject(props.subject)}
      />
      {showBillingAccountDropDown ? (
        <BillingAccountSelect
          fullWidth
          margin="normal"
          size="medium"
          label={t("DetailsStep.billingAccount")}
          onChange={handleBillingAccountChanged}
          helperText={t("DetailsStep.billingAccountHelper")}
          error={props.error}
          disabled={false}
          data-cy="detailsstep-billing-account-autocomplete"
        />
      ) : (
        <SelectCategory
          helperText={props.helperText}
          handleChangeCategory={props.handleChangeCategory}
          platforms={props.platforms}
          selectedPlatform={props.selectedPlatform}
          category={props.category ? props.category : null}
          categories={productsList}
          changeCategoryInfo={props.changeCategoryInfo}
          categoryInfo={props.categoryInfo}
          error={props.error}
          categoryInfoList={props.categoryInfoList}
          step={props.step}
          data-cy="detailsstep-project_id-autocomplete"
        />
      )}
      {renderInput(
        {
          fullWidth: true,
          classes,
          InputProps: {
            startAdornment: props.selectedEmails.map((item) => {
              let maxWidth;
              if (smDown) {
                maxWidth = props.selectedEmails.length >= 2 ? "48%" : `${100 / props.selectedEmails.length - 2}%`;
              } else {
                maxWidth = props.selectedEmails.length >= 4 ? "23%" : `${100 / props.selectedEmails.length - 2}%`;
              }
              return (
                <Tooltip key={item} title={item}>
                  <Chip
                    key={item}
                    tabIndex={-1}
                    className={classes.chip}
                    label={item}
                    size="small"
                    onDelete={() => props.handleDelete(item)}
                    sx={{ maxWidth }}
                  />
                </Tooltip>
              );
            }),
            onChange: props.handleCChange,
            onBlur: props.handleCChangeOut,
            value: props.cc,
            placeholder: props.selectedEmails?.length > 0 ? "" : "alice@example.com, bob@example.com",
          },
          label: t("DetailsStep.additionalCCs"),
        },
        smDown
      )}
      {resourceDetailsEnabled &&
        !caseIQABTesting &&
        resourceDetailsPriorityTrigger.includes(props.severity?.value) &&
        Object.keys(resourceDetailsComponentTypeMap).includes(props.cloudProduct.id) && (
          <ResourceDetailsWrapper resourceType={props.cloudProduct.id} onChange={props.handleChangeResourceDetails} />
        )}
      {caseIQABTesting ? null : (
        <Box sx={{ minHeight: 28 }}>
          <DetailsAlertBox severity={props.severity?.value} cloudProduct={cloudProduct} />
        </Box>
      )}
      <Box
        sx={{
          position: "relative",
          minHeight: minHeight,
        }}
      >
        <Editor
          placeholder={placeholder}
          name="body"
          value={localContent || props.textArea}
          onChange={(newContent) => {
            const htmlContent = `<div>${props.textArea}</div>`;
            if (newContent.includes("<div>") && htmlContent !== newContent) {
              setLocalContent(newContent);
              const hasJapaneseSymbols = isJapanese(props.textArea);
              setShowComfortableWithEnglish(hasJapaneseSymbols);
              debouncedContentChange(newContent, () => {
                props.onChangeTextarea(newContent);
              });
            }
          }}
        />
        {caseIQABTesting ? (
          <CaseIQClientProvider>
            <CaseIQ setMinHeight={setMinHeight} detectedDetails={caseIQDetails?.technicalDetails} />
          </CaseIQClientProvider>
        ) : null}
        <div className={classes.uploadContainer}>
          <Box
            sx={{
              display: "flex",
              mt: 1,
            }}
          >
            <input
              id="file-upload-button"
              type="file"
              multiple
              className={classes.fileInput}
              onChange={handleAddFiles}
            />
            <label htmlFor="file-upload-button">
              <Button variant="outlined" color="primary" component="span" className={classes.button}>
                <AttachFileIcon />
                {t("DetailsStep.attachFiles")}
              </Button>
            </label>

            <div className={classes.chipsContainer}>
              {map(props.files, (file, i) => (
                <Fade in key={i}>
                  <Chip
                    key={i}
                    label={`${file.name}`}
                    onDelete={handleRemoveFile(i)}
                    className={classes.chip}
                    size="small"
                  />
                </Fade>
              ))}
            </div>
          </Box>
        </div>
      </Box>
      {showComfortableWithEnglish ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            my: 1.5,
          }}
        >
          <Checkbox
            id="respond-in-japanese"
            data-cy="respond-in-japanese"
            onChange={() => {
              props.setComfortableWithEnglish(!props.comfortableWithEnglish);
            }}
            checked={props.comfortableWithEnglish}
          />

          <Typography variant="body1" component="label" htmlFor="respond-in-japanese" sx={{ cursor: "pointer" }}>
            {t("DetailsStep.comfortableWithEnglish")}
          </Typography>
        </Box>
      ) : null}
    </Box>
  );
};

DetailsStep.propTypes = {
  severity: PropTypes.object,
  cloudProduct: PropTypes.object,
  handleChangeResourceDetails: PropTypes.func,
  comfortableWithEnglish: PropTypes.bool.isRequired,
  setComfortableWithEnglish: PropTypes.func.isRequired,
  onChangeTextarea: PropTypes.func.isRequired,
};

export default withStyles(styles)(DetailsStep);
