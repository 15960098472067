import { CloudFlowNodeType } from "@doitintl/cmp-models";
import { type Node } from "@xyflow/react";
import { v4 as uuidv4 } from "uuid";

import { type RFNode } from "../../types";
import { mapToUpdateNodePayload } from "./nodeTransformUtils";
import { initializeNode } from "./nodeUtils";

const updateConditionNodeWithTransitions = (
  newNode: Node<RFNode<CloudFlowNodeType>>,
  targetNodeId: string,
  label: string
): Node<RFNode<CloudFlowNodeType>> => ({
  ...newNode,
  data: {
    ...newNode.data,
    nodeData: {
      ...newNode.data.nodeData,
      transitions: [
        {
          targetNodeId,
          label,
        },
      ],
    },
  },
});

const handleDeleteActions = (
  flowId: string,
  currentTargetNode: Node<RFNode<CloudFlowNodeType>>,
  incomer: Node<RFNode<CloudFlowNodeType>>,
  outgoers: Node<RFNode<CloudFlowNodeType>>[]
) => {
  const newNode = initializeNode(CloudFlowNodeType.CONDITION, uuidv4());
  const conditionNodePayload = mapToUpdateNodePayload(newNode);
  const nodesToDelete = [...outgoers.filter((n) => n.type !== CloudFlowNodeType.GHOST), currentTargetNode];
  const updateNodesPayload = {
    flowId,
    addedNode: {
      node: conditionNodePayload,
      transition: {
        parentNodeId: incomer.id,
        targetNodeId: newNode.id,
      },
    },

    deletedNodes: nodesToDelete.map((n) => n.id),
  };
  return updateNodesPayload;
};

const handleMoveActions = (
  flowId: string,
  currentTargetNode: Node<RFNode<CloudFlowNodeType>>,
  moveToTrue: boolean,
  incomer: Node<RFNode<CloudFlowNodeType>>
) => {
  const newNode = initializeNode(CloudFlowNodeType.CONDITION, uuidv4());
  const newNodeWithTransitions = updateConditionNodeWithTransitions(
    newNode,
    currentTargetNode.id,
    moveToTrue ? "True" : "False"
  );
  const conditionNodePayload = mapToUpdateNodePayload(newNodeWithTransitions);
  const updateNodesPayload = {
    flowId,
    addedNodes: [
      {
        node: conditionNodePayload,
        transition: {
          parentNodeId: incomer.id,
          targetNodeId: newNode.id,
        },
      },
    ],
  };
  return updateNodesPayload;
};

const handleDeleteIfNode = (
  flowId: string,
  nodeToDelete: Node<RFNode<CloudFlowNodeType>>,
  outgoers: Node<RFNode<CloudFlowNodeType>>[]
) => {
  const nodesToDelete = [...outgoers].filter((n) => n.type !== CloudFlowNodeType.GHOST);
  const updateNodesPayload = {
    flowId,
    deletedNodes: [...nodesToDelete.map((n) => n.id), nodeToDelete.id],
  };
  return updateNodesPayload;
};

const utils = {
  handleDeleteActions,
  handleMoveActions,
  handleDeleteIfNode,
};

export default utils;
