import { useMemo } from "react";

import { type Member, type StructureApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Alert, Autocomplete, FormControl, Link, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormikContext } from "formik";
import noop from "lodash/noop";

import { useCustomerId } from "../../../../Components/hooks/useCustomerId";
import { AccountStatus } from "../../../../Pages/Settings/AWS/types";
import { useAWSAccounts } from "../../../Settings/AWS/hooks";
import { ALL_ACCOUNTS } from "../../consts";
import { useAwsData } from "../../hooks";
import { withRootPath } from "./CloudSpecificParameterForm";
import { useFieldCommonProps } from "./useFieldCommonProps";

const ALL_REGIONS = "All regions";

const allAccountsOption = {
  accountId: ALL_ACCOUNTS,
  status: AccountStatus.healthy,
  properties: {
    name: "",
  },
};

const AWSParameterForm = ({
  inputModel,
  rootPath,
}: {
  inputModel: StructureApiServiceModelDescriptor<Member>;
  rootPath?: string;
}) => {
  const customerId = useCustomerId();
  const [accounts] = useAWSAccounts();
  const formikProps = useFormikContext<{ accountId: string; regions: string[] }>();

  const accountIdProps = useFieldCommonProps<string>(
    formikProps.getFieldProps(withRootPath("accountId", rootPath)),
    "Account",
    true
  );
  const regionsProps = useFieldCommonProps(
    formikProps.getFieldProps(withRootPath("regions", rootPath)),
    "Regions",
    false
  );

  const { regions } = useAwsData(
    accountIdProps.value,
    "regions" in inputModel.members && Boolean(accountIdProps.value)
  );

  const accountOptions = useMemo(() => [allAccountsOption, ...accounts], [accounts]);

  const selectedAccount = useMemo(
    () => accountOptions.find((account) => account.accountId === accountIdProps.value) || null,
    [accountOptions, accountIdProps]
  );

  const showWarning = selectedAccount?.status === AccountStatus.notConfigured;

  return (
    <Stack
      sx={{
        gap: 2,
      }}
    >
      <FormControl>
        <Autocomplete
          options={accountOptions}
          groupBy={(option) =>
            option.status === AccountStatus.notConfigured ? "Unlinked accounts" : "Linked accounts"
          }
          getOptionLabel={(option) =>
            option.properties?.name ? `${option.accountId} (${option.properties.name})` : option.accountId
          }
          renderOption={(props, option) => (
            <MenuItem {...props} key={option.accountId} value={option.accountId}>
              {option.properties?.name ? `${option.accountId} (${option.properties.name})` : option.accountId}
            </MenuItem>
          )}
          onChange={(_, value) => {
            formikProps.setFieldValue(accountIdProps.name, value?.accountId || "");
          }}
          renderInput={(params) => (
            <TextField {...params} {...accountIdProps} type="text" fullWidth variant="outlined" />
          )}
          size="small"
          fullWidth
          value={selectedAccount}
        />
      </FormControl>
      {showWarning && (
        <Alert severity="warning">
          <Stack direction="row" spacing={2} alignItems="center">
            <Typography
              variant="body2"
              sx={{
                fontWeight: 400,
              }}
              flexShrink={1}
            >
              Connect your AWS account to use CloudFlow.
            </Typography>
            <Link
              href={`/customers/${customerId}/settings/aws/link`}
              target="_blank"
              underline="hover"
              color="inherit"
              flexShrink={0}
            >
              Link account
            </Link>
          </Stack>
        </Alert>
      )}
      {"regions" in inputModel.members && (
        <FormControl>
          <Autocomplete
            multiple
            options={regions}
            onChange={async (_, value) => formikProps.setFieldValue(regionsProps.name, value)}
            renderOption={(props, option) => (
              <MenuItem {...props} value={option}>
                {option}
              </MenuItem>
            )}
            disableCloseOnSelect
            getOptionDisabled={(option) => regionsProps.value?.includes(ALL_REGIONS) && option !== ALL_REGIONS}
            disabled={regions.length === 0}
            filterSelectedOptions
            renderInput={(params) => {
              const { InputProps, ...rest } = params;
              if (
                (!Array.isArray(regionsProps.value) || regionsProps.value.length === 0) &&
                !rest.inputProps["aria-expanded"] &&
                !rest.inputProps.value
              ) {
                InputProps.startAdornment = (
                  <Typography
                    sx={{
                      pl: 1,
                    }}
                  >
                    {ALL_REGIONS}
                  </Typography>
                );
              }
              return (
                <TextField
                  {...rest}
                  {...regionsProps}
                  onChange={noop}
                  fullWidth
                  variant="outlined"
                  slotProps={{
                    input: InputProps,
                  }}
                />
              );
            }}
            size="small"
            fullWidth
            value={regionsProps.value ?? []}
          />
        </FormControl>
      )}
    </Stack>
  );
};

export default AWSParameterForm;
