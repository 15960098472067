import { createContext, type ReactNode, useContext, useEffect, useMemo, useRef, useState } from "react";

import { type SupportZendeskTicketModel, type UserModel } from "@doitintl/cmp-models";

import useAssigneeUsers from "../hooks/useAssigneeUsers";
import { useListZendeskTickets } from "../hooks/useListZendeskTickets";

type ZendeskTicketsContextType = {
  zendeskTickets: SupportZendeskTicketModel[];
  loading: boolean;
  error: Error | undefined;
  assigneeUsers: Map<string, UserModel>;
  assigneeUsersLoading: boolean;
};

const ZendeskTicketsContext = createContext<ZendeskTicketsContextType | undefined>(undefined);

export function ZendeskTicketsContextProvider({ children }: { children: ReactNode }) {
  const [tickets, loading, error] = useListZendeskTickets();
  const [isSettled, setIsSettled] = useState(false);
  const timeoutRef = useRef<number>();

  // Filter out Ava conversation tickets, matching the logic in TicketsContainer
  const filteredTickets = useMemo(
    () => (tickets ?? []).filter((t) => !t.ticket.description.includes("created a new chat called")),
    [tickets]
  );

  const { assigneeUsers, assigneeUsersLoading } = useAssigneeUsers(filteredTickets);

  // Reset the settled state whenever loading changes to true
  useEffect(() => {
    if (loading) {
      setIsSettled(false);
    } else {
      // When loading becomes false, wait a tick before marking as settled
      // This gives time for the data to actually populate
      timeoutRef.current = window.setTimeout(() => {
        setIsSettled(true);
      }, 0);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [loading]);

  const contextValue = useMemo(
    () => ({
      zendeskTickets: filteredTickets,
      loading: loading || !isSettled, // Consider it loading until settled
      error,
      assigneeUsers,
      assigneeUsersLoading,
    }),
    [filteredTickets, loading, isSettled, error, assigneeUsers, assigneeUsersLoading]
  );

  return <ZendeskTicketsContext.Provider value={contextValue}>{children}</ZendeskTicketsContext.Provider>;
}

export function useZendeskTicketsContext() {
  const context = useContext(ZendeskTicketsContext);
  if (context === undefined) {
    throw new Error("useZendeskTickets must be used within a ZendeskTicketsProvider");
  }
  return context;
}
