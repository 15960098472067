import { useCallback, useEffect, useState } from "react";

import {
  type Cluster,
  ClusterType,
  type ClusterWithCloudConnect,
  type KubernetesFeature,
  KubernetesFeatureStatus,
} from "@doitintl/cmp-models";
import { Box } from "@mui/material";
import { type AxiosResponse } from "axios";

import { useApiContext } from "../../../api/context";
import { Stepper } from "../../../Components/Stepper";
import { AWSFeatureName } from "../../Settings/AWS/types";
import { type GCPCloudConnectData } from "../../Settings/GCP/useCloudConnectData";
import { activateCluster } from "../api";
import { useClustersWithCloudConnect } from "../hooks";
import { useKubernetesClusterActivation } from "./hooks";
import {
  EnableFeaturesStep,
  SelectClustersStep,
  SelectClusterTypeStep,
  SelectFeaturesStep,
  TestConnectionStep,
} from "./Steps";
import { type StepConfig, useSteps } from "./utils";

const BulkClusterActivation = () => {
  const api = useApiContext();
  const [clusters] = useClustersWithCloudConnect();
  const { currentStep, setCurrentStep, goBack } = useKubernetesClusterActivation();

  const [selectedClusterType, setSelectedClusterType] = useState<ClusterType | null>(null);
  const [selectedCloudConnect, setSelectedCloudConnect] = useState<string | null | GCPCloudConnectData>(null);
  const [selectedClusterIds, setSelectedClusterIds] = useState<Cluster["id"][]>([]);
  const [selectedFeatures, setSelectedFeatures] = useState<KubernetesFeature[]>([]);

  const [clusterSelectorTouched, setClusterSelectorTouched] = useState(false);

  const selectedClusters: ClusterWithCloudConnect[] = selectedClusterIds
    .map((id) => clusters?.find((c) => c.id === id))
    .filter(Boolean) as ClusterWithCloudConnect[];

  const partiallyHealthyClusters = selectedClusters.filter((cluster) =>
    selectedFeatures.some((f) => cluster.features?.[f.id]?.status === KubernetesFeatureStatus.HEALTHY)
  );

  const filterClustersByCloudConnect = useCallback(
    (c: ClusterWithCloudConnect) => {
      if (!selectedCloudConnect) return false;

      return selectedClusterType === ClusterType.EKS
        ? c.projectId === (selectedCloudConnect as string)
        : c.cloudConnect?.clientId === (selectedCloudConnect as GCPCloudConnectData)?.data?.clientId;
    },
    [selectedCloudConnect, selectedClusterType]
  );

  const preselectAllClusters = useCallback(() => {
    if (clusterSelectorTouched || !clusters || selectedClusterIds.length > 0) return;

    const newSelectedClusterIds = clusters.filter(filterClustersByCloudConnect).map((c) => c.id);
    if (newSelectedClusterIds.length > 0) {
      setClusterSelectorTouched(true);
      setSelectedClusterIds(newSelectedClusterIds);
    }
  }, [clusters, selectedClusterIds, clusterSelectorTouched, filterClustersByCloudConnect]);

  useEffect(() => {
    preselectAllClusters();
  }, [preselectAllClusters]);

  const handleSubmit = async () => {
    const promises: Promise<AxiosResponse<void>>[] = [];
    partiallyHealthyClusters.forEach((c) => {
      promises.push(activateCluster(api, c.id));
    });

    await Promise.all(promises);
    goBack();
  };

  const resetClusterState = () => {
    setSelectedClusterIds([]);
    setSelectedFeatures([]);
    setClusterSelectorTouched(false);
  };

  const handleClusterTypeSelect = (type: ClusterType) => {
    setSelectedClusterType(type);
    setSelectedCloudConnect(null);
    resetClusterState();
  };

  const handleCloudConnectSelect = (cloudConnect: string | null | GCPCloudConnectData) => {
    setSelectedCloudConnect(cloudConnect);
    resetClusterState();
  };

  const handleSelectClusters = (ids: Cluster["id"][]) => {
    setSelectedClusterIds(ids);
    if (!clusterSelectorTouched) {
      setClusterSelectorTouched(true);
    }
  };

  const getSelectedProjectId = () => {
    if (selectedClusterType === ClusterType.EKS) {
      return selectedCloudConnect as string;
    }

    if (selectedClusterType === ClusterType.GKE) {
      const gcpCloudConnect = selectedCloudConnect as GCPCloudConnectData;
      if (!gcpCloudConnect) return "";

      return (
        (gcpCloudConnect.data.scope === "organization"
          ? gcpCloudConnect.data.organizations?.[0].displayName
          : gcpCloudConnect.data.projectId) ?? gcpCloudConnect.id
      );
    }

    return "";
  };

  const selectedProjectId = getSelectedProjectId();

  const stepConfig: StepConfig[] = [
    {
      label: "Select type",
      isComplete: !!selectedClusterType && !!selectedCloudConnect,
      children: (
        <SelectClusterTypeStep
          selectedProjectId={selectedCloudConnect}
          selectedType={selectedClusterType}
          onSelectType={handleClusterTypeSelect}
          onSelectProjectId={handleCloudConnectSelect}
          targetAwsFeatures={[AWSFeatureName.fsk8s_core]}
          targetGcpCategories={["fsk8s-core"]}
        />
      ),
    },
    {
      label: "Select clusters",
      isComplete: !!selectedClusterType && selectedClusters.length > 0,
      children: (
        <SelectClustersStep
          projectId={selectedProjectId}
          onSelect={handleSelectClusters}
          selectedClusters={selectedClusters}
          clusterType={selectedClusterType as ClusterType}
          filterAvailableClusters={filterClustersByCloudConnect}
          gcpScope={
            selectedClusterType === ClusterType.GKE
              ? (selectedCloudConnect as GCPCloudConnectData)?.data?.scope
              : undefined
          }
        />
      ),
    },
    {
      label: "Select features",
      isComplete: selectedFeatures.length > 0,
      children: (
        <SelectFeaturesStep
          clusters={selectedClusters}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
        />
      ),
    },

    {
      label: "Enable features",
      children: (
        <EnableFeaturesStep
          clusters={selectedClusters}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
        />
      ),
    },
    {
      label: "Test connection",
      isComplete: partiallyHealthyClusters.length > 0,
      children: (
        <TestConnectionStep
          clusters={selectedClusters}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
        />
      ),
    },
  ];

  const steps = useSteps({ stepConfig, currentStep });

  return (
    <Box
      sx={{
        pt: 4,
        pb: 5,
      }}
    >
      <Stepper
        submitButtonLabel="Done"
        steps={steps}
        loading={false}
        onCancel={goBack}
        onSubmit={handleSubmit}
        overrideStep={currentStep}
        getCurrentStep={setCurrentStep}
        footerMaxWidth={925}
        maxWidth={1000}
        contentSx={{
          mt: 3,
          mb: 3,
        }}
      />
    </Box>
  );
};

export default BulkClusterActivation;
