import { type Cluster, type KubernetesFeature, KubernetesFeatureStatus } from "@doitintl/cmp-models";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, CircularProgress, Typography } from "@mui/material";

import ClusterStatusDescription from "./ClusterStatusDescription";

interface ClusterFeatureConnectionStatusProps {
  cluster: Cluster;
  feature: KubernetesFeature;
  loading: boolean;
}

const ClusterFeatureConnectionStatus = ({ cluster, feature, loading }: ClusterFeatureConnectionStatusProps) => {
  const clusterFeature = cluster.features?.[feature.id] ?? null;

  if (!clusterFeature) {
    return <CircularProgress size={24} />;
  }

  const featureIsHealthy = clusterFeature.status === KubernetesFeatureStatus.HEALTHY;

  const getIcon = () => {
    if (loading) {
      return <CircularProgress size={24} />;
    }

    if (featureIsHealthy) {
      return <CheckCircleIcon color="success" />;
    }

    return <ErrorIcon color="error" />;
  };

  const renderContent = () => {
    if (!featureIsHealthy) {
      return (
        <Accordion elevation={0} style={{ width: "100%" }}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography fontWeight={400} variant="body1">
              {cluster.name}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography sx={{ width: "100%" }} variant="body2" color="text.secondary">
              <ClusterStatusDescription featureIsHealthy={featureIsHealthy} issues={clusterFeature.issues || []} />
            </Typography>
          </AccordionDetails>
        </Accordion>
      );
    }

    return (
      <Box sx={{ display: "flex", alignItems: "center", pt: 1, pb: 1, pl: 2, pr: 2 }}>
        <Typography sx={{ mt: "4px", mb: "4px" }} variant="body1" fontWeight={400}>
          {cluster.name}
        </Typography>
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", gap: 2, pl: 2, pr: 2, pt: 0, pb: 0 }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>{getIcon()}</Box>
      <Box sx={{ width: "100%" }}>{renderContent()}</Box>
    </Box>
  );
};

export default ClusterFeatureConnectionStatus;
