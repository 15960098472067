import { budgetText } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type Budget } from "../../../types";
import { type DataColumn } from "../../../types/FilterTable";

export const itemsDescriptions: FilterTableItemDescriptor<Budget>[] = [
  {
    value: "data.name",
    headerText: budgetText.TABLE_HEADERS.NAME,
    tooltip: budgetText.TABLE_HEADERS.NAME,
    headerStyle: { align: "left", padding: "normal" },
    filterType: "string",
  },
  {
    value: "data.owner",
    headerText: budgetText.TABLE_HEADERS.OWNER,
    tooltip: budgetText.TABLE_HEADERS.OWNER,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    filterType: "string",
  },
  {
    value: "data.period",
    headerText: budgetText.TABLE_HEADERS.PERIOD,
    tooltip: budgetText.TABLE_HEADERS.PERIOD,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    disableSorting: true,
    filterType: "string",
  },
  {
    value: "data.config.amount",
    headerText: budgetText.TABLE_HEADERS.BUDGET_AMOUNT,
    tooltip: budgetText.TABLE_HEADERS.BUDGET_AMOUNT,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { xsDown: true },
    filterType: "number",
  },
  {
    value: "data.budgetAmountToDate",
    headerText: budgetText.TABLE_HEADERS.BUDGET_AMOUNT_TO_DATE,
    tooltip: budgetText.TABLE_HEADERS.BUDGET_AMOUNT_TO_DATE,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { xsDown: true },
    filterType: "number",
  },
  {
    value: "data.utilization.current",
    headerText: budgetText.TABLE_HEADERS.ACTUALS_TO_DATE,
    tooltip: budgetText.TABLE_HEADERS.ACTUALS_TO_DATE,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterType: "number",
  },
  {
    value: "data.varianceToDate",
    headerText: budgetText.TABLE_HEADERS.VARIANCE_TO_DATE,
    tooltip: budgetText.TABLE_HEADERS.VARIANCE_TO_DATE,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { mdDown: true },
    filterType: "number",
  },
  {
    value: "data.currentPercentage",
    headerText: budgetText.TABLE_HEADERS.CURRENT_PCT,
    tooltip: budgetText.TABLE_HEADERS.CURRENT_PCT,
    headerStyle: { align: "left", padding: "normal" },
    disableSorting: true,
    hidden: { xsDown: true },
    filterType: "number",
  },
  {
    value: "data.timeCreated",
    headerText: budgetText.TABLE_HEADERS.TIME_CREATED,
    tooltip: budgetText.TABLE_HEADERS.TIME_CREATED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    hiddenByDefault: true,
    filterType: "date",
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    value: "data.timeModified",
    headerText: budgetText.TABLE_HEADERS.LAST_MODIFIED,
    tooltip: budgetText.TABLE_HEADERS.LAST_MODIFIED,
    headerStyle: { align: "left", padding: "normal" },
    hidden: { smDown: true },
    hiddenByDefault: true,
    filterType: "date",
    filterOptions: {
      comparators: ["<", "<=", ">", ">="],
      ...firestoreTimestampFilterProps("yyyy-LL-dd"),
    },
  },
  {
    value: "@",
    headerText: "",
    headerStyle: { align: "left", padding: "normal" },
    disableSorting: true,
  },
];

const additionalFilters: DataColumn<Budget>[] = [{ comparators: ["=="], label: "ID", path: "snapshot.id" }];

const { headerColumns, filters: baseFilters } = generateHeadersAndFilters(itemsDescriptions);
const filters = [...additionalFilters, ...baseFilters];

export { filters, headerColumns };
