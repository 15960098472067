import { useCallback, useState } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import { type Node, useNodesState, useReactFlow } from "@xyflow/react";

import { type RFNode } from "../../../types";

export const useReferencedNodeLookup = () => {
  const { getNodes } = useReactFlow<Node<RFNode>>();
  const [_, setNodes] = useNodesState<Node<RFNode>>([]);
  const [nodesWithReferencedNode, setNodesWithReferencedNode] = useState<Node<RFNode<CloudFlowNodeType>>[]>([]);
  const findNodesWithReferencedNode = useCallback(
    (nodeId: string) => {
      const currentNodes = getNodes();
      const nodeIsReferencedFor = [] as Node<RFNode<CloudFlowNodeType>>[];
      for (const node of currentNodes) {
        const parameters = node.data.nodeData.parameters;
        if (parameters && "referencedNodeId" in parameters && parameters.referencedNodeId === nodeId) {
          nodeIsReferencedFor.push(node);
        }
      }

      setNodesWithReferencedNode(nodeIsReferencedFor);
      return nodeIsReferencedFor;
    },
    [getNodes]
  );

  const cleanupNodesWithReferencedNode = useCallback(() => {
    const referencedNodeIds = new Set(nodesWithReferencedNode.map((node) => node.id));

    setNodes((prev) =>
      prev.map((node) => ({
        ...node,
        data: {
          ...node.data,
          nodeData: {
            ...node.data.nodeData,
            parameters: referencedNodeIds.has(node.id)
              ? { referencedNodeId: "", referencedField: [], transformations: [] }
              : node.data.nodeData.parameters,
          },
        },
      }))
    );
    setNodesWithReferencedNode([]);
    return null;
  }, [nodesWithReferencedNode, setNodes]);

  return {
    nodesWithReferencedNode,
    findNodesWithReferencedNode,
    cleanupNodesWithReferencedNode,
  };
};
