import { useEffect, useMemo, useState } from "react";

import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { type RouteComponentProps } from "react-router";
import { EarlyAccessFeature, TierPackageTypes, type UserModel } from "@doitintl/cmp-models";
import { Container, Stack, Typography } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

import Hide from "../../Components/HideChildren/Hide";
import { useFeatureFlag } from "../../Components/hooks/useFeatureFlag";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useTier } from "../../Context/TierProvider";
import { useUserContext } from "../../Context/UserContext";
import TierTrialEndAlert from "../../Navigation/Components/HeaderAlerts/TrialEnd/TierTrialEndAlert";
import { consoleErrorWithSentry } from "../../utils";
import { getCustomerTierTrialDates } from "../../utils/tiers";
import {
  downloadTickets,
  initTicketList,
  loadTickets,
  sendNewDefaultEmailCC,
  toggleOrganizationSharing,
} from "../Actions/TicketAction";
import DefaultEmailCCDialog from "../Components/DefaultEmailCCDialog";
import { HeaderCards } from "../Components/HeaderCards";
import HeaderEssentials from "../Components/HeaderEssentials";
import { PlatformFilters } from "../Components/PlatformFilters";
import { SettingsButton } from "../Components/SettingsButton";
import SnackbarLoader from "../Components/SnackbarLoader";
import SupportTicketsTable from "../Components/TicketsFilterTable/SupportTicketsTable";
import TicketsFilterTable from "../Components/TicketsFilterTable/TicketsFilterTable";
import { ticketsCsvFileName } from "../consts";
import { useZendeskTicketsContext } from "../Context/ZendeskTicketsContext";
import { useFilterCloud } from "../hooks/useFilterCloud";
import { useFilterCloudFirestore } from "../hooks/useFilterCloudFirestore";
import { useIsDCIEssentialsTier } from "../hooks/useIsDCIEssentialsTier";
import { type Organization, type Ticket, type User } from "../types";

type Params = {
  customerId: string;
};

type FunctionProps = {
  loadTickets: ({ isDoitEmployee, userId }: { isDoitEmployee: boolean; userId: string }) => void;
  downloadTickets: () => void;
  initTicketList: () => void;
  toggleOrganizationSharing: (org: Organization) => void;
  sendNewDefaultEmailCC: (data: string[]) => void;
};

type StateProps = {
  ticketList: Ticket[];
  isFetchingTicketList: boolean;
  organization: Organization;
  userList: User[];
  isDownloadingCSV: boolean;
  defaultEmailsList: string[];
  customerUsers: UserModel[];
};

type Props = RouteComponentProps<Params> & FunctionProps & StateProps;

const TicketsContainer = ({
  ticketList,
  loadTickets,
  userList,
  isFetchingTicketList,
  organization,
  isDownloadingCSV,
  defaultEmailsList,
  customerUsers,
  downloadTickets,
  toggleOrganizationSharing,
  sendNewDefaultEmailCC,
  history,
  match,
}: Props) => {
  const { currentUser, isDoitEmployee, userId } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const { userRoles } = useUserContext();
  const { isActiveTrialCustomer } = useTier();
  const isDCIEssentials = useIsDCIEssentialsTier();
  const { zendeskTickets, loading: ticketsLoading, error: ticketsError } = useZendeskTicketsContext();
  const [showLoadingSnackbar, setShowLoadingSnackbar] = useState(false);
  const newModeFeatureFlag = useFeatureFlag(EarlyAccessFeature.SUPPORT_FIRESTORE);
  const [forceOldTable, setForceOldTable] = useState(false);

  const fromFirestore = useMemo<boolean>(
    () => newModeFeatureFlag && !forceOldTable,
    [newModeFeatureFlag, forceOldTable]
  );

  const { t } = useTranslation("services");

  const [isDefaultEmailsDialogOpen, setIsDefaultEmailsDialogOpen] = useState(false);

  const currentTicketList = useMemo(
    () =>
      ticketList
        .map((ticket) => {
          ticket.requester = userList.find((u) => ticket.requester_id === u.id)?.email ?? "";
          return ticket;
        })
        .filter((ticket) => ticket.is_public || isDoitEmployee),
    [ticketList, userList, isDoitEmployee]
  );

  useEffect(() => {
    if (customer.id !== match.params.customerId) {
      initTicketList();
    }
    loadTickets({ isDoitEmployee, userId });
  }, [customer.id, match.params.customerId, isDoitEmployee, userId, loadTickets]);

  useEffect(() => {
    if (ticketsError) {
      consoleErrorWithSentry(ticketsError);
    }
  }, [ticketsError]);

  const isCurrentUserUserManager = useMemo(() => !!userRoles?.usersManager || !!userRoles?.doitEmployee, [userRoles]);

  const createNewRequest = () => {
    history.push(`support/new`);
  };

  // exclude tickets for ava conversations
  const prefiltered = useMemo(
    () => currentTicketList.filter((t) => !t.description.includes("created a new chat called")),
    [currentTicketList]
  );

  const { selectedFilter, onClickFilter, filteredTickets, platformsAvailable } = useFilterCloud(prefiltered);

  const { filteredTicketsFirestore, onClickSupportFilter, selectedPlatformFilter, supportPlatformsAvailable } =
    useFilterCloudFirestore(zendeskTickets);

  const { start, end } = getCustomerTierTrialDates(customer, TierPackageTypes.SOLVE);

  return (
    <>
      {isDCIEssentials ? (
        <HeaderEssentials onClickOpenRequest={createNewRequest} />
      ) : (
        <HeaderCards
          onClickOpenRequest={createNewRequest}
          orgClassification={organization.organization_fields?.classification}
          customerClassification={customer?.classification}
        />
      )}
      <Container maxWidth="xl">
        {isActiveTrialCustomer(TierPackageTypes.SOLVE) && end && (
          <TierTrialEndAlert solveTrialStartDate={start} solveTrialEndDate={end} />
        )}
        <Snackbar
          open={isDownloadingCSV}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        >
          <Alert icon={false} elevation={6} variant="filled" severity="info">
            Downloading {ticketsCsvFileName} ...
          </Alert>
        </Snackbar>

        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            pt: 5,
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: "500",
            }}
          >
            {t("TicketsContainer.title")}
          </Typography>
          <Stack
            direction="row"
            spacing={2}
            sx={{
              alignItems: "center",
            }}
          >
            <PlatformFilters
              onClickFilter={fromFirestore ? onClickSupportFilter : onClickFilter}
              selectedFilter={fromFirestore ? selectedPlatformFilter : selectedFilter}
              platformFilters={fromFirestore ? supportPlatformsAvailable : platformsAvailable}
            />
            {userRoles?.billingProfilesAdmin && (
              <Hide smDown>
                <SettingsButton
                  toggleOrganizationSharing={toggleOrganizationSharing}
                  organization={organization}
                  downloadTickets={downloadTickets}
                  setIsDefaultEmailsDialogOpen={setIsDefaultEmailsDialogOpen}
                  isCurrentUserUserManager={!!isCurrentUserUserManager}
                  loadTickets={loadTickets}
                />
              </Hide>
            )}
          </Stack>
        </Stack>
        {fromFirestore ? (
          <SupportTicketsTable
            zendeskTickets={filteredTicketsFirestore}
            ticketsLoading={ticketsLoading}
            isDoitEmployee={isDoitEmployee}
            setShowLoadingSnackbar={setShowLoadingSnackbar}
            setForceOldTable={setForceOldTable}
          />
        ) : (
          <TicketsFilterTable
            isDoitEmployee={isDoitEmployee}
            currentUser={currentUser}
            currentTicketList={filteredTickets}
            customerClassification={customer?.classification}
            customerId={customer?.id}
            loading={isFetchingTicketList}
          />
        )}
        <DefaultEmailCCDialog
          users={customerUsers}
          open={isDefaultEmailsDialogOpen}
          onClose={() => {
            setIsDefaultEmailsDialogOpen(false);
          }}
          defaultEmailsListFromDB={defaultEmailsList}
          sendNewDefaultEmailCC={sendNewDefaultEmailCC}
        />

        <SnackbarLoader text="Loading requests" open={fromFirestore ? showLoadingSnackbar : isFetchingTicketList} />
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  loadTickets: ({ isDoitEmployee, userId }: { isDoitEmployee: boolean; userId: string }) =>
    dispatch(loadTickets({ isDoitEmployee, userId })),
  downloadTickets: () => dispatch(downloadTickets()),
  initTicketList: () => dispatch(initTicketList()),
  toggleOrganizationSharing: (org) => dispatch(toggleOrganizationSharing(org)),
  sendNewDefaultEmailCC: (data) => dispatch(sendNewDefaultEmailCC(data)),
});

type TicketsStateProps = {
  tickets: StateProps;
};

const mapStateToProps = (state: TicketsStateProps) => ({
  ticketList: state.tickets.ticketList,
  isFetchingTicketList: state.tickets.isFetchingTicketList,
  organization: state.tickets.organization,
  userList: state.tickets.userList,
  isDownloadingCSV: state.tickets.isDownloadingCSV,
  defaultEmailsList: state.tickets.defaultEmailsList,
  customerUsers: state.tickets.customerUsers,
});

export default connect(mapStateToProps, mapDispatchToProps)(TicketsContainer);
