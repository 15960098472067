import { useState } from "react";

import { useParams } from "react-router";

import LoadingButton from "../../../../../../Components/LoadingButton";
import { useErrorSnackbar } from "../../../../../Integrations/Slack/utils";
import contractHooks, { useBackToList } from "../../../../hooks";

export const UndoCancellationButton = () => {
  const { contractId } = useParams<{ contractId: string }>();
  const [loading, setLoading] = useState(false);
  const updateContractStatus = contractHooks.useUpdateContractStatus();
  const backToList = useBackToList();
  const errorSnackbar = useErrorSnackbar();

  return (
    <LoadingButton
      mixpanelEventId=""
      data-cy="undo-cancel-contract-button"
      variant="outlined"
      color="primary"
      loading={loading}
      onClick={() => {
        setLoading(true);
        updateContractStatus(
          contractId,
          { active: true, terminated: false },
          () => {
            setLoading(false);
            backToList();
          },
          () => {
            setLoading(false);
            errorSnackbar("Failed to undo cancellation of contract");
          }
        );
      }}
    >
      Undo cancellation
    </LoadingButton>
  );
};
