import { type CloudflowExecutionStatus } from "@doitintl/cmp-models";

import { cloudflowTexts } from "../../../assets/texts";
import { firestoreTimestampFilterProps } from "../../../Components/EnhancedTableFilterUtils";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../Components/FilterTable/filterTableUtils";
import { type CloudflowExecutionSnap } from "../../../types/Cloudflow";
import { executionStatusNames } from "../consts";

export const itemsDescriptions: FilterTableItemDescriptor<CloudflowExecutionSnap>[] = [
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.INSTANCE,
    value: "data.cloudflow.data.name",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.STATUS,
    value: "data.status",
    filterType: "string",
    filterOptions: {
      toOption: (value: `${CloudflowExecutionStatus}`) => ({
        value,
        label: executionStatusNames[value],
      }),
    },
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.TRIGGERED_BY,
    value: "data.triggeredBy",
    filterType: "string",
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.TIME_COMPLETED,
    value: "data.endTime",
    filterType: "date",
    filterOptions: firestoreTimestampFilterProps("yyyy-LL-dd"),
  },
  {
    headerText: cloudflowTexts.HISTORY.TABLE_HEADERS.TIME_TAKEN,
    value: "@",
  },
  {
    headerText: "Execution ID",
    hiddenByDefault: true,
    value: "ref.id",
    filterType: "string",
  },
  {
    headerText: "CloudFlow ID",
    hiddenByDefault: true,
    value: "data.cloudflowId",
    filterType: "string",
  },
];

const { headerColumns, filters } = generateHeadersAndFilters(itemsDescriptions);

// NOTE: filter table doesnt provide hiding headers unless you are also showing the column selection
// so manually filtering them out here for the same effect
const columns = headerColumns.filter((c) => !c.hiddenByDefault);

export { columns, filters };
