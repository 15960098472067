import { useCallback, useEffect } from "react";

import { type CloudFlowNodeType, CloudFlowProvider } from "@doitintl/cmp-models";
import { Avatar, Button, Card, Stack, Typography } from "@mui/material";
import isEqual from "lodash/isEqual";

import { Loader } from "../../../../../Components/Loader";
import {
  ApiActionParametersForm,
  GenericApiActionParametersForm,
} from "../../ApiActionParametersForm/ApiActionParametersForm";
import CloudSpecificParameterForm from "../../ApiActionParametersForm/CloudSpecificParameterForm";
import { ReferencedFieldContextProvider } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { useModalManager } from "../../Common/CloudflowModalsProvider";
import { useCloudflowOperations } from "../../Common/CloudflowOperationsProvider";
import DisablePublishedFlowGuard from "../../Common/DisablePublishedFlowGuard";
import { useGetOperationById } from "../../Common/hooks/useGetOperationById";
import { useReferenceableNodes } from "../../Common/hooks/useReferenceableNodes";
import { useUnwrappedApiActionModel } from "../../Common/hooks/useUnwrappedApiActionModel";
import { useApiProviderLogo } from "../../Common/utils";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";
import Approval from "./Approval/Approval";
import { useErrorUpdates, useFormValidation } from "./hooks";
import { NodeCardHeader } from "./NodeCardHeader";

const APIParametersTab = () => {
  const { nodeConfig, updateNode } = useNodeConfigurationContext<CloudFlowNodeType.ACTION>();
  const { openModal } = useModalManager();
  const {
    operationData: { operation, operationPointer },
    loading: isOperationLoading,
  } = useGetOperationById(nodeConfig.parameters.operation);
  const {
    modelId,
    model,
    loading: isModelLoading,
  } = useUnwrappedApiActionModel(operationPointer, operation?.inputModel);
  const { httpOperationLoading } = useCloudflowOperations();

  const providerLogo = useApiProviderLogo();

  const { configurationFormValid, setConfigurationFormValid, inputModelValid, setInputModelValid } = useFormValidation({
    operation,
    model,
  });

  const isFormLoading =
    isOperationLoading ||
    isModelLoading ||
    configurationFormValid === undefined ||
    inputModelValid === undefined ||
    nodeConfig.name !== operation?.operationName;

  useErrorUpdates<CloudFlowNodeType.ACTION>({
    isValid: !!configurationFormValid && !!inputModelValid,
    errorKey: "param_error",
    errorMessage: "Incorrect form parameters",
    isFormLoading,
  });

  const onConfigurationValuesChange = useCallback(
    (configurationValues: unknown) => {
      if (isEqual(nodeConfig.parameters.configurationValues, configurationValues)) {
        return;
      }
      updateNode((prevNode) => ({
        parameters: { ...prevNode.parameters!, configurationValues: configurationValues as any },
      }));
    },
    [nodeConfig.parameters.configurationValues, updateNode]
  );

  useEffect(() => {
    if (!isOperationLoading && operation?.inputModel === undefined) {
      setInputModelValid(true);
    }
  }, [isOperationLoading, operation?.inputModel, setInputModelValid]);

  const onFormValuesChange = useCallback(
    (formValues: unknown) => {
      // NOTE: Can the APIActionForm be updated to know it returns object?
      if (formValues instanceof Object) {
        updateNode((prevNode) => ({ parameters: { ...prevNode.parameters!, formValues } }));
      }
    },
    [updateNode]
  );

  const [referenceableNodes, referenceableNodesLoading] = useReferenceableNodes(nodeConfig.id);

  return (
    <DisablePublishedFlowGuard>
      <Stack
        sx={{
          p: 2,
          justifyContent: "center",
          gap: 2,
        }}
      >
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          Service and action
        </Typography>
        <Card>
          <NodeCardHeader
            avatar={<Avatar src={providerLogo(nodeConfig.parameters.provider)} />}
            subheader={nodeConfig.parameters.operation.service}
            title={nodeConfig.parameters.operation.id}
            action={
              <Button
                disabled={httpOperationLoading}
                onClick={() => {
                  openModal("action");
                }}
              >
                Change
              </Button>
            }
          />
        </Card>
        <Approval />
        <Typography variant="subtitle2" sx={{ fontWeight: 500 }}>
          Parameters
        </Typography>
        <Loader loading={isOperationLoading || modelId !== operation?.inputModel || referenceableNodesLoading}>
          {operation?.parameters && (
            <ApiActionParametersForm
              key={nodeConfig.id}
              inputModel={operation.parameters}
              values={nodeConfig.parameters.configurationValues}
              onValidityChange={setConfigurationFormValid}
              onValuesChange={onConfigurationValuesChange}
              enableReinitialize={nodeConfig.parameters.provider === CloudFlowProvider.GCP}
            >
              <CloudSpecificParameterForm
                inputModel={operation.parameters}
                provider={nodeConfig.parameters.operation.provider}
              />
            </ApiActionParametersForm>
          )}
          {model !== null && (
            <ReferencedFieldContextProvider
              referenceableNodes={referenceableNodes}
              values={nodeConfig.parameters.formValues}
            >
              <GenericApiActionParametersForm
                key={nodeConfig.id}
                inputModel={model}
                values={nodeConfig.parameters.formValues}
                onValuesChange={onFormValuesChange}
                onValidityChange={setInputModelValid}
              />
            </ReferencedFieldContextProvider>
          )}
        </Loader>
      </Stack>
    </DisablePublishedFlowGuard>
  );
};

export default APIParametersTab;
