import { useCallback, useState } from "react";

import { useHistory } from "react-router";
import { CLOUD_FLOW_CREATION_STATUS } from "@doitintl/cmp-models";
import { Box, Container, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";
import noop from "lodash/noop";

import { cloudflowTexts } from "../../assets/texts";
import { cmpBaseColors } from "../../cmpBaseColors";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { CircularProgressLoader } from "../../Components/Loader";
import { useAuthContext } from "../../Context/AuthContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { type CustomerPageId } from "../../Navigation/config/pages";
import { type CloudflowWSnap } from "../../types/Cloudflow";
import BlueprintsRow from "./CloudflowBlueprints/BlueprintsRow";
import CloudflowBrowser from "./CloudflowBrowser/CloudflowBrowser";
import { useEditLink } from "./CloudflowBrowser/utils";
import { initialNodes } from "./CloudflowBuilder/hooks/consts";
import { CloudflowFAQ, CloudFlowOverview } from "./CloudflowDefaultState";
import { CLOUDFLOW_CONTENT_MAX_WIDTH } from "./consts";
import DeleteCloudFlowDialog from "./Dialog/DeleteCloudFlow";
import {
  useCloudflowBlueprints,
  useCloudflowExecutions,
  useCloudflows,
  useCreateCloudflow,
  useCreateCloudflowFromBlueprint,
  useDeleteCloudflow,
} from "./hooks";
import { type CloudflowBlueprint } from "./types";

type DashboardProps = {
  pageId: CustomerPageId;
};

const Dashboard = ({ pageId }: DashboardProps) => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(customer);

  const {
    currentUser: { email },
  } = useAuthContext({ mustHaveUser: true });
  const [deleteDialogId, setDeleteDialogId] = useState<string | null>(null);

  const { cloudflows, cloudflowsLoading } = useCloudflows();
  const { cloudflowExecutions, cloudflowExecutionsLoading } = useCloudflowExecutions();

  const { blueprints, blueprintsLoading } = useCloudflowBlueprints();
  const [createCloudflow, loading] = useCreateCloudflow();
  const [createCloudflowFromBlueprint, creatingCloudflowFromBlueprint] = useCreateCloudflowFromBlueprint();
  const [deleteCloudflow, deleteCloudflowLoading] = useDeleteCloudflow();

  const onViewRunHistory = useCallback(
    (row?: CloudflowWSnap) => {
      const pathname = `/customers/${customer.id}/cloudflow/history`;
      const state = row && !isPresentationMode ? { name: row.data.name } : undefined;

      history.push({
        pathname,
        state,
      });
    },
    [customer.id, history, isPresentationMode]
  );

  const onEditLink = useEditLink();

  const onEdit = useCallback(
    (id: string) => {
      const link = onEditLink(id);
      history.push(link);
    },
    [history, onEditLink]
  );

  const onUnpublish = useCallback(noop, []);

  const onDelete = useCallback((id: string) => {
    setDeleteDialogId(id);
  }, []);

  const deleteCloudFlow = useCallback(
    async (id: string) => {
      await deleteCloudflow(customer.id, { id });
    },
    [customer.id, deleteCloudflow]
  );

  const handelCloseDeleteDialog = useCallback(() => {
    setDeleteDialogId(null);
  }, []);

  const handleDelete = useCallback(async () => {
    if (!deleteDialogId) return;

    await deleteCloudFlow(deleteDialogId);
    handelCloseDeleteDialog();
  }, [deleteCloudFlow, handelCloseDeleteDialog, deleteDialogId]);

  const onNameClick = useCallback(
    (link) => {
      history.push(link);
    },
    [history]
  );

  const handleCreateCloudflow = useCallback(
    async (cloudflowName: string) => {
      const result = await createCloudflow(customer.id, {
        name: cloudflowName,
        status: CLOUD_FLOW_CREATION_STATUS.DRAFT,
        firstNodeId: initialNodes[0].id,
        nodes: initialNodes,
        collaborators: [{ email, role: "owner" }],
        type: isPresentationMode ? "demo" : "custom",
      });

      const cloudflowId = result?.data.id;

      if (cloudflowId) {
        history.push(`/customers/${customer.id}/cloudflow/edit/${cloudflowId}`);
      }
    },
    [email, createCloudflow, customer.id, history, isPresentationMode]
  );

  const onCreateCloudflow = useCallback(async () => {
    await handleCreateCloudflow("New Cloudflow");
  }, [handleCreateCloudflow]);

  const onClickViewAll = useCallback(() => {
    history.push(`/customers/${customer.id}/cloudflow/blueprints/`);
  }, [history, customer.id]);

  const onBlueprintClick = useCallback(
    async (blueprint: CloudflowBlueprint) => {
      if (isPresentationMode) {
        history.push(`/customers/${customer.id}/blueprint/${blueprint.id}`);
        return;
      }
      const res = await createCloudflowFromBlueprint(customer.id, blueprint);

      if (res) {
        history.push(`/customers/${customer.id}/cloudflow/edit/${res.id}`);
      }
    },
    [isPresentationMode, createCloudflowFromBlueprint, customer.id, history]
  );

  const isDarkMode = useDarkThemeCheck();

  const shouldRenderDefaultState =
    !cloudflowsLoading &&
    !cloudflowExecutionsLoading &&
    !cloudflows?.length &&
    (!cloudflowExecutions?.length || isPresentationMode);

  if (cloudflowsLoading || cloudflowExecutionsLoading || blueprintsLoading) {
    return <CircularProgressLoader />;
  }
  return (
    <>
      {pageId === "cloudflow" && (
        <>
          {shouldRenderDefaultState ? (
            <>
              <Container maxWidth="lg" sx={{ p: 4 }}>
                <CloudFlowOverview onCreateCloudflow={onCreateCloudflow} createCloudFlowLoading={loading} />
                <Stack px={1} maxWidth={{ xs: CLOUDFLOW_CONTENT_MAX_WIDTH, sm: "100%" }}>
                  <BlueprintsRow
                    blueprintsLoading={blueprintsLoading}
                    blueprints={blueprints}
                    onBlueprintClick={onBlueprintClick}
                    onClickViewAll={onClickViewAll}
                    disabled={creatingCloudflowFromBlueprint}
                  />
                </Stack>
              </Container>
              <Stack
                mx={-2}
                justifyContent="center"
                sx={{
                  backgroundColor: isDarkMode ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight,
                }}
              >
                <Container maxWidth="lg" sx={{ px: 2 }}>
                  <CloudflowFAQ />
                </Container>
              </Stack>
            </>
          ) : (
            <Box
              sx={{
                pt: 1,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  sx={{
                    pt: 2,
                  }}
                  size={12}
                >
                  <Stack
                    sx={{
                      gap: 1,
                    }}
                  >
                    <Typography variant="h1">{cloudflowTexts.CLOUDFLOW}</Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        color: "text.secondary",
                      }}
                    >
                      {cloudflowTexts.DASHBOARD_SUBHEADER}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
              <Box
                sx={{
                  mb: 3,
                }}
              />
              <BlueprintsRow
                blueprintsLoading={blueprintsLoading}
                blueprints={blueprints}
                onBlueprintClick={onBlueprintClick}
                onClickViewAll={onClickViewAll}
                disabled={creatingCloudflowFromBlueprint}
              />
              <Box
                sx={{
                  mb: 3,
                }}
              />
              <CloudflowBrowser
                cloudflows={cloudflows}
                loading={cloudflowsLoading}
                createCloudFlowLoading={loading}
                actions={{ onCreateCloudflow, onViewRunHistory, onDelete, onEdit, onUnpublish, onNameClick }}
              />
              <DeleteCloudFlowDialog
                flowId={deleteDialogId}
                handleClose={handelCloseDeleteDialog}
                handleDelete={handleDelete}
                deleteCloudflowLoading={deleteCloudflowLoading}
                isDialogOpened={!!deleteDialogId}
              />
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default Dashboard;
