import { type ReactNode } from "react";

import { CaseIQClientProvider } from "../../Support/Components/CaseIQ/utils";
import { useFetcher } from "../Ava/Messenger/hooks";
import { AvaCaseIQProvider } from "./AvaCaseIQProvider";
import { AvaContextProvider } from "./AvaContext";
import { AvaDialogContextProvider } from "./AvaDialogContextProvider";
import { TrpcContextProvider } from "./AvaTRPCContext";

type Props = {
  children: ReactNode;
};

export const AVAProviders = ({ children }: Props) => {
  const fetcher = useFetcher();

  return (
    <CaseIQClientProvider>
      <AvaCaseIQProvider>
        <AvaDialogContextProvider>
          <AvaContextProvider fetcher={fetcher}>
            <TrpcContextProvider value={{ baseURL: window.location.origin }}>{children}</TrpcContextProvider>
          </AvaContextProvider>
        </AvaDialogContextProvider>
      </AvaCaseIQProvider>
    </CaseIQClientProvider>
  );
};
