import { type ClusterType } from "@doitintl/cmp-models";
import { LoadingButton } from "@mui/lab";
import { Stack, Typography } from "@mui/material";

import { useClusters } from "../../hooks";
import SelectedAccountAndCluster from "./components/SelectedAccountAndCluster";

interface DiscoverEksClustersStepProps {
  projectId: string | null;
  onSkip: () => void;
  clusterType?: ClusterType;
  cloudDiagramsImportLoading: boolean;
  diagramAlreadyExists: boolean;
}

const DiscoverEksClustersStep = ({
  projectId,
  onSkip,
  clusterType,
  cloudDiagramsImportLoading,
  diagramAlreadyExists,
}: DiscoverEksClustersStepProps) => {
  const [clusters] = useClusters();

  const discoveredClusters = clusters?.filter((c) => c.projectId === projectId);

  return (
    <Stack spacing={4}>
      <Stack spacing={2}>
        <Typography variant="h1">We detected {discoveredClusters?.length} EKS clusters in your account</Typography>
        <SelectedAccountAndCluster projectId={projectId} clusterType={clusterType} />
      </Stack>

      <Stack spacing={2}>
        <Typography variant="body1">
          In order to get a detailed view of your EKS resources (pods, deployments, and container details) and
          connections, you need to grant Cloud Diagrams additional permissions by following these instructions:
        </Typography>
        <Typography variant="body1">
          1. Select which clusters you want you want to enable enhanced EKS access for
          <br />
          2. Grant additional permissions via EKS access entry or Kubernetes RBAC
          <br />
          3. Test the clusters connection
        </Typography>
      </Stack>

      {!diagramAlreadyExists && (
        <Stack spacing={1}>
          <Typography variant="subtitle1" fontWeight={500}>
            Or skip this and create your Cloud Diagram now
          </Typography>
          <Stack spacing={2}>
            <Typography variant="body1">
              You can enable enhanced EKS visualization for any clusters later if needed
            </Typography>
            <LoadingButton
              loading={cloudDiagramsImportLoading}
              onClick={onSkip}
              variant="outlined"
              sx={{ width: "fit-content" }}
            >
              Skip and create diagram
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default DiscoverEksClustersStep;
