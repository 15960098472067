import { type FC } from "react";

import { ModelType, NodeTransformationType } from "@doitintl/cmp-models";
import AddIcon from "@mui/icons-material/AddRounded";
import { Autocomplete, Button, FormControl, Stack, TextField } from "@mui/material";

import { cloudflowTexts } from "../../../../../../assets/texts";
import { StringParam } from "../../../ApiActionParametersForm/parameters/StringParam";
import { ReferencedFieldContextProvider } from "../../../ApiActionParametersForm/parameters/wrappers/ReferencedField/ReferencedFieldContextProvider";
import { FieldSectionHeader } from "../../../Common/FieldSectionHeader";
import { useConcatenationForm } from "./hooks";

export const ConcatenationForm: FC = () => {
  const {
    addField,
    newFieldNameCommonProps,
    transformationFieldValue,
    referenceableNodes,
    removeField,
    getFieldProps,
    renderTags,
  } = useConcatenationForm();

  if (transformationFieldValue?.type !== NodeTransformationType.CONCATENATION) {
    return null;
  }

  return (
    <Stack
      spacing={2}
      sx={{
        pb: 4,
      }}
    >
      <TextField fullWidth variant="outlined" size="small" {...newFieldNameCommonProps} />
      <FieldSectionHeader
        title="How to use concatenation"
        subtitle="Concatenate multiple fields or values into a new field"
      />
      <ReferencedFieldContextProvider referenceableNodes={referenceableNodes}>
        {transformationFieldValue?.payload.map((_, index: number) => {
          const f = getFieldProps(`transformation.payload[${index}]`);

          return (
            <StringParam
              key={index}
              inputModel={{ type: ModelType.STRING }}
              fieldProps={f}
              label={`Value ${index + 1}`}
              onRemove={
                transformationFieldValue?.payload.length > 1
                  ? () => {
                      removeField(index);
                    }
                  : undefined
              }
            />
          );
        })}
        <Button startIcon={<AddIcon />} onClick={addField} variant="text">
          Add new value
        </Button>
        <FormControl required>
          <Autocomplete
            sx={{
              ".MuiBox-root": {
                overflow: "hidden",
              },
            }}
            multiple
            disabled
            forcePopupIcon={false}
            options={[]}
            value={transformationFieldValue?.payload ?? []}
            renderTags={renderTags}
            renderInput={(params) => (
              <TextField {...params} label={cloudflowTexts.FINAL_OUTPUT} variant="outlined" margin="dense" />
            )}
          />
        </FormControl>
      </ReferencedFieldContextProvider>
    </Stack>
  );
};
