import { useEffect, useMemo, useState } from "react";

import { Link, useHistory } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import capitalize from "lodash/capitalize";

import { CellsWrapper } from "../../Components/FilterTable/Toolbar/CellsWrapper";
import Hide from "../../Components/HideChildren/Hide";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";
import { InfoTooltip } from "../../Components/InfoTooltip/InfoTooltip";
import { defaultCurrencySymbol } from "../../constants/common";
import { asyncConvertCurrencyTo } from "../../Context/AsyncCurrencyConverterContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../Context/TierProvider";
import { formatCurrency, getCloudIcon } from "../../utils/common";
import { ACTIVE_STATUS, HOURLY_FREQUENCY } from "./const";
import SparkLineTable from "./SparkLine/SparkLineTable";
import { type CostAnomaliesItem } from "./types";
import { getActiveAnomalyTooltip, getAnomalyDuration, getTimeAnomalyTooltip, levelChipColor, levelText } from "./utils";

type Props = {
  row: CostAnomaliesItem;
};

export const CostAnomaliesRow = ({ row }: Props) => {
  const { customer } = useCustomerContext();
  const history = useHistory();
  const isEntitledCostAnomaliesAcknowledgement = useIsFeatureEntitled("governance:costAnomalies:acknowledgement");
  const [costConvertedAndFormated, setCostConvertedAndFormated] = useState<string>();
  const isDarkMode = useDarkThemeCheck();
  const customerCurrency = customer.settings?.currency ?? defaultCurrencySymbol;

  useEffect(() => {
    asyncConvertCurrencyTo(row.cost_of_anomaly, new Date(), defaultCurrencySymbol, customerCurrency).then(
      (converted) => {
        setCostConvertedAndFormated(formatCurrency(converted, customerCurrency));
      }
    );
  }, [customerCurrency, row.cost_of_anomaly]);

  const getLinkUrl = (row: CostAnomaliesItem) => row.ref.path.split("/")[1];

  const anomalyDuration = useMemo(
    () => (
      <Typography
        sx={{
          fontSize: "14px",
          lineHeight: "22px",
          color: "text.secondary",
        }}
      >
        {getAnomalyDuration(row)}
      </Typography>
    ),
    [row]
  );

  return (
    <CellsWrapper>
      <TableCell align="left" data-cy="start-time">
        {row.frequency === HOURLY_FREQUENCY ? (
          <InfoTooltip placement="right" description={getTimeAnomalyTooltip(row)}>
            <span>{row._startDate}</span>
          </InfoTooltip>
        ) : (
          <>{row._startDate}</>
        )}

        <Stack direction="row" spacing={0.5}>
          {row.status === ACTIVE_STATUS ? (
            <InfoTooltip placement="right" description={getActiveAnomalyTooltip(row)}>
              {anomalyDuration}
            </InfoTooltip>
          ) : (
            anomalyDuration
          )}
        </Stack>
      </TableCell>
      <Hide mdDown>
        <TableCell data-cy="status">
          <Chip
            variant="status"
            label={capitalize(row.status ?? "INACTIVE")}
            color={row.status === "ACTIVE" ? "success" : "default"}
          />
          <Typography
            variant="body2"
            sx={{
              color: "text.secondary",
              minWidth: 120,
            }}
          >
            {isEntitledCostAnomaliesAcknowledgement && (row.acknowledged ? "Acknowledged" : "Not acknowledged")}
          </Typography>
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="project-account">{row.project_id}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="service">
          <Stack
            direction="row"
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Avatar src={getCloudIcon(row.platform, isDarkMode)} sx={{ height: 27, width: 27 }} />
            <div>{row.service_name}</div>
          </Stack>
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="sku">{row.sku_name}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="source">{row._source}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="severity">
          <Chip
            variant="status"
            label={row._severity === 1 ? "Info" : levelText[row._severity]}
            color={levelChipColor[row._severity]}
          />
        </TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="anomaly-cost">{costConvertedAndFormated}</TableCell>
      </Hide>
      <Hide mdDown>
        <TableCell data-cy="attribution">{row.attribution_name}</TableCell>
      </Hide>
      <TableCell data-cy="anomaly">
        <SparkLineTable miniGraph={row.miniGraph} />
      </TableCell>
      <TableCell component="th" scope="row" data-cy="view-cta">
        <Link
          style={{ textDecoration: "none" }}
          to={`${history.location.pathname.replace(/\/$/, "")}/${getLinkUrl(row)}/${row.id}`}
        >
          <Button variant="outlined" color="primary" size="small">
            View
          </Button>
        </Link>
      </TableCell>
    </CellsWrapper>
  );
};
