import { useState } from "react";

import { useHistory } from "react-router";
import { CategoryStatus, ClusterDiscoveryStatus, ClusterType } from "@doitintl/cmp-models";
import { Link, type SxProps, TableCell, type Theme, Typography } from "@mui/material";
import { type AxiosError } from "axios";

import { useApiContext } from "../../../../api/context";
import { CellsWrapper } from "../../../../Components/FilterTable/Toolbar/CellsWrapper";
import useRouteMatchURL from "../../../../Components/hooks/useRouteMatchURL";
import { LimitedListWithTooltip } from "../../../../Components/LimitedListWithTooltip";
import { useErrorSnackbar, useSuccessSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { getAxiosErrorMessage } from "../../../../utils/axios";
import { AWSFeatureName } from "../../../Settings/AWS/types";
import { getAwsCloudConnectSettingsLink } from "../../../Settings/AWS/utils";
import { getGcpCloudConnectSettingsLink } from "../../../Settings/GCP/utils";
import { activateCluster, connectEksCluster, deactivateCluster, deleteCluster, testClusterConnection } from "../../api";
import { ClusterDialog } from "../ClusterDialog";
import AgentStatus from "./AgentStatus";
import { ClusterStatus } from "./ClusterStatus";
import ClusterThreeDotsMenu from "./ClusterThreeDotsMenu";
import ClusterTypeDisplay from "./ClusterType";
import DeleteClusterDialog from "./DeleteClusterDialog";
import { type ClusterTableItem } from "./types";

interface ClusterTableRowProps {
  row: ClusterTableItem;
}

const ClusterTableRow = ({ row }: ClusterTableRowProps) => {
  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();

  const { awsFeatures, customer } = useCustomerContext();
  const api = useApiContext();
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();

  const [actionLoading, setActionLoading] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);

  const runClusterAction = async (action: (() => Promise<void>) | (() => void)) => {
    try {
      setActionLoading(true);
      await action();
    } catch (e) {
      const message = getAxiosErrorMessage(e as AxiosError);
      errorSnackbar(`Error performing action on cluster ${row.name}: ${message}`);
    } finally {
      setActionLoading(false);
    }
  };

  const openDeleteDialog = () => {
    setDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const openEditDialog = () => {
    setEditDialogOpen(true);
  };

  const startEksClusterActivation = async () => {
    const hasFsk8sCore = awsFeatures?.includes(AWSFeatureName.fsk8s_core);
    const hasFsk8sAutoConnect = awsFeatures?.includes(AWSFeatureName.fsk8s_auto_connect);

    if (hasFsk8sCore) {
      if (hasFsk8sAutoConnect) {
        await runClusterAction(() => connectEksCluster(api, row.id));
      }
      history.push(`${routeMatchURL}/activate/eks?clusterId=${row.id}&projectId=${row.projectId}`);
    } else {
      history.push(
        getAwsCloudConnectSettingsLink({
          customer,
          cloudConnect: row.cloudConnect,
          callbackUrl: routeMatchURL,
        })
      );
    }
  };

  const activateGkeCluster = async () => {
    if (row.cloudConnect?.status === CategoryStatus.Healthy) {
      history.push(`${routeMatchURL}/activate/gke?clusterId=${row.id}&projectId=${row.projectId}`);
      return;
    }

    history.push(
      getGcpCloudConnectSettingsLink({
        customer,
        cloudConnect: row.cloudConnect,
        callbackUrl: routeMatchURL,
      })
    );
  };

  const handleTestConnection = async () => {
    await runClusterAction(() => testClusterConnection(api, row.id));
  };

  const handleActivateCluster = async () => {
    if (row.type === ClusterType.EKS) {
      await startEksClusterActivation();
      return;
    }

    if (row.type === ClusterType.GKE) {
      await activateGkeCluster();
      return;
    }

    await runClusterAction(() => {
      activateCluster(api, row.id);
    });
  };

  const handleDeactivateCluster = async () => {
    await runClusterAction(() => deactivateCluster(api, row.id));
    successSnackbar(`Cluster deactivated successfully`);
  };

  const handleDeleteCluster = async () => {
    setDeleteDialogOpen(false);
    await runClusterAction(() => deleteCluster(api, row.id));
    successSnackbar(`Cluster deleted successfully`);
  };

  const handleInstallAgent = () => {
    history.push(`${routeMatchURL}/${row.id}/agent/install`);
  };

  const clusterNameStyle: SxProps<Theme> = {
    textDecoration: row.discoveryStatus === ClusterDiscoveryStatus.DELETED ? "line-through" : "none",
  };

  const clusterCanBeActivated =
    !row.isPrivate && row.type !== ClusterType.SELF_MANAGED && row.discoveryStatus === ClusterDiscoveryStatus.HEALTHY;

  return (
    <>
      {deleteDialogOpen && (
        <DeleteClusterDialog
          cluster={row}
          open={deleteDialogOpen}
          onClose={closeDeleteDialog}
          onDelete={handleDeleteCluster}
        />
      )}
      {editDialogOpen && <ClusterDialog open={editDialogOpen} setOpen={setEditDialogOpen} cluster={row} />}
      <CellsWrapper>
        <TableCell sx={clusterNameStyle}>
          {clusterCanBeActivated ? (
            <Typography
              component={Link}
              onClick={handleActivateCluster}
              color="inherit"
              variant="body2"
              title="Click to enable features"
              sx={{ textDecorationColor: "inherit", cursor: "pointer" }}
            >
              {row.name}
            </Typography>
          ) : (
            row.name
          )}
        </TableCell>
        <TableCell>
          <ClusterStatus cluster={row} />
        </TableCell>
        <TableCell>
          <ClusterTypeDisplay type={row.type} />
        </TableCell>
        <TableCell>
          <LimitedListWithTooltip items={row.enabledFeatures?.split(", ") || []} limit={1} title="Enabled features" />
        </TableCell>
        <TableCell>
          <AgentStatus cluster={row} />
        </TableCell>
        <TableCell>{row.region}</TableCell>
        <TableCell>{row.projectId || "N/A"}</TableCell>
        <TableCell>{row.kubernetesControlPlaneVersion || "Unknown"}</TableCell>

        <TableCell sx={{ display: "flex", justifyContent: "flex-end" }}>
          <ClusterThreeDotsMenu
            cluster={row}
            onTestConnection={handleTestConnection}
            onActivateCluster={handleActivateCluster}
            onDeactivateCluster={handleDeactivateCluster}
            onDeleteCluster={openDeleteDialog}
            onEditCluster={openEditDialog}
            onInstallAgent={handleInstallAgent}
            loading={actionLoading}
          />
        </TableCell>
      </CellsWrapper>
    </>
  );
};

export default ClusterTableRow;
