import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Stack,
  Typography,
} from "@mui/material";

import { cloudflowTexts, globalText } from "../../../../assets/texts";

const CantUnpublishCloudflowAlert = ({ handleClose }: { handleClose: () => void }) => (
  <>
    <DialogTitle>{cloudflowTexts.CANT_UNPUBLISH_TITLE}</DialogTitle>
    <DialogContent>
      <DialogContentText>{cloudflowTexts.CANT_UNPUBLISH_TEXT}</DialogContentText>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose} variant="contained" color="primary">
        {globalText.DONE}
      </Button>
    </DialogActions>
  </>
);

type ConfirmUnpublishDialogProps = {
  isDialogOpened: boolean;
  hasActiveExecutions: boolean | undefined;
  isPendingApproval: boolean | undefined;
  handleCloseDialog: () => void;
  handleUnpublish: () => void;
};

const ConfirmUnpublishDialog = ({
  isDialogOpened,
  hasActiveExecutions,
  isPendingApproval,
  handleCloseDialog,
  handleUnpublish,
}: ConfirmUnpublishDialogProps) => {
  if (hasActiveExecutions && !isPendingApproval) {
    return (
      <Dialog open={isDialogOpened} onClose={handleCloseDialog} fullWidth maxWidth="sm">
        <CantUnpublishCloudflowAlert handleClose={handleCloseDialog} />
      </Dialog>
    );
  }

  return (
    <Dialog open={isDialogOpened} onClose={handleCloseDialog} fullWidth maxWidth="sm">
      <DialogTitle>{cloudflowTexts.CONFIRM_UNPUBLISH_TITLE}</DialogTitle>
      <DialogContent>
        {isPendingApproval ? (
          <Stack spacing={3}>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_1}</Typography>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_2}</Typography>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_3}</Typography>
          </Stack>
        ) : (
          <>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_1}</Typography>
            <Typography>{cloudflowTexts.CONFIRM_UNPUBLISH_CONTENT.LINE_3}</Typography>
          </>
        )}
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" data-cy="cancel-button">
          {cloudflowTexts.CANCEL}
        </Button>
        <Button onClick={handleUnpublish} color="primary" variant="contained" data-cy="unpublish-button">
          {cloudflowTexts.UNPUBLISH}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmUnpublishDialog;
