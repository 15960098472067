import { type ReactNode, useState } from "react";

import { useTranslation } from "react-i18next";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { Box, DialogContent, Link, Typography } from "@mui/material";

import SimpleDialog from "../Components/SimpleDialog";
import TooltipArrow from "../Components/TooltipArrow/TooltipArrow";
import { useAuthContext } from "../Context/AuthContext";
import { useCustomerContext } from "../Context/CustomerContext";
import { useGetLoginUrl } from "../utils/useGetLoginUrl";

type AwsDirectSupportProps = {
  accountId: string;
  children?: ReactNode;
  onCancel: () => void;
};

export default function AwsDirectSupport({ accountId, onCancel, children }: AwsDirectSupportProps) {
  const { isDoitEmployee } = useAuthContext();
  const { customer } = useCustomerContext();
  const [openSupportAWS, setOpenSupportAWS] = useState<boolean>(false);
  const getLoginUrl = useGetLoginUrl();
  const { t } = useTranslation("services");

  const openAWSTicket = async () => {
    try {
      const awsLoginUrl = await getLoginUrl(accountId, false, customer.id);
      if (awsLoginUrl !== "#") {
        window.location.href = awsLoginUrl;
      }
    } catch (error) {
      setOpenSupportAWS(false);
    }
  };

  return (
    <Box sx={{ mt: -3 }}>
      <TooltipArrow title={isDoitEmployee ? t("Sauron.helper") : ""}>
        <Link
          disabled={isDoitEmployee}
          color={!isDoitEmployee ? "text.secondary" : "text.disabled"}
          sx={{ textDecoration: "none" }}
          component="button"
          onClick={() => {
            setOpenSupportAWS(true);
          }}
        >
          <Typography variant="body2">
            {t("Sauron.title")}
            {isDoitEmployee && " (disabled)"}
            <OpenInNewIcon sx={{ ml: 0.5, mb: -0.8 }} />
          </Typography>
        </Link>
      </TooltipArrow>
      <SimpleDialog
        open={openSupportAWS}
        dialogProps={{
          fullWidth: true,
        }}
        onConfirm={openAWSTicket}
        title="Open request with AWS"
        onCancel={() => {
          onCancel();
          setOpenSupportAWS(false);
        }}
        confirmButtonText="Open AWS console"
        cancelButtonText="Cancel"
        confirmButtonColor="primary"
        disableConfirm={!accountId}
      >
        <DialogContent>
          <Box>{children}</Box>
        </DialogContent>
      </SimpleDialog>
    </Box>
  );
}
