// noinspection JSUnusedGlobalSymbols

import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type CustomerModel, type CustomerModelOrganizationModel } from "./Customer";
import { type DashboardModelAttributionModel } from "./Dashboard";
import { type UserEnrichment } from "./Enrichment";
import { type EntityModel } from "./Entity";
import { type InAppNotificationType } from "./InAppNotifications";
import { type UserPersonalNotificationModel } from "./Notifications";
import { type RoleModel } from "./Role";
import { type ZendeskTicketRestriction } from "./Support";

export enum UserNotification {
  NewInvoice = 1,
  CostAnomalies = 2,
  PaymentOverdue = 3,
  CreditsUtilization = 4,
  CloudQuotaUtilization = 5,
  CloudKnownIssues = 6,
  DailyDigest = 7,
  MonthlyDigest = 8,
  WeeklyDigest = 9,
  NewProformaInvoice = 10,
  DashboardSubscription = 11,
  ReportSubscription = 12,
  CustomInsight = 13,
  NewSKU = 14,
}

export type InAppNotificationReadModel = {
  lastRead: Timestamp;
  customer: Reference<CustomerModel>;
  ttl: Timestamp;
};

export type InAppNotificationSettings = {
  notificationTypes: InAppNotificationType[];
  allowRealTime: boolean;
};

type TempAccessKey = {
  cloudflow?: null | string;
};

@collection("users")
export class UserModel {
  accessKey?: null | string;

  customer?: {
    name: string;
    _name: string;
    ref: Reference<CustomerModel>;
  };

  dailyDigests?: Reference<DashboardModelAttributionModel>[];

  weeklyDigests?: Reference<DashboardModelAttributionModel>[];

  disabled?: boolean;

  displayName!: string;

  domain!: string;

  email!: string;

  enrichment?: UserEnrichment;

  entities?: Reference<EntityModel>[] | null;

  extraDelight?: boolean;

  firstName!: string;

  jobFunction?: number;

  lastLogin?: Timestamp;

  lastName!: string;

  newFeatures?: NewFeature[];

  organizations?: Reference<CustomerModelOrganizationModel>[];

  permissions?: UserPermission[];

  phone?: null | string;

  phoneExtension?: null | string;

  prevLastLogin?: Timestamp;

  role?: number | string;

  roles?: Reference<RoleModel>[];

  sessionCount?: number;

  tempAccessKey?: TempAccessKey;

  inAppNotificationSettings?: InAppNotificationSettings;

  userNotifications?: UserNotification[];

  userSettings?: UserSettings;

  termsOfService?: {
    type: "standalone-estimation";
    timeCreated: Timestamp;
  } | null;

  subCollections?: {
    userPersonalNotifications: UserPersonalNotificationModel;
    inAppNotificationRead: InAppNotificationReadModel;
  };

  language?: string;

  zendeskTicketRestriction?: ZendeskTicketRestriction;
}

enum NewFeature {
  NewReportsUI = "newReportsUI",
}

export enum UserPermissions {
  BillingProfilesAdmin = "1SmYWoSAO1frHKjt34Gz",
  CloudAnalyticsUser = "sfmBZeLN8uXWooCqJ4NO",
  FlexibleRI = "tvQnB14mSGr8LSU8reYH",
  InvoicesViewer = "HN6A3cPzDBcAIlc3ncDy",
  AssetsManager = "wfDH3k1FmYKHlQBwGIzZ",
  Settings = "AIzQjXTUQDgeZjXqNsgF",
  SandboxAdmin = "jnrMNJLdzRsyLCHCfq6T",
  SandboxUser = "KpogRUOHgMlroIH8xOUQ",
  IAM = "ZqLGIVDUhNiSEDtrEb0S",
  ContractsViewer = "8zXuFyohNSiiLy2ZQ6Xu",
  AnomaliesViewer = "eUKNGKekajR0NbOkHFfC",
  PerksViewer = "itIlDPCy18ymtEVgaW0B",
  IssuesViewer = "dEJbIiUcHn8GhW7IiWLW",
  BudgetsManager = "BgYDGr8dABLKjUkys7AD",
  MetricsManager = "fSFpOG5xUeHPlYVI5N1k",
  AttributionsManager = "AnJW2Hwipmucak00yko0",
  SupportRequester = "jg1YHuQhsRlg5msNhpZZ",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  Licenses = "wfDH3k1FmYKHlQBwGIzZ",
  RampPlansViewer = "u10SsW3V123pb5lVdgcO",
  CloudAnalyticsAdmin = "pwWRo04l9uXUYa8rIQSW",
  SpotScaling = "akRhXeDLdP3mh87dXKSt",
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  UsersManager = "ZqLGIVDUhNiSEDtrEb0S",
  LabelsManager = "CLxHZjNiVpjK83TA4h8S",
  InsightsManager = "Co8Hf8SfDWvS7PxPlqc6",
  DataHubAdmin = "Ss3OzvfCQQVFiRjUZQSp",
  ThreadsManager = "DZQ8q0gSZl3bRcX1555V",
  CloudFlowViewer = "INLwPz3BS2MbC6VaDVO0",
  CloudFlowManager = "i5kigiXm2ogVzuFMYeQF",
}

export type UserPermission = `${UserPermissions}`;

interface UserSettings {
  anomalyAbsolute?: number;
  anomalyAlerts?: number;
  anomalyScope?: Reference<DashboardModelAttributionModel>[];
}
