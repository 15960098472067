import { useCallback, useEffect, useState } from "react";

import { CloudFlowNodeType, CloudFlowProvider } from "@doitintl/cmp-models";
import AssignmentIcon from "@mui/icons-material/Assignment";
import CallSplitIcon from "@mui/icons-material/CallSplit";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import TransformIcon from "@mui/icons-material/Transform";
import { Box, ClickAwayListener, Grow, IconButton, Paper, Popper, Stack, Typography, useTheme } from "@mui/material";

import AWSLogoLight from "../../../../assets/aws-logo.svg";
import AWSLogoDark from "../../../../assets/aws-logo-dark-mode.svg";
import DoitLogo from "../../../../assets/doit-logo-hero-square.svg";
import GCPLogo from "../../../../assets/gcp-logo.svg";
import { cloudflowTexts } from "../../../../assets/texts";
import { useDarkThemeCheck } from "../../../../Components/hooks/useDarkThemeCheck";
import { type EdgeData } from "../../types";
import { useCloudflowOperations } from "../Common/CloudflowOperationsProvider";
import { useCloudflowState } from "../Common/hooks/useCloudflowState";

type Props = {
  data?: EdgeData;
  target: string;
};

const NodeOption = ({ icon, title, onClick }) => (
  <Stack
    direction="row"
    spacing={1}
    onClick={onClick}
    sx={{
      alignItems: "center",
      py: 1,
      px: 2,
      cursor: "pointer",
      "&:hover": { backgroundColor: "action.hover" },
    }}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: 1,
        borderColor: "divider",
        borderRadius: "50%",
        width: 32,
        height: 32,
      }}
    >
      {icon}
    </Box>
    <Typography variant="body1" gutterBottom>
      {title}
    </Typography>
  </Stack>
);

const ProviderIcon = ({ src }: { src: string }) => (
  <Box
    component="img"
    src={src}
    sx={{
      width: 18,
      height: 18,
    }}
  />
);

const AddNodePopover = ({ data, target }: Props) => {
  const { httpOperationLoading } = useCloudflowOperations();

  const { isPublished, isBlueprint } = useCloudflowState();

  const { shadows } = useTheme();
  const isDarkMode = useDarkThemeCheck();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    data?.setInteractionEnabled(true);
  };
  const open = Boolean(anchorEl);
  const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);

  const handleOptionClick = useCallback(
    (nodeType: CloudFlowNodeType, provider?: CloudFlowProvider) => {
      setAnchorEl(null);
      data?.handleAddNode(nodeType, target, provider);
    },
    [data, target]
  );

  const handleAttachBlueprint = useCallback(() => {
    setAnchorEl(null);
    data?.handleAttachBlueprint(target);
  }, [data, target]);

  useEffect(() => {
    data?.setInteractionEnabled?.(!open);
  }, [open, data]);

  const isAddNodeDisabled = httpOperationLoading || isPublished || isBlueprint;

  const actionOptions = [
    {
      icon: <ProviderIcon src={isDarkMode ? AWSLogoDark : AWSLogoLight} />,
      title: "AWS",
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.ACTION, CloudFlowProvider.AWS);
      },
    },
    {
      icon: <ProviderIcon src={GCPLogo} />,
      title: "Google Cloud",
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.ACTION, CloudFlowProvider.GCP);
      },
    },
    {
      icon: <ProviderIcon src={DoitLogo} />,
      title: "DoiT",
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.ACTION, CloudFlowProvider.DoiT);
      },
    },
    {
      icon: <AssignmentIcon color="primary" />,
      title: "Blueprints",
      onClick: handleAttachBlueprint,
    },
  ];

  const operationOptions = [
    {
      icon: <FilterAltIcon color="primary" />,
      title: cloudflowTexts.FILTER_RESULTS,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.FILTER);
      },
    },
    {
      icon: <CallSplitIcon color="primary" />,
      title: cloudflowTexts.IF_STATEMENT,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.CONDITION);
      },
    },
    {
      icon: <TransformIcon color="primary" />,
      title: cloudflowTexts.TRANSFORM_DATA,
      onClick: () => {
        handleOptionClick(CloudFlowNodeType.TRANSFORMATION);
      },
    },
  ];

  return (
    <>
      <IconButton
        aria-describedby={open ? "add-node-popper" : undefined}
        disabled={isAddNodeDisabled}
        sx={{
          p: 0,
          "&:hover": {
            color: "primary.main",
            backgroundColor: "primary.contrastText",
          },
          pointerEvents: "all",
          backgroundColor: "primary.contrastText",
        }}
        onClick={handleClick}
      >
        <ControlPointIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="right"
        disablePortal={false}
        modifiers={[
          {
            name: "offset",
            options: {
              offset: [10, 15],
            },
          },
          {
            name: "arrow",
            enabled: true,
            options: {
              element: arrowRef,
            },
          },
        ]}
        sx={{
          boxShadow: shadows[24],
          borderRadius: 1,
        }}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: "right top" }}>
            <>
              <Box
                sx={{
                  position: "absolute",
                  width: 16,
                  height: 16,
                  boxSizing: "border-box",
                  color: "background.paper",
                  marginLeft: -1,
                  zIndex: -1,
                  "&::before": {
                    content: '""',
                    display: "block",
                    width: "100%",
                    height: "100%",
                    boxShadow: "none",
                    backgroundColor: "background.paper",
                    transform: "rotate(45deg)",
                    zIndex: -1,
                  },
                }}
                ref={setArrowRef}
              />
              <Paper elevation={2} sx={{ boxShadow: "none" }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Stack
                    sx={{
                      py: 2,
                    }}
                  >
                    <Typography variant="subtitle2" color="text.secondary" px={2} pb={1}>
                      Actions
                    </Typography>
                    {actionOptions.map((option, index) => (
                      <NodeOption key={index} {...option} />
                    ))}
                    <Typography variant="subtitle2" color="text.secondary" px={2} py={1}>
                      Operations
                    </Typography>
                    {operationOptions.map((option, index) => (
                      <NodeOption key={index} {...option} />
                    ))}
                  </Stack>
                </ClickAwayListener>
              </Paper>
            </>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default AddNodePopover;
