import { useEffect, useRef, useState } from "react";

type Props = {
  text: string;
  maxWidth: number;
  fontSize?: number;
};

const TruncatedText = ({ text, maxWidth, fontSize = 16 }: Props) => {
  const [truncatedText, setTruncatedText] = useState(text);
  const canvasRef = useRef(document.createElement("canvas"));

  useEffect(() => {
    const measureTextWidth = (txt) => {
      const context = canvasRef.current.getContext("2d");

      if (context) {
        context.font = `${fontSize}px Roboto`;
        return context.measureText(txt).width + 40;
      }
      return 0;
    };

    if (measureTextWidth(text) <= maxWidth) {
      setTruncatedText(text);
      return;
    }

    let truncated = text;
    while (measureTextWidth(`${truncated}...`) > maxWidth && truncated.length > 0) {
      truncated = truncated.slice(0, -1);
    }

    setTruncatedText(`${truncated}...`);
  }, [text, maxWidth, fontSize]);

  return truncatedText;
};

export default TruncatedText;
