import { type ReactNode } from "react";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

type LoadingWrapperProps = {
  loading: boolean;
  children: ReactNode;
  wrapperWidth: string;
};

const LoadingBudgetCardWrapper = ({ loading, children, wrapperWidth }: LoadingWrapperProps) =>
  loading ? (
    <Box
      sx={{
        minWidth: "150px",
      }}
    >
      <Skeleton width={wrapperWidth} />
    </Box>
  ) : (
    <>{children}</>
  );

export default LoadingBudgetCardWrapper;
