import { CloudflowExecutionStatus } from "@doitintl/cmp-models";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

import { cloudflowTexts, globalText } from "../../../assets/texts";
import { CircularProgressLoader } from "../../../Components/Loader";
import { useHasActiveCloudflowExecutions } from "../hooks";

const CantDeleteCloudflowAlert = ({ handleClose }: { handleClose: () => void }) => (
  <>
    <DialogTitle>{cloudflowTexts.CANT_DELETE_CLOUDFLOW_DIALOG_TITLE}</DialogTitle>
    <DialogContent>
      <DialogContentText>{cloudflowTexts.CANT_DELETE_CLOUDFLOW_DIALOG_TEXT}</DialogContentText>
    </DialogContent>
    <Divider />
    <DialogActions>
      <Button onClick={handleClose} variant="contained" color="primary">
        {globalText.DONE}
      </Button>
    </DialogActions>
  </>
);

type DeleteCloudFlowDialogProps = {
  flowId: string | null;
  isDialogOpened: boolean;
  handleClose: () => void;
  handleDelete: () => void;
  deleteCloudflowLoading: boolean;
};

const DeleteCloudFlowDialog = ({
  flowId,
  isDialogOpened,
  handleClose,
  handleDelete,
  deleteCloudflowLoading,
}: DeleteCloudFlowDialogProps) => {
  const { breakpoints } = useTheme();
  const fullScreen = useMediaQuery(breakpoints.down("md"));
  const { hasActiveExecutions, executions, loading } = useHasActiveCloudflowExecutions(flowId ?? "");
  const isAwaitingApproval = executions?.some((e) => e.status === CloudflowExecutionStatus.PENDING_APPROVAL);

  // NOTE: flowId addition here stops flicker of loading when closing the dialog
  if (flowId && loading)
    return (
      <Dialog open={isDialogOpened} onClose={handleClose} fullScreen={fullScreen} fullWidth>
        <DialogContent sx={{ minHeight: 150 }}>
          <CircularProgressLoader />
        </DialogContent>
      </Dialog>
    );

  if (!loading && hasActiveExecutions && !isAwaitingApproval)
    return (
      <Dialog open={isDialogOpened} onClose={handleClose} fullScreen={fullScreen} fullWidth>
        <CantDeleteCloudflowAlert handleClose={handleClose} />
      </Dialog>
    );

  return (
    <Dialog open={isDialogOpened} onClose={handleClose} fullScreen={fullScreen} fullWidth>
      <DialogTitle>{cloudflowTexts.DELETE_CLOUDFLOW}</DialogTitle>
      <DialogContent>
        <DialogContentText>{cloudflowTexts.CLOUDFLOW_DELETE_DIALOG_TEXT}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={handleClose} variant="text" color="primary">
          {cloudflowTexts.CANCEL}
        </Button>
        <Button
          onClick={handleDelete}
          variant="contained"
          color="error"
          disabled={deleteCloudflowLoading}
          data-cy="delete-cloudflow-button"
        >
          {cloudflowTexts.DELETE_CLOUDFLOW}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteCloudFlowDialog;
