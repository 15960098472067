import { ClusterType } from "@doitintl/cmp-models";
import { Box } from "@mui/material";

import { Stepper } from "../../../Components/Stepper";
import { useKubernetesClusterActivation } from "./hooks";
import { EnableFeaturesStep, SelectFeaturesStep, TestConnectionStep } from "./Steps";
import { type StepConfig, useSteps } from "./utils";

const GkeClusterActivation = () => {
  const {
    currentStep,
    selectedProjectId,
    selectedCluster,
    selectedFeatures,
    setCurrentStep,
    setSelectedFeatures,
    goBack,
    handleSubmit,
  } = useKubernetesClusterActivation(ClusterType.GKE);

  const stepConfig: StepConfig[] = [
    {
      label: "Enable features",
      isComplete: selectedFeatures.length > 0,
      children: (
        <SelectFeaturesStep
          clusters={selectedCluster ? [selectedCluster] : null}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
          setSelectedFeatures={setSelectedFeatures}
        />
      ),
    },
    {
      label: "Enable features",
      children: (
        <EnableFeaturesStep
          clusters={selectedCluster ? [selectedCluster] : null}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
        />
      ),
    },
    {
      label: "Test connection",
      children: (
        <TestConnectionStep
          clusters={selectedCluster ? [selectedCluster] : null}
          projectId={selectedProjectId}
          selectedFeatures={selectedFeatures}
        />
      ),
    },
  ];

  const steps = useSteps({ stepConfig, currentStep });

  return (
    <Box
      sx={{
        pt: 4,
        pb: 5,
      }}
    >
      <Stepper
        submitButtonLabel="Done"
        steps={steps}
        loading={false}
        onCancel={goBack}
        onSubmit={handleSubmit}
        overrideStep={currentStep}
        getCurrentStep={setCurrentStep}
        footerMaxWidth={830}
        maxWidth={1000}
        contentSx={{
          mt: 3,
          mb: 3,
          width: 1000,
          padding: "0 101px",
        }}
      />
    </Box>
  );
};

export default GkeClusterActivation;
