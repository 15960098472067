import { CategoryStatus } from "@doitintl/cmp-models";
import { Chip } from "@mui/material";

import { CloudConnectStatusText } from "../../../assets/texts";

export const statusLabels: Record<CategoryStatus, string> = {
  [CategoryStatus.NotConfigured]: CloudConnectStatusText.NotConfigured,
  [CategoryStatus.Healthy]: CloudConnectStatusText.Healthy,
  [CategoryStatus.Unhealthy]: CloudConnectStatusText.Unhealthy,
  [CategoryStatus.Critical]: CloudConnectStatusText.Critical,
  [CategoryStatus.Partial]: CloudConnectStatusText.Partial,
};

const Colors: Record<CategoryStatus, string> = {
  [CategoryStatus.NotConfigured]: "default",
  [CategoryStatus.Healthy]: "success",
  [CategoryStatus.Unhealthy]: "warning",
  [CategoryStatus.Critical]: "error",
  [CategoryStatus.Partial]: "info",
};

export const ServiceAccountStatusChip = ({ status }) => (
  <Chip variant="status" label={statusLabels[status]} color={Colors[status]} />
);
