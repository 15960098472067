import { type JSX, useCallback } from "react";

import { Redirect, useHistory, useParams } from "react-router-dom";
import { LinkAccountStepper } from "@client/src/Pages/Settings/Azure/Stepper/LinkAccountStepper";
import { Box } from "@mui/material";

import AccountManagersForm from "../../Components/AccountManagersByCompanyForm/AccountManagersByCompanyForm";
import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import { useCustomerContext } from "../../Context/CustomerContext";
import { AWSAccounts } from "./AWS/Accounts/AWSAccounts";
import { LinkAccount } from "./AWS/ManagePermissions/LinkAccount";
import { UpdatePermissions } from "./AWS/ManagePermissions/UpdatePermissions";
import { SubscriptionsPage } from "./Azure/SubscriptionsPage/SubscriptionsPage";
import { defaultTab, TabValue } from "./consts";
import { EditConnectionPage } from "./GCP/EditConnectionPage";
import { NewConnectionPage } from "./GCP/NewConnectionPage";
import { ServiceAccountsPage } from "./GCP/ServiceAccountsPage";
import { General } from "./General/General";

type SettingsProps = {
  tabs: JSX.Element | undefined;
  pageId: string;
};

const Settings = ({ pageId }: SettingsProps) => {
  const { customer } = useCustomerContext();
  const routeMatchURL = useRouteMatchURL();
  const history = useHistory();
  const { accountId } = useParams<{ accountId: string }>();
  const goToAWSPageCallback = useCallback(() => {
    history.push(`/customers/${customer.id}/settings/${TabValue.AWS}`);
  }, [customer.id, history]);

  return (
    <Box
      sx={{
        pt: 1,
      }}
    >
      {pageId === "settings" && <Redirect to={`${routeMatchURL}/${defaultTab}`} />}
      {pageId === "settings:general" && <General />}
      {pageId === "settings:gcp" && <ServiceAccountsPage />}
      {pageId === "settings:gcp:new" && <NewConnectionPage />}
      {pageId === "settings:gcp:edit" && <EditConnectionPage />}
      {pageId === "settings:aws" && <AWSAccounts />}
      {pageId === "settings:azure" && <SubscriptionsPage />}
      {pageId === "settings:azure:new" && <LinkAccountStepper />}
      {pageId === "settings:aws:account" && (
        <UpdatePermissions backPageCallback={goToAWSPageCallback} accountId={accountId} />
      )}
      {pageId === "settings:account-managers" && <AccountManagersForm />}
      {pageId === "settings:aws:link" && <LinkAccount backPageCallback={goToAWSPageCallback} />}
    </Box>
  );
};

export default Settings;
