import { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router-dom";
import { type AttributionFilter, CommitmentManagersModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { DateTime } from "luxon";

import { Loader } from "../../Components/Loader";
import { useErrorSnackbar, useSuccessSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { WizardForm } from "../../Components/WizardForm/WizardForm";
import { useContractsContext } from "../../Context/customer/ContractsContext";
import { consoleErrorWithSentry } from "../../utils/errorHandling";
import { useCreateCommitmentApiRequest } from "./hooks";
import { CommitmentStep } from "./Steps/CommitmentStep";
import { ConfigurationStep } from "./Steps/ConfigurationStep";
import { GeneralStep } from "./Steps/GeneralStep";
import { type CommitmentFormValues, CommitmentManagerError } from "./types";
import { validationSchema } from "./utils";

type CommitmentFormInitialValues = Omit<
  CommitmentFormValues,
  "skuFilter" | "serviceFilter" | "billingAccounts" | "selectedContracts"
> & {
  skuFilter: Partial<AttributionFilter>;
  serviceFilter: Partial<AttributionFilter>;
  billingAccounts: string[];
  selectedContractIds: string[];
};

export const CreateCommitmentFlow = () => {
  const { handleSubmit, handleUpdate } = useCreateCommitmentApiRequest();
  const showErrorSnackbar = useErrorSnackbar();
  const showSuccessSnackbar = useSuccessSnackbar();
  const history = useHistory();
  const { commitmentId } = useParams<{ commitmentId?: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const { contracts } = useContractsContext();

  const defaultInitialValues: CommitmentFormInitialValues = {
    name: "",
    selectedContractIds: [],
    currency: "USD",
    startDate: DateTime.now(),
    numberOfPeriods: 1,
    periods: [],
    excess: false,
    excessValue: 0,
    shortfall: false,
    shortfallValue: 0,
    skuFilter: { inverse: true },
    serviceFilter: { inverse: true },
    billingAccounts: [],
  };

  const [initialValues, setInitialValues] = useState<CommitmentFormInitialValues>(defaultInitialValues);

  const isEditMode = history.location.pathname.endsWith("/edit");
  const commitmentManagerPath = history.location.pathname.split("/").slice(0, -1).join("/");

  useEffect(() => {
    if (isEditMode && commitmentId) {
      setIsLoading(true);

      getCollection(CommitmentManagersModel)
        .doc(commitmentId)
        .get()
        .then((snap) => {
          const data = snap.asModelData();

          if (!data) {
            showErrorSnackbar("Commitment not found");
            return;
          }

          const formValues: CommitmentFormInitialValues = {
            name: data.name,
            selectedContractIds: data.contracts || [],
            currency: data.currency,
            startDate: DateTime.fromJSDate(data.periods[0].startDate.toDate()),
            numberOfPeriods: data.periods.length,
            periods: data.periods.map((period) => ({
              startDate: period.startDate.toDate(),
              endDate: period.endDate.toDate(),
              commitmentValue: String(period.commitmentValue),
              periodLength: DateTime.fromJSDate(period.endDate.toDate()).diff(
                DateTime.fromJSDate(period.startDate.toDate()),
                "months"
              ).months,
              marketplaceLimitPercentage: String(period.marketplaceLimitPercentage),
            })),
            excess: !!data.excessRollOverPercentage,
            excessValue: data.excessRollOverPercentage || 0,
            shortfall: !!data.shortfallRollOverPercentage,
            shortfallValue: data.shortfallRollOverPercentage || 0,
            billingAccounts: data.billingAccounts,
            skuFilter: data.skuFilter || { inverse: true },
            serviceFilter: data.serviceFilter || { inverse: true },
            contractType: data.contractType,
          };

          setInitialValues(formValues);
        })
        .catch((error) => {
          consoleErrorWithSentry(error);
          showErrorSnackbar("Error loading commitment data");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isEditMode, commitmentId, history, commitmentManagerPath, showErrorSnackbar, contracts]);

  const steps = [GeneralStep, CommitmentStep, ConfigurationStep];

  const onSubmit = async (values: CommitmentFormInitialValues) => {
    try {
      const apiValues: CommitmentFormValues = {
        ...values,
        serviceFilter: values.serviceFilter as AttributionFilter,
        skuFilter: values.skuFilter as AttributionFilter,
      };

      if (isEditMode && commitmentId) {
        await handleUpdate(apiValues, commitmentId);
        history.push(commitmentManagerPath);
        showSuccessSnackbar("Commitment updated successfully");
      } else {
        const res = await handleSubmit(apiValues);
        history.push(`${commitmentManagerPath}/${res.data}`);
        showSuccessSnackbar("Commitment created successfully");
      }
    } catch (error) {
      consoleErrorWithSentry(error);
      if (error instanceof CommitmentManagerError) {
        showErrorSnackbar(error.message);
      } else {
        showErrorSnackbar(`Error ${isEditMode ? "updating" : "creating"} commitment`);
      }
    }
  };

  return (
    <Loader loading={isLoading}>
      <WizardForm
        initialValues={initialValues}
        steps={steps}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        loadingTitle={`${isEditMode ? "Updating" : "Creating"} your commitment manager`}
        loadingDescription="This may take a few minutes"
      />
    </Loader>
  );
};
