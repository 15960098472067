import { MenuItem, TextField } from "@mui/material";

import { RootOrgId } from "../../../utils/common";
import { type InviteUserDialogProps } from "./InviteUserDialog";

type OrgSelectionProps = {
  customerOrgs: InviteUserDialogProps["orgsList"];
  onOrgSelection: (orgId: string) => void;
};

export const OrgSelection = (props: OrgSelectionProps) => {
  const { customerOrgs, onOrgSelection } = props;
  const rootOrg = customerOrgs.find((org) => org.id === RootOrgId);

  const orgsList = customerOrgs
    .sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }))
    .map((role) => (
      <MenuItem key={role.id} value={role.id}>
        {role.name}
      </MenuItem>
    ));

  return orgsList ? (
    <TextField
      id="userOrgSelection"
      name="orgSelection"
      fullWidth
      select
      label="Select organization"
      required
      defaultValue={rootOrg?.id}
      onChange={(e) => {
        onOrgSelection(e.target.value);
      }}
      variant="outlined"
      margin="normal"
      data-testid="new-user-orgs-menu"
      size="small"
    >
      {orgsList}
    </TextField>
  ) : null;
};
