import { useCallback, useState } from "react";

import { useParams } from "react-router";
import { Add } from "@mui/icons-material";
import { Button, DialogContent, DialogContentText, Paper, Stack, Typography } from "@mui/material";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import SimpleDialog from "../../../Components/SimpleDialog";
import useSegmentTrackEvent from "../../../utils/useSegmentTrackEvent";
import useAllocationDndStore from "./hooks/useAllocationDnd";
import { trackAllocationEvent } from "./utils";

type Props = {
  disabled: boolean;
};

const AllocationSegment = ({ disabled }: Props) => {
  const { addItem, setHasUnallocatedCosts, items, hasUnallocatedCosts, setUnallocatedCosts } = useAllocationDndStore();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { trackEvent } = useSegmentTrackEvent();
  const { allocationId } = useParams<{ allocationId: string }>();

  const handleAddAllocation = useCallback(() => {
    addItem(crypto.randomUUID());
    trackAllocationEvent({
      trackEvent,
      eventName: "Allocation Rule Added",
      ruleCount: items.main.length,
      hasUnallocatedCosts,
      allocationId,
    });
  }, [addItem, trackEvent, items.main.length, hasUnallocatedCosts, allocationId]);

  const handleAddUnallocatedCosts = useCallback(() => {
    setHasUnallocatedCosts(true);
    setUnallocatedCosts(allocationTxt.UNALLOCATED_COSTS);
  }, [setHasUnallocatedCosts, setUnallocatedCosts]);

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setDialogOpen(false);
  }, []);

  return (
    <>
      <Paper
        variant="outlined"
        sx={({ palette }) => ({
          py: 2,
          px: 3,
          backgroundColor: palette.general.backgroundDark,
        })}
      >
        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 500 }}>
          {allocationTxt.ALLOCATION_SEGMENT_TITLE}
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 4 }}>
          {allocationTxt.ALLOCATION_SEGMENT_DESCRIPTION}
        </Typography>

        <Stack direction="row" spacing={2} sx={{ display: { xs: "none", md: "flex" } }}>
          <Button
            disabled={disabled}
            variant="outlined"
            size="medium"
            startIcon={<Add />}
            onClick={handleAddAllocation}
          >
            {allocationTxt.ADD_ALLOCATION}
          </Button>
          <Button disabled={disabled} variant="text" size="medium" onClick={handleOpenDialog}>
            {allocationTxt.ADD_UNALLOCATED_COSTS}
          </Button>
        </Stack>
      </Paper>
      <SimpleDialog
        open={dialogOpen}
        title={allocationTxt.UNALLOCATED_MODAL_TITLE}
        onConfirm={handleAddUnallocatedCosts}
        onCancel={handleCloseDialog}
        confirmButtonCy="confirmAddUnallocatedButton"
      >
        <DialogContent>
          <DialogContentText>
            <Typography component="span">{allocationTxt.UNALLOCATED_MODAL_DESCRIPTION}</Typography>
          </DialogContentText>
        </DialogContent>
      </SimpleDialog>
    </>
  );
};

export default AllocationSegment;
