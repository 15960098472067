import alutFriends from "../assets/charity/logos/alutfriends.png";
import asiaFoundation from "../assets/charity/logos/asiafoundation.png";
import aware from "../assets/charity/logos/aware.png";
import codeClub from "../assets/charity/logos/codeclub.png";
import contraelCancerEs from "../assets/charity/logos/contraelcancer-es.svg";
import dailyBread from "../assets/charity/logos/dailybread.svg";
import electronicFrontierFoundation from "../assets/charity/logos/electronic-frontier-foundation.svg";
import engineeringGood from "../assets/charity/logos/engineering-good.png";
import forcesEmploymentUk from "../assets/charity/logos/forces-employment-uk.svg";
import girlsWhoCode from "../assets/charity/logos/girls-who-code.svg";
import hervolution from "../assets/charity/logos/hervolution.jpg";
import homebrew from "../assets/charity/logos/homebrew.png";
import jrc from "../assets/charity/logos/jrc.png";
import linuxFoundation from "../assets/charity/logos/linuxFoundationLogo.png";
import littledreamers from "../assets/charity/logos/littledreamers.png";
import makeAWish from "../assets/charity/logos/make-a-wish.svg";
import mates4mates from "../assets/charity/logos/mates4mates.svg";
import mozillaFoundation from "../assets/charity/logos/mozilla-foundation.svg";
import openSourceCollective from "../assets/charity/logos/open-source-collective.png";
import spaceil from "../assets/charity/logos/spaceil.svg";
import takingbabysteps from "../assets/charity/logos/takingbabysteps.png";
import techSheCan from "../assets/charity/logos/tech-she-can.png";
import theNixosFoundation from "../assets/charity/logos/the-nixos-foundation.jpg";
import unitedway from "../assets/charity/logos/unitedway.png";
import usHungerReliefFoundation from "../assets/charity/logos/us-hunger-relief-foundation.svg";

export enum CharityGeo {
  Global = "Global",
  ASEAN = "ASEAN",
  Australia = "Australia",
  Canada = "Canada",
  Ireland = "Ireland",
  Israel = "Israel",
  Japan = "Japan",
  Singapore = "Singapore",
  Spain = "Spain",
  UnitedKingdom = "United Kingdom",
  UnitedStates = "United States",
}

export type CharityItem =
  | {
      label: string;
      tag: string;
      link: string;
      logo: any;
    }
  | {
      label: "[None]";
      tag: "";
    };

const charities: Record<CharityGeo, CharityItem[]> = {
  [CharityGeo.Global]: [
    { label: "Code Blurb", tag: "codeclub", link: "https://codeclub.org/", logo: codeClub },
    {
      label: "Electronic Frontier Foundation",
      tag: "electronic-frontier-foundation",
      link: "https://www.eff.org/",
      logo: electronicFrontierFoundation,
    },
    { label: "Homebrew", tag: "homebrew", link: "https://brew.sh/", logo: homebrew },
    {
      label: "Linux foundation",
      tag: "linux-foundation",
      link: "https://www.linuxfoundation.org/",
      logo: linuxFoundation,
    },
    { label: "Make-a-wish", tag: "make-a-wish", link: "https://wish.org/", logo: makeAWish },
    {
      label: "Mozilla foundation",
      tag: "mozilla-foundation",
      link: "https://foundation.mozilla.org/en/",
      logo: mozillaFoundation,
    },
    {
      label: "NixOS foundation",
      tag: "nixos-foundation",
      link: "https://nixos.org/community/index.html#foundation",
      logo: theNixosFoundation,
    },
    {
      label: "Open Source Collective",
      tag: "open-source-collective",
      link: "https://opencollective.com/opensource#category-ABOUT",
      logo: openSourceCollective,
    },
    {
      label: "United Way",
      tag: "unitedway",
      link: "https://www.unitedway.org/",
      logo: unitedway,
    },
  ],
  [CharityGeo.ASEAN]: [
    {
      label: "The Asia Foundation",
      tag: "asiafoundation",
      link: "https://asiafoundation.org/emerging-issues/go-digital-asean/",
      logo: asiaFoundation,
    },
  ],
  [CharityGeo.Australia]: [
    {
      label: "Little Dreamers",
      tag: "littledreamers",
      link: "https://www.littledreamers.org.au/",
      logo: littledreamers,
    },
    {
      label: "Mates4Mates",
      tag: "mates4mates",
      link: "https://mates4mates.org/",
      logo: mates4mates,
    },
  ],
  [CharityGeo.Canada]: [
    {
      label: "Daily Bread",
      tag: "dailybread",
      link: "https://www.dailybread.ca/",
      logo: dailyBread,
    },
    {
      label: "Her Volution",
      tag: "hervolution",
      link: "https://www.hervolution.org/",
      logo: hervolution,
    },
  ],
  [CharityGeo.Ireland]: [
    { label: "Aware", tag: "aware", link: "https://www.aware.ie/", logo: aware },
    {
      label: "Taking Baby Steps",
      tag: "takingbabysteps",
      link: "https://takingbabysteps.co.uk/",
      logo: takingbabysteps,
    },
  ],
  [CharityGeo.Israel]: [
    { label: "ALUT", tag: "alutfriends", link: "https://alutfriends.org/", logo: alutFriends },
    {
      label: "SpaceIL",
      tag: "spaceil",
      link: "https://www.eng.spaceil.com/home-page",
      logo: spaceil,
    },
  ],
  [CharityGeo.Japan]: [
    {
      label: "Japanese Red Cross Society",
      tag: "jrc",
      link: "https://www.jrc.or.jp/english/donate/",
      logo: jrc,
    },
  ],
  [CharityGeo.Singapore]: [
    {
      label: "Engineering Good",
      tag: "engineering-good",
      link: "https://www.engineeringgood.org/",
      logo: engineeringGood,
    },
  ],
  [CharityGeo.Spain]: [
    {
      label: "Spanish Association Against Cancer",
      tag: "contraelcancer-es",
      link: "https://www.contraelcancer.es/",
      logo: contraelCancerEs,
    },
  ],
  [CharityGeo.UnitedKingdom]: [
    {
      label: "Forces Employment",
      tag: "forces-employment-uk",
      link: "https://www.forcesemployment.org.uk/",
      logo: forcesEmploymentUk,
    },
    {
      label: "TechSheCan",
      tag: "tech-she-can",
      link: "https://techshecan.org/",
      logo: techSheCan,
    },
  ],
  [CharityGeo.UnitedStates]: [
    {
      label: "Girls who code",
      tag: "girls-who-code",
      link: "https://girlswhocode.com/",
      logo: girlsWhoCode,
    },
    {
      label: "U.S. Hunger Relief Organization",
      tag: "us-hunger-relief-organization",
      link: "https://www.feedingamerica.org/",
      logo: usHungerReliefFoundation,
    },
  ],
};

export default charities;
