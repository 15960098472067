import { useEffect, useMemo, useState } from "react";

import { useHistory } from "react-router-dom";
import { CommitmentManagersModel } from "@doitintl/cmp-models";
import { getCollection, type ModelId } from "@doitintl/models-firestore";
import { Box, Button, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";

import { Loader } from "../../Components/Loader";
import { useCustomerContext } from "../../Context/CustomerContext";
import { consoleErrorWithSentry } from "../../utils/errorHandling";
import { CommitmentCard } from "./CommitmentCard";
import { Onboarding } from "./Onboarding";

export const CommitmentManagerList = () => {
  const { customer } = useCustomerContext();
  const [commitmentManagers, setCommitmentManagers] = useState<ModelId<CommitmentManagersModel>[] | undefined | null>(
    undefined
  );
  const history = useHistory();

  useEffect(
    () =>
      getCollection(CommitmentManagersModel)
        .where("customerId", "==", customer.id)
        .onSnapshot(
          (snap) => {
            const commitmentManagers = snap.docs.map((doc) => {
              const data = doc.asModelData();
              if (data) {
                (data as ModelId<CommitmentManagersModel>).id = doc.id;
              }
              return data as ModelId<CommitmentManagersModel>;
            });
            setCommitmentManagers(commitmentManagers);
          },
          (error) => {
            consoleErrorWithSentry(error);
            setCommitmentManagers(null);
          }
        ),
    [customer]
  );

  const { activeCommitments, inactiveCommitments } = useMemo(() => {
    if (!commitmentManagers) {
      return { activeCommitments: [], inactiveCommitments: [] };
    }

    const now = DateTime.now().toUTC();
    const active: ModelId<CommitmentManagersModel>[] = [];
    const inactive: ModelId<CommitmentManagersModel>[] = [];

    commitmentManagers.forEach((commitment) => {
      if (!commitment.periods || commitment.periods.length === 0) {
        return;
      }

      const lastPeriod = commitment.periods[commitment.periods.length - 1];
      const endDate = DateTime.fromJSDate(lastPeriod.endDate.toDate()).toUTC();

      if (endDate < now) {
        inactive.push(commitment);
      } else {
        active.push(commitment);
      }
    });

    return { activeCommitments: active, inactiveCommitments: inactive };
  }, [commitmentManagers]);

  const handleCreateCommitment = () => {
    history.push(`/customers/${customer.id}/commitment-manager/create`);
  };

  const handleCardClick = (commitmentId: string) => {
    history.push(`/customers/${customer.id}/commitment-manager/${commitmentId}`);
  };

  return (
    <Loader loading={commitmentManagers === undefined}>
      {commitmentManagers && commitmentManagers.length > 0 ? (
        <Stack spacing={2} sx={{ width: { lg: "70%" }, mx: "auto", mb: 4 }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h1">Commitment Managers</Typography>
              <Typography variant="body1" color="text.secondary">
                Track and manage your cloud commitment spend
              </Typography>
            </Box>
            <Button variant="contained" color="primary" onClick={handleCreateCommitment}>
              Create Commitment Manager
            </Button>
          </Stack>

          <Box>
            {activeCommitments.map((commitment) => (
              <CommitmentCard key={commitment.id} commitment={commitment} onCardClick={handleCardClick} />
            ))}
          </Box>

          {inactiveCommitments.length > 0 && (
            <Stack spacing={2}>
              <Typography variant="h4">Inactive commitment managers</Typography>
              {inactiveCommitments.map((commitment) => (
                <CommitmentCard key={commitment.id} commitment={commitment} onCardClick={handleCardClick} />
              ))}
            </Stack>
          )}
        </Stack>
      ) : (
        <Onboarding />
      )}
    </Loader>
  );
};
