import { useMemo } from "react";

import { UserPermissions } from "@doitintl/cmp-models";
import uniqBy from "lodash/uniqBy";

import { type InviteWithRole, type UserWithRole } from "../../../types";
import { useRoles } from "../IAM/useRoles";
import { useInvites, useUsers } from "../IAM/useUsersOrInvites";

const getUserEmails = (users: (UserWithRole | InviteWithRole)[]) =>
  users
    .filter((user) => user.roles?.permissions?.find((p) => p.id === UserPermissions.CloudAnalyticsUser))
    .map((user) => user.email);

const useAnalyticsUsers = () => {
  const { roles } = useRoles();
  const { users: rawAllUsers } = useUsers(roles);
  const { invites: rawAllInvites } = useInvites(roles);

  const { userEmails, allUsersAndInvites, invites } = useMemo(() => {
    const uniqueUsers = uniqBy(rawAllUsers, "email");
    const uniqueInvites = uniqBy(rawAllInvites, "email");

    const userEmails = getUserEmails(uniqueUsers);
    const inviteEmails = getUserEmails(uniqueInvites);

    const allUsersAndInvites = {
      allUsers: uniqueUsers.map((user) => user.email),
      allInvites: uniqueInvites.map((invite) => invite.email),
    };

    return { userEmails, allUsersAndInvites, invites: inviteEmails };
  }, [rawAllUsers, rawAllInvites]);

  return useMemo(
    () => ({
      invites,
      userEmails,
      allUsersAndInvites,
    }),
    [allUsersAndInvites, invites, userEmails]
  );
};

export default useAnalyticsUsers;
