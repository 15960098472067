import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import Grid from "@mui/material/Grid2";
import Typography from "@mui/material/Typography";

import { cloudflowTexts } from "../../../assets/texts";
import { type CloudflowBlueprint } from "../types";
import Blueprint from "./Blueprint";

type BlueprintsRowProps = {
  blueprints?: Readonly<Array<CloudflowBlueprint>>;
  blueprintsLoading?: boolean;
  onBlueprintClick: (blueprint: CloudflowBlueprint) => void;
  onClickViewAll?: () => void;
  numBlueprints?: number;
  disabled?: boolean;
};

const BlueprintsRow = ({
  blueprints,
  onBlueprintClick,
  onClickViewAll,
  blueprintsLoading,
  numBlueprints = 3,
  disabled = false,
}: BlueprintsRowProps) => {
  if (numBlueprints < 1) {
    return null;
  }

  if (blueprintsLoading) {
    return (
      <Grid container={true} spacing={1}>
        <Grid size={12}>
          <Stack
            direction="row"
            sx={{
              py: 1,
              justifyContent: "space-between",
              alignItems: "center",
              gap: 1,
              width: "100%",
            }}
          >
            <Skeleton variant="rectangular" height={28} width={309} />
            <Skeleton variant="rectangular" height={28} width={162} />
          </Stack>
        </Grid>
        <Grid size={12}>
          <Stack
            direction="row"
            sx={{
              gap: 1,
            }}
          >
            {Array.from({ length: numBlueprints }, (_, index) => (
              <Box key={index} sx={{ flexGrow: 1 }}>
                <Skeleton variant="rectangular" height={160} />
              </Box>
            ))}
          </Stack>
        </Grid>
      </Grid>
    );
  }

  if (!blueprints || blueprints.length === 0) {
    return null;
  }

  return (
    <Grid container spacing={1}>
      <Grid size={12}>
        <Stack
          direction="row"
          sx={{
            py: 1,
            justifyContent: "space-between",
            alignItems: "center",
            gap: 1,
            width: "100%",
          }}
        >
          <Typography variant="h4" data-cy="blueprintsSubTitle">
            {cloudflowTexts.BLUEPRINTS_ROW_TITLE}
          </Typography>
          <Button
            variant="text"
            data-cy="viewAllBlueprintsButton"
            onClick={onClickViewAll}
            sx={{
              cursor: "pointer",
            }}
          >
            {cloudflowTexts.VIEW_ALL_BLUEPRINTS} <ArrowForwardIcon sx={{ height: 16, width: 16, ml: 1 }} />
          </Button>
        </Stack>
      </Grid>
      <Grid size={12}>
        <Stack
          direction="row"
          spacing={2}
          sx={{
            justifyContent: "center",
            alignItems: "stretch",
          }}
        >
          {(blueprints || []).slice(0, numBlueprints).map((blueprint) => (
            <Box key={blueprint.id} sx={{ width: `${100 / numBlueprints}%` }}>
              <Blueprint
                key={blueprint.id}
                blueprint={blueprint}
                onBlueprintClick={onBlueprintClick}
                clouds={blueprint.tags?.cloud || []}
                disabled={disabled}
              />
            </Box>
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default BlueprintsRow;
