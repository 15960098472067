import { useEffect, useRef } from "react";

import { Box, Stack, useTheme } from "@mui/material";
import { Resizable } from "re-resizable";

import { AvaCaseIQ } from "./AvaCaseIQ";
import { useAvaCaseIQContext } from "./AvaCaseIQProvider";
import { useAvaDialogContext } from "./AvaDialogContextProvider";
import { ChatHistory } from "./ChatHistory";
import { ChatToolbar } from "./ChatToolbar";
import { Messenger } from "./Messenger";

type Props = {
  closeDialog: () => void;
};

export const AvaResizableChat = ({ closeDialog }: Props) => {
  const ref = useRef<Resizable>(null);

  const { isAvaDialogMinimized, width, height, setWidth, setHeight, isFullscreenMode } = useAvaDialogContext();

  const { caseIQConversationId, loading } = useAvaCaseIQContext();
  const theme = useTheme();

  useEffect(() => {
    const currentSize = ref.current?.size.height;
    if (caseIQConversationId && currentSize && currentSize < 900) {
      setHeight("900px");
    }
  }, [caseIQConversationId, setHeight]);

  let content: React.ReactNode = null;

  if (loading) {
    content = (
      <Stack sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <img src="/doit-loading.gif" alt="Loading" style={{ height: 170 }} />
      </Stack>
    );
  } else if (caseIQConversationId) {
    content = <AvaCaseIQ />;
  } else {
    content = (
      <Stack
        sx={{
          flexDirection: "row",
          flex: 1,
          overflow: isFullscreenMode ? "hidden" : "auto",
        }}
      >
        {isFullscreenMode && <ChatHistory />}
        <Messenger />
      </Stack>
    );
  }

  return (
    <Box
      sx={{
        position: "absolute",
        display: !isAvaDialogMinimized ? "flex" : "none",
        justifyContent: isFullscreenMode ? "center" : "flex-end",
        alignItems: isFullscreenMode ? "center" : "flex-end",
        zIndex: 1200,
        width: "100%",
        height: "-webkit-fill-available",
        pointerEvents: "none",
        pr: isFullscreenMode ? 0 : 1,
      }}
    >
      <Resizable
        ref={ref}
        style={{
          position: "fixed",
          pointerEvents: "auto",
          border: "1px solid",
          borderColor: theme.palette.divider,
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
          backgroundColor: theme.palette.general.backgroundDark,
          borderRadius: isFullscreenMode ? 4 : 0,
          display: "flex",
        }}
        size={{ width, height }}
        maxHeight={isFullscreenMode ? "" : "90vh"}
        maxWidth={isFullscreenMode ? "" : "90vw"}
        minHeight={250}
        minWidth={300}
        onResizeStop={(e, direction, ref) => {
          setWidth(ref.style.width);
          setHeight(ref.style.height);
        }}
        enable={{
          top: !isFullscreenMode,
          right: false,
          bottom: false,
          left: !isFullscreenMode,
          topLeft: !isFullscreenMode,
        }}
      >
        <Stack justifyContent="space-between" sx={{ flex: 1, maxWidth: "100%" }}>
          <ChatToolbar onCloseClicked={closeDialog} hideHistory={!!caseIQConversationId || isFullscreenMode} />
          {content}
        </Stack>
      </Resizable>
    </Box>
  );
};
