import { useCallback, useMemo } from "react";

import { MenuItem, TextField } from "@mui/material";

import { sanitizeKeepingLocalDate } from "../../../../../../utils/common";
import { commitmentTermOptions } from "../../../const";
import { useContractFormContext } from "../../../ContractsFormContext";
import { calculateChargePerTerm, isNavigatorSolve } from "../../../utils";

export const CommitmentMonthsSelector = () => {
  const { state, setState } = useContractFormContext();

  const availableCommitmentTermOptions = useMemo(() => {
    if (!isNavigatorSolve(state)) {
      return null;
    }
    return state.paymentTerm === "annual" ? [12] : commitmentTermOptions;
  }, [state]);

  const handleChange = useCallback(
    ({ target: { value } }) => {
      if (!isNavigatorSolve(state)) {
        return null;
      }
      const commitmentMonths = value;
      const endDate =
        commitmentMonths && commitmentMonths > 0
          ? sanitizeKeepingLocalDate(state.startDate.plus({ months: commitmentMonths }))
          : state.endDate;
      const chargePerTerm = calculateChargePerTerm(
        state.paymentTerm,
        commitmentMonths,
        state.tier?.name === "enterprise" ? state.chargePerTerm : state.tier?.price?.[state.currency]
      );
      const paymentTerm = commitmentMonths === -1 ? "monthly" : state.paymentTerm;

      setState({
        ...state,
        commitmentMonths,
        endDate,
        chargePerTerm,
        paymentTerm,
      });
    },
    [setState, state]
  );

  if (!isNavigatorSolve(state)) {
    return null;
  }

  return (
    <TextField
      label="Subscription term"
      data-cy="subscription-term"
      value={state.commitmentMonths ?? ""}
      onChange={handleChange}
      disabled={state.isEditForbidden}
      select
      fullWidth
      margin="normal"
      variant="outlined"
    >
      {availableCommitmentTermOptions?.map((option) => (
        <MenuItem key={option} value={option}>
          {option} months
        </MenuItem>
      ))}
      {state.paymentTerm === "monthly" && <MenuItem value={-1}>Custom</MenuItem>}
    </TextField>
  );
};
