import { ClusterType } from "@doitintl/cmp-models";
import { Box, Grid2, Skeleton, Tooltip } from "@mui/material";

import awsLogo from "../../../../../../assets/aws-logo.svg";
import awsLogoDark from "../../../../../../assets/aws-logo-dark-mode.svg";
import gcpLogo from "../../../../../../assets/gcp-logo.svg";
import { useDarkThemeCheck } from "../../../../../../Components/hooks/useDarkThemeCheck";
import ClusterTypeOption from "./ClusterTypeOption";

export interface ClusterTypeSelectorOption {
  type: ClusterType;
  label: string;
  logoSrc: string;
}

interface ClusterTypeSelectorProps {
  selectedClusterType: ClusterType | null;
  onSelect: (type: ClusterType) => void;
  disabledTypes?: ClusterType[];
  loading?: boolean;
}

const ClusterTypeSelector = ({ selectedClusterType, onSelect, disabledTypes, loading }: ClusterTypeSelectorProps) => {
  const darkMode = useDarkThemeCheck();

  const options: ClusterTypeSelectorOption[] = [
    { type: ClusterType.EKS, label: "EKS", logoSrc: darkMode ? awsLogoDark : awsLogo },
    { type: ClusterType.GKE, label: "GKE", logoSrc: gcpLogo },
  ];

  const renderOptionCard = (option: ClusterTypeSelectorOption) => {
    const disabled = disabledTypes?.includes(option.type);

    return (
      <ClusterTypeOption
        onClick={() => {
          onSelect(option.type);
        }}
        option={option}
        disabled={disabled}
        isSelected={selectedClusterType === option.type}
      />
    );
  };

  if (loading) {
    return <Skeleton variant="rectangular" height={78} />;
  }

  return (
    <Grid2
      container
      spacing={{
        sm: 2,
        xs: 1,
      }}
    >
      {options.map((option) => (
        <Grid2
          size={{
            sm: 6,
            xs: 12,
          }}
          key={option.type}
        >
          {disabledTypes?.includes(option.type) ? (
            <Tooltip title="No clusters available">
              <Box>{renderOptionCard(option)}</Box>
            </Tooltip>
          ) : (
            renderOptionCard(option)
          )}
        </Grid2>
      ))}
    </Grid2>
  );
};

export default ClusterTypeSelector;
