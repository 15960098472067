import { useEffect, useMemo, useState } from "react";

import {
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
  type CurrencyCode,
  CustomerModel,
  EntityModel,
} from "@doitintl/cmp-models";
import {
  type DocumentSnapshotModel,
  getCollection,
  useCollectionDataOnce,
  type WithFirebaseModel,
} from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import { DialogContentText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import { DateTime } from "luxon";
import * as yup from "yup";

import { globalText } from "../../assets/texts";
import { CustomerPicker } from "../../Components/CustomerPicker/CustomerPicker";
import AutoLocaleDatePicker from "../../Components/DateRange/AutoLocaleDatePicker";
import LoadingButton from "../../Components/LoadingButton";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { type InvoiceAdjustmentType } from "../../Context/InvoiceAdjustmentsContext";
import { consoleErrorWithSentry } from "../../utils";
import { assetTypeName, formatCurrency, getCurrencyByCode, sanitizeDate } from "../../utils/common";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import { serverTimestamp } from "../../utils/firebase";
import { CurrencyOptions } from "../CloudAnalytics/utilities";

const assetTypes = [
  AssetTypeAmazonWebServices,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeMicrosoftAzure,
  AssetTypeOffice365,
];

const useStyles = makeStyles((theme) => ({
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
  },
  spacer: {
    flex: "1 1 100%",
  },
  contentText: {
    margin: theme.spacing(2, 0, 0),
    minHeight: "24px",
  },
}));

const transformEntities = (val: WithFirebaseModel<EntityModel>, snapshot: DocumentSnapshotModel<EntityModel>) => ({
  priorityId: val.priorityId,
  name: val.name,
  id: snapshot.id,
  currency: val.currency,
});

type Values = {
  customerId: string | undefined | null;
  currency: CurrencyCode | null | undefined | "";
  details: string;
  type: string;
  description: string;
  entityId: string | undefined | null;
  amount: number;
  startDate?: DateTime | null;
  endDate?: DateTime | null;
};

type Props = {
  onClose: () => void;
  adjustment: InvoiceAdjustmentType | undefined | null;
};

const adjustmentSchema = yup.object().shape({
  customerId: yup.string().required("Customer is a required field"),
  entityId: yup.string().required("Billing profile is a required field"),
  description: yup.string().max(48).required(),
  details: yup.string().max(120),
  amount: yup.number().typeError("Must be a number").notOneOf([0], "Can not be 0").required("Amount is required"),
  currency: yup.string().oneOf(CurrencyOptions).required(),
  type: yup.string().oneOf(assetTypes).required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
});

const AdjustmentFormDialog = ({ onClose, adjustment }: Props) => {
  const classes = useStyles();
  const { userId } = useAuthContext({ mustHaveUser: true });
  const sharedSnackbar = useSnackbar();
  const { isMobile: matches } = useFullScreen();
  const [currencies, setCurrencies] = useState<CurrencyCode[]>(
    adjustment?.data.currency ? [adjustment.data.currency] : []
  );
  const [customerId, setCustomerId] = useState(adjustment?.data.customer.id ?? "");
  const [entityId, setEntityId] = useState(adjustment?.data.entity?.id ?? "");

  const customerRef = useMemo(
    () => (customerId ? getCollection(CustomerModel).doc(customerId) : undefined),
    [customerId]
  );

  const [entities, entitiesLoading] = useCollectionDataOnce(
    customerId ? getCollection(EntityModel).where("customer", "==", customerRef) : undefined,
    {
      transform: transformEntities,
    }
  );

  const selectedEntity = useMemo(
    () =>
      (entities ?? []).find((option) => option.id === entityId) ?? {
        priorityId: "",
        name: "",
        id: "",
        currency: undefined,
      },
    [entities, entityId]
  );

  const isEdit = !!adjustment;

  useEffect(() => {
    if (isEdit) {
      return;
    }

    if (selectedEntity) {
      const currency = getCurrencyByCode(selectedEntity.currency);
      const currencies = [currency];

      if (currency !== "USD") {
        currencies.unshift("USD");
      }

      setCurrencies(currencies);

      return;
    }

    setCurrencies([]);
  }, [selectedEntity, isEdit]);

  const submitHandler = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    if (
      !customerRef ||
      !selectedEntity ||
      !values.customerId ||
      !values.entityId ||
      !values.startDate ||
      !values.endDate ||
      !values.currency ||
      !values.amount
    ) {
      sharedSnackbar.onOpen({
        message: "Validation error",
        variant: "error",
      });
      return;
    }

    const customerData = (await customerRef.get()).asModelData();
    const entityRef = getCollection(EntityModel).doc(entityId);

    if (!customerData) {
      return;
    }

    const invoiceMonths: Date[] = [];
    for (let dt = values.startDate; dt <= values.endDate; dt = dt.plus({ months: 1 })) {
      invoiceMonths.push(dt.toUTC().startOf("month").toJSDate());
    }

    const updateFields = {
      description: values.description,
      details: values.details,
      amount: values.amount,
      entity: entityRef,
      invoiceMonths,
      timestamp: serverTimestamp(),
      updatedBy: userId,
      metadata: {
        customer: {
          primaryDomain: customerData.primaryDomain,
          name: customerData.name,
          priorityId: selectedEntity.priorityId,
        },
      },
    } as const;

    const message = isEdit ? "Invoice adjustment updated successfully" : "Invoice adjustment created successfully";
    setSubmitting(true);
    try {
      if (adjustment) {
        await adjustment.ref.update(updateFields);
      } else {
        const adjustmentRef = customerRef.collection("customerInvoiceAdjustments");

        await adjustmentRef.add({
          ...updateFields,
          currency: values.currency,
          finalized: false,
          type: values.type,
          customer: customerRef,
        });
      }

      sharedSnackbar.onOpen({
        message,
        variant: "success",
        autoHideDuration: 3000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });

      onClose();
    } catch (error: any) {
      sharedSnackbar.onOpen({
        message: error.message ? error.message : "Invoice adjustment creation failed",
        variant: "error",
        autoHideDuration: 10000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    } finally {
      setSubmitting(false);
    }
  };

  const today = DateTime.utc();

  return (
    <Formik<Values>
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={adjustmentSchema}
      validateOnMount
      onSubmit={submitHandler}
      initialValues={{
        customerId: adjustment?.data?.customer?.id ?? null,
        currency: adjustment?.data?.currency ?? "",
        details: adjustment?.data.details ?? "",
        type: adjustment?.data.type ?? "",
        description: adjustment?.data.description ?? "",
        entityId: adjustment?.data?.entity?.id ?? null,
        amount: adjustment?.data.amount ?? 0,
        startDate: adjustment?.data.invoiceMonths
          ? sanitizeDate(DateTime.fromJSDate(adjustment?.data.invoiceMonths[0].toDate()))
          : null,
        endDate: adjustment?.data.invoiceMonths
          ? sanitizeDate(
              DateTime.fromJSDate(adjustment?.data.invoiceMonths[adjustment.data.invoiceMonths.length - 1].toDate())
            )
          : null,
      }}
    >
      {({
        setFieldValue,
        resetForm,
        setFieldTouched,
        setSubmitting,
        errors,
        isSubmitting,
        values,
        handleChange,
        handleBlur,
        touched,
      }) => {
        const getTotalMonths = () => {
          if (!values.startDate || !values.endDate) {
            return 0;
          }
          return values.startDate <= values.endDate ? values.endDate.diff(values.startDate, "months").months + 1 : 0;
        };

        return (
          <Dialog
            open={true}
            aria-labelledby="adjustment-dialog-title"
            onClose={preventOnCloseWhile(isSubmitting, onClose)}
            fullScreen={matches}
            fullWidth
            maxWidth="sm"
          >
            <Form className={classes.dialogForm}>
              <DialogTitle id="adjustment-dialog-title">Add invoice adjustment</DialogTitle>
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1}>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <Box sx={{ mt: 1 }}>
                      <CustomerPicker
                        data-cy="customer-select-textfield"
                        value={customerId}
                        onChange={(_event, newCustomer) => {
                          setCustomerId(newCustomer?.objectID ?? "");
                          setEntityId("");
                          resetForm({
                            values: {
                              customerId: newCustomer?.objectID ?? null,
                              currency: "",
                              entityId: "",
                              details: "",
                              description: "",
                              amount: 0,
                              type: "",
                              startDate: null,
                              endDate: null,
                            },
                          });
                        }}
                        disabled={isEdit || isSubmitting}
                        getOptionLabel={({ primaryDomain, hasActiveBillingProfile }) =>
                          hasActiveBillingProfile ? primaryDomain : `${primaryDomain} (inactive)`
                        }
                        getOptionDisabled={({ hasActiveBillingProfile }) => !hasActiveBillingProfile}
                        TextFieldProps={{
                          label: "Customer",
                          variant: "outlined",
                          helperText: (touched.customerId && errors.customerId) || "Required",
                          error: touched.customerId && Boolean(errors.customerId),
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <Autocomplete
                      data-cy="entity-autocomplete"
                      disableClearable
                      options={entities ?? []}
                      getOptionLabel={({ priorityId, name }) => `${priorityId} ${name}`}
                      isOptionEqualToValue={(o, v) => o.id === v.id}
                      value={selectedEntity}
                      onChange={(_event, newEntity) => {
                        setEntityId(newEntity.id);
                        setFieldValue("entityId", newEntity?.id);
                      }}
                      disabled={isSubmitting || entitiesLoading}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Billing profile"
                          variant="outlined"
                          margin="dense"
                          helperText={(touched.entityId && errors.entityId) || "Required"}
                          error={touched.entityId && Boolean(errors.entityId)}
                          {...params}
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              endAdornment: (
                                <>
                                  {customerId && entitiesLoading ? (
                                    <CircularProgress color="inherit" size={20} />
                                  ) : (
                                    params.InputProps.endAdornment
                                  )}
                                </>
                              ),
                            },
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      name="type"
                      label="Type"
                      margin="dense"
                      variant="outlined"
                      disabled={isSubmitting || isEdit}
                      helperText={(touched.type && errors.type) || ""}
                      error={touched.type && Boolean(errors.type)}
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      select
                      fullWidth
                      data-cy="type"
                      slotProps={{
                        select: {
                          MenuProps: {
                            MenuListProps: {
                              dense: true,
                            },
                          },
                        },
                      }}
                    >
                      {assetTypes.map((option) => (
                        <MenuItem key={option} value={option}>
                          {assetTypeName(option)}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      name="description"
                      label="Description"
                      variant="outlined"
                      margin="dense"
                      helperText={(touched.description && errors.description) || "Short description of the adjustment"}
                      error={touched.description && Boolean(errors.description)}
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      fullWidth
                      data-cy="description"
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  </Grid>

                  <Grid size={12}>
                    <TextField
                      name="details"
                      label="Details"
                      variant="outlined"
                      margin="dense"
                      helperText={(touched.details && errors.details) || "Optional details for the adjustment"}
                      error={touched.details && Boolean(errors.details)}
                      value={values.details}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      fullWidth
                      slotProps={{
                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      select
                      fullWidth
                      name="currency"
                      label="Currency"
                      margin="dense"
                      variant="outlined"
                      disabled={isSubmitting || isEdit}
                      helperText={(touched.currency && errors.currency) || ""}
                      error={touched.currency && Boolean(errors.currency)}
                      value={values.currency}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      data-cy="currency"
                      slotProps={{
                        select: {
                          MenuProps: {
                            MenuListProps: {
                              dense: true,
                            },
                          },
                        },
                      }}
                    >
                      {currencies.map((option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>

                  <Grid size={6}>
                    <TextField
                      type="number"
                      name="amount"
                      label="Amount"
                      variant="outlined"
                      margin="dense"
                      helperText={(touched.amount && errors.amount) || `Total amount per month`}
                      error={touched.amount && Boolean(errors.amount)}
                      value={values.amount}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      fullWidth
                      data-cy="amount"
                      slotProps={{
                        input: {
                          inputProps: {
                            step: "0.01",
                          },
                        },

                        inputLabel: {
                          shrink: true,
                        },
                      }}
                    />
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <AutoLocaleDatePicker
                      TextFieldProps={{
                        name: "startDate",
                        margin: "dense",
                        fullWidth: true,
                        error: touched.startDate && !!errors.startDate,
                        helperText: "Starting from invoice month",
                        onBlur: () => {
                          setFieldTouched("startDate", true);
                        },
                        "data-cy": "start-date",
                      }}
                      label="Start Month"
                      value={values.startDate ?? null}
                      onChange={(e) => {
                        if (e) {
                          const newDate = e
                            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                            .setZone("utc", { keepLocalTime: true });
                          setFieldValue("startDate", newDate);
                        }
                      }}
                      disabled={isEdit || isSubmitting}
                      minDate={today.minus({ months: 2 }).startOf("month")}
                      views={["year", "month"]}
                      openTo="month"
                    />
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <AutoLocaleDatePicker
                      TextFieldProps={{
                        name: "endDate",
                        margin: "dense",
                        fullWidth: true,
                        error: touched.endDate && !!errors.endDate,
                        helperText: "Until and including invoice month",
                        onBlur: () => {
                          setFieldTouched("endDate", true);
                        },
                        "data-cy": "end-date",
                      }}
                      label="End Month"
                      value={values.endDate ?? null}
                      onChange={(e) => {
                        if (e) {
                          const newDate = e
                            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                            .setZone("utc", { keepLocalTime: true });
                          setFieldValue("endDate", newDate);
                        }
                      }}
                      disabled={isSubmitting}
                      minDate={values.startDate?.minus({ months: 1 }).startOf("month")}
                      views={["year", "month"]}
                      openTo="month"
                    />
                  </Grid>
                </Grid>

                <DialogContentText className={classes.contentText} data-cy="total-text">
                  {values.currency
                    ? `Total of ${formatCurrency(values.amount * getTotalMonths(), values.currency, 2)} over period of ${getTotalMonths()} months`
                    : ""}
                </DialogContentText>
              </DialogContent>

              <Divider />

              <DialogActions>
                {isEdit && (
                  <>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={async () => {
                        if (isEdit) {
                          try {
                            setSubmitting(true);
                            await adjustment.ref.delete();
                            setSubmitting(false);
                            resetForm();
                            onClose();
                          } catch (error) {
                            consoleErrorWithSentry(error);
                          }
                        }
                      }}
                    >
                      DELETE
                    </Button>
                    <div className={classes.spacer} />
                  </>
                )}
                <Button color="primary" variant="text" onClick={onClose} disabled={isSubmitting}>
                  {globalText.CANCEL}
                </Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting}
                  mixpanelEventId="financials.adjustment.save"
                >
                  {globalText.SAVE}
                </LoadingButton>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default AdjustmentFormDialog;
