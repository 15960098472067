import React from "react";

import { Stack, Typography } from "@mui/material";

import { FilterTable } from "../../../../../Components/FilterTable/FilterTable";
import {
  type FilterTableItemDescriptor,
  generateHeadersAndFilters,
} from "../../../../../Components/FilterTable/filterTableUtils";
import { PODFIT_DATA } from "../../data/podfit";
import WorkloadsTableRow from "./WorkloadsTableRow";

export interface WorkloadsTableItem {
  name: string;
  workloadType: string;
  namespace: string;
  totalCost: number;
}

const WorkloadsTable = () => {
  const data = PODFIT_DATA.data;
  const { workloads } = data;

  const itemDescriptions: FilterTableItemDescriptor<WorkloadsTableItem>[] = [
    {
      headerText: "",
      value: "@",
      disableSorting: true,
    },
    {
      headerText: "Name",
      value: "name",
      filterType: "string",
    },
    {
      headerText: "Type",
      value: "workloadType",
      filterType: "string",
    },
    {
      headerText: "Namespace",
      value: "namespace",
      filterType: "string",
    },
    {
      headerText: "Total cost",
      value: "totalCost",
      filterType: "number",
      headerStyle: {
        align: "right",
      },
    },
    {
      headerText: "Container",
      value: "@",
      disableSorting: true,
    },
    {
      headerText: "CPU request",
      value: "@",
      disableSorting: true,
      headerStyle: {
        align: "right",
      },
    },
    {
      headerText: "CPU limit",
      value: "@",
      disableSorting: true,
      headerStyle: {
        align: "right",
      },
    },
    {
      headerText: "Memory request",
      value: "@",
      disableSorting: true,
      headerStyle: {
        align: "right",
      },
    },
    {
      headerText: "Memory limit",
      value: "@",
      disableSorting: true,
      headerStyle: {
        align: "right",
      },
    },
  ];

  const { headerColumns, filters } = generateHeadersAndFilters(itemDescriptions);

  return (
    <Stack>
      <Typography variant="h4" sx={{ mb: -2 }}>
        Workloads ({workloads.length})
      </Typography>
      <FilterTable<WorkloadsTableItem>
        rowComponent={WorkloadsTableRow}
        persistenceKey="k8s-workloads-table"
        tableItems={workloads}
        headerColumns={headerColumns}
        filterColumns={filters}
        defaultSortDirection="asc"
        wrapRowComponent={false}
        tableProps={{
          size: "small",
        }}
        filterAreaSXProp={{
          marginBottom: 1,
        }}
      />
    </Stack>
  );
};

export default WorkloadsTable;
