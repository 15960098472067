import { useCallback, useEffect, useState } from "react";

import { type AssetModel } from "@doitintl/cmp-models";
import { type ModelIdRef } from "@doitintl/models-firestore";
import CancelIcon from "@mui/icons-material/Cancel";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField from "@mui/material/TextField";

type Props = {
  options: ModelIdRef<AssetModel>[];
  value: ModelIdRef<AssetModel>[];
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  setFieldValue: (field: "assets", value: ModelIdRef<AssetModel>[]) => void;
};

const formatAssetLabel = (asset: ModelIdRef<AssetModel>, defaultValue = "") => {
  switch (asset.type) {
    case "google-cloud":
      return `${asset.properties.billingAccountId} (${asset.properties.displayName})`;
    case "amazon-web-services":
      return `${asset.properties.accountId} (${asset.properties.name})`;
    default:
      return defaultValue;
  }
};

const AssetsChipsSelect = ({ options, value, disabled, error, helperText, setFieldValue }: Props) => {
  const getMatchingAssets = useCallback(
    (assets: ModelIdRef<AssetModel>[] = []) =>
      assets
        .map((asset) => options.find((option) => option.id === asset.id))
        .filter(Boolean) as ModelIdRef<AssetModel>[],
    [options]
  );

  const [selectedAssets, setSelectedAssets] = useState<ModelIdRef<AssetModel>[]>(() => getMatchingAssets(value));

  useEffect(() => {
    setSelectedAssets(getMatchingAssets(value));
  }, [value, options, getMatchingAssets]);

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedAssets}
      onChange={(_event, newValue) => {
        setSelectedAssets(newValue);
        setFieldValue("assets", newValue);
      }}
      disabled={disabled}
      getOptionLabel={(option) => formatAssetLabel(option)}
      renderTags={(value, getTagProps) =>
        value.map((asset, index) => (
          <Chip
            {...getTagProps({ index })}
            label={formatAssetLabel(asset)}
            onDelete={() => {
              const updatedAssets = value.filter((v) => v.id !== asset.id);
              setSelectedAssets(updatedAssets);
              setFieldValue("assets", updatedAssets);
            }}
            deleteIcon={<CancelIcon />}
            key={asset.id}
          />
        ))
      }
      renderInput={(params) => (
        <TextField {...params} label="Assets" variant="outlined" margin="dense" error={error} helperText={helperText} />
      )}
      noOptionsText="No match found"
    />
  );
};

export default AssetsChipsSelect;
