import { AppModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import { useDocument } from "@doitintl/models-firestore/src/react-firebase-hooks/firestore/useDocument";
import { Alert, AlertTitle, Typography } from "@mui/material";

export const DisabledBillingProfileBucketEditingBanner = () => {
  const [appBillingProfiles] = useDocument(getCollection(AppModel).doc("entities"));

  const appBillingProfileData = appBillingProfiles?.asModelData();

  return (
    <Alert severity="warning" sx={{ marginBottom: 3 }}>
      <AlertTitle>Maintenance</AlertTitle>
      <Typography>
        Billing profile bucket creation and editing will be temporarily unavailable
        {appBillingProfileData?.disabledStartingDate &&
          ` from ${appBillingProfileData?.disabledStartingDate.toDate().toLocaleDateString()}`}
        . Thank you for your patience.
      </Typography>
    </Alert>
  );
};
