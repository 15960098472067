import React, { useEffect, useState } from "react";

import { CopyCodeBlock } from "@client/src/Components/CopyCodeBlock/CopyCodeBlock";
import {
  AZURE_FEATURE_APP,
  copyCommandNames,
  copyCommandVariables,
  rolePathPrefixes,
} from "@client/src/Pages/Settings/Azure/consts";
import { getCommandCopy } from "@client/src/Pages/Settings/Azure/utils";
import { TextField, Typography } from "@mui/material";
import { type AzureFeaturePermission } from "@services/cmp/models";
import type { Scope } from "@client/src/Pages/Settings/Azure/types";

type Props = {
  feature: AzureFeaturePermission;
  roleChanged: (featureName: string, roleName: string, scope: Scope, resourceId: string, roleId: string) => void;
};

export const FeatureForm = ({ feature, roleChanged }: Props) => {
  const [commands, setCommands] = useState<string[]>([]);
  const [resourcesIds, setResourcesIds] = useState<string[]>([]);
  const [rolesIds, setRoleIds] = useState<string[]>([]);
  const [copyClicked, setCopyClicked] = useState<boolean[]>([]);

  useEffect(() => {
    setCommands(
      feature.roles.map(
        (role) =>
          `az role assignment create --assignee  ${AZURE_FEATURE_APP.clientId} --role ${role.name} --scope ${rolePathPrefixes[role.scope]}/${copyCommandVariables[role.scope]} --query name --output tsv`
      )
    );
  }, [feature]);

  const updateCopyClicked = (idx: number, state: boolean) => {
    const newCopyClicked = [...copyClicked];
    newCopyClicked[idx] = state;
    setCopyClicked(newCopyClicked);
  };
  return (
    <>
      <Typography variant="h4" mb={-2}>
        {feature.description}
      </Typography>

      {feature.roles.map((role, roleIdx) => (
        <React.Fragment key={`role-fragment-${roleIdx}`}>
          <Typography variant="body2">2. Enter {copyCommandNames[role.scope]}</Typography>
          <TextField
            label={copyCommandNames[role.scope]}
            name={copyCommandNames[role.scope]}
            variant="outlined"
            value={resourcesIds[roleIdx]}
            error={!resourcesIds[roleIdx] && copyClicked[roleIdx]}
            onChange={(e) => {
              const resources = [...resourcesIds];
              resources[roleIdx] = e.target.value;
              setResourcesIds(resources);
              roleChanged(feature.name, role.name, role.scope, e.target.value, rolesIds[roleIdx]);
              updateCopyClicked(roleIdx, false);
            }}
          />

          <Typography variant="body2" mb={-2}>
            Assign role {feature.roles[roleIdx].name} to {feature.roles[roleIdx].scope}
          </Typography>

          <CopyCodeBlock
            base={commands[roleIdx] || ""}
            callbackClick={() => {
              updateCopyClicked(roleIdx, true);
            }}
            variables={getCommandCopy(feature.roles[roleIdx].scope, resourcesIds[roleIdx])}
          />
          <Typography variant="body2" mb={-2}>
            Paste role ID response
          </Typography>

          <TextField
            key={`roleId-input-${roleIdx}`}
            label={`Role ID`}
            variant="outlined"
            value={rolesIds[roleIdx]}
            onChange={(e) => {
              const roles = [...rolesIds];
              roles[roleIdx] = e.target.value;
              setRoleIds(roles);
              roleChanged(feature.name, role.name, role.scope, resourcesIds[roleIdx], e.target.value);
            }}
          />
        </React.Fragment>
      ))}
    </>
  );
};
