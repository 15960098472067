import { type Dispatch, type SetStateAction } from "react";

import { type ContractStateType } from "../../types";
import { isDoiTCloudIntelligence } from "../../utils";

export const validateDciSubscriptionStep = (
  state: ContractStateType,
  setState: Dispatch<SetStateAction<ContractStateType>>
): boolean => {
  if (!isDoiTCloudIntelligence(state)) {
    return false;
  }

  const errors: Record<string, boolean> = {};
  if (!state.tierId) {
    errors.tier = true;
  }

  if (state.tier?.name === "essentials") {
    errors.commitmentMonths =
      (state.isCommitment && !state.commitmentMonths) || (!state.isCommitment && !!state.commitmentMonths);
    errors.endDate = state.isCommitment && (!state.endDate || state.endDate < state.startDate);
    errors.minimumServiceFee = state.minimumServiceFee !== undefined;
    errors.cloudSpendPercentage = state.cloudSpendPercentage !== undefined;
    errors.paymentTerm = state.paymentTerm !== undefined;

    setState({ ...state, errors });

    return !Object.values(errors).some((error) => error);
  }

  errors.isCommitment = state.isCommitment === undefined;
  errors.commitmentMonths =
    (state.isCommitment && !state.commitmentMonths) || (!state.isCommitment && !!state.commitmentMonths);
  errors.startDate = !state.startDate;
  errors.endDate = state.isCommitment && (!state.endDate || state.endDate < state.startDate);
  errors.minimumServiceFee = (state.minimumServiceFee as any) === "";
  errors.cloudSpendPercentage = (state.cloudSpendPercentage as any) === "";

  errors.paymentTerm = !state.paymentTerm;
  if (state.paymentTerm === "annual" && state.cloudSpendPercentage !== 0) {
    errors.cloudSpendPercentage = true;
  }

  setState({ ...state, errors });

  return !Object.values(errors).some((error) => error);
};
