import { useState } from "react";

import { AccessLevel, type Cluster, ClusterType, type ClusterWithCloudConnect } from "@doitintl/cmp-models";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  Stack,
  Typography,
} from "@mui/material";

import { LimitedListWithTooltip } from "../../../../../Components/LimitedListWithTooltip";
import { useClustersWithCloudConnect } from "../../../hooks";

interface SelectedAccountAndClusterProps {
  projectId: string | null;
  selectedClusters?: ClusterWithCloudConnect[];
  clusterType?: ClusterType;
  filterAvailableClusters?: (cluster: Cluster) => boolean;
  gcpScope?: AccessLevel;
  showClusterInfo?: boolean;
}

const MAX_CLUSTER_IDS_TO_SHOW = 1;

const displayCluster = (cluster: ClusterWithCloudConnect) => `${cluster.name} (${cluster.region})`;

const SelectedAccountAndCluster = ({
  projectId,
  filterAvailableClusters,
  selectedClusters = [],
  clusterType = undefined,
  gcpScope = AccessLevel.PROJECT,
  showClusterInfo = true,
}: SelectedAccountAndClusterProps) => {
  const [clusters] = useClustersWithCloudConnect();

  const discoveredClusters = clusters?.filter((c) =>
    filterAvailableClusters ? filterAvailableClusters(c) : c.projectId === projectId
  );

  const [clusterListDialogOpen, setClusterListDialogOpen] = useState(false);

  const getProjectIdType = () => {
    const clustersGcpCloudConnectScope =
      selectedClusters.length > 0 ? selectedClusters[0].cloudConnect?.scope : undefined;
    const gcpCloudConnectScope = clustersGcpCloudConnectScope ?? gcpScope;
    const gcpScopeDisplay = gcpCloudConnectScope === "project" ? "Project" : "Organization";

    const isEks =
      (selectedClusters.length > 0 && selectedClusters[0].type === ClusterType.EKS) || clusterType === ClusterType.EKS;

    return isEks ? "Account ID" : gcpScopeDisplay;
  };

  const renderClusterIds = () => {
    if (selectedClusters.length === 0) {
      return null;
    }

    return (
      <LimitedListWithTooltip
        items={selectedClusters.map(displayCluster)}
        limit={MAX_CLUSTER_IDS_TO_SHOW}
        title="Clusters"
      />
    );
  };

  const renderClusterDisplay = () => {
    if (selectedClusters.length > 0) {
      return (
        <Typography variant="body2" color="text.secondary">
          {selectedClusters.length > 1 ? "Clusters" : "Cluster"}:{" "}
          <strong style={{ fontWeight: 500 }}>{renderClusterIds()}</strong>
        </Typography>
      );
    }

    return (
      <Typography
        onClick={() => {
          setClusterListDialogOpen(true);
        }}
        component={Link}
        variant="body2"
        sx={{ textDecoration: "none", cursor: "pointer" }}
      >
        View detected clusters
      </Typography>
    );
  };

  return (
    <>
      <Dialog
        open={clusterListDialogOpen}
        onClose={() => {
          setClusterListDialogOpen(false);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Detected clusters</DialogTitle>
        <DialogContent sx={{ maxWidth: 1000 }}>
          <ul>{discoveredClusters?.map((c) => <li key={c.id}>{displayCluster(c)}</li>)}</ul>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setClusterListDialogOpen(false);
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
      <Stack direction="row" spacing={2}>
        <Typography variant="body2" color="text.secondary">
          {getProjectIdType()}: <strong style={{ fontWeight: 500 }}>{projectId}</strong>
        </Typography>
        {showClusterInfo && (
          <>
            <Divider orientation="vertical" flexItem />
            {renderClusterDisplay()}
          </>
        )}
      </Stack>
    </>
  );
};

export default SelectedAccountAndCluster;
