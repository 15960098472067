import { useMemo } from "react";

import {
  type CloudConnectGoogleCloud,
  type Member,
  type StructureApiServiceModelDescriptor,
} from "@doitintl/cmp-models";
import { type ModelData } from "@doitintl/models-firestore";
import { ListSubheader, MenuItem, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormikContext } from "formik";

import VirtualizedAutocomplete from "../../../../Components/Autocomplete/VirtualizedAutocomplete";
import { useCustomerContext } from "../../../../Context/CustomerContext";
import { useCachedCloudConnectData } from "../../../Settings/GCP/useCloudConnectData";
import { useGetGcpProjects } from "../../hooks";
import { PROJECT_ID_VARIATIONS } from "../utils/gcpUtils";
import { withRootPath } from "./CloudSpecificParameterForm";
import { useFieldCommonProps } from "./useFieldCommonProps";

type GCPParameterFormProps = Readonly<{
  inputModel: StructureApiServiceModelDescriptor<Member>;
  rootPath?: string;
}>;

type OrganizationOrProject = {
  serviceAccount: string;
  displayName: string;
  id: string;
  scope: "organization" | "project";
};

function isConnectedProject(
  cloudConnect: ModelData<CloudConnectGoogleCloud, "id", "ref">
): cloudConnect is ModelData<CloudConnectGoogleCloud, "id", "ref"> & { projectId: string } {
  return cloudConnect.scope === "project" && cloudConnect.projectId !== undefined;
}

export default function GCPParameterForm({ inputModel, rootPath }: GCPParameterFormProps) {
  const { customer } = useCustomerContext();
  const formikProps = useFormikContext();
  const { getFieldProps, setFieldValue } = formikProps;

  const projectMemberName = Object.keys(inputModel.members).find((key) => PROJECT_ID_VARIATIONS.includes(key));

  const organizationField = useFieldCommonProps(
    getFieldProps<string>(withRootPath("organization", rootPath)),
    "Organization or project",
    true
  );
  const projectField = useFieldCommonProps(
    getFieldProps(withRootPath(projectMemberName ?? "", rootPath)),
    "Project",
    true
  );

  const [cloudConnectData, isCloudConnectDataLoading] = useCachedCloudConnectData();

  const connectedOrganizationsAndProjects = useMemo((): Record<string, OrganizationOrProject> => {
    const connectedOrganizations =
      cloudConnectData
        ?.filter(({ scope }) => scope === "organization")
        .flatMap(
          ({ organizations, clientEmail }) =>
            organizations?.map(({ name, displayName }): [string, OrganizationOrProject] => [
              name,
              {
                id: name,
                displayName,
                scope: "organization",
                serviceAccount: clientEmail,
              },
            ]) ?? []
        ) ?? [];
    const connectedProjects =
      cloudConnectData
        ?.filter(isConnectedProject)
        .map(({ projectId, clientEmail }): [string, OrganizationOrProject] => [
          projectId,
          {
            id: projectId,
            scope: "project",
            displayName: projectId,
            serviceAccount: clientEmail,
          },
        ]) ?? [];

    return Object.fromEntries(connectedOrganizations.concat(connectedProjects));
  }, [cloudConnectData]);

  const isOrganization = (maybeOrgId: string) =>
    connectedOrganizationsAndProjects[maybeOrgId]?.scope === "organization";

  const needsProject = projectMemberName !== undefined;
  const shouldShowProjectField = needsProject && isOrganization(organizationField.value);

  const { data: organizationProjects, isLoading: gcpProjectsLoading } = useGetGcpProjects(
    customer.id,
    organizationField.value,
    shouldShowProjectField
  );

  const projects = useMemo(
    () => organizationProjects.map(({ projectId }) => projectId).sort((a, b) => a.localeCompare(b)),
    [organizationProjects]
  );

  const setServiceAccountByPredicate = (id: string) => {
    const cloudConnect = connectedOrganizationsAndProjects[id];
    if (cloudConnect) {
      setFieldValue(withRootPath("serviceAccount", rootPath), cloudConnect.serviceAccount);
    }
  };

  return (
    <Stack sx={{ gap: 2 }}>
      <TextField
        {...organizationField}
        select
        fullWidth
        variant="outlined"
        onChange={({ target: { value } }) => {
          formikProps.setFieldValue(organizationField.name, value);
          setServiceAccountByPredicate(value);
          if (needsProject) {
            setFieldValue(projectField.name, isOrganization(value) ? "" : value);
          }
        }}
      >
        {isCloudConnectDataLoading && (
          <MenuItem disabled value="">
            Loading data...
          </MenuItem>
        )}
        {Object.values(connectedOrganizationsAndProjects)
          .flatMap((item, idx, all) => (all[idx - 1]?.scope !== item.scope ? [item.scope, item] : [item]))
          .map((option) =>
            typeof option === "string" ? (
              <ListSubheader key={`list-header-${option}`}>
                {option === "organization" ? "Organizations" : "Projects"}
              </ListSubheader>
            ) : (
              <MenuItem key={option.id} value={option.id}>
                {option.displayName}
              </MenuItem>
            )
          )}
      </TextField>

      {shouldShowProjectField && (
        <VirtualizedAutocomplete
          loading={gcpProjectsLoading}
          loadingText="Loading projects..."
          noOptionsText="No projects found"
          options={projects}
          disableClearable={!organizationField.value}
          onChange={(_, value) => {
            setFieldValue(projectField.name, value);
          }}
          onBlur={projectField.onBlur}
          renderOption={({ key, ...props }, option) => (
            <MenuItem key={key} {...props} value={option}>
              {option}
            </MenuItem>
          )}
          renderInput={(params) => <TextField {...params} {...projectField} fullWidth variant="outlined" />}
          size="small"
          fullWidth
          value={projectField.value}
        />
      )}
    </Stack>
  );
}
