import { type DataDogSubscription } from "@doitintl/cmp-models";
import { type Timestamp } from "@doitintl/models-types";

import { type Subscription } from "../types";

export const formatSubscription = (subscription: Subscription): DataDogSubscription => ({
  startDate: subscription.startDate as unknown as Timestamp,
  endDate: subscription.endDate as unknown as Timestamp,
  products: subscription.products.map((product) => ({
    id: product.id,
    label: product.label,
    usageType: product.usageType,
    quantity: product.quantity as number,
    multiplier: product.multiplier as string,
    price: product.price as number,
    unit: product.unit as string,
  })),
});
