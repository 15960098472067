import { createContext, type Dispatch, type FC, type ReactNode, useCallback, useContext } from "react";

import { ModelType, type UnwrappedApiServiceModelDescriptor } from "@doitintl/cmp-models";
import { Form, Formik, useFormikContext } from "formik";
import noop from "lodash/noop";
import * as yup from "yup";

import { FormChangesListener } from "../Common/FormChangesListener";
import { useInitialErrors } from "../ConfigurationPanel/Tabs/hooks";
import { BooleanParam } from "./parameters/BooleanParam";
import { ListParam } from "./parameters/ListParam";
import { MapParam } from "./parameters/MapParam";
import { NumberParam } from "./parameters/NumberField";
import { StringParam } from "./parameters/StringParam";
import { StructureParam } from "./parameters/StructureParam";
import { TimestampParam } from "./parameters/TimestampParam";
import { getInitialValueForModel, useApiActionParametersSchema } from "./useApiActionParametersSchema";

export const GenericForm: FC<{
  fieldPath: string;
  inputModel: UnwrappedApiServiceModelDescriptor;
  label: string;
  onRemove?: () => void;
  disallowReferencedField?: boolean;
  renderAsNotRequired?: boolean;
  isListItem?: boolean;
  rootPath?: string;
}> = ({
  inputModel,
  fieldPath,
  onRemove,
  label,
  disallowReferencedField,
  renderAsNotRequired,
  isListItem,
  rootPath,
}) => {
  const formikProps = useFormikContext();

  const fieldProps = formikProps.getFieldProps(fieldPath);

  if (fieldProps.value === undefined) {
    return null;
  }

  switch (inputModel.type) {
    case ModelType.STRING:
      return (
        <StringParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.TIMESTAMP:
      return (
        <TimestampParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.INTEGER:
    case ModelType.FLOAT:
      return (
        <NumberParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.BOOLEAN:
      return (
        <BooleanParam
          inputModel={inputModel}
          fieldProps={fieldProps}
          label={label}
          onRemove={onRemove}
          disallowReferencedField={disallowReferencedField}
          renderAsNotRequired={renderAsNotRequired}
        />
      );

    case ModelType.LIST:
      return (
        <ListParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
          isFormItem={renderAsNotRequired !== undefined}
        />
      );

    case ModelType.STRUCTURE:
      return (
        <StructureParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
          isListItem={isListItem}
          rootPath={rootPath}
        />
      );

    case ModelType.MAP:
      return (
        <MapParam
          fieldPath={fieldPath}
          fieldProps={fieldProps}
          label={label}
          inputModel={inputModel}
          onRemove={onRemove}
        />
      );

    default:
      return null;
  }
};

/**
 * due to a bug in Formik (https://github.com/jaredpalmer/formik/issues/3335) we need to create a validation schema context manually
 * it can be removed after adopting Formik v3, which fixes the bug
 */
const ApiActionParametersValidationSchemaContext = createContext<yup.AnySchema>(yup.mixed());

export const useApiActionParametersValidationSchemaContext = () =>
  useContext(ApiActionParametersValidationSchemaContext);

const ApiActionParametersValidationSchemaContextProvider = ({
  validationSchema,
  children,
}: {
  validationSchema: yup.AnySchema;
  children: ReactNode;
}) => (
  <ApiActionParametersValidationSchemaContext.Provider value={validationSchema}>
    {children}
  </ApiActionParametersValidationSchemaContext.Provider>
);

type ApiActionParametersFormProps<TValues = unknown> = Readonly<{
  inputModel: UnwrappedApiServiceModelDescriptor;
  values?: TValues;
  onValuesChange?: (values: TValues) => void;
  onValidityChange: Dispatch<boolean>;
  enableReinitialize?: boolean;
  children?: ReactNode;
}>;

export function ApiActionParametersForm<TValues = unknown>({
  inputModel,
  values,
  onValuesChange,
  onValidityChange,
  enableReinitialize = false,
  children,
}: ApiActionParametersFormProps<TValues>) {
  const validationSchema = useApiActionParametersSchema(inputModel);

  const castAndDispatchValues = useCallback(
    (values: unknown) => {
      if (onValuesChange) {
        onValuesChange(
          validationSchema.cast(values, {
            context: {
              castingPhase: "outgoing",
            },
          }) as TValues
        );
      }
    },
    [onValuesChange, validationSchema]
  );

  const initialValues = values ? validationSchema.cast(values) : getInitialValueForModel(inputModel);
  const initialErrors = useInitialErrors(validationSchema as yup.Schema, initialValues);

  return (
    <Formik
      validateOnChange
      validateOnBlur
      validateOnMount
      enableReinitialize={enableReinitialize}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={noop}
      initialErrors={initialErrors}
    >
      <ApiActionParametersValidationSchemaContextProvider validationSchema={validationSchema}>
        <Form>
          {children}
          <FormChangesListener onValuesChange={castAndDispatchValues} onValidityChange={onValidityChange} />
        </Form>
      </ApiActionParametersValidationSchemaContextProvider>
    </Formik>
  );
}

export const GenericApiActionParametersForm: FC<ApiActionParametersFormProps> = (props) => (
  <ApiActionParametersForm {...props}>
    <GenericForm inputModel={props.inputModel} fieldPath="" label="" />
  </ApiActionParametersForm>
);
