import { type Cluster, type KubernetesFeature } from "@doitintl/cmp-models";
import { CardContent, Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";

import ClusterFeatureConnectionStatus from "./ClusterFeatureConnectionStatus";

interface MultiClusterFeatureStatusProps {
  feature: KubernetesFeature;
  selectedFeatures: KubernetesFeature[];
  clusters: Cluster[];
  loading: boolean;
}

const MultiClusterFeatureStatus = ({
  feature,
  selectedFeatures,
  clusters,
  loading,
}: MultiClusterFeatureStatusProps) => (
  <>
    <Divider />
    {selectedFeatures.length > 1 && (
      <>
        <CardContent>
          <Typography variant="subtitle1" fontWeight={500}>
            {feature.name}
          </Typography>
        </CardContent>
        <Divider />
      </>
    )}
    <CardContent key={feature.id} style={{ padding: 0 }}>
      {clusters.map((cluster, index) => (
        <Box key={cluster.id}>
          <ClusterFeatureConnectionStatus key={cluster.id} cluster={cluster} feature={feature} loading={loading} />
          {index !== clusters.length - 1 && <Divider />}
        </Box>
      ))}
    </CardContent>
  </>
);

export default MultiClusterFeatureStatus;
