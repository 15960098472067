import {
  ClusterType,
  type CustomerModelCloudConnectModel,
  type KubernetesFeature,
  type KubernetesFeatureRequirement,
  type KubernetesResourceVerb,
} from "@doitintl/cmp-models";

export const DOIT_EKS_GROUP = "doit-eks-group";
export const DEFAULT_DOIT_ROLE_NAME = "doitintl-cmp";

interface K8sGroupPermissions {
  resources: string[];
  verbs: KubernetesResourceVerb[];
}

type K8sApiGroup = string;

type AggregatedRbacPermissions = Partial<Record<K8sApiGroup, K8sGroupPermissions>>;

export const CREATE_ACCESS_ENTRY_BASE_COMMAND = `
aws eks create-access-entry \\
  --cluster-name $CLUSTER_NAME \\
  --principal arn:aws:iam::$ACCOUNT_ID:role/$ROLE_NAME \\
  --kubernetes-group ${DOIT_EKS_GROUP} \\
  --region $REGION
`.trim();

export const ASSOCIATE_ACCESS_POLICY_BASE_COMMAND = `
aws eks associate-access-policy \\
  --cluster-name $CLUSTER_NAME \\
  --principal arn:aws:iam::$ACCOUNT_ID:role/$ROLE_NAME \\
  --policy-arn arn:aws:eks::aws:cluster-access-policy/AmazonEKSViewPolicy \\
  --access-scope type=cluster \\
  --region $REGION
`.trim();

export const getAggregatedRbacPermissions = (
  requirements: KubernetesFeatureRequirement[]
): AggregatedRbacPermissions => {
  const aggregatedPermissions: AggregatedRbacPermissions = {};

  for (const { group = "", resource, verb } of requirements) {
    if (!aggregatedPermissions[group]) {
      aggregatedPermissions[group] = { resources: [], verbs: [] };
    }

    const resources = aggregatedPermissions[group].resources;
    if (!resources.includes(resource)) {
      resources.push(resource);
    }

    const verbs = aggregatedPermissions[group].verbs;
    if (!verbs.includes(verb)) {
      verbs.push(verb);
    }
  }

  return aggregatedPermissions;
};

const getRoleBindingSubject = (clusterType: ClusterType, cloudConnect?: CustomerModelCloudConnectModel): string => {
  if (clusterType === ClusterType.EKS) {
    return `subjects:
  - kind: Group
    name: ${DOIT_EKS_GROUP}`;
  } else if (clusterType === ClusterType.GKE) {
    return `subjects:
  - kind: User
    name: ${cloudConnect?.clientEmail}`;
  }

  return "";
};

const getYamlContentForFeature = (
  clusterType: ClusterType,
  feature: KubernetesFeature,
  cloudConnect?: CustomerModelCloudConnectModel
): string => {
  const { id, name, requirements } = feature;

  const roleName = `doit-k8s-${id}-role`;
  const roleBindingName = `doit-k8s-${id}-binding`;

  const aggregatedPermissions = getAggregatedRbacPermissions(requirements);

  const rules = Object.entries(aggregatedPermissions).map(([key, value]) => {
    const resources = value?.resources.map((r) => `"${r}"`).join(", ");
    const verbs = value?.verbs.map((v) => `"${v}"`).join(", ");

    return `  - apiGroups: ["${key}"]
    resources: [${resources}]
    verbs: [${verbs}]`;
  });

  return `# Define a ClusterRole with permissions for the feature: ${name}
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRole
metadata:
  name: ${roleName}
  labels:
    feature: ${id}
rules:
${rules.join("\n")}

---
# Bind the ClusterRole to a Group
apiVersion: rbac.authorization.k8s.io/v1
kind: ClusterRoleBinding
metadata:
  name: ${roleBindingName}
  labels:
    feature: ${id}
${getRoleBindingSubject(clusterType, cloudConnect)}
roleRef:
  kind: ClusterRole
  name: ${roleName}
  apiGroup: rbac.authorization.k8s.io

`;
};

export const buildRbacYamlFileContent = (
  clusterType: ClusterType,
  features: KubernetesFeature[],
  cloudConnect?: CustomerModelCloudConnectModel
): string => features.map((feature) => getYamlContentForFeature(clusterType, feature, cloudConnect)).join("\n---\n");

export const getRbacYamlFileContent = (
  clusterType: ClusterType,
  features: KubernetesFeature[],
  cloudConnect?: CustomerModelCloudConnectModel
): string => buildRbacYamlFileContent(clusterType, features, cloudConnect);
