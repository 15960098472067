import { useMemo, useState } from "react";

import { Roles } from "@doitintl/cmp-models";

import { allocationTxt } from "../../../assets/texts/CloudAnalytics/allocation";
import ShareDialog from "../dialogs/shareDialog/ShareDialog";
import { CloudAnalyticsEntities } from "../utilities";
import { type AttributionDataWithId } from "./AllocationBrowser/AllocationBrowser";
import { useShareAllocationHandler } from "./hooks";

export type Props = {
  open: boolean;
  attributions: AttributionDataWithId[];
  onClose: () => void;
};

export function AllocationShareDialog({ open, attributions, onClose }: Props) {
  const [shareLoading, setShareLoading] = useState(false);
  const attributionsWithoutPresets = useMemo(() => attributions.filter((x) => x.type !== "preset"), [attributions]);
  const handleChangeSharing = useShareAllocationHandler({ attributions, closeDialog: onClose, setShareLoading });
  return (
    <ShareDialog
      open={open}
      onClose={onClose}
      title={allocationTxt.SHARE_ALLOCATION}
      entity={CloudAnalyticsEntities.ATTRIBUTION}
      handleChangeSharing={handleChangeSharing}
      loading={shareLoading}
      availableRoles={[Roles.EDITOR, Roles.VIEWER]}
      defaultRole={Roles.EDITOR}
      shareEntities={attributionsWithoutPresets}
      showPresetExcludedAlert={attributions.length !== attributionsWithoutPresets.length}
    />
  );
}
