import { type Customer } from "@client/src/types";
import { type SegmentEventProperties } from "@client/src/utils/useSegmentTrackEvent";

export interface SendCloudDiagramsUpsellEventArgs {
  trackEvent: (eventName: string, properties: CloudDiagramsUpsellPageViewedProperties) => void;
  customer: Customer;
}

interface CloudDiagramsUpsellPageViewedProperties extends SegmentEventProperties {
  pageType: "Governance";
  feature: "CloudDiagrams";
  monthlyCloudSpend: number;
}

export function sendCloudDiagramsUpsellEvent(
  eventName: string,
  { trackEvent, customer }: SendCloudDiagramsUpsellEventArgs
) {
  if (!customer) return;

  const monthlyCloudSpend = customer.customerSegment?.monthlyCloudSpend || 0;

  trackEvent(eventName, {
    pageType: "Governance",
    feature: "CloudDiagrams",
    monthlyCloudSpend,
  } as CloudDiagramsUpsellPageViewedProperties);
}
