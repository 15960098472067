import { useEffect, useMemo, useRef, useState } from "react";

import { ThreadModel, UserModel } from "@doitintl/cmp-models";
import { getCollection, type ModelId, useCollectionData } from "@doitintl/models-firestore";
import { type QueryFunctionContext, useQueries, useQuery, type UseQueryResult } from "@tanstack/react-query";
import { type AxiosError } from "axios";

import { useApiContextOrMockAPIContext } from "../../api/context";
import { useErrorSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { queryKeys } from "../../constants";
import { useCustomerContext } from "../../Context/CustomerContext";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { consoleErrorWithSentry } from "../../utils";
import { getCachingKeys } from "../../utils/cachingKeys";
import { type JiraField, type JiraIssue, type JiraIssueType, type JiraProject } from "./types";

type ThreadState = {
  thread: ModelId<ThreadModel> | null | undefined;
  isFetching: boolean;
};

type ThreadsState = {
  threads: ModelId<ThreadModel>[] | undefined;
  isFetching: boolean;
};

export const useInsightThreads = (insightKey: string): ThreadsState => {
  const errSnackbar = useErrorSnackbar(10);

  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  // Get Threads for this Insight and Customer (if it exists) from Firestore
  const query = getCollection(ThreadModel)
    .where("customer", "==", customer.ref)
    .where("isDeleted", "==", false)
    .where("source", "==", "insight")
    .where("sourceData.insightKey", "==", insightKey);

  const [data, isFetching, error] = useCollectionData(query, {
    idField: "id",
    caching: true,
    cachingKeys: [...getCachingKeys(customer.id), "insight-threads", insightKey],
  });

  if (isFetching) {
    return { threads: undefined, isFetching: true };
  }

  if (error !== undefined) {
    consoleErrorWithSentry(error);
    errSnackbar(error.message || "Failed to load threads");
  }

  return { threads: data, isFetching: false };
};

export const useThreads = (): ThreadsState => {
  const errSnackbar = useErrorSnackbar(10);
  const { customerOrPresentationModeCustomer: customer } = useCustomerContext();

  // Get Thread for this Insight and Customer (if it exists) from Firestore
  const query = getCollection(ThreadModel).where("customer", "==", customer.ref).where("isDeleted", "==", false);

  const [data, isFetching, error] = useCollectionData(query, {
    idField: "id",
    caching: true,
    cachingKeys: [...getCachingKeys(customer.id), "threads"],
  });

  return useMemo(() => {
    if (isFetching) {
      return { threads: undefined, isFetching: true };
    }

    if (error !== undefined) {
      consoleErrorWithSentry(error);
      errSnackbar(error.message || "Failed to load threads");
    }

    return { threads: data, isFetching: false };
  }, [data, errSnackbar, error, isFetching]) as ThreadsState;
};

type JiraIssueState = {
  jiraIssue: JiraIssue | undefined | null;
  isErrored: boolean;
};

export const useJiraIssue = (issueKey: string | undefined): JiraIssueState => {
  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  const { isFetching, data, error } = useQuery<JiraIssue | null, AxiosError>(
    [customer.id, queryKeys.jiraIssue, issueKey],
    async () => {
      if (!issueKey) {
        return null;
      }

      const response = await api.get<JiraIssue>(`threads/jira/issue?customerID=${customer.id}&issueID=${issueKey}`);
      return response.data;
    },
    {
      enabled: !!issueKey,
      // Allow retries, as we have a race condition here just after thread creation, where it sometimes can't be loaded yet
      retry: 2,
      // After this is unmounted, keep the data for a minute, to avoid re-fetching when going from page to page
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
    }
  );

  if (isFetching) {
    return { jiraIssue: undefined, isErrored: false };
  }

  if (error) {
    consoleErrorWithSentry(error);

    return { jiraIssue: null, isErrored: true };
  }

  const issue: JiraIssue | null = data
    ? {
        summary: data.summary,
        description: data.description,
        status: data.status,
        statusCategory: data.statusCategory,
        assignee: data.assignee,
        priority: data.priority,
        project: data.project,
        issueType: data.issueType,
        dueDate: data.dueDate,
        createdDate: data.createdDate,
        reporter: data.reporter,
        updatedDate: data.updatedDate,
        updatedName: data.updatedName,
      }
    : null;

  return { jiraIssue: issue, isErrored: false };
};

export const useMultipleJiraIssues = (issueKeys: string[]) => {
  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  const settledQueriesCounter = useRef(0);

  const newJiraIssues = useQueries({
    queries: issueKeys.map((issueKey) => ({
      onSettled: () => {
        settledQueriesCounter.current = settledQueriesCounter.current + 1;
      },
      queryKey: [queryKeys.jiraIssue, customer.id, issueKey],
      queryFn: async ({ signal }: { signal?: QueryFunctionContext["signal"] }) => {
        try {
          const response = await api.get<JiraIssue>(
            `threads/jira/issue?customerID=${customer.id}&issueID=${issueKey}`,
            { signal }
          );
          return response.data;
        } catch (error) {
          consoleErrorWithSentry(error);
          throw error;
        }
      },
      // After this is unmounted, keep the data for a minute, to avoid re-fetching when going from page to page
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
    })),
  });

  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>({});

  useEffect(() => {
    const newLoadingStates = issueKeys.reduce(
      (acc, issueKey, index) => {
        acc[issueKey] = newJiraIssues[index]?.isLoading ?? true;
        return acc;
      },
      {} as Record<string, boolean>
    );

    setLoadingStates(newLoadingStates);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settledQueriesCounter.current, issueKeys]);

  // Make a map, so we can easier find the correct result later
  // useQueries has issues with react-query v4, and we are using are workaround to make the data stable
  // see here https://github.com/TanStack/query/issues/5137
  return useMemo(
    () =>
      issueKeys.reduce(
        (acc, issueKey, index) => {
          acc[issueKey] = newJiraIssues[index];
          return acc;
        },
        {} as Record<string, UseQueryResult<JiraIssue>>
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [settledQueriesCounter.current, loadingStates]
  );
};

export const useThreadId = (threadId: string): ThreadState => {
  const [data, setData] = useState<ModelId<ThreadModel> | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    setIsFetching(true);
    getCollection(ThreadModel)
      .doc(threadId)
      .get()
      .then((doc) => {
        const fetchedData = doc.asModelData();
        if (!fetchedData) {
          setData(null);
          return;
        }
        setData({ ...fetchedData, id: doc.id });
      })
      .catch((error) => {
        consoleErrorWithSentry(error);
        setData(null);
      })
      .finally(() => {
        setIsFetching(false);
      });
  }, [threadId]);

  return { thread: data, isFetching };
};

export const useJiraProjects = () => {
  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  return useQuery<JiraProject[], AxiosError>(
    ["jiraProjects", customer.id],
    async ({ signal }) => {
      const response = await api.get<JiraProject[]>(`threads/jira/projects?customerID=${customer.id}`, {
        signal,
      });

      return response.data;
    },
    {
      // After this is unmounted, keep the data for a minute, to avoid re-fetching when going from page to page
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
    }
  );
};

export const useJiraIssueTypes = (selectedProject: JiraProject | null) => {
  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  return useQuery<JiraIssueType[], AxiosError>(
    ["jiraIssueTypes", customer.id, selectedProject?.key],
    async ({ signal }) => {
      if (!selectedProject) {
        return [];
      }

      const response = await api.get<JiraIssueType[]>(
        `threads/jira/projects/issueTypes?customerID=${customer.id}&projectID=${selectedProject.key}`,
        {
          signal,
        }
      );

      return response.data.filter((issueType) => issueType.name !== "Sub-task");
    },
    {
      enabled: !!selectedProject,
      // After this is unmounted, keep the data for a minute, to avoid re-fetching when going from page to page
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
    }
  );
};

export const useJiraFields = (selectedProject: JiraProject | null, selectedIssueType: JiraIssueType | null) => {
  const { customer: originalCustomer, customerOrPresentationModeCustomer: customer } = useCustomerContext();
  const isPresentationMode = isCustomerInPresentationMode(originalCustomer);
  const api = useApiContextOrMockAPIContext(isPresentationMode);

  return useQuery<JiraField[], AxiosError>(
    ["jiraFields", customer.id, selectedProject?.key, selectedIssueType?.id],
    async ({ signal }) => {
      if (!selectedProject || !selectedIssueType) {
        return [];
      }

      const response = await api.get<JiraField[]>(
        `threads/jira/projects/fields?customerID=${customer.id}&projectID=${selectedProject.key}&issueTypeID=${selectedIssueType.id}`,
        {
          signal,
        }
      );

      return response.data
        .filter(
          (field) =>
            (field.required && !field.hasDefaultValue) || field.key === "summary" || field.key === "description"
        )
        .filter((field) => field.key !== "project" && field.key !== "issuetype");
    },
    {
      enabled: !!selectedProject && !!selectedIssueType,
      // After this is unmounted, keep the data for a minute, to avoid re-fetching when going from page to page
      cacheTime: 1000 * 60,
      staleTime: 1000 * 60,
    }
  );
};

type UserState = {
  user: UserModel | null | undefined;
  isFetching: boolean;
};

export const useUserId = (userId: string | undefined): UserState => {
  const [data, setData] = useState<UserModel | null>(null);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    const fetchUser = async () => {
      if (!userId) {
        setData(null);

        return;
      }

      setIsFetching(true);

      try {
        const doc = await getCollection(UserModel).doc(userId).get();
        const fetchedData = doc.asModelData();
        setData(fetchedData || null);
      } catch (error) {
        consoleErrorWithSentry(error);
        setData(null);
      } finally {
        setIsFetching(false);
      }
    };

    fetchUser();
  }, [userId]);

  return { user: data, isFetching };
};
