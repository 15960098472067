import { useCallback } from "react";

import { useParams } from "react-router";
import { SyncLoader } from "react-spinners";
import { AnalyticsDataSource, Metadata } from "@doitintl/cmp-models";
import { Add } from "@mui/icons-material";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { allocationTxt } from "../../../../assets/texts/CloudAnalytics/allocation";
import { DraggableContainer } from "../../../../Components/DragAndDrop/DraggableContainer";
import { DraggableGroup } from "../../../../Components/DragAndDrop/DraggableGroup";
import { useAnalyticsDimensions } from "../../../../Components/hooks/cloudAnalytics/useAnalyticsDimensions/useAnalyticsDimensions";
import { useCloudAnalyticsMetadata } from "../../../../Components/hooks/cloudAnalytics/useCloudAnalyticsMetadata";
import { useAuthContext } from "../../../../Context/AuthContext";
import useSegmentTrackEvent from "../../../../utils/useSegmentTrackEvent";
import AllocationSegment from "../AllocationSegment";
import useAllocationDndStore from "../hooks/useAllocationDnd";
import { UnallocatedCosts } from "../UnallocatedCosts";
import { trackAllocationEvent } from "../utils";
import { AllocationDraggableItem } from "./AllocationDraggableItem/AllocationDraggableItem";

type Props = {
  disabled: boolean;
};

export function AllocationDragAndDrop({ disabled }: Props) {
  const { items, addItem, setItems, hasUnallocatedCosts } = useAllocationDndStore();
  const theme = useTheme();
  const { trackEvent } = useSegmentTrackEvent();
  const { metadata, hasDataHub } = useCloudAnalyticsMetadata();
  const { allocationId } = useParams<{ allocationId: string }>();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { dimensions = [] } = useAnalyticsDimensions({
    metadataSnapshots: metadata,
    defaultDataSource: hasDataHub ? AnalyticsDataSource.BILLING_DATAHUB : AnalyticsDataSource.BILLING,
    excludeMetadataTypes: [Metadata.ATTRIBUTION_GROUP],
  });

  const handleAddAllocation = useCallback(() => {
    addItem(crypto.randomUUID());
    trackAllocationEvent({
      trackEvent,
      eventName: "Allocation Rule Added",
      ruleCount: items.main.length,
      hasUnallocatedCosts,
      allocationId,
      currentUserEmail: currentUser.email,
    });
  }, [addItem, trackEvent, items.main.length, hasUnallocatedCosts, allocationId, currentUser.email]);

  const handleItemsOrderChanged = useCallback(
    (newItems) => {
      setItems(newItems);
      trackAllocationEvent({
        trackEvent,
        eventName: "Allocation Rule Reordered",
        ruleCount: items.main.length,
        hasUnallocatedCosts,
        allocationId,
        currentUserEmail: currentUser.email,
      });
    },
    [setItems, trackEvent, items.main.length, hasUnallocatedCosts, allocationId, currentUser.email]
  );

  const renderDraggingItem = useCallback(
    (id: string) => <AllocationDraggableItem grabbing initialAttributionID={id} dimensions={dimensions} />,
    [dimensions]
  );

  const renderContent = useCallback(
    (items: string[]) => (
      <>
        {items.map((id, index) => (
          <AllocationDraggableItem
            key={id}
            disabled={disabled}
            attributionNumber={index}
            initialAttributionID={id}
            dimensions={dimensions}
          />
        ))}
      </>
    ),
    [dimensions, disabled]
  );

  if (items.main?.length === 0 || dimensions.length === 0) {
    return (
      <Stack width="100%" alignItems="center">
        <SyncLoader size={10} color={theme.palette.primary.main} loading />
      </Stack>
    );
  }

  return (
    <Stack gap={2}>
      <Stack>
        <DraggableContainer
          values={items}
          renderDraggingItem={renderDraggingItem}
          onItemsOrderChanged={handleItemsOrderChanged}
        >
          <Stack direction="column">
            <DraggableGroup id="main" renderContent={renderContent} />
          </Stack>
        </DraggableContainer>
        {hasUnallocatedCosts && (
          <Box>
            <Button
              variant="outlined"
              size="medium"
              disabled={disabled}
              startIcon={<Add />}
              onClick={handleAddAllocation}
            >
              {allocationTxt.ADD_ALLOCATION}
            </Button>
          </Box>
        )}
      </Stack>
      {hasUnallocatedCosts && (
        <Stack gap={2}>
          <Typography
            variant="subtitle1"
            sx={{ fontWeight: 500 }}
          >{`Allocation rule ${items.main.length + 1}`}</Typography>
          <UnallocatedCosts />
        </Stack>
      )}
      {!hasUnallocatedCosts && <AllocationSegment disabled={disabled} />}
    </Stack>
  );
}
