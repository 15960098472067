import { useCallback } from "react";

import { DashboardType } from "@doitintl/cmp-models";

import aws from "../../assets/aws-logo.svg";
import awsDark from "../../assets/aws-logo-dark-mode.svg";
import azure from "../../assets/azure-logo-a.png";
import datadog from "../../assets/datadog-logo.png";
import datadogDark from "../../assets/datadog-logo-white.png";
import eks from "../../assets/eks-logo.svg";
import gcp from "../../assets/gcp-logo.svg";
import gke from "../../assets/gke-logo.png";
import bq from "../../assets/google-bq.png";
import pulse from "../../assets/pulse.png";
import snowflake from "../../assets/snowflake.svg";
import { useDarkThemeCheck } from "../../Components/hooks/useDarkThemeCheck";

const dashboardDescriptionByType: Record<string, [string, string]> = {
  [DashboardType.Pulse]: ["View all cloud spend", pulse],
  [DashboardType.AwsLens]: ["Dive into AWS consumption", aws],
  [DashboardType.GcpLens]: ["Understand GCP usage", gcp],
  [DashboardType.EKSLens]: ["Detailed map of EKS costs", eks],
  [DashboardType.SnowflakeLens]: ["Dive into Snowflake cost", snowflake],
  [DashboardType.BqLens]: ["Optimize BigQuery costs", bq],
  [DashboardType.AzureLens]: ["Detailed look at Azure billing", azure],
  [DashboardType.GkeLensV2]: ["Manage GKE spend", gke],
  [DashboardType.SaaSAwsLens]: ["Dive into AWS consumption", aws],
  [DashboardType.SaaSGcpLens]: ["Understand GCP usage", gcp],
  [DashboardType.AWSMAPLens]: ["Dive into AWS MAP costs", aws],
  [DashboardType.DatadogLens]: ["Dive into Datadog costs", datadog],
};

export function useDashboardInfo(): (dashboardType: DashboardType) => [string, string] {
  const isDarkMode = useDarkThemeCheck();

  return useCallback(
    (dashboardType: DashboardType) => {
      const info = dashboardDescriptionByType[dashboardType];

      if (isDarkMode && [DashboardType.SaaSAwsLens, DashboardType.AwsLens].includes(dashboardType)) {
        return [info[0], awsDark];
      }

      if (isDarkMode && [DashboardType.SaaSAwsLens, DashboardType.DatadogLens].includes(dashboardType)) {
        return [info[0], datadogDark];
      }

      return info;
    },
    [isDarkMode]
  );
}
