import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import { type Node } from "@xyflow/react";

import { cloudflowTexts, globalText } from "../../../assets/texts";
import LoadingButton from "../../../Components/LoadingButton";
import { useCloudflowOperations } from "../CloudflowBuilder/Common/CloudflowOperationsProvider";
import { type RFNode } from "../types";

type RemoveReferencedNodeDialogProps = {
  isOpen: boolean;
  onCancel: () => void;
  onConfirm: () => Promise<void>;
  nodesWithReferencedNode: Node<RFNode<CloudFlowNodeType>>[];
};

export const RemoveReferencedNodeDialog = ({
  isOpen,
  onCancel,
  onConfirm,
  nodesWithReferencedNode,
}: RemoveReferencedNodeDialogProps) => {
  const { httpOperationLoading } = useCloudflowOperations();

  return (
    <Dialog open={isOpen} onClose={onCancel} maxWidth="sm">
      <DialogTitle>{cloudflowTexts.REMOVE_REFERENCED_NODE_DIALOG.TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>{cloudflowTexts.REMOVE_REFERENCED_NODE_DIALOG.CONTENT}</DialogContentText>
        <List dense sx={{ py: 2 }}>
          {nodesWithReferencedNode.map((node) => (
            <ListItem key={node.id} disableGutters dense disablePadding>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                }}
              >{`${node.data.stepNumber}. ${node.data.nodeData.name}`}</Typography>
            </ListItem>
          ))}
        </List>
        <DialogContentText>{cloudflowTexts.REMOVE_REFERENCED_NODE_DIALOG.DELETE_ACTION}</DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onCancel}>{globalText.CANCEL}</Button>
        <LoadingButton
          loading={httpOperationLoading}
          variant="contained"
          color="error"
          onClick={onConfirm}
          mixpanelEventId="cloudflow-delete-referenced-node"
        >
          {globalText.DELETE}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
