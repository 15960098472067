import { CategoryStatus } from "@doitintl/cmp-models";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Autocomplete, Box, Chip, FormControl, Skeleton, Stack, TextField, Tooltip, Typography } from "@mui/material";

import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type GCPCloudConnectData, useCloudConnectData } from "../../../../Settings/GCP/useCloudConnectData";
import { usePermissionCategories } from "../../../../Settings/GCP/usePermissionCategories";

interface GcpCloudConnectSelectorProps {
  value?: GCPCloudConnectData | null;
  onChange?: (value: GCPCloudConnectData | null) => void;
  size?: "small" | "medium";
  targetCategories?: string[];
  filterAvailableOptions?: (account: GCPCloudConnectData) => boolean;
}

const GcpCloudConnectSelector = ({
  value = null,
  onChange = () => {},
  size = "medium",
  filterAvailableOptions = () => true,

  // Optional array of target categories. If provided, the selector options will be filtered
  // based on the presence of the target categories.
  targetCategories = [],
}: GcpCloudConnectSelectorProps) => {
  const categories = usePermissionCategories();
  const { customer } = useCustomerContext();
  const [cloudConnectData, , isLoaded] = useCloudConnectData(customer.id);

  const featureDetectionModeEnabled = targetCategories.length > 0;

  const availableOptions = cloudConnectData.filter(filterAvailableOptions);

  const connectedCloudConnectDocs = availableOptions.filter((doc) =>
    targetCategories.every((f) => doc.data.categoriesStatus[f] === CategoryStatus.Healthy)
  );

  const getOptionIdentifier = (option: GCPCloudConnectData) =>
    (option.data.scope === "organization" ? option.data.organizations?.[0].displayName : option.data.projectId) ??
    option.id;

  const getOptionLabel = (option: GCPCloudConnectData) => `${getOptionIdentifier(option)} (${option.data.scope})`;

  const renderOption = (props: React.HTMLAttributes<HTMLLIElement> & { key: string }, option: GCPCloudConnectData) => {
    const targetCategoryNames = categories.filter((c) => targetCategories.includes(c.id)).map((c) => c.name);

    return (
      <li {...props} key={props.key}>
        <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
          <Stack direction="row" spacing={2}>
            <span>{getOptionIdentifier(option)}</span>
            <Typography fontWeight={300} variant="subtitle2" color="text.secondary">
              ({option.data.scope})
            </Typography>
          </Stack>
          {featureDetectionModeEnabled && !connectedCloudConnectDocs.includes(option) && (
            <Tooltip
              title={`This ${option.data.scope} doesn't have the required features enabled (${targetCategoryNames.join(", ")}).`}
            >
              <Chip variant="status" color="warning" size="small" icon={<InfoOutlinedIcon />} label="Not connected" />
            </Tooltip>
          )}
        </Box>
      </li>
    );
  };

  const groupBy = (option: GCPCloudConnectData) => {
    if (featureDetectionModeEnabled) {
      return connectedCloudConnectDocs.map((a) => a.id).includes(option.id)
        ? "Connected projects/organizations"
        : "Not connected projects/organizations";
    }

    return "";
  };

  if (!isLoaded) {
    return <Skeleton variant="rectangular" width={350} height={56} />;
  }

  const sortedOptions = [...availableOptions].sort((a, b) => {
    if (connectedCloudConnectDocs.includes(a) && !connectedCloudConnectDocs.includes(b)) {
      return -1;
    }
    if (!connectedCloudConnectDocs.includes(a) && connectedCloudConnectDocs.includes(b)) {
      return 1;
    }
    return 0;
  });

  return (
    <FormControl required>
      <Autocomplete
        options={sortedOptions}
        getOptionLabel={getOptionLabel}
        value={value}
        onChange={(event, value) => {
          onChange(value);
        }}
        groupBy={groupBy}
        renderOption={renderOption}
        renderInput={(params) => <TextField {...params} label="GCP project/organization" size={size} />}
      />
    </FormControl>
  );
};

export default GcpCloudConnectSelector;
