import { useEffect, useState } from "react";

import { AssetModel, CustomerModel, type EntityModel } from "@doitintl/cmp-models";
import { getCollection, type ModelIdRef, useCollectionData } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/Close";
import { Alert, MenuItem } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import { Form, Formik } from "formik";
import { type FormikHelpers } from "formik/dist/types";
import trim from "lodash/trim";
import { DateTime } from "luxon";
import * as yup from "yup";

import { useApiContext } from "../../api/context";
import { globalText } from "../../assets/texts";
import AssetsChipsSelect from "../../Components/AssetsChipsSelect";
import CustomerSelect from "../../Components/CustomerSelect";
import AutoLocaleDatePicker from "../../Components/DateRange/AutoLocaleDatePicker";
import EntitySelect from "../../Components/EntitySelect";
import { CircularProgressLoader } from "../../Components/Loader";
import LoadingButton from "../../Components/LoadingButton";
import { type GCPPricebook } from "../../Components/Pricebooks/Types";
import { useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../utils";
import { preventOnCloseWhile, useFullScreen } from "../../utils/dialog";
import { type CustomerData, useCustomer } from "../CreditsAdjustments/hooks";

const useStyles = makeStyles((theme) => ({
  dialogForm: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 auto",
  },
  dialogContent: {
    paddingBottom: theme.spacing(4),
  },
  spacer: {
    flex: "1 1 100%",
  },
}));

const schema = yup.object().shape({
  entity: yup.object().required(),
  customer: yup.object().nullable().required(),
  assets: yup.array().nullable(),
  tableRef: yup.string().required(),
  startDate: yup.date().required(),
  endDate: yup.date().required(),
});

type Props = {
  onClose: () => void;
  pricebook: GCPPricebook | null;
};

type Values = {
  tableRef: string;
  startDate: DateTime | undefined;
  endDate: DateTime | undefined;
  assets: ModelIdRef<AssetModel>[] | undefined;
  entity: ModelIdRef<EntityModel> | undefined | null;
  customer: CustomerData | undefined | null;
};

type CustomError = {
  message?: string;
  response?: {
    data?: {
      error?: string;
    };
  };
  stack?: string;
};

const GoogleCloudFormDialog = ({ onClose, pricebook }: Props) => {
  const classes = useStyles();
  const { userId } = useAuthContext();
  const sharedSnackbar = useSnackbar();
  const { isMobile: matches } = useFullScreen();
  const { customer, setCustomer, entity, setEntity, entities } = useCustomer(
    pricebook?.customer.id,
    pricebook?.entity.id
  );
  const api = useApiContext();

  const [tables, setTables] = useState<string[]>([]);
  const [loadingTables, setLoadingTables] = useState<boolean>(false);
  const [fetchTablesError, setFetchTablesError] = useState<string>("");
  const [pricebookAssets, setPricebookAssets] = useState<ModelIdRef<AssetModel>[]>([]);

  useEffect(() => {
    if (customer?.ref.id) {
      const fetchTables = async () => {
        setLoadingTables(true);
        setFetchTablesError("");
        try {
          const response = await api.request<string[]>({
            method: "get",
            url: `v1/pricebooks/google-cloud/tables/${customer?.ref.id}`,
          });

          setTables(response.data);
        } catch (error) {
          const customError = error as CustomError;
          consoleErrorWithSentry(error);
          setFetchTablesError(customError.response?.data?.error || "Error fetching tables");
          setTables([]);
        } finally {
          setLoadingTables(false);
        }
      };

      fetchTables();
    } else {
      setTables([]);
    }
  }, [customer, api]);

  const [assets] = useCollectionData(
    customer?.ref.id
      ? getCollection(AssetModel)
          .where("customer", "==", getCollection(CustomerModel).doc(customer.ref.id))
          .where("type", "==", "google-cloud")
      : undefined,
    { refField: "ref", idField: "id" }
  );

  const isEdit = Boolean(pricebook);

  useEffect(() => {
    if (!isEdit && !pricebook) {
      return;
    }

    const pricebookAssetsIds = pricebook?.assets?.map((asset) => asset.id) ?? [];

    setPricebookAssets(assets?.filter((asset) => pricebookAssetsIds.includes(asset.ref.id)) ?? []);
  }, [assets, isEdit, pricebook]);

  const handleSubmit = async (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    if (
      !values.startDate ||
      !values.endDate ||
      !values.customer ||
      !values.entity ||
      !values.tableRef ||
      !values.entity.priorityId
    ) {
      return;
    }

    setSubmitting(true);
    try {
      const assets = values.assets && values.assets.length > 0 ? values.assets.map((asset) => asset.ref) : null;

      const valuesData = {
        startDate: values.startDate.toJSDate(),
        endDate: values.endDate.toJSDate(),
        entity: values.entity.ref,
        tableRef: trim(values.tableRef),
        assets,
        updatedBy: userId,
        metadata: {
          customer: {
            primaryDomain: values.customer.primaryDomain,
            name: values.customer.name,
          },
          entity: {
            priorityId: values.entity.priorityId,
          },
        },
      } as const;
      const message = pricebook ? "Pricebook updated successfully" : "Pricebook created successfully";

      if (pricebook) {
        await pricebook.ref.update(valuesData);
      } else {
        await values.customer.ref
          .collection("customerPricebooks")
          .add({ ...valuesData, customer: values.customer.ref, type: "google-cloud" });
      }

      sharedSnackbar.onOpen({
        message,
        variant: "success",
        autoHideDuration: 3000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });

      onClose();
    } catch (error: any) {
      sharedSnackbar.onOpen({
        message: error.message ? error.message : "Pricebook creation failed",
        variant: "error",
        autoHideDuration: 10000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
      consoleErrorWithSentry(error);
    } finally {
      setSubmitting(false);
    }
  };

  // prevent from the form to render before the customer is loaded
  if (pricebook && (!customer || !entity)) {
    return <CircularProgressLoader />;
  }

  const showTableMenuItems = () => {
    if (!customer?.ref?.id) {
      return <MenuItem disabled>Select a customer to show the tables</MenuItem>;
    } else if (loadingTables) {
      return <MenuItem disabled>Loading...</MenuItem>;
    } else if (fetchTablesError) {
      return <MenuItem disabled>{fetchTablesError}</MenuItem>;
    } else if (tables.length > 0) {
      return tables.map((table) => (
        <MenuItem key={table} value={table}>
          {table}
        </MenuItem>
      ));
    }

    return <MenuItem disabled>Invalid data format</MenuItem>;
  };

  return (
    <Formik<Values>
      validateOnChange={true}
      validateOnBlur={true}
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        tableRef: pricebook?.tableRef || "",
        startDate: pricebook ? DateTime.fromJSDate(pricebook.startDate.toDate()) : undefined,
        endDate: pricebook ? DateTime.fromJSDate(pricebook.endDate.toDate()) : undefined,
        assets: pricebookAssets ?? [],
        customer,
        entity,
      }}
    >
      {({
        errors,
        setFieldValue,
        isSubmitting,
        isValid,
        handleBlur,
        values,
        setFieldTouched,
        handleChange,
        touched,
      }) => {
        const entityAssets = assets?.filter((asset) => entity?.ref.id === asset.entity?.id) ?? [];
        return (
          <Dialog
            open={true}
            aria-labelledby="pricebook-dialog-title"
            onClose={preventOnCloseWhile(isSubmitting, onClose)}
            fullScreen={matches}
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="pricebook-dialog-title">{isEdit ? "Edit pricebook" : "Add pricebook"}</DialogTitle>
            <Alert severity="warning" sx={{ mx: 3 }}>
              If you are replacing a pricebook for a customer, adjust the end date of the older pricebook to prevent
              overlap
            </Alert>
            <Form
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              className={classes.dialogForm}
            >
              <DialogContent className={classes.dialogContent}>
                <Grid container spacing={1}>
                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <CustomerSelect
                      value={values.customer}
                      error={Boolean(touched.customer && errors.customer)}
                      disabled={isEdit || isSubmitting}
                      setFieldValue={(field, customer) => {
                        setCustomer(customer);
                        return setFieldValue(field, customer);
                      }}
                      helperText="Start typing the customer's name to see matching suggestions"
                    />
                  </Grid>

                  <Grid
                    size={{
                      xs: 12,
                      md: 6,
                    }}
                  >
                    <EntitySelect
                      options={entities}
                      setFieldValue={(field, entity) => {
                        setEntity(entity);
                        return setFieldValue(field, entity);
                      }}
                      value={values.entity}
                      TextFieldProps={{
                        variant: "outlined",
                        margin: "dense",
                        disabled: isSubmitting,
                        error: touched.entity && Boolean(errors.entity),
                        InputLabelProps: {
                          shrink: true,
                        },
                        SelectProps: {
                          onBlur: handleBlur,
                          MenuProps: {
                            MenuListProps: {
                              dense: true,
                            },
                          },
                        },
                        fullWidth: true,
                      }}
                    />
                  </Grid>

                  <Grid size={12}>
                    <AssetsChipsSelect
                      options={entityAssets}
                      value={values.assets ?? []}
                      setFieldValue={setFieldValue}
                      helperText={
                        touched.assets && errors.assets ? "Optional. Leave empty to include all assets" : undefined
                      }
                      error={touched.assets && Boolean(errors.assets)}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid size={12}>
                    <TextField
                      name="tableRef"
                      label="Table"
                      variant="outlined"
                      margin="dense"
                      select
                      helperText={(touched.tableRef && errors.tableRef) || "BigQuery table reference"}
                      error={touched.tableRef && Boolean(errors.tableRef)}
                      value={values.tableRef}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={isSubmitting}
                      fullWidth
                      data-cy="table-select"
                    >
                      {showTableMenuItems()}
                    </TextField>
                  </Grid>

                  <Grid size={6}>
                    <AutoLocaleDatePicker
                      TextFieldProps={{
                        helperText: "Pricebook start date",
                        name: "startDate",
                        margin: "dense",
                        fullWidth: true,
                        onBlur: () => setFieldTouched("startDate", true),
                        "data-cy": "start-date",
                      }}
                      label="Start Date"
                      value={values.startDate ?? null}
                      onChange={(e) => setFieldValue("startDate", e)}
                      disabled={isSubmitting}
                      maxDate={values.endDate}
                    />
                  </Grid>

                  <Grid size={6}>
                    <AutoLocaleDatePicker
                      TextFieldProps={{
                        name: "endDate",
                        margin: "dense",
                        fullWidth: true,
                        helperText: (touched.endDate && errors.endDate) || "Pricebook end date (exclusive)",
                        error: touched.endDate && Boolean(errors.endDate),
                        onBlur: () => setFieldTouched("endDate", true),
                        "data-cy": "end-date",
                      }}
                      label="End Date"
                      value={values.endDate ?? null}
                      onChange={(e) => setFieldValue("endDate", e)}
                      disabled={isSubmitting}
                      minDate={values.startDate}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <Divider />

              <DialogActions>
                <Button color="primary" variant="text" onClick={onClose} disabled={isSubmitting}>
                  {globalText.CANCEL}
                </Button>
                <LoadingButton
                  color="primary"
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  disabled={isSubmitting || !isValid}
                  mixpanelEventId="pricebooks.google-cloud-form.save"
                >
                  {globalText.SAVE}
                </LoadingButton>
              </DialogActions>
            </Form>
          </Dialog>
        );
      }}
    </Formik>
  );
};

export default GoogleCloudFormDialog;
