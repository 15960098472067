import { type DataRecord } from "../ReportData";

export function getWidgetFormatter(cols?: DataRecord[]) {
  const isDate = cols?.every((c) => c?.type === "datetime") ?? false;
  const weekColIndex = cols?.findIndex((c) => c.id === "datetime:week") ?? -1;
  const weekDayColIndex = cols?.findIndex((c) => c.id === "datetime:week_day") ?? -1;
  const colsLength = cols?.length ?? 0;

  return (value: string | number): string => {
    const valueStr = value.toString();
    const colParts = valueStr.split("-");

    if (colParts.length !== colsLength) {
      return valueStr;
    }

    if (weekDayColIndex !== -1 || weekColIndex !== -1) {
      // Use abbreviated weekday names e.g. "Sun" for "Sunday"
      if (weekDayColIndex !== -1) {
        colParts[weekDayColIndex] = colParts[weekDayColIndex].slice(0, 3);
      }

      // Change "W17 (Apr 25)" to "Apr 25"
      if (weekColIndex !== -1) {
        colParts[weekColIndex] = colParts[weekColIndex].slice(5, 11);
      }

      return colParts.join("-");
    }

    // validating if is date and if is valid date
    if (isDate && colParts.length > 2 && !isNaN(Date.parse(valueStr))) {
      if (colParts.length > 3) {
        value = valueStr.slice(0, 10);
      }
      return `${new Date(value).toLocaleString("en", { month: "short" })} ${new Date(value).toLocaleString("en", {
        day: "numeric",
      })}`;
    }

    return valueStr;
  };
}
