import { Link } from "react-router-dom";
import { UserPermissions } from "@doitintl/cmp-models";
import ForwardIcon from "@mui/icons-material/ArrowForward";
import { Button } from "@mui/material";
import Grid from "@mui/material/Grid2";

import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useUserContext } from "../../Context/UserContext";
import { customersRoute } from "../../Navigation/core/utils";
import mixpanel from "../../utils/mixpanel";
import { type ReportCardProps, ReportChartCard } from "./ReportChartCard";
import SectionHeader from "./SectionHeader";

export function Reports() {
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const customerId = useCustomerId();

  const cards: ReportCardProps[] = [
    {
      title: "Services breakdown",
      mixpanelTitle: "servicesbreakdown",
      reportId: "KKi6lAX4N66XYCVAdqRD",
      type: "treemap_chart",
    },
    {
      title: "This month vs. last",
      mixpanelTitle: "thismonthvslast",
      reportId: "Anl2FHDAgyxR4GFellrA",
      type: "area_chart",
    },
    {
      title: "Historical costs & forecast",
      mixpanelTitle: "historicalcost",
      reportId: "ViSpueEq1iXNMcL1n6Un",
      type: "column_and_line",
    },
  ];

  if (!userRoles?.has(UserPermissions.CloudAnalyticsUser)) {
    return null;
  }

  const link = (
    <Button
      component={Link}
      to={customersRoute(customerId, `analytics/reports`)}
      endIcon={<ForwardIcon />}
      onClick={() => {
        mixpanel.track("home.reports.all.click", { $referrer: location.href });
      }}
    >
      Go to reports
    </Button>
  );

  return (
    <Grid
      container
      spacing={2}
      direction="row"
      sx={{
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Grid size={12}>
        <SectionHeader text="Dive into your cloud with preset reports" link={link} />
      </Grid>
      {cards.map((card) => (
        <Grid
          key={card.title}
          size={{
            xs: 12,
            md: 4,
          }}
        >
          <ReportChartCard customerId={customerId} card={card} />
        </Grid>
      ))}
    </Grid>
  );
}
