import { type AxiosResponse } from "axios";

import { useApiContext } from "../../api/context";
import { useCloudAnalyticsTransforms } from "../../Components/hooks/cloudAnalytics/useCloudAnalyticsTransforms";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { type CommitmentFormValues, CommitmentManagerError } from "./types";

export const useGetLabelText = (filter) => {
  const transforms = useCloudAnalyticsTransforms();

  const getLabelText = (filterValue) => {
    const labelAux = transforms?.[filter.id]?.(filterValue);
    const labelText = labelAux ?? filterValue;
    if (labelText.length > 20) {
      return `${labelText.slice(0, 20)}...`;
    }
    return labelText;
  };

  return getLabelText;
};

const commitmentFromValues = (values: CommitmentFormValues) => {
  const commitment = {
    name: values.name,
    contractType: values.contractType,
    contracts: values.selectedContractIds,
    currency: values.currency,
    periods: values.periods.map((period) => ({
      ...period,
      commitmentValue: Number(period.commitmentValue),
      marketplaceLimitPercentage: Number(period.marketplaceLimitPercentage),
    })),
    billingAccounts: values.billingAccounts,
    excessRollOverPercentage: Number(values.excessValue),
    shortfallRollOverPercentage: Number(values.shortfallValue),
    serviceFilter: values.serviceFilter?.values?.length ? values.serviceFilter : undefined,
    skuFilter: values.skuFilter?.values?.length ? values.skuFilter : undefined,
  };

  return commitment;
};

export const useCreateCommitmentApiRequest = () => {
  const api = useApiContext();
  const customerId = useCustomerId();

  const handleSubmit = (values: CommitmentFormValues): Promise<AxiosResponse<string>> => {
    const commitment = commitmentFromValues(values);
    return api.post(`/v1/customers/${customerId}/commitment-manager`, commitment).catch((error) => {
      if (error.response) {
        if (error.response.status === 404) {
          throw new CommitmentManagerError("Error creating commitment, no data found");
        }
        if (error.response.status >= 400 && error.response.status < 500) {
          throw new CommitmentManagerError("Error creating commitment, invalid input");
        }
      }

      throw error;
    });
  };

  const handleUpdate = (values: CommitmentFormValues, commitmentId: string): Promise<AxiosResponse<string>> => {
    const commitment = commitmentFromValues(values);

    return api
      .put<string>(`/v1/customers/${customerId}/commitment-manager/${commitmentId}`, commitment)
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 404) {
            throw new CommitmentManagerError("Error updating commitment, no data found");
          }
          if (error.response.status >= 400 && error.response.status < 500) {
            throw new CommitmentManagerError("Error updating commitment, invalid input");
          }
        }

        throw error;
      });
  };

  return { handleSubmit, handleUpdate };
};
