import { type FC, useState } from "react";

import { Box, CircularProgress, Container, Stack, Typography } from "@mui/material";
import { Form, Formik, type FormikHelpers, type FormikValues } from "formik";
import { type AnyObject, type ObjectSchema } from "yup";

import { stepLabels } from "../../Pages/CommitmentManager/consts";
import { ButtonsFooter } from "../ButtonsFooter/ButtonsFooter";
import { FullWidthStepper } from "./FullWidthStepper";
import { useWizardForm } from "./hooks";

type Props<T extends FormikValues & AnyObject> = {
  steps: FC[];
  onSubmit: (values: T) => Promise<void>;
  initialValues: T;
  validationSchema: ObjectSchema<object>[];
  loadingTitle?: string;
  loadingDescription?: string;
};

export const WizardForm = <T extends FormikValues & AnyObject>({
  steps,
  onSubmit,
  initialValues,
  validationSchema,
  loadingTitle,
  loadingDescription,
}: Props<T>) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { currentStep, next, back, StepComponent } = useWizardForm(steps);

  const handleSubmit = async (values: T, { setTouched }: FormikHelpers<T>) => {
    if (currentStep !== steps.length - 1) {
      setTouched({});
      next();
    } else {
      setIsSubmitting(true);
      await onSubmit(values);
      setIsSubmitting(false);
    }
  };

  if (isSubmitting && loadingTitle && loadingDescription) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          zIndex: 9999,
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 4 }}>
          <CircularProgress size={40} />
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h5">{loadingTitle}</Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              {loadingDescription}
            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema[currentStep]} onSubmit={handleSubmit}>
      <Form id="wizard-form" noValidate>
        <Container maxWidth="md" sx={{ my: 4, pb: 10 }} disableGutters>
          <FullWidthStepper steps={stepLabels} activeStep={currentStep} />
          <Stack mt={7}>
            <StepComponent />
          </Stack>
          <ButtonsFooter
            isLastStep={currentStep === steps.length - 1}
            onBackClicked={back}
            onNextClicked={next}
            allowNext={true}
            maxWidth="md"
            formId="wizard-form"
            finalStep="Create"
            hideBackButton={currentStep === 0}
            loading={isSubmitting}
          />
        </Container>
      </Form>
    </Formik>
  );
};
