import { Box, Checkbox, FormControlLabel, InputAdornment, Stack, Typography } from "@mui/material";

import { CustomTextField } from "./CustomTextField";

export const CustomCheckbox = ({ checked, name, value, handleChange, title, subtitle, errors, touched, required }) => (
  <Stack>
    <FormControlLabel
      sx={{ pl: 1 }}
      control={<Checkbox checked={checked} onChange={handleChange} name={name} color="primary" />}
      label={
        <Box>
          <Typography variant="body1">{title}</Typography>
          <Typography variant="caption" color="textSecondary">
            {subtitle}
          </Typography>
        </Box>
      }
    />

    {checked && (
      <CustomTextField
        onChange={handleChange}
        value={value ?? ""}
        name={`${name}Value`}
        sx={{
          width: "fit-content",
          ml: 5,
          mt: 1,
        }}
        label="Amount"
        type="number"
        errors={errors}
        touched={touched}
        required={required}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{
                  mr: 0.5,
                  "& .MuiTypography-root": {
                    color: "text.primary",
                  },
                }}
              >
                %
              </InputAdornment>
            ),
            inputProps: {
              min: 0,
              max: 100,
            },
          },
        }}
      />
    )}
  </Stack>
);
