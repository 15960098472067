import { type ClusterWithCloudConnect } from "@doitintl/cmp-models";

export enum ClusterComputedStatus {
  CONNECTION_ISSUE = "01-Connection issue",
  UNHEALTHY = "02-Unhealthy",
  NOT_CONFIGURED = "03-Not configured",
  HEALTHY = "04-Healthy",
  PARTIAL = "05-Partial",
  DELETED = "06-Deleted",
  PRIVATE = "07-Private",
  UNKNOWN = "08-Unknown",
}

export enum ClusterAgentStatus {
  NOT_INSTALLED = "01-Not installed",
  INSTALLED = "02-Installed",
  UNKNOWN = "03-Unknown",
  UPGRADE_AVAILABLE = "04-Upgrade available",
}

export interface ClusterTableItem extends ClusterWithCloudConnect {
  enabledFeatures?: string;
  computedStatus: ClusterComputedStatus;
  agentStatus: ClusterAgentStatus;
}
