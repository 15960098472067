import { type ReactNode } from "react";

export type Attachment = {
  id: string;
  file_name: string;
  content_url: string;
};

export type Comment = {
  created_at: string;
  public: boolean;
  html_body: string;
  attachments: Attachment[];
};

export type Author = {
  id: number;
  photo: {
    content_url: string;
  };
  name: string;
  role: string;
};

export enum SeverityLevels {
  low = "low",
  normal = "normal",
  high = "high",
  urgent = "urgent",
}

export enum Platform {
  finance = "finance___billing",
  cmp = "cloud_management_platform",
  gcp = "google_cloud_platform",
  aws = "amazon_web_services",
  credits = "credits___request",
  azure = "microsoft_azure",
  gSuite = "google_g_suite",
  office365 = "microsoft_office_365",
}

export const isZendeskPlatform = (value: string): value is Platform =>
  Object.values(Platform)
    .map((p) => p.toString())
    .includes(value);

export type Severity = {
  title?: string;
  description?: ReactNode;
  value: SeverityLevels;
  responseTime: string;
  color?: string;
};

export type AccessPlatform = {
  projectName: string;
  concedefyEmail: string;
  sauronRole?: boolean;
  concedefyRole?: string | null;
};

export type Ticket = {
  // from the API
  id: number;
  subject: string;
  description: string;
  status: string;
  priority: string;
  created_at: string;
  updated_at: string;
  requester_id: number;
  assignee_id?: number;
  organization_id: number;
  collaborator_ids: number[];
  email_cc_ids: number[];
  is_public: boolean;
  tags?: string[];
  fields?: {
    id: number;
    value: string;
  }[];
  satisfaction_rating?: {
    score: string;
    comment: string;
  };

  // types added by reducers
  requester: string;
  assignee?: {
    name: string;
    photo?: {
      content_url: string;
    };
    id?: number;
  };
};

export type Organization = {
  shared_tickets: boolean;
  organization_fields: {
    concedefy_disabled: boolean;
    classification: string;
  };
};

export type User = {
  id: number;
  name: string;
  email: string;
  role: string;
  photo: {
    content_url: string;
  };
  organization_id: number;
};

export type EscalateInfo = {
  id: number;
  reason: string;
  comment: string;
};

export type TagUpdateInfo = {
  id: number;
  tags: (string | null | undefined)[];
  vendorScoreChanged: boolean;
  charityChanged: boolean;
};

export type SolvedInfo = {
  solved: boolean;
  id: number;
};

export type SurveyInfo = {
  id: number;
  score: "good" | "bad";
  comment: string;
};

export type PlatformFilter =
  | "amazon_web_services"
  | "google_cloud_platform"
  | "google_g_suite"
  | "cloud_management_platform"
  | "microsoft_azure"
  | "finance___billing"
  | "credits___request"
  | "microsoft_office_365";

export type PlatformFilterInfo = {
  value: string;
  title: string;
};

export type ProductProperties = {
  asset: string;
  identifier?: string;
  accountId?: string;
  label?: string;
};
