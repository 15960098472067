import { Avatar, Card, CardContent, CardHeader, Stack } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { cmpBaseColors } from "../../../cmpBaseColors";
import { useDarkThemeCheck } from "../../../Components/hooks/useDarkThemeCheck";
import { useApiProviderLogo } from "../CloudflowBuilder/Common/utils";
import { type CloudflowBlueprint } from "../types";

type BlueprintProps = {
  blueprint: CloudflowBlueprint;
  onBlueprintClick: (blueprint: CloudflowBlueprint) => void;
  clouds: string[];
  disabled: boolean;
};

const Blueprint = ({ blueprint, onBlueprintClick, clouds, disabled }: BlueprintProps) => {
  const theme = useTheme();
  const getLogo = useApiProviderLogo();
  const isDarkMode = useDarkThemeCheck();
  const chipBackgroundColor = isDarkMode ? cmpBaseColors.backgroundDark : cmpBaseColors.backgroundLight;

  return (
    <Card
      data-cy="blueprintCard"
      onClick={() => {
        onBlueprintClick(blueprint);
      }}
      sx={{
        py: 2,
        cursor: "pointer",
        "&:hover": {
          borderColor: theme.palette.primary.main,
        },
        height: "100%",
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "initial",
      }}
    >
      <CardHeader
        title={blueprint.name}
        sx={{
          pb: 0.5,
          pt: 0,
        }}
        titleTypographyProps={{
          variant: "subtitle1",
          fontWeight: 500,
        }}
      />
      <CardContent
        sx={{
          py: 0,
        }}
      >
        <Typography
          variant="body2"
          sx={{
            color: "text.secondary",
          }}
        >
          {blueprint.description}
        </Typography>
      </CardContent>
      <CardActions disableSpacing sx={{ pb: 0 }}>
        <Stack
          direction="row"
          sx={{
            gap: 1,
            px: 1,
            mt: 1,
          }}
        >
          {clouds.map((cloud) => (
            <Avatar
              key={cloud}
              alt={cloud}
              src={getLogo(cloud)}
              sx={{
                backgroundColor: chipBackgroundColor,
                width: 26,
                height: 26,
                padding: 0.5,
              }}
            />
          ))}
        </Stack>
      </CardActions>
    </Card>
  );
};

export default Blueprint;
