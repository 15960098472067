import { useCallback, useMemo } from "react";

import { useHistory } from "react-router-dom";
import { type Collaborators, EarlyAccessFeature, type PublicAccess } from "@doitintl/cmp-models";
import { Typography } from "@mui/material";
import { isAxiosError } from "axios";

import { useApiContext } from "../../../api/context";
import { attributionGroupsText } from "../../../assets/texts";
import { attributionGroupTxt } from "../../../assets/texts/CloudAnalytics";
import useAnalyticsUsers from "../../../Components/hooks/cloudAnalytics/useAnalyticsUsers";
import { useCloudAnalyticsAdminCheck } from "../../../Components/hooks/useCloudAnalyticsAdminCheck";
import { useFeatureFlag } from "../../../Components/hooks/useFeatureFlag";
import useRouteMatchURL from "../../../Components/hooks/useRouteMatchURL";
import {
  useErrorSnackbar,
  useInfoSnackbar,
  useSuccessSnackbar,
} from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useEntitiesContext } from "../../../Context/customer/EntitiesContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useIsFeatureEntitled } from "../../../Context/TierProvider";
import { useUserContext } from "../../../Context/UserContext";
import { consoleErrorWithSentry } from "../../../utils";
import mixpanel from "../../../utils/mixpanel";
import Invites from "../../IAM/InviteUserDialog/handleInvite";
import { type AnalyticsResourcesAttributionGroups } from "../analyticsResources/types";
import { useCloudAnalyticsContext } from "../CloudAnalyticsContext";
import { BasicMenuOptions, getNewCollaborators, getNewUsers, isEditor, isOwner } from "../utilities";
import { copyAttributionGroup, deleteAttributionGroup, deleteAttributionGroups, shareAttributionGroups } from "./api";
import { type AttributionGroupWithRef } from "./types";
import { attributionGroupMenuOptions } from "./utils";

export const useCreateAttributionGroupHandler = ({
  mixpanelEventName,
  baseUrl,
}: {
  mixpanelEventName: string;
  baseUrl: string;
}) => {
  const history = useHistory();
  const routeMatchURL = useRouteMatchURL();

  return useCallback(() => {
    mixpanel.track(mixpanelEventName);
    history.push(`${baseUrl}/create`, { prevPage: routeMatchURL });
  }, [baseUrl, history, mixpanelEventName, routeMatchURL]);
};

export const useAttributionGroupThreeDotsMenu = ({
  row,
  handleShare,
  handleDelete,
  tierLimitReached,
}: {
  row: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups;
  handleShare: () => void;
  handleDelete: () => void;
  tierLimitReached: boolean;
}) => {
  const infoSnackbar = useInfoSnackbar(3);
  const isEntitledAnalyticsAttributionGroups = useIsFeatureEntitled("analytics:attributionGroups");
  const isAllocationsFeatureEnabled = useFeatureFlag(EarlyAccessFeature.ALLOCATIONS_PAGES);
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const isCurrentUserOwner = isOwner(currentUser.email, row.data);
  const isCurrentUserEditor = isEditor(currentUser.email, row.data);
  const api = useApiContext();
  const { customer } = useCustomerContext();
  const isCloudAnalyticsAdmin = useCloudAnalyticsAdminCheck();

  const handleCopyAttributionGroup = useCallback(async () => {
    if (!currentUser.email) {
      return;
    }
    await copyAttributionGroup(api, row, customer.id);
    infoSnackbar(attributionGroupTxt.DUPLICATE_SUCCESSFUL);
  }, [api, currentUser.email, customer.id, infoSnackbar, row]);

  const actionMenu = useMemo(
    () => ({
      [BasicMenuOptions.SHARE]: handleShare,
      [BasicMenuOptions.DUPLICATE]: handleCopyAttributionGroup,
      [BasicMenuOptions.DELETE]: handleDelete,
    }),
    [handleCopyAttributionGroup, handleDelete, handleShare]
  );

  const attributionGroupsActionHandler = useCallback(
    (action: string) => () => {
      actionMenu[action](true);
    },
    [actionMenu]
  );

  const threeDotsMenuOptions: ThreeDotsMenuOption[] = useMemo(
    () =>
      attributionGroupMenuOptions
        .filter(
          (option) =>
            !(
              (option.value === BasicMenuOptions.DELETE && !isCurrentUserOwner) ||
              (option.value === BasicMenuOptions.SHARE && !(isCurrentUserEditor || isCloudAnalyticsAdmin)) ||
              (option.value === BasicMenuOptions.DUPLICATE && isAllocationsFeatureEnabled)
            )
        )
        .map((option) => ({
          label: <Typography color={option.color}>{option.value}</Typography>,
          action: attributionGroupsActionHandler(option.value),
          key: option.value,
          disabled: option.value === BasicMenuOptions.DUPLICATE && tierLimitReached,
        })),
    [
      isCurrentUserOwner,
      isCurrentUserEditor,
      isCloudAnalyticsAdmin,
      attributionGroupsActionHandler,
      tierLimitReached,
      isAllocationsFeatureEnabled,
    ]
  );

  return (
    <ThreeDotsMenu closeAfterSelect options={threeDotsMenuOptions} disabled={!isEntitledAnalyticsAttributionGroups} />
  );
};

export const useAttributionGroupShareHandler = ({
  attributionGroups,
  setShareLoading,
  onClose,
}: {
  attributionGroups: {
    name: string;
    collaborators: Collaborators;
    public: PublicAccess;
    id: string;
    type: string;
  }[];
  setShareLoading: (loading: boolean) => void;
  onClose: () => void;
}) => {
  const api = useApiContext();
  const { userRoles } = useUserContext({ requiredRoles: true, allowNull: true });
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();
  const { currentUser } = useAuthContext({ mustHaveUser: true });
  const { customer } = useCustomerContext();
  const { entities } = useEntitiesContext();
  const { handleMissingPermission } = useCloudAnalyticsContext();
  const { invites, userEmails, allUsersAndInvites } = useAnalyticsUsers();

  const isCurrentUserUserManager = userRoles.usersManager || userRoles.doitEmployee;
  const attributionGroupsWithoutPresets = useMemo(
    () => attributionGroups.filter((x) => x.type !== "preset"),
    [attributionGroups]
  );

  const inviteAction = useCallback(
    async (collaborators) => {
      for (const attributionGroup of attributionGroupsWithoutPresets) {
        const emails = await getNewUsers({
          collaborators,
          users: userEmails,
          invites,
          baseEntity: { data: attributionGroup },
          allUsersAndInvites,
        });
        if (emails.length && currentUser) {
          await Invites.handleInvite({
            newEmails: emails,
            customer,
            currentUser,
            api,
            entities,
          });
        }
      }
    },
    [attributionGroupsWithoutPresets, userEmails, invites, allUsersAndInvites, currentUser, customer, api, entities]
  );

  return useCallback(
    async (collaborators: Collaborators, publicAccess: PublicAccess) => {
      try {
        setShareLoading(true);
        if (isCurrentUserUserManager) {
          try {
            await inviteAction(collaborators);
          } catch (e) {
            handleMissingPermission(e as string);
            setShareLoading(false);
            return;
          }
        }

        for (const attributionGroup of attributionGroupsWithoutPresets) {
          await shareAttributionGroups({
            attributionGroupId: attributionGroup.id,
            collaborators: getNewCollaborators(collaborators, attributionGroup.collaborators),
            publicAccess: publicAccess === "mixed" ? attributionGroup.public : publicAccess,
            api,
            customerId: customer.id,
          });
        }

        successSnackbar(attributionGroupsText.SHARE_SUCCESS);
        onClose();
      } catch (error) {
        errorSnackbar(attributionGroupsText.SHARE_FAILED);
        consoleErrorWithSentry(error);
      }

      setShareLoading(false);
    },
    [
      setShareLoading,
      isCurrentUserUserManager,
      successSnackbar,
      onClose,
      inviteAction,
      handleMissingPermission,
      attributionGroupsWithoutPresets,
      api,
      customer.id,
      errorSnackbar,
    ]
  );
};

export const useDeleteAttributionGroupHandler = ({
  row,
  selected,
  closeDialog,
}: {
  row: AttributionGroupWithRef | AnalyticsResourcesAttributionGroups;
  selected: AttributionGroupWithRef[] | AnalyticsResourcesAttributionGroups[];
  closeDialog: () => void;
}) => {
  const { handleDeleteAttributionGroupValidationResponse } = useCloudAnalyticsContext();
  const successSnackbar = useSuccessSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const { customer } = useCustomerContext();
  const api = useApiContext();

  return useCallback(async () => {
    try {
      if (!row.data) {
        const attributionGroupIds = selected.map((b) => b.ref.id);
        await deleteAttributionGroups({ attributionGroupIds, api, customerId: customer.id });
        successSnackbar(attributionGroupsText.DELETE_ATTRIBUTION_GROUPS_SUCCESS);
        mixpanel.track("analytics.attribution-groups.delete", {
          attributionGroupIds: attributionGroupIds.join(","),
        });
      } else {
        await deleteAttributionGroup({ attributionGroupId: row.ref.id, api, customerId: customer.id });
        successSnackbar(attributionGroupsText.DELETE_ATTRIBUTION_GROUP_SUCCESS);
        mixpanel.track("analytics.attribution-groups.delete", {
          attributionGroupId: row.ref.id,
        });
      }
    } catch (error) {
      consoleErrorWithSentry(error);

      if (isAxiosError(error) && error.response?.status === 409) {
        handleDeleteAttributionGroupValidationResponse(error.response?.data);
      } else {
        errorSnackbar(attributionGroupsText.DELETE_ATTRIBUTION_GROUPS_ERROR);
      }
    } finally {
      closeDialog();
    }
  }, [
    row,
    selected,
    api,
    customer.id,
    successSnackbar,
    handleDeleteAttributionGroupValidationResponse,
    errorSnackbar,
    closeDialog,
  ]);
};
