import { useEffect, useMemo, useState } from "react";

import { type TransformMethod, useCollectionDataOnce } from "@doitintl/models-firestore";
import { type FirebaseCollectionReferenceModel, type FirebaseQueryModel } from "@doitintl/models-firestore/src/core";
import { type DocumentData } from "firebase/firestore";
import noop from "lodash/noop";

import { useErrorSnackbar } from "../../../../Components/SharedSnackbar/SharedSnackbar.context";
import { consoleErrorWithSentry } from "../../../../utils";

function getFieldFromFirstItem<T extends object, K extends keyof T>(
  collection: T[] | undefined,
  field: K,
  defaultValue: T[K]
): T[K] {
  return collection?.[0]?.[field] ?? defaultValue;
}

function getFieldFromLastItem<T extends object, K extends keyof T>(
  collection: T[] | undefined,
  field: K,
  defaultValue: T[K]
): T[K] {
  return collection?.[collection?.length - 1]?.[field] ?? defaultValue;
}

export function usePaginatedCollectionData<
  TModel extends DocumentData,
  TQueryField extends string & keyof TModel,
  TTransformed extends { [key in TQueryField]: TModel[TQueryField] },
>(
  query: FirebaseCollectionReferenceModel<TModel> | undefined,
  transform: TransformMethod<TModel, TTransformed>,
  options: {
    queryField: TQueryField;
    transformedQueryField?: TQueryField;
    limit?: number;
    // when provided, this field should contain a list of results matching the expected outcome from the queryField e.g
    // if the query field was the serviceName then the list could contain services like "AWS Lambda". any matching items
    // will be prepended to the first page of results
    initialListItems?: string[];
  }
) {
  const transformedQueryField = options.transformedQueryField ?? options.queryField;
  const limit = options.limit ?? 25;
  const showErrorSnackbar = useErrorSnackbar();
  const initialListItems = options.initialListItems || [];

  const [cursor, setCursor] = useState<{ direction: "<" | ">"; value: string; currentPage: number }>({
    direction: ">",
    value: "",
    currentPage: 0,
  });

  let paginatedQuery: FirebaseQueryModel<TModel> | undefined = query;
  let initialItemsQuery: FirebaseQueryModel<TModel> | undefined = undefined;

  const shouldLoadInitialItems = cursor.currentPage === 0 && initialListItems.length > 0;

  if (paginatedQuery) {
    if (shouldLoadInitialItems) {
      initialItemsQuery = paginatedQuery.where(options.queryField, "in", initialListItems);

      paginatedQuery = paginatedQuery
        .where(options.queryField, cursor.direction, cursor.value)
        .where(options.queryField, "not-in", options.initialListItems)
        .orderBy(options.queryField)
        .limit(Math.max(1, limit - initialListItems.length));
    } else {
      // For all other pages regularly load the data
      paginatedQuery = paginatedQuery
        .where(options.queryField, cursor.direction, cursor.value)
        .orderBy(options.queryField);

      if (cursor.direction === ">") {
        paginatedQuery = paginatedQuery.limit(limit);
      } else {
        paginatedQuery = paginatedQuery.limitToLast(limit);
      }

      if (initialListItems.length > 0) {
        paginatedQuery = paginatedQuery.where(options.queryField, "not-in", initialListItems);
      }
    }
  }

  useEffect(() => {
    setCursor({ direction: ">", value: "", currentPage: 0 });
  }, [query?.path]);

  const [result, loading, error] = useCollectionDataOnce(paginatedQuery, {
    transform,
  });

  const [initialItemsResult, initialItemsLoading, _] = useCollectionDataOnce(initialItemsQuery, {
    transform,
  });

  useEffect(() => {
    if (error) {
      showErrorSnackbar(error.message);
      consoleErrorWithSentry(error);
    }
  }, [error, showErrorSnackbar]);

  const [lastItemWrapper, loadingLastPageCheck, lastPageError] = useCollectionDataOnce(
    query ? query.orderBy(options.queryField).limitToLast(1) : undefined,
    { transform }
  );
  useEffect(() => {
    if (lastPageError) {
      showErrorSnackbar(lastPageError.message);
      consoleErrorWithSentry(lastPageError);
    }
  }, [lastPageError, showErrorSnackbar]);

  const res = useMemo(() => {
    if (shouldLoadInitialItems && initialItemsResult && initialItemsResult.length > 0) {
      return [...initialItemsResult, ...(result || [])];
    }

    return result;
  }, [initialItemsResult, result, shouldLoadInitialItems]);

  const isLoading = loading || loadingLastPageCheck || (shouldLoadInitialItems && initialItemsLoading);

  const loadNext = isLoading
    ? noop
    : () => {
        setCursor(({ currentPage }) => ({
          direction: ">",
          value: getFieldFromLastItem(res as { [key in TQueryField]: string }[], transformedQueryField, ""),
          currentPage: currentPage + 1,
        }));
      };

  const loadPrev = isLoading
    ? noop
    : () => {
        setCursor(({ currentPage }) => ({
          direction: "<",
          value: getFieldFromFirstItem(res as { [key in TQueryField]: string }[], transformedQueryField, ""),
          currentPage: currentPage - 1,
        }));
      };

  const disablePrev = !query || cursor.currentPage === 0;
  const disableNext =
    !query ||
    (res !== undefined &&
      (res.length < limit ||
        (res.length > 0 &&
          getFieldFromFirstItem(lastItemWrapper as { [key in TQueryField]: string }[], transformedQueryField, "") ===
            getFieldFromLastItem(res as { [key in TQueryField]: string }[], transformedQueryField, ""))));

  return [res, isLoading, loadPrev, loadNext, disablePrev, disableNext] as const;
}
