import { useMemo, useRef, useState } from "react";

import { Box, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { type EChartsOption } from "echarts";
import ECharts from "echarts-for-react";

import { METRICS } from "../../data/metrics";
import { Percentile } from "../Components/PercentileSelector";
import ChartCard from "./ChartCard";
import { formatMemory, formatTimestamp, formatTimestampAxis, getCommonChartOptions } from "./utils";

const xAxisIntervals = 4;

const chartStyles: React.CSSProperties = {
  height: "100%",
  width: "100%",
};

const UtilizationCharts = () => {
  const theme = useTheme();
  const originalMetrics = METRICS;

  const [percentile, setPercentile] = useState(Percentile.P_99_9);

  const metrics = useMemo(
    () =>
      originalMetrics.map((m) => ({
        ...m,
        formatted_start_time: formatTimestamp(m.start_time),
      })),
    [originalMetrics]
  );

  const chartRef1 = useRef<ECharts>(null);
  const chartRef2 = useRef<ECharts>(null);

  const option1: EChartsOption = {
    ...getCommonChartOptions(theme, {
      tooltip: {
        valueFormatter(value) {
          return `${value ? (+value).toFixed(2) : 0} cores`;
        },
      },
    }),
    xAxis: {
      data: metrics.map((m) => m.formatted_start_time),
      axisLabel: {
        formatter: formatTimestampAxis,
        interval: Math.floor(metrics.length / xAxisIntervals),
      },
    },
    series: [
      {
        name: "Allocated",
        type: "line",
        data: metrics.map((m) => m.cpu_limit),
        color: "black",
        areaStyle: {
          color: "black",
          opacity: 0.5,
        },
      },
      {
        name: "Requested",
        type: "line",
        data: metrics.map((m) => m.cpu_requested),
        color: theme.palette.statusPrimary.main,
        areaStyle: {
          color: theme.palette.statusPrimary.main,
          opacity: 0.5,
        },
      },
      {
        name: "Used",
        type: "line",
        data: metrics.map((m) => m.cpu_use),
        color: theme.palette.statusPrimary.light,
        areaStyle: {
          color: theme.palette.statusPrimary.light,
          opacity: 0.5,
        },
      },
    ],
  };

  const option2: EChartsOption = {
    ...getCommonChartOptions(theme, {
      yAxis: {
        axisLabel: {
          formatter: (v) => formatMemory(v, 0),
        },
      },
      tooltip: {
        valueFormatter(value) {
          return value ? `${formatMemory(String(value))} GB` : "0";
        },
      },
    }),
    xAxis: {
      data: metrics.map((m) => m.formatted_start_time),
      axisLabel: {
        formatter: formatTimestampAxis,
        interval: Math.floor(metrics.length / xAxisIntervals),
      },
    },
    series: [
      {
        name: "Allocated",
        type: "line",
        data: metrics.map((m) => m.mem_limit),
        color: "black",
        areaStyle: {
          color: "black",
          opacity: 0.5,
        },
      },
      {
        name: "Requested",
        type: "line",
        data: metrics.map((m) => m.mem_requested),
        color: theme.palette.secondary.dark,
        areaStyle: {
          color: theme.palette.secondary.dark,
          opacity: 0.5,
        },
      },
      {
        name: "Used",
        type: "line",
        data: metrics.map((m) => m.mem_use),
        color: theme.palette.secondary.main,
        areaStyle: {
          color: theme.palette.secondary.main,
          opacity: 0.5,
        },
      },
    ],
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChartHighlight = (params: any, chartIndex: number) => {
    const otherChartRef = chartIndex === 1 ? chartRef2.current : chartRef1.current;

    if (otherChartRef) {
      otherChartRef.getEchartsInstance().dispatchAction({
        type: "showTip",
        seriesIndex: params.batch?.[0].seriesIndex,
        dataIndex: params.batch?.[0].dataIndex,
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChartMouseOut = (params: any, chartIndex: number) => {
    const otherChartRef = chartIndex === 1 ? chartRef2.current : chartRef1.current;
    if (otherChartRef) {
      otherChartRef.getEchartsInstance().dispatchAction({
        type: "hideTip",
      });
    }
  };

  const getEventHandlers = (chartIndex: number) => ({
    highlight: (params: object) => {
      handleChartHighlight(params, chartIndex);
    },
    mouseout: (params: object) => {
      handleChartMouseOut(params, chartIndex);
    },
    globalout: (params: object) => {
      handleChartMouseOut(params, chartIndex);
    },
  });

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid size={6}>
          <ChartCard title="Memory utilization" percentile={percentile} onChangePercentile={setPercentile}>
            <ECharts style={chartStyles} option={option1} ref={chartRef1} onEvents={getEventHandlers(1)} lazyUpdate />
          </ChartCard>
        </Grid>
        <Grid size={6}>
          <ChartCard title="CPU utilization" percentile={percentile} onChangePercentile={setPercentile}>
            <ECharts style={chartStyles} option={option2} ref={chartRef2} onEvents={getEventHandlers(2)} lazyUpdate />
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default UtilizationCharts;
